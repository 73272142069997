import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { InitialEducationStockService } from 'src/app/services/initial-education-stock.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-initial-education-stock-alert',
  templateUrl: './initial-education-stock-alert.component.html',
  styleUrl: './initial-education-stock-alert.component.scss',
})
export class InitialEducationStockAlertComponent {
  public isSubmitting: boolean = false;
  constructor(
    private router: Router,
    private userService: UserService,
    private initialEducationStockService: InitialEducationStockService,
    private alertService: AlertService
  ) {}

  /**
   * Getter for the isInitialEducationStockSet property
   * @returns boolean
   */
  get isInitialEducationStockSet(): boolean {
    if (!this.userService.currentUserIsStudent()) return true;
    return this.userService.currentUser.isInitialEducationStockSet;
  }

  /**
   * Redirects to the initial-education-stock route
   * @returns void
   */
  public onOpenInitialEducationStock(): void {
    this.router.navigate([
      'eleguide/organization/education-progress/initial-education-stock',
    ]);
  }

  /**
   * Calls the markInitialEducationStockAsSet method from the InitialEducationStockService
   * @returns void
   */
  public markInitialEducationStockAsSet(): void {
    this.isSubmitting = true;
    this.initialEducationStockService
      .markInitialEducationStockAsSet()
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Anfangsbestand wurde erfolgreich initialisiert.'
          );
          this.userService.currentUser.isInitialEducationStockSet = true;
          this.isSubmitting = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Dsa hat leider nicht geklappt!',
            'Der Anfangsbestand konnte nicht initialisiert werden.'
          );

          this.isSubmitting = false;
        },
      });
  }
}
