import { Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { MobileUiService } from 'src/app/service/mobile-ui.service';

@Component({
  selector: 'app-course-detail-skeleton',
  templateUrl: './course-detail-skeleton.component.html',
  styleUrl: './course-detail-skeleton.component.scss',
})
export class CourseDetailSkeletonComponent implements OnDestroy {
  public isMobile = false;

  private destroy$: Subject<void> = new Subject();

  constructor(private mobileUiService: MobileUiService) {
    this.viewChanges();
  }

  /**
   * Subscribe to the current view changes
   * @returns void
   */
  private viewChanges() {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.isMobile = currentView === 'mobile';
      });
  }

  /**
   * Unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
