import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
})
export class ImageCropperDialogComponent {
  imageCropper: ImageCropperComponent;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  aspectRatio = 1 / 1;
  roundCropper = false;
  height = 150;
  title = 'Bild zuschneiden';
  constructor(
    public dialogRef: MatDialogRef<ImageCropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      image: any;
      title?: string;
      aspectRatio?: number;
      height?: number;
      round?: boolean;
    }
  ) {
    this.imageChangedEvent = data.image;
    this.roundCropper = data.round;
    this.height = data.height;
    this.aspectRatio = data.aspectRatio || 1 / 1;
    this.title = data.title || 'Bild zuschneiden';
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    const reader = new FileReader();
    reader.onload = () => {
      this.croppedImage = reader.result;
    };
    reader.readAsDataURL(event.blob);
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  onSave(): void {
    this.dialogRef.close(this.croppedImage);
  }
  onCancel(): void {
    this.dialogRef.close();
  }
}
