<div [class.table-bicolor]="dataSource?.filteredData?.length > 1" class="table">
  <table [dataSource]="dataSource" mat-table matSort>
    <!-- Practial Work cooperation partner column -->
    <ng-container matColumnDef="cooperationPartner">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        Kooperationspartner
        <app-custom-table-sort-icon
          [sort]="sort"
          columnName="cooperationPartner">
        </app-custom-table-sort-icon>
      </th>
      <td
        *matCellDef="let practicalWork; dataSource: dataSource"
        (click)="viewPracticalWork(practicalWork)"
        class="cursor-pointer gray-900"
        matTooltip="Praktische Tätigkeit anzeigen"
        mat-cell>
        {{ practicalWork.cooperationPartner.name }}
      </td>
    </ng-container>

    <!-- Practial Work Date Column -->
    <ng-container matColumnDef="date">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        Datum
        <app-custom-table-sort-icon [sort]="sort" columnName="date">
        </app-custom-table-sort-icon>
      </th>
      <td *matCellDef="let practicalWork; dataSource: dataSource" mat-cell>
        <span>
          {{ practicalWork.startDate | date: 'dd.MM.yyyy' }}
        </span>
        <span
          *ngIf="!isSameDay(practicalWork.startDate, practicalWork.endDate)">
          - {{ practicalWork.endDate | date: 'dd.MM.yyyy' }}
        </span>
      </td>
    </ng-container>

    <!-- Practial Work duration column -->
    <ng-container matColumnDef="duration">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        Dauer
        <app-custom-table-sort-icon [sort]="sort" columnName="duration">
        </app-custom-table-sort-icon>
      </th>
      <td *matCellDef="let practicalWork; dataSource: dataSource" mat-cell>
        {{ practicalWork.duration | floatToHoursMinutes }}
      </td>
    </ng-container>

    <!-- Practial Work type column -->
    <ng-container matColumnDef="type">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        PT
        <app-custom-table-sort-icon [sort]="sort" columnName="type">
        </app-custom-table-sort-icon>
      </th>
      <td *matCellDef="let practicalWork; dataSource: dataSource" mat-cell>
        {{ practicalWork.type === 1 ? 'PT1' : 'PT2' }}
      </td>
    </ng-container>

    <!-- Practial Work confirmation column -->
    <ng-container matColumnDef="confirmation">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        Bestätigung
        <app-custom-table-sort-icon [sort]="sort" columnName="confirmation">
        </app-custom-table-sort-icon>
      </th>
      <td
        *matCellDef="let practicalWork; dataSource: dataSource"
        class="gray-600"
        mat-cell>
        <div
          *ngIf="practicalWork?.files?.length > 0"
          class="flex-r align-center">
          <button
            (click)="onDownload(practicalWork?.id, practicalWork?.files[0]?.id)"
            type="button"
            class="table-button-icon"
            matTooltip="Datei herunterladen">
            <i class="fa-light fa-file-contract icon-sm"></i>
          </button>
          <span>Dokument</span>
        </div>
        <div
          *ngIf="!practicalWork?.files || practicalWork?.files?.length == 0"
          class="flex-r gap-8 align-center">
          <i class="fa-light fa-file-slash icon-sm"></i>
          <span class="gray-600">Keine Bestätigung</span>
        </div>
      </td>
    </ng-container>

    <!-- Practial Work buttons column -->
    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef class="center-header-cell" mat-header-cell>
        {{ isMobile ? 'Akt.' : 'Aktionen' }}
      </th>
      <td *matCellDef="let practicalWork; dataSource: dataSource" mat-cell>
        <div class="table-button-icons">
          <button
            #t="matMenuTrigger"
            *appCheckPermissions="{
              feature: feature.PRACTICAL_WORK,
              permission: permission.VIEW,
            }"
            [matMenuTriggerFor]="actionsMenu"
            type="button"
            class="table-button-icon"
            matTooltip="Praktische Tätigkeit Aktionen">
            <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
          </button>

          <mat-menu #actionsMenu="matMenu" class="action-menu">
            <button
              *appCheckPermissions="{
                feature: feature.PRACTICAL_WORK,
                permission: permission.VIEW,
              }"
              (click)="viewPracticalWork(practicalWork)"
              mat-menu-item
              type="button">
              <div class="action-menu-item">
                <i class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                <span class="hasomed-text-body gray-600">Anzeigen</span>
              </div>
            </button>
            <mat-divider
              *appCheckPermissions="{
                feature: feature.PRACTICAL_WORK,
                permission: permission.ADMIN,
              }"
              class="menu-divider"></mat-divider>
            <button
              *appCheckPermissions="{
                feature: feature.PRACTICAL_WORK,
                permission: permission.ADMIN,
              }"
              (click)="editPracticalWork(practicalWork)"
              mat-menu-item
              type="button">
              <div class="action-menu-item">
                <i class="fa-light fa-pen icon-sm"></i>
                <span class="hasomed-text-body gray-600">Bearbeiten</span>
              </div>
            </button>
            <mat-divider
              *appCheckPermissions="{
                feature: feature.PRACTICAL_WORK,
                permission: permission.ADMIN,
              }"
              class="menu-divider"></mat-divider>
            <button
              *appCheckPermissions="{
                feature: feature.PRACTICAL_WORK,
                permission: permission.ADMIN,
              }"
              (click)="deletePracticalWork(practicalWork)"
              mat-menu-item
              type="button">
              <div class="action-menu-item">
                <i class="fa-light fa-trash-can icon-sm"></i>
                <span class="hasomed-text-body gray-600">Löschen</span>
              </div>
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <tbody>
      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

      <!-- Row shown when there is no matching data. -->
      <tr *matNoDataRow class="mat-row">
        <td [attr.colspan]="displayedColumns.length">
          <div *ngIf="isLoading" class="loading-wrapper">
            <app-lottie-loading-spinner
              size="45px"></app-lottie-loading-spinner>
          </div>
          <div
            *ngIf="!isLoading"
            class="mat-cell table-no-match hasomed-text-body font-grey">
            Es wurde keine Praktische Tätigkeit gefunden.
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <mat-divider></mat-divider>
  <mat-paginator
    [appCustomLength]="dataSource.filteredData.length"
    [pageSize]="7"
    [renderButtonsNumber]="isMobile ? 0 : 1"
    custom-paginator
    aria-label="Select page"></mat-paginator>
</div>
