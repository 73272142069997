<span mat-dialog-title>Sitzung abgelaufen</span>
<div mat-dialog-content>
  <span class="hasomed-text-body">
    Bitte melden Sie sich erneut an, um fortzufahren.
  </span>
</div>

<div mat-dialog-actions class="action-buttons">
  <button
    type="button"
    class="hasomed-text-button hasomed-button-primary"
    (click)="onConfirm()">
    <i class="hasomed-button-icon fa-light fa-right-to-bracket"></i>
    <span class="hasomed-button-text">Neu anmelden</span>
  </button>
</div>
