import { Component } from '@angular/core';
import { NavLink } from 'src/app/models/nav-link.model';
import { Feature, Permission } from 'src/app/models/permission.model';

@Component({
  selector: 'app-institute',
  templateUrl: './institute.component.html',
  styleUrl: './institute.component.scss',
})
export class InstituteComponent {
  public navLinks: NavLink[] = [
    {
      label: 'Stammdaten',
      link: 'master-data',
      feature: Feature.INSTITUTE,
      permission: Permission.VIEW,
    },
    {
      label: 'Aus- und Weiterbildungsgänge',
      link: 'education-courses',
      feature: Feature.INSTITUTE,
      permission: Permission.ADMIN,
    },
    {
      label: 'Mitglieder',
      link: 'members',
      feature: Feature.INSTITUTE,
      permission: Permission.ADMIN,
    },
    {
      label: 'Lehrinhalte',
      link: 'learning-contents',
      feature: Feature.INSTITUTE,
      permission: Permission.ADMIN,
    },
    {
      label: 'Prüfungsarten',
      link: 'exam-types',
      feature: Feature.INSTITUTE,
      permission: Permission.ADMIN,
    },
    {
      label: 'Räume',
      link: 'rooms',
      feature: Feature.INSTITUTE,
      permission: Permission.ADMIN,
    },
  ];
}
