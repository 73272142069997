import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { ImageCropperDialogComponent } from 'src/app/components/shared-components/image-cropper-dialog/image-cropper-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import {
  WikiPageCreateModel,
  WikiPageUpdateModel,
} from 'src/app/models/wiki.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { WikiService } from 'src/app/services/wiki.service';
import { isRequired } from 'src/app/utils/form.utils';

@Component({
  selector: 'app-create-edit-page',
  templateUrl: './create-edit-page.component.html',
  styleUrls: ['./create-edit-page.component.scss'],
})
export class CreateEditPageComponent implements OnInit, OnDestroy {
  public currentWikiPageId: number;
  public editMode = false;
  public imageUrl: string | ArrayBuffer | null;
  private initialFormValues: {};
  public isLoading = true;

  public allowedFileTypes: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'WEBP', mimeType: 'image/webp' },
  ];

  public tinyMceSettings = {
    // plugins:
    //   'lists link image table help emoticons anchor autolink charmap emoticons searchreplace visualblocks checklist casechange export formatpainter pageembed permanentpen tableofcontents powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss ',
    toolbar:
      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link table | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | help',
    help_tabs: ['shortcuts'],
    menubar: false,
  };

  public wikiPageForm: FormGroup = new FormGroup({
    title: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    subtitle: new FormControl('', [
      Validators.required,
      Validators.maxLength(255),
    ]),
    tinyMCE: new FormControl('', [
      Validators.required,
      Validators.maxLength(20000),
    ]),
    articlePicture: new FormControl(''),
    link: new FormControl('', Validators.maxLength(2000)),
  });

  // import from form.utils.ts
  public isRequired = isRequired;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.wikiPageForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private wikiService: WikiService,
    private formDeactivateService: FormDeactivateService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService,
    private loadingService: LoadingService
  ) {}

  public ngOnInit() {
    if (this.activatedRoute.snapshot.params['id']) {
      this.currentWikiPageId = +atob(this.activatedRoute.snapshot.params['id']);
      this.editMode = true;
      this.initData();
    } else {
      this.isLoading = false;
      this.initialFormValues = this.wikiPageForm.value;
    }
  }

  /**
   * getData
   * retrieves the current wiki page data from backend and initializes the form values
   * @returns void
   */
  private initData(): void {
    this.wikiService
      .getWikiPageById(this.currentWikiPageId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          const wikiPage = response.body
            ? await this.wikiService.parseBackendWikiPage(response.body)
            : null;

          if (!wikiPage) {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Der Informations-Artikel konnte nicht geladen werden.'
            );

            return;
          }

          this.wikiPageForm.patchValue({
            title: wikiPage.title,
            subtitle: wikiPage.subtitle,
            tinyMCE: wikiPage.content,
            link: wikiPage.link,
            articlePicture: wikiPage.image,
          });
          this.initialFormValues = this.wikiPageForm.value;
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Informations-Artikel konnte nicht geladen werden.'
          );
        },
      });
  }

  /**
   * Handles the form submission for creating or editing a wiki page.
   * If the form is valid and has changes, the loading spinner is shown and the wiki page is created or updated.
   * @returns void
   */
  public onSubmit(): void {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.wikiPageForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.wikiPageForm.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.loadingService.show();
    this.editMode ? this.updateWikiPage() : this.createWikiPage();
  }

  /**
   * onCancel
   * navigates back to the WikiPage list
   * @returns Promise<void>
   */
  public onCancel(): void {
    this.editMode
      ? this.router.navigate(['../../'], { relativeTo: this.activatedRoute })
      : this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  /**
   * createWikiPage
   * creates a new WikiPage
   * @returns Promise<void>
   */
  private async createWikiPage(): Promise<void> {
    const wikiPageCreateModel: WikiPageCreateModel = {
      title: this.wikiPageForm.get('title').value,
      subtitle: this.wikiPageForm.get('subtitle').value,
      content: this.wikiPageForm.get('tinyMCE').value,
      image: this.wikiPageForm.get('articlePicture').value ?? null,
      link: this.wikiPageForm.get('link').value ?? null,
    };

    const createPageObservable =
      await this.wikiService.createWikiPage(wikiPageCreateModel);
    createPageObservable.pipe(first()).subscribe({
      next: response => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          `Der Informations-Artikel "${wikiPageCreateModel.title}" wurde erfolgreich erstellt.`
        );
        this.initialFormValues = this.wikiPageForm.value;
        this.onCancel();
        this.loadingService.hide();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Der Informations-Artikel konnte nicht erstellt werden.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * updateWikiPage
   * updates an existing WikiPage
   * @returns Promise<void>
   */
  private async updateWikiPage(): Promise<void> {
    const wikiPageUpdateModel: WikiPageUpdateModel = {
      title: this.wikiPageForm.get('title').value,
      subtitle: this.wikiPageForm.get('subtitle').value,
      content: this.wikiPageForm.get('tinyMCE').value,
      image: this.wikiPageForm.get('articlePicture').value ?? null,
      link: this.wikiPageForm.get('link').value ?? null,
    };

    const updatePageObservable = await this.wikiService.updateWikiPage(
      this.currentWikiPageId,
      wikiPageUpdateModel
    );
    updatePageObservable.pipe(first()).subscribe({
      next: response => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          `Der Informations-Artikel "${wikiPageUpdateModel.title}" wurde erfolgreich aktualisiert.`
        );
        this.initialFormValues = this.wikiPageForm.value;
        this.onCancel();
        this.loadingService.hide();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Der Informations-Artikel konnte nicht bearbeitet werden.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * onDeleteWikiPage
   * deletes the current WikiPage
   * @returns void
   */
  public onDeleteWikiPage(): void {
    if (this.currentWikiPageId) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Löschen',
          message: 'Möchten Sie den Artikel wirklich löschen?',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.loadingService.show();
            this.wikiService
              .deleteWikiPage(this.currentWikiPageId)
              .pipe(first())
              .subscribe({
                next: response => {
                  this.alertService.showSuccessAlert(
                    'Das hat geklappt!',
                    `Der Informations-Artikel wurde erfolgreich gelöscht.`
                  );
                  this.onCancel();
                  this.loadingService.hide();
                },
                error: error => {
                  this.alertService.showErrorAlert(
                    'Das hat leider nicht geklappt!',
                    'Der Informations-Artikel konnte nicht gelöscht werden.'
                  );
                  this.loadingService.hide();
                },
              });
          }
        });
    }
  }

  /**
   * when an image is selected and the file type is valid, open the image cropper dialog
   * @param event
   * @returns void
   */
  public imageChangeEvent(event: any): void {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
      width: '500px',
      data: {
        image: event,
        title: 'Artikelbild zuschneiden',
        round: false,
        height: 500,
        aspectRatio: 16 / 9,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        if (result) {
          this.wikiPageForm.get('articlePicture').setValue(result);
        }
      });
  }

  /**
   * deleteImage
   * @description delete the uploaded image
   * @returns void
   */
  public deleteImage() {
    this.wikiPageForm.get('articlePicture').setValue(null);
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }

    return this.formDeactivateService.confirmDeactivation(
      this.wikiPageForm.value,
      this.initialFormValues
    );
  }

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
