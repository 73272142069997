<mat-sidenav-container
  [ngClass]="{
    'container-closed': sidenavService.collapsed || isMobile,
  }"
  class="sidenav-container">
  <mat-sidenav
    #sidenav
    class="sidenav"
    fixedInViewport
    mode="side"
    [opened]="!isMobile">
    <app-sidenav *ngIf="!isMobile"></app-sidenav>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <mat-toolbar class="topnav-toolbar">
      <div class="nav-header" *ngIf="isMobile">
        <!-- Logo and Title Left -->
        <a class="nav-icon" href="/eleguide" target>
          <img
            width="40"
            alt="EleGuideLogo"
            src="../../../../assets/img/Eleguide_logo_small.svg" />
        </a>
      </div>
      <div class="horizontal-spacer"></div>
      <div
        [class.bg-white]="instituteMenuOpen"
        *ngIf="showInstituteMenu && !isMobile"
        [class.institute-menu-hover]="
          filterCurrentInstitute() && filterCurrentInstitute()?.length > 0
        "
        class="institute-menu"
        id="instituteMenu">
        <div class="institute gap-8">
          <div class="flex-r align-center justify-center institute-icon">
            <i class="hasomed-button-icon-sm fa-light fa-school"></i>
          </div>

          <div class="flex-r justify-space-between flex-1">
            <span
              (click)="
                openInstituteDetails(instituteService.currentInstitute?.id)
              "
              class="hasomed-text-body institute-name cursor-pointer">
              {{ instituteService.currentInstitute?.name }}
            </span>
            <div
              *ngIf="
                filterCurrentInstitute() && filterCurrentInstitute()?.length > 0
              "
              (click)="onOpenInstituteMenu()"
              class="institute-menu-trigger">
              <i
                [class.rotated]="instituteMenuOpen"
                class="fa-light fa-chevron-down institute-menu-trigger-icon"></i>
            </div>
          </div>
        </div>
        <div *ngIf="instituteMenuOpen" class="institute-menu-content">
          <button
            *ngFor="let institute of filterCurrentInstitute()"
            (click)="onSwitchInstitute(institute)"
            class="institute-menu-button">
            <div class="hasomed-button-icon-sm institute-icon">
              <i class="fa-light fa-school"></i>
            </div>
            <div [matTooltip]="institute.name" class="hasomed-button-text">
              {{ institute.name }}
            </div>
          </button>
        </div>
      </div>
      <div
        *ngIf="!isMobile"
        [ngClass]="{ 'bg-white': userMenuOpen }"
        class="user-menu"
        id="userMenu">
        <div (click)="userMenuOpen = !userMenuOpen" class="user">
          <div class="flex-r align-center">
            <div class="user-icon">
              <img
                #userIconImage
                [attr.src]="
                  userService.currentUser.profilePicture ??
                  '../../../../../assets/img/user.jpg'
                "
                class="user-icon-image" />
            </div>
            <div class="user-name hasomed-text-body">
              <span>
                {{ this.userService.currentUser.name.firstName }}
                {{ this.userService.currentUser.name.lastName }}
              </span>
            </div>
          </div>
          <div class="user-menu-trigger">
            <i
              [class.rotated]="userMenuOpen"
              class="fa-light fa-chevron-down user-menu-trigger-icon"></i>
          </div>
        </div>
        <div *ngIf="userMenuOpen" class="user-menu-content">
          <button
            (click)="onOpenSettings()"
            type="button"
            class="user-menu-button">
            <div class="hasomed-button-icon">
              <i class="fa-light fa-gear"></i>
            </div>
            <div class="hasomed-button-text">
              <span>Einstellungen</span>
            </div>
          </button>

          <button
            (click)="onOpenInformation()"
            type="button"
            class="user-menu-button">
            <div class="hasomed-button-icon">
              <i class="fa-light fa-circle-info"></i>
            </div>
            <div class="hasomed-button-text">
              <span>Informationen</span>
            </div>
          </button>

          <button
            (click)="logout()"
            type="button"
            class="user-menu-button logout-button">
            <div class="hasomed-button-icon">
              <i class="fa-light fa-right-from-bracket"></i>
            </div>
            <div class="hasomed-button-text">
              <span>Log out</span>
            </div>
          </button>
        </div>
      </div>
      <app-mobile-menu *ngIf="isMobile"></app-mobile-menu>
    </mat-toolbar>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
