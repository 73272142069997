import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { CourseLocation, CourseModel } from 'src/app/models/course.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';
import { getEducationCourseTitles } from 'src/app/utils/course.utils';

@Component({
  selector: 'app-course-description',
  templateUrl: './course-description.component.html',
  styleUrl: './course-description.component.scss',
})
export class CourseDescriptionComponent implements OnInit, OnDestroy {
  public course: CourseModel;
  public isLoading = true;
  public isStudent: boolean = this.userService.currentUserIsStudent();

  public getEducationCourseTitles = getEducationCourseTitles;
  public CourseLocation = CourseLocation;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private courseService: CourseService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private cancellationService: CancellationService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnInit(): void {
    const courseId = +atob(
      this.activatedRoute.parent?.snapshot.paramMap.get('id')
    );
    this.getCourse(courseId);
  }

  /**
   * retrieves the course details of the current course
   * @param courseId
   * @returns void
   */
  private getCourse(courseId: number): void {
    this.courseService
      .getCourseById(courseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.course = await this.courseService.parseBackendCourse(
            response.body
          );
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Kursdetails konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  public getSanitizedHtml(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  /**
   * cancels all requests and unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
