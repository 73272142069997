import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { ViewProfilePictureDialogComponent } from 'src/app/components/shared-components/view-profile-picture-dialog/view-profile-picture-dialog.component';
import {
  Filter,
  FilterDateRange,
  FilterType,
} from 'src/app/models/filter.model';
import { Feature, Permission, Role } from 'src/app/models/permission.model';
import { UserModel } from 'src/app/models/user.model';
import { FilterStudentPipe } from 'src/app/pipes/filter-student.pipe';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserService } from 'src/app/services/user.service';
import { getTotalEducationalProgressInPercent } from 'src/app/utils/educational-progress.utils';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';
import { dateRangeIsNotEmpty } from 'src/app/utils/form.utils';
import { getFullName } from 'src/app/utils/user.utils';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';
import * as XLSX from 'xlsx';

type ExportedStudent = {
  name: string;
  email: string;
  educationCourse: string;
  entryDate?: string;
};

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss'],
})
export class StudentsComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'name',
    'educationCourse',
    'educationalProgress',
    'entryDate',
    'label',
    'actions',
  ];
  public dataSource: MatTableDataSource<UserModel> = new MatTableDataSource();
  public allStudents: UserModel[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public isLoading = true;
  public isLecturer: boolean = this.userService.currentUserIsLecturer();
  public currentUserIsAdministrator: boolean =
    this.userService.currentUserIsAdministrator();
  public isMobile = false;
  public isTablet = false;
  public showSearchBar = false;

  /* for permission */
  public feature = Feature;
  public permission = Permission;

  public dateRangeIsNotEmpty = dateRangeIsNotEmpty;

  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;
  private dateRangeSubject: Subject<FilterDateRange | null> = new Subject();
  public dateRangeFilter: Filter = { type: FilterType.DATE_RANGE, value: null };
  public studentFilter: Filter[] = [
    this.dateRangeFilter,
    {
      type: FilterType.EDUCATION_COURSE,
      value: null,
    },
    {
      type: FilterType.LABEL,
      value: null,
    },
  ];
  public hasActiveFilterValue = hasActiveFilterValue;
  public filterOpened: boolean = false;

  public searchForm: FormGroup = new FormGroup({
    searchText: new FormControl('', noWhitespaceValidator()),
    dateRange: new FormGroup({
      start: new FormControl(null, Validators.required),
      end: new FormControl(null, Validators.required),
    }),
  });

  // import from utils
  public getTotalEducationalProgressInPercent =
    getTotalEducationalProgressInPercent;
  public getFullName = getFullName;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private navigationService: NavigationService,
    private mobileUiService: MobileUiService
  ) {}

  public ngOnInit() {
    this.initTable();
    this.viewChanges();
    this.handleDateRangeFilterChange();
  }

  /**
   * Handles changes to the date range filter.
   * Updates the search form and applies the student filter.
   * @returns {void}
   */
  private handleDateRangeFilterChange(): void {
    this.dateRangeSubject.pipe(takeUntil(this.destroy$)).subscribe({
      next: dateRange => {
        if (!dateRange) {
          this.searchForm.patchValue({
            dateRange: {
              start: null,
              end: null,
            },
          });
        }
        this.dateRangeFilter.value = dateRange;
        this.applyStudentFilter();
      },
    });
  }

  /**
   * sets isMobile and isTablet depending on the current view
   * sets showSearchBar to true if the current view is desktop
   * sets the displayed columns depending on the current view
   * @returns void
   */
  private viewChanges() {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.initTableColumns(currentView);

        this.isMobile = currentView === 'mobile';
        this.isTablet = currentView === 'tablet';

        this.showSearchBar = currentView === 'desktop';
      });
  }

  /**
   * set the columns for the table depending on the current view
   * @param currentView the current view
   * @returns void
   */
  private initTableColumns(currentView: string): void {
    if (currentView === 'mobile') {
      this.displayedColumns = ['name', 'actions'];
    } else if (currentView === 'tablet') {
      this.displayedColumns = ['name', 'label', 'actions'];
    } else {
      if (!this.isLecturer) {
        this.displayedColumns = [
          'name',
          'educationCourse',
          'educationalProgress',
          'entryDate',
          'label',
          'actions',
        ];
        return;
      }

      this.displayedColumns = ['name', 'educationCourse', 'label', 'actions'];
    }
  }

  /**
   * initializes the sorting, pagination and filtering of the table
   * inits the table data with the students of the current institute
   * @returns void
   */
  private initTable(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item.name?.lastName;
        case 'educationalProgress':
          return getTotalEducationalProgressInPercent(item);
        case 'vintage':
          return item.entryDate;
        case 'label':
          return item.label?.name;
        default:
          return item[property];
      }
    };

    this.dataSource.filterPredicate = (data, filter) => {
      const firstName = data.name.firstName
        ? String(data.name.firstName).toLowerCase()
        : '';
      const lastName = data.name.lastName
        ? String(data.name.lastName).toLowerCase()
        : '';

      const dataStr = firstName + ' ' + lastName;
      return dataStr.indexOf(filter.toLowerCase()) !== -1;
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getInstituteStudents();
  }

  /**
   * retrieves all students of the current institute and initializes the table data
   * @returns Promise<void>
   */
  private async getInstituteStudents(): Promise<void> {
    this.userService
      .getInstituteUsersByRole(Role.STUDENT, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          const promises = response.body?.map(
            async (userData: any): Promise<UserModel> => {
              const user = await this.userService.parseBackendUser(userData);
              return user;
            }
          );

          if (!promises) {
            this.isLoading = false;
            return;
          }

          this.allStudents = await Promise.all(promises);
          this.dataSource.data = this.allStudents;

          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Kandidaten konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * studentFilterChanged
   * gets called when the student filter changed
   * @param student Filter[]
   * @returns void
   */
  public studentFilterChanged(studentFilter: Filter[]): void {
    this.studentFilter = studentFilter;

    if (!studentFilter.includes(this.dateRangeFilter)) {
      studentFilter.push(this.dateRangeFilter);
    }

    this.applyStudentFilter();
    this.filterMenuTrigger.closeMenu();

    // find FILTER_TYPE.DATE_RANGE and update date range
    const dateRangeFilter = studentFilter.find(
      filter => filter.type === FilterType.DATE_RANGE
    );

    if (dateRangeFilter && !dateRangeFilter.value) {
      this.dateRangeSubject.next(null);
    }
  }

  /**
   * applyStudentFilter
   * applies the student filter
   * @returns void
   */
  public applyStudentFilter(): void {
    this.dataSource.data = FilterStudentPipe.prototype.transform(
      this.allStudents,
      this.studentFilter
    );
  }

  /**
   * search the student name by search text
   * @param event
   */
  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /**
   * openStudentDetail
   * navigate to student detail page
   * @param userId
   * @returns void
   */
  public openStudentDetail(userId: number) {
    this.navigationService.setOverviewUrl(this.router.url);
    this.router.navigate(['detail', btoa(String(userId))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onEditStudent
   * navigate to edit page
   * @param userId
   */
  public onEditStudent(userId: number) {
    this.router.navigate(['edit', btoa(String(userId))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * Exports the filtered student data to an Excel file.
   * @throws Will show an error alert if there is an issue during the export process.
   * @returns {void}
   */
  public exportDataToExcel(): void {
    const filteredStudents = this.dataSource.filteredData;

    if (filteredStudents.length === 0) {
      this.alertService.showInfoAlert(
        'Keine Daten zum Exportieren',
        'Es sind keine Daten zum Exportieren vorhanden.'
      );
      return;
    }

    const exportedStudents = this.prepareExportedStudents(filteredStudents);

    try {
      const worksheet = this.createWorksheet(exportedStudents);
      const workbook = this.createWorkbook(worksheet);
      const fileName = this.generateFileName();

      XLSX.writeFile(workbook, fileName);

      this.alertService.showSuccessAlert(
        'Liste exportiert',
        `Die Liste ${fileName} wurde erfolgreich exportiert.`
      );
    } catch (error) {
      this.alertService.showErrorAlert(
        'Fehler beim Exportieren der Daten',
        'Beim Exportieren der Daten ist ein Fehler aufgetreten.'
      );
    }
  }

  /**
   * Prepares a list of students for export by mapping the filtered students to a new format.
   * @param filteredStudents - An array of UserModel objects representing the filtered students.
   * @returns An array of ExportedStudent objects representing the filtered students.
   */
  private prepareExportedStudents(
    filteredStudents: UserModel[]
  ): ExportedStudent[] {
    return filteredStudents.map((student: UserModel) => {
      const studentData: ExportedStudent = {
        name: `${student.name.firstName} ${student.name.lastName}`,
        email: student.email,
        educationCourse: student.educationCourse?.title ?? '-',
      };

      if (this.currentUserIsAdministrator) {
        studentData.entryDate = student.entryDate
          ? moment(student.entryDate).format('DD.MM.YYYY')
          : '-';
      }

      return studentData;
    });
  }

  /**
   * Creates an XLSX worksheet from an array of exported students.
   * @param exportedStudents - An array of `ExportedStudent` objects to be converted into a worksheet.
   * @returns An `XLSX.WorkSheet` object representing the exported students.
   */
  private createWorksheet(exportedStudents: ExportedStudent[]): XLSX.WorkSheet {
    const worksheet: XLSX.WorkSheet =
      XLSX.utils.json_to_sheet(exportedStudents);

    worksheet['A1'].v = 'Name';
    worksheet['B1'].v = 'E-Mail';
    worksheet['C1'].v = 'Bildungsgang';

    if (this.currentUserIsAdministrator) {
      worksheet['D1'].v = 'Eintrittsdatum';
    }

    worksheet['!cols'] = [{ wch: 20 }, { wch: 30 }, { wch: 30 }, { wch: 20 }];

    return worksheet;
  }

  /**
   * Creates a new Excel workbook and appends the provided worksheet to it.
   * @param worksheet - The worksheet to be appended to the new workbook.
   * @returns The newly created workbook with the appended worksheet.
   */
  private createWorkbook(worksheet: XLSX.WorkSheet): XLSX.WorkBook {
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Kandidat_innen');
    return workbook;
  }

  /**
   * Generates a filename for a candidate list Excel file.
   * The filename includes the current date and time formatted as 'DD_MM_YYYY-HH_mm'.
   * @returns {string} The generated filename in the format 'Kandidat_innen_DD_MM_YYYY-HH_mm.xlsx'.
   */
  private generateFileName(): string {
    const date = moment().format('DD_MM_YYYY-HH_mm');
    return `Kandidat_innen_${date}.xlsx`;
  }

  /**
   * openProfilePictureDialog
   * opens the profile picture dialog
   * @param user
   */
  public openProfilePictureDialog(user: UserModel): void {
    this.dialog.open(ViewProfilePictureDialogComponent, {
      data: {
        image: user.profilePicture,
      },
    });
  }

  /**
   * navigate to the invite members page
   * @returns void
   */
  public onInviteUser(): void {
    this.router.navigate(['/eleguide/institute/members/invite']);
  }

  /**
   * Applies the selected date range from the search form to the dateRangeSubject.
   * @returns {void}
   */
  public applyDateRange(): void {
    this.dateRangeSubject.next({
      start: this.searchForm.value.dateRange?.start,
      end: this.searchForm.value.dateRange?.end,
    });
  }

  /**
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
