import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Filter, FilterDateRange, FilterType } from '../models/filter.model';
import { Label } from '../models/label.model';
import { Role } from '../models/permission.model';
import { UserModel } from '../models/user.model';

@Pipe({
  name: 'filterMembers',
  standalone: true,
})
export class FilterMembersPipe implements PipeTransform {
  transform(members: UserModel[], filter: Filter[]): UserModel[] {
    if (!members || !filter) {
      return members;
    }

    return members.filter(member => {
      return filter.every(filter => {
        if (filter.value === null) {
          return true;
        }

        switch (filter.type) {
          case FilterType.DATE_RANGE:
            const start = moment((filter.value as FilterDateRange).start)
              .startOf('day')
              .toDate();
            const end = moment((filter.value as FilterDateRange).end)
              .endOf('day')
              .toDate();
            return (
              moment(member.entryDate).isSameOrAfter(start) &&
              moment(member.entryDate).isSameOrBefore(end)
            );
          case FilterType.LABEL:
            if (this.isLabel(filter.value)) {
              return member.label?.id === filter.value.id;
            }
            return false;
          case FilterType.ROLE:
            switch (filter.value) {
              case Role.ADMINISTRATOR:
                return member.roleId === Role.ADMINISTRATOR;
              case Role.LECTURER:
                return member.roleId === Role.LECTURER;
              case Role.STUDENT:
                return member.roleId === Role.STUDENT;
              default:
                return true;
            }
          case FilterType.LOGON_STATE:
            switch (filter.value) {
              case 'invited':
                if (!member.isConfirmedByAdmin && member.isInvited) {
                  return true;
                }
                return false;
              case 'registered':
                if (
                  member.encryptionKeysGenerated &&
                  !member.isConfirmedByAdmin
                ) {
                  return true;
                }
                return false;
              case 'confirmed':
                if (
                  member.encryptionKeysGenerated &&
                  member.isConfirmedByAdmin
                ) {
                  return true;
                }
                return false;
              default:
                return true;
            }
          default:
            return true;
        }
      });
    });
  }

  private isLabel(obj: any): obj is Label {
    return obj && typeof obj === 'object' && 'name' in obj && 'color' in obj;
  }
}
