import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  AppointmentCreateModel,
  AppointmentModel,
  AppointmentUpdateModel,
} from '../models/appointment.model';
import { RoomService } from './room.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  private instituteId: number =
    this.userService.currentUser?.currentInstituteId;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService,
    private roomService: RoomService
  ) {}

  /**
   * Retrieves an appointment by its ID.
   * @param appointmentId - The ID of the appointment to retrieve.
   * @returns An observable of the HTTP response containing the appointment data.
   */
  public getAppointmentById(
    appointmentId: number
  ): Observable<HttpResponse<any>> {
    return this.http.get(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/appointments/${appointmentId}`,
      { observe: 'response', responseType: 'json' }
    );
  }

  /**
   * Creates a new appointment.
   * @param appointmentCreateModel - The data for the new appointment.
   * @returns An observable of the HTTP response.
   */
  public createAppointment(
    appointmentCreateModel: AppointmentCreateModel
  ): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/appointments`,
      appointmentCreateModel,
      { headers: headers, observe: 'response', responseType: 'json' }
    );
  }

  /**
   * Updates an existing appointment.
   * @param appointmentId - The ID of the appointment to update.
   * @param appointmentUpdateModel - The updated data for the appointment.
   * @returns An observable of the HTTP response.
   */
  public updateAppointment(
    appointmentId: number,
    appointmentUpdateModel: AppointmentUpdateModel
  ): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/appointments/${appointmentId}`,
      appointmentUpdateModel,
      { headers: headers, observe: 'response', responseType: 'json' }
    );
  }

  /**
   * Deletes an appointment.
   * @param appointmentId - The ID of the appointment to delete.
   * @returns An observable of the HTTP response.
   */
  public deleteAppointment(
    appointmentId: number
  ): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/appointments/${appointmentId}`,
      { observe: 'response', responseType: 'json' }
    );
  }

  /**
   * Deletes an event date for an appointment.
   * @param appointmentId - The ID of the appointment.
   * @param eventDateId - The ID of the event date to delete.
   * @returns An observable of the HTTP response.
   */
  public deleteAppointmentEventDate(
    appointmentId: number,
    eventDateId: number
  ): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/appointments/${appointmentId}/event-dates/${eventDateId}`,
      { observe: 'response', responseType: 'json' }
    );
  }

  /**
   * Parses backend appointment data into an AppointmentModel.
   * @param appointmentData - The raw appointment data from the backend.
   * @returns A promise that resolves to an AppointmentModel.
   */
  async parseBackendAppointment(
    appointmentData: AppointmentModel
  ): Promise<AppointmentModel> {
    const appointment: AppointmentModel = {
      id: appointmentData.id,
      type: appointmentData.type,
      title: appointmentData.title,
      description: appointmentData.description,
      maxParticipants: appointmentData.maxParticipants,
      dateSeriesStart: appointmentData.dateSeriesStart
        ? moment(appointmentData.dateSeriesStart).tz('Europe/Berlin').toDate()
        : null,
      dateSeriesEnd: appointmentData.dateSeriesEnd
        ? moment(appointmentData.dateSeriesEnd).tz('Europe/Berlin').toDate()
        : null,
      recurrencePattern: appointmentData.recurrencePattern,
      eventDates: appointmentData.eventDates
        ? await Promise.all(
            appointmentData.eventDates.map(async eventDate => {
              const room = eventDate.room
                ? await this.roomService.parseBackendRoom(eventDate.room)
                : null;
              return {
                id: eventDate.id,
                startDate: moment(eventDate.startDate)
                  .tz('Europe/Berlin')
                  .toDate(),
                endDate: moment(eventDate.endDate).tz('Europe/Berlin').toDate(),
                room: room,
              };
            })
          )
        : [],
      location: appointmentData.location,
      videoMeetingUrl: appointmentData.videoMeetingUrl,
      holidayDate: appointmentData.holidayDate
        ? moment(appointmentData.holidayDate).toDate()
        : null,
    };

    return appointment;
  }
}
