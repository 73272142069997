import { Component, Input } from '@angular/core';
import { ClipboardService } from 'src/app/services/clipboard.service';

@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
})
export class CopyToClipboardComponent {
  @Input() textToCopy: string;
  @Input() type: string;

  constructor(public clipboardService: ClipboardService) {}
}
