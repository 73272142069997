import { Component, Input } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-lottie-loading-spinner',
  templateUrl: './lottie-loading-spinner.component.html',
  styleUrl: './lottie-loading-spinner.component.scss',
})
export class LottieLoadingSpinnerComponent {
  public lottieConfig: AnimationOptions = {
    path: '/assets/lottie/eleguide-loading-animation.json',
  };

  @Input() public size: string = '100px';
}
