import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, interval, of, startWith, switchMap } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private cookieService: CookieService,
    private authService: AuthService
  ) {}

  /**
   * checkSessionValidity
   * checks if the session is still valid
   * @returns Observable<boolean>
   */
  public checkSessionValidity(): Observable<boolean> {
    return interval(60000).pipe(
      startWith(0),
      switchMap(() => {
        const currentTime = moment();
        const sessionTimeoutCookie = this.cookieService.get(
          this.config.sessionTimeoutCookieName
        );
        const timeLeft = moment
          .utc(sessionTimeoutCookie, 'MM/DD/YYYY HH:mm:ss')
          .diff(currentTime, 'seconds');

        // if session is expired, logout
        if (timeLeft <= 60) {
          this.authService.logout();
          return of(false); // Emit false as the session is being terminated
        }

        return of(timeLeft > 0 && timeLeft <= 300); // Emit true if less than or equal to 5 minutes left
      })
    );
  }
}
