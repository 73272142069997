import {
  EducationalProgressModel,
  EducationalProgressType,
} from '../models/education-progress.model';
import { UserModel } from '../models/user.model';

/**
 * gets the total education progress of a student in percent
 * @param student
 * @returns total education progress in percent
 */
export function getTotalEducationalProgressInPercent(
  student: UserModel
): number {
  if (!student.educationalProgress) {
    return 0;
  }

  const totalProcentualProgress = student.educationalProgress.find(
    (progress: EducationalProgressModel) =>
      progress.type === EducationalProgressType.TOTAL
  )?.progressInPercent;

  return totalProcentualProgress ? totalProcentualProgress : 0;
}
