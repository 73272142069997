import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ngSwitchMultiCase',
  standalone: true,
})
export class NgSwitchMultiCasePipe<T> implements PipeTransform {
  transform(value: T, ...options: T[]): T | undefined {
    return options.includes(value) ? value : undefined;
  }
}
