import { RoomModel } from '../models/room.model';

/**
 * gets the room name with floor
 * @param room RoomModel
 * @returns string
 */
export function getRoomNameWithFloor(room?: RoomModel): string {
  if (!room) {
    return 'Kein Raum';
  }

  const roomFloor = room.floor > 0 ? room.floor + '.OG' : 'EG';

  return `${room.name} ${roomFloor}`;
}
