import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import { CancellationService } from './cancellation.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class EducationProgressService {
  public instituteId: number = this.userService.currentUser?.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService,
    private cancellationService: CancellationService
  ) {}

  /**
   * Calls the backend to get the educational progress of a student
   * @param studentId The id of the student
   * @returns An observable of the HTTP response
   */
  public getEducationProgressByStudentId(
    studentId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/users/${studentId}/educational-progress`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Calls the backend to check if the student has initialized education stock
   * @param studentId The id of the student
   * @returns An observable of the HTTP response with a boolean value
   */
  public hasStudentInitializedEducationStock(
    studentId: number
  ): Observable<HttpResponse<boolean>> {
    return this.http
      .get<boolean>(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/initial-education-stocks/students/${studentId}/is-initialized`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }
}
