<app-event-room-planning
  *ngIf="showCourseRoomPlanning"
  [eventDates]="selectedEvent.eventDates"
  [title]="selectedEvent.name"
  [subtitle]="getSubTitleForRoomPlanning()"
  [participants]="selectedEvent.participants"
  [numberOfLecturers]="selectedEvent.numberOfLecturers"
  [isCourse]="selectedEvent.type === eventType.COURSE"
  (onRoomPlanningClose)="closeRoomPlanning($event)">
</app-event-room-planning>
<div class="component-content" *ngIf="!showCourseRoomPlanning">
  <div class="header">
    <div class="header-title">
      <span class="hasomed-text-h1">Räume organisieren</span>
      <span class="hasomed-text-h3">
        Organisieren Sie die Räume des Instituts
      </span>
    </div>
    <form class="header-buttons" [formGroup]="searchForm">
      <!-- Search input -->
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche"
          (keyup)="applyFilter($event)"
          #input />
      </div>

      <div class="hasomed-date-range">
        <mat-date-range-input
          class="hasomed-text-input hasomed-date-range-input"
          formGroupName="dateRange"
          [rangePicker]="picker">
          <input
            id="startDate"
            matStartDate
            placeholder="Startdatum"
            formControlName="start"
            readonly />
          <input
            id="endDate"
            matEndDate
            placeholder="Enddatum"
            formControlName="end"
            (dateChange)="applyDateRange()"
            readonly />
        </mat-date-range-input>
        <mat-datepicker-toggle
          class="hasomed-date-range-toggle"
          matIconSuffix
          [for]="picker">
          <mat-icon
            matDatepickerToggleIcon
            class="calendar-toggle-icon fa-light fa-calendar">
          </mat-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker
          #picker
          [restoreFocus]="false"></mat-date-range-picker>
      </div>
      <!-- Filter Button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        [ngClass]="{
          'hasomed-button-primary': hasActiveFilterValue(
            roomOrganizationFilter,
            true
          ),
          'hasomed-button-white-grey': !hasActiveFilterValue(
            roomOrganizationFilter,
            true
          ),
          'hasomed-button-active': filterOpened,
        }"
        [matMenuTriggerFor]="filterMenu"
        (onMenuOpen)="filterOpened = true"
        (onMenuClose)="filterOpened = false">
        <i class="fa-light fa-filter-list hasomed-button-icon"></i>
        <span class="hasomed-button-text">Filter</span>
      </button>

      <!-- Filter Menu -->
      <mat-menu class="filter-overlay" #filterMenu="matMenu">
        <app-filter
          *ngIf="filterOpened && !isLoading"
          [filters]="roomOrganizationFilter"
          (filterChanged)="roomOrganizationFilterChanged($event)"
          [instituteEvents]="instituteEvents">
        </app-filter>
      </mat-menu>

      <!-- Room overview Button -->
      <button
        *appCheckPermissions="{
          permission: permission.ADMIN,
          feature: feature.ROOM_ADMINISTRATION,
        }"
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="openRoomOverview()">
        <i class="fa-light fa-building-columns hasomed-button-icon"></i>
        <span class="hasomed-button-text">Raumübersicht</span>
      </button>
    </form>
  </div>

  <div class="main-content">
    <!-- Filter Tags -->
    <app-filter-tags
      [filters]="roomOrganizationFilter"
      (filterChanged)="roomOrganizationFilterChanged($event)">
    </app-filter-tags>
    <div class="table">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Event title column -->
        <ng-container matColumnDef="event_title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Veranstaltung</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="event_title">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let instituteEvent" class="max-width-400">
            <div class="flex-r gap-4 cell-overflow-hidden">
              <span class="hasomed-text-body" style="font-weight: bold">
                <ng-container [ngSwitch]="instituteEvent.type">
                  <ng-container *ngSwitchCase="eventType.COURSE">
                    Kurs:
                  </ng-container>
                  <ng-container *ngSwitchCase="eventType.PATIENT_SESSION">
                    Patient:
                  </ng-container>
                  <ng-container *ngSwitchCase="eventType.SUPERVISION">
                    Supervision:
                  </ng-container>
                  <ng-container *ngSwitchCase="eventType.GROUP_SUPERVISION">
                    Gruppensupervision:
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    {{ instituteEvent.type }}:
                  </ng-container>
                </ng-container>
              </span>

              <span
                class="hasomed-text-body text-overflow-hidden"
                [matTooltip]="instituteEvent.name">
                {{ instituteEvent.name }}
              </span>
            </div>
          </td>
        </ng-container>

        <!-- Organizer column -->
        <ng-container matColumnDef="organizer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Organisator</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="organizer">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let instituteEvent">
            <span class="hasomed-text-body">
              {{
                instituteEvent.organizer.id
                  ? (instituteEvent.organizer.name.academicTitle
                      ? instituteEvent.organizer.name.academicTitle + ' '
                      : '') +
                    instituteEvent.organizer.name.firstName +
                    ' ' +
                    instituteEvent.organizer.name.lastName
                  : '-'
              }}
            </span>
          </td>
        </ng-container>

        <!-- Current room column -->
        <ng-container matColumnDef="currentRoom">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Aktueller Raum</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="currentRoom">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let instituteEvent">
            <span class="hasomed-text-body">
              {{ instituteEvent.currentRoom?.name || '-' }}
            </span>
          </td>
        </ng-container>

        <!-- Dates column -->
        <ng-container matColumnDef="dates">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>Termine</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="dates">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let instituteEvent">
            <span class="hasomed-text-body">
              {{ instituteEvent.startDate | date: 'dd.MM.yyyy' }} -
              {{ instituteEvent.endDate | date: 'dd.MM.yyyy' }}
            </span>
          </td>
        </ng-container>

        <!-- Rommplanning column -->
        <ng-container matColumnDef="roomplanning">
          <th mat-header-cell *matHeaderCellDef>
            <span>Raumplanung</span>
          </th>
          <td mat-cell *matCellDef="let instituteEvent">
            <button
              type="button"
              class="hasomed-text-button hasomed-button-transparent-orange"
              (click)="openRoomPlanning(instituteEvent)">
              <i
                class="hasomed-button-icon fa-arrow-up-right-from-square fa-light"></i>
              <span class="hasomed-button-text">Raumplanung</span>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length">
            <div *ngIf="isLoading" class="loading-wrapper">
              <app-lottie-loading-spinner
                size="45px"></app-lottie-loading-spinner>
            </div>
            <div
              *ngIf="!isLoading"
              class="table-no-match hasomed-text-body font-grey">
              <span>Es wurden keine Veranstaltungen gefunden.</span>
            </div>
          </td>
        </tr>
      </table>

      <mat-divider></mat-divider>
      <mat-paginator
        custom-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="10"
        [renderButtonsNumber]="1"
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
