<div class="component-content">
  <div class="header">
    <div class="header-buttons" *ngIf="isMobile || isTablet">
      <button
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onBackToOverview()">
        <span class="hasomed-button-text"> Zurück </span>
      </button>
      <button
        *ngIf="course?.isRegistered && isStudent"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCourseStudentChange(course)"
        [disabled]="isCourseLogoffDisabled(course)"
        [matTooltip]="getCourseLogoffTooltip(course)">
        <i class="hasomed-button-icon fa-light fa-right-to-bracket"> </i>
        <span class="hasomed-button-text"> Abmelden </span>
      </button>
      <button
        *ngIf="!course?.isRegistered && isStudent"
        class="hasomed-text-button hasomed-button-primary"
        (click)="onCourseStudentChange(course)"
        [disabled]="isCourseLogonDisabled(course)"
        [matTooltip]="getCourseLogonTooltip(course)">
        <i class="hasomed-button-icon fa-light fa-right-to-bracket"> </i>
        <span class="hasomed-button-text"> Anmelden </span>
      </button>
      <button
        *appCheckPermissions="{
          feature: Feature.COURSE_ADMINISTRATION,
          permission: Permission.ADMIN,
        }"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onEditCourse()">
        <i class="hasomed-button-icon fa-light fa-pen"></i>
        <span class="hasomed-button-text">Bearbeiten</span>
      </button>
    </div>
    <app-course-detail-skeleton
      class="w-full"
      *ngIf="isLoading"></app-course-detail-skeleton>
    <div class="summary" *ngIf="!isLoading">
      <div class="flex-r image-container" *ngIf="!isMobile">
        <img [src]="course.titlePicture" class="course-image" alt="Titelbild" />
        <div class="label-small label-course-image" *ngIf="course.mandatory">
          <i class="label-icon fa-light fa-file-certificate"></i>
          <span class="label-text">Ausbildungsrelevant</span>
        </div>
      </div>
      <div class="flex-c gap-16 justify-space-between w-full">
        <h1
          class="hasomed-text-h1"
          [matTooltip]="course.title?.length > 150 ? course.title : ''">
          {{ course.title | slice: 0 : 150
          }}{{ course.title?.length > 150 ? '...' : '' }}
        </h1>
        <div class="flex-r justify-space-between align-center gray-600">
          <div>
            <span> {{ getFullNames(course?.lecturers, false) }}, </span>
            <span class="m-z gray-600">
              {{ getCourseDateRange(course) }},
              {{ getCourseStartDate(course) | date: 'HH:mm' }} -
              {{ getCourseEndDate(course) | date: 'HH:mm' }} Uhr
            </span>
          </div>
          <app-elog-cell
            class="flex-r justify-end"
            *ngIf="course?.isRegistered && isStudent && !isMobile && !isTablet"
            [eLogStatusCounts]="course.elogStatusCounts"
            [shortText]="false"></app-elog-cell>
        </div>
        <mat-divider></mat-divider>
        <div class="flex-r gap-16 justify-space-between">
          <div class="flex-r gap-24" [class.flex-wrap]="isMobile || isTablet">
            <div class="flex-r align-center gap-8">
              <i class="fa-light fa-book hasomed-icon"></i>
              <span class="hasomed-text-body gray-700">
                {{ getEducationCourseTitles(course) }}
              </span>
            </div>
            <div *ngIf="course.closed" class="flex-r align-center gap-8">
              <i class="fa-light fa-lock-keyhole hasomed-icon"></i>
              <span class="hasomed-text-body gray-700"> geschlossen </span>
            </div>
            <div *ngIf="!course.closed" class="flex-r align-center gap-8">
              <i class="fa-light fa-lock-keyhole-open hasomed-icon"></i>
              <span class="hasomed-text-body gray-700"> offen </span>
            </div>
            <div class="flex-r align-center gap-8">
              <i class="fa-light fa-map-pin hasomed-icon"></i>
              <span class="hasomed-text-body gray-700">
                {{ getCourseRoom(course) }}
              </span>
            </div>
            <div class="flex-r align-center gap-8">
              <i class="fa-light fa-user-pen hasomed-icon"></i>
              <span class="hasomed-text-body gray-700">
                {{ course.registeredStudents ?? 0 }} von
                {{ course.maxParticipants }}
                angemeldet
              </span>
            </div>
          </div>
          <div class="flex-r gap-16" *ngIf="!isMobile && !isTablet">
            <button
              class="hasomed-text-button hasomed-button-white-grey"
              (click)="onBackToOverview()">
              <i class="hasomed-button-icon fa-light fa-chevron-left"></i>
            </button>
            <button
              *ngIf="course.isRegistered && isStudent"
              class="hasomed-text-button hasomed-button-white-grey"
              (click)="onCourseStudentChange(course)"
              [disabled]="isCourseLogoffDisabled(course)"
              [matTooltip]="getCourseLogoffTooltip(course)">
              <i class="hasomed-button-icon fa-light fa-right-to-bracket"> </i>
              <span class="hasomed-button-text"> Abmelden </span>
            </button>
            <button
              *ngIf="!course.isRegistered && isStudent"
              class="hasomed-text-button hasomed-button-primary"
              (click)="onCourseStudentChange(course)"
              [disabled]="isCourseLogonDisabled(course)"
              [matTooltip]="getCourseLogonTooltip(course)">
              <i class="hasomed-button-icon fa-light fa-right-to-bracket"> </i>
              <span class="hasomed-button-text"> Anmelden </span>
            </button>
            <button
              *appCheckPermissions="{
                feature: Feature.COURSE_ADMINISTRATION,
                permission: Permission.ADMIN,
              }"
              class="hasomed-text-button hasomed-button-white-grey"
              (click)="onEditCourse()">
              <i class="hasomed-button-icon fa-light fa-pen"></i>
              <span class="hasomed-button-text">Bearbeiten</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-c main-content-details course-details">
    <div class="child-navigation">
      <ng-container *ngFor="let link of navLinks">
        <a
          [routerLink]="link.link"
          #rla="routerLinkActive"
          routerLinkActive
          [class.hasomed-button-blank]="!rla.isActive"
          routerLinkActive="hasomed-button-transparent-primary"
          class="hasomed-text-button">
          <span class="hasomed-text-menu">{{ link.label }}</span>
        </a>
      </ng-container>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
