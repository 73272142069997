import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { OpeningHourModel } from 'src/app/models/opening-hour.model';
import {
  RoomCreateModel,
  RoomModel,
  RoomType,
  RoomUpdateModel,
} from 'src/app/models/room.model';
import { SelectOption } from 'src/app/models/select-option.model';
import { AddressService } from 'src/app/services/address.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { InstituteService } from 'src/app/services/institute.service';
import { LoadingService } from 'src/app/services/loading.service';
import { RoomService } from 'src/app/services/room.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import {
  createOpeningHoursFromFormArray,
  parseBackendOpeningHourModel,
} from 'src/app/utils/opening-hour.utils';
import { houseNumberValidator } from 'src/app/validators/house-number.validator';
import { maxNumberLength } from 'src/app/validators/max-number-length.validator';
import { minNumberLength } from 'src/app/validators/min-number-length.validator';
import { positiveNumbersOnlyValidator } from 'src/app/validators/positive-numbers-only.validator';

@Component({
  selector: 'app-create-edit-room',
  templateUrl: './create-edit-room.component.html',
  styleUrl: './create-edit-room.component.scss',
})
export class CreateEditRoomComponent implements OnInit, OnDestroy {
  public roomForm: FormGroup;
  public initialFormValues: {};
  public editMode: boolean;
  public isLoading: boolean = true;
  public currentRoom: RoomModel;
  public availableRoomTypes: SelectOption[] = [];
  public openingHours: OpeningHourModel[] = [];

  // import from form.utils.ts
  public isRequired = isRequired;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.roomForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private roomService: RoomService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService,
    public addressService: AddressService,
    private insituteService: InstituteService,
    private userService: UserService,
    private loadingService: LoadingService
  ) {}

  public async ngOnInit() {
    this.createForm();

    if (this.activatedRoute.snapshot.paramMap.get('id')) {
      const roomId: number = +atob(
        this.activatedRoute.snapshot.paramMap.get('id')
      );
      this.getData(roomId);
      this.editMode = true;
    }

    // get institute opening hours if creating a new room
    if (!this.editMode) {
      await this.getInstituteOpeningHours();
      this.isLoading = false;
    }

    this.getRoomTypes();

    if (this.editMode) {
      // subscribe to changes of available
      this.roomForm
        .get('available')
        .valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe({
          next: checked => {
            if (!checked) {
              this.roomService
                .hasRoomUpcomingEventDates(this.currentRoom.id)
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: response => {
                    if (response.body) {
                      const dialogRef = this.dialog.open(
                        ConfirmDialogComponent,
                        {
                          maxWidth: '400px',
                          data: {
                            title: `Bevorstehende Termine in Raum '${this.currentRoom.name}'!`,
                            message:
                              'Es sind noch bevorstehende Termine in diesem Raum vorhanden.  \
                        Wenn Sie den Raum deaktivieren und speichern, wird der Raum aus allen bevorstehenden Terminen entfernt. \
                        Möchten Sie den Raum trotzdem deaktivieren?',
                          },
                        }
                      );

                      dialogRef
                        .afterClosed()
                        .pipe(first())
                        .subscribe(dialogResult => {
                          if (dialogResult) {
                          } else {
                            this.roomForm.get('available').setValue(true);
                          }
                        });
                      this.initialFormValues = this.roomForm.value;
                    }
                  },
                });
            }
          },
        });
    }
  }

  /**
   * get the opening hours of the institute
   * @returns void
   */
  private async getInstituteOpeningHours(): Promise<void> {
    const instituteId = this.userService.currentUser?.currentInstituteId;
    return new Promise<void>((resolve, reject) => {
      this.insituteService
        .getInstituteOpeningHours(instituteId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: response => {
            this.openingHours = response.body.map(
              (openingHour: OpeningHourModel) => {
                return parseBackendOpeningHourModel(openingHour);
              }
            );
            resolve();
          },
          error: () => {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Die Öffnungszeiten des Instituts konnten nicht geladen werden.'
            );
            reject();
          },
        });
    });
  }

  /**
   * createForm
   * creates the form
   * @returns void
   */
  private createForm(): void {
    this.roomForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      roomType: new FormControl(null, Validators.required),
      street: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      houseNumber: new FormControl('', [
        Validators.required,
        houseNumberValidator(),
        Validators.maxLength(6),
      ]),
      addressAddition: new FormControl('', Validators.maxLength(100)),
      zipCode: new FormControl('', [
        Validators.required,
        minNumberLength(5),
        maxNumberLength(5),
        positiveNumbersOnlyValidator(true),
      ]),
      city: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      country: new FormControl(null, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      floor: new FormControl(null, [
        Validators.required,
        maxNumberLength(2),
        Validators.max(10),
      ]),
      building: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      seatNumber: new FormControl(null, [
        Validators.required,
        positiveNumbersOnlyValidator(true),
        maxNumberLength(4),
      ]),
      equipment: new FormControl('', Validators.maxLength(255)),
      responsible: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      available: new FormControl(true, Validators.required),
      openingHoursFormGroup: new FormGroup({}),
    });
  }

  /**
   * Returns the FormControl for the roomType field in the roomForm.
   * @returns The FormControl for the roomType field.
   */
  get roomTypeControl(): FormControl {
    return this.roomForm.get('roomType') as FormControl;
  }

  /**
   * Returns the FormControl for the country field in the roomForm.
   * @returns The FormControl for the country field.
   */
  get countryControl(): FormControl {
    return this.roomForm.get('country') as FormControl;
  }

  /**
   * getData
   * get the room data
   * @param roomId
   * @returns void
   */
  public getData(roomId: number): void {
    this.roomService
      .getRoomById(roomId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.currentRoom = await this.roomService.parseBackendRoom(
            response.body
          );

          this.roomForm.patchValue(this.currentRoom);

          // patch address & available
          this.roomForm.patchValue({
            roomType: this.currentRoom.roomType?.id,
            street: this.currentRoom.address.street,
            houseNumber: this.currentRoom.address.houseNumber,
            addressAddition: this.currentRoom.address.addressAddition,
            zipCode: this.currentRoom.address.zipCode,
            city: this.currentRoom.address.city,
            country: this.currentRoom.address.country,
            responsible: this.currentRoom.responsiblePerson,
            available: this.currentRoom.isAvailable,
          });

          this.openingHours = this.currentRoom.openingHours;
          this.isLoading = false;
          this.initialFormValues = this.roomForm.value;
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Raum konnte nicht geladen werden.'
          );
        },
      });
  }

  /**
   * getRoomTypes
   * get all available room types
   * @returns void
   */
  private getRoomTypes(): void {
    this.roomService
      .getRoomTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.availableRoomTypes = response.body.map(
            (roomType: RoomType): SelectOption => {
              return {
                value: roomType.id,
                label: roomType.name,
              };
            }
          );
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Raumtypen konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * Handles the form submission.
   * If the form is valid and has changes, it will create or update the room.
   * @returns void
   */
  public onSubmit(): void {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.roomForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.roomForm.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.loadingService.show();
    this.editMode ? this.updateRoom() : this.createRoom();
  }

  /**
   * onCancel
   * navigates back to the room overview
   * @returns void
   */
  public onCancel(): void {
    const route = this.editMode ? '../../' : '../';
    this.router.navigate([route], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * createRoom
   * creates a new room
   * @returns void
   */
  public async createRoom(): Promise<void> {
    const room: RoomCreateModel = {
      name: this.roomForm.get('name').value,
      roomTypeId: this.roomForm.get('roomType').value,
      address: {
        street: this.roomForm.get('street').value,
        houseNumber: this.roomForm.get('houseNumber').value,
        addressAddition: this.roomForm.get('addressAddition').value,
        zipCode: this.roomForm.get('zipCode').value,
        city: this.roomForm.get('city').value,
        country: this.roomForm.get('country').value,
      },
      floor: this.roomForm.get('floor').value,
      building: this.roomForm.get('building').value,
      seatNumber: this.roomForm.get('seatNumber').value,
      equipment: this.roomForm.get('equipment').value,
      responsiblePerson: this.roomForm.get('responsible').value,
      openingHours: createOpeningHoursFromFormArray(
        this.roomForm
          .get('openingHoursFormGroup')
          .get('openingHours') as FormArray
      ),
      isAvailable: Boolean(this.roomForm.get('available').value),
    };

    const createRoomObservable = await this.roomService.createRoom(room);

    createRoomObservable.pipe(first()).subscribe({
      next: () => {
        // reset initialFormValues to current form values
        this.initialFormValues = this.roomForm.value;
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });

        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          `Der Raum '${room.name}' wurde hinzugefügt!`
        );
        this.loadingService.hide();
      },
      error: error => {
        this.loadingService.hide();
        if (error.status === 409) {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            `Ein Raum mit dem Namen '${room.name}' existiert bereits.`
          );
          return;
        }
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          `Der Raum '${room.name}' konnte nicht erstellt werden.`
        );
      },
    });
  }

  /**
   * updateRoom
   * updates a room
   * @returns void
   */
  public async updateRoom(): Promise<void> {
    const room: RoomUpdateModel = {
      name: this.roomForm.get('name').value,
      roomTypeId: this.roomForm.get('roomType').value,
      address: {
        street: this.roomForm.get('street').value,
        houseNumber: this.roomForm.get('houseNumber').value,
        addressAddition: this.roomForm.get('addressAddition').value,
        zipCode: this.roomForm.get('zipCode').value,
        city: this.roomForm.get('city').value,
        country: this.roomForm.get('country').value,
      },
      floor: this.roomForm.get('floor').value,
      building: this.roomForm.get('building').value,
      seatNumber: this.roomForm.get('seatNumber').value,
      equipment: this.roomForm.get('equipment').value,
      responsiblePerson: this.roomForm.get('responsible').value,
      openingHours: createOpeningHoursFromFormArray(
        this.roomForm
          .get('openingHoursFormGroup')
          .get('openingHours') as FormArray
      ),
      isAvailable: Boolean(this.roomForm.get('available').value),
    };

    const updateRoomObservable = await this.roomService.updateRoom(
      this.currentRoom.id,
      room
    );

    updateRoomObservable.pipe(first()).subscribe({
      next: () => {
        if (!room.isAvailable && this.initialFormValues['available']) {
          this.removeRoomFromFutureEventDates(this.currentRoom);
        }

        // reset initialFormValues to current form values
        this.initialFormValues = this.roomForm.value;
        this.router.navigate(['../../'], { relativeTo: this.activatedRoute });

        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          `Der Raum '${room.name}' wurde bearbeitet.`
        );
        this.loadingService.hide();
      },
      error: () => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          `Der Raum '${room.name}' konnte nicht bearbeitet werden.`
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.roomForm.value,
      this.initialFormValues
    );
  }

  /**
   * removeRoomFromFutureEventDates
   * @param room
   * @returns void
   */
  private removeRoomFromFutureEventDates(room: RoomModel): void {
    this.roomService
      .removeRoomFromUpcomingEventDates(room.id)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            `Der Raum '${room.name}' wurde aus allen bevorstehenden Terminen entfernt.`
          );
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            `Der Raum '${room.name}' konnte nicht aus den bevorstehenden Terminen entfernt werden.`
          );
        },
      });
  }

  /**
   * Sets the opening hours to the opening hours that are passed from the opening hours component
   * @param openingHours The opening hours that are passed from the opening hours component
   * @returns void
   */
  public onOpeningHoursChange(openingHours: OpeningHourModel[]): void {
    this.openingHours = openingHours;
  }

  /**
   * Sets the initial form values after the opening hours have been initialized
   * @returns void
   */
  public onOpeningHoursInitialized(): void {
    this.initialFormValues = JSON.parse(JSON.stringify(this.roomForm.value));
  }

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
