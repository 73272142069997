<form
  [formGroup]="personalDataForm"
  (ngSubmit)="onSubmit()"
  class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Persönliche Daten</h1>
      <h3 class="hasomed-text-h3">Füllen Sie Ihre Daten aus.</h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>

      <!-- Submit button -->
      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Anrede und Titel</span>
        <span class="label-description">
          Geben Sie Ihre Anrede und Titel ein.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <gender-title-select
            class="flex-1"
            [control]="genderTitleControl"></gender-title-select>
          <academic-title-select
            class="flex-1"
            [control]="academicTitleControl">
          </academic-title-select>
        </div>
        <app-error-message
          [formField]="personalDataForm.get('genderTitle')"></app-error-message>
        <app-error-message
          [formField]="
            personalDataForm.get('academicTitle')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Vor- und Nachname
          <span
            *ngIf="
              isRequired(personalDataForm.get('firstName')) ||
              isRequired(personalDataForm.get('lastName'))
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Geben Sie Ihren Vor- und Nachnamen ein.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="firstName"
            class="hasomed-text-input"
            autocomplete="given-name"
            type="text"
            size="5"
            formControlName="firstName"
            placeholder="Vorname" />
          <input
            id="lastName"
            size="10"
            class="hasomed-text-input"
            autocomplete="family-name"
            type="text"
            formControlName="lastName"
            placeholder="Nachname" />
        </div>
        <app-error-message
          [formField]="personalDataForm.get('firstName')"></app-error-message>
        <app-error-message
          [formField]="personalDataForm.get('lastName')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Email -->
    <div class="formular-row">
      <label class="formular-row-label" for="email">
        <span class="label-title">E-Mail Adresse</span>
        <span class="label-description">Ihre E-Mail Adresse</span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-icon-input">
          <i class="fa-light fa-envelope input-icon"></i>
          <input
            id="email"
            class="hasomed-text-input"
            type="text"
            size="20"
            autocomplete="email"
            formControlName="email"
            placeholder="Email"
            readonly />
        </div>
        <app-error-message
          [formField]="personalDataForm.get('email')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Birthdate -->
    <div class="formular-row">
      <div class="formular-row-label">
        <div class="label-title">Geburtsdatum</div>
        <div class="label-description">Geben Sie Ihr Geburtsdatum an.</div>
      </div>
      <div class="formular-row-input">
        <mat-form-field class="hasomed-date-field hasomed-date-field-center">
          <input
            [max]="today"
            [matDatepicker]="picker"
            class="hasomed-date-input"
            maxlength="10"
            size="9"
            matInput
            placeholder="Datum wählen"
            formControlName="birthdate" />
          <mat-datepicker-toggle
            [for]="picker"
            class="hasomed-date-toggle"
            matIconPrefix>
            <mat-icon
              matDatepickerToggleIcon
              class="calendar-toggle-icon fa-light fa-calendar">
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker
            #picker
            [startAt]="datePickerStartDate"
            startView="multi-year"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Student number -->
    <div class="formular-row">
      <div class="formular-row-label">
        <ng-container [ngSwitch]="user?.roleId">
          <span *ngSwitchCase="Role.STUDENT" class="label-title">
            Ihre "Matrikelnummer"
          </span>
          <span *ngSwitchCase="Role.LECTURER" class="label-title">
            Ihre "Lehrpersonalnummer"
          </span>
          <span *ngSwitchDefault class="label-title">
            Ihre Personalnummer
          </span>
        </ng-container>
        <span class="label-description"> Wird automatisch vergeben </span>
      </div>
      <div class="formular-row-input student-number">
        <i class="fa-light fa-lock"></i>
        <span>
          {{
            user?.userIdentifier != '' && user?.userIdentifier
              ? user?.userIdentifier
              : (user?.roleId === Role.STUDENT
                  ? '"Matrikelnummer"'
                  : user?.roleId === Role.LECTURER
                    ? '"Lehrpersonalnummer"'
                    : 'Personalnummer') + ' noch nicht definiert'
          }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- entry date -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Eintrittsdatum</span>
        <span class="label-description"> Ihr Eintrittsdatum am Institut. </span>
      </div>
      <div class="formular-row-input entry-date">
        <i class="fa-light fa-lock"></i>
        <span>
          {{
            (user?.entryDate | date: 'dd.MM.yyyy') ??
              'Ihr Eintrittsdatum wurde noch nicht hinterlegt'
          }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Avatar -->
    <div class="formular-row">
      <label class="formular-row-label" for="profilePictureUpload">
        <span class="label-title">Profilfoto</span>
        <span class="label-description">
          Laden Sie hier ein Profilfoto hoch, welches in Ihrem Profil angezeigt
          wird.
        </span>
      </label>
      <div class="formular-row-input-image-upload">
        <div
          (click)="openProfilePictureDialog()"
          class="uploaded-image-container">
          <img
            #userIconImage
            [attr.src]="
              personalDataForm.get('profilePicture').value ??
              '../../../../../assets/img/user.jpg'
            "
            id="user-icon-image"
            class="uploaded-image"
            alt="Dein Profilbild"
            matTooltip="Dein Profilbild" />
        </div>
        <app-upload-area-dnd
          [showFileList]="false"
          [allowedFileTypes]="allowedFileTypes"
          (change)="imageChangeEvent($event)"
          context="profilePictureUpload"
          class="image-upload-area"
          inputId="profilePictureUpload">
        </app-upload-area-dnd>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Education Type -->
    <div *ngIf="user?.roleId === Role.STUDENT" class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Aus- oder Weiterbildungsgang </span>
        <span class="label-description">
          Ihr Aus- oder Weiterbildungsgang.
        </span>
      </div>
      <div class="formular-row-input education-course">
        <i class="fa-light fa-lock"></i>
        <span>
          {{
            user?.educationCourse?.title
              ? user?.educationCourse?.title
              : 'Noch nicht hinterlegt.'
          }}
        </span>
      </div>
    </div>
    <mat-divider *ngIf="user?.roleId === Role.STUDENT"></mat-divider>

    <!-- Measles Protection -->
    <!-- <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Masernstatus</span>
        <span class="label-description"> Impfschutz für Masern </span>
      </div>
      <div
        class="formular-row-input measles-protection"
        *ngIf="
          userService.currentUserIsStudent() ||
            userService.currentUserIsLecturer();
          else measlesToggle
        ">
        <i class="fa-light fa-lock"></i>
        <span>
          {{ user?.measlesProtection ? 'Geprüft' : 'Nicht geprüft' }}
        </span>
      </div>
      <ng-template #measlesToggle>
        <div class="formular-row-input measles-protection">
          <mat-slide-toggle
            hideIcon
            (change)="onMeaslesProtectionChanged($event)"
            [checked]="personalDataForm.get('measlesProtection').value">
          </mat-slide-toggle>
          <span class="hasomed-text-small">
            {{
              personalDataForm.get('measlesProtection').value
                ? 'Geprüft'
                : 'Nicht geprüft'
            }}
          </span>
        </div>
      </ng-template>
    </div>
    <mat-divider></mat-divider> -->

    <!-- Address -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Adresse</span>
        <span class="label-description">
          Geben Sie hier ihre Anschrift ein.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input-columns">
          <div class="formular-row-input">
            <input
              id="street"
              class="hasomed-text-input"
              type="text"
              size="15"
              formControlName="street"
              placeholder="Straße" />
            <input
              id="houseNumber"
              class="hasomed-text-input"
              type="text"
              maxlength="5"
              size="5"
              formControlName="houseNumber"
              placeholder="Hausnummer" />
          </div>
          <div class="formular-row-input">
            <input
              id="addressAddition"
              class="hasomed-text-input"
              type="text"
              size="10"
              formControlName="addressAddition"
              placeholder="Adresszusatz (Etage, Appartment)" />
          </div>
          <div class="formular-row-input">
            <input
              id="zipCode"
              class="hasomed-text-input"
              type="text"
              size="5"
              formControlName="zipCode"
              placeholder="PLZ" />
            <input
              id="city"
              class="hasomed-text-input"
              type="text"
              size="15"
              formControlName="city"
              placeholder="Ort" />
          </div>
          <div class="formular-row-input">
            <country-select
              class="flex-1"
              [control]="countryControl"></country-select>
          </div>
        </div>
        <app-error-message
          [formField]="personalDataForm.get('street')"></app-error-message>
        <app-error-message
          [formField]="personalDataForm.get('houseNumber')"></app-error-message>
        <app-error-message
          [formField]="
            personalDataForm.get('addressAddition')
          "></app-error-message>
        <app-error-message
          [formField]="personalDataForm.get('zipCode')"></app-error-message>
        <app-error-message
          [formField]="personalDataForm.get('city')"></app-error-message>
        <app-error-message
          [formField]="personalDataForm.get('country')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Document -->
    <div class="formular-row">
      <label class="formular-row-label" for="profileDocuments">
        <span class="label-title">Dokumentenupload</span>
        <span class="label-description">
          Laden Sie hier Ihre wichtigen Dokumente für die Aus- und Weiterbildung
          hoch.
        </span>
      </label>
      <div class="formular-row-input">
        <div class="flex-grow">
          <app-upload-area-dnd
            [existingFiles]="existingFiles"
            [showFileList]="true"
            [multiple]="true"
            [allowedFileTypes]="allowedFileTypesDocuments"
            (openExistingFile)="onOpenExistingFile($event)"
            (downloadExistingFile)="onDownloadExistingFile($event)"
            (deleteExistingFile)="onDeleteExistingFile($event)"
            context="profileDocumentUpload"
            inputId="profileDocuments"
            formControlName="uploadedFiles">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>
  </div>
</form>
