<app-event-room-planning
  *ngIf="roomPlanningOpen"
  title="Raumplanung"
  subtitle="Planen Sie die Räume des Patiententermins"
  [participants]="2"
  [eventDates]="eventDates"
  (onRoomPlanningClose)="roomPlanningChanged($event)">
</app-event-room-planning>

<form
  [class.hide]="roomPlanningOpen"
  [formGroup]="patientAppointmentForm"
  (ngSubmit)="onSubmit()"
  class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{
          editMode
            ? 'Patiententermin bearbeiten'
            : 'Neuen Patiententermin erstellen'
        }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie den Patiententermin.'
            : 'Fügen Sie einen neuen Patiententermin hinzu.'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>
      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>
  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <div *ngIf="!isLoading" class="main-content-form">
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Behandlungsfall
          <span
            *ngIf="
              isRequired(
                patientAppointmentForm.get('patientAppointmentTreatmentCaseId')
              )
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Wählen Sie einen Behandlungsfall, der in der geplanten Sitzung
          bearbeitet wird.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select">
            <mat-select
              id="patientAppointmentTreatmentCaseId"
              formControlName="patientAppointmentTreatmentCaseId">
              <mat-option
                *ngFor="let availableTreatmentCase of availableTreatmentCases"
                [value]="availableTreatmentCase?.id"
                class="hasomed-text-body">
                Patient: Chiffre ({{ availableTreatmentCase?.patientChiffre }})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            patientAppointmentForm.get('patientAppointmentTreatmentCaseId')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Begleitpersonen
          <span
            *ngIf="
              isRequired(patientAppointmentForm.get('accompanyingPersonsIds'))
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Wählen Sie eine oder mehrere Begleitpersonen aus, die an diesem Termin
          teilnhemen.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select">
            <mat-select
              id="accompanyingPersonsIds"
              formControlName="accompanyingPersonsIds"
              multiple
              [placeholder]="
                availableAccompanyingPersons?.length > 0
                  ? 'Begleitpersonen auswählen'
                  : patientAppointmentForm.get(
                        'patientAppointmentTreatmentCaseId'
                      ).value
                    ? 'Keine Begleitpersonen im Behandlungsfall hinterlegt'
                    : ''
              ">
              <app-select-check-all
                [control]="accompanyingPersonsIdsFormControl"
                [values]="
                  availableAccompanyingPersonsIds
                "></app-select-check-all>

              <mat-option
                *ngFor="
                  let availableAccompanyingPerson of availableAccompanyingPersons
                "
                [value]="availableAccompanyingPerson?.id"
                [disabled]="
                  patientAppointmentForm
                    .get('accompanyingPersonsIds')
                    .value.includes(null)
                "
                class="hasomed-text-body">
                {{ availableAccompanyingPerson?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            patientAppointmentForm.get('accompanyingPersonsIds')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Dauer des Termins in Zeiteinheiten
          <span
            *ngIf="
              isRequired(patientAppointmentForm.get('durationInTimeUnits'))
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Eine Einheit entspricht 50 Minuten.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select">
            <mat-select
              id="durationInTimeUnits"
              formControlName="durationInTimeUnits">
              <mat-option
                *ngFor="let duration of [1, 2, 3, 4, 5]"
                [value]="duration"
                class="hasomed-text-body">
                {{ duration }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            patientAppointmentForm.get('durationInTimeUnits')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Datum des Patiententermins </span>
        <span class="label-description">
          Definieren Sie zunächst den Gesamtzeitraum für den Patiententermin und
          konfigurieren Sie anschließend alle individuellen Termine.
        </span>
      </div>

      <div class="hasomed-input-field">
        <app-event-picker
          [events]="eventDates"
          [duration]="
            +patientAppointmentForm.get('durationInTimeUnits').value * 50
          "
          [startDate]="startDate"
          [endDate]="endDate"
          [allDayEvent]="allDayEvent"
          [recurrencePattern]="recurrencePattern"
          [parentFormSubmitValidation]="formSubmitted"
          (eventsChanged)="onEventsChanged($event)"
          (recurrencePatternChanged)="recurrencePattern = $event"
          (datesChanged)="onDatesChanged($event)"
          class="formular-row-input"
          formControlName="dateGroup"
          id="dateGroup"></app-event-picker>
        <app-error-message
          [formField]="
            patientAppointmentForm.get('dateGroup')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="roomPlanning">
        <span class="label-title">
          Ort des Patientermins
          <span *ngIf="isRequired(patientAppointmentForm.get('location'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Wählen Sie zunächst den Ort des Patiententermins und rufen Sie
          anschließend die Raumplanung auf oder/und fügen Sie eine URL für ein
          Online-Meeting hinzu.
        </span>
      </label>

      <div class="formular-row-input-columns">
        <div class="flex-r gap-16">
          <button
            *ngFor="
              let eventLocation of EventLocation
                | keyvalue: originalEventLocationOrder
            "
            [ngClass]="{
              'hasomed-button-primary':
                patientAppointmentForm.get('location').value ===
                eventLocation.value,
              'hasomed-button-white-grey':
                patientAppointmentForm.get('location').value !=
                eventLocation.value,
            }"
            (click)="setEventLocation(eventLocation.value)"
            type="button"
            class="hasomed-text-button">
            <span class="hasomed-button-text">
              {{ eventLocation.value }}
            </span>
          </button>
        </div>

        <button
          [disabled]="roomPlanningDisabled"
          (click)="openRoomPlanning()"
          id="roomPlanning"
          type="button"
          class="hasomed-text-button hasomed-button-primary button-full-width">
          <i
            class="fa-light fa-arrow-up-right-from-square hasomed-button-icon"></i>
          <span class="hasomed-button-text"> Raumplanung </span>
        </button>

        <!-- LINK -->
        <div *ngIf="showLink" class="hasomed-input-field">
          <div class="formular-row-input">
            <label class="formular-row-label link-label" for="videoMeetingLink">
              <span class="label-title">
                URL für Video-Meeting
                <span
                  *ngIf="
                    isRequired(patientAppointmentForm.get('videoMeetingLink'))
                  "
                  >*</span
                >
              </span>
            </label>
            <input
              id="videoMeetingLink"
              class="hasomed-text-input"
              type="text"
              formControlName="videoMeetingLink"
              placeholder="URL für Video-Meeting" />
          </div>
          <app-error-message
            [formField]="
              patientAppointmentForm.get('videoMeetingLink')
            "></app-error-message>
        </div>
      </div>
    </div>
  </div>
</form>
