import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import * as moment from 'moment-timezone';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import { Role } from '../models/permission.model';
import {
  AdditionalQualificationEnum,
  UserCreateModel,
  UserModel,
  UserUpdateModel,
  UserUpdatePermissionModel,
} from '../models/user.model';
import { maskIban, prettyPrintIban } from '../utils/user.utils';
import { CancellationService } from './cancellation.service';
import { DecryptionService } from './decryption.service';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private cryptoService: CryptoService,
    private fileService: FileService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  public currentUser: UserModel;
  private encryptedProfilePicture: string;

  /**
   * stores the encrypted profilePicture and updates the currentUser without the profilePicture
   * @param user
   * @returns void
   */
  public setEncryptedCurrentUser(user: UserModel): void {
    this.encryptedProfilePicture = user.profilePicture?.toString();
    user.profilePicture = null;
    this.currentUser = user;
  }

  /**
   * sets the decrypted currentUser
   * @returns Promise<void>
   */
  public async setDecryptedCurrentUser(): Promise<void> {
    this.currentUser = await this.parseBackendUser(this.currentUser);
    const decryptedProfilePicture = await this.decryptionService.decryptString(
      this.encryptedProfilePicture
    );
    this.currentUser.profilePicture = decryptedProfilePicture;
  }

  /**
   * Updates user information
   * @param userUpdateModel
   * @returns Observable
   */
  public async updateUser(
    userId: number,
    userUpdateModel: UserUpdateModel
  ): Promise<Observable<HttpResponse<any>>> {
    // only encrypt data if the user has an institute
    if (this.currentUser?.currentInstituteId !== null) {
      userUpdateModel.profilePicture = userUpdateModel.profilePicture
        ? await this.cryptoService.encrypt(userUpdateModel.profilePicture)
        : null;

      userUpdateModel.bankAccount = {
        accountHolder: userUpdateModel.bankAccount?.accountHolder
          ? await this.cryptoService.encrypt(
              userUpdateModel.bankAccount.accountHolder
            )
          : null,
        // remove spaces from iban
        iban: userUpdateModel.bankAccount?.iban
          ? await this.cryptoService.encrypt(
              userUpdateModel.bankAccount.iban.replace(/\s/g, '')
            )
          : null,
        bic: userUpdateModel.bankAccount?.bic
          ? await this.cryptoService.encrypt(userUpdateModel.bankAccount.bic)
          : null,
      };
      return this.http.put(
        this.config.backendUrl +
          `/api/institutes/${this.currentUser?.currentInstituteId}/users/${userId}`,
        userUpdateModel,
        { observe: 'response', responseType: 'json' }
      );
    } else {
      // prevent the user from storing unencrypted bank account data
      if (userUpdateModel.bankAccount !== null) {
        userUpdateModel.bankAccount = null;
      }
      return this.http.put(
        this.config.backendUrl + `/api/users/${userId}`,
        userUpdateModel,
        { observe: 'response', responseType: 'json' }
      );
    }
  }

  /**
   * getUserInvoices
   * gets all invoices of a user
   * @param id_user
   * @returns Observable
   */
  public getUserInvoices(id_user: number): Observable<any> {
    return this.http
      .get(
        this.config.backendUrl +
          '/user/getUserInvoices.php?id_user=' +
          id_user.toString()
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * updateCurrentUser
   * @param userData
   */
  public async updateCurrentUser(userData: UserModel) {
    //
    this.currentUser = await this.parseBackendUser(userData);
  }

  /**
   * switchUsersInstitute
   * switches the institute of the current user
   * @param instituteId
   */
  public switchUsersInstitute(instituteId: number): Observable<any> {
    return this.http.put(
      this.config.backendUrl +
        `/api/users/switch-institute?instituteId=${instituteId}`,
      {
        responseType: 'json',
        observe: 'response',
      }
    );
  }

  /**
   * getInstituteUserById
   * gets a user by the given id
   * @param userId
   * @param expands
   * @returns
   */
  public getInstituteUserById(
    userId: number,
    onlyBaseData?: boolean
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.currentUser?.currentInstituteId}/users/${userId}` +
          (onlyBaseData ? '?onlyBase=true' : ''),
        {
          responseType: 'json',
          observe: 'response',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getCurrentUser
   * gets the current user
   * @returns
   */
  public getCurrentUser(): Observable<HttpResponse<any>> {
    return this.http
      .get(this.config.backendUrl + '/api/users/current-user', {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * deleteInstituteUser
   * deletes a user from an institute
   * @param instituteId
   * @param userId
   * @returns
   */
  public deleteInstituteUser(
    instituteId: number,
    userId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .delete(
        this.config.backendUrl +
          `/api/institutes/${instituteId}/users/${userId}`,
        {
          responseType: 'json',
          observe: 'response',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createInstituteUser
   * creates a user for an institute
   * @param instituteId
   * @param userCreateModel
   * @returns
   */
  public createInstituteUser(
    instituteId: number,
    userCreateModel: UserCreateModel
  ): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      this.config.backendUrl + `/api/institutes/${instituteId}/users/`,
      userCreateModel,
      { headers: headers, observe: 'response', responseType: 'json' }
    );
  }

  /**
   * getAllUsersByInstitute
   * gets all users of the current institute
   * @returns Observable<HttpResponse<any>>
   */
  public getAllUsersByInstitute(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.currentUser?.currentInstituteId}/users`,
        {
          responseType: 'json',
          observe: 'response',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getInstituteUsersByRole
   * gets all users of an institute by the given role
   * @param roleId
   * @param profilePicture
   * @param expands
   * @returns Observable<HttpResponse<any>>
   */
  public getInstituteUsersByRole(
    roleId: Role,
    profilePicture: boolean,
    expands?: string
  ): Observable<HttpResponse<any>> {
    // todo: add educationCourse and profilePicture as params
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.currentUser?.currentInstituteId}/users/roles/${roleId}`,
        {
          responseType: 'json',
          observe: 'response',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getInstituteUsersWithAdditionalQualifications
   * gets all users of an institute with additional qualifications
   * @param roleId
   * @returns Observable<HttpResponse<any>>
   */
  public getInstituteUsersWithAdditionalQualifications(): Observable<
    HttpResponse<any>
  > {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.currentUser?.currentInstituteId}/users/qualifications`,
        {
          responseType: 'json',
          observe: 'response',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getInstituteUsersWithAdditionalQualifications
   * gets all users of an institute with the additional qualification
   * @param qualificationIds AdditionalQualificationEnum[]
   * @returns Observable<HttpResponse<any>>
   */
  public getInstituteUsersByAdditionalQualifications(
    qualificationIds: AdditionalQualificationEnum[]
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.currentUser?.currentInstituteId}/users/by-qualifications?qualificationIds=${qualificationIds}`,
        {
          responseType: 'json',
          observe: 'response',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * switchUsersRole
   * switches the role of a user
   * @param userId
   * @param roleId
   * @returns Observable<HttpResponse<any>>
   */
  public switchUsersRole(
    userId: number,
    roleId: number
  ): Observable<HttpResponse<any>> {
    return this.http.patch(
      this.config.backendUrl +
        `/api/institutes/${this.currentUser?.currentInstituteId}/users/${userId}/switch-role`,
      { roleId },
      {
        responseType: 'json',
        observe: 'response',
      }
    );
  }

  /**
   * getAllUsersWithoutInstitute
   * gets all users without an institute
   * @returns Observable<HttpResponse<any>>
   */
  public getAllUsersWithoutInstitute(): Observable<HttpResponse<any>> {
    return this.http
      .get(this.config.backendUrl + '/api/users/without-institute', {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * updateUserInstituteAndPermissions
   * Updates the institute and permissions of a user
   * @param userUpdateModel
   * @returns Observable<HttpResponse<any>>
   */
  public updateUserInstituteAndPermissions(
    userId: number,
    userUpdateModel: UserUpdatePermissionModel
  ): Observable<HttpResponse<any>> {
    return this.http.put(
      this.config.backendUrl + `/api/users/${userId}/permissions`,
      userUpdateModel,
      { observe: 'response', responseType: 'json' }
    );
  }

  /**
   * deleteUser
   * deletes a user by the given id
   * @param userId
   * @returns Observable<HttpResponse<any>>
   */
  public deleteUser(userId: number): Observable<HttpResponse<any>> {
    return this.http.delete(this.config.backendUrl + `/api/users/${userId}`, {
      observe: 'response',
      responseType: 'json',
    });
  }

  /**
   * createUserIdentifier
   * creates a user identifiert for the given user
   * @param user
   * @returns The user identifier
   */
  public createUserIdentifier(user: UserModel): string {
    let userIdentifier = null;
    if (user.userIdentifier) {
      return user.userIdentifier;
    }
    if (user.name.firstName && user.name.lastName && user.birthdate) {
      if (user.educationCourse?.id && user.roleId === Role.STUDENT) {
        userIdentifier =
          user.name.firstName.charAt(0) +
          user.name.lastName.charAt(0) +
          moment(user.birthdate).format('DDMMYYYY') +
          user.educationCourse.id +
          user.id;
      } else if (user.roleId !== Role.STUDENT) {
        userIdentifier =
          user.name.firstName.charAt(0) +
          user.name.lastName.charAt(0) +
          moment(user.birthdate).format('DDMMYYYY') +
          user.id;
      }
    }
    return userIdentifier;
  }

  /**
   * openFile
   * get file from backend and open it in a new tab
   * @param userId
   * @param fileId
   * @returns void
   */
  public openFile(userId: number, fileId: number): void {
    this.fileService.openFile(
      `/api/institutes/${this.currentUser?.currentInstituteId}/users/${userId}/files/${fileId}`
    );
  }

  /**
   * downloadFile
   * downloads a file from the backend
   * @param userId number
   * @param fileId number
   * @returns void
   */
  public downloadFile(userId: number, fileId: number): void {
    this.fileService.downloadFile(
      `/api/institutes/${this.currentUser?.currentInstituteId}/users/${userId}/files/${fileId}`
    );
  }

  /**
   * parseBackendUser
   * parses the user data from the backend and decrypts the encrypted data
   * @param userData
   * @returns User
   */
  public async parseBackendUser(userData: UserModel): Promise<UserModel> {
    //
    return {
      id: userData.id,
      externalId: userData.externalId,
      currentInstituteId: userData.currentInstituteId,
      email: userData.email,
      roleId: userData.roleId,
      isSuperadmin: Boolean(userData.isSuperadmin),
      name: userData.name && {
        academicTitle: userData.name.academicTitle,
        genderTitle: userData.name.genderTitle,
        firstName: await this.decryptionService.decryptString(
          userData.name.firstName
        ),
        lastName: await this.decryptionService.decryptString(
          userData.name.lastName
        ),
      },
      birthdate: userData.birthdate
        ? moment(userData.birthdate, 'YYYY-MM-DD').toDate()
        : null,
      profilePicture:
        userData.profilePicture && typeof userData.profilePicture === 'string'
          ? await this.decryptionService.decryptString(userData.profilePicture)
          : null,
      educationCourse: userData.educationCourse,
      courses: userData.courses,
      userIdentifier: userData.userIdentifier,
      entryDate: userData.entryDate
        ? moment(userData.entryDate, 'YYYY-MM-DD').toDate()
        : null,
      address: userData.address && {
        street: await this.decryptionService.decryptString(
          userData.address.street
        ),
        houseNumber: await this.decryptionService.decryptString(
          userData.address.houseNumber
        ),
        addressAddition: await this.decryptionService.decryptString(
          userData.address.addressAddition
        ),
        zipCode: await this.decryptionService.decryptString(
          userData.address.zipCode
        ),
        city: await this.decryptionService.decryptString(userData.address.city),
        country: await this.decryptionService.decryptString(
          userData.address.country
        ),
      },
      bankAccount: {
        iban:
          userData.bankAccount?.iban &&
          prettyPrintIban(
            await this.decryptionService.decryptString(
              userData.bankAccount?.iban
            )
          ),
        maskedIban:
          userData.bankAccount?.iban &&
          prettyPrintIban(
            maskIban(
              await this.decryptionService.decryptString(
                userData.bankAccount?.iban
              )
            )
          ),
        bic:
          userData.bankAccount?.bic &&
          (await this.decryptionService.decryptString(
            userData.bankAccount?.bic
          )),
        accountHolder:
          userData.bankAccount?.accountHolder &&
          (await this.decryptionService.decryptString(
            userData.bankAccount?.accountHolder
          )),
      },
      label: userData.label,
      isInvited: Boolean(userData.isInvited),
      measlesProtection: Boolean(userData.measlesProtection),
      additionalQualifications: userData.additionalQualifications,
      timeCreated: userData.timeCreated
        ? moment(userData.timeCreated).tz('Europe/Berlin').toDate()
        : null,
      timeLastLogin: userData.timeLastLogin
        ? moment(userData.timeLastLogin).tz('Europe/Berlin').toDate()
        : null,
      files: await this.fileService.parseBackendFiles(userData.files),
      medicalAuthorizationOnly: userData.medicalAuthorizationOnly,
      professionalAssociation: userData.professionalAssociation,
      expertises: userData.expertises,
      procedures: userData.procedures,
      ptkConsultation: userData.ptkConsultation,
      ptkConsultationDate: userData.ptkConsultationDate
        ? moment(userData.ptkConsultationDate, 'YYYY-MM-DD').toDate()
        : null,
      confirmed: userData.confirmed,
      confirmationDate: userData.confirmationDate
        ? moment(userData.confirmationDate, 'YYYY-MM-DD').toDate()
        : null,
      landlineNumber: userData.landlineNumber,
      mobileNumber: userData.mobileNumber,
      educationalProgress: userData.educationalProgress,
      isPrimaryAdmin: Boolean(userData.isPrimaryAdmin),
      encryptionKeysGenerated: Boolean(userData.encryptionKeysGenerated),
      isConfirmedByAdmin: Boolean(userData.isConfirmedByAdmin),
      confirmingAdminIdpId: userData.confirmingAdminIdpId,
      isInitialEducationStockSet: Boolean(userData.isInitialEducationStockSet),
    };
  }

  /**
   * currentUserIsStudent
   * checks if the current user is a student
   * @returns boolean
   */
  public currentUserIsStudent(): boolean {
    return this.currentUser?.roleId === Role.STUDENT;
  }

  /**
   * currentUserisAdministrator
   * checks if the current user is an administrator
   * @returns boolean
   */
  public currentUserIsAdministrator(): boolean {
    return this.currentUser?.roleId === Role.ADMINISTRATOR;
  }

  /**
   * currentUserIsSuperadmin
   * checks if the current user is a superadmin
   * @returns boolean
   */
  public currentUserIsSuperadmin(): boolean {
    return this.currentUser?.isSuperadmin === true;
  }

  /**
   * currentUserIsLecturer
   * checks if the current user is a lecturer
   * @returns boolean
   */
  public currentUserIsLecturer(): boolean {
    return this.currentUser?.roleId === Role.LECTURER;
  }

  /**
   * inviteUser
   * invites a user with the given data
   * @param user
   * @returns
   */
  public inviteInstituteUser(
    instituteId: number,
    userCreateModel: UserCreateModel
  ): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      this.config.backendUrl +
        `/api/institutes/${instituteId}/users/invite-user`,
      userCreateModel,
      { headers: headers, observe: 'response', responseType: 'json' }
    );
  }

  /**
   * getUserAdditionalQualifications
   * gets all additional qualifications
   * @returns Observable<HttpResponse<any>>
   */
  public getUserAdditionalQualifications(): Observable<HttpResponse<any>> {
    return this.http
      .get(this.config.backendUrl + '/api/additional-qualifications', {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Calls the backend to get the amount of unconfirmed users
   * @returns An observable with a http response containing the amount of unconfirmed users as a number
   */
  public getAmountOfUnconfirmedUsers(): Observable<HttpResponse<number>> {
    return this.http.get<number>(
      this.config.backendUrl +
        `/api/institutes/${this.currentUser?.currentInstituteId}/users/unconfirmed-users`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Calls the backend to mark the current users keys as generated
   * @returns An observable with a http response
   */
  public markKeysAsGenerated(): Observable<HttpResponse<any>> {
    return this.http.patch(
      this.config.backendUrl +
        `/api/institutes/${this.currentUser?.currentInstituteId}/users/${this.currentUser.id}/mark-keys-as-generated`,
      {},
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Calls the backend to mark the given user as confirmed
   * @param userId The id of the user to confirm
   * @param confirmingAdminIdpId The idp id of the admin confirming the user
   * @returns An observable with a http response
   */
  public confirmUserRegistration(
    userId: number,
    confirmingAdminIdpId: string
  ): Observable<HttpResponse<any>> {
    return this.http.patch(
      this.config.backendUrl +
        `/api/institutes/${this.currentUser?.currentInstituteId}/users/${userId}/confirm-registration`,
      { confirmingAdminIdpId: confirmingAdminIdpId },
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }
}
