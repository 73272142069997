<form
  [formGroup]="initialEducationStockForm"
  (ngSubmit)="onSubmit()"
  class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{
          isCurrentUserAdmin
            ? 'Bereits absolvierte Stunden dieses Kandidaten'
            : 'Bereits absolvierte Stunden für Ihre Ausbildung'
        }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          isCurrentUserAdmin
            ? 'Bearbeitung aller Ausbildungsstunden außerhalb von EleGuide.'
            : editMode
              ? 'Bearbeitung aller Ausbildungsstunden außerhalb von EleGuide.'
              : 'Fügen Sie einen neuen Eintrag hinzu.'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>

      <!-- Submit button -->
      <button type="submit" class="hasomed-text-button hasomed-button-orange">
        <i class="fa-light fa-floppy-disk hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <!-- LOADING WRAPPER -->
  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>

  <div *ngIf="!isLoading" class="main-content-form">
    <!-- Theoretical education duration hours -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Dauer der Ausbildungsstunden
          <span
            *ngIf="
              isRequired(
                initialEducationStockForm.get(
                  'theoreticalEducationDurationHours'
                )
              )
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Gesamtdauer der schon absolvierten Theoretischen Ausbildung in
          Stunden.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="theoreticalEducationDurationHours"
            class="hasomed-text-input"
            min="0"
            step="0.5"
            type="number"
            formControlName="theoreticalEducationDurationHours"
            placeholder="Dauer" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            initialEducationStockForm.get('theoreticalEducationDurationHours')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Theoretical education date range -->
    <div class="formular-row">
      <div class="formular-row-label flex-1 align-center">
        <span class="label-title">
          Zeitraum
          <span
            *ngIf="
              isRequired(
                initialEducationStockForm.get('theoreticalEducationDateRange')
              )
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Start- und Enddatum in dem die Theoretische Ausbildung bisher
          ausgeführt wurde.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div
            [class.error-border]="
              initialEducationStockForm.get('theoreticalEducationDateRange')
                .invalid &&
              initialEducationStockForm.get('theoreticalEducationDateRange')
                .touched
            "
            class="hasomed-date-range">
            <mat-date-range-input
              [rangePicker]="theoreticalEducationPicker"
              class="hasomed-date-range-input"
              formGroupName="theoreticalEducationDateRange">
              <input
                id="theoreticalEducationStartDate"
                matStartDate
                placeholder="Startdatum"
                formControlName="theoreticalEducationStartDate" />
              <input
                id="theoreticalEducationEndDate"
                matEndDate
                placeholder="Enddatum"
                formControlName="theoreticalEducationEndDate" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              [for]="theoreticalEducationPicker"
              class="hasomed-date-range-toggle"
              matIconSuffix>
              <mat-icon
                matDatepickerToggleIcon
                class="calendar-toggle-icon fa-light fa-calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker
              #theoreticalEducationPicker
              [restoreFocus]="false"></mat-date-range-picker>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          *ngIf="
            !initialEducationStockForm
              .get('theoreticalEducationDateRange')
              .get('theoreticalEducationEndDate').invalid
          "
          [formField]="
            initialEducationStockForm
              .get('theoreticalEducationDateRange')
              .get('theoreticalEducationStartDate')
          "></app-error-message>
        <app-error-message
          [formField]="
            initialEducationStockForm
              .get('theoreticalEducationDateRange')
              .get('theoreticalEducationEndDate')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Initial practical work stocks -->
    <ng-container formArrayName="initialPracticalWorkStocks">
      <ng-container
        *ngFor="
          let practicalWork of initialPracticalWorkStocks.controls;
          let i = index
        "
        [formGroupName]="i">
        <!-- Practical work type -->
        <div class="formular-row">
          <div class="formular-row-label">
            <span class="label-title">
              Praktische Tätigkeit
              <span *ngIf="isRequired(practicalWork.get('type'))">*</span>
            </span>
            <span class="label-description">
              Um welche Art der praktischen Tätigkeit handelt es sich?
            </span>
          </div>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <div class="flex-r gap-16 flex-1 type-remove-container">
                <eleguide-select
                  [control]="getPracticalWorkTypeControl(i)"
                  controlId="type"
                  [options]="practicalWotkTypeSelectOptions"
                  placeholder="Wählen Sie Ihre Praktische Tätigkeit"></eleguide-select>
                <button
                  *ngIf="initialPracticalWorkStocks.length > 1 && i !== 0"
                  (click)="removePracticalWorkStock(i)"
                  type="button"
                  class="hasomed-text-button hasomed-button-white-grey">
                  <i class="hasomed-button-icon-sm fa-light fa-trash-can"></i>
                </button>
              </div>
            </div>
            <!-- Error Messages -->
            <app-error-message
              [formField]="practicalWork.get('type')"></app-error-message>
          </div>
        </div>

        <mat-divider></mat-divider>

        <!-- Cooperation partner -->
        <div class="formular-row">
          <div class="formular-row-label">
            <span class="label-title">
              Kooperationspartner
              <span
                *ngIf="isRequired(practicalWork.get('cooperationPartnerId'))"
                >*</span
              >
            </span>
            <span class="label-description">
              Wählen Sie einen Kooperationspartner.
            </span>
          </div>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <eleguide-select
                [control]="getCooperationPartnerControl(i)"
                controlId="cooperationPartnerId"
                [options]="availableCooperationPartners"
                [placeholder]="
                  availableCooperationPartners.length === 0
                    ? 'Keine Kooperationspartner vorhanden'
                    : 'Wählen Sie einen Kooperationspartner'
                "></eleguide-select>
            </div>
            <!-- Error Messages -->
            <app-error-message
              [formField]="
                practicalWork.get('cooperationPartnerId')
              "></app-error-message>
          </div>
        </div>

        <mat-divider></mat-divider>

        <!-- Duration practical work hours -->
        <div class="formular-row">
          <div class="formular-row-label">
            <span class="label-title">
              Dauer der Tätigkeit
              <span *ngIf="isRequired(practicalWork.get('durationHours'))"
                >*</span
              >
            </span>
            <span class="label-description">
              Gesamtdauer der praktischen Tätigkeit in Stunden.
            </span>
          </div>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <input
                id="durationHours"
                class="hasomed-text-input"
                min="0"
                step="0.5"
                type="number"
                formControlName="durationHours"
                placeholder="Dauer" />
            </div>
            <!-- Error Messages -->
            <app-error-message
              [formField]="
                practicalWork.get('durationHours')
              "></app-error-message>
          </div>
        </div>

        <mat-divider></mat-divider>

        <!-- Practical work date range -->
        <div class="formular-row">
          <div class="formular-row-label flex-1 align-center">
            <span class="label-title">
              Zeitraum
              <span *ngIf="isRequired(practicalWork.get('dateRange'))">*</span>
            </span>
            <span class="label-description">
              Start- und Enddatum der praktischen Tätigkeit.
            </span>
          </div>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <div
                [class.error-border]="
                  practicalWork.get('dateRange').invalid &&
                  practicalWork.get('dateRange').touched
                "
                class="hasomed-date-range">
                <mat-date-range-input
                  [rangePicker]="practicalWorkPicker"
                  class="hasomed-date-range-input"
                  formGroupName="dateRange">
                  <input
                    id="startDate"
                    matStartDate
                    placeholder="Startdatum"
                    formControlName="startDate" />
                  <input
                    id="endDate"
                    matEndDate
                    placeholder="Enddatum"
                    formControlName="endDate" />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  [for]="practicalWorkPicker"
                  class="hasomed-date-range-toggle"
                  matIconSuffix>
                  <mat-icon
                    matDatepickerToggleIcon
                    class="calendar-toggle-icon fa-light fa-calendar">
                  </mat-icon>
                </mat-datepicker-toggle>
                <mat-date-range-picker
                  #practicalWorkPicker
                  [restoreFocus]="false"></mat-date-range-picker>
              </div>
            </div>
            <!-- Error Messages -->
            <app-error-message
              *ngIf="!practicalWork.get('dateRange').get('endDate').invalid"
              [formField]="
                practicalWork.get('dateRange').get('startDate')
              "></app-error-message>
            <app-error-message
              [formField]="
                practicalWork.get('dateRange').get('endDate')
              "></app-error-message>
          </div>
        </div>

        <mat-divider></mat-divider>
      </ng-container>
    </ng-container>

    <!-- Add practical work stock button -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Weitere Praktische Tätigkeiten </span>
        <span class="label-description">
          Fügen Sie weitere Tätigkeiten hinzu.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <button
            (click)="addPracticalWorkStock()"
            type="button"
            class="hasomed-text-button hasomed-button-white-grey">
            <i class="fa-light fa-plus hasomed-button-icon-sm"></i>
            <span class="hasomed-button-text"> weitere hinzufügen </span>
          </button>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Duration practical education hours -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Dauer der Patientenbehandlung
          <span
            *ngIf="
              isRequired(
                initialEducationStockForm.get('patientTreatmentDurationHours')
              )
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Gesamtdauer der Patientenbehandlungen in Stunden.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="patientTreatmentDurationHours"
            class="hasomed-text-input"
            min="0"
            step="0.5"
            type="number"
            formControlName="patientTreatmentDurationHours"
            placeholder="Dauer" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            initialEducationStockForm.get('patientTreatmentDurationHours')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Completed patient treatments -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Anzahl der abgeschlossenen Patientenbehandlungen
          <span
            *ngIf="
              isRequired(
                initialEducationStockForm.get('completedPatientTreatments')
              )
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Gesamtheit aller abgeschlossenen Patientenbehandlungen.
          <br />
          <br />
          Offene Fälle bitte über Ambulanten Teil nachtragen und pflegen.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="completedPatientTreatments"
            class="hasomed-text-input"
            min="0"
            step="1"
            type="number"
            formControlName="completedPatientTreatments"
            placeholder="Anzahl" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            initialEducationStockForm.get('completedPatientTreatments')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Patient treatment date range -->
    <div class="formular-row">
      <div class="formular-row-label flex-1 align-center">
        <span class="label-title">
          Zeitraum
          <span
            *ngIf="
              isRequired(
                initialEducationStockForm.get('patientTreatmentDateRange')
              )
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Start- und Enddatum der gesamten Patientenbehanldungen.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div
            [class.error-border]="
              initialEducationStockForm.get('patientTreatmentDateRange')
                .invalid &&
              initialEducationStockForm.get('patientTreatmentDateRange').touched
            "
            class="hasomed-date-range">
            <mat-date-range-input
              [rangePicker]="patientTreatmentpicker"
              class="hasomed-date-range-input"
              formGroupName="patientTreatmentDateRange">
              <input
                id="patientTreatmentStartDate"
                matStartDate
                placeholder="Startdatum"
                formControlName="patientTreatmentStartDate" />
              <input
                id="patientTreatmentEndDate"
                matEndDate
                placeholder="Enddatum"
                formControlName="patientTreatmentEndDate" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              [for]="patientTreatmentpicker"
              class="hasomed-date-range-toggle"
              matIconSuffix>
              <mat-icon
                matDatepickerToggleIcon
                class="calendar-toggle-icon fa-light fa-calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker
              #patientTreatmentpicker
              [restoreFocus]="false"></mat-date-range-picker>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          *ngIf="
            !initialEducationStockForm
              .get('patientTreatmentDateRange')
              .get('patientTreatmentEndDate').invalid
          "
          [formField]="
            initialEducationStockForm
              .get('patientTreatmentDateRange')
              .get('patientTreatmentStartDate')
          "></app-error-message>
        <app-error-message
          [formField]="
            initialEducationStockForm
              .get('patientTreatmentDateRange')
              .get('patientTreatmentEndDate')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Supervision duration hours -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Dauer der Supervisionen
          <span
            *ngIf="
              isRequired(
                initialEducationStockForm.get('supervisionDurationHours')
              )
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Gesamtdauer der Supervisionen aller abgeschlossenen Behandlungsfälle
          in Stunden.
          <br />
          <br />
          Bei laufenden Fälle Supervision bitte über Ambulanten Teil nachtragen
          und pflegen.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="supervisionDurationHours"
            class="hasomed-text-input"
            min="0"
            step="0.5"
            type="number"
            formControlName="supervisionDurationHours"
            placeholder="Dauer" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            initialEducationStockForm.get('supervisionDurationHours')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Supervision date range -->
    <div class="formular-row">
      <div class="formular-row-label flex-1 align-center">
        <span class="label-title">
          Zeitraum
          <span
            *ngIf="
              isRequired(initialEducationStockForm.get('supervisionDateRange'))
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Start- und Enddatum der gesamten Supervisionen.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div
            [class.error-border]="
              initialEducationStockForm.get('supervisionDateRange').invalid &&
              initialEducationStockForm.get('supervisionDateRange').touched
            "
            class="hasomed-date-range">
            <mat-date-range-input
              [rangePicker]="supervisionPicker"
              class="hasomed-date-range-input"
              formGroupName="supervisionDateRange">
              <input
                id="supervisionStartDate"
                matStartDate
                placeholder="Startdatum"
                formControlName="supervisionStartDate" />
              <input
                id="supervisionEndDate"
                matEndDate
                placeholder="Enddatum"
                formControlName="supervisionEndDate" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              [for]="supervisionPicker"
              class="hasomed-date-range-toggle"
              matIconSuffix>
              <mat-icon
                matDatepickerToggleIcon
                class="calendar-toggle-icon fa-light fa-calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker
              #supervisionPicker
              [restoreFocus]="false"></mat-date-range-picker>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          *ngIf="
            !initialEducationStockForm
              .get('supervisionDateRange')
              .get('supervisionEndDate').invalid
          "
          [formField]="
            initialEducationStockForm
              .get('supervisionDateRange')
              .get('supervisionStartDate')
          "></app-error-message>
        <app-error-message
          [formField]="
            initialEducationStockForm
              .get('supervisionDateRange')
              .get('supervisionEndDate')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Self awareness duration hours -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Dauer der Selbsterfahrung/Lehranalyse
          <span
            *ngIf="
              isRequired(
                initialEducationStockForm.get('selfAwarenessDurationHours')
              )
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Gesamtdauer der Selbsterfahrung/Lehranalyse in Stunden.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="selfAwarenessDurationHours"
            class="hasomed-text-input"
            min="0"
            step="0.5"
            type="number"
            formControlName="selfAwarenessDurationHours"
            placeholder="Dauer" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            initialEducationStockForm.get('selfAwarenessDurationHours')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Self awareness date range -->
    <div class="formular-row">
      <div class="formular-row-label flex-1 align-center">
        <span class="label-title">
          Zeitraum
          <span
            *ngIf="
              isRequired(
                initialEducationStockForm.get('selfAwarenessDateRange')
              )
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Start- und Enddatum der gesamten Selbsterfahrung/Lehranalyse.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div
            [class.error-border]="
              initialEducationStockForm.get('selfAwarenessDateRange').invalid &&
              initialEducationStockForm.get('selfAwarenessDateRange').touched
            "
            class="hasomed-date-range">
            <mat-date-range-input
              [rangePicker]="selfAwarenessPicker"
              class="hasomed-date-range-input"
              formGroupName="selfAwarenessDateRange">
              <input
                id="selfAwarenessStartDate"
                matStartDate
                placeholder="Startdatum"
                formControlName="selfAwarenessStartDate" />
              <input
                id="selfAwarenessEndDate"
                matEndDate
                placeholder="Enddatum"
                formControlName="selfAwarenessEndDate" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              [for]="selfAwarenessPicker"
              class="hasomed-date-range-toggle"
              matIconSuffix>
              <mat-icon
                matDatepickerToggleIcon
                class="calendar-toggle-icon fa-light fa-calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker
              #selfAwarenessPicker
              [restoreFocus]="false"></mat-date-range-picker>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          *ngIf="
            !initialEducationStockForm
              .get('selfAwarenessDateRange')
              .get('selfAwarenessEndDate').invalid
          "
          [formField]="
            initialEducationStockForm
              .get('selfAwarenessDateRange')
              .get('selfAwarenessStartDate')
          "></app-error-message>
        <app-error-message
          [formField]="
            initialEducationStockForm
              .get('selfAwarenessDateRange')
              .get('selfAwarenessEndDate')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title"> Dokumente </span>
        <span class="label-description">
          Laden Sie hier Ihre Bestätigungen für alle oben angeführten
          Teilbereiche Ihrer bisherigen Ausbildung hoch.
        </span>
      </label>
      <div class="formular-row-input">
        <div id="documents" class="document-upload-wrapper">
          <app-upload-area-dnd
            [existingFiles]="existingFiles"
            [showFileList]="true"
            [multiple]="true"
            [allowedFileTypes]="allowedFileTypesDocuments"
            [scrollTo]="true"
            [sensitiveDataAlert]="false"
            (openExistingFile)="onOpenExistingFile($event)"
            (downloadExistingFile)="onDownloadExistingFile($event)"
            (deleteExistingFile)="onDeleteExistingFile($event)"
            context="practicalWorkDocumentsUpload"
            inputId="documentUpload"
            formControlName="uploadedFiles"
            maxFileSize="10 MB">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>
  </div>
</form>
