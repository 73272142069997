import { CourseType } from './course.model';
import { EducationCourse } from './education-course.model';
import { Label } from './label.model';
import { RoomModel } from './room.model';

export enum FilterType {
  DATE_RANGE = 'dateRange',

  // room filter
  ROOM_FLOOR = 'roomFloor',
  ROOM_CAPACITY = 'roomCapacity',
  ROOM_ACTIVE = 'roomActive',

  // course filter
  COURSE_TYPE = 'courseType',
  LECTURER = 'lecturer',
  ROOM_NAME = 'roomName',
  REGISTERED_COURSES = 'registeredCourses',
  FINISHED_COURSES = 'finishedCourses',
  OPEN_COURSES = 'openCourses',
  PENDING_ELOGS = 'pendingElogs',

  // room organization filter
  OPEN_ROOMPLANNING = 'openRoomplanning',

  // student, lecturer, members filter
  LABEL = 'label',
  EDUCATION_COURSE = 'educationCourse',
  ADDITIONAL_QUALIFICATION = 'additionalQualification',
  ONLY_DOCTORS = 'onlyDoctors',
  EXPERTISE = 'expertise',
  PROCEDURE = 'procedure',

  // members filter
  ROLE = 'role',
  LOGON_STATE = 'logonState',

  // supervision filter
  SUPERVISION_STATUS = 'supervisionStatus',
  SUPERVISION_TYPE = 'supervisionType',
  SUPERVISION_STUDENT = 'supervisionStudent',
  SUPERVISION_SUPERVISOR = 'supervisionSupervisor',
}
export type FilterDateRange = { start: Date; end: Date };
export type FilterUser = { id: number; name: string };
export type FilterValue =
  | string
  | number
  | boolean
  | FilterDateRange
  | RoomModel
  | CourseType
  | FilterUser
  | Label
  | EducationCourse
  | null;

export interface Filter {
  type: FilterType;
  value: FilterValue;
}
