import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import { CancellationService } from './cancellation.service';

@Injectable({
  providedIn: 'root',
})
export class ProcedureService {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private cancellationService: CancellationService
  ) {}

  /**
   * getAllProcedures
   * gets all procedures
   * @returns Observable<HttpResponse<any>>
   */
  public getAllProcedures(): Observable<HttpResponse<any>> {
    return this.http
      .get(this.config.backendUrl + '/api/procedures', {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }
}
