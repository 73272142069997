import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { InstituteModel } from 'src/app/models/institute.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { getGermanWeekday } from 'src/app/utils/date.utils';
import { EmptyStateDialogComponent } from '../empty-state-dialog/empty-state-dialog.component';

@Component({
  selector: 'app-institute-overview',
  templateUrl: './institute-overview.component.html',
  styleUrl: './institute-overview.component.scss',
})
export class InstituteOverviewComponent {
  public isLoading = false;

  @Input() public institute: InstituteModel;

  /* for permission */
  public feature = Feature;
  public permission = Permission;

  public getGermanWeekday = getGermanWeekday;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  /**
   * downloadPdf
   * download the pdf
   * @returns void
   */
  public downloadPdf(): void {
    // show empty state dialog
    this.dialog.open(EmptyStateDialogComponent, {});
  }

  /**
   * onEdit
   * navigate to the edit page
   * @returns void
   */
  public onEdit(): void {
    const route = this.activatedRoute.snapshot.params.id ? '../edit' : './edit';
    this.router.navigate([route, btoa(String(this.institute.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onCreate
   * create a new institute
   * @returns void
   */
  public onCreate(): void {
    this.router.navigate(['/eleguide/institute/master-data/create']);
  }
}
