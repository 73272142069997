import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import {
  MultipleSelectionChangeEvent,
  SelectionChangeEvent,
  SelectOption,
  SelectOptionValue,
} from 'src/app/models/select-option.model';

@Component({
  selector: 'eleguide-select',
  templateUrl: './eleguide-select.component.html',
  styleUrl: './eleguide-select.component.scss',
})
export class EleguideSelectComponent implements OnInit {
  @Input() control: FormControl = new FormControl();
  @Input() controlId: string = '';
  @Input() options: SelectOption[] = [];
  @Input() placeholder: string = '';
  @Input() showDivider: boolean = true;
  @Input() index: number | null = null; // index of the select if used in a form array
  @Input() multiple: boolean = false;
  @Input() showCheckAll: boolean = false;
  @Input() showNullOption: boolean = false;
  @Input() nullOptionLabel: string = '';
  @Input() disabled: boolean = false;

  @Output() selectionChanged: EventEmitter<SelectionChangeEvent> =
    new EventEmitter<SelectionChangeEvent>();
  @Output()
  multipleSelectionChanged: EventEmitter<MultipleSelectionChangeEvent> =
    new EventEmitter<MultipleSelectionChangeEvent>();

  ngOnInit(): void {
    if (this.disabled) this.control.disable();
  }

  /**
   * Handles the change event when a single value is selected.
   * @param event - The change event.
   * @param index - The optional index of the selection.
   * @returns void
   */
  public onSingleValueChanges(event: MatSelectChange, index?: number): void {
    const selectionChangeEvent: SelectionChangeEvent = {
      newValue: event.value,
      index,
    };
    this.selectionChanged.emit(selectionChangeEvent);
  }

  /**
   * Handles the changes in multiple values.
   * @param event - The change event.
   * @param index - The index of the component.
   */
  public onMultipleValuesChanges(event: MatSelectChange, index: number) {
    const multipleSelectionChangeEvent: MultipleSelectionChangeEvent = {
      newValues: event.value,
      index,
    };
    this.multipleSelectionChanged.emit(multipleSelectionChangeEvent);
  }

  /**
   * Retrieves an array of all option values.
   * @returns An array containing the values of all options.
   */
  get getAllOptionValues(): SelectOptionValue[] {
    return this.options.map(option => option.value);
  }
}
