import { Component } from '@angular/core';
import { NavLink } from 'src/app/models/nav-link.model';
import { Feature, Permission } from 'src/app/models/permission.model';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss'],
})
export class AdministrationComponent {
  public navLinks: NavLink[] = [
    {
      label: 'Institute',
      link: 'institutes',
      feature: Feature.ADMINISTRATION,
      permission: Permission.VIEW,
    },
    {
      label: 'Benutzer',
      link: 'users',
      feature: Feature.ADMINISTRATION,
      permission: Permission.VIEW,
    },
  ];

  ngOnInit(): void {}
}
