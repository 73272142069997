import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CryptoRestService } from '@healthycloud/lib-ngx-crypto';
import { Subject, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import { TelemetryService } from './services/telemetry.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'mvp-eleguide';

  @HostBinding('class') componentCssClass;

  private destroy$: Subject<void> = new Subject();

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private cryptoRestService: CryptoRestService,
    private router: Router,
    private telemetryService: TelemetryService
  ) {}

  public ngOnInit() {
    this.preventDefaultFileBehaviour();
    this.sendTelemetrySignalsOnNavigation();

    this.cryptoRestService.backendUrl.set(this.config.backendUrl);
  }

  /**
   * sends telemetry signals on navigation
   * @returns void
   */
  private sendTelemetrySignalsOnNavigation(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(async event => {
      if (event instanceof NavigationEnd) {
        await this.telemetryService.trackPageview();
      }
    });
  }

  /**
   * prevents dropped files from opening in the browser
   * @returns void
   */
  private preventDefaultFileBehaviour(): void {
    window.addEventListener(
      'dragover',
      e => {
        if (e.target instanceof HTMLInputElement && e.target.type === 'file') {
          return;
        }
        e && e.preventDefault();
      },
      false
    );
    window.addEventListener(
      'drop',
      e => {
        if (e.target instanceof HTMLInputElement && e.target.type === 'file') {
          return;
        }
        e && e.preventDefault();
      },
      false
    );
  }

  /**
   * unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
