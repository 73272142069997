<app-event-room-planning
  *ngIf="roomPlanningOpen"
  [title]="'Raumplanung'"
  [participants]="this.appointmentForm.get('maxParticipants').value ?? 0"
  [eventDates]="eventDates"
  subtitle="Planen Sie die Räume des Termins"
  (onRoomPlanningClose)="roomPlanningChanged($event)">
</app-event-room-planning>

<div *ngIf="isLoading" class="loading-wrapper">
  <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
</div>

<div class="dialog flex-c" *ngIf="!roomPlanningOpen && !isLoading">
  <div class="header flex-r align-center">
    <span class="hasomed-text-h1">
      {{ this.editMode ? 'Termin bearbeiten' : 'Termin erstellen' }}
    </span>
    <button
      type="button"
      class="close-button"
      matTooltip="Schließen"
      (click)="onClose()">
      <i class="fa-light fa-x close-button-icon"></i>
    </button>
  </div>
  <form
    [formGroup]="appointmentForm"
    class="dialog-form"
    (ngSubmit)="onSubmit()">
    <div class="flex-c gap-24 scrollable-content">
      <div class="formular-row">
        <label class="formular-row-label" for="type">
          <span class="label-title">
            Terminart
            <span *ngIf="isRequired(appointmentForm.get('type'))">*</span>
          </span>
          <span class="label-description">
            Wählen Sie eine Art des Termins aus.
          </span>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <eleguide-select
              [control]="typeControl"
              controlId="type"
              [options]="availableTypeSelectOptions"
              placeholder="Terminart"
              [disabled]="editMode"></eleguide-select>
          </div>
          <!-- Error Messages -->
          <app-error-message
            [formField]="appointmentForm.get('type')"></app-error-message>
        </div>
      </div>

      <mat-divider></mat-divider>

      <!-- title -->
      <div class="formular-row">
        <label class="formular-row-label" for="title">
          <span class="label-title">
            Titel
            <span *ngIf="isRequired(appointmentForm.get('title'))">*</span>
          </span>
          <span class="label-description">
            Vergeben Sie einen Titel für diesen Termin.
          </span>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              class="hasomed-text-input flex-grow"
              formControlName="title"
              id="title"
              placeholder="Titel" />
          </div>
          <!-- Error Messages -->
          <app-error-message
            [formField]="appointmentForm.get('title')"></app-error-message>
        </div>
      </div>

      <mat-divider></mat-divider>

      <!-- description -->
      <div class="formular-row">
        <label class="formular-row-label" for="description">
          <span class="label-title">
            Beschreibung des Termins
            <span *ngIf="isRequired(appointmentForm.get('description'))"
              >*</span
            >
          </span>
          <span class="label-description"> Ergänzende Informationen. </span>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <textarea
              rows="4"
              class="hasomed-textarea-input"
              formControlName="description"
              id="description"
              placeholder="Beschreibung"></textarea>
          </div>
          <!-- Error Messages -->
          <app-error-message
            [formField]="
              appointmentForm.get('description')
            "></app-error-message>
        </div>
      </div>

      <mat-divider></mat-divider>

      <ng-container [ngSwitch]="selectedType">
        <!-- max participants -->
        <div
          *ngSwitchCase="
            selectedType
              | ngSwitchMultiCase
                : AppointmentTypeEnum.EVENT
                : AppointmentTypeEnum.OTHER
          "
          class="formular-row">
          <label class="formular-row-label" for="maxParticipants">
            <span class="label-title">
              Maximale Anzahl der Teilnehmer
              <span *ngIf="isRequired(appointmentForm.get('maxParticipants'))"
                >*</span
              >
            </span>
            <span class="label-description">
              Regulieren Sie die Teilnehmerzahl.
            </span>
          </label>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <input
                class="hasomed-text-input"
                min="0"
                max="999"
                type="number"
                formControlName="maxParticipants"
                id="maxParticipants"
                placeholder="Maximale Anzahl der Teilnehmer" />
            </div>
            <!-- Error Messages -->
            <app-error-message
              [formField]="
                appointmentForm.get('maxParticipants')
              "></app-error-message>
          </div>
        </div>

        <mat-divider
          *ngSwitchCase="
            selectedType
              | ngSwitchMultiCase
                : AppointmentTypeEnum.EVENT
                : AppointmentTypeEnum.OTHER
          "></mat-divider>

        <div
          *ngSwitchCase="
            selectedType
              | ngSwitchMultiCase
                : AppointmentTypeEnum.EVENT
                : AppointmentTypeEnum.OTHER
          "
          class="formular-row">
          <div class="formular-row-label">
            <span class="label-title">
              Datum des Termins *
              <span *ngIf="isRequired(appointmentForm.get('dateGroup'))"
                >*</span
              >
            </span>
            <span class="label-description">
              Definieren Sie den Gesamtzeitraum oder konfigurieren Sie alle
              individuellen Termine.
            </span>
          </div>
          <div class="hasomed-input-field">
            <app-event-picker
              [events]="eventDates"
              [startDate]="dateSeriesStart"
              [endDate]="dateSeriesEnd"
              [allDayEvent]="allDayEvent"
              [recurrencePattern]="recurrencePattern"
              [parentFormSubmitValidation]="formSubmitted"
              (eventsChanged)="onEventsChanged($event)"
              (recurrencePatternChanged)="recurrencePattern = $event"
              (datesChanged)="onDatesChanged($event)"
              class="formular-row-input"
              formControlName="dateGroup"
              id="dateGroup"></app-event-picker>
            <app-error-message
              [formField]="
                appointmentForm.get('dateGroup')
              "></app-error-message>
          </div>
        </div>

        <mat-divider
          *ngSwitchCase="
            selectedType
              | ngSwitchMultiCase
                : AppointmentTypeEnum.EVENT
                : AppointmentTypeEnum.OTHER
          "></mat-divider>

        <div
          class="formular-row"
          *ngSwitchCase="
            selectedType
              | ngSwitchMultiCase
                : AppointmentTypeEnum.EVENT
                : AppointmentTypeEnum.OTHER
          ">
          <div class="formular-row-label" for="roomPlanning">
            <span class="label-title">
              Ort des Termins
              <span *ngIf="isRequired(appointmentForm.get('location'))">*</span>
            </span>
            <span class="label-description">
              Wählen Sie zunächst den Ort des Termins und rufen Sie anschließend
              die Raumplanung auf oder/und fügen Sie eine URL für ein
              Online-Meeting hinzu.
            </span>
          </div>

          <div class="formular-row-input-columns">
            <div class="flex-r gap-16">
              <button
                *ngFor="
                  let eventLocation of EventLocation
                    | keyvalue: originalEventLocationOrder
                "
                [ngClass]="{
                  'hasomed-button-primary':
                    appointmentForm.get('location').value ===
                    eventLocation.value,
                  'hasomed-button-white-grey':
                    appointmentForm.get('location').value !=
                    eventLocation.value,
                }"
                (click)="
                  this.appointmentForm
                    .get('location')
                    .setValue(eventLocation.value)
                "
                type="button"
                class="hasomed-text-button">
                <span class="hasomed-button-text">
                  {{ eventLocation.value }}
                </span>
              </button>
            </div>

            <button
              [class.hasomed-button-red]="roomPlanningValid === false"
              [disabled]="roomPlanningDisabled"
              (click)="roomPlanningOpen = !roomPlanningOpen"
              id="roomPlanning"
              type="button"
              class="hasomed-text-button hasomed-button-primary button-full-width">
              <i
                class="fa-light fa-arrow-up-right-from-square hasomed-button-icon"></i>
              <span class="hasomed-button-text">
                {{
                  roomPlanningValid ? 'Raumplanung bearbeiten' : 'Raumplanung'
                }}
              </span>
            </button>
            <span
              *ngIf="roomPlanningValid === false"
              class="flex-r justify-center">
              Nicht alle Termine haben einen Raum
            </span>

            <!-- LINK -->
            <div *ngIf="showLink" class="hasomed-input-field">
              <div class="formular-row-input">
                <label
                  class="formular-row-label link-label"
                  for="videoMeetingUrl">
                  <span class="label-title">
                    URL für Video-Meeting
                    <span
                      *ngIf="isRequired(appointmentForm.get('videoMeetingUrl'))"
                      >*</span
                    >
                  </span>
                </label>
                <input
                  id="videoMeetingUrl"
                  class="hasomed-text-input"
                  type="text"
                  formControlName="videoMeetingUrl"
                  placeholder="URL für Video-Meeting" />
              </div>
              <app-error-message
                [formField]="
                  appointmentForm.get('videoMeetingUrl')
                "></app-error-message>
            </div>
          </div>
        </div>

        <!-- holiday date -->
        <div *ngSwitchCase="AppointmentTypeEnum.HOLIDAY" class="formular-row">
          <label class="formular-row-label" for="holidayDate">
            <span class="label-title">
              Datum des Feiertages
              <span *ngIf="isRequired(appointmentForm.get('holidayDate'))"
                >*</span
              >
            </span>
            <span class="label-description">
              Geben Sie an, wann dieser Feiertag stattfindet.
            </span>
          </label>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <mat-form-field
                id="holidayDate"
                [class.hasomed-date-field-empty]="
                  !appointmentForm.get('holidayDate').value
                "
                class="hasomed-date-field">
                <input
                  [matDatepicker]="picker"
                  class="hasomed-date-input"
                  maxlength="10"
                  matInput
                  placeholder="Wählen Sie ein Datum"
                  formControlName="holidayDate" />
                <mat-datepicker-toggle
                  [for]="picker"
                  class="hasomed-date-toggle"
                  matIconPrefix>
                  <mat-icon
                    matDatepickerToggleIcon
                    class="calendar-toggle-icon fa-light fa-calendar">
                  </mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <!-- Error Messages -->
            <app-error-message
              [formField]="
                appointmentForm.get('holidayDate')
              "></app-error-message>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="formular-buttons flex-r justify-space-between">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onClose()">
        <span class="hasomed-button-text"> Schließen </span>
      </button>
      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="hasomed-button-icon fa-light fa-floppy-disk"></i>
        <span class="hasomed-button-text">
          {{ editMode ? 'Termin bearbeiten' : 'Termin erstellen' }}
        </span>
      </button>
    </div>
  </form>
</div>
