import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { Observable } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import { SessionExpiredDialogComponent } from '../components/shared-components/session-expired-dialog/session-expired-dialog.component';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          // send the error to Sentry
          if (this.config.sentryEnvironment !== 'local') {
            Sentry.captureException(error, {
              tags: {
                url: error.url,
                status: error.status,
                details: error.error.detail,
              },
              fingerprint: ['{{ default }}', error.url],
            });
          }
          // if the error is 401 Unauthorized, redirect to login page
          if (error.status === 401) {
            if (
              this.dialog.openDialogs.length > 0 &&
              this.dialog.openDialogs[0].componentInstance instanceof
                SessionExpiredDialogComponent
            ) {
              throw error;
            }
            const dialogRef = this.dialog.open(SessionExpiredDialogComponent, {
              maxWidth: '400px',
            });

            dialogRef
              .afterClosed()
              .pipe(first())
              .subscribe(dialogResult => {
                if (dialogResult) {
                  this.dialog.closeAll();
                  this.authService.saveCurrentUrlAndRedirectToLogin(
                    this.router.url
                  );
                }
              });
          }
        }
        throw error;
      })
    );
  }
}
