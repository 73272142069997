import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'stripHtmlAndTruncate',
  standalone: true,
})
export class StripHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(
    value: string,
    limit: number = 100,
    trail: string = '...'
  ): SafeHtml {
    if (!value) {
      return '';
    }

    // Remove HTML tags
    let strippedValue = value.replace(/<[^>]+>/g, '');

    // Decode HTML entities
    const textarea = document.createElement('textarea');
    textarea.innerHTML = strippedValue;
    strippedValue = textarea.value;

    // Truncate to the specified limit and append trail if needed
    const truncatedText =
      strippedValue.length > limit
        ? strippedValue.substring(0, limit).trim() + trail
        : strippedValue;

    // Sanitize and return the truncated text as a SafeHtml object
    return this.sanitizer.bypassSecurityTrustHtml(truncatedText);
  }
}
