import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function maxNumberLength(maxLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const valueLength = control.value?.toString().length;

    return valueLength <= maxLength
      ? null
      : { maxNumberLength: { value: maxLength } };
  };
}
