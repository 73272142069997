import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';
import {
  Calendar,
  CalendarOptions,
  EventContentArg,
  EventMountArg,
} from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { createElement, VNode } from '@fullcalendar/core/preact';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import * as moment from 'moment';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
  CalendarEventDateModel,
  FullCalendarEventModel,
} from 'src/app/models/calendar-event.model';
import { EventDateType } from 'src/app/models/event.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EventService } from 'src/app/services/event.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { UserService } from 'src/app/services/user.service';
import {
  getCalendarEventColor,
  getCalendarEventRoomName,
  getCalendarEventTitle,
} from 'src/app/utils/calendar.utils';
import { CalendarEventDetailComponent } from './calendar-event-detail/calendar-event-detail.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() refreshCalendar: Observable<void>;
  public calendarOptions: CalendarOptions;
  public isTodayInView = true;
  private scrollTopPosition: number = 0;
  public calendar: Calendar;
  public selectedCalendarView: string;
  private shortWeekdays: boolean = false;
  private shortWeekdaysBrakepoint: number = 700;

  private destroy$: Subject<void> = new Subject<void>();

  public datePickerControl = new FormControl();

  @ViewChild('fullCalendar') calendarComponent: FullCalendarComponent;

  @HostListener('window:resize', ['$event'])
  public resize($event: any) {
    // change view
    this.shortWeekdaysBrakepoint = this.sidenavService.collapsed ? 700 : 900;

    window.innerWidth < this.shortWeekdaysBrakepoint
      ? (this.shortWeekdays = true)
      : (this.shortWeekdays = false);

    this.updateDayHeaderContent();

    this.selectedCalendarView = this.calendar.view.type;
    // keep scroll position on resize
    this.keepScrollPosition();
    this.updateTitle();
  }

  constructor(
    private eventService: EventService,
    private sidenavService: SidenavService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private cancellationService: CancellationService
  ) {}

  async ngOnInit() {
    this.initializeCalendarOptions();

    this.refreshCalendar.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.initializeCalendarEventDatesForView();
    });

    this.handleDatePickerChange();
  }

  ngAfterViewInit() {
    this.calendar = this.calendarComponent.getApi();
    this.checkIsTodayInView();
    this.updateTitle();
    this.initializeCalendarEventDatesForView();
    this.calculateMarginLeftForNowIndicatorLine();

    // get scroll position
    const fcScroller = document.getElementsByClassName('fc-scroller')[1];
    this.scrollTopPosition = fcScroller.scrollTop;

    fcScroller.addEventListener('scroll', () => {
      this.scrollTopPosition = fcScroller.scrollTop;
    });

    this.sidenavService.sidenavChangedSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        // trigger a window resize event to update the calendar
        window.dispatchEvent(new Event('resize'));

        // rerender calendar after sidenav is collapsed
        setTimeout(() => {
          this.calendar.render();
        }, 500);
      });

    window.innerWidth < this.shortWeekdaysBrakepoint
      ? (this.shortWeekdays = true)
      : (this.shortWeekdays = false);

    this.selectedCalendarView = this.calendar.view.type;
    localStorage.setItem('calendarView', this.selectedCalendarView);

    // call detectChanges because calendar.getApi() is called in ngAfterViewInit
    this.cdr.detectChanges();
  }

  /**
   * update the day header content to support changing screen sizes and sidenav state
   * @returns void
   */
  private updateDayHeaderContent(): void {
    this.calendarOptions.dayHeaderContent = args => {
      const date = new Date(args.date);
      const weekday = date.toLocaleDateString('de-DE', {
        weekday:
          this.shortWeekdays && this.calendar.view.type !== 'timeGridDay'
            ? 'short'
            : 'long',
      });
      const day = date.getDate();

      const weekdayDiv = createElement(
        'div',
        { class: 'fc-custom-header-weekday' },
        weekday
      );

      const dayDiv = createElement(
        'div',
        { class: 'fc-custom-header-day', id: 'day' },
        day
      );

      return createElement('div', { class: 'fc-custom-header-cell' }, [
        weekdayDiv,
        this.calendar?.view.type !== 'dayGridMonth' ? dayDiv : null,
      ]);
    };
  }

  /**
   * onViewChange
   * triggerd when the view of the calendar changes
   * @returns void
   */
  public onViewChange(view: string, day?: string): void {
    this.selectedCalendarView = view;

    if (day) {
      this.calendar.changeView(this.selectedCalendarView, day);
    } else {
      this.calendar.changeView(this.selectedCalendarView);
    }

    if (this.selectedCalendarView === 'timeGridSevenDay') {
      this.calculateMarginLeftForNowIndicatorLine();
    }

    localStorage.setItem('calendarView', this.selectedCalendarView);
    this.updateTitle();
    this.checkIsTodayInView();
    this.initializeCalendarEventDatesForView();
  }

  /**
   * initializeCalendarOptions
   * initialize the calendar options
   * @returns void
   */
  private initializeCalendarOptions(): void {
    // get calendarView from localStorage
    this.selectedCalendarView =
      localStorage.getItem('calendarView') ?? 'timeGridSevenDay';

    this.calendarOptions = {
      locale: 'de',
      plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin],
      initialView: this.selectedCalendarView,
      firstDay: 1,
      slotMinTime: '05:00:00',
      slotMaxTime: '23:59:59',
      slotDuration: '1:00',
      views: {
        timeGridSevenDay: {
          type: 'timeGridWeek',
          nowIndicator: true,
          eventMaxStack: 2,
        },
        timeGridDay: {
          type: 'timeGrid',
          nowIndicator: true,
          eventMaxStack: 3,
        },
        dayGridMonth: {
          type: 'dayGridMonth',
          dayMaxEventRows: 3,
        },
      },

      moreLinkText: 'mehr',
      allDayText: '',
      allDayClassNames: 'hasomed-text-small',
      moreLinkContent(renderProps, createElement) {
        return createElement(
          'span',
          { class: 'hasomed-text-small' },
          renderProps.num +
            ' weitere' +
            (renderProps.num === 1 ? 'r ' : ' ') +
            ' Termin' +
            (renderProps.num === 1 ? '' : 'e')
        );
      },
      moreLinkDidMount: function (info) {
        info.el.title = 'Termine anzeigen'; // Custom tooltip text
      },
      dayHeaderFormat: {
        weekday: 'long',
        month: 'numeric',
        day: 'numeric',
        omitCommas: true,
      },
      nowIndicatorContent: args => {
        // create the nor indicator line
        if (args.isAxis) {
          return createElement(
            'span',
            { class: 'hasomed-text-small font-white' },
            moment(args.date).format('HH:mm')
          );
        } else {
          return null;
        }
      },
      dateClick: info => {
        if (this.selectedCalendarView === 'dayGridMonth') {
          this.onViewChange('timeGridDay', info.dateStr);
          this.updateTitle();
        }
      },
      dayHeaderContent: args => {
        // create the custom header div (weekday, day)
        const date = new Date(args.date);
        const weekday = date.toLocaleDateString('de-DE', {
          weekday:
            this.shortWeekdays && this.calendar.view.type !== 'timeGridDay'
              ? 'short'
              : 'long',
        });
        const day = date.getDate();

        const weekdayDiv = createElement(
          'div',
          { class: 'fc-custom-header-weekday' },
          weekday
        );

        const dayDiv = createElement(
          'div',
          { class: 'fc-custom-header-day', id: 'day' },
          day
        );

        return createElement('div', { class: 'fc-custom-header-cell' }, [
          weekdayDiv,
          this.calendar?.view.type !== 'dayGridMonth' ? dayDiv : null,
        ]);
      },
      dayHeaderDidMount: ({ el, view }) => {
        const today = new Date();
        const headerDate = new Date(el.getAttribute('data-date'));

        // check, if the header date is todays date
        if (moment(today).isSame(headerDate, 'day')) {
          // find the div where the content is the day of today
          const dayDiv = el.querySelector('.fc-custom-header-day');
          if (dayDiv.innerHTML === today.getDate().toString()) {
            dayDiv.classList.add('fc-custom-today');
          }
        }
      },
      slotLabelFormat: {
        hour: 'numeric',
        minute: 'numeric',
        omitZeroMinute: false,
      },
      dayCellDidMount: info => {
        info.el.title = moment(info.date).format('DD.MM.YYYY') + ' anzeigen';
      },
      slotLabelContent: args => {
        // create the slot label (time to the left) div to display in calendar
        const slotLabelText = args.text;
        let slotLabelDiv = createElement(
          'div',
          { class: 'fc-custom-slot-label' },
          slotLabelText !== '05:00' ? slotLabelText : ''
        );

        return slotLabelDiv;
      },
      eventContent: (args: EventContentArg) => {
        return this.createEventDiv(args);
      },
      eventDidMount: (info: EventMountArg) => {
        info.el.title = this.generateEventTooltip(info);
      },
      eventClick: info => {
        // open event detail dialog
        const dialogRef = this.dialog.open(CalendarEventDetailComponent, {
          data: {
            calendarEventDate: info.event.extendedProps['calendarEventDate'],
          },
          width: '500px',
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.destroy$))
          .subscribe(result => {
            if (result?.eventDateHasBeenUpdated) {
              this.initializeCalendarEventDatesForView();
            }
          });
      },
      // remove header toolbar
      headerToolbar: {
        left: '',
        center: '',
        right: '',
      },
      buttonText: {
        today: 'Heute',
      },
      height: 'auto',
    };
  }

  /**
   * Initalizes the even dates for the current view
   * @returns voidq
   */
  private initializeCalendarEventDatesForView(): void {
    const startDate = this.calendar.view.activeStart;
    const endDate = this.calendar.view.activeEnd;

    let getAllEventDates = this.eventService.getAllEventDatesByInstituteId(
      startDate,
      endDate
    );

    if (this.userService.currentUserIsStudent()) {
      getAllEventDates = this.eventService.getAllEventDatesByStudentId(
        startDate,
        endDate
      );
    }

    if (this.userService.currentUserIsLecturer()) {
      getAllEventDates = this.eventService.getAllEventDatesByLecturerId(
        startDate,
        endDate
      );
    }

    getAllEventDates.pipe(takeUntil(this.destroy$)).subscribe({
      next: async response => {
        const calendarEventDates = response.body
          ? await Promise.all(
              response.body.map(async (eventDate: CalendarEventDateModel) => {
                return this.eventService.parseCalendarEventDate(eventDate);
              })
            )
          : [];

        const calendarEvents: FullCalendarEventModel[] = calendarEventDates.map(
          (
            calendarEventDate: CalendarEventDateModel
          ): FullCalendarEventModel => {
            const title = getCalendarEventTitle(calendarEventDate);
            const color = getCalendarEventColor(calendarEventDate);

            return {
              title: title,
              start: calendarEventDate.startDate,
              end: calendarEventDate.endDate,
              backgroundColor: color.backgroundColor,
              borderColor: color.borderColor,
              textColor: color.textColor,
              allDay: calendarEventDate.isAllDay,
              extendedProps: {
                calendarEventDate: calendarEventDate,
              },
            };
          }
        );

        // update calendar
        this.calendar.setOption('events', calendarEvents);
      },
      error: error => {},
    });
  }

  /**
   * onClickNext
   * trigger calenderApi.next() when clicking on next button, update isTodayInView and title
   * @returns void
   */
  public onClickNext(): void {
    this.calendar.next();
    this.checkIsTodayInView();
    this.updateTitle();
    this.initializeCalendarEventDatesForView();

    if (this.selectedCalendarView === 'timeGridSevenDay') {
      this.calculateMarginLeftForNowIndicatorLine();
    }
  }

  /**
   * onClickPrev
   * trigger calenderApi.prev() when clicking on prev button, update isTodayInView and title
   * @returns void
   */
  public onClickPrev(): void {
    this.calendar.prev();
    this.checkIsTodayInView();
    this.updateTitle();
    this.initializeCalendarEventDatesForView();

    if (this.selectedCalendarView === 'timeGridSevenDay') {
      this.calculateMarginLeftForNowIndicatorLine();
    }
  }

  /**
   * onClickToday
   * trigger calenderApi.today() when clicking on today button,  update isTodayInView and title
   * @returns void
   */
  public onClickToday() {
    this.calendar.today();
    this.isTodayInView = true;
    this.updateTitle();
    this.initializeCalendarEventDatesForView();

    if (this.selectedCalendarView === 'timeGridSevenDay') {
      this.calculateMarginLeftForNowIndicatorLine();
    }
  }

  /**
   * Handles changes in the date picker control.
   * @returns void
   */
  public handleDatePickerChange(): void {
    this.datePickerControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((date: Date) => {
        const dateStr = moment(date).format('YYYY-MM-DD');
        this.calendar.gotoDate(dateStr);
        this.updateTitle();
        this.checkIsTodayInView();
        this.initializeCalendarEventDatesForView();
      });
  }

  /**
   * onChangeCalendarView
   * change calendar view to day or seven day view
   * @returns void
   */
  public onChangeCalendarView(): void {
    // check which view is active and change to the other
    if (this.calendar.view.type === 'timeGridDay') {
      this.calendar.changeView('timeGridSevenDay');
    } else {
      this.calendar.changeView('timeGridDay');
    }
    this.keepScrollPosition();
  }

  /**
   * checkIsTodayInView
   * Update isTodayInView to true, when todays date is in current view, else false
   * @returns void
   */
  private checkIsTodayInView(): void {
    const calendarApiView = this.calendar.view;
    const viewStart = calendarApiView.activeStart;
    const viewEnd = calendarApiView.activeEnd;
    const today = new Date();

    if (today >= viewStart && today <= viewEnd) {
      this.isTodayInView = true;
    } else {
      this.isTodayInView = false;
    }
  }

  /**
   * updateTitle
   * updates the title (date-range) of current view
   * @returns void
   */
  private updateTitle(): void {
    const calendarApiView = this.calendar.view;
    const titleSpan = document.getElementById('fcCustomTitle');
    titleSpan.innerHTML = calendarApiView.title;
  }

  /**
   * getEventsInView
   * returns all events in current view
   * @returns EventImpl[]
   */
  public getEventsInView(): EventImpl[] {
    const viewStart = this.calendar.view.activeStart;
    const viewEnd = this.calendar.view.activeEnd;

    const allEvents = this.calendar.getEvents();
    const eventsInView = [];

    allEvents.forEach(event => {
      if (
        (event.start >= viewStart && event.start <= viewEnd) ||
        (event.end >= viewStart && event.end <= viewEnd)
      ) {
        eventsInView.push(event);
      }
    });

    eventsInView.sort((a, b) => a.start - b.start);

    return eventsInView;
  }

  /**
   * keepScrollPosition
   * keeps the scroll position of the calendar
   * @returns void
   */
  private keepScrollPosition(): void {
    const fcScroller = document.getElementsByClassName('fc-scroller')[1];
    fcScroller.scrollTop = this.scrollTopPosition;
  }

  /**
   * Creates a VNode representing an event div.
   * @param args - The arguments for creating the event div.
   * @returns A VNode representing the event div.
   */
  private createEventDiv(args: EventContentArg): VNode<any> {
    const event = args.event;
    let title = event.title;
    const time = args.timeText;

    const calendarEventDate: CalendarEventDateModel =
      event.extendedProps['calendarEventDate'];

    if (calendarEventDate.isCanceled) {
      title = 'Abgesagt - ' + title;
    }

    // get view
    const view = this.calendar.view.type;
    if (view === 'dayGridMonth') {
      return createElement(
        'div',
        {
          class: 'fc-custom-event-month',
          id: 'event',
          style:
            'background-color: ' +
            event.backgroundColor +
            ';' +
            'border-color: ' +
            event.borderColor +
            ';' +
            'color: ' +
            event.textColor +
            ';',
        },
        [title]
      );
    }

    let titleDiv = createElement(
      'div',
      { class: 'fc-custom-event-title' },
      title
    );

    let additionalInfoDiv = null;
    if (calendarEventDate.eventDateType === EventDateType.PATIENT_SESSION) {
      additionalInfoDiv = createElement(
        'div',
        { class: 'fc-custom-event-title' },
        calendarEventDate.patientChiffre
      );
    }

    const roomDiv = createElement(
      'div',
      { class: 'fc-custom-event-room' },
      'Raum: ' + getCalendarEventRoomName(calendarEventDate)
    );

    let titleInfoRoomDiv = createElement(
      'div',
      { class: 'fc-custom-event-title-room' },
      [
        titleDiv,
        additionalInfoDiv,
        calendarEventDate.eventDateType !== EventDateType.APPOINTMENT_HOLIDAY
          ? roomDiv
          : null,
      ]
    );

    const timeDiv = createElement('div', { class: 'fc-custom-event-time' }, [
      time,
    ]);

    let eventWrapperDiv = createElement(
      'div',
      { class: 'fc-custom-event', id: 'event' },
      [titleInfoRoomDiv, timeDiv]
    );

    return eventWrapperDiv;
  }

  /**
   * Calculate the margin left for the now indicator line
   * @returns void
   */
  private calculateMarginLeftForNowIndicatorLine(): void {
    const calendarApiView = this.calendar.view;
    const viewStart = calendarApiView.activeStart;
    const now = new Date();

    const diffInDays = moment(now).diff(viewStart, 'days', false);
    const offsetToNowIndicatorArrow = -diffInDays - 6;
    const marginLeft = diffInDays * -100 + offsetToNowIndicatorArrow;

    const nowIndicatorLine = document.getElementsByClassName(
      'fc-timegrid-now-indicator-line'
    )[0] as HTMLElement;

    if (!nowIndicatorLine) return;

    nowIndicatorLine.style.marginLeft = marginLeft + '%';
  }

  /**
   * Generates a tooltip for an event.
   *
   * @param info - The event mount argument.
   * @returns The generated tooltip
   */
  private generateEventTooltip(info: EventMountArg) {
    const title = info.event.title;
    const formattedDate = this.formatEventDateTime(info);

    if (info.event.allDay) return title + ' - ' + formattedDate;

    const room = info.event.extendedProps['calendarEventDate'].room
      ? info.event.extendedProps['calendarEventDate'].room.name
      : 'Kein Raum';
    return title + ' - ' + room + ' - ' + formattedDate;
  }

  /**
   * Formats the event date and time.
   * @param info - The event mount argument.
   * @returns The formatted event date and time.
   */
  private formatEventDateTime(info: EventMountArg): string {
    const formattedStartDate = moment(info.event.start).format('DD.MM.YYYY');
    if (info.event.allDay) {
      return formattedStartDate;
    } else {
      return (
        formattedStartDate +
        ', ' +
        moment(info.event.start).format('HH:mm') +
        ' - ' +
        moment(info.event.end).format('HH:mm') +
        ' Uhr'
      );
    }
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
