<div class="component-content">
  <div class="header">
    <div class="header-title" *ngIf="!isMobile">
      <h1 class="hasomed-text-h1">Aus- und Weiterbildungsgänge</h1>
      <h3 class="hasomed-text-h3">
        Erstellen oder bearbeiten Sie die Aus- oder Weiterbildungsgänge.
      </h3>
    </div>
    <form [formGroup]="searchForm" class="header-buttons">
      <!-- Search -->
      <div class="hasomed-search" *ngIf="showSearchBar">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          #input
          (keyup)="applySearch($event)"
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>
      <div class="filter-buttons" *ngIf="isMobile || isTablet">
        <button
          *ngIf="isMobile || isTablet"
          id="openSearchButton"
          [class.hasomed-button-active]="showSearchBar"
          [class.hasomed-button-primary]="
            searchForm.get('searchText').value !== ''
          "
          (click)="showSearchBar = !showSearchBar"
          class="hasomed-text-button hasomed-button-white-grey">
          <i class="fa-light fa-search hasomed-button-icon"></i>
        </button>
      </div>
      <button
        (click)="onCreate()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-plus hasomed-button-icon"></i>
        <span class="hasomed-button-text"> Bildungsgang hinzufügen </span>
      </button>
    </form>
  </div>
  <div class="main-content">
    <!-- Filter Tags -->
    <div
      [class.table-bicolor]="dataSource?.filteredData?.length > 1"
      class="table">
      <table [dataSource]="dataSource" mat-table matSort>
        <!-- title column -->
        <ng-container matColumnDef="title">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Titel
            <app-custom-table-sort-icon [sort]="sort" columnName="title">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let row; dataSource: dataSource"
            (click)="onOpen(row)"
            class="cursor-pointer gray-900"
            mat-cell>
            {{ row?.title }}
          </td>
        </ng-container>

        <!-- Accreditation column -->
        <ng-container matColumnDef="accreditation">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Jahr
            <app-custom-table-sort-icon
              [sort]="sort"
              columnName="accreditation">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{ row?.accreditationYear }}
          </td>
        </ng-container>

        <!-- Regulation column -->
        <ng-container matColumnDef="regulation">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Ordnung
            <app-custom-table-sort-icon [sort]="sort" columnName="regulation">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{ row?.regulation }}
          </td>
        </ng-container>

        <!-- expertise column -->
        <ng-container matColumnDef="expertise">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Fachkunde
            <app-custom-table-sort-icon [sort]="sort" columnName="expertise">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{ row?.expertise?.name }}
          </td>
        </ng-container>

        <!-- procedure column -->
        <ng-container matColumnDef="procedure">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Richtlinienverfahren
            <app-custom-table-sort-icon [sort]="sort" columnName="procedure">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{ row?.procedure?.name }}
          </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="center-header-cell" mat-header-cell>
            {{ isMobile ? 'Akt.' : 'Aktionen' }}
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            <div class="table-button-icons">
              <button
                #t="matMenuTrigger"
                [matMenuTriggerFor]="actionsMenu"
                type="button"
                class="table-button-icon"
                matTooltip="Bildungsgang Aktionen">
                <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button (click)="onOpen(row)" mat-menu-item type="button">
                  <div class="action-menu-item">
                    <i
                      class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Anzeigen</span>
                  </div>
                </button>
                <mat-divider class="menu-divider"></mat-divider>
                <mat-button-toggle-group
                  (click)="onEdit(row)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Bearbeiten</span>
                  </div>
                </mat-button-toggle-group>
                <mat-divider class="menu-divider"></mat-divider>
                <button (click)="onDelete(row)" mat-menu-item type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-trash-can icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Löschen</span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="displayedColumns.length">
              <div *ngIf="isLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!isLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Es wurden noch keine Aus- und Weiterbildungsgänge angelegt.
                Erstellen Sie neue Bildungsgänge.
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <mat-divider></mat-divider>
      <mat-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="1"
        custom-paginator
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
