import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs';
import { FileModel } from 'src/app/models/file.model';
import { AlertService } from 'src/app/services/alert.service';
import { convertToBytes, formatBytes } from 'src/app/utils/file.utils';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

export interface FileFormat {
  type: string;
  mimeType: string;
}

interface ExtendedFileModel extends FileModel {
  progress: number;
  uploaded: boolean;
}

@Component({
  selector: 'app-upload-area-dnd',
  templateUrl: './upload-area-dnd.component.html',
  styleUrls: ['./upload-area-dnd.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadAreaDNDComponent),
      multi: true,
    },
  ],
})
export class UploadAreaDNDComponent implements OnInit, ControlValueAccessor {
  constructor(
    private alertService: AlertService,
    private dialog: MatDialog
  ) {}

  @ViewChild('fileInput') fileInput: ElementRef;

  // Inputs
  /* Context Information: e.g. profilePictureUpload */
  @Input() context: string;
  /* Configuration for showing the uploaded files with progress */
  @Input() showFileList: boolean;
  /* Array of required File Types as Array with File Format Object. Type und Mime Type  */
  @Input() allowedFileTypes: Array<FileFormat>;
  /* Configuration for maximum file size */
  @Input() maxFileSize = '10 MB';
  /* Configuration for multiple or single file upload */
  @Input() multiple: boolean;
  /* Configuration for scroll to uploaded file */
  @Input() scrollTo: boolean;

  @Input() inputId: string;

  @Input() disabled: boolean;

  @Input() invalid: boolean;

  @Input() existingFiles: FileModel[];

  @Output() deleteExistingFile: EventEmitter<FileModel> = new EventEmitter();

  @Output() openExistingFile: EventEmitter<FileModel> = new EventEmitter();

  @Output() downloadExistingFile: EventEmitter<FileModel> = new EventEmitter();

  @Input() sensitiveDataAlert: boolean = true;

  // Variables
  public isDisabled: boolean = false;
  public allowedFileTypesString = '';
  public requiredFileMimeTypesString = '';
  public uploadedFiles: ExtendedFileModel[] = [];
  public errorMessage: string;
  public scrolledToFile = false;
  public val: any[];
  public showNoSensitiveDataAlert: boolean = false;
  public showDropHere = false;
  private dragCoordiantes = { x: 0, y: 0 };

  // import from utils
  public formatBytes = formatBytes;

  public ngOnInit() {
    this.allowedFileTypes.forEach(fileType => {
      if (this.allowedFileTypesString != '') {
        this.allowedFileTypesString += ', ' + fileType.type;
      } else {
        this.allowedFileTypesString += fileType.type;
      }
      if (this.requiredFileMimeTypesString != '') {
        this.requiredFileMimeTypesString += ', ' + fileType.mimeType;
      } else {
        this.requiredFileMimeTypesString += fileType.mimeType;
      }
    });
  }

  /*---------------------------------------- UPLOAD DIALOG FUNCTIONS START ----------------------------------------*/
  /**
   * onFileDropped
   * run when file in FileChooser is selected
   * @param event
   * @returns Promise<void>
   */
  public async onFileDropped(event: any): Promise<void> {
    this.prepareFilesList(event);
  }

  /**
   * handle file from browsing
   * @param event
   * @returns void
   */
  public fileBrowseHandler(event: any): void {
    this.prepareFilesList(event.target.files);
  }

  /**
   * onDeleteExistingFile
   * removes the existing file from the list
   * @param file
   * @returns void
   */
  public onDeleteExistingFile(file: FileModel): void {
    this.deleteExistingFile.emit(file);
    const index = this.existingFiles.indexOf(file);
    if (index > -1) {
      this.existingFiles.splice(index, 1);
    }
  }

  /**
   * Open existing file
   * @param file
   * @returns void
   */
  public onOpenExistingFile(file: FileModel): void {
    this.openExistingFile.emit(file);
  }

  /**
   * Download existing file
   * @param file
   * @returns void
   */
  public onDownloadExistingFile(file: FileModel): void {
    this.downloadExistingFile.emit(file);
  }

  /**
   * Simulate the upload process
   */
  public uploadFilesSimulator(index: number) {
    if (
      index !== this.uploadedFiles.length - 1 &&
      this.uploadedFiles[index]?.progress === 100
    ) {
      this.uploadFilesSimulator(index + 1);
      return;
    }
    setTimeout(() => {
      if (index === this.uploadedFiles.length) {
        /* reset scrolledToFile for next file upload */
        this.scrolledToFile = false;
        return;
      } else {
        const progressInterval = setInterval(() => {
          /* scroll to last uploaded file if scrollto is set to true */
          if (this.uploadedFiles.length > 0 && this.scrollTo) {
            const itemToScrollTo = document.getElementById(
              'file-' + (this.uploadedFiles.length - 1)
            );
            if (itemToScrollTo && this.scrolledToFile === false) {
              this.scrolledToFile = true;
              itemToScrollTo.scrollIntoView({ behavior: 'smooth' });
            }
          }

          if (this.uploadedFiles[index].progress === 100) {
            clearInterval(progressInterval);
            if (this.uploadedFiles[index].uploaded !== true) {
              setTimeout(() => {
                this.uploadedFiles[index].uploaded = true;
              }, 500);
            }
            this.uploadFilesSimulator(index + 1);
          } else {
            this.uploadedFiles[index].progress += 10;
          }
        }, 50);
      }
    }, 1000);
  }

  /**
   * prepareFilesList
   * Convert Files list to normal array list
   * @param files (Files List)
   * @returns void
   */
  private prepareFilesList(files: Array<any>): void {
    // reset array if only a single file should be uploaded
    if (!this.multiple) {
      this.uploadedFiles = [];
    }
    for (const file of files) {
      file.progress = 0;
      if (this.isDuplicate(file)) {
        this.alertService.showErrorAlert(
          'Dateiname bereits vergeben!',
          'Eine Datei mit dem Namen ' + file.name + ' existiert bereits.'
        );
        return;
      }

      if (this.isFileTooLarge(file)) {
        this.alertService.showErrorAlert(
          'Datei zu groß!',
          `Die ausgewählte Datei '${file.name}' ist zu groß.`
        );
        return;
      }

      if (this.isTotalSizeExceeded(file)) {
        this.alertService.showErrorAlert(
          'Dateien zu groß!',
          'Die ausgewählten Dateien sind zu groß!',
          10000
        );
        return;
      }

      if (!this.isFileTypeAllowed(file)) {
        this.alertService.showErrorAlert(
          'Dateityp nicht erlaubt!',
          `Der Dateityp von der Datei '${file.name}' ist nicht erlaubt.`
        );
        return;
      }

      // reset array if only a single file should be uploaded
      if (!this.multiple) {
        this.uploadedFiles = [];
      }
      this.uploadedFiles.push(file);
    }
    this.onChange(this.uploadedFiles);
    this.uploadFilesSimulator(0);
  }

  /**
   * Check if the file is a duplicate in uploadedFiles or existingFiles.
   * @param file The file to check.
   * @returns True if the file is a duplicate, false otherwise.
   */
  private isDuplicate(file: any): boolean {
    const duplicate = this.uploadedFiles?.some(
      uploadedFile => uploadedFile.name === file.name
    );
    const duplicateExisting = this.existingFiles?.some(
      existingFile => existingFile.name === file.name
    );
    return duplicate || duplicateExisting;
  }

  /**
   * Check if the file size exceeds the maximum allowed size.
   * @param file The file to check.
   * @returns True if the file is too large, false otherwise.
   */
  private isFileTooLarge(file: any): boolean {
    if (file.size > convertToBytes(this.maxFileSize)) {
      this.errorMessage = `Die Datei ist zu groß. Maximale Dateigröße: ${this.maxFileSize}`;
      return true;
    }
    this.errorMessage = '';
    return false;
  }

  /**
   * Check if the total size of all files exceeds the maximum allowed size.
   * @param file The file to check.
   * @returns True if the total size is exceeded, false otherwise.
   */
  private isTotalSizeExceeded(file: any): boolean {
    const totalSize =
      this.uploadedFiles.reduce(
        (sum, uploadedFile) => sum + uploadedFile.size,
        0
      ) + file.size;
    if (totalSize > convertToBytes(this.maxFileSize)) {
      this.errorMessage = `Die Dateien sind zu groß. Maximale Dateigröße: ${this.maxFileSize}`;
      return true;
    }
    this.errorMessage = '';
    return false;
  }

  /**
   * Check if the file type is allowed.
   * @param file The file to check.
   * @returns True if the file type is allowed, false otherwise.
   */
  private isFileTypeAllowed(file: any): boolean {
    return this.allowedFileTypes.some(fileType =>
      fileType.mimeType.includes(file.type)
    );
  }

  /*---------------------------------------- UPLOAD DIALOG FUNCTIONS END ----------------------------------------*/

  /*---------------------------------------- CONTROL VALUE ACCESSOR FUNCTIONS START ----------------------------------------*/
  onChange: any = () => {};
  onTouch: any = () => {};
  // this is the updated value that the class accesses
  set value(val) {
    // this value is updated by programmatic changes
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }
  // this method sets the value programmatically
  writeValue(value: any) {
    if (value && Array.isArray(value)) {
      this.uploadedFiles = value;
    } else {
      this.uploadedFiles = []; // Reset if the value is not provided
    }
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  /*---------------------------------------- CONTROL VALUE ACCESSOR FUNCTIONS END ----------------------------------------*/

  /**
   * onDelete
   * delete the uploaded file from the list
   * @param file
   * @returns void
   */
  public onDelete(file: any): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Datei löschen',
        message: 'Möchten Sie die Datei wirklich löschen?',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          const index = this.uploadedFiles.indexOf(file);
          if (index > -1) {
            this.uploadedFiles.splice(index, 1);
          }
          this.fileInput.nativeElement.value = '';
          this.onChange(this.uploadedFiles);
        }
      });
  }

  /**
   * onDownload
   * download the uploaded file
   * @param file
   * @returns void
   */
  public onDownload(file: any): void {
    // download file
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(fileURL);
  }

  /**
   * if the a drag event leaves the drop area hide the drop here message
   * @param event The drag event
   * @returns void
   */
  public onDragLeave(event: DragEvent): void {
    // check if the drag Coordinates are the same as the event coordinates
    if (
      this.dragCoordiantes.x === event.clientX &&
      this.dragCoordiantes.y === event.clientY
    ) {
      return;
    }
    this.showDropHere = false;
  }

  /**
   * if the a drag event enters the drop area show the drop here message
   * @param event The drag event
   * @returns void
   */
  public onDragEnter(event: DragEvent): void {
    this.dragCoordiantes = { x: event.clientX, y: event.clientY };
    this.showDropHere = true;
  }
}
