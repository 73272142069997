<div class="alert-container" *ngIf="!isInitialEducationStockSet">
  <div class="alert-message">
    <i class="fa-light fa-warning icon-sm"></i>
    <span class="hasomed-text-body">
      Bitte tragen Sie Ihre bereits absolvierten Stunden ein, damit EleGuide
      Ihren aktuellen Fortschritt kennt.
    </span>
  </div>

  <div class="button-container">
    <button
      type="button"
      class="hasomed-text-button hasomed-button-white-grey"
      (click)="onOpenInitialEducationStock()">
      <i class="fa-light fa-cloud-upload hasomed-button-icon-sm"></i>
      <span class="hasomed-button-text">Jetzt eintragen</span>
    </button>
    <button
      [disabled]="isSubmitting"
      type="button"
      class="hasomed-text-button hasomed-button-red-white"
      (click)="markInitialEducationStockAsSet()">
      <span class="hasomed-button-text">Noch keine Stunden absolviert</span>
    </button>
  </div>
</div>
