<form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <div *ngIf="!isLoading" class="user-container-header">
        <img
          src="{{
            user?.profilePicture ?? '../../../../../assets/img/user.jpg'
          }}"
          alt="Profilbild des Kandidaten"
          class="user-icon-image" />

        <div class="user-info">
          <h1 class="hasomed-text-h1">
            {{ getFullName(user) }}
          </h1>
          <div class="flex-r gap-16 align-center">
            <h3 class="hasomed-text-h3">
              {{ user?.userIdentifier ?? '-' }}
            </h3>
            <div
              *ngIf="user?.label"
              [style.background]="user?.label?.color"
              class="label">
              <i class="fa-light fa-id-badge label-icon"></i>
              <span class="label-text">
                {{ user?.label?.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>

      <!-- Submit button -->
      <button
        type="submit"
        [disabled]="isLoading"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content-form">
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Anrede und Titel
          <span
            *ngIf="
              isRequired(userForm.get('genderTitle')) ||
              isRequired(userForm.get('academicTitle'))
            "
            >*</span
          >
        </span>
        <span class="label-description">
          {{ 'Anrede und Titel' + roleBasedLabel }}.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <gender-title-select
            class="flex-1"
            [control]="genderTitleControl"></gender-title-select>
          <academic-title-select
            class="flex-1"
            [control]="academicTitleControl"></academic-title-select>
        </div>
        <app-error-message
          [formField]="userForm.get('genderTitle')"></app-error-message>
        <app-error-message
          [formField]="userForm.get('academicTitle')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Name
          <span
            *ngIf="
              isRequired(userForm.get('firstName')) ||
              isRequired(userForm.get('lastName'))
            "
            >*</span
          >
        </span>
        <span class="label-description">
          {{ 'Vorname und Nachname' + roleBasedLabel }}.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="firstName"
            class="hasomed-text-input"
            autocomplete="given-name"
            type="text"
            formControlName="firstName"
            placeholder="Vorname" />
          <input
            id="lastName"
            class="hasomed-text-input"
            autocomplete="family-name"
            type="text"
            formControlName="lastName"
            placeholder="Nachname" />
        </div>
        <app-error-message
          [formField]="userForm.get('firstName')"></app-error-message>
        <app-error-message
          [formField]="userForm.get('lastName')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Label -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Etikett
          <span *ngIf="isRequired(userForm.get('label'))">*</span>
        </span>
        <span class="label-description">
          {{ 'Spezifische Informationen' + roleBasedLabel }}.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <eleguide-select
            [control]="labelControl"
            controlId="label"
            [options]="availableLabelSelectOptions"
            placeholder="Etikett"
            [showNullOption]="true"
            nullOptionLabel="-- Kein Etikett --"></eleguide-select>
        </div>
        <app-error-message
          [formField]="userForm.get('label')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Email -->
    <div class="formular-row">
      <label class="formular-row-label" for="userEmail">
        <span class="label-title"> E-Mail Adresse </span>
        <span class="label-description">
          {{ 'E-Mail Adresse' + roleBasedLabel }}.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-icon-input">
          <i class="fa-light fa-envelope input-icon"></i>
          <input
            id="userEmail"
            class="hasomed-text-input"
            type="text"
            size="20"
            autocomplete="off"
            formControlName="email"
            placeholder="Email"
            readonly />
        </div>
        <app-error-message
          [formField]="userForm.get('email')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Birthdate -->
    <div class="formular-row">
      <div class="formular-row-label">
        <div class="label-title">
          Geburtsdatum
          <span *ngIf="isRequired(userForm.get('birthdate'))">*</span>
        </div>
        <div class="label-description">
          {{ 'Geburtsdatum' + roleBasedLabel }}.
        </div>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field
            [class.hasomed-date-field-empty]="!userForm.get('birthdate').value"
            class="hasomed-date-field">
            <input
              [max]="today"
              [matDatepicker]="picker"
              class="hasomed-date-input"
              maxlength="10"
              size="9"
              matInput
              placeholder="Wählen Sie ein Datum"
              formControlName="birthdate" />
            <mat-datepicker-toggle
              [for]="picker"
              class="hasomed-date-toggle"
              matIconPrefix>
              <mat-icon
                matDatepickerToggleIcon
                class="calendar-toggle-icon fa-light fa-calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              #picker
              [startAt]="datePickerStartDate"
              startView="multi-year"></mat-datepicker>
          </mat-form-field>
        </div>
        <app-error-message
          [formField]="userForm.get('birthdate')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Student number -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Bildungs-ID</span>
        <span class="label-description"> Wird automatisch vergeben. </span>
      </div>
      <div class="formular-row-input detail-value">
        <i class="fa-light fa-lock"></i>
        <span>
          {{
            user?.userIdentifier != '' && user?.userIdentifier
              ? user?.userIdentifier
              : 'Bildungs-ID noch nicht definiert'
          }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- entry date -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Eintrittsdatum
          <span *ngIf="isRequired(userForm.get('entryDate'))">*</span>
        </span>
        <span class="label-description">
          {{ 'Eintrittsdatum' + roleBasedLabel }}.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field
            [class.hasomed-date-field-empty]="!userForm.get('entryDate').value"
            class="hasomed-date-field">
            <input
              [max]="today"
              [matDatepicker]="entryDatePicker"
              class="hasomed-date-input"
              maxlength="10"
              size="9"
              matInput
              placeholder="Wählen Sie ein Datum"
              formControlName="entryDate" />
            <mat-datepicker-toggle
              [for]="entryDatePicker"
              class="hasomed-date-toggle"
              matIconPrefix>
              <mat-icon
                matDatepickerToggleIcon
                class="calendar-toggle-icon fa-light fa-calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              #entryDatePicker
              startView="multi-year"></mat-datepicker>
          </mat-form-field>
        </div>
        <app-error-message
          [formField]="userForm.get('entryDate')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Avatar -->
    <div class="formular-row">
      <label class="formular-row-label" for="profilePictureUpload">
        <span class="label-title">
          Profilfoto
          <span *ngIf="isRequired(userForm.get('profilePicture'))">*</span>
        </span>
        <span class="label-description">
          {{ 'Profilfoto' + roleBasedLabel }}.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input-image-upload">
          <div
            (click)="openProfilePictureDialog()"
            class="uploaded-image-container">
            <img
              #userIconImage
              [attr.src]="
                userForm.get('profilePicture').value ??
                '../../../../../assets/img/user.jpg'
              "
              id="user-icon-image"
              class="uploaded-image"
              alt="Profilbild des Benutzers"
              matTooltip="Profilbild des Benutzers" />
          </div>
          <app-upload-area-dnd
            [showFileList]="false"
            [allowedFileTypes]="allowedFileTypes"
            (change)="imageChangeEvent($event)"
            context="profilePictureUpload"
            class="image-upload-area"
            inputId="profilePictureUpload">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <ng-container *ngIf="user.roleId == Role.LECTURER">
      <!-- additional qualifications -->
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">
            Zusatzqualifikationen
            <span *ngIf="isRequired(userForm.get('additionalQualifications'))"
              >*</span
            >
          </span>
          <span class="label-description">
            {{ 'Zusatzqualifikationen' + roleBasedLabel }}.
          </span>
        </div>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <button
              [ngClass]="{
                'hasomed-button-primary':
                  userForm.get('additionalQualifications').value == [],
                'hasomed-button-white-grey':
                  userForm.get('additionalQualifications').value != [],
              }"
              (click)="
                changeArrayFormFieldValue(null, 'additionalQualifications')
              "
              type="button"
              class="hasomed-text-button">
              <span class="hasomed-button-text">Keine</span>
            </button>
            <button
              *ngFor="let additionalQualification of additionalQualifications"
              [ngClass]="{
                'hasomed-button-primary': userForm
                  .get('additionalQualifications')
                  .value?.includes(additionalQualification.id),
                'hasomed-button-white-grey': !userForm
                  .get('additionalQualifications')
                  .value?.includes(additionalQualification.id),
              }"
              (click)="
                changeArrayFormFieldValue(
                  additionalQualification.id,
                  'additionalQualifications'
                )
              "
              class="hasomed-text-button"
              type="button">
              <span class="hasomed-button-text">
                {{ additionalQualification.name }}
              </span>
            </button>
          </div>
          <app-error-message
            [formField]="
              userForm.get('additionalQualifications')
            "></app-error-message>
        </div>
      </div>
      <mat-divider></mat-divider>

      <!-- professionalAssociation -->
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">
            Anerkennung durch Berufsverband
            <span *ngIf="isRequired(userForm.get('professionalAssociation'))"
              >*</span
            >
          </span>
          <span class="label-description">
            {{ 'Berufsverband' + roleBasedLabel }}.
          </span>
        </div>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <button
              [ngClass]="{
                'hasomed-button-primary': !userForm.get(
                  'professionalAssociation'
                ).value,
                'hasomed-button-white-grey': userForm.get(
                  'professionalAssociation'
                ).value,
              }"
              (click)="userForm.get('professionalAssociation').setValue(null)"
              type="button"
              class="hasomed-text-button">
              <span class="hasomed-button-text">Keine</span>
            </button>
            <button
              *ngFor="
                let professionalAssociation of filteredProfessionalAssociations
              "
              [ngClass]="{
                'hasomed-button-primary':
                  userForm.get('professionalAssociation').value ===
                  professionalAssociation.value,
                'hasomed-button-white-grey':
                  userForm.get('professionalAssociation').value !=
                  professionalAssociation.value,
              }"
              (click)="
                userForm
                  .get('professionalAssociation')
                  .setValue(professionalAssociation.value)
              "
              class="hasomed-text-button"
              type="button">
              <span class="hasomed-button-text">
                {{ professionalAssociation.value }}
              </span>
            </button>
          </div>
          <app-error-message
            [formField]="
              userForm.get('professionalAssociation')
            "></app-error-message>
        </div>
      </div>
      <mat-divider></mat-divider>

      <!-- medicalAuthorizationOnly -->
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">
            Ausschließlich Zulassung für Ärzte
            <span *ngIf="isRequired(userForm.get('medicalAuthorizationOnly'))"
              >*</span
            >
          </span>
          <span class="label-description">
            {{ 'Ausschließlich ärztliche Zulassung' + roleBasedLabel }}.
          </span>
        </div>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <button
              [ngClass]="{
                'hasomed-button-primary':
                  userForm.get('medicalAuthorizationOnly').value === true,
                'hasomed-button-white-grey':
                  userForm.get('medicalAuthorizationOnly').value !== true,
              }"
              (click)="userForm.get('medicalAuthorizationOnly').setValue(true)"
              type="button"
              class="hasomed-text-button">
              <span class="hasomed-button-text">Ja</span>
            </button>
            <button
              [ngClass]="{
                'hasomed-button-primary':
                  userForm.get('medicalAuthorizationOnly').value === false,
                'hasomed-button-white-grey':
                  userForm.get('medicalAuthorizationOnly').value !== false,
              }"
              (click)="userForm.get('medicalAuthorizationOnly').setValue(false)"
              type="button"
              class="hasomed-text-button">
              <span class="hasomed-button-text">Nein</span>
            </button>
          </div>
          <app-error-message
            [formField]="
              userForm.get('medicalAuthorizationOnly')
            "></app-error-message>
        </div>
      </div>
      <mat-divider></mat-divider>

      <!-- expertises -->
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">
            Fachkunde
            <span *ngIf="isRequired(userForm.get('expertises'))">*</span>
          </span>
          <span class="label-description">
            {{ 'Fachkunde' + roleBasedLabel }}.
          </span>
        </div>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <button
              *ngFor="let expertise of expertises"
              [ngClass]="{
                'hasomed-button-primary': userForm
                  .get('expertises')
                  .value?.includes(expertise.id),
                'hasomed-button-white-grey': !userForm
                  .get('expertises')
                  .value?.includes(expertise.id),
              }"
              (click)="changeArrayFormFieldValue(expertise.id, 'expertises')"
              class="hasomed-text-button"
              type="button">
              <span class="hasomed-button-text">
                {{ expertise.name }}
              </span>
            </button>
          </div>
          <app-error-message
            [formField]="userForm.get('expertises')"></app-error-message>
        </div>
      </div>
      <mat-divider></mat-divider>

      <!-- procedures -->
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">
            Richtlinienverfahren
            <span *ngIf="isRequired(userForm.get('procedures'))">*</span>
          </span>
          <span class="label-description">
            {{ 'Richtlinienverfahren' + roleBasedLabel }}.
          </span>
        </div>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <button
              *ngFor="let procedure of procedures"
              [ngClass]="{
                'hasomed-button-primary': userForm
                  .get('procedures')
                  .value?.includes(procedure.id),
                'hasomed-button-white-grey': !userForm
                  .get('procedures')
                  .value?.includes(procedure.id),
              }"
              (click)="changeArrayFormFieldValue(procedure.id, 'procedures')"
              class="hasomed-text-button"
              type="button">
              <span class="hasomed-button-text">
                {{ procedure.name }}
              </span>
            </button>
          </div>
          <app-error-message
            [formField]="userForm.get('procedures')"></app-error-message>
        </div>
      </div>
      <mat-divider></mat-divider>

      <!-- ptkConsultation -->
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">
            PTK Hinzuziehung
            <span *ngIf="isRequired(userForm.get('ptkConsultation'))">*</span>
          </span>
          <span class="label-description">
            {{ 'PTK Hinzuziehung' + roleBasedLabel }}.
          </span>
        </div>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <mat-checkbox
              id="ptkConsultation"
              class="input-checkbox"
              type="checkbox"
              color="primary"
              formControlName="ptkConsultation">
              <div class="checkbox-title">Liegt vor seit dem:</div>
            </mat-checkbox>
            <mat-form-field
              [class.hasomed-date-field-empty]="
                !userForm.get('ptkConsultationDate').value
              "
              class="hasomed-date-field">
              <input
                [max]="today"
                [matDatepicker]="ptkConsultationDatePicker"
                class="hasomed-date-input"
                maxlength="10"
                size="9"
                matInput
                placeholder="Wählen Sie ein Datum"
                formControlName="ptkConsultationDate" />
              <mat-datepicker-toggle
                [for]="ptkConsultationDatePicker"
                class="hasomed-date-toggle"
                matIconPrefix>
                <mat-icon
                  matDatepickerToggleIcon
                  class="calendar-toggle-icon fa-light fa-calendar">
                </mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker
                #ptkConsultationDatePicker
                startView="multi-year"></mat-datepicker>
            </mat-form-field>
          </div>
          <app-error-message
            [formField]="userForm.get('ptkConsultation')"></app-error-message>
        </div>
      </div>
      <mat-divider></mat-divider>

      <!-- confirmed -->
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">
            Bestätigung durch AWA oder erweitertem Vorstand
            <span *ngIf="isRequired(userForm.get('confirmed'))">*</span>
          </span>
          <span class="label-description">
            {{
              'Bestätigung durch AWA oder erweitertem Vorstand' +
                roleBasedLabel
            }}.
          </span>
        </div>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <mat-checkbox
              id="confirmed"
              class="input-checkbox"
              type="checkbox"
              color="primary"
              formControlName="confirmed">
              <div class="checkbox-title">Liegt vor seit dem:</div>
            </mat-checkbox>
            <mat-form-field
              [class.hasomed-date-field-empty]="
                !userForm.get('confirmationDate').value
              "
              class="hasomed-date-field">
              <input
                [max]="today"
                [matDatepicker]="confirmationDateDatePicker"
                class="hasomed-date-input"
                maxlength="10"
                size="9"
                matInput
                placeholder="Wählen Sie ein Datum"
                formControlName="confirmationDate" />
              <mat-datepicker-toggle
                [for]="confirmationDateDatePicker"
                class="hasomed-date-toggle"
                matIconPrefix>
                <mat-icon
                  matDatepickerToggleIcon
                  class="calendar-toggle-icon fa-light fa-calendar">
                </mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker
                #confirmationDateDatePicker
                startView="multi-year"></mat-datepicker>
            </mat-form-field>
          </div>
          <app-error-message
            [formField]="userForm.get('confirmed')"></app-error-message>
        </div>
      </div>
      <mat-divider></mat-divider>

      <!-- landlineNumber -->
      <div class="formular-row">
        <label class="formular-row-label" for="landlineNumber">
          <span class="label-title">
            Telefonnummer (Festnetz)
            <span *ngIf="isRequired(userForm.get('landlineNumber'))">*</span>
          </span>
          <span class="label-description">
            {{ 'Festnetznummer' + roleBasedLabel }}.
          </span>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-icon-input">
            <i class="fa-light fa-phone input-icon"></i>
            <input
              id="landlineNumber"
              class="hasomed-text-input"
              type="text"
              formControlName="landlineNumber"
              placeholder="01234/56789" />
          </div>
          <app-error-message
            [formField]="userForm.get('landlineNumber')"></app-error-message>
        </div>
      </div>
      <mat-divider></mat-divider>

      <!-- mobileNumber -->
      <div class="formular-row">
        <label class="formular-row-label" for="mobileNumber">
          <span class="label-title">
            Telefonnummer (Mobile)
            <span *ngIf="isRequired(userForm.get('mobileNumber'))">*</span>
          </span>
          <span class="label-description">
            {{ 'Mobilfunknummer' + roleBasedLabel }}.
          </span>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-icon-input">
            <i class="fa-light fa-mobile-screen-button input-icon"></i>
            <input
              id="mobileNumber"
              class="hasomed-text-input"
              type="text"
              formControlName="mobileNumber"
              placeholder="+49 123 / 4567890" />
          </div>
          <app-error-message
            [formField]="userForm.get('mobileNumber')"></app-error-message>
        </div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

    <ng-container *ngIf="user.roleId == Role.STUDENT">
      <!-- Education Type -->
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">
            Aus- und Ausbildungsgang
            <span *ngIf="isRequired(userForm.get('educationCourse'))">*</span>
          </span>
          <span class="label-description">
            {{ 'Aus- oder Weiterbildungsgang' + roleBasedLabel }}.
          </span>
        </div>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <eleguide-select
              [control]="educationCourseControl"
              controlId="educationCourse"
              [options]="availableEducationCourseSelectOptions"
              placeholder="Aus- und Weiterbildungsgang"
              [showNullOption]="true"
              nullOptionLabel="-- Kein Bildungsgang --"></eleguide-select>
          </div>
          <app-error-message
            [formField]="userForm.get('educationCourse')"></app-error-message>
        </div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

    <!-- Measles Protection -->
    <!-- <div class="formular-row">
			<div class="formular-row-label">
				<span class="label-title">Masernstatus</span>
				<span class="label-description">
					Impfschutz für Masern
				</span>
			</div>
			<div class="hasomed-input-field measles-protection">
				<div class="formular-row-input">
					<mat-slide-toggle hideIcon
						formControlName="measlesProtection">
						<span class="hasomed-text-small">
							{{
                userForm.get('measlesProtection').value
                  ? 'Geprüft'
                  : 'Nicht geprüft'
              }}
						</span>
					</mat-slide-toggle>
				</div>
				<app-error-message [formField]="userForm.get('measlesProtection')"></app-error-message>
			</div>
		</div>
		<mat-divider></mat-divider> -->

    <!-- Address -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Adresse</span>
        <span class="label-description">
          {{ 'Anschrift' + roleBasedLabel }} für Schriftverkehr.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input-columns">
          <div class="formular-row-input">
            <input
              id="street"
              class="hasomed-text-input"
              type="text"
              size="15"
              formControlName="street"
              placeholder="Straße" />
            <input
              id="houseNumber"
              class="hasomed-text-input"
              type="text"
              maxlength="5"
              size="5"
              formControlName="houseNumber"
              placeholder="Hausnummer" />
          </div>
          <div class="formular-row-input">
            <input
              id="addressAddition"
              class="hasomed-text-input"
              type="text"
              size="10"
              formControlName="addressAddition"
              placeholder="Adresszusatz (Etage, Appartment)" />
          </div>
          <div class="formular-row-input">
            <input
              id="zipCode"
              class="hasomed-text-input"
              type="text"
              size="5"
              formControlName="zipCode"
              placeholder="PLZ" />
            <input
              id="city"
              class="hasomed-text-input"
              type="text"
              size="15"
              formControlName="city"
              placeholder="Ort" />
          </div>
          <div class="formular-row-input">
            <country-select
              class="flex-1"
              [control]="countryControl"></country-select>
          </div>
        </div>
        <app-error-message
          [formField]="userForm.get('street')"></app-error-message>
        <app-error-message
          [formField]="userForm.get('houseNumber')"></app-error-message>
        <app-error-message
          [formField]="userForm.get('addressAddition')"></app-error-message>
        <app-error-message
          [formField]="userForm.get('zipCode')"></app-error-message>
        <app-error-message
          [formField]="userForm.get('city')"></app-error-message>
        <app-error-message
          [formField]="userForm.get('country')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <ng-container *ngIf="user.roleId == Role.STUDENT">
      <!-- Kontoinhaber -->
      <div
        class="formular-row"
        *appCheckPermissions="{
          feature: feature.STUDENT_ADMINISTRATION,
          permission: permission.ADMIN,
        }">
        <div class="formular-row-label">
          <span class="label-title">Bankverbindung</span>
          <span class="label-description">
            {{ 'Daten für den Zahlungsverkehr' + roleBasedLabel }}.
          </span>
        </div>
        <div class="hasomed-input-field">
          <div class="formular-row-input-columns">
            <div class="hasomed-input-field">
              <input
                id="accountHolder"
                class="hasomed-text-input flex-grow"
                formControlName="accountHolder"
                placeholder="Kontoinhaber" />
              <app-error-message
                [formField]="userForm.get('accountHolder')"></app-error-message>
            </div>
            <div class="hasomed-input-field">
              <input
                id="iban"
                class="hasomed-text-input flex-grow"
                formControlName="iban"
                placeholder="IBAN" />
              <app-error-message
                [formField]="userForm.get('iban')"></app-error-message>
            </div>
            <div class="hasomed-input-field">
              <input
                id="bic"
                class="hasomed-text-input flex-grow"
                formControlName="bic"
                placeholder="BIC" />
              <app-error-message
                [formField]="userForm.get('bic')"></app-error-message>
            </div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

    <!-- Document -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Dokumente</span>
        <span class="label-description">
          {{ 'Hochgeladene Dokumente' + roleBasedLabel }}.
        </span>
      </div>
      <span
        *ngIf="!user?.files || user?.files?.length == 0; else files"
        class="detail-value">
        Keine Dokumente hochgeladen.
      </span>
      <ng-template #files>
        <div class="detail-value-files">
          <app-file-view
            *ngFor="let file of user?.files"
            [file]="file"
            (downloadFile)="onDownloadFile($event)"
            (openFile)="onOpenFile($event)"></app-file-view>
        </div>
      </ng-template>
    </div>

    <ng-container *ngIf="user.roleId == Role.STUDENT">
      <mat-divider></mat-divider>

      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title"> Account-Einstellungen </span>
          <span class="label-description">
            {{ 'Diverse Einstellungen' + roleBasedLabel }}.
          </span>
        </div>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <button
              *appCheckPermissions="{
                feature: feature.STUDENT_ADMINISTRATION,
                permission: permission.ADMIN,
              }"
              (click)="onChangeInstitut()"
              type="button"
              class="hasomed-text-button hasomed-button-white-red button-full-width">
              <i class="fa-light fa-right-left-large hasomed-button-icon"></i>
              <span class="hasomed-button-text">
                Kandidat möchte Institut wechseln
              </span>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</form>
