import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsArticleModel } from 'src/app/models/news-article.model';
import { Role } from 'src/app/models/permission.model';
import { UserService } from 'src/app/services/user.service';
import { getFullName } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-news-article',
  templateUrl: './news-article.component.html',
  styleUrls: ['./news-article.component.scss'],
})
export class NewsArticleComponent {
  public showLabels = false;

  // import from utils
  public getFullName = getFullName;

  @Input() newsArticle: NewsArticleModel;
  @Input() isSelected = false;
  @Output() selectedNewsArticle = new EventEmitter<NewsArticleModel>();

  constructor(
    public userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    if (this.userService.currentUser.roleId == Role.ADMINISTRATOR) {
      this.showLabels = true;
    }
  }

  /**
   * navToArticle
   * navigates to the news article
   * @param newsArticle
   * @returns void
   */
  public navToArticle(newsArticle: NewsArticleModel) {
    this.router.navigate(['../institute-news', btoa(String(newsArticle.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onSelectNewsArticle
   * emits the selected news article
   * @param newsArticle
   * @returns void
   */
  public onSelectNewsArticle(newsArticle: NewsArticleModel): void {
    this.selectedNewsArticle.emit(newsArticle);
  }
}
