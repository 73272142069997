import { Pipe, PipeTransform } from '@angular/core';
import { ExpertiseModel } from '../models/expertise.model';
import { Filter, FilterDateRange, FilterType } from '../models/filter.model';
import { Label } from '../models/label.model';
import { ProcedureModel } from '../models/procedure.model';
import { AdditionalQualification, UserModel } from '../models/user.model';
import { isDateInRange } from '../utils/date.utils';

@Pipe({
  name: 'filterLecturer',
})
export class FilterLecturerPipe implements PipeTransform {
  transform(lecturers: UserModel[], filter: Filter[]): UserModel[] {
    if (!lecturers || !filter) {
      return lecturers;
    }

    return lecturers.filter(lecturer => {
      return filter.every(filter => {
        if (filter.value === null) {
          return true;
        }

        switch (filter.type) {
          case FilterType.DATE_RANGE:
            return isDateInRange(
              lecturer.entryDate,
              lecturer.entryDate,
              filter.value as FilterDateRange
            );
          case FilterType.LABEL:
            if (this.isLabel(filter.value)) {
              return lecturer.label?.id === filter.value.id;
            }
            return false;
          case FilterType.ADDITIONAL_QUALIFICATION:
            return lecturer.additionalQualifications?.some(
              qualification =>
                qualification.id ===
                (filter.value as AdditionalQualification)?.id
            );
          case FilterType.PROCEDURE:
            return lecturer.procedures?.some(
              procedure => procedure.id === (filter.value as ProcedureModel)?.id
            );
          case FilterType.EXPERTISE:
            return lecturer.expertises?.some(
              expertise => expertise.id === (filter.value as ExpertiseModel)?.id
            );
          case FilterType.ONLY_DOCTORS:
            return lecturer.medicalAuthorizationOnly === true;
          case FilterType.PENDING_ELOGS:
            return lecturer.courses?.some(
              course => course.elogStatusCounts.pending > 0
            );
          default:
            return true;
        }
      });
    });
  }

  private isLabel(obj: any): obj is Label {
    return obj && typeof obj === 'object' && 'name' in obj && 'color' in obj;
  }
}
