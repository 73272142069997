<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Rauminformationen</h1>
      <h3 class="hasomed-text-h3">Sehen Sie sich die Rauminformationen an.</h3>
    </div>
    <div class="header-buttons">
      <button
        *appCheckPermissions="{
          feature: feature.INSTITUTE,
          permission: permission.ADMIN,
        }"
        (click)="onGoBack()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Zurück</span>
      </button>
      <button
        *appCheckPermissions="{
          feature: feature.INSTITUTE,
          permission: permission.ADMIN,
        }"
        [disabled]="isLoading"
        (click)="onEdit()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-pen hasomed-button-icon"></i>
        <span class="hasomed-button-text">Bearbeiten</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content-details">
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Raum</span>
        <span class="label-description">Name des Raums.</span>
      </div>
      <div class="detail-value">{{ room?.name }}</div>
    </div>
    <mat-divider></mat-divider>

    <!-- Description -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Raumtyp</span>
        <span class="label-description">
          Der Raumtyps beschränkt dessen Buchbarkeit. Im Raumtyp “Kurs” können
          beispielsweise nur Kurse statt finden.
        </span>
      </div>
      <div class="detail-value">
        {{ room?.roomType?.name ?? 'Kein Raumtyp hinterlegt.' }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- floor -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Etage</span>
        <span class="label-description">Etage des Raums.</span>
      </div>
      <div class="detail-value">
        <span>
          {{ room?.floor ?? 'Keine Etage hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- building -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Gebäude</span>
        <span class="label-description">Gebäude des Raums.</span>
      </div>
      <div class="detail-value">
        <span>
          {{ room?.building ?? 'Kein Gebäude hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- seatNumber -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Maximale Anzahl der Teilnehmer </span>
        <span class="label-description">
          Anzahl der maximalen Teilnehmerplätze in diesem Raum.
        </span>
      </div>
      <div class="detail-value">
        <span>
          {{ room?.seatNumber ?? 'Keine Platzanzahl hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- equipment -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Ausstattung</span>
        <span class="label-description"> Ausstattung des Raums. </span>
      </div>
      <div class="detail-value">
        <span>
          {{
            room?.equipment && room?.equipment !== ''
              ? room?.equipment
              : 'Keine Ausstattung hinterlegt.'
          }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Address -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Adresse</span>
        <span class="label-description">Adresse des Raums.</span>
      </div>
      <span
        *ngIf="
          !room?.address?.street &&
            !room?.address?.houseNumber &&
            !room?.address?.addressAddition &&
            !room?.address?.zipCode &&
            !room?.address?.city &&
            !room?.address?.country;
          else address
        "
        class="detail-value">
        Nicht hinterlegt.
      </span>
      <ng-template #address>
        <div class="detail-value-address">
          <span *ngIf="room?.address?.street || room?.address?.houseNumber">
            {{ room?.address?.street }}
            {{ room?.address?.houseNumber }}
          </span>
          <span *ngIf="room?.address?.addressAddition">
            {{ room?.address?.addressAddition }}
          </span>
          <span *ngIf="room?.address?.zipCode || room?.address?.city">
            {{ room?.address?.zipCode }}
            {{ room?.address?.city }}
          </span>
          <span *ngIf="room?.address?.country">
            {{ room?.address?.country }}
          </span>
        </div>
      </ng-template>
    </div>
    <mat-divider></mat-divider>

    <!-- openingHours -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Zeitliche Verfügbarkeit des Raums </span>
        <span class="label-description">
          In diesen Zeiten ist der Raum buchbar.
        </span>
      </div>
      <div class="opening-hours">
        <span
          *ngIf="!room?.openingHours || room?.openingHours?.length == 0"
          class="detail-value">
          Keine Öffnungszeiten hinterlegt.
        </span>
        <div
          *ngFor="let openingHour of room?.openingHours"
          class="opening-hour">
          <div class="day-box">
            <i class="fa-light fa-calendar"></i>
            <span>
              {{ getGermanWeekday(openingHour.dayOfWeek) }}
            </span>
          </div>
          <div class="time-box">
            <i class="fa-light fa-clock-seven"></i>
            <span>
              {{ openingHour.openingTime }}
            </span>
          </div>
          <div class="time-box">
            <i class="fa-light fa-clock-seven"></i>
            <span>
              {{ openingHour.closingTime }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- responsiblePerson -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Verantwortlicher</span>
        <span class="label-description"> Verantwortlicher des Raums. </span>
      </div>
      <div class="detail-value">
        <span>
          {{ room?.responsiblePerson ?? 'Kein Verantwortlicher hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- isAvailable -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Verfügbarkeit</span>
        <span class="label-description">
          Dieser Status zeigt, ob der Raum aktuell verfügbar ist.
        </span>
      </div>
      <div class="detail-value">
        {{ room?.isAvailable ? 'Aktiv' : 'Inaktiv' }}
      </div>
    </div>
  </div>
</div>
