import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { CourseEvent } from 'src/app/models/course.model';
import { Role } from 'src/app/models/permission.model';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { RoomService } from 'src/app/services/room.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-course-events',
  templateUrl: './course-events.component.html',
  styleUrl: './course-events.component.scss',
})
export class CourseEventsComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<CourseEvent> =
    new MatTableDataSource<CourseEvent>();
  public columnsToDisplay = ['date', 'time', 'room', 'participantAmount'];
  public isStudent: boolean;
  public isLoading = true;
  public isMobile = false;

  public currentPage = 0;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private courseService: CourseService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private roomService: RoomService,
    private router: Router,
    private cancellationService: CancellationService,
    public mobileUiService: MobileUiService
  ) {}

  public ngOnInit(): void {
    this.isStudent = this.userService.currentUser.roleId === Role.STUDENT;

    this.initTable();

    this.activatedRoute.parent?.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        const courseId = +atob(params.get('id'));
        courseId && this.getCourseEvents(courseId);
      });

    this.viewChanges();
  }

  /**
   * subscribes to the current view changes
   * sets the columns to display based on the current view
   * sets the isMobile variable based on the current view
   * @returns void
   */
  private viewChanges(): void {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.initTableColumns(currentView);
        this.isMobile = currentView === 'mobile';
      });
  }

  /**
   * initializes the columns to display based on the current view and the user role
   * @param currentView The current view
   * @returns void
   */
  private initTableColumns(currentView: string): void {
    if (currentView === 'mobile') {
      this.columnsToDisplay = ['date'];
    } else if (currentView === 'tablet') {
      this.columnsToDisplay = ['date', 'time', 'room'];
    } else {
      this.columnsToDisplay = ['date', 'time', 'room', 'participantAmount'];
    }

    const roleId = this.userService.currentUser.roleId;

    let elogs = 0;
    this.dataSource.data?.forEach((event: any) => {
      if (event.eLogStatus !== null) {
        elogs++;
      }
    });

    if ((roleId === Role.STUDENT && elogs > 0) || roleId !== Role.STUDENT) {
      this.columnsToDisplay.push('eLog');
    }
    if (roleId !== Role.STUDENT) {
      this.columnsToDisplay.push('actions');
    } else {
      if (!this.columnsToDisplay.includes('room')) {
        this.columnsToDisplay.push('room');
      }
    }
  }

  /**
   * initializes the sorting and pagination of the table
   * @returns void
   */
  private initTable(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'date':
          return moment(item.startDate);
        case 'lecturer':
          return item.lecturers[0].name.firstName;
        case 'room':
          return item.room.name;
        case 'participantAmount':
          return this.getParticipantAmount(item);
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  /**
   * getCourseEvents
   * get all course events for the course
   * @param courseId
   */
  private getCourseEvents(courseId: number) {
    this.courseService
      .getCourseEventDates(courseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.dataSource.data = response.body
            ? await Promise.all(
                response.body.map(async (event: any) => {
                  return {
                    ...event,
                    room: event.room
                      ? await this.roomService.parseBackendRoom(event.room)
                      : null,
                    lecturers: event.lecturers
                      ? await Promise.all(
                          event.lecturers.map(async (lecturer: any) => {
                            return await this.userService.parseBackendUser(
                              lecturer
                            );
                          })
                        )
                      : [],
                  };
                })
              )
            : [];

          this.isLoading = false;

          this.initTableColumns(this.mobileUiService.currentView$.value);

          this.jumpToPageWithClosestEvent();
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Kurstermine konnten nicht geladen werden.'
          );
          this.isLoading = false;
        },
      });
  }

  /**
   * finds the closest event to today and jumps to the page where the event is located
   * @returns void
   */
  private jumpToPageWithClosestEvent(): void {
    // find date closest to today
    const closestEventIndex = this.dataSource.data.findIndex(event =>
      moment(event.startDate).isAfter(moment())
    );

    if (closestEventIndex) {
      const pageIndex = Math.floor(
        closestEventIndex / this.dataSource.paginator.pageSize
      );
      this.currentPage = pageIndex;
    }
  }

  /**
   * navigate to eLog page
   * @param event
   */
  public viewELog(event: CourseEvent) {
    this.router.navigate(['../elog', btoa(event.id.toString())], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * get the amount of participants for the event
   * @param event
   */
  public getParticipantAmount(event: CourseEvent): number {
    if (!event.elogStatusCounts) {
      return 0;
    }
    return (
      event.elogStatusCounts.absent +
      event.elogStatusCounts.checked +
      event.elogStatusCounts.excused +
      event.elogStatusCounts.pending +
      event.elogStatusCounts.unexcused +
      event.elogStatusCounts.upcoming
    );
  }

  /**
   * cancel all requests and unsubscribe from observables
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
