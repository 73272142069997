import { EventLocation } from '../enums/event-location.enum';
import {
  BaseCalendarEventDateModel,
  CalendarEventDateModel,
  FullCalendarEventColorModel,
  RoomCalenderEventDateModel,
} from '../models/calendar-event.model';
import { EventDateType } from '../models/event.model';
import { getRoomNameWithFloor } from './room.utils';

export const CalendarEventColors = {
  course: {
    backgroundColor: '#eff3f8',
    borderColor: '#1c53f3',
    textColor: '#1c53f3',
  },
  patient: {
    backgroundColor: '#f9f0e4',
    borderColor: '#f08a00',
    textColor: '#f08a00',
  },
  supervision: {
    backgroundColor: '#eff6e8',
    borderColor: '#79b63f',
    textColor: '#79b63f',
  },
  event: {
    backgroundColor: '#eff3f8',
    borderColor: '#1cc4f3',
    textColor: '#1cc4f3',
  },
  other: {
    backgroundColor: '#eff6e8',
    borderColor: '#00973a',
    textColor: '#00973a',
  },
  holiday: {
    backgroundColor: '#f5f5f5',
    borderColor: '#575858',
    textColor: '#575858',
  },
  canceled: {
    backgroundColor: '#ffeeee',
    borderColor: '#f93816',
    textColor: '#f93816',
  },
};

/**
 * returns the title of the calendar event based on the event date type
 * @param calendarEventDate calendar event date
 * @returns string
 */
export function getCalendarEventTitle(
  calendarEventDate: CalendarEventDateModel
): string {
  switch (calendarEventDate.eventDateType) {
    case EventDateType.COURSE:
      return calendarEventDate.courseTitle ?? 'Kurs';
    case EventDateType.PATIENT_SESSION:
      return 'Patient';
    case EventDateType.SUPERVISION:
      return 'Supervision';
    case EventDateType.GROUP_SUPERVISION:
      return 'Supervision';
    case EventDateType.APPOINTMENT_EVENT:
      return calendarEventDate.appointmentTitle ?? 'Veranstaltung';
    case EventDateType.APPOINTMENT_OTHER:
      return calendarEventDate.appointmentTitle ?? 'Sonstiges';
    case EventDateType.APPOINTMENT_HOLIDAY:
      return calendarEventDate.appointmentTitle ?? 'Feiertag';
    default:
      return '';
  }
}

export function getRoomCalendarEventTitle(
  eventDate: RoomCalenderEventDateModel
): string {
  switch (eventDate.eventDateType) {
    case EventDateType.COURSE:
      return 'Kurs';
    case EventDateType.PATIENT_SESSION:
      return 'Patient';
    case EventDateType.SUPERVISION:
      return 'Supervision';
    case EventDateType.GROUP_SUPERVISION:
      return 'Supervision';
    case EventDateType.APPOINTMENT_EVENT:
      return 'Veranstaltung';
    case EventDateType.APPOINTMENT_OTHER:
      return 'Sonstiges';
    case EventDateType.APPOINTMENT_HOLIDAY:
      return 'Feiertag';
    default:
      return '';
  }
}

/**
 * returns the color of the calendar event based on the event date type
 * @param eventDate calendar event date
 * @returns CalendarEventColors
 */
export function getCalendarEventColor(
  eventDate: BaseCalendarEventDateModel
): FullCalendarEventColorModel {
  if (eventDate.isCanceled) {
    return CalendarEventColors.canceled;
  }

  switch (eventDate.eventDateType) {
    case EventDateType.COURSE:
      return CalendarEventColors.course;
    case EventDateType.PATIENT_SESSION:
      return CalendarEventColors.patient;
    case EventDateType.SUPERVISION:
      return CalendarEventColors.supervision;
    case EventDateType.GROUP_SUPERVISION:
      return CalendarEventColors.supervision;
    case EventDateType.SUPERVISION:
      return CalendarEventColors.patient;
    case EventDateType.GROUP_SUPERVISION:
      return CalendarEventColors.patient;
    case EventDateType.APPOINTMENT_EVENT:
      return CalendarEventColors.event;
    case EventDateType.APPOINTMENT_OTHER:
      return CalendarEventColors.other;
    case EventDateType.APPOINTMENT_HOLIDAY:
      return CalendarEventColors.holiday;
    default:
      return CalendarEventColors.course;
  }
}

/**
 * returns the room name of the calendar event based on the event date location
 * @param eventDate calendar event date
 * @returns string
 */
export function getCalendarEventRoomName(
  eventDate: BaseCalendarEventDateModel
): string {
  switch (eventDate.location) {
    case EventLocation.ONLINE:
      return 'online';
    case EventLocation.ONSITE:
      return getRoomNameWithFloor(eventDate.room);
    case EventLocation.HYBRID:
      return `${getRoomNameWithFloor(eventDate.room)} / online`;
    default:
      return '';
  }
}
