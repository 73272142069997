import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MaterialModule } from 'src/app/modules/material.module';
import { SharedModule } from 'src/app/modules/shared.module';
import { FilterCoursePipe } from 'src/app/pipes/filter-course.pipe';
import { FilterRoomOrganizationPipe } from 'src/app/pipes/filter-room-organization.pipe';
import { FilterRoomOverviewPipe } from 'src/app/pipes/filter-room-overview.pipe';
import { CustomTimePipe } from '../../../pipes/custom-time.pipe';
import { AdvancedLecturerDetailsComponent } from './advanced-lecturers/advanced-lecturer-details/advanced-lecturer-details.component';
import { AdvancedLecturersComponent } from './advanced-lecturers/advanced-lecturers.component';
import { AmbulatoryPartComponent } from './ambulatory-part/ambulatory-part.component';
import { CreateEditPatientAppointmentComponent } from './ambulatory-part/create-edit-patient-appointment/create-edit-patient-appointment.component';
import { CreateEditTreatmentCaseComponent } from './ambulatory-part/create-edit-treatment-case/create-edit-treatment-case.component';
import { PatientEventDateDetailComponent } from './ambulatory-part/patient-event-date-details/patient-event-date-details.component';
import { TreatmentCaseEventDatesComponent } from './ambulatory-part/treatment-case-event-dates/treatment-case-event-dates.component';
import { CooperationPartnerDetailsComponent } from './cooperation-partner/cooperation-partner-details/cooperation-partner-details.component';
import { CooperationPartnerComponent } from './cooperation-partner/cooperation-partner.component';
import { CreateEditCooperationPartnerComponent } from './cooperation-partner/create-edit-cooperation-partner/create-edit-cooperation-partner.component';
import { CourseAdministrationComponent } from './course-administration/course-administration.component';
import { CreateEditCourseComponent } from './course-administration/create-edit-course/create-edit-course.component';
import { CourseDescriptionComponent } from './course-details/course-description/course-description.component';
import { CourseDetailsComponent } from './course-details/course-details.component';
import { CourseDocumentsComponent } from './course-details/course-documents/course-documents.component';
import { CourseEventsComponent } from './course-details/course-events/course-events.component';
import { CourseLecturersComponent } from './course-details/course-lecturers/course-lecturers.component';
import { CourseParticipantsComponent } from './course-details/course-participants/course-participants.component';
import { ElogComponent } from './course-details/elog/elog.component';
import { EducationComponent } from './education.component';
import { LecturerDetailModule } from './lecturers/lecturer-detail/lecturer-detail.module';
import { LecturersComponent } from './lecturers/lecturers.component';
import { CreateEditPracticalWorkComponent } from './practical-work/create-edit-practical-work/create-edit-practical-work.component';
import { PracticalWorkComponent } from './practical-work/practical-work.component';
import { RoomOrganizationComponent } from './room-organization/room-organization.component';
import { CreateEditSelfAwarenessEntryComponent } from './self-awareness/create-edit-self-awareness-entry/create-edit-self-awareness-entry.component';
import { SelfAwarenessComponent } from './self-awareness/self-awareness.component';
import { StudentDetailModule } from './students/student-detail/student-detail.module';
import { StudentsComponent } from './students/students.component';
import { CreateEditSupervisionComponent } from './supervision/create-edit-supervision/create-edit-supervision.component';
import { EditSupervisionEventDateComponent } from './supervision/edit-supervision-event-date/edit-supervision-event-date.component';
import { SupervisionEventDateDetailsComponent } from './supervision/supervision-event-date-details/supervision-event-date-details.component';
import { SupervisionComponent } from './supervision/supervision.component';
import { TheoreticalEducationComponent } from './theoretical-education/theoretical-education.component';

@NgModule({
  declarations: [
    EducationComponent,
    TheoreticalEducationComponent,
    CreateEditCourseComponent,
    RoomOrganizationComponent,
    PracticalWorkComponent,
    CreateEditPracticalWorkComponent,
    AmbulatoryPartComponent,
    CreateEditTreatmentCaseComponent,
    SelfAwarenessComponent,
    CreateEditSelfAwarenessEntryComponent,
    LecturersComponent,
    StudentsComponent,
    CourseAdministrationComponent,
    CourseDetailsComponent,
    CourseEventsComponent,
    CourseDescriptionComponent,
    CourseLecturersComponent,
    CourseParticipantsComponent,
    CourseDocumentsComponent,
    ElogComponent,
    CreateEditCooperationPartnerComponent,
    CooperationPartnerComponent,
    CooperationPartnerDetailsComponent,
    CreateEditPatientAppointmentComponent,
    TreatmentCaseEventDatesComponent,
    AdvancedLecturersComponent,
    AdvancedLecturerDetailsComponent,
    PatientEventDateDetailComponent,
    SupervisionComponent,
    CreateEditSupervisionComponent,
    EditSupervisionEventDateComponent,
    SupervisionEventDateDetailsComponent,
  ],
  exports: [EducationComponent],
  providers: [],
  imports: [
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    CustomTimePipe,
    FilterRoomOverviewPipe,
    FilterCoursePipe,
    FilterRoomOrganizationPipe,
    StudentDetailModule,
    LecturerDetailModule,
    EditorModule,
  ],
})
export class EducationModule {}
