import { CooperationPartnerModel } from '../models/cooperation-partner.model';

export function getFullContactPersonName(
  cooperationPartner: CooperationPartnerModel
): string {
  if (!cooperationPartner || !cooperationPartner.contactPerson) {
    return '';
  }
  let fullName = '';
  if (cooperationPartner.contactPerson.academicTitle) {
    fullName += cooperationPartner.contactPerson.academicTitle + ' ';
  }
  if (cooperationPartner.contactPerson.firstName) {
    fullName += cooperationPartner.contactPerson.firstName + ' ';
  }
  if (cooperationPartner.contactPerson.lastName) {
    fullName += cooperationPartner.contactPerson.lastName;
  }
  return fullName.trim();
}

/**
 * getAllProcedures
 * returns all procedures of a user as a string
 * @param cooperationPartner
 * @returns string
 */
export function getAllProcedures(
  cooperationPartner: CooperationPartnerModel
): string | null {
  if (
    !cooperationPartner ||
    !cooperationPartner.procedures ||
    cooperationPartner.procedures?.length === 0
  ) {
    return null;
  }

  return cooperationPartner.procedures.map(p => p.name).join(', ');
}

/**
 * getAllExpertises
 * returns all expertises of a user as a string
 * @param cooperationPartner
 * @returns string
 */
export function getAllExpertises(
  cooperationPartner: CooperationPartnerModel
): string | null {
  if (
    !cooperationPartner ||
    !cooperationPartner.expertises ||
    cooperationPartner.expertises?.length === 0
  ) {
    return null;
  }

  return cooperationPartner.expertises.map(e => e.name).join(', ');
}
