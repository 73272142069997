import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { ImageDetailDialogComponent } from 'src/app/components/shared-components/image-detail-dialog/image-detail-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { FileModel } from 'src/app/models/file.model';
import { Role } from 'src/app/models/permission.model';
import { UserModel } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-study-administration-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss'],
})
export class PersonalDataComponent implements OnInit, OnDestroy {
  public initialFormValues: {};
  public user: UserModel;
  public today = moment();
  public isLoading = true;
  public uploadedFiles: FileModel[] = [];
  public existingFiles: FileModel[] = [];
  public imageUrl: string | ArrayBuffer | null;
  public allowedFileTypes: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'GIF', mimeType: 'image/gif' },
    { type: 'WEBP', mimeType: 'image/webp' },
  ];
  public allowedFileTypesDocuments: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
  ];

  public Role = Role;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    this.getCurrentUserData();
  }

  /**
   * getCurrentUserData
   * get user data of current user
   * @returns void
   */
  public getCurrentUserData(): void {
    this.userService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.user = await this.userService.parseBackendUser(response.body);

          this.user.files?.forEach(file => {
            this.existingFiles.push(file);
          });

          this.isLoading = false;

          // if measlesProtection is not set and currentUser is Student, show alert
          // if (
          //   this.user.measlesProtection === false &&
          //   (this.userService.currentUserIsStudent() ||
          //     this.userService.currentUserIsLecturer())
          // ) {
          //   this.alertService.showInfoAlert(
          //     'Impfnachweis fehlt',
          //     'Bitte reichen Sie Ihren Impfnachweis für Masern bei der Verwaltung ein.',
          //     20000
          //   );
          // }
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Ihre Daten konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * onEdit
   * navigate to edit page
   * @returns void
   */
  public onEdit(): void {
    this.router.navigate(['edit'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * openProfilePictureDialog
   * opens the profile picture dialog
   * @returns void
   */
  public openProfilePictureDialog(): void {
    this.dialog.open(ImageDetailDialogComponent, {
      data: {
        image: this.user.profilePicture,
        viewOnly: true,
      },
    });
  }

  /**
   * onOpenFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenFile(file: FileModel): void {
    this.userService.openFile(this.user?.id, file.id);
  }

  /**
   * onDownloadFile
   * download a file
   * @param file
   * @returns void
   */
  public onDownloadFile(file: FileModel): void {
    this.userService.downloadFile(this.user?.id, file.id);
  }

  /**
   * unsubscribe from all subscriptions and cancel all requests
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
