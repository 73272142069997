import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { UserModel } from 'src/app/models/user.model';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';
import {
  getAllAdditionalQualifications,
  getFullName,
} from 'src/app/utils/user.utils';

@Component({
  selector: 'app-course-lecturers',
  templateUrl: './course-lecturers.component.html',
  styleUrls: ['./course-lecturers.component.scss'],
})
export class CourseLecturersComponent implements OnInit, OnDestroy {
  public dataSource: MatTableDataSource<UserModel> =
    new MatTableDataSource<UserModel>();
  public displayedColumns = ['user', 'additionalQualifications', 'email'];
  public isLoading = true;
  public isStudent: boolean;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  // import from utils
  public getFullName = getFullName;
  public getAllAdditionalQualifications = getAllAdditionalQualifications;

  constructor(
    private courseService: CourseService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private cancellationService: CancellationService,
    private alertService: AlertService,
    private mobileUiService: MobileUiService
  ) {}

  public ngOnInit(): void {
    const courseId = this.activatedRoute.snapshot.paramMap.get('id')
      ? +atob(this.activatedRoute.snapshot.paramMap.get('id'))
      : +atob(this.activatedRoute.parent?.snapshot.paramMap.get('id'));

    this.initTable(courseId);
    this.viewChanges();
  }

  /**
   * subscribes to the current view and initializes the table columns
   * @returns void
   */
  private viewChanges(): void {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.initTableColumns(currentView);
      });
  }

  /**
   * initializes the table columns based on the current view
   * @param currentView The current view
   * @returns void
   */
  private initTableColumns(currentView: string): void {
    if (currentView === 'mobile') {
      this.displayedColumns = ['user'];
    } else if (currentView === 'tablet') {
      this.displayedColumns = ['user', 'additionalQualifications', 'email'];
    } else {
      this.displayedColumns = ['user', 'additionalQualifications', 'email'];
    }
  }

  /**
   * initializes the sorting and pagination of the table
   * inits the table data with the lecturers of the current course
   * @param courseId The id of the course
   * @returns void
   */
  private initTable(courseId: number): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'user':
          return item.name.firstName;
        case 'additionalQualifications':
          return item.additionalQualifications
            ? item.additionalQualifications[0]?.name
            : null;
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.getCourseLecturers(courseId);
  }

  /**
   * retrieves all lecturers of a course and initializes the table data
   * @param courseId The id of the course
   * @returns void
   */
  private getCourseLecturers(courseId: number): void {
    this.courseService
      .getCourseLecturers(courseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.dataSource.data = response.body
            ? await Promise.all(
                response.body.map(async (lecturer: any) => {
                  return await this.userService.parseBackendUser(lecturer);
                })
              )
            : [];

          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Das Lehrpersonal konnte nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * cancel all requests and unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
