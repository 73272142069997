import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, take, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import {
  Filter,
  FilterDateRange,
  FilterType,
} from 'src/app/models/filter.model';
import {
  TreatmentCaseEventDateModel,
  TreatmentCaseModel,
} from 'src/app/models/treatment-case.model';
import { FilterTreatmentCaseEventDatesPipe } from 'src/app/pipes/filter-treatment-case-event-dates.pipe';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PatientAppointmentService } from 'src/app/services/patient-appointment.service';
import { SupervisionService } from 'src/app/services/supervision.service';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';
import { UserService } from 'src/app/services/user.service';
import { dateIsInFuture } from 'src/app/utils/date.utils';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';
import {
  getAllEventDateSupervisors,
  getAllSupervisors,
} from 'src/app/utils/treatment-case.utils';
import { getFullName, getFullNames } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-treatment-case-event-dates',
  templateUrl: './treatment-case-event-dates.component.html',
  styleUrl: './treatment-case-event-dates.component.scss',
})
export class TreatmentCaseEventDatesComponent implements OnInit, OnDestroy {
  public isStudent = this.userService.currentUserIsStudent();
  private studentId: number;
  private treatmentCaseId: number;
  public treatmentCase?: TreatmentCaseModel;
  public treatmentCaseEventDates: TreatmentCaseEventDateModel[] = [];
  public searchForm: FormGroup = new FormGroup({
    dateRange: new FormGroup({
      start: new FormControl(null, Validators.required),
      end: new FormControl(null, Validators.required),
    }),
  });
  public displayedColumns = [
    'date',
    'room',
    'type',
    'supervisors',
    'status',
    'actions',
  ];
  public dataSource: MatTableDataSource<TreatmentCaseEventDateModel> =
    new MatTableDataSource();
  public isLoading = true;
  public isMobile = false;
  public isTablet = false;
  public showSearchBar = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;
  private dateRangeSubject: Subject<FilterDateRange | null> = new Subject();
  public dateRangeFilter: Filter = { type: FilterType.DATE_RANGE, value: null };
  public treatmentCaseEventDatesFilter: Filter[] = [this.dateRangeFilter];
  public filterOpened: boolean = false;

  public hasActiveFilterValue = hasActiveFilterValue;

  private destroy$: Subject<void> = new Subject<void>();

  // import from utils
  public getAllEventDateSupervisors = getAllEventDateSupervisors;
  public getAllSupervisors = getAllSupervisors;
  public getFullName = getFullName;
  public dateIsInFuture = dateIsInFuture;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private treatmentCaseService: TreatmentCaseService,
    private cancellationService: CancellationService,
    private alertService: AlertService,
    private patientAppointmentService: PatientAppointmentService,
    private supervisionAppointmentService: SupervisionService,
    private dialog: MatDialog,
    private mobileUiService: MobileUiService,
    private userService: UserService,
    private loadingService: LoadingService
  ) {}

  public ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (!params.treatmentCaseId) {
          return;
        }
        if (params.studentId) {
          this.studentId = +atob(decodeURIComponent(params.studentId));
        }

        this.treatmentCaseId = +atob(
          decodeURIComponent(params.treatmentCaseId)
        );

        this.getTreatmentCase();

        this.initTable();
      });

    this.dateRangeSubject.pipe(takeUntil(this.destroy$)).subscribe({
      next: dateRange => {
        if (!dateRange) {
          this.searchForm.patchValue({
            dateRange: {
              start: null,
              end: null,
            },
          });
        }

        // update value inside treatmentCaseEventDatesFilter
        this.dateRangeFilter.value = dateRange;
        this.treatmentCaseEventDatesFilter =
          this.treatmentCaseEventDatesFilter.map(filter => {
            if (filter.type === FilterType.DATE_RANGE) {
              filter.value = dateRange;
              this.applyFilter();
            }
            return filter;
          });
      },
    });
    this.viewChanges();
  }

  /**
   * sets isMobile and isTablet depending on the current view
   * sets showSearchBar to true if the current view is desktop
   * sets the displayed columns depending on the current view
   * @returns void
   */
  private viewChanges() {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.initTableColumns(currentView);

        this.isMobile = currentView === 'mobile';
        this.isTablet = currentView === 'tablet';

        this.showSearchBar = currentView === 'desktop';
      });
  }

  /**
   * set the columns for the table depending on the current view
   * @param currentView the current view
   * @returns void
   */
  private initTableColumns(currentView: string): void {
    if (currentView === 'mobile') {
      this.displayedColumns = ['date', 'type', 'actions'];
    } else if (currentView === 'tablet') {
      this.displayedColumns = ['date', 'type', 'status', 'actions'];
    } else {
      this.displayedColumns = [
        'date',
        'room',
        'type',
        'supervisors',
        'status',
        'actions',
      ];
    }
  }

  /**
   * retrieves the treatment case by it's id
   * @returns void
   */
  private getTreatmentCase(): void {
    this.treatmentCaseService
      .getTreatmentCaseById(this.treatmentCaseId)
      .pipe(take(1))
      .subscribe({
        next: async response => {
          this.treatmentCase = response.body
            ? await this.treatmentCaseService.parseBackendTreatmentCase(
                response.body
              )
            : null;
          this.isLoading = false;
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Behandlungsfall konnte nicht geladen werden.'
          );
        },
      });
  }

  /**
   * initializes the sorting and pagination of the table
   * inits the table data with the event dates of the current treatment case
   * @returns void
   */
  private initTable(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'date':
          return item.startDate;
        case 'room':
          return item.room?.name;
        case 'supervisors':
          if (item.supervisor) {
            return getFullName(item.supervisor);
          }
          return getFullNames(item.treatmentCaseSupervisors);
        default:
          return item[property];
      }
    };

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.getTreatmentCaseEventDates();
  }

  /**
   * retrieves all event dates of a treatment case and initializes the table data
   * @returns void
   */
  private getTreatmentCaseEventDates(): void {
    this.treatmentCaseService
      .getAllAppointmentEventDatesByTreatmentCaseId(this.treatmentCaseId)
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.treatmentCaseEventDates = response.body;
          this.dataSource.data = response.body;

          this.isLoading = false;
          this.applyFilter();
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Termine konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * Navigates to the create/edit appointment page.
   * @returns void
   */
  public onCreateAppointment(): void {
    this.router.navigate(['../create-appointment'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * Applies the treatment case event dates filter
   * @returns void
   */
  public applyFilter(): void {
    this.dataSource.data =
      FilterTreatmentCaseEventDatesPipe.prototype.transform(
        this.treatmentCaseEventDates,
        this.treatmentCaseEventDatesFilter
      );
  }

  /**
   * Applies the date range filter
   * @returns void
   */
  public applyDateRange(): void {
    this.dateRangeSubject.next({
      start: this.searchForm.value.dateRange?.start,
      end: this.searchForm.value.dateRange?.end,
    });
  }

  /**
   * Gets called when the treatment case event dates filter has changed
   * @param treatmentCaseEventDatesFilter Filter[]
   * @returns void
   */
  public treatmentCaseEventDatesFilterChanged(
    treatmentCaseEventDatesFilter: Filter[]
  ): void {
    this.treatmentCaseEventDatesFilter = treatmentCaseEventDatesFilter;

    if (!treatmentCaseEventDatesFilter.includes(this.dateRangeFilter)) {
      treatmentCaseEventDatesFilter.push(this.dateRangeFilter);
    }

    this.applyFilter();
    this.filterMenuTrigger?.closeMenu();

    // find FILTER_TYPE.DATE_RANGE and update date range subject
    const dateRangeFilter = treatmentCaseEventDatesFilter.find(
      filter => filter.type === FilterType.DATE_RANGE
    );

    if (dateRangeFilter && !dateRangeFilter.value) {
      this.dateRangeSubject.next(null);
    } else if (dateRangeFilter) {
      this.dateRangeSubject.next(dateRangeFilter.value as FilterDateRange);
    }
  }

  /**
   * Navigates to the view appointment page.
   * @param treatmentCaseEventDate event date to view
   * @returns void
   */
  public onViewEventDate(
    treatmentCaseEventDate: TreatmentCaseEventDateModel
  ): void {
    if (treatmentCaseEventDate.type === 'Patiententermin') {
      const appointmentId = encodeURIComponent(
        btoa(treatmentCaseEventDate.patientAppointmentId.toString())
      );
      const eventDateId = encodeURIComponent(
        btoa(treatmentCaseEventDate.eventDateId.toString())
      );

      this.router.navigate([appointmentId, eventDateId], {
        relativeTo: this.activatedRoute,
      });
    } else {
      const supervisionId = encodeURIComponent(
        btoa(String(treatmentCaseEventDate.supervisionId))
      );
      const eventDateId = encodeURIComponent(
        btoa(String(treatmentCaseEventDate.eventDateId))
      );

      const route = this.studentId ? '/eleguide/education/' : '../../../';

      this.router.navigate([route, 'supervision', supervisionId, eventDateId], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  /**
   * Navigates to the edit appointment page.
   * @param treatmentCaseEventDate event date to edit
   */
  public onEditAppointment(
    treatmentCaseEventDate: TreatmentCaseEventDateModel
  ): void {
    if (treatmentCaseEventDate.type === 'Patiententermin') {
      const patientAppointmentId = encodeURIComponent(
        btoa(treatmentCaseEventDate.patientAppointmentId.toString())
      );
      this.router.navigate(['../edit-appointment', patientAppointmentId], {
        relativeTo: this.activatedRoute,
      });
    } else {
      const supervisionId = encodeURIComponent(
        btoa(treatmentCaseEventDate.supervisionId.toString())
      );
      const eventDateId = encodeURIComponent(
        btoa(treatmentCaseEventDate.eventDateId.toString())
      );
      const route = this.studentId ? '/eleguide/education/' : '../../../';

      this.router.navigate(
        [route, 'supervision', supervisionId, 'edit', eventDateId],
        {
          relativeTo: this.activatedRoute,
        }
      );
    }
  }

  /**
   * Calls the function to cancel the patient appointment event date or the supervision appointment event date
   * @param treatmentCaseEventDate the event date to cancel
   * @returns void
   */
  public onCancelEventDate(
    treatmentCaseEventDate: TreatmentCaseEventDateModel
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Termin absagen',
        message: `Möchten Sie den Termin am ${moment(treatmentCaseEventDate.startDate).format('DD.MM.YYYY')} absagen?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          if (treatmentCaseEventDate.type === 'Patiententermin')
            this.cancelPatientAppointmentEventDate(treatmentCaseEventDate);
        }
      });
  }

  /**
   * Cancels the patient appointment event date
   * @param treatmentCaseEventDate the event date to cancel
   * @returns void
   */
  private cancelPatientAppointmentEventDate(
    treatmentCaseEventDate: TreatmentCaseEventDateModel
  ): void {
    this.loadingService.show();
    this.patientAppointmentService
      .cancelPatientAppointmentEventDate(
        this.treatmentCaseId,
        treatmentCaseEventDate.patientAppointmentId,
        treatmentCaseEventDate.eventDateId
      )
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.loadingService.hide();
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Termin wurde erfolgreich abgesagt.'
          );

          // reload the data
          this.getTreatmentCaseEventDates();
        },
        error: () => {
          this.loadingService.hide();
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Termin konnte nicht abgesagt werden.'
          );
        },
      });
  }

  /**
   * Calls the function to confirm the patient appointment event date or the supervision appointment event date
   * @param treatmentCaseEventDate the event date to confirm
   * @returns void
   */
  public onConfirmEventDate(
    treatmentCaseEventDate: TreatmentCaseEventDateModel
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Termin zusagen',
        message: `Möchten Sie dem Termin am ${moment(treatmentCaseEventDate.startDate).format('DD.MM.YYYY')} zusagen?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          if (treatmentCaseEventDate.type === 'Patiententermin')
            this.confirmPatientAppointmentEventDate(treatmentCaseEventDate);
        }
      });
  }

  /**
   * Confirms the patient appointment event date
   * @param treatmentCaseEventDate the event date to confirm
   * @returns void
   */
  private confirmPatientAppointmentEventDate(
    treatmentCaseEventDate: TreatmentCaseEventDateModel
  ): void {
    this.loadingService.show();
    this.patientAppointmentService
      .confirmPatientAppointmentEventDate(
        this.treatmentCaseId,
        treatmentCaseEventDate.patientAppointmentId,
        treatmentCaseEventDate.eventDateId
      )
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.loadingService.hide();
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Termin wurde erfolgreich zugesagt.'
          );
          // reload the data
          this.getTreatmentCaseEventDates();
        },
        error: () => {
          this.loadingService.hide();
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Termin konnte nicht zugesagt werden.'
          );
        },
      });
  }

  /**
   * Calls the function to delete the patient appointment event date or the supervision appointment event date
   * @param treatmentCaseEventDate the event date to delete
   * @returns void
   */
  public onDeleteEventDate(
    treatmentCaseEventDate: TreatmentCaseEventDateModel
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Termin löschen',
        message: `Möchten Sie den Termin am ${moment(treatmentCaseEventDate.startDate).format('DD.MM.YYYY')} löschen?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          this.deletePatientAppointmentEventDate(treatmentCaseEventDate);
        }
      });
  }

  /**
   * Deletes the patient appointment event date
   * @param treatmentCaseEventDate the event date to delete
   * @returns void
   */
  private deletePatientAppointmentEventDate(
    treatmentCaseEventDate: TreatmentCaseEventDateModel
  ): void {
    this.loadingService.show();
    this.patientAppointmentService
      .deletePatientAppointmentEventDate(
        this.treatmentCaseId,
        treatmentCaseEventDate.supervisionId,
        treatmentCaseEventDate.eventDateId
      )
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.loadingService.hide();
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Termin wurde erfolgreich gelöscht.'
          );

          // reload the data
          this.getTreatmentCaseEventDates();
        },
        error: () => {
          this.loadingService.hide();
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Termin konnte nicht gelöscht werden.'
          );
        },
      });
  }

  /**
   * Returns the class for the label of the event date status
   * @param status the status of the event date
   * @returns label class name
   */
  public getStatusLabelClass(status: string): string {
    const approvedStatuses = ['supervidiert', 'geprüft'];
    return approvedStatuses.includes(status) ? 'label-green' : 'label-white';
  }

  /**
   * Returns the class for the icon of the event date status
   * @param status the status of the event date
   * @returns icon class name
   */
  public getStatusIconClass(status: string): string {
    const checkedStatuses = ['supervidiert', 'geprüft', 'erfasst'];
    const failedStatuses = ['abgesagt'];

    if (checkedStatuses.includes(status)) {
      return 'fa-circle-check';
    } else if (failedStatuses.includes(status)) {
      return 'fa-circle-x';
    }
    return 'fa-circle-dashed';
  }

  /**
   * Navigates back to the treatment case details page
   * @returns void
   */
  public onGoBack(): void {
    this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
  }

  /**
   * cancels all requests and unsubscribes from the destroy$ subject
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
