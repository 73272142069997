import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MobileUiService {
  // change it to observable
  public currentView$ = new BehaviorSubject<'desktop' | 'tablet' | 'mobile'>(
    'desktop'
  );
  // Listen to window resize events
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkViewportWidth();
  }
  constructor() {
    this.checkViewportWidth();
  }

  // Method to check the viewport width
  public checkViewportWidth() {
    const width = window.innerWidth;
    const currentView = this.currentView$.getValue();
    let newView: 'desktop' | 'tablet' | 'mobile';

    if (width <= 576) {
      newView = 'mobile';
    } else if (width > 576 && width <= 991) {
      newView = 'tablet';
    } else {
      newView = 'desktop';
    }

    if (currentView !== newView) {
      this.currentView$.next(newView);
    }
  }
}
