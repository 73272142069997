import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-empty-state-dialog',
  templateUrl: './empty-state-dialog.component.html',
  styleUrls: ['./empty-state-dialog.component.scss'],
})
export class EmptyStateDialogComponent {
  constructor(public dialogRef: MatDialogRef<EmptyStateDialogComponent>) {}
}
