import { KeyValue, Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { EventLocation } from 'src/app/enums/event-location.enum';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { AccompanyingPerson as AccompaningPersonModel } from 'src/app/models/accompanying-person.model';
import { RecurrencePattern } from 'src/app/models/course.model';
import {
  EventDate,
  EventDateCreateModel,
  EventDateUpdateModel,
} from 'src/app/models/event.model';
import {
  PatientAppointmentCreateModel,
  PatientAppointmentModel,
  PatientAppointmentUpdateModel,
} from 'src/app/models/patient-appointment.model';
import { TreatmentCaseModel } from 'src/app/models/treatment-case.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PatientAppointmentService } from 'src/app/services/patient-appointment.service';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';
import { UserService } from 'src/app/services/user.service';
import {
  mapEventDatesToEventDateModels,
  sortEventDatesByStartDate,
} from 'src/app/utils/date.utils';
import { isRequired } from 'src/app/utils/form.utils';
import {
  getAllAdditionalQualifications,
  getFullName,
} from 'src/app/utils/user.utils';

@Component({
  selector: 'app-create-edit-patient-appointment',
  templateUrl: './create-edit-patient-appointment.component.html',
  styleUrl: './create-edit-patient-appointment.component.scss',
})
export class CreateEditPatientAppointmentComponent
  implements OnInit, OnDestroy
{
  public currentAppointment?: PatientAppointmentModel;
  public editMode: boolean = false;
  public isLoading: boolean = true;
  public patientAppointmentForm: FormGroup;
  public initialFormValues: any = {};
  public availableTreatmentCases: TreatmentCaseModel[] = [];
  public availableAccompanyingPersons: AccompaningPersonModel[] = [];
  private studentId: number = this.userService.currentUser.id;
  private treatmentCaseId: number;
  private appointmentId: number;

  // event picker
  public recurrencePattern: RecurrencePattern;
  public eventDates: EventDate[] = [];
  private initialEventDates: EventDate[] = [];
  public startDate: any;
  public endDate: any;
  public allDayEvent: boolean = false;
  public formSubmitted: boolean = false;

  public roomPlanningOpen: boolean = false;
  public roomPlanningDisabled: boolean = false;
  public showLink: boolean = false;

  // import from utils
  public isRequired = isRequired;
  public getFullName = getFullName;
  public getAllAdditionalQualifications = getAllAdditionalQualifications;

  public EventLocation = EventLocation;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    this.eventDates = mapEventDatesToEventDateModels(this.eventDates);
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.patientAppointmentForm.value,
        this.initialFormValues
      ) ||
      this.formDeactivateService.hasUnsavedChanges(
        this.eventDates,
        this.initialEventDates
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private treatmentCaseService: TreatmentCaseService,
    private patientAppointmentService: PatientAppointmentService,
    private userService: UserService,
    private cancellationService: CancellationService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    private dialog: MatDialog,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private location: Location,
    private loadingService: LoadingService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.createForm();
    await this.initAvailableTreatmentCases();
    this.initAvailableAccompanyingPersons();
    this.processRouteParams();

    await this.initPatientAppointment(this.treatmentCaseId, this.appointmentId);

    this.isLoading = false;
    this.initialFormValues = JSON.parse(
      JSON.stringify(this.patientAppointmentForm.value)
    );
  }

  /**
   * processes the route params
   * initializes the patient appointment if the appointmentId and treatmentCaseId are provided
   * initializes the studentId if the studentId is provided
   * @returns void
   */
  private processRouteParams(): void {
    const params = this.activatedRoute.snapshot.params;
    if (params['appointmentId']) {
      this.editMode = true;
      this.appointmentId = +atob(decodeURIComponent(params['appointmentId']));
    }
    if (params['treatmentCaseId']) {
      this.treatmentCaseId = +atob(
        decodeURIComponent(params['treatmentCaseId'])
      );
      this.patientAppointmentForm
        .get('patientAppointmentTreatmentCaseId')
        .setValue(this.treatmentCaseId);
      this.patientAppointmentForm
        .get('patientAppointmentTreatmentCaseId')
        .disable();
    }
    if (params['studentId']) {
      this.studentId = +atob(decodeURIComponent(params['studentId']));
    }
  }

  /**
   * Create the form
   * @returns void
   */
  private createForm(): void {
    this.patientAppointmentForm = this.formBuilder.group({
      patientAppointmentTreatmentCaseId: new FormControl(
        null,
        Validators.required
      ),
      accompanyingPersonsIds: new FormControl({
        value: [],
        disabled: true,
      }),
      durationInTimeUnits: new FormControl(1, Validators.required),
      dateGroup: new FormControl(null),
      location: new FormControl(EventLocation.ONSITE),
      videoMeetingLink: new FormControl(null),
    });

    this.handleLocationChange();

    this.initialFormValues = this.patientAppointmentForm.value;
  }

  /**
   * Handles the change of the location form control.
   * If the location is set to online, the room planning is disabled and the video meeting link is required.
   * If the location is set to onsite, the video meeting link is not required and is not shown.
   * If the location is set to hybrid, the video meeting link is required and the room planning is enabled.
   * @returns void
   */
  private handleLocationChange(): void {
    this.patientAppointmentForm.controls['location'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(location => {
        this.roomPlanningDisabled = location === EventLocation.ONLINE;
        this.showLink = location !== EventLocation.ONSITE;

        if (location === EventLocation.ONSITE) {
          this.patientAppointmentForm.get('videoMeetingLink').clearValidators();
        } else {
          this.patientAppointmentForm
            .get('videoMeetingLink')
            .setValidators([Validators.required]);
        }

        this.patientAppointmentForm
          .get('videoMeetingLink')
          .updateValueAndValidity();
      });
  }

  /**
   * Returns the FormControl for the accompanyingPersonsIds.
   * @returns The FormControl for the accompanyingPersonsIds.
   */
  get accompanyingPersonsIdsFormControl(): FormControl {
    return this.patientAppointmentForm.get(
      'accompanyingPersonsIds'
    ) as FormControl;
  }

  /**
   * Returns an array of IDs for the available accompanying persons.
   * @returns {number[]} An array of IDs for the available accompanying persons.
   */
  get availableAccompanyingPersonsIds(): number[] {
    return this.availableAccompanyingPersons?.map(
      (accompanyingPerson: AccompaningPersonModel) => accompanyingPerson.id
    );
  }

  /**
   * Init available treatment cases and supervisors for the select fields
   * @returns void
   */
  private async initAvailableTreatmentCases(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.treatmentCaseService
        .getAllTreatmentCasesByInstituteIdAndStudentId(this.studentId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            this.availableTreatmentCases = response.body
              ? await Promise.all(
                  response.body.map(async (treatmentCaseData: any) => {
                    return this.treatmentCaseService.parseBackendTreatmentCase(
                      treatmentCaseData
                    );
                  })
                )
              : [];
            resolve();
          },
          error: error => {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Die Behandlungsfälle konnten nicht geladen werden!'
            );
            this.isLoading = false;
            reject(error);
          },
        });
    });
  }

  /**
   * initalizes the available accompanying persons from the treatment case that is selected
   * @returns void
   */
  private initAvailableAccompanyingPersons(): void {
    this.patientAppointmentForm
      .get('patientAppointmentTreatmentCaseId')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: number) => {
        if (value) {
          this.patientAppointmentForm.get('accompanyingPersonsIds').enable();
          this.availableAccompanyingPersons = this.availableTreatmentCases.find(
            (treatmentCase: TreatmentCaseModel) => treatmentCase.id === value
          )?.accompanyingPersons;
        } else {
          this.patientAppointmentForm.get('accompanyingPersonsIds').disable();
          this.availableAccompanyingPersons = [];
        }
      });
  }

  /**
   * initalizes the patient appointment to edit
   * @param treatmentCaseId the treatment case id
   * @param appointmentId the appointment id
   * @returns void
   */
  private async initPatientAppointment(
    treatmentCaseId: number,
    appointmentId: number
  ): Promise<void> {
    if (!this.editMode) {
      return;
    }
    return new Promise<void>((resolve, reject) => {
      this.patientAppointmentService
        .getPatientAppointmentById(treatmentCaseId, appointmentId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            this.currentAppointment =
              await this.patientAppointmentService.parseBackendPatientAppointment(
                response.body
              );

            this.patientAppointmentForm.patchValue({
              patientAppointmentTreatmentCaseId:
                this.currentAppointment.treatmentCase.id,
              accompanyingPersonsIds:
                this.currentAppointment.accompanyingPersons.map(
                  (accompanyingPerson: AccompaningPersonModel) =>
                    accompanyingPerson.id
                ),
              durationInTimeUnits: this.currentAppointment.durationInTimeUnits,
              location: this.currentAppointment.location,
              videoMeetingLink: this.currentAppointment.videoMeetingLink,
            });

            this.eventDates = sortEventDatesByStartDate(
              this.currentAppointment.eventDates
            );
            this.initialEventDates = JSON.parse(
              JSON.stringify(this.eventDates)
            );
            this.recurrencePattern = this.currentAppointment.recurrencePattern;

            // set start date to the earliest date and end date to the latest date
            this.startDate = this.eventDates.reduce((acc, date) => {
              return acc < date.startDate ? acc : date.startDate;
            }, this.eventDates[0].startDate);

            this.endDate = this.eventDates.reduce((acc, date) => {
              return acc > date.endDate ? acc : date.endDate;
            }, this.eventDates[0].endDate);

            // set patientAppointmentTreatmentCaseId to disabled
            this.patientAppointmentForm
              .get('patientAppointmentTreatmentCaseId')
              .disable();

            this.cdr.detectChanges();
            resolve();
          },
          error: () => {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Der Patiententermin konnte nicht geladen werden.'
            );
            this.onCancel();
            reject();
          },
        });
    });
  }

  /**
   * event dates changed from event picker
   * @param eventDates
   * @returns void
   */
  public onEventsChanged(eventDates: EventDate[]): void {
    this.eventDates = eventDates;
  }

  /**
   * dates changed from event picker
   * @param dates
   * @returns void
   */
  public onDatesChanged(dates: {
    start: Date;
    end: Date;
    allDayEvent: boolean;
  }): void {
    this.startDate = dates.start;
    this.endDate = dates.end;
    this.allDayEvent = dates.allDayEvent;
    this.cdr.detectChanges();
  }

  /**
   * setEventLocation
   * set the event location in the appointment form
   * @param location event location
   */
  public setEventLocation(location: EventLocation) {
    this.patientAppointmentForm.get('location').setValue(location);
  }

  /**
   * open room planning
   * @returns void
   */
  public openRoomPlanning(): void {
    /* only open room planning if eventDates have been selected */
    if (this.eventDates.length > 0) {
      this.roomPlanningOpen = true;
    } else {
      this.alertService.showErrorAlert(
        'Fehler',
        'Bitte wählen Sie mindestens einen Termin aus!'
      );
    }
  }

  /**
   * room planning changed
   * @param eventDates
   * @returns void
   */
  public roomPlanningChanged(eventDates: EventDate[]): void {
    if (!eventDates) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Ungespeicherte Änderungen!',
          message:
            'Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren. \
              Möchten Sie die Seite trotzdem verlassen?',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.roomPlanningOpen = !this.roomPlanningOpen;
            return;
          }
        });
    } else {
      this.eventDates = eventDates;
      this.roomPlanningOpen = false;
    }
  }

  /**
   * On cancel
   * @returns void
   */
  public onCancel(): void {
    this.location.back();
  }

  /**
   * Handles the form submission for creating or updating an appointment.
   * Clears the validators based on the appointment type.
   * If the form is valid and has changes it creates or updates the appointment.
   * @returns void
   */
  public onSubmit(): void {
    this.formSubmitted = true;

    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.patientAppointmentForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.patientAppointmentForm.value,
        this.initialFormValues
      ) &&
      !this.formDeactivateService.hasUnsavedChanges(
        this.eventDates,
        this.initialEventDates
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.loadingService.show();
    this.editMode
      ? this.updatePatientAppointment()
      : this.createPatientAppointment();
  }
  /**
   * create a patient appointment
   * @returns Promise<void>
   */
  private async createPatientAppointment(): Promise<void> {
    const treatmentCaseId = this.patientAppointmentForm
      .get('patientAppointmentTreatmentCaseId')
      .getRawValue();

    const patientAppointment: PatientAppointmentCreateModel = {
      accompanyingPersonsIds: this.patientAppointmentForm
        .get('accompanyingPersonsIds')
        .value.filter((value: any) => value !== null),
      durationInTimeUnits: this.patientAppointmentForm.get(
        'durationInTimeUnits'
      ).value,
      recurrencePattern: this.recurrencePattern,
      eventDates: this.eventDates.map(
        (eventDate: EventDate): EventDateCreateModel => {
          const eventDateCreateModel: EventDateCreateModel = {
            startDate: eventDate.startDate.toISOString(),
            endDate: eventDate.endDate.toISOString(),
            roomId: eventDate.room?.id ?? null,
          };

          return eventDateCreateModel;
        }
      ),
      location: this.patientAppointmentForm.get('location').value,
      videoMeetingLink:
        this.patientAppointmentForm.get('videoMeetingLink').value,
    };

    const createPatientAppointmentObservable =
      await this.patientAppointmentService.createPatientAppointment(
        treatmentCaseId,
        patientAppointment
      );

    createPatientAppointmentObservable.pipe(take(1)).subscribe({
      next: () => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          'Der Patiententermin wurde erfolgreich erstellt!'
        );
        this.initialFormValues = this.patientAppointmentForm.value;
        this.eventDates = mapEventDatesToEventDateModels(this.eventDates);
        this.initialEventDates = this.eventDates;
        this.onCancel();
        this.loadingService.hide();
      },
      error: () => {
        this.alertService.showErrorAlert(
          'Das hat nicht geklappt',
          'Der Patiententermin konnte nicht erstellt werden!'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * update a patient appointment
   * @returns Promise<void>
   */
  private async updatePatientAppointment(): Promise<void> {
    const treatmentCaseId = this.patientAppointmentForm.get(
      'patientAppointmentTreatmentCaseId'
    ).value;

    const patientAppointment: PatientAppointmentUpdateModel = {
      accompanyingPersonsIds: this.patientAppointmentForm
        .get('accompanyingPersonsIds')
        .value.filter((value: any) => value !== null),
      durationInTimeUnits: this.patientAppointmentForm.get(
        'durationInTimeUnits'
      ).value,
      recurrencePattern: this.recurrencePattern,
      eventDates: this.eventDates.map(
        (eventDate: EventDate): EventDateUpdateModel => {
          const eventDateUpdateModel: EventDateUpdateModel = {
            id: eventDate.id,
            startDate: eventDate.startDate.toISOString(),
            endDate: eventDate.endDate.toISOString(),
            roomId: eventDate.room?.id ?? null,
          };

          return eventDateUpdateModel;
        }
      ),
      location: this.patientAppointmentForm.get('location').value,
      videoMeetingLink:
        this.patientAppointmentForm.get('videoMeetingLink').value,
    };

    const updatePatientAppointmentObservable =
      await this.patientAppointmentService.updatePatientAppointment(
        treatmentCaseId,
        this.currentAppointment.id,
        patientAppointment
      );

    updatePatientAppointmentObservable.pipe(take(1)).subscribe({
      next: () => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          'Der Patiententermin wurde erfolgreich aktualisiert.'
        );
        this.initialFormValues = this.patientAppointmentForm.value;
        this.eventDates = mapEventDatesToEventDateModels(this.eventDates);
        this.initialEventDates = this.eventDates;
        this.onCancel();
        this.loadingService.hide();
      },
      error: () => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Der Patiententermin konnte nicht aktualisiert werden.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    // todo: refactor component so this is not needed
    this.eventDates = mapEventDatesToEventDateModels(this.eventDates);

    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.eventDates,
        this.initialEventDates
      )
    ) {
      return this.formDeactivateService.confirmDeactivation(
        this.eventDates,
        this.initialEventDates
      );
    }

    return this.formDeactivateService.confirmDeactivation(
      this.patientAppointmentForm.value,
      this.initialFormValues
    );
  }

  /**
   * Preserve the original enum order
   */
  public originalEventLocationOrder = (
    a: KeyValue<string, EventLocation>,
    b: KeyValue<string, EventLocation>
  ): number => {
    return 0;
  };

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
