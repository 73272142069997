import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { CourseModel } from 'src/app/models/course.model';
import {
  Filter,
  FilterDateRange,
  FilterType,
} from 'src/app/models/filter.model';
import { FilterCoursePipe } from 'src/app/pipes/filter-course.pipe';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserService } from 'src/app/services/user.service';
import {
  getCourseDateRange,
  getCourseEndDate,
  getCourseRoom,
  getCourseStartDate,
  isCourseFinished,
} from 'src/app/utils/course.utils';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';
import { dateRangeIsNotEmpty } from 'src/app/utils/form.utils';
import { getFullNames } from 'src/app/utils/user.utils';
import { isSameDay } from 'src/app/utils/utils';

@Component({
  selector: 'app-course-administration',
  templateUrl: './course-administration.component.html',
  styleUrls: ['./course-administration.component.scss'],
})
export class CourseAdministrationComponent implements OnInit, OnDestroy {
  public selectedCourse?: CourseModel;
  public courses: CourseModel[];
  public searchForm: FormGroup = new FormGroup({
    searchText: new FormControl(''),
    dateRange: new FormGroup({
      start: new FormControl(null, Validators.required),
      end: new FormControl(null, Validators.required),
    }),
  });
  public filterOpened: boolean = false;
  public displayedColumns = [
    'title',
    'lecturer',
    'dates',
    'rooms',
    'elog',
    'actions',
  ];
  public dataSource: MatTableDataSource<CourseModel> = new MatTableDataSource();
  private tableData: Subject<CourseModel[]> = new Subject();
  public isLoading = true;
  public isLecturer: boolean = false;
  public isAdministrator: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;

  private dateRangeSubject: Subject<FilterDateRange | null> = new Subject();
  public dateRangeFilter: Filter = { type: FilterType.DATE_RANGE, value: null };
  public courseFilter: Filter[] = [
    this.dateRangeFilter,
    {
      type: FilterType.COURSE_TYPE,
      value: null,
    },
    {
      type: FilterType.ROOM_NAME,
      value: null,
    },
    {
      type: FilterType.PENDING_ELOGS,
      value: null,
    },
  ];
  public hasActiveFilterValue = hasActiveFilterValue;

  // import from utils
  public getFullNames = getFullNames;
  public getCourseRoom = getCourseRoom;
  public isSameDay = isSameDay;
  public getCourseStartDate = getCourseStartDate;
  public getCourseEndDate = getCourseEndDate;
  public getCourseDateRange = getCourseDateRange;
  public isCourseFinished = isCourseFinished;
  public dateRangeIsNotEmpty = dateRangeIsNotEmpty;

  public isMobile = false;
  public isTablet = false;
  public showSearchBar = false;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private courseService: CourseService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private navigationService: NavigationService,
    private mobileUiService: MobileUiService
  ) {}

  public ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.pendingELogsOnly) {
        // set pending eLogs filter to true
        this.courseFilter.find(
          filter => filter.type === FilterType.PENDING_ELOGS
        ).value = true;
      }
    });

    this.dateRangeSubject.pipe(takeUntil(this.destroy$)).subscribe({
      next: dateRange => {
        if (!dateRange) {
          this.searchForm.patchValue({
            dateRange: {
              start: null,
              end: null,
            },
          });
        }
        this.dateRangeFilter.value = dateRange;
        this.applyCourseFilter();
      },
    });
    this.isLecturer = this.userService.currentUserIsLecturer();
    this.isAdministrator = this.userService.currentUserIsAdministrator();

    if (!this.isLecturer) {
      this.courseFilter.push({
        type: FilterType.LECTURER,
        value: null,
      });
    }

    this.initTable();
    this.viewChanges();
  }

  /**
   * sets isMobile and isTablet depending on the current view
   * sets showSearchBar to true if the current view is desktop
   * sets the displayed columns depending on the current view
   * @returns void
   */
  private viewChanges() {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.initTableColumns(currentView);

        this.isMobile = currentView === 'mobile';
        this.isTablet = currentView === 'tablet';

        this.showSearchBar = currentView === 'desktop';
      });
  }

  /**
   * set the columns for the table depending on the current view
   * @param currentView the current view
   * @returns void
   */
  private initTableColumns(currentView: string): void {
    if (currentView === 'mobile') {
      this.displayedColumns = ['title', 'elog', 'actions'];
    } else if (currentView === 'tablet') {
      this.displayedColumns = ['title', 'elog', 'actions'];
    } else {
      if (!this.isLecturer) {
        this.displayedColumns = [
          'title',
          'lecturer',
          'dates',
          'rooms',
          'elog',
          'active',
          'actions',
        ];
        return;
      }

      this.displayedColumns = [
        'title',
        'lecturer',
        'dates',
        'rooms',
        'elog',
        'actions',
      ];
    }
  }

  /**
   * initializes the sorting, pagination and filtering of the table
   * inits the table data with the courses of the current institute
   * @returns void
   */
  private initTable(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'title':
          return item.title;
        case 'lecturer':
          return item.lecturers[0].name.firstName;
        case 'dates':
          return item.eventsStartDate;
        case 'rooms':
          return item.differentRooms ? 'Unterschiedliche' : item.room.name;
        case 'active':
          return item.logonEnabled;
        case 'elog':
          return item.elogStatusCounts.pending;
        default:
          return item[property];
      }
    };

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.tableData
      .pipe(takeUntil(this.destroy$))
      .subscribe((courses: CourseModel[]) => {
        this.courses = courses;
        this.dataSource.data = this.courses;

        this.isLoading = false;

        this.applyCourseFilter();
      });

    this.initTableColumns(this.mobileUiService.currentView$.value);

    if (!this.isLecturer) {
      this.getInstituteCourses();
      return;
    }
    this.getLecturersCourses();
  }

  /**
   * retrieve all courses of the current institute
   * @returns void
   */
  private getInstituteCourses(): void {
    this.courseService
      .getInstituteCourses()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.tableData.next(
            response.body
              ? await Promise.all(
                  response.body.map(
                    async (courseData: any): Promise<CourseModel> => {
                      return await this.courseService.parseBackendCourse(
                        courseData
                      );
                    }
                  )
                )
              : []
          );
        },
        error: () => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Kurse konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * retrieve all courses of the current lecturer in the current institute
   * @returns void
   */
  private getLecturersCourses(): void {
    this.courseService
      .getCurrentLecturersCourses()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.tableData.next(
            response.body
              ? await Promise.all(
                  response.body.map(
                    async (courseData: any): Promise<CourseModel> => {
                      return await this.courseService.parseBackendCourse(
                        courseData
                      );
                    }
                  )
                )
              : []
          );
        },
        error: () => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Kurse konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * courseFilterChanged
   * gets called when the course filter changed
   * @param courseFilter Filter[]
   * @returns void
   */
  public courseFilterChanged(courseFilter: Filter[]): void {
    this.courseFilter = courseFilter;

    if (!courseFilter.includes(this.dateRangeFilter)) {
      courseFilter.push(this.dateRangeFilter);
    }

    this.applyCourseFilter();
    this.filterMenuTrigger.closeMenu();

    // find FILTER_TYPE.DATE_RANGE and update date range
    const dateRangeFilter = courseFilter.find(
      filter => filter.type === FilterType.DATE_RANGE
    );

    if (dateRangeFilter && !dateRangeFilter.value) {
      this.dateRangeSubject.next(null);
    }

    // if pendingElogs filter gets removed, remove it from query params
    const pendingELogsFilter = courseFilter.find(
      filter => filter.type === FilterType.PENDING_ELOGS
    );

    if (pendingELogsFilter && !pendingELogsFilter.value) {
      this.router.navigate([], {
        queryParams: {
          pendingELogsOnly: null,
        },
      });
    }
  }

  /**
   * applyCourseFilter
   * applies the course filter
   * @returns void
   */
  public applyCourseFilter(): void {
    this.dataSource.data = FilterCoursePipe.prototype.transform(
      this.courses,
      this.courseFilter
    );
  }

  /**
   * filter courses by search text
   * @param event
   */
  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * onCourseActiveChanged
   * gets called when the course active state changed
   * @param data MatSlideToggleChange
   * @param course Course
   * @returns void
   */
  public async onCourseActiveChanged(
    data: MatSlideToggleChange,
    course: CourseModel
  ) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Achtung',
        message: `Möchten Sie den Kurs '${course.title}' wirklich ${
          data.checked ? 'aktivieren' : 'deaktivieren'
        }?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(async dialogResult => {
        if (dialogResult) {
          const activeStatus = data.checked ? 'aktiviert' : 'deaktiviert';

          this.courseService
            .enableOrDisableCourseLogon(course.id, data.checked)
            .pipe(first())
            .subscribe({
              next: () => {
                this.alertService.showSuccessAlert(
                  'Das hat geklappt!',
                  `Der Kurs '${course.title}' wurde ${activeStatus}!`
                );
              },
              error: () => {
                this.alertService.showErrorAlert(
                  'Das hat leider nicht geklappt!',
                  `Der Kurs '${course.title}' konnte nicht ${activeStatus} werden!`
                );
                course.logonEnabled = !data.checked;
                data.source.checked = !data.checked;
              },
            });
        } else {
          data.source.checked = !data.checked;
        }
      });
  }

  /**
   * applyDateRange
   * applies the date range filter
   * @returns void
   */
  public applyDateRange() {
    this.dateRangeSubject.next({
      start: this.searchForm.value.dateRange?.start,
      end: this.searchForm.value.dateRange?.end,
    });
  }

  /**
   * open eLog for the course
   * @param courseId
   */
  public openELog(courseId: number) {
    this.router.navigate(['../elog'], {
      queryParams: {
        course_id: btoa(String(courseId)),
      },
      relativeTo: this.route,
    });
  }

  /**
   * viewCourse in theoretical education
   * @param course
   * @returns void
   */
  public viewCourse(course: CourseModel): void {
    this.navigationService.setOverviewUrl(this.router.url);
    this.router.navigate([btoa(course.id.toString())], {
      relativeTo: this.route,
    });
  }

  /**
   * createCourse
   * @returns void
   */
  public createCourse(): void {
    this.router.navigate(['./create'], { relativeTo: this.route });
  }

  /**
   * editCourse
   * @param id
   * @returns void
   */
  public editCourse(id: number): void {
    this.router.navigate(['./', 'edit', btoa(String(id))], {
      relativeTo: this.route,
    });
  }

  /**
   * duplicateCourse
   * @param id
   * @returns void
   */
  public duplicateCourse(id: number): void {
    this.router.navigate(['./', 'create', btoa(String(id))], {
      relativeTo: this.route,
    });
  }

  /**
   * deleteCourse
   * delete the course
   * @param course
   * @returns void
   */
  public deleteCourse(course: CourseModel): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Löschen',
        message: `Möchten Sie den Kurs '${course.title}' wirklich löschen?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.courseService
            .deleteCourse(course.id)
            .pipe(first())
            .subscribe({
              next: () => {
                this.alertService.showSuccessAlert(
                  'Kurs gelöscht',
                  `Der Kurs '${course.title}' wurde gelöscht!`
                );

                // delete entry from table data without reloading from backend
                const index = this.dataSource.data.indexOf(course);
                if (index > -1) {
                  this.dataSource.data = this.dataSource.data.filter(
                    item => item !== course
                  );
                }

                // delete entry from courses without reloading from backend
                const indexCourses = this.courses.indexOf(course);
                if (indexCourses > -1) {
                  this.courses = this.courses.filter(item => item !== course);
                }
              },
              error: () => {
                this.alertService.showErrorAlert(
                  'Das hat leider nicht geklappt!',
                  `Der Kurs '${course.title}' konnte nicht gelöscht werden!`
                );
              },
            });
        }
      });
  }

  /**
   * getParticipantAmount
   * get the amount of participants for the course from the eLogStatusCounts
   * @param course
   */
  public getParticipantAmount(course: CourseModel): number {
    if (!course.elogStatusCounts) {
      return 0;
    }
    return (
      course.elogStatusCounts.absent +
      course.elogStatusCounts.checked +
      course.elogStatusCounts.excused +
      course.elogStatusCounts.pending +
      course.elogStatusCounts.unexcused +
      course.elogStatusCounts.upcoming
    );
  }

  /**
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
