import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { FilterUser } from '../models/filter.model';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor(private userService: UserService) {}

  /**
   * getLecturerFilterValue
   * returns the lecturer filter value
   * @returns void
   */
  public getLecturerFilterValue(): FilterUser | null {
    if (this.userService.currentUserIsLecturer()) {
      const currentUser = this.userService.currentUser;
      const name =
        (currentUser.name.academicTitle
          ? currentUser.name.academicTitle + ' '
          : '') +
        currentUser.name.firstName +
        ' ' +
        currentUser.name.lastName;
      return {
        id: currentUser.id,
        name: name,
      };
    }
    return null;
  }
}
