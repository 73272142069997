import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  LearningContentCreateModel,
  LearningContentModel,
} from '../models/learning-content.model';
import { CancellationService } from './cancellation.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class LearningContentService {
  private instituteId: number =
    this.userService.currentUser?.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService,
    private cancellationService: CancellationService
  ) {}

  /**
   * getLearningContentById
   * @param learningContentId
   * @returns Observable<HttpResponse<any>>
   */
  public getLearningContentById(
    learningContentId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/learning-contents/${learningContentId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getInstituteLearningContents
   * @returns Observable<HttpResponse<any>>
   */
  public getInstituteLearningContents(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/learning-contents`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createLearningContent
   * @param learningContent
   * @returns Observable<HttpResponse<any>>
   */
  public createLearningContent(
    learningContentCreateModel: LearningContentCreateModel
  ): Observable<HttpResponse<any>> {
    return this.http
      .post(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/learning-contents`,
        learningContentCreateModel,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * updateLearningContent
   * @param learningContentId
   * @param learningContent
   * @returns Observable<HttpResponse<any>>
   * */
  public updateLearningContent(
    learningContentId: number,
    learningContentCreateModel: LearningContentCreateModel
  ): Observable<HttpResponse<any>> {
    return this.http
      .put(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/learning-contents/${learningContentId}`,
        learningContentCreateModel,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * deleteLearningContent
   * @param learningContentId
   * @returns Observable<HttpResponse<any>>
   * */
  public deleteLearningContent(
    learningContentId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .delete(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/learning-contents/${learningContentId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * parseBackendLearningContent
   * @param backendLearningContent
   * @returns LearningContentModel
   */
  public parseBackendLearningContent(
    backendLearningContent: LearningContentModel
  ): LearningContentModel {
    return {
      id: backendLearningContent.id,
      name: backendLearningContent.name,
      shortName: backendLearningContent.shortName,
      creator: backendLearningContent.creator,
      timeCreated:
        backendLearningContent.timeCreated &&
        moment(backendLearningContent.timeCreated).tz('Europe/Berlin').toDate(),
      isStandard: backendLearningContent.isStandard,
    };
  }
}
