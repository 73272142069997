<div class="no-sensitive-data-content">
  <div class="icon">
    <i class="fa-light fa-triangle-exclamation"></i>
  </div>

  <div class="text">
    <div class="title">Achtung: Keine sensiblen Daten hochladen!</div>
    <span class="description">
      Bitte beachten Sie, dass dieser Bereich nicht für den Upload sensibler
      oder persönlicher Daten vorgesehen ist.
      <br />
      <br />
      Ihre Sicherheit ist uns wichtig.
    </span>
  </div>
</div>
