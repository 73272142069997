import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import {
  ExamTypeCreateModel,
  ExamTypeModel,
  ExamTypeUpdateModel,
} from 'src/app/models/exam-type.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { ExamTypeService } from 'src/app/services/exam-type.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { isRequired } from 'src/app/utils/form.utils';

@Component({
  selector: 'app-create-edit-exam-type',
  templateUrl: './create-edit-exam-type.component.html',
  styleUrl: './create-edit-exam-type.component.scss',
})
export class CreateEditExamTypeComponent implements OnInit, OnDestroy {
  public initialFormValues: {};
  public examType: ExamTypeModel;
  public examTypeForm: FormGroup;
  private destroy$: Subject<void> = new Subject<void>();
  public isLoading = false;
  public editMode: boolean = false;

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.examTypeForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  // import from utils
  public isRequired = isRequired;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private examTypeService: ExamTypeService,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private router: Router,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService,
    private loadingService: LoadingService
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params.id) {
          this.editMode = true;
          this.getData(+atob(params.id));
        }
      });
    this.initialFormValues = this.examTypeForm.value;
  }

  /**
   * createForm
   * creates the form
   * @returns void
   */
  private createForm(): void {
    this.examTypeForm = this.formBuilder.group({
      name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(255),
      ]),
    });
  }

  /**
   * getData
   * get the exam type data
   * @param examTypeId
   * @returns void
   */
  private getData(examTypeId: number): void {
    this.examTypeService
      .getExamTypeById(examTypeId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.examType = this.examTypeService.parseBackendExamType(
            response.body
          );
          this.examTypeForm.patchValue(this.examType);
          this.initialFormValues = this.examTypeForm.value;
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Prüfungsart konnte nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
          this.onCancel();
        },
      });
  }

  /**
   * Handles the form submission.
   * If the form is valid and has changes, it will create or update the exam type.
   * @returns void
   */
  public onSubmit(): void {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.examTypeForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.examTypeForm.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.loadingService.show();
    this.editMode ? this.updateExamType() : this.createExamType();
  }

  /**
   * createExamType
   * creates a new exam type
   * @returns void
   */
  private createExamType(): void {
    const examType: ExamTypeCreateModel = {
      name: this.examTypeForm.value.name,
    };

    this.examTypeService
      .createExamType(examType)
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Die Prüfungsart wurde hinzugefügt.'
          );
          this.initialFormValues = this.examTypeForm.value;
          this.onCancel();
          this.loadingService.hide();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Prüfungsart konnte nicht erstellt werden.'
          );
          this.loadingService.hide();
        },
      });
  }

  /**
   * updateExamType
   * updates the exam type
   * @returns void
   */
  private updateExamType(): void {
    const examType: ExamTypeUpdateModel = {
      name: this.examTypeForm.value.name,
    };

    this.examTypeService
      .updateExamType(this.examType.id, examType)
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Die Prüfungsart wurde aktualisiert.'
          );
          this.initialFormValues = this.examTypeForm.value;
          this.onCancel();
          this.loadingService.hide();
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Prüfungsart konnte nicht aktualisiert werden.'
          );
          this.loadingService.hide();
        },
      });
  }

  /**
   * onCancel
   * handles the form cancel
   * @returns void
   */
  public onCancel(): void {
    this.editMode
      ? this.router.navigate(['../../'], { relativeTo: this.activatedRoute })
      : this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.examTypeForm.value,
      this.initialFormValues
    );
  }

  /**
   * unsubscribe from all subscriptions and cancel all requests
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
