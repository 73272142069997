export type EducationalProgressModel = {
  type: EducationalProgressType;
  contingentInHours: number;
  progressInHours: number;
  progressInPercent: number;
};

export enum EducationalProgressType {
  THEORETICAL = 1,
  PRACTICAL_1 = 2,
  PRACTICAL_2 = 3,
  TREATMENT = 4,
  SUPERVISION = 5,
  SELF_AWARENESS = 6,
  TEACHING_ANALYSIS = 7,
  TOTAL = 8,
}
