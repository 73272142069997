<form
  [formGroup]="treatmentCaseForm"
  (ngSubmit)="onSubmit()"
  class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{
          editMode
            ? 'Behandlungsfall bearbeiten'
            : 'Neuen Behandlungsfall erstellen'
        }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie einen bestehenden Behandlungsfall'
            : 'Erstellen Sie einen neuen Behandlungsfall'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>

      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>
  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <div *ngIf="!isLoading" class="main-content-form">
    <div class="formular-row">
      <label class="formular-row-label" for="patientChiffre">
        <span class="label-title">
          Patienten Chiffre
          <span *ngIf="isRequired(treatmentCaseForm.get('patientChiffre'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Vergeben Sie eine Chiffre nach dem Schema Anfangsbuchstabe
          Familienname + 6 stelligen Geburtstag.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="patientChiffre"
            class="hasomed-text-input"
            type="text"
            formControlName="patientChiffre" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            treatmentCaseForm.get('patientChiffre')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="patientAge">
        <span class="label-title">
          Alter des Patienten
          <span *ngIf="isRequired(treatmentCaseForm.get('patientAge'))">*</span>
        </span>
        <span class="label-description">
          Hinterlegen Sie das Alter des Patienten.
        </span>
      </label>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="patientAge"
            class="hasomed-text-input"
            type="number"
            formControlName="patientAge" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="treatmentCaseForm.get('patientAge')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="therapyForm">
        <span class="label-title">
          Therapieform
          <span *ngIf="isRequired(treatmentCaseForm.get('therapyForm'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Wählen Sie die gewünschte Art der Therapie.
        </span>
      </label>

      <div class="hasomed-input-field">
        <div class="hasomed-toggle-button">
          <button
            *ngFor="
              let therapyForm of TherapyForm
                | keyvalue: originalTherapyFormOrder
            "
            [ngClass]="{
              'hasomed-button-orange':
                treatmentCaseForm.get('therapyForm').value ===
                therapyForm.value,
              'hasomed-button-white-grey':
                treatmentCaseForm.get('therapyForm').value != therapyForm.value,
              'border-red':
                treatmentCaseForm.get('therapyForm').invalid &&
                treatmentCaseForm.get('therapyForm').touched,
            }"
            [class.btn-left]="therapyForm.value === TherapyForm.INDIVIDUAL"
            [class.btn-right]="therapyForm.value === TherapyForm.GROUP"
            (click)="setTherapyForm(therapyForm.value)"
            type="button"
            class="hasomed-text-button button-full-width">
            <span class="hasomed-button-text">
              {{ therapyForm.value }}
            </span>
          </button>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            treatmentCaseForm.get('therapyForm')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Therapiephase
          <span *ngIf="isRequired(treatmentCaseForm.get('therapyPhaseId'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Bestimmen Sie die Phase Ihrer Therapie entsprechend Ihrem aktuellen
          Behandlungsfortschritt.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <eleguide-select
            [control]="therpapyPhaseControl"
            controlId="therapyPhaseId"
            [options]="availableTherapyPhases"
            placeholder="Therapiephase auswählen"></eleguide-select>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            treatmentCaseForm.get('therapyPhaseId')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Behandlungsverfahren
          <span
            *ngIf="isRequired(treatmentCaseForm.get('treatmentProceduresIds'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Wählen Sie in welchem Verfahren die Therapie stattfinden wird.
        </span>
      </div>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <eleguide-select
            [control]="treatmentProceduresControl"
            controlId="treatmentProceduresIds"
            [options]="availableTreatmentProcedures"
            placeholder="Behandlungsverfahren auswählen"
            [multiple]="true"></eleguide-select>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            treatmentCaseForm.get('treatmentProceduresIds')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="supervisorIds">
        <span class="label-title">
          Supervisor auswählen
          <span *ngIf="isRequired(treatmentCaseForm.get('supervisorIds'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Wählen Sie einen Supervisor für den Behandlungsfall.
        </span>
      </label>

      <div class="hasomed-input-field">
        <div class="formular-row-input-columns gap-32">
          <div class="flex-c gap-16 select-user-container">
            <div class="hasomed-search">
              <i class="fa-light fa-search hasomed-search-icon"></i>
              <input
                #supervisorInput
                id="supervisorSearch"
                class="hasomed-search-input"
                type="text"
                placeholder="Personensuche"
                formControlName="supervisorSearch" />
            </div>
            <div
              *ngIf="availableSupervisors"
              [ngClass]="{
                'error-border':
                  treatmentCaseForm.get('supervisorIds').invalid &&
                  treatmentCaseForm.get('supervisorIds').touched,
              }"
              id="supervisorIds"
              class="flex-c vertical-overflow gap-8 users">
              <div
                *ngFor="
                  let supervisor of availableSupervisors
                    | searchFilter: supervisorInput.value
                "
                [ngClass]="{
                  active: treatmentCaseForm
                    .get('supervisorIds')
                    .value.includes(supervisor.id),
                }"
                (click)="onSupervisorSelected(supervisor)"
                class="flex-r user-container">
                <div class="flex-r user">
                  <div class="user-icon">
                    <img
                      src="{{
                        supervisor.profilePicture ??
                          '../../../../../assets/img/user.jpg'
                      }}"
                      alt
                      class="user-icon-image" />
                  </div>
                  <div class="user-info">
                    <span class="hasomed-text-body">
                      {{ getFullName(supervisor) }}
                    </span>
                    <span class="hasomed-text-small">
                      {{ getAllAdditionalQualifications(supervisor) }}
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="
                    treatmentCaseForm
                      .get('supervisorIds')
                      .value.includes(supervisor.id)
                  ">
                  <i class="fa-light fa-circle-check user-check"></i>
                </div>
              </div>
            </div>
            <span
              *ngIf="availableSupervisors?.length === 0"
              class="hasomed-text-small">
              Kein Supervisor gefunden.
            </span>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            treatmentCaseForm.get('supervisorIds')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <div class="label-title">
          Begleitpersonen
          <span
            *ngIf="isRequired(treatmentCaseForm.get('accompanyingPersonsIds'))"
            >*</span
          >
        </div>
        <div class="label-description">
          Wählen Sie eine oder mehrere Begleitpersonen für die Therapie.
        </div>
      </div>
      <div class="formular-row-input">
        <eleguide-multiple-select
          [array]="accompanyingPersonsFormArray"
          arrayName="accompanyingPersonsIds"
          [options]="availableAccompanyingPersons"
          placeholder="Begleitperson auswählen"></eleguide-multiple-select>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="description">
        <span class="label-title">
          Beschreibung
          <span *ngIf="isRequired(treatmentCaseForm.get('description'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Ergänzen Sie weitere Informationen.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="description"
            class="hasomed-textarea-input"
            rows="5"
            formControlName="description"></textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            treatmentCaseForm.get('description')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title"> Dokumente </span>
        <span class="label-description">
          Laden Sie hier Ihre Dokumente für diese Behandlung hoch.
        </span>
      </label>
      <div class="formular-row-input">
        <div id="documents" class="document-upload-wrapper">
          <app-upload-area-dnd
            [existingFiles]="existingFiles"
            [multiple]="true"
            [scrollTo]="true"
            [showFileList]="true"
            [sensitiveDataAlert]="false"
            [allowedFileTypes]="allowedFileTypesDocuments"
            (openExistingFile)="onOpenExistingFile($event)"
            (downloadExistingFile)="onDownloadExistingFile($event)"
            (deleteExistingFile)="onDeleteExistingFile($event)"
            context="treatmentCaseDocumentsUpload"
            formControlName="uploadedFiles"
            inputId="documentUpload"
            maxFileSize="12 MB">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>
  </div>
</form>
