<div class="change-password-dialog">
  <span mat-dialog-title>Passwort ändern</span>
  <div mat-dialog-content>
    <form
      class="change-password-form flex-c gap-8"
      [formGroup]="changePasswordForm">
      <!-- Old password -->
      <div class="formular-row">
        <label for="oldPassword" class="formular-row-label">
          <div class="label-title">Altes Passwort</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="oldPassword"
              class="hasomed-text-input flex-grow"
              [type]="showOldPassword ? 'text' : 'password'"
              formControlName="oldPassword"
              placeholder="********"
              autocomplete="on" />
            <button
              type="button"
              class="button-show-hide-password"
              [matTooltip]="
                showOldPassword ? 'Passwort verstecken' : 'Passwort anzeigen'
              "
              matTooltipPosition="right"
              (click)="togglePasswordVisability('oldPassword')">
              <div *ngIf="showOldPassword">
                <i class="fa-light fa-eye"></i>
              </div>
              <div *ngIf="!showOldPassword">
                <i class="fa-light fa-eye-slash"></i>
              </div>
            </button>
          </div>

          <!-- Error Messages -->
          <div class="hasomed-error-container">
            <div class="hasomed-text-error" *ngIf="hasErrors('oldPassword')">
              <span
                *ngIf="
                  changePasswordForm.get('oldPassword').hasError('required')
                ">
                Dieses Feld ist erforderlich
              </span>
              <span
                *ngIf="
                  changePasswordForm
                    .get('oldPassword')
                    .hasError('wrongPassword')
                ">
                Falsches Passwort
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- New password -->
      <div class="formular-row">
        <label for="newPassword" class="formular-row-label">
          <div class="label-title">Neues Passwort</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="newPassword"
              class="hasomed-text-input"
              [type]="showNewPassword ? 'text' : 'password'"
              formControlName="newPassword"
              placeholder="********"
              autocomplete="on" />
            <button
              type="button"
              class="button-show-hide-password"
              [matTooltip]="
                showNewPassword ? 'Passwort verstecken' : 'Passwort anzeigen'
              "
              matTooltipPosition="right"
              (click)="togglePasswordVisability('newPassword')">
              <div *ngIf="showNewPassword">
                <i class="fa-light fa-eye"></i>
              </div>
              <div *ngIf="!showNewPassword">
                <i class="fa-light fa-eye-slash"></i>
              </div>
            </button>
          </div>
          <!-- Error Messages -->
          <div class="hasomed-error-container">
            <div class="hasomed-text-error" *ngIf="hasErrors('newPassword')">
              <span
                *ngIf="
                  changePasswordForm.get('newPassword').hasError('required')
                ">
                Dieses Feld ist erforderlich
              </span>
              <span
                *ngIf="
                  changePasswordForm
                    .get('newPassword')
                    .hasError('sameAsOldPassword')
                ">
                Das neue Passwort darf nicht mit dem alten Passwort
                übereinstimmen
              </span>
              <span
                *ngIf="
                  changePasswordForm.get('newPassword').hasError('minLength')
                ">
                Das Passwort muss mindestens 6 Zeichen lang sein
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- New password confirm -->
      <div class="formular-row">
        <label for="newPasswordConfirm" class="formular-row-label">
          <div class="label-title">Neues Passwort bestätigen</div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="newPasswordConfirm"
              class="hasomed-text-input"
              [type]="showNewPasswordConfirm ? 'text' : 'password'"
              formControlName="newPasswordConfirm"
              placeholder="********"
              autocomplete="on" />
            <button
              type="button"
              class="button-show-hide-password"
              [matTooltip]="
                showNewPasswordConfirm
                  ? 'Passwort verstecken'
                  : 'Passwort anzeigen'
              "
              matTooltipPosition="right"
              (click)="togglePasswordVisability('newPasswordConfirm')">
              <div *ngIf="showNewPasswordConfirm">
                <i class="fa-light fa-eye"></i>
              </div>
              <div *ngIf="!showNewPasswordConfirm">
                <i class="fa-light fa-eye-slash"></i>
              </div>
            </button>
          </div>

          <!-- Error Messages -->
          <div class="hasomed-error-container">
            <div
              class="hasomed-text-error"
              *ngIf="hasErrors('newPasswordConfirm')">
              <span
                *ngIf="
                  changePasswordForm
                    .get('newPasswordConfirm')
                    .hasError('required')
                ">
                Dieses Feld ist erforderlich
              </span>
              <span
                *ngIf="
                  changePasswordForm
                    .get('newPasswordConfirm')
                    .hasError('notSame')
                ">
                Passwörter stimmen nicht überein
              </span>
              <span
                *ngIf="
                  changePasswordForm
                    .get('newPasswordConfirm')
                    .hasError('minLength')
                ">
                Das Passwort muss mindestens 6 Zeichen lang sein
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- Buttons -->
      <div mat-dialog-actions class="formular-buttons">
        <button
          type="submit"
          class="hasomed-text-button hasomed-button-primary"
          [disabled]="
            !changePasswordForm.valid || changePasswordForm.valid !== true
          "
          (click)="onConfirm()">
          <i class="hasomed-button-icon fa-light fa-check"></i>
          <span class="hasomed-button-text">Passwort ändern</span>
        </button>
        <button
          type="button"
          class="hasomed-text-button hasomed-button-white"
          (click)="onDismiss()">
          <i class="hasomed-button-icon fa-light fa-x"></i>
          <span class="hasomed-button-text">Abbrechen</span>
        </button>
      </div>
    </form>
  </div>
</div>
