import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/modules/material.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/modules/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SettingsComponent } from './settings.component';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { EditPersonalDataComponent } from './edit-personal-data/edit-personal-data.component';
import { ImprintComponent } from './imprint/imprint.component';
import { InstituteInformationComponent } from './institute-information/institute-information.component';

@NgModule({
  declarations: [
    SettingsComponent,
    PersonalDataComponent,
    EditPersonalDataComponent,
    ImprintComponent,
    InstituteInformationComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ImageCropperModule,
  ],
  exports: [SettingsComponent],
})
export class SettingsModule {}
