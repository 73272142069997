<div class="component-content">
  <!-- Header -->
  <div class="header">
    <div class="header-title">
      <app-user-container-header-skeleton
        *ngIf="isLoading"></app-user-container-header-skeleton>

      <div *ngIf="!isLoading" class="user-container-header">
        <img
          src="{{
            lecturer.profilePicture ?? '../../../../../assets/img/user.jpg'
          }}"
          alt
          class="user-icon-image" />
        <div class="user-info">
          <h1 class="hasomed-text-h1">
            {{ getFullName(lecturer) }}
          </h1>
          <div class="user-identifier-row">
            <h3 class="hasomed-text-h3">
              {{ lecturer?.userIdentifier ?? '-' }}
            </h3>
            <div
              *ngIf="lecturer?.label"
              [style.background]="lecturer?.label?.color"
              class="label">
              <i class="fa-light fa-id-badge label-icon"></i>
              <span class="label-text">
                {{ lecturer?.label?.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-buttons">
      <button
        (click)="onBackToOverview()"
        class="hasomed-text-button hasomed-button-white-grey">
        <i class="fa-light fa-chevron-left hasomed-button-icon"></i>
        <span class="hasomed-button-text">
          Zurück {{ !isMobile ? 'zur Übersicht' : '' }}
        </span>
      </button>

      <button
        *appCheckPermissions="{
          feature: feature.STUDENT_ADMINISTRATION,
          permission: permission.ADMIN,
        }"
        (click)="editLecturer()"
        class="hasomed-text-button hasomed-button-white-grey">
        <i class="fa-light fa-pen hasomed-button-icon"></i>
        <span class="hasomed-button-text">Bearbeiten</span>
      </button>
    </div>
  </div>

  <!-- Main content -->
  <div class="main-content">
    <div class="child-navigation lecturer-detail-child-nav">
      <a
        #rla="routerLinkActive"
        *ngFor="let link of navLinks"
        [routerLink]="link.link"
        [class.hasomed-button-blank]="!rla.isActive"
        routerLinkActive="hasomed-button-transparent-primary"
        class="hasomed-text-button">
        <span class="hasomed-text-menu">{{ link.label }}</span>
      </a>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
