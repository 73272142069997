export const environment = {
  authority: 'http://172.20.192.238/tgp/',
  authority_short: '/tgp/',
  roleSwitchingEnabled: false,
  exportedKeyMaterial: {
    encryptedDmk:
      'iLyamu/y7Yhloy2hChXVKdoT5SAIHpJgkJKG6BiSf7wKwxxYz9RsR81Dauf+GLPcRsisuWny4KHR+zb/',
    encryptedKek:
      'Imkx2yoUd6XSsAefnzvPDQdvYsNwQWCKIxr76gEy25MkjKc+33zbEyMSsvXCEjSe0qbCem8dM7Ke7AzA',
    pek: 'lg+v+z1ak2dLdf0SSlSgO92MmAeCqh7YliMmdbWS/oo=',
    privateKey:
      '84UjuQ3FlXDnCMicRmTJymdE1efMo7t/NzbykuP2RQ+adklM2fymNcrwWBgmuc2eQFRISmbdAd9gzbzgwuWT6eFME0JMOTLOqdCAGzJE9AgOmzeBISQvfTfAGJ/rsopOnGh1gSUBhBHs0CZJyAeXEW6znedxAz/3vWxztXnCs53nw4Z8QX7cNUUOkVZywyaeX3m6pOYVsuff3YlxGFBUEqKZwzDcrDviO5gLO7tvLOJRJ6ADvEQn19TqWNl8UTftp+bjEu7uP1CPqRDqzbNuBsQnQccfJMNtmPQ4lB8f18vAYWcN+P5SGNh8Z/YKzASQzR+qSm2k5Bw6tSQm8gaXTycMx0R+94xW/iQVSOKo6u614Fl/sb4ram363oRJTTNavSFJ4T/NFk31+o5jmDjCp9G2K2gQ/6+Pep1k21hTEXMVqqyPwLx3ec0MD+F+gpC8OtOyTpXIm98lTzQC4brySq09ituU3+D5xprVpI82EJqVSSMrdaM=',
    publicKey:
      'BAGWtN91v9SeC+aLLLjAWpsJkek/pTWNniwdvtJqC7nnaQ7n/B4Yn2bRAjbanIO5cKElF22X/Ac2ghd3zWUJgID9jgF0B/m8E3LjRCzglCbBVGA32YaxB8MDSYhMYuQy8bGX32wki0pX7bNkpudkOQ9nQH8HFkncjyzzd1HSIun1A1ndyw==',
  },
};
