import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { NavLink } from 'src/app/models/nav-link.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { UserModel } from 'src/app/models/user.model';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserService } from 'src/app/services/user.service';
import { getFullName } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-lecturer-detail',
  templateUrl: './lecturer-detail.component.html',
  styleUrl: './lecturer-detail.component.scss',
})
export class LecturerDetailComponent implements OnInit, OnDestroy {
  public lecturer: UserModel;
  public isLoading = true;
  public navLinks: NavLink[];
  public userId: number;
  public isMobile = false;
  public isTablet = false;

  /* for permission */
  public feature = Feature;
  public permission = Permission;

  // import from utils
  public getFullName = getFullName;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cancellationService: CancellationService,
    private navigationService: NavigationService,
    private mobileUiService: MobileUiService,
    private alertService: AlertService
  ) {
    this.navLinks = [
      {
        label: 'Kurse & Anwesenheiten',
        link: 'courses',
        feature: Feature.LECTURER_ADMINISTRATION,
        permission: Permission.VIEW,
      },
      {
        label: 'Stammdaten',
        link: 'master-data',
        feature: Feature.LECTURER_ADMINISTRATION,
        permission: Permission.VIEW,
      },
    ];
  }

  public ngOnInit() {
    this.userId = +atob(this.activatedRoute.snapshot.paramMap.get('userId'));
    this.getLecturer(this.userId);

    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.isMobile = currentView === 'mobile';
        this.isTablet = currentView === 'tablet';
      });
  }

  /**
   * getLecturer
   * @description get lecturer data from backend
   * @param userId id of the lecturer
   * @returns void
   */
  private getLecturer(userId: number): void {
    this.userService
      .getInstituteUserById(userId, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.lecturer = await this.userService.parseBackendUser(
            response.body
          );
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Lehrpersonal konnte nicht geladen werden.'
          );
        },
      });
  }

  /**
   * editLecturer
   * @description navigate to edit lecturer page
   * @returns void
   */
  public editLecturer(): void {
    this.router.navigate([
      `/eleguide/education/lecturers/edit/${btoa(String(this.userId))}`,
    ]);
  }

  /**
   * navigate back to the previous overview page or to the lecturers overview
   * @returns void
   */
  public onBackToOverview(): void {
    this.navigationService.getOverviewUrl()
      ? this.router.navigateByUrl(this.navigationService.getOverviewUrl())
      : this.router.navigate(['/eleguide/education/lecturers']);
  }

  /**
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
