import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function positiveNumbersOnlyValidator(
  includeNull: boolean
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    // numbers only pattern, decimal allowed, negative not allowed
    const pattern = new RegExp(
      '^[' + (includeNull ? '0' : '1') + '-9]+[0-9]*$'
    );
    const valid = pattern.test(control.value);

    return valid ? null : { positiveNumbersOnly: true };
  };
}
