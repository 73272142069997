<div class="component-content">
  <div class="header">
    <div class="header-title" *ngIf="!isMobile">
      <h1 class="hasomed-text-h1">Anwesenheit</h1>
      <h3 class="hasomed-text-h3">
        Überprüfen Sie die Anwesenheit in diesem Kurs.
      </h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onBackToOverview()"
        class="hasomed-text-button hasomed-button-white-grey">
        <i class="fa-light fa-chevron-left hasomed-button-icon"></i>
        <span class="hasomed-button-text"> Zurück zur Übersicht </span>
      </button>
    </div>
  </div>

  <div class="main-content gap-40">
    <ng-container *ngIf="courseIsLoading">
      <app-course-elog-detail-skeleton></app-course-elog-detail-skeleton>
    </ng-container>
    <div *ngIf="!courseIsLoading" class="flex-r gap-24 summary">
      <div class="flex-r image-container" *ngIf="!isMobile">
        <img [src]="course.titlePicture" class="course-image" alt="Titelbild" />
        <div *ngIf="course.mandatory" class="label-small label-course-image">
          <i class="label-icon fa-light fa-file-certificate"></i>
          <span class="label-text">Ausbildungsrelevant</span>
        </div>
      </div>

      <div class="flex-c gap-8 justify-space-between">
        <h1
          [matTooltip]="course.title?.length > 150 ? course.title : ''"
          class="hasomed-text-h1">
          {{ course.title | slice: 0 : 150
          }}{{ course.title?.length > 150 ? '...' : '' }}
        </h1>
        <div class="flex-c gap-16">
          <div class="flex-c gap-8">
            <span class="hasomed-text-body gray-600">
              {{ getFullNames(course?.lecturers, false) }}
            </span>
            <span class="hasomed-text-body gray-600">
              {{ getCourseDateRange(course) }}
            </span>
            <span class="hasomed-text-body gray-600">
              {{ getEducationCourseTitles(course) }}
            </span>
          </div>
          <div class="label-small label-white">
            <i class="label-icon fa-light fa-book"></i>
            <span class="label-text">
              {{ course.mandatory ? 'Verpflichtend' : 'Freiwillig' }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-c course-details-main table-no-paginator">
      <table
        *ngIf="showEventTable"
        [dataSource]="eventDataSource"
        mat-table
        class="course-details-table">
        <!-- date column -->
        <ng-container matColumnDef="date">
          <th *matHeaderCellDef mat-header-cell>Datum</th>
          <td *matCellDef="let row; dataSource: eventDataSource" mat-cell>
            <span>
              {{ row.startDate | date: 'dd.MM.yyyy' }}
            </span>
          </td>
        </ng-container>

        <!-- time column -->
        <ng-container matColumnDef="time">
          <th *matHeaderCellDef mat-header-cell>Uhrzeit</th>
          <td *matCellDef="let row; dataSource: eventDataSource" mat-cell>
            <span class="gray-600">
              {{ row.startDate | date: 'HH:mm' }} -
              {{ row.endDate | date: 'HH:mm' }} Uhr
            </span>
          </td>
        </ng-container>

        <!-- room column -->
        <ng-container matColumnDef="room">
          <th *matHeaderCellDef mat-header-cell>Raum</th>
          <td *matCellDef="let row; dataSource: eventDataSource" mat-cell>
            {{ row.room?.name ?? '-' }}
          </td>
        </ng-container>

        <!-- participants column -->
        <ng-container matColumnDef="participantAmount">
          <th *matHeaderCellDef mat-header-cell>Teilnehmeranzahl</th>
          <td *matCellDef="let row; dataSource: eventDataSource" mat-cell>
            {{ getParticipantAmount(row) + ' Teilnehmende' }}
          </td>
        </ng-container>

        <!-- eLog column -->
        <ng-container matColumnDef="eLog">
          <th *matHeaderCellDef mat-header-cell>Anwesenheit</th>
          <td *matCellDef="let row; dataSource: eventDataSource" mat-cell>
            <app-elog-cell
              [eLogStatus]="row.elogStatus"
              [eLogStatusCounts]="row.elogStatusCounts"></app-elog-cell>
          </td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="center-header-cell" mat-header-cell>
            {{ isMobile ? 'Akt.' : 'Aktionen' }}
          </th>
          <td *matCellDef="let row; dataSource: eventDataSource" mat-cell>
            <div class="table-button-icons"></div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="eventColumnsToDisplay" mat-header-row></tr>
          <tr *matRowDef="let row; columns: eventColumnsToDisplay" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="eventColumnsToDisplay.length">
              <div *ngIf="firstTableDataIsLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!firstTableDataIsLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Es wurden keine Kurstermine gefunden.
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table
        *ngIf="showStudentTable"
        [dataSource]="studentTableDataSource"
        mat-table
        class="course-details-table">
        <!-- name column -->
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell>Kandidat</th>
          <td
            *matCellDef="let row; dataSource: studentTableDataSource"
            mat-cell>
            <div class="user-container">
              <div class="user-icon" *ngIf="!isMobile">
                <img
                  src="{{ row?.profilePicture ?? '../assets/img/user.jpg' }}"
                  alt="Profilbild"
                  class="user-icon-image" />
              </div>
              <div class="user-info">
                <span class="user-name hasomed-text-body">
                  {{ getFullName(row) }}
                </span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- userIdentifier column -->
        <ng-container matColumnDef="userIdentifier">
          <th *matHeaderCellDef mat-header-cell>Bildungs-ID</th>
          <td
            *matCellDef="let row; dataSource: studentTableDataSource"
            mat-cell>
            <span class="gray-600">
              {{ row?.userIdentifier ?? '-' }}
            </span>
          </td>
        </ng-container>

        <!-- entryDate column -->
        <ng-container matColumnDef="entryDate">
          <th *matHeaderCellDef mat-header-cell>Eintrittsdatum</th>
          <td
            *matCellDef="let row; dataSource: studentTableDataSource"
            mat-cell>
            {{ row.entryDate | date: 'dd.MM.yyyy' }}
          </td>
        </ng-container>

        <!-- label column -->
        <ng-container matColumnDef="label">
          <th *matHeaderCellDef mat-header-cell>Etikett</th>
          <td
            *matCellDef="let row; dataSource: studentTableDataSource"
            mat-cell>
            <div
              *ngIf="row.label"
              [style.background]="row.label?.color"
              class="label">
              <i class="fa-light fa-id-badge label-icon"></i>
              <span class="label-text">
                {{ row.label?.name }}
              </span>
            </div>
            <span *ngIf="!row.label">-</span>
          </td>
        </ng-container>

        <!-- eLog column -->
        <ng-container matColumnDef="eLog">
          <th *matHeaderCellDef mat-header-cell>Anwesenheit</th>
          <td
            *matCellDef="let row; dataSource: studentTableDataSource"
            mat-cell>
            <app-elog-cell
              [eLogStatusCounts]="eLogStatusCounts"></app-elog-cell>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="studentColumnsToDisplay" mat-header-row></tr>
          <tr
            *matRowDef="let row; columns: studentColumnsToDisplay"
            mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="studentColumnsToDisplay.length">
              <div *ngIf="firstTableDataIsLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!firstTableDataIsLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Kandidat wurde nicht gefunden.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flex-c gap-24">
      <div class="multiple-select-row">
        <button
          #t="matMenuTrigger"
          [matMenuTriggerFor]="setMultipleELogMenu"
          [disabled]="isSetELogStatusDisabled"
          [class.hasomed-button-orange]="t.menuOpen"
          [class.hasomed-button-white-grey]="!t.menuOpen"
          [matTooltip]="setStatusTooltip"
          type="button"
          class="hasomed-text-button">
          <i class="hasomed-button-icon fa-light fa-sliders"></i>
          <div class="hasomed-button-text">
            Mehrfachauswahl

            <div *ngIf="t.menuOpen">
              <i class="hasomed-button-icon fa-chevron-up fa-light"></i>
            </div>
            <div *ngIf="!t.menuOpen">
              <i class="hasomed-button-icon fa-chevron-down fa-light"></i>
            </div>
          </div>
        </button>

        <mat-menu #setMultipleELogMenu="matMenu" class="eLog-menu">
          <button
            *ngFor="let status of getAvailableElogs(); let last = last"
            [disabled]="isSetELogStatusDisabled"
            (click)="updateSelectedUsersELogStatus(status)"
            mat-menu-item>
            <div
              [class.margin]="!last"
              class="flex-r gap-16 align-center eLog-menu-item">
              <i
                [ngClass]="{
                  'fa-circle-check': status === eLogStatus.CHECKED,
                  'fa-circle-dot': status === eLogStatus.ABSENT,
                  'fa-circle-dashed': status === eLogStatus.PENDING,
                  'fa-circle-minus': status === eLogStatus.EXCUSED,
                  'fa-circle-xmark': status === eLogStatus.UNEXCUSED,
                }"
                class="fa-light"></i>
              <span class="hasomed-text-body">
                <ng-container *ngIf="status === eLogStatus.CHECKED"
                  >anwesend</ng-container
                >
                <ng-container *ngIf="status === eLogStatus.ABSENT"
                  >abwesend</ng-container
                >
                <ng-container *ngIf="status === eLogStatus.PENDING"
                  >ausstehend</ng-container
                >
                <ng-container *ngIf="status === eLogStatus.EXCUSED"
                  >entschuldigt</ng-container
                >
                <ng-container *ngIf="status === eLogStatus.UNEXCUSED"
                  >nicht entschuldigt</ng-container
                >
              </span>
            </div>
            <mat-divider *ngIf="!last"></mat-divider>
          </button>
        </mat-menu>

        <form [formGroup]="searchForm" class="search-form flex-grow">
          <div class="hasomed-search">
            <i class="fa-light fa-search hasomed-search-icon"></i>
            <input
              (keyup)="applyFilter($event)"
              id="searchInput"
              class="hasomed-search-input"
              type="text"
              formControlName="searchText"
              placeholder="Suche" />
          </div>
        </form>
      </div>
      <div
        [class.table-bicolor]="eLogDataSource?.filteredData?.length > 1"
        class="table">
        <table [dataSource]="eLogDataSource" mat-table matSort>
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell>
              <mat-checkbox
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                (change)="$event ? toggleAllRows() : null"
                color="primary">
              </mat-checkbox>
            </th>
            <td *matCellDef="let row; dataSource: eLogDataSource" mat-cell>
              <mat-checkbox
                [checked]="selection.isSelected(row)"
                (click)="$event.stopPropagation()"
                (change)="$event ? selectRow(row) : null"
                color="primary">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Student column -->
          <ng-container matColumnDef="studentName">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              Teilnehmende
              <app-custom-table-sort-icon
                [sort]="sort"
                columnName="studentName">
              </app-custom-table-sort-icon>
            </th>
            <td *matCellDef="let row; dataSource: eLogDataSource" mat-cell>
              <div class="user-container">
                <div class="user-icon" *ngIf="!isMobile">
                  <img
                    src="{{
                      row.user?.profilePicture ??
                        '../../../../../../../../assets/img/user.jpg'
                    }}"
                    alt
                    class="user-icon-image" />
                </div>
                <div class="user-info">
                  {{ getFullName(row.user) }}
                </div>
              </div>
            </td>
          </ng-container>

          <!-- userIdentifier column -->
          <ng-container matColumnDef="userIdentifier">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              Bildungs-ID
              <app-custom-table-sort-icon
                [sort]="sort"
                columnName="userIdentifier">
              </app-custom-table-sort-icon>
            </th>
            <td
              *matCellDef="let row; dataSource: eLogDataSource"
              class="elog-cell"
              mat-cell>
              {{ row.user?.userIdentifier ?? '-' }}
            </td>
          </ng-container>

          <!-- date column -->
          <ng-container matColumnDef="date">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              Datum
              <app-custom-table-sort-icon [sort]="sort" columnName="date">
              </app-custom-table-sort-icon>
            </th>
            <td *matCellDef="let row; dataSource: eLogDataSource" mat-cell>
              <span>
                {{ row.courseEventDate?.startDate | date: 'dd.MM.yyyy' }}
              </span>
            </td>
          </ng-container>

          <!-- time column -->
          <ng-container matColumnDef="time">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              Uhrzeit
              <app-custom-table-sort-icon [sort]="sort" columnName="time">
              </app-custom-table-sort-icon>
            </th>
            <td *matCellDef="let row; dataSource: eLogDataSource" mat-cell>
              <span class="gray-600">
                {{ row.courseEventDate?.startDate | date: 'HH:mm' }} -
                {{ row.courseEventDate?.endDate | date: 'HH:mm' }} Uhr
              </span>
            </td>
          </ng-container>

          <!-- room column -->
          <ng-container matColumnDef="room">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              Raum
              <app-custom-table-sort-icon [sort]="sort" columnName="room">
              </app-custom-table-sort-icon>
            </th>
            <td *matCellDef="let row; dataSource: eLogDataSource" mat-cell>
              {{ row.courseEventDate?.room?.name ?? '-' }}
            </td>
          </ng-container>

          <!-- eLog Status column -->
          <ng-container matColumnDef="eLogStatus">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              Anwesenheit
              <app-custom-table-sort-icon [sort]="sort" columnName="eLogStatus">
              </app-custom-table-sort-icon>
            </th>
            <td
              *matCellDef="let row; dataSource: eLogDataSource"
              class="elog-cell"
              mat-cell>
              <app-elog-cell
                class="elog"
                [eLogStatus]="row.status"></app-elog-cell>
            </td>
          </ng-container>

          <!-- Actions column -->
          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef class="center-header-cell" mat-header-cell>
              {{ isMobile ? 'Akt.' : 'Aktionen' }}
            </th>
            <td *matCellDef="let row; dataSource: eLogDataSource" mat-cell>
              <div class="table-button-icons">
                <button
                  [disabled]="
                    row.status === eLogStatus.CHECKED ||
                    isSingleELogStatusDisabled(row)
                  "
                  [matTooltip]="
                    row.status === eLogStatus.CHECKED ||
                    isSingleELogStatusDisabled(row)
                      ? ''
                      : 'Als anwesend setzen'
                  "
                  (click)="updateSingleELogStatus(row, eLogStatus.CHECKED)"
                  type="button"
                  class="table-button-icon">
                  <i class="fa-light fa-circle-check"></i>
                </button>
                <button
                  *ngIf="!isMobile"
                  #triggerSingleELog="matMenuTrigger"
                  [matMenuTriggerFor]="setSingleELogMenu"
                  [matTooltip]="
                    isSingleELogStatusDisabled(row)
                      ? ''
                      : 'Anwesenheitsstatus setzen'
                  "
                  [class.color-primary]="triggerSingleELog.menuOpen"
                  [disabled]="isSingleELogStatusDisabled(row)"
                  type="button"
                  class="table-button-icon"
                  matTooltip="Anwesenheitsstatus setzen">
                  <i class="fa-light fa-pen-to-square"></i>
                </button>
              </div>
              <mat-menu #setSingleELogMenu="matMenu" class="eLog-menu">
                <button
                  *ngFor="let status of getAvailableElogs(); let last = last"
                  [disabled]="isSingleELogStatusDisabled(row)"
                  (click)="updateSingleELogStatus(row, status)"
                  mat-menu-item>
                  <div
                    [class.margin]="!last"
                    class="flex-r gap-16 align-center eLog-menu-item">
                    <i
                      [ngClass]="{
                        'fa-circle-check': status === eLogStatus.CHECKED,
                        'fa-circle-dot': status === eLogStatus.ABSENT,
                        'fa-circle-dashed': status === eLogStatus.PENDING,
                        'fa-circle-minus': status === eLogStatus.EXCUSED,
                        'fa-circle-xmark': status === eLogStatus.UNEXCUSED,
                      }"
                      class="fa-light"></i>
                    <span class="hasomed-text-body">
                      <ng-container *ngIf="status === eLogStatus.CHECKED"
                        >anwesend</ng-container
                      >
                      <ng-container *ngIf="status === eLogStatus.ABSENT"
                        >abwesend</ng-container
                      >
                      <ng-container *ngIf="status === eLogStatus.PENDING"
                        >ausstehend</ng-container
                      >
                      <ng-container *ngIf="status === eLogStatus.EXCUSED"
                        >entschuldigt</ng-container
                      >
                      <ng-container *ngIf="status === eLogStatus.UNEXCUSED"
                        >nicht entschuldigt</ng-container
                      >
                    </span>
                  </div>
                  <mat-divider *ngIf="!last"></mat-divider>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tbody>
            <tr *matHeaderRowDef="eLogColumnsToDisplay" mat-header-row></tr>
            <tr
              *matRowDef="let row; columns: eLogColumnsToDisplay"
              mat-row></tr>

            <!-- Row shown when there is no matching data. -->
            <tr *matNoDataRow class="mat-row">
              <td [attr.colspan]="eLogColumnsToDisplay.length">
                <div *ngIf="eLogTableDataIsLoading" class="loading-wrapper">
                  <app-lottie-loading-spinner
                    size="45px"></app-lottie-loading-spinner>
                </div>
                <div
                  *ngIf="!eLogTableDataIsLoading"
                  class="mat-cell table-no-match hasomed-text-body font-grey">
                  Es wurden keine Teilnehmer für diesen Termin gefunden.
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <mat-divider></mat-divider>
        <mat-paginator
          [appCustomLength]="eLogDataSource.filteredData.length"
          [pageSize]="6"
          [pageNumber]="currentELogDataSourcePage"
          [renderButtonsNumber]="isMobile ? 0 : 1"
          custom-paginator
          aria-label="Select page"></mat-paginator>
      </div>
    </div>
  </div>
</div>
