import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first, Observable, Subject } from 'rxjs';
import { ConfirmDialogComponent } from '../components/shared-components/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class FormDeactivateService {
  constructor(private dialog: MatDialog) {}

  /**
   * confirmDeactivation
   * @param currentValue the current value of the form
   * @param initialValue the initial value of the form
   * @returns Observable<boolean> if the user wants to leave the page
   */
  public confirmDeactivation(
    currentValue: any,
    initialValue: any
  ): Observable<boolean> {
    // check if the form has unsaved changes
    if (this.hasUnsavedChanges(currentValue, initialValue)) {
      const deactivateSubject = new Subject<boolean>();
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Ungespeicherte Änderungen!',
          message:
            'Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren. \
            Möchten Sie die Seite trotzdem verlassen?',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe(dialogResult => {
          deactivateSubject.next(dialogResult);
        });

      return deactivateSubject.asObservable();
    }
    return new Observable<boolean>(observer => {
      observer.next(true);
      observer.complete();
    });
  }

  /**
   * hasUnsavedChanges
   * checks if the form has unsaved changes
   * @param currentValue the current value of the form
   * @param initialValue the initial value of the form
   * @returns boolean if the form has unsaved changes
   */
  public hasUnsavedChanges(currentValue: any, initialValue: any): boolean {
    if (initialValue && initialValue['tinyMCE']) {
      initialValue['tinyMCE'] = this.decodeHtml(initialValue['tinyMCE']);
      currentValue['tinyMCE'] = this.decodeHtml(currentValue['tinyMCE']);
    }

    return JSON.stringify(currentValue) !== JSON.stringify(initialValue);
  }

  /**
   * decodeHtml
   * decodes html entities
   * @param html the html string
   * @returns string the decoded html
   */
  private decodeHtml(html: string): string {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }
}
