import { Component } from '@angular/core';

@Component({
  selector: 'app-student-overview-of-fees',
  templateUrl: './student-overview-of-fees.component.html',
  styleUrls: ['./student-overview-of-fees.component.scss']
})
export class StudentOverviewOfFeesComponent {

}
