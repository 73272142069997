import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/modules/material.module';
import { SharedModule } from 'src/app/modules/shared.module';
import { LecturerCoursesComponent } from './lecturer-courses/lecturer-courses.component';
import { LecturerDetailComponent } from './lecturer-detail.component';

@NgModule({
  declarations: [LecturerDetailComponent, LecturerCoursesComponent],
  exports: [LecturerDetailComponent],
  imports: [
    MaterialModule,
    BrowserModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
  ],
})
export class LecturerDetailModule {}
