import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { PermissionService } from 'src/app/services/permission.service';

@Component({
  selector: 'app-default-route',
  templateUrl: './default-route.component.html',
  styleUrls: ['./default-route.component.scss'],
})
export class DefaultRouteComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private permissionService: PermissionService,
    private alertService: AlertService
  ) {}

  public ngOnInit() {
    this.activatedRoute.data.pipe(takeUntil(this.destroy$)).subscribe(data => {
      /* iterate over all routePermissions and check if the user has permission */
      const routePermissions = data.routePermissions;
      for (const route of routePermissions) {
        if (
          this.permissionService.checkPermission(
            route.feature,
            route.permission
          )
        ) {
          /* If the user has the permission, navigate to the corresponding route */
          this.router.navigate([route.route], {
            relativeTo: this.activatedRoute,
          });
          return;
        }
      }

      /* Did not find a route with permission, navigate to settings */
      this.router.navigate(['settings']);

      this.alertService.showErrorAlert(
        'Fehler!',
        'Keine Berechtigung für die Route'
      );
    });
  }

  /**
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
