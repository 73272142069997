import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Feature, Permission } from 'src/app/models/permission.model';
import { WikiPage } from 'src/app/models/wiki.model';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { WikiService } from 'src/app/services/wiki.service';
import { getFullName } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-wiki',
  templateUrl: './wiki.component.html',
  styleUrls: ['./wiki.component.scss'],
})
export class WikiComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public isMobile = false;
  public isTablet = false;
  public showSearchBar = false;
  public showDrafts = false;
  public selectedPage: WikiPage;
  public searchForm = new FormGroup({
    searchText: new FormControl(''),
  });
  public wikiPages: WikiPage[];
  public filteredWikiPages: WikiPage[];
  public feature = Feature;
  public permission = Permission;
  @ViewChild('articleContainer') articleContainer: ElementRef;

  private destroy$: Subject<void> = new Subject<void>();

  public getFullName = getFullName;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private wikiService: WikiService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private cancellationService: CancellationService,
    private mobileUiService: MobileUiService
  ) {}

  public ngOnInit() {
    this.getAllWikiPages();
    this.viewChanges();
  }

  /**
   * sets isMobile and isTablet depending on the current view
   * @returns void
   */
  private viewChanges() {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.isMobile = currentView === 'mobile';
        this.isTablet = currentView === 'tablet';

        this.showSearchBar = currentView === 'desktop';

        if (!this.isMobile && !this.selectedPage) {
          this.selectedPage =
            this.wikiPages?.length > 0 ? this.wikiPages[0] : null;
        } else if (this.isMobile && this.selectedPage) {
          this.selectedPage = null;
        }
      });
  }

  /**
   * retrieves all wiki pages of the current institute
   * @returns void
   */
  private getAllWikiPages(): void {
    this.wikiService
      .getAllWikiPages()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          if (response.body) {
            this.wikiPages = response.body
              ? await Promise.all(
                  response.body?.map((page: any) => {
                    return this.wikiService.parseBackendWikiPage(page);
                  })
                )
              : [];

            this.filteredWikiPages = this.wikiPages;
            this.selectedPage = this.isMobile ? null : this.wikiPages[0];
          } else {
            this.wikiPages = [];
            this.filteredWikiPages = [];
            this.selectedPage = null;
          }
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Informations-Artikel konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * applies the search filter to the wiki pages
   * @param event The event
   */
  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    const trimmedFilterValue = filterValue.trim().toLowerCase();
    this.filteredWikiPages = this.wikiPages.filter(it =>
      it.title.toLowerCase().includes(trimmedFilterValue)
    );
    if (this.filteredWikiPages.length > 0) {
      this.scrollToTop();
      this.selectedPage = this.isMobile ? null : this.filteredWikiPages[0];
    } else {
      this.selectedPage = null;
    }
  }

  /**
   * scrolls to the top of the article container
   * @returns void
   */
  private scrollToTop(): void {
    const element = this.articleContainer.nativeElement;
    element.scrollTop = 0;
  }

  /**
   * navigates to the create wiki page component
   * @returns void
   */
  public onCreate(): void {
    this.router.navigate(['create'], { relativeTo: this.activatedRoute });
  }

  /**
   * navigates to the edit wiki page component
   * @param wikiPage The wiki page
   * @returns void
   */
  public onEdit(wikiPage: WikiPage): void {
    this.router.navigate(['edit', btoa(wikiPage.id.toString())], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * selects a wiki page
   * @param wikiPage The wiki page
   * @returns void
   */
  public onSelectPage(wikiPage: WikiPage) {
    //
    this.scrollToTop();
    this.selectedPage = wikiPage;
  }

  /**
   * gets the sanitized article content
   * @returns SafeHtml
   */
  public getSanitizedArticleContent(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.selectedPage?.content);
  }

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
