import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  public countries = ['Deutschland', 'Österreich', 'Schweiz'];

  constructor() {}
}
