import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import * as moment from 'moment-timezone';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  SupervisionAppointmentModel,
  SupervisionCreateModel,
  SupervisionEventDateModel,
  SupervisionEventDateStatusUpdateModel,
  SupervisionEventDateUpdateModel,
} from '../models/supervision.model';
import { CancellationService } from './cancellation.service';
import { DecryptionService } from './decryption.service';
import { FileService } from './file.service';
import { RoomService } from './room.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class SupervisionService {
  private instituteId: number =
    this.userService.currentUser?.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService,
    private cryptoService: CryptoService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService,
    private roomService: RoomService,
    private fileService: FileService
  ) {}

  /**
   * Get a supervision appointment by id
   * @param supervisionAppointmentId The supervision appointment id
   * @returns Observable<HttpResponse<any>>
   */
  public getSupervisionAppointmentById(
    supervisionAppointmentId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/supervision-appointments/${supervisionAppointmentId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Create a supervision appointment
   * @param supervisionAppointmentCreateModel The suervision appointment create model
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async createSupervisionAppointment(
    supervisionAppointmentCreateModel: SupervisionCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    supervisionAppointmentCreateModel.videoMeetingLink =
      supervisionAppointmentCreateModel.videoMeetingLink &&
      (await this.cryptoService.encrypt(
        supervisionAppointmentCreateModel.videoMeetingLink
      ));

    return this.http.post(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/supervisions`,
      supervisionAppointmentCreateModel,
      {
        headers: headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Update a supervision appointment
   * @param supervisionAppointmentId The supervision appointment id
   * @param supervisionAppointmentUpdateModel The supervision appointment update model
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async updateSupervisionAppointment(
    supervisionAppointmentId: number,
    supervisionAppointmentUpdateModel: SupervisionCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    supervisionAppointmentUpdateModel.videoMeetingLink =
      supervisionAppointmentUpdateModel.videoMeetingLink &&
      (await this.cryptoService.encrypt(
        supervisionAppointmentUpdateModel.videoMeetingLink
      ));

    return this.http.put(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/supervision-appointments/${supervisionAppointmentId}`,
      supervisionAppointmentUpdateModel,
      {
        headers: headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * openFile
   * get file from backend and open it in a new tab
   * @param supervisionId The supervision id
   * @param supervisionEventDateId The supervision appointment id
   * @param fileId The file id
   * @returns void
   */
  public openFile(
    supervisionId: number,
    supervisionEventDateId: number,
    fileId: number
  ): void {
    this.fileService.openFile(
      `/api/institutes/${this.instituteId}/supervisions/${supervisionId}/event-dates/${supervisionEventDateId}/files/${fileId}`
    );
  }

  /**
   * downloadFile
   * get file from backend and download it
   * @param supervisionId The supervision id
   * @param supervisionEventDateId The supervision appointment id
   * @param fileId The file id
   * @returns void
   */
  public downloadFile(
    supervisionId: number,
    supervisionEventDateId: number,
    fileId: number
  ): void {
    this.fileService.downloadFile(
      `/api/institutes/${this.instituteId}/supervisions/${supervisionId}/event-dates/${supervisionEventDateId}/files/${fileId}`
    );
  }

  /**
   * get the supervision event dates from the backend
   * @returns Observable<HttpResponse<any>>
   */
  public getSupervisionEventDates(): Observable<HttpResponse<any>> {
    return this.http.get(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/supervisions/event-dates`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * get a supervision event date by id
   * @param supervisionId the supervision id
   * @param supervisionEventDateId the supervision event date id
   * @returns Observable<HttpResponse<any>>
   */
  public getSupervisionEventDate(
    supervisionId: number,
    supervisionEventDateId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/supervisions/${supervisionId}/event-dates/${supervisionEventDateId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * updates a supervision event date
   * @param supervisionId the supervision id
   * @param supervisionEventDateId the supervision event date id
   * @param supervisionAppointmentUpdateModel the supervision appointment update model
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async updateSupervisionEventDate(
    supervisionId: number,
    supervisionEventDateId: number,
    supervisionAppointmentUpdateModel: SupervisionEventDateUpdateModel
  ): Promise<Observable<HttpResponse<any>>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    supervisionAppointmentUpdateModel.videoMeetingLink =
      supervisionAppointmentUpdateModel.videoMeetingLink &&
      (await this.cryptoService.encrypt(
        supervisionAppointmentUpdateModel.videoMeetingLink
      ));

    return this.http.put(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/supervisions/${supervisionId}/event-dates/${supervisionEventDateId}`,
      supervisionAppointmentUpdateModel,
      {
        headers: headers,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * updates the status of a supervision event date
   * @param supervisionId supervision id
   * @param supervisionEventDateId supervision event date id
   * @param statusUpdateModel the status update model
   * @returns Observable<HttpResponse<any>
   */
  public updateSupervisionEventDateStatus(
    supervisionId: number,
    supervisionEventDateId: number,
    statusUpdateModel: SupervisionEventDateStatusUpdateModel
  ): Observable<HttpResponse<any>> {
    return this.http.patch(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/supervisions/${supervisionId}/event-dates/${supervisionEventDateId}/status`,
      statusUpdateModel,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * deletes a supervision event date
   * @param supervisionId the supervision id
   * @param supervisionEventDateId the supervision event date id
   * @returns Observable<HttpResponse<any>
   */
  public deleteSupervisionEventDate(
    supervisionId: number,
    supervisionEventDateId: number
  ): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/supervisions/${supervisionId}/event-dates/${supervisionEventDateId}`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * parses the backend supervision appointment
   * @param supervisionAppointment the backend data of the supervision appointment
   * @returns Promise<SupervisionAppointmentModel>
   */
  public async parseBackendSupervisionAppointment(
    supervisionAppointment: SupervisionAppointmentModel
  ): Promise<SupervisionAppointmentModel> {
    const supervisionAppointmentModel: SupervisionAppointmentModel = {
      id: supervisionAppointment.id,
      treatmentCases: supervisionAppointment.treatmentCases
        ? await Promise.all(
            supervisionAppointment.treatmentCases?.map(async treatmentCase => {
              return {
                id: treatmentCase.id,
                patientChiffre: await this.decryptionService.decryptString(
                  treatmentCase.patientChiffre
                ),
                isCompleted: treatmentCase.isCompleted,
              };
            })
          )
        : [],
      type: supervisionAppointment.type,
      durationInTimeUnits: supervisionAppointment.durationInTimeUnits,
      recurrencePattern: supervisionAppointment.recurrencePattern,
      eventDates: supervisionAppointment.eventDates
        ? await Promise.all(
            supervisionAppointment.eventDates.map(async eventDate => {
              const room = eventDate.room
                ? await this.roomService.parseBackendRoom(eventDate.room)
                : null;
              return {
                id: eventDate.id,
                startDate: moment(eventDate.startDate)
                  .tz('Europe/Berlin')
                  .toDate(),
                endDate: moment(eventDate.endDate).tz('Europe/Berlin').toDate(),
                room: room,
              };
            })
          )
        : [],
      location: supervisionAppointment.location,
      videoMeetingLink: await this.decryptionService.decryptString(
        supervisionAppointment.videoMeetingLink
      ),
      supervisors: supervisionAppointment.supervisors
        ? await Promise.all(
            supervisionAppointment.supervisors.map(async supervisor => {
              return await this.userService.parseBackendUser(supervisor);
            })
          )
        : [],
      description: supervisionAppointment.description,
      files: await this.fileService.parseBackendFiles(
        supervisionAppointment.files
      ),
    };

    return supervisionAppointmentModel;
  }

  /**
   * parseBackendSupervisionEventDate
   * parses the backend supervision event date
   * @param supervisionEventDate the backend data of the supervision event date
   * @returns Promise<SupervisionEventDateModel>
   */
  public async parseBackendSupervisionEventDate(
    supervisionEventDate: SupervisionEventDateModel
  ): Promise<SupervisionEventDateModel> {
    return {
      id: supervisionEventDate.id,
      startDate: moment(supervisionEventDate.startDate)
        .tz('Europe/Berlin')
        .toDate(),
      endDate: moment(supervisionEventDate.endDate)
        .tz('Europe/Berlin')
        .toDate(),
      room: supervisionEventDate.room
        ? await this.roomService.parseBackendRoom(supervisionEventDate.room)
        : null,
      location: supervisionEventDate.location,
      videoMeetingLink: supervisionEventDate.videoMeetingLink,
      isCanceled: supervisionEventDate.isCanceled,
      supervisionId: supervisionEventDate.supervisionId,
      treatmentCases: supervisionEventDate.treatmentCases
        ? await Promise.all(
            supervisionEventDate.treatmentCases?.map(async treatmentCase => {
              return {
                id: treatmentCase.id,
                patientChiffre: await this.decryptionService.decryptString(
                  treatmentCase.patientChiffre
                ),
                isCompleted: treatmentCase.isCompleted,
              };
            })
          )
        : [],
      durationInTimeUnits: supervisionEventDate.durationInTimeUnits,
      type: supervisionEventDate.type,
      description: supervisionEventDate.description,
      supervisor: supervisionEventDate.supervisor
        ? await this.userService.parseBackendUser(
            supervisionEventDate.supervisor
          )
        : null,
      student: supervisionEventDate.student
        ? await this.userService.parseBackendUser(supervisionEventDate.student)
        : null,
      isChecked: supervisionEventDate.isChecked,
      files: await this.fileService.parseBackendFiles(
        supervisionEventDate.files
      ),
    };
  }
}
