import { KeyValue, Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { EventLocation } from 'src/app/enums/event-location.enum';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { AccompanyingPerson as AccompaningPersonModel } from 'src/app/models/accompanying-person.model';
import { AppointmentType } from 'src/app/models/appointment-type.model';
import { EventDate } from 'src/app/models/event.model';
import { FileModel } from 'src/app/models/file.model';
import {
  SupervisionEventDateModel,
  SupervisionEventDateUpdateModel,
} from 'src/app/models/supervision.model';
import { TreatmentCaseModel } from 'src/app/models/treatment-case.model';
import {
  AdditionalQualificationEnum,
  UserModel,
} from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FileService } from 'src/app/services/file.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SupervisionService } from 'src/app/services/supervision.service';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import {
  getAllAdditionalQualifications,
  getFullName,
} from 'src/app/utils/user.utils';

@Component({
  selector: 'app-edit-supervision-event-date',
  templateUrl: './edit-supervision-event-date.component.html',
  styleUrl: './edit-supervision-event-date.component.scss',
})
export class EditSupervisionEventDateComponent implements OnInit, OnDestroy {
  public appointmentType: AppointmentType = 'Patiententermin';
  public currentEventDate?: SupervisionEventDateModel;
  public editMode: boolean = false;
  public isLoading: boolean = true;
  public eventDateForm: FormGroup;
  public initialFormValues: any = {};
  public availableTreatmentCases: TreatmentCaseModel[] = [];
  public availableAccompanyingPersons: AccompaningPersonModel[] = [];
  public availableSupervisors: UserModel[] = [];
  public availablePatientAppointmentEventDates: EventDate[] = [];
  public defaultSelectedSupervisors: UserModel[] = [];
  public uploadedFiles: FileModel[] = [];
  public existingFiles: FileModel[] = [];
  public allowedFileTypesDocuments: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'PDF', mimeType: 'application/pdf' },
  ];
  private studentId: number = this.userService.currentUser.id;

  // event picker
  public formSubmitted: boolean = false;
  public startDate: Date;
  public endDate: Date;
  public eventDates: EventDate[] = [];
  private initialEventDates: EventDate[] = [];

  public roomPlanningOpen: boolean = false;
  public roomPlanningValid: boolean = true;
  public roomPlanningDisabled: boolean = false;
  public showLink: boolean = false;

  // import from utils
  public isRequired = isRequired;
  public getFullName = getFullName;
  public getAllAdditionalQualifications = getAllAdditionalQualifications;

  public EventLocation = EventLocation;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.eventDateForm.value,
        this.initialFormValues
      ) ||
      this.formDeactivateService.hasUnsavedChanges(
        this.eventDates,
        this.initialEventDates
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private treatmentCaseService: TreatmentCaseService,
    private supervisionService: SupervisionService,
    private userService: UserService,
    private cancellationService: CancellationService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    private dialog: MatDialog,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private location: Location,
    private loadingService: LoadingService,
    private fileService: FileService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.createForm();

    const params = this.activatedRoute.snapshot.params;

    const supervisionId = +atob(decodeURIComponent(params.supervisionId));
    const supervisionEventDateId = +atob(decodeURIComponent(params.id));

    await this.getSupervisionEventDate(supervisionId, supervisionEventDateId);
    await Promise.all([
      this.initAvailableTreatmentCases(),
      this.initAvailableSupervisors(),
    ]);

    this.initialFormValues = JSON.parse(
      JSON.stringify(this.eventDateForm.value)
    );
    this.isLoading = false;
  }

  /**
   * Create the form
   * @returns void
   */
  private createForm(): void {
    this.eventDateForm = this.formBuilder.group({
      supervisionsType: new FormControl('', Validators.required),
      supervisionAppointmentTreatmentCaseIds: new FormControl(
        [],
        Validators.required
      ),
      durationInTimeUnits: new FormControl(1, Validators.required),
      dateGroup: new FormControl(null),
      location: new FormControl(EventLocation.ONSITE),
      videoMeetingLink: new FormControl(null),
      supervisorId: new FormControl(null, Validators.required),
      supervisorSearch: new FormControl(null),
      description: new FormControl(null, Validators.maxLength(6000)),
      documents: new FormControl(null),
      uploadedFiles: new FormControl(null),
      existingFiles: new FormControl(null),
    });

    this.handleLocationChange();

    // subscribe to uploadedFiles changes and convert them to base64
    this.eventDateForm
      .get('uploadedFiles')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (!value || value.length === 0) {
          this.eventDateForm.get('documents').setValue(null);
          return;
        }
        this.fileService.handleFileUpload(
          value,
          this.eventDateForm.get('documents'),
          false,
          true
        );
      });

    this.initialFormValues = this.eventDateForm.value;
  }

  /**
   * Handles the change of the location form control.
   * If the location is set to online, the room planning is disabled and the video meeting link is required.
   * If the location is set to onsite, the video meeting link is not required and is not shown.
   * If the location is set to hybrid, the video meeting link is required and the room planning is enabled.
   * @returns void
   */
  private handleLocationChange(): void {
    this.eventDateForm.controls['location'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(location => {
        this.roomPlanningDisabled = location === EventLocation.ONLINE;
        this.showLink = location !== EventLocation.ONSITE;

        if (location === EventLocation.ONSITE) {
          this.eventDateForm.get('videoMeetingLink').clearValidators();
        } else {
          this.eventDateForm
            .get('videoMeetingLink')
            .setValidators([Validators.required]);
        }

        this.eventDateForm.get('videoMeetingLink').updateValueAndValidity();
      });
  }

  /**
   * Init available treatment cases and supervisors for the select fields
   * @returns void
   */
  private async initAvailableTreatmentCases(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.treatmentCaseService
        .getAllTreatmentCasesByInstituteIdAndStudentId(this.studentId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            this.availableTreatmentCases = response.body
              ? await Promise.all(
                  response.body.map(async (treatmentCaseData: any) => {
                    return this.treatmentCaseService.parseBackendTreatmentCase(
                      treatmentCaseData
                    );
                  })
                )
              : [];
            resolve();
          },
          error: error => {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Die Behandlungsfälle konnten nicht geladen werden!'
            );
            this.isLoading = false;
            reject(error);
          },
        });
    });
  }

  /**
   * init the available supervisors for the select field
   * @returns void
   */
  private async initAvailableSupervisors(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.userService
        .getInstituteUsersByAdditionalQualifications([
          AdditionalQualificationEnum.SUPERVISOR,
        ])
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            this.availableSupervisors = response.body
              ? await Promise.all(
                  response.body.map(
                    async (userData: any) =>
                      await this.userService.parseBackendUser(userData)
                  )
                )
              : [];
            resolve();
          },
          error: error => {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Die Supervisoren konnten nicht geladen werden!'
            );
            reject(error);
          },
        });
    });
  }

  /**
   * initalizes the supervision appointment to edit
   * @param appointmentId the appointment id
   * @returns Promise<void>
   */
  private async getSupervisionEventDate(
    supervisionId: number,
    supervisionEventDateId: number
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.supervisionService
        .getSupervisionEventDate(supervisionId, supervisionEventDateId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            this.currentEventDate =
              await this.supervisionService.parseBackendSupervisionEventDate(
                response.body
              );

            this.eventDateForm.patchValue({
              supervisionsType: this.currentEventDate.type,
              supervisionAppointmentTreatmentCaseIds:
                this.currentEventDate.treatmentCases.map(
                  (treatmentCase: TreatmentCaseModel) => treatmentCase.id
                ),
              durationInTimeUnits: this.currentEventDate.durationInTimeUnits,
              location: this.currentEventDate.location,
              videoMeetingLink: this.currentEventDate.videoMeetingLink,
              supervisorId: this.currentEventDate.supervisor?.id,
              description: this.currentEventDate.description,
            });

            this.startDate = this.currentEventDate.startDate;
            this.endDate = this.currentEventDate.endDate;
            this.eventDates = [
              {
                startDate: this.currentEventDate.startDate,
                endDate: this.currentEventDate.endDate,
                room: this.currentEventDate.room,
              },
            ];

            this.initialEventDates = JSON.parse(
              JSON.stringify(this.eventDates)
            );

            this.studentId =
              this.currentEventDate.student?.id || this.studentId;

            this.cdr.detectChanges();
            resolve();
          },
          error: () => {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Die Supervision konnte nicht geladen werden.'
            );
            this.onCancel();
            reject();
          },
        });
    });
  }

  /**
   * event dates changed from event picker
   * @param eventDates
   * @returns void
   */
  public onDatesChanged(dates: { start: Date; end: Date }): void {
    this.startDate = dates.start;
    this.endDate = dates.end;
    this.eventDates = [
      {
        startDate: dates.start,
        endDate: dates.end,
      },
    ];
  }

  /**
   * setEventLocation
   * set the event location in the appointment form
   * @param location event location
   */
  public setEventLocation(location: EventLocation) {
    this.eventDateForm.get('location').setValue(location);
  }

  /**
   * room planning changed
   * @param eventDates
   * @returns void
   */
  public roomPlanningChanged(eventDates: EventDate[]): void {
    if (!eventDates) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Ungespeicherte Änderungen!',
          message:
            'Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren. \
              Möchten Sie die Seite trotzdem verlassen?',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.roomPlanningOpen = !this.roomPlanningOpen;
            return;
          }
        });
    } else {
      this.eventDates = eventDates;
      this.roomPlanningOpen = false;
    }
  }
  /**
   * adds or removes the clicked supervisor to the selected supervisors control
   * @param supervisor
   * @returns void
   */
  public onSupervisorSelected(supervisor: UserModel): void {
    if (this.eventDateForm.get('supervisorId').value === supervisor.id) {
      this.eventDateForm.get('supervisorId').setValue(null);
      return;
    }
    this.eventDateForm.get('supervisorId').setValue(supervisor.id);
  }

  /**
   * On cancel
   * @returns void
   */
  public onCancel(): void {
    this.location.back();
  }

  /**
   * Handles the form submission for creating or updating an appointment.
   * Clears the validators based on the appointment type.
   * If the form is valid and has changes it creates or updates the appointment.
   * @returns void
   */
  public onSubmit(): void {
    this.formSubmitted = true;

    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.eventDateForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.eventDateForm.value,
        this.initialFormValues
      ) &&
      !this.formDeactivateService.hasUnsavedChanges(
        this.eventDates,
        this.initialEventDates
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.loadingService.show();

    this.updateSupervisionEventDate();
  }

  /**
   * update a supervision appointment
   * @returns Promise<void>
   */
  private async updateSupervisionEventDate(): Promise<void> {
    const files: FileModel[] = this.eventDateForm.value.documents
      ? this.existingFiles.concat(this.eventDateForm.value.documents)
      : this.existingFiles;

    const supervisionAppointment: SupervisionEventDateUpdateModel = {
      id: this.currentEventDate.id,
      type: this.eventDateForm.get('supervisionsType').value,
      treatmentCaseIds: this.eventDateForm.get(
        'supervisionAppointmentTreatmentCaseIds'
      ).value,
      durationInTimeUnits: this.eventDateForm.get('durationInTimeUnits').value,
      startDate: this.startDate,
      endDate: this.endDate,
      location: this.eventDateForm.get('location').value,
      videoMeetingLink: this.eventDateForm.get('videoMeetingLink').value,
      supervisorId: this.eventDateForm.get('supervisorId').value,
      description: this.eventDateForm.get('description').value,
      files: files,
      roomId: this.eventDates[0].room?.id || null,
    };

    const updateSupervisionEventDateObservable =
      await this.supervisionService.updateSupervisionEventDate(
        this.currentEventDate.supervisionId,
        this.currentEventDate.id,
        supervisionAppointment
      );

    updateSupervisionEventDateObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Supervisionstermin wurde erfolgreich aktualisiert.'
          );
          this.initialFormValues = this.eventDateForm.value;
          this.initialEventDates = this.eventDates;
          this.onCancel();
          this.loadingService.hide();
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Supervisionstermin konnte nicht aktualisiert werden.'
          );
          this.loadingService.hide();
        },
      });
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }

    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.eventDates,
        this.initialEventDates
      )
    ) {
      return this.formDeactivateService.confirmDeactivation(
        this.eventDates,
        this.initialEventDates
      );
    }

    return this.formDeactivateService.confirmDeactivation(
      this.eventDateForm.value,
      this.initialFormValues
    );
  }

  /**
   * Preserve the original enum order
   */
  public originalEventLocationOrder = (
    a: KeyValue<string, EventLocation>,
    b: KeyValue<string, EventLocation>
  ): number => {
    return 0;
  };

  /**
   * onOpenExistingFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenExistingFile(file: FileModel): void {
    this.supervisionService.openFile(
      this.currentEventDate.supervisionId,
      this.currentEventDate?.id,
      file.id
    );
  }

  /**
   * onDownloadExistingFile
   * download a file
   * @param file
   * @returns void
   */
  public onDownloadExistingFile(file: FileModel): void {
    this.supervisionService.downloadFile(
      this.currentEventDate.supervisionId,
      this.currentEventDate?.id,
      file.id
    );
  }

  /**
   * onDeleteExistingFile
   * @param file
   * @returns void
   */
  public onDeleteExistingFile(file: FileModel): void {
    const index = this.existingFiles.indexOf(file);
    if (index > -1) {
      this.existingFiles.splice(index, 1);
    }
    this.eventDateForm.get('existingFiles').setValue(this.existingFiles);
  }

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
