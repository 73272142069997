import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function chiffreValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const pattern = new RegExp(
      '^^[A-Z](((0[1-9]|[12][0-9]|3[01])(0[1,3,5,7,8]|1[0,2]))|((0[1-9]|[12][0-9]|30)(0[4,6,9]|11))|((0[1-9]|[12][0-9])(02)))\\d{2}$'
    );
    const valid = pattern.test(control.value);

    return valid ? null : { invalidChiffre: true };
  };
}
