import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import { VNode, createElement } from '@fullcalendar/core/preact';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import * as moment from 'moment';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
  RoomCalenderEventDateModel,
  RoomFullCalendarEventModel,
} from 'src/app/models/calendar-event.model';
import { EventDateType } from 'src/app/models/event.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EventService } from 'src/app/services/event.service';
import { RoomService } from 'src/app/services/room.service';
import { UserService } from 'src/app/services/user.service';
import {
  getCalendarEventColor,
  getCalendarEventRoomName,
  getRoomCalendarEventTitle,
} from 'src/app/utils/calendar.utils';

@Component({
  selector: 'app-room-utilization-calendar',
  templateUrl: './room-utilization-calendar.component.html',
  styleUrl: './room-utilization-calendar.component.scss',
})
export class RoomUtilizationCalendarComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  @Input() refreshCalendar: Observable<void>;
  public calendarOptions: CalendarOptions;
  public calendar: Calendar;
  public isTodayInView: boolean = true;

  private destroy$: Subject<void> = new Subject<void>();

  public datePickerControl = new FormControl();

  @ViewChild('fullCalendar') calendarComponent: FullCalendarComponent;

  constructor(
    private roomService: RoomService,
    private userService: UserService,
    private eventService: EventService,
    private cancellationService: CancellationService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.initCalendarOptions();
    this.initResources();

    this.refreshCalendar.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.initializeCalendarEventDatesForView();
    });

    this.handleDatePickerChange();
  }

  ngAfterViewInit() {
    this.calendar = this.calendarComponent.getApi();
    this.checkIsTodayInView();
    this.initializeCalendarEventDatesForView();
  }

  ngAfterViewChecked() {
    this.updateTitle();
  }

  /**
   * Initializes the options for the fullcalendar
   * @returns void
   */
  private initCalendarOptions(): void {
    this.calendarOptions = {
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      plugins: [resourceTimeGridPlugin],
      locale: 'de',
      initialView: 'resourceTimeGridDay',
      allDaySlot: false,
      nowIndicator: true,
      slotMinTime: '05:00:00',
      slotMaxTime: '23:59:59',
      slotDuration: '1:00',
      // remove header toolbar
      headerToolbar: {
        left: '',
        center: '',
        right: '',
      },
      // start 1.5 hours before now
      scrollTime: moment()
        .minute(0)
        .second(0)
        .subtract(1.5, 'h')
        .format('HH:mm:ss'),
      nowIndicatorContent: args => {
        // create the nor indicator line
        if (args.isAxis) {
          return createElement(
            'span',
            { class: 'hasomed-text-small font-white' },
            moment(args.date).format('HH:mm')
          );
        } else {
          return null;
        }
      },
      slotLabelContent: args => {
        // create the slot label (time to the left) div to display in calendar
        const slotLabelText = args.text;
        let slotLabelDiv = createElement(
          'div',
          { class: 'fc-custom-slot-label' },
          slotLabelText !== '05:00' ? slotLabelText : ''
        );

        return slotLabelDiv;
      },
      eventContent: args => {
        // create the event div to display in calendar
        const event = args.event;
        const time = args.timeText;
        const eventWrapperDiv = this.createEventDiv(event, time);
        return eventWrapperDiv;
      },
      eventDidMount: info => {
        const title = info.event.title;
        const room = info.event.extendedProps['room']
          ? info.event.extendedProps['room'].name
          : 'Kein Raum';
        const date =
          moment(info.event.start).format('DD.MM.YYYY') +
          ', ' +
          moment(info.event.start).format('HH:mm') +
          ' - ' +
          moment(info.event.end).format('HH:mm') +
          ' Uhr';
        const tooltipText = title + ' - ' + room + ' - ' + date;

        info.el.title = tooltipText;
      },
      height: 'auto',
    };
  }

  /**
   * initResources
   * Fetches all rooms from the backend and initializes the resources of the calendar
   * @returns void
   */
  private initResources(): void {
    this.roomService
      .getAllRooms()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          const resources = response.body
            ? await Promise.all(
                response.body.map(async (roomData: any): Promise<any> => {
                  const room =
                    await this.roomService.parseBackendRoom(roomData);
                  return {
                    id: room.id,
                    title: room.name,
                  };
                })
              )
            : [];
          this.calendar.setOption('resources', resources);
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Raumliste konnte nicht geladen werden.'
          );
        },
      });
  }

  /**
   * Initalizes the even dates for the current view
   * @returns voidq
   */
  private initializeCalendarEventDatesForView(): void {
    const startDate = this.calendar.view.activeStart;
    const endDate = this.calendar.view.activeEnd;

    this.eventService
      .getAllRoomCalendarEventDatesByInsitute(startDate, endDate)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          const calendarEventDates = response.body
            ? await Promise.all(
                response.body.map(
                  async (eventDate: RoomCalenderEventDateModel) => {
                    return this.eventService.parseRoomCalendarEventDate(
                      eventDate
                    );
                  }
                )
              )
            : [];

          const calendarEvents: RoomFullCalendarEventModel[] =
            calendarEventDates.map(
              (
                roomCalendarEventDate: RoomCalenderEventDateModel
              ): RoomFullCalendarEventModel => {
                const title = getRoomCalendarEventTitle(roomCalendarEventDate);
                const color = getCalendarEventColor(roomCalendarEventDate);

                return {
                  title: title,
                  resourceId: roomCalendarEventDate.room
                    ? roomCalendarEventDate.room.id.toString()
                    : '',
                  start: roomCalendarEventDate.startDate,
                  end: roomCalendarEventDate.endDate,
                  backgroundColor: color.backgroundColor,
                  borderColor: color.borderColor,
                  textColor: color.textColor,
                  extendedProps: {
                    roomCalendarEventDate: roomCalendarEventDate,
                  },
                };
              }
            );

          // update calendar
          this.calendar.setOption('events', calendarEvents);
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Veranstaltungen konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * createEventDiv
   * creates the event div
   * @param event
   * @param time
   * @returns VNode<any>
   */
  private createEventDiv(event: EventImpl, time: string): VNode<any> {
    const roomCalendarEventDate: RoomCalenderEventDateModel =
      event.extendedProps['roomCalendarEventDate'];

    let title = event.title;

    if (roomCalendarEventDate.isCanceled) {
      title = 'Abgesagt -' + title;
    }

    // get view
    const view = this.calendar.view.type;
    if (view === 'dayGridMonth') {
      return createElement(
        'div',
        {
          class: 'fc-custom-event-month',
          id: 'event',
          style:
            'background-color: ' +
            event.backgroundColor +
            ';' +
            'border-color: ' +
            event.borderColor +
            ';' +
            'color: ' +
            event.textColor +
            ';',
        },
        [title]
      );
    }

    let titleDiv = createElement(
      'div',
      { class: 'fc-custom-event-title' },
      title
    );

    let additionalInfoDiv = null;
    if (
      roomCalendarEventDate.eventDateType === EventDateType.PATIENT_SESSION &&
      roomCalendarEventDate.treatmentCaseStudent
    ) {
      additionalInfoDiv = createElement(
        'div',
        { class: 'fc-custom-event-title' },
        roomCalendarEventDate.treatmentCaseStudent.name.lastName
      );
    }

    const roomDiv = createElement(
      'div',
      { class: 'fc-custom-event-room' },
      'Raum: ' + getCalendarEventRoomName(roomCalendarEventDate)
    );

    let titleInfoRoomDiv = createElement(
      'div',
      { class: 'fc-custom-event-title-room' },
      [titleDiv, additionalInfoDiv, roomDiv]
    );

    const timeDiv = createElement('div', { class: 'fc-custom-event-time' }, [
      time,
    ]);

    let eventWrapperDiv = createElement(
      'div',
      { class: 'fc-custom-event', id: 'event' },
      [titleInfoRoomDiv, timeDiv]
    );

    return eventWrapperDiv;
  }

  /**
   * checkIsTodayInView
   * Update isTodayInView to true, when todays date is in current view, else false
   * @returns void
   */
  private checkIsTodayInView(): void {
    const calendarApiView = this.calendar.view;
    const viewStart = calendarApiView.activeStart;
    const viewEnd = calendarApiView.activeEnd;
    const today = new Date();

    if (today >= viewStart && today <= viewEnd) {
      this.isTodayInView = true;
    } else {
      this.isTodayInView = false;
    }
  }

  /**
   * onClickNext
   * trigger calenderApi.next() when clicking on next button, update isTodayInView and title
   * @returns void
   */
  public onClickNext(): void {
    this.calendar.next();
    this.checkIsTodayInView();
    this.updateTitle();
    this.initializeCalendarEventDatesForView();
  }

  /**
   * onClickPrev
   * trigger calenderApi.prev() when clicking on prev button, update isTodayInView and title
   * @returns void
   */
  public onClickPrev(): void {
    this.calendar.prev();
    this.checkIsTodayInView();
    this.updateTitle();
    this.initializeCalendarEventDatesForView();
  }

  /**
   * onClickToday
   * trigger calenderApi.today() when clicking on today button,  update isTodayInView and title
   * @returns void
   */
  public onClickToday() {
    this.calendar.today();
    this.isTodayInView = true;
    this.updateTitle();
  }

  /**
   * Handles changes in the date picker control.
   * @returns void
   */
  public handleDatePickerChange(): void {
    this.datePickerControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((date: Date) => {
        const dateStr = moment(date).format('YYYY-MM-DD');
        this.calendar.gotoDate(dateStr);
        this.updateTitle();
        this.checkIsTodayInView();
        this.initializeCalendarEventDatesForView();
      });
  }

  /**
   * updateTitle
   * updates the title (date-range) of current view
   * @returns void
   */
  private updateTitle(): void {
    const calendarApiView = this.calendar.view;
    const titleSpan = document.getElementById('fcCustomTitle');
    titleSpan.innerHTML = calendarApiView.title;
  }

  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
