import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(
    private alertService: AlertService,
    private clipboard: Clipboard
  ) {}

  /**
   * copyToClipboard
   * @text Copy the text to the clipboard
   * @returns void
   */
  public copyToClipboard(type: string, text: string): void {
    const success = this.clipboard.copy(text);

    if (success) {
      this.alertService.showSuccessAlert(
        `${type} kopiert!`,
        `${type} '${text}' erfolgreich kopiert.`
      );
    } else {
      this.alertService.showErrorAlert(
        'Das hat leider nicht geklappt!',
        'Der Text konnte nicht kopiert werden.'
      );
    }
  }
}
