<div *ngIf="isLoading" class="loading-wrapper">
  <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
</div>

<div
  class="education-progress-card"
  *ngIf="!isLoading && studentEducationalProgress && totalProgress">
  <div class="education-progress-container">
    <div
      class="education-progress"
      *ngFor="let studentEducationProgress of studentEducationalProgress">
      <div class="progress-header">
        <span class="hasomed-text-small">
          {{ getTitle(studentEducationProgress.type) }}
        </span>
        <button
          *ngIf="this.userService.currentUser.roleId === role.STUDENT"
          type="button"
          class="table-button-icon"
          [matTooltip]="getTooltip(studentEducationProgress.type)"
          (click)="goToPage(studentEducationProgress.type)">
          <i class="fa-light fa-arrow-up-right-from-square"></i>
        </button>
      </div>
      <div class="progress-content">
        <div class="progress-bar-container">
          <div
            class="progress-bar"
            [ngStyle]="{
              width: studentEducationProgress.progressInPercent + '%',
            }"></div>
        </div>
        <span class="progress-label">
          {{
            studentEducationProgress.progressInHours +
              ' / ' +
              studentEducationProgress.contingentInHours +
              'h'
          }}
        </span>
      </div>
    </div>
  </div>

  <mat-divider class="divider"></mat-divider>

  <!-- TOTAL PROGRESS -->
  <div class="education-progress-total">
    <div class="progress-header">
      <span class="hasomed-text-small">
        {{ getTitle(totalProgress.type) }}
      </span>
    </div>
    <div class="progress-content">
      <div class="progress-bar-container">
        <div
          class="progress-bar"
          [ngStyle]="{
            width: totalProgress.progressInPercent + '%',
          }"></div>
      </div>
      <span class="progress-label">
        {{
          totalProgress.progressInHours +
            ' / ' +
            totalProgress.contingentInHours +
            'h'
        }}
      </span>
    </div>
  </div>
</div>

<div *ngIf="!isLoading && !studentEducationalProgress">
  Dieser Nutzer hat keinen Aus- oder Weiterbildungsgang! Somit kann der
  Ausbildungsfortschritt nicht angezeigt werden.
</div>
