<div class="component-content">
  <div class="header">
    <div class="header-title" *ngIf="!isMobile">
      <h1 class="hasomed-text-h1">Übersicht Ausbildungsbegleitungen</h1>
      <h3 class="hasomed-text-h3">
        Hier finden Sie das Lehrpersonal mit Zusatzqualifikation.
      </h3>
    </div>
    <form [formGroup]="searchForm" class="header-buttons">
      <!-- Search -->
      <div class="hasomed-search" *ngIf="showSearchBar">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          #input
          (keyup)="applySearch($event)"
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>
      <div class="filter-buttons">
        <button
          *ngIf="isMobile || isTablet"
          id="openSearchButton"
          [class.hasomed-button-active]="showSearchBar"
          [class.hasomed-button-primary]="
            searchForm.get('searchText').value !== ''
          "
          (click)="showSearchBar = !showSearchBar"
          class="hasomed-text-button hasomed-button-white-grey">
          <i class="fa-light fa-search hasomed-button-icon"></i>
        </button>
        <!-- Filter Button -->
        <button
          id="filterButton"
          [ngClass]="{
            'hasomed-button-primary': hasActiveFilterValue(
              lecturerFilter,
              true
            ),
            'hasomed-button-white-grey': !hasActiveFilterValue(
              lecturerFilter,
              true
            ),
            'hasomed-button-active': filterOpened,
          }"
          [matMenuTriggerFor]="filterMenu"
          (onMenuOpen)="filterOpened = true"
          (onMenuClose)="filterOpened = false"
          type="button"
          class="hasomed-text-button hasomed-button-white-grey">
          <i class="fa-light fa-filter-list hasomed-button-icon"></i>
          <span class="hasomed-button-text">Filter</span>
        </button>

        <!-- Filter Menu -->
        <mat-menu #filterMenu="matMenu" class="filter-overlay">
          <app-filter
            *ngIf="filterOpened"
            [filters]="lecturerFilter"
            [eLogUserFilter]="true"
            (filterChanged)="lecturerFilterChanged($event)">
          </app-filter>
        </mat-menu>
      </div>
    </form>
  </div>
  <div class="main-content">
    <!-- Filter Tags -->
    <app-filter-tags
      [filters]="lecturerFilter"
      [eLogUserFilter]="true"
      (filterChanged)="lecturerFilterChanged($event)">
    </app-filter-tags>
    <div
      [class.table-bicolor]="dataSource?.filteredData?.length > 1"
      class="table">
      <table [dataSource]="dataSource" mat-table matSort>
        <!-- Lecturer column -->
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Lehrpersonal
            <app-custom-table-sort-icon
              [sort]="sort"
              columnName="name"></app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let lecturer; dataSource: dataSource" mat-cell>
            <div class="user-container cursor-pointer">
              <div
                (click)="openProfilePictureDialog(lecturer)"
                class="user-icon"
                matTooltip="Profilbild ansehen">
                <img
                  src="{{
                    lecturer.profilePicture ??
                      '../../../../../assets/img/user.jpg'
                  }}"
                  alt
                  class="user-icon-image" />
              </div>
              <div
                (click)="onShowDetails(lecturer.id)"
                class="user-info"
                matTooltip="Benutzer anzeigen">
                <span class="user-name hasomed-text-body">
                  {{ getFullName(lecturer) }}
                </span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- additionalQualifications column -->
        <ng-container matColumnDef="additionalQualifications">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Zusatzqualifikationen
            <app-custom-table-sort-icon
              [sort]="sort"
              columnName="additionalQualifications">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let lecturer; dataSource: dataSource" mat-cell>
            <span *ngIf="lecturer.additionalQualifications?.length == 1">
              {{ lecturer.additionalQualifications[0].name }}
            </span>
            <span
              *ngIf="lecturer.additionalQualifications?.length > 1"
              [matTooltip]="getAllAdditionalQualifications(lecturer)">
              Mehrere
            </span>
            <span
              *ngIf="
                !lecturer.additionalQualifications ||
                lecturer.additionalQualifications?.length == 0
              "
              >-</span
            >
          </td>
        </ng-container>

        <!-- email column -->
        <ng-container matColumnDef="email">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            E-Mail Adresse
            <app-custom-table-sort-icon [sort]="sort" columnName="email">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let lecturer; dataSource: dataSource" mat-cell>
            {{ lecturer.email }}
          </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="center-header-cell" mat-header-cell>
            {{ isMobile ? 'Akt.' : 'Aktionen' }}
          </th>
          <td *matCellDef="let lecturer; dataSource: dataSource" mat-cell>
            <div class="table-button-icons">
              <button
                (click)="onShowDetails(lecturer.id)"
                type="button"
                class="table-button-icon"
                matTooltip="Benutzer anzeigen">
                <i
                  class="hasomed-button-icon fa-light fa-arrow-up-right-from-square"></i>
              </button>
            </div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="displayedColumns.length">
              <div *ngIf="isLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!isLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Kein Lehrpersonal gefunden.
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <mat-divider></mat-divider>
      <mat-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="isMobile ? 0 : 1"
        custom-paginator
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
