import { Component } from '@angular/core';

@Component({
  selector: 'app-no-sensitive-data-alert',
  templateUrl: './no-sensitive-data-alert.component.html',
  styleUrls: ['./no-sensitive-data-alert.component.scss']
})
export class NoSensitiveDataAlertComponent {

}
