<div class="flex-c date-form" [formGroup]="dateForm">
  <div class="flex-c date-wrapper">
    <div class="flex-r field-row">
      <div class="field-label">{{ dateSeries ? 'Zeitraum:' : 'Datum:' }}</div>
      <div class="flex-r gap-16 align-center flex-wrap">
        <ng-container *ngIf="!dateSeries">
          <mat-form-field
            class="hasomed-date-field"
            [class.hasomed-date-field-empty]="!dateForm?.get('date').value">
            <input
              class="hasomed-date-input"
              maxlength="10"
              [min]="minDate"
              (dateChange)="dateChanged($event)"
              matInput
              placeholder="Wählen Sie ein Datum"
              [matDatepicker]="picker"
              formControlName="date" />
            <mat-datepicker-toggle
              class="hasomed-date-toggle"
              matIconPrefix
              [for]="picker">
              <mat-icon
                matDatepickerToggleIcon
                class="calendar-toggle-icon fa-light fa-calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="dateSeries">
          <div
            class="hasomed-date-range"
            [class.error-border]="
              dateForm?.get('dateRange').invalid &&
              dateForm?.get('dateRange').touched
            ">
            <mat-date-range-input
              class="hasomed-date-range-input"
              formGroupName="dateRange"
              [min]="minDate"
              [rangePicker]="picker">
              <input
                matStartDate
                id="startDate"
                name="startDate"
                [min]="minDate"
                size="8"
                placeholder="Startdatum"
                formControlName="start" />
              <input
                matEndDate
                id="endDate"
                name="endDate"
                [min]="minDate"
                size="8"
                placeholder="Enddatum"
                formControlName="end" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              class="hasomed-date-range-toggle"
              matIconSuffix
              [for]="picker">
              <mat-icon
                matDatepickerToggleIcon
                class="calendar-toggle-icon fa-light fa-calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker #picker (closed)="onDateRangePickerClosed()">
            </mat-date-range-picker>
          </div>
        </ng-container>
        <mat-checkbox
          id="dateSeriesCheckbox"
          class="input-checkbox checkbox"
          type="checkbox"
          color="primary"
          (change)="dateSeries = !dateSeries"
          [disableRipple]="true"
          formControlName="dateSeries">
          <span
            class="flex-c hasomed-text-body font-greydark m-z input-checkbox-label checkbox-label">
            Terminreihe
          </span>
        </mat-checkbox>
      </div>
    </div>
    <div class="flex-r field-row">
      <div class="field-label">Dauer:</div>
      <div class="flex-r time-picker flex-wrap">
        <input
          type="time"
          class="hasomed-time-input"
          (change)="startTimeChanged($event)"
          placeholder="hh:mm"
          formControlName="startTime" />
        <input
          type="time"
          class="hasomed-time-input"
          (change)="endTimeChanged($event)"
          formControlName="endTime" />
        <mat-checkbox
          id="allDayCheckbox"
          class="input-checkbox checkbox"
          type="checkbox"
          color="primary"
          [disableRipple]="true"
          (change)="onAllDayCheckboxClicked($event)"
          formControlName="allDayCheckbox">
          <span
            class="flex-c hasomed-text-body font-greydark m-z input-checkbox-label checkbox-label">
            Ganztägiges Event
          </span>
        </mat-checkbox>
      </div>
    </div>
    <ng-container *ngIf="dateSeries">
      <div class="flex-r field-row">
        <div class="field-label">Turnus:</div>
        <div class="formular-row-input">
          <button
            class="hasomed-text-button button-full-width"
            type="button"
            [disabled]="sameDay || disabledDayRecurrence"
            [matTooltip]="getTooltipForDailyRecurrence()"
            (click)="onRecurringFrequencyChanged(recurrenceFrequency.DAILY)"
            [ngClass]="{
              'hasomed-button-primary':
                recurrencePattern?.frequency === recurrenceFrequency.DAILY,
              'hasomed-button-white-grey':
                recurrencePattern?.frequency !== recurrenceFrequency.DAILY,
            }">
            <span class="hasomed-button-text">Täglich</span>
          </button>
          <button
            class="hasomed-text-button"
            type="button"
            [disabled]="sameWeek || disabledWeekRecurrence"
            [matTooltip]="getTooltipForWeeklyRecurrence()"
            (click)="onRecurringFrequencyChanged(recurrenceFrequency.WEEKLY)"
            [ngClass]="{
              'hasomed-button-primary':
                recurrencePattern?.frequency === recurrenceFrequency.WEEKLY,
              'hasomed-button-white-grey':
                recurrencePattern?.frequency !== recurrenceFrequency.WEEKLY,
            }">
            <span class="hasomed-button-text">Wöchentlich</span>
          </button>
          <button
            class="hasomed-text-button"
            type="button"
            [disabled]="sameMonth"
            [matTooltip]="getTooltipForMonthlyRecurrence()"
            (click)="onRecurringFrequencyChanged(recurrenceFrequency.MONTHLY)"
            [ngClass]="{
              'hasomed-button-primary':
                recurrencePattern?.frequency === recurrenceFrequency.MONTHLY,
              'hasomed-button-white-grey':
                recurrencePattern?.frequency !== recurrenceFrequency.MONTHLY,
            }">
            <span class="hasomed-button-text">Monatlich</span>
          </button>
        </div>
      </div>
      <div
        id="recurringInterval"
        class="flex-r field-row"
        *ngIf="recurrencePattern?.frequency && !sameDay">
        <div class="field-label"></div>
        <span
          *ngIf="recurrencePattern?.frequency !== recurrenceFrequency.WEEKLY">
          Wiederhole jeden
        </span>
        <span
          *ngIf="recurrencePattern?.frequency === recurrenceFrequency.WEEKLY">
          Wiederhole jede
        </span>
        <div class="flex-r">
          <input
            type="number"
            min="1"
            max="30"
            (change)="onRecurringIntervalChanged($event)"
            class="hasomed-text-input input-number font-greydark"
            formControlName="recurringInterval" />
        </div>
        <span>
          {{
            recurrencePattern?.frequency === recurrenceFrequency.DAILY
              ? 'Tag'
              : ''
          }}
          {{
            recurrencePattern?.frequency === recurrenceFrequency.WEEKLY
              ? 'Woche'
              : ''
          }}
          {{
            recurrencePattern?.frequency === recurrenceFrequency.MONTHLY
              ? 'Monat'
              : ''
          }}
          ab Startdatum
        </span>
      </div>
      <div
        class="flex-r field-row"
        *ngIf="
          recurrencePattern?.frequency === recurrenceFrequency.MONTHLY &&
          !sameMonth
        ">
        <div class="field-label"></div>
        <span> Jeweils am </span>

        <eleguide-select
          [control]="occurranceDayIntervalInMonthControl"
          controlId="ocurranceDayIntervalInMonth"
          [options]="occurranceDayIntervalInMonthOptions"
          (selectionChanged)="onOcurranceDayInMonthChanged($event)"
          class="day-select">
        </eleguide-select>
        <eleguide-select
          [control]="occurranceWeekDayInMonthControl"
          controlId="ocurranceDayInMonth"
          [options]="occurranceWeekDayInMonthOptions"
          (selectionChanged)="onOcurranceWeekdayInMonthChanged($event)"
          class="weekday-select">
        </eleguide-select>
      </div>

      <div
        class="flex-r field-row"
        *ngIf="
          recurrencePattern?.frequency === recurrenceFrequency.WEEKLY &&
          !sameWeek
        ">
        <div class="field-label"></div>
        <div class="days-of-week">
          <span class="days-of-week-label">Immer</span>
          <mat-checkbox
            id="mondayCheckbox"
            class="input-checkbox checkbox"
            type="checkbox"
            color="primary"
            (change)="onRecurrenceDayCheckboxChanged('MO', $event)"
            [disableRipple]="true"
            formControlName="mondayCheckbox">
            <span
              class="flex-c hasomed-text-body font-greydark m-z input-checkbox-label checkbox-label">
              Mo
            </span>
          </mat-checkbox>
          <mat-checkbox
            id="tuesdayCheckbox"
            class="input-checkbox checkbox"
            type="checkbox"
            color="primary"
            (change)="onRecurrenceDayCheckboxChanged('TU', $event)"
            [disableRipple]="true"
            formControlName="tuesdayCheckbox">
            <span
              class="flex-c hasomed-text-body font-greydark m-z input-checkbox-label checkbox-label">
              Di
            </span>
          </mat-checkbox>
          <mat-checkbox
            id="wednesdayCheckbox"
            class="input-checkbox checkbox"
            type="checkbox"
            color="primary"
            (change)="onRecurrenceDayCheckboxChanged('WE', $event)"
            [disableRipple]="true"
            formControlName="wednesdayCheckbox">
            <span
              class="flex-c hasomed-text-body font-greydark m-z input-checkbox-label checkbox-label">
              Mi
            </span>
          </mat-checkbox>
          <mat-checkbox
            id="thursdayCheckbox"
            class="input-checkbox checkbox"
            type="checkbox"
            color="primary"
            (change)="onRecurrenceDayCheckboxChanged('TH', $event)"
            [disableRipple]="true"
            formControlName="thursdayCheckbox">
            <span
              class="flex-c hasomed-text-body font-greydark m-z input-checkbox-label checkbox-label">
              Do
            </span>
          </mat-checkbox>
          <mat-checkbox
            id="fridayCheckbox"
            class="input-checkbox checkbox"
            type="checkbox"
            color="primary"
            (change)="onRecurrenceDayCheckboxChanged('FR', $event)"
            [disableRipple]="true"
            formControlName="fridayCheckbox">
            <span
              class="flex-c hasomed-text-body font-greydark m-z input-checkbox-label checkbox-label">
              Fr
            </span>
          </mat-checkbox>
          <mat-checkbox
            id="saturdayCheckbox"
            class="input-checkbox checkbox"
            type="checkbox"
            color="primary"
            (change)="onRecurrenceDayCheckboxChanged('SA', $event)"
            [disableRipple]="true"
            formControlName="saturdayCheckbox">
            <span
              class="flex-c hasomed-text-body font-greydark m-z input-checkbox-label checkbox-label">
              Sa
            </span>
          </mat-checkbox>
          <mat-checkbox
            id="sundayCheckbox"
            class="input-checkbox checkbox"
            type="checkbox"
            color="primary"
            (change)="onRecurrenceDayCheckboxChanged('SU', $event)"
            [disableRipple]="true"
            formControlName="sundayCheckbox">
            <span
              class="flex-c hasomed-text-body font-greydark m-z input-checkbox-label checkbox-label">
              So
            </span>
          </mat-checkbox>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showEvents">
      <div class="field-label">Termine:</div>
      <ng-container formArrayName="eventList">
        <div #eventsContainer class="flex-c course-event-wrapper">
          <ng-container
            *ngFor="let eventForm of eventList?.controls; let i = index">
            <div
              class="flex-r field-row"
              [class.invalid-date]="eventForm.hasError('duplicate')"
              id="event{{ i }}"
              [formGroupName]="i">
              <mat-form-field
                class="hasomed-date-field hasomed-date-field-center">
                <input
                  class="hasomed-date-input"
                  [min]="minDate"
                  maxlength="10"
                  size="9"
                  (dateChange)="eventDateChanged($event)"
                  matInput
                  placeholder="Datum wählen"
                  [matDatepicker]="picker"
                  formControlName="eventDate" />
                <mat-datepicker-toggle matIconPrefix [for]="picker">
                  <div matDatepickerToggleIcon class="hasomed-button-icon-sm">
                    <i class="fa-light fa-calendar"></i>
                  </div>
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <div class="event-time-pickers">
                <input
                  type="time"
                  class="hasomed-time-input"
                  (change)="eventStartTimeChanged($event, i)"
                  formControlName="eventStartTime" />
                <input
                  type="time"
                  class="hasomed-time-input"
                  (change)="eventEndTimeChanged($event, i)"
                  formControlName="eventEndTime" />
                <div
                  type="button"
                  (click)="removeEvent(i)"
                  class="hasomed-text-button hasomed-button-white-grey remove-button">
                  <i class="hasomed-button-icon-sm fa-light fa-trash-can"></i>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <button
        id="room"
        type="button"
        (click)="addEvent()"
        class="hasomed-text-button hasomed-button-white-grey button-flex-start invalid">
        <i class="fa-light fa-square-plus hasomed-button-icon"></i>
        <span class="hasomed-button-text">Neuen Termin hinzufügen</span>
      </button>
    </ng-container>
  </div>
</div>
