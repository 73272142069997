import { Component, Input, OnInit } from '@angular/core';
import { eLogStatus, eLogStatusCounts } from 'src/app/models/elog.model';

@Component({
  selector: 'app-elog-cell',
  templateUrl: './elog-cell.component.html',
  styleUrls: ['./elog-cell.component.scss'],
})
export class ElogCellComponent implements OnInit {
  eLog = eLogStatus;
  @Input() eLogStatusCounts: eLogStatusCounts;
  @Input() eLogStatus: eLogStatus;
  @Input() shortText: boolean = true;

  public ngOnInit(): void {}
}
