import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    // Report the error to Sentry
    Sentry.captureException(error);

    // Optional: Re-throw the error if necessary
    throw error;
  }
}
