import { Role } from '../models/permission.model';
import { SelectOption } from '../models/select-option.model';

export const availableRolesSelectOptions: SelectOption[] = [
  {
    value: Role.ADMINISTRATOR,
    label: 'Verwaltung',
  },
  {
    value: Role.LECTURER,
    label: 'Lehrpersonal',
  },
  {
    value: Role.STUDENT,
    label: 'Kandidat',
  },
];
