<div class="label-dialog flex-c">
  <!-- HEADER -->
  <div class="header flex-r">
    <span class="hasomed-text-h1">{{
      label ? 'Etikett bearbeiten' : 'Neues Etikett anlegen'
    }}</span>
    <button
      type="button"
      class="close-button"
      matTooltip="Abbrechen"
      (click)="onCancel()">
      <i class="fa-light fa-x close-button-icon"></i>
    </button>
  </div>

  <!-- FORMULAR -->
  <form
    class="label-form flex-c gap-24"
    [formGroup]="labelForm"
    (ngSubmit)="onSubmit()">
    <!-- NAME -->
    <div class="formular-row">
      <label for="name" class="formular-row-label">
        <div class="label-title">
          Bezeichnung <span *ngIf="isRequired(labelForm.get('name'))"> * </span>
        </div>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="name"
            class="hasomed-text-input flex-grow"
            formControlName="name"
            placeholder="Bezeichnung" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="labelForm.get('name')"></app-error-message>
      </div>
    </div>

    <!-- COLOR -->
    <div class="formular-row">
      <label for="color" class="formular-row-label">
        <div class="label-title">Farbe</div>
      </label>
      <div class="color-container">
        <div *ngFor="let color of availableColors">
          <div
            class="color-choice"
            [style.background-color]="color"
            (click)="onColorSelect(color)">
            <div *ngIf="color === selectedColor" class="selected-color-icon">
              <i class="fa-light fa-check"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- BUTTONS -->
    <div class="formular-buttons flex-r">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white"
        (click)="onCancel()">
        <span class="hasomed-button-text"> Abbrechen </span>
      </button>
      <button
        type="submit"
        class="hasomed-text-button hasomed-button-primary"
        [disabled]="!labelForm.valid">
        <i class="hasomed-button-icon fa-light fa-floppy-disk"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </form>
</div>
