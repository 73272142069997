import { FileModel } from '../models/file.model';

/**
 * returns the type of the file based on the mimeType
 * @param file
 * @returns
 */
export function getFileType(file: FileModel): string {
  const mimeType = file.mimeType;
  if (mimeType.includes('image')) {
    return 'Bild';
  } else if (mimeType.includes('video')) {
    return 'Video';
  } else if (mimeType.includes('pdf')) {
    return 'Dokument';
  } else if (mimeType.includes('wordprocessingml')) {
    return 'Dokument';
  } else if (mimeType.includes('spreadsheetml')) {
    return 'Dokument';
  } else if (mimeType.includes('presentationml')) {
    return 'Dokument';
  } else if (mimeType.includes('zip')) {
    return 'Archiv';
  } else {
    return 'Datei';
  }
}

/**
 * returns the file name without the file extension
 * @param file
 * @returns
 */
export function getFileNameOnly(file: FileModel): string {
  // remove file extension
  return file.name.split('.').slice(0, -1).join('.');
}

/**
 * format bytes
 * @param bytes (File size in bytes)
 * @param decimals (Decimals point)
 */
export function formatBytes(bytes, decimals) {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1000;
  const dm = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Convert size with unit to bytes
 * @param sizeStr - size with unit as string
 * @returns size in byte
 */
export function convertToBytes(sizeStr: string): number {
  const sizeArray = sizeStr.split(' ');
  const size = parseInt(sizeArray[0], 10);
  if (sizeArray[1] === 'KB') {
    return size * 1000;
  } else if (sizeArray[1] === 'MB') {
    return size * 1000 * 1000;
  } else if (sizeArray[1] === 'GB') {
    return size * 1000 * 1000 * 1000;
  } else if (sizeArray[1] === 'TB') {
    return size * 1000 * 1000 * 1000 * 1000;
  }
  return size;
}

/**
 * generates a key pair
 * @returns the generated crypto key pair
 */
export async function generateKeyPair(): Promise<CryptoKeyPair> {
  return await window.crypto.subtle.generateKey(
    {
      name: 'RSASSA-PKCS1-v1_5',
      modulusLength: 2048,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: 'SHA-256',
    },
    true,
    ['sign', 'verify']
  );
}

/**
 * sign the file with the private key
 * @param fileBuffer the file array buffer to sign
 * @param privateKey the private key to sign the file
 * @returns the signed data as a base64 string
 */
export async function signData(
  fileBuffer: ArrayBuffer,
  privateKey: CryptoKey
): Promise<string> {
  const signedDataArrayBuffer = await window.crypto.subtle.sign(
    {
      name: 'RSASSA-PKCS1-v1_5',
    },
    privateKey,
    fileBuffer
  );
  return btoa(
    String.fromCharCode.apply(null, new Uint8Array(signedDataArrayBuffer))
  );
}

/**
 * export the public key as a base64 string
 * @param publicKey the public key to export
 * @returns the public key as a base64 string
 */
export async function exportPublicKey(publicKey: CryptoKey): Promise<string> {
  const publicKeyArrayBuffer = await window.crypto.subtle.exportKey(
    'spki',
    publicKey
  );
  return btoa(
    String.fromCharCode.apply(null, new Uint8Array(publicKeyArrayBuffer))
  );
}

/**
 * get the hash of the file
 * @param fileBuffer the file array buffer
 * @returns the hash of the file as a base64 string
 */
export async function getFileHash(fileBuffer: ArrayBuffer): Promise<string> {
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', fileBuffer);
  return btoa(String.fromCharCode.apply(null, new Uint8Array(hashBuffer)));
}

/**
 * convert ArrayBuffer to string
 * @param buffer the buffer to convert to string
 * @returns string
 */
export function arrayBufferToString(buffer: ArrayBuffer): string {
  const uint8Array = new Uint8Array(buffer);
  let result = '';
  const chunkSize = 0x8000; // 32,768 - chunk size to avoid exceeding the argument limit

  for (let i = 0; i < uint8Array.length; i += chunkSize) {
    result += String.fromCharCode.apply(
      null,
      uint8Array.subarray(i, i + chunkSize)
    );
  }

  return result;
}

/**
 * convert a string to ArrayBuffer
 * @param str the string to convert to ArrayBuffer
 * @returns ArrayBuffer
 */
export function stringToArrayBuffer(str: string): ArrayBuffer {
  const buffer = new ArrayBuffer(str.length);
  const uint8Array = new Uint8Array(buffer);

  for (let i = 0; i < str.length; i++) {
    uint8Array[i] = str.charCodeAt(i);
  }

  return buffer;
}
