<div class="error-404-container">
  <div class="error-404-content">
    <div class="icon">
      <i class="fa-light fa-file-xmark"></i>
    </div>

    <div class="text">
      <h1 class="hasomed-text-title">Seite wurde nicht gefunden!</h1>
      <h3 class="hasomed-text-h3">
        Die Seite, die Sie suchen, wurde nicht gefunden. Möglicherweise wurde
        sie verschoben oder gelöscht. Überprüfen Sie die URL und versuchen Sie
        es erneut.
        <br />
        <br />
        Wenn Sie der Meinung sind, dass es sich um einen Fehler handelt, wenden
        Sie sich bitte an den Support.
      </h3>
    </div>
  </div>
</div>
