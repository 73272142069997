import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import {
  EducationalProgressModel,
  EducationalProgressType,
} from 'src/app/models/education-progress.model';
import { Role } from 'src/app/models/permission.model';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EducationProgressService } from 'src/app/services/education-progress.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-education-progress-bar',
  templateUrl: './education-progress-bar.component.html',
  styleUrl: './education-progress-bar.component.scss',
})
export class EducationProgressBarComponent implements OnInit, OnDestroy {
  public studentEducationalProgress?: EducationalProgressModel[];
  public totalProgress?: EducationalProgressModel;
  public isLoading = true;

  public role = Role;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private educationProgressService: EducationProgressService,
    public userService: UserService,
    private router: Router,
    private cancellationService: CancellationService
  ) {}

  public ngOnInit() {
    this.activatedRoute.parent?.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        let userId = +atob(params.get('userId'));

        if (!userId) {
          userId = this.userService.currentUser.id;
        }

        this.getEducationalProgressFromStudent(userId);
      });
  }

  /**
   * Get educational progress from student
   * @param studentId The student id
   * @returns void
   */
  public getEducationalProgressFromStudent(studentId: number): void {
    this.educationProgressService
      .getEducationProgressByStudentId(studentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.body == null) {
            this.studentEducationalProgress = null;
            this.totalProgress = null;
            this.isLoading = false;
            return;
          }

          this.studentEducationalProgress = response.body.filter(
            (progress: EducationalProgressModel) =>
              progress.type !== EducationalProgressType.TOTAL
          );

          this.totalProgress = response.body.find(
            (progress: EducationalProgressModel) =>
              progress.type === EducationalProgressType.TOTAL
          );

          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
        },
      });
  }

  /**
   * Redirects to the given page
   * @param type
   */
  public goToPage(type: EducationalProgressType) {
    switch (type) {
      case EducationalProgressType.THEORETICAL:
        this.router.navigate(['eleguide/education/theoretical-education']);
        break;
      case EducationalProgressType.PRACTICAL_1:
        this.router.navigate(['eleguide/education/practical-work']);
        break;
      case EducationalProgressType.PRACTICAL_2:
        this.router.navigate(['eleguide/education/practical-work']);
        break;
      case EducationalProgressType.TREATMENT:
        this.router.navigate(['eleguide/education/ambulatory-part']);
        break;
      case EducationalProgressType.SUPERVISION:
        this.router.navigate(['eleguide/education/ambulatory-part']);
        break;
      case EducationalProgressType.SELF_AWARENESS:
        this.router.navigate(['eleguide/education/self-awareness']);
        break;
      case EducationalProgressType.TEACHING_ANALYSIS:
        this.router.navigate(['eleguide/education/self-awareness']);
        break;
      default:
        break;
    }
  }

  /**
   * getTooltip
   */
  public getTooltip(progressType: EducationalProgressType) {
    switch (progressType) {
      case EducationalProgressType.THEORETICAL:
        return 'Zur Kursübersicht';
      case EducationalProgressType.PRACTICAL_1:
        return 'Zur Praktischen Tätigkeit';
      case EducationalProgressType.PRACTICAL_2:
        return 'Zur Praktischen Tätigkeit';
      case EducationalProgressType.TREATMENT:
        return 'Zu den Behandlungsfällen';
      case EducationalProgressType.SELF_AWARENESS:
        return 'Zur Selbsterfahrung';
      case EducationalProgressType.TEACHING_ANALYSIS:
        return 'Zur Lehranalyse';
      case EducationalProgressType.SUPERVISION:
        return 'Zur Supervision';
      default:
        return '';
    }
  }

  /**
   * Get the title of the progress div
   */
  public getTitle(progressType: EducationalProgressType): string {
    switch (progressType) {
      case EducationalProgressType.THEORETICAL:
        return 'Theoretisch Wissenschaftlich';
      case EducationalProgressType.PRACTICAL_1:
        return 'Praktische Tätigkeit §2 Abs. Nr 1';
      case EducationalProgressType.PRACTICAL_2:
        return 'Praktische Tätigkeit §2 Abs. Nr 2';
      case EducationalProgressType.TREATMENT:
        return 'Behandlungspraktikum';
      case EducationalProgressType.SELF_AWARENESS:
        return 'Selbsterfahrung';
      case EducationalProgressType.TEACHING_ANALYSIS:
        return 'Lehranalyse';
      case EducationalProgressType.SUPERVISION:
        return 'Supervision';
      case EducationalProgressType.TOTAL:
        return 'Gesamt';
      default:
        return '';
    }
  }

  /**
   * cancel all requests and unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
