import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import {
  RecurrencePattern,
  recurrenceFrequency,
} from 'src/app/models/course.model';
import { EventDate } from 'src/app/models/event.model';
import { RoomModel } from 'src/app/models/room.model';
import {
  SelectOption,
  SelectionChangeEvent,
} from 'src/app/models/select-option.model';
import { AlertService } from 'src/app/services/alert.service';

type EventPickerEventModel = EventDate & {
  startTime?: string;
  endTime?: string;
  manuallyCreated?: boolean;
  dateUpdated?: boolean;
  timeUpdated?: boolean;
  isCanceled?: boolean;
};

@Component({
  selector: 'app-event-picker',
  templateUrl: './event-picker.component.html',
  styleUrls: ['./event-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EventPickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EventPickerComponent),
      multi: true,
    },
  ],
})
export class EventPickerComponent
  implements
    OnInit,
    ControlValueAccessor,
    Validator,
    AfterViewChecked,
    OnChanges,
    OnDestroy
{
  constructor(
    private fb: FormBuilder,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef
  ) {}
  @ViewChild('eventsContainer') private eventsContainer: ElementRef;
  @Input() events: EventPickerEventModel[];
  @Input() duration: number | null;
  @Input() startDate: any;
  @Input() endDate: any;
  @Input() reoccurringIntervalType: string;
  @Input() recurrencePattern: RecurrencePattern;
  @Input() defaultRoom: RoomModel;
  @Input() allDayEvent: boolean;
  @Input() parentFormSubmitValidation: Boolean;

  public showEvents: boolean = false;
  public startTime: any = null;
  public endTime: any = null;
  public dateForm: FormGroup;
  private manuallyAddedDates: Date[] = [];
  private manuallyRemovedDates: Date[] = [];
  private eventsFormArrayLength: number;
  public recurrenceFrequency = recurrenceFrequency;
  private daysOfWeek: string[] = [];
  private manuallyAddedEventIndexes: number[] = [];

  public sameDay: boolean = false;
  public sameWeek: boolean = false;
  public sameMonth: boolean = false;
  public disabledDayRecurrence: boolean = false;
  public disabledWeekRecurrence: boolean = false;
  public minDate: Date = new Date(1960, 0, 1);

  private previousStartTime: any;
  private previousEndTime: any;
  public dateSeries: boolean = false;

  private triggered: number = 0;

  @Output() eventsChanged: EventEmitter<EventPickerEventModel[]> =
    new EventEmitter();
  @Output() datesChanged: EventEmitter<{
    start: Date;
    end: Date;
    allDayEvent: boolean;
  }> = new EventEmitter();
  @Output() reoccurringIntervalChanged: EventEmitter<number> =
    new EventEmitter();
  @Output() reoccurringIntervalTypeChanged: EventEmitter<string> =
    new EventEmitter();
  @Output() recurrencePatternChanged: EventEmitter<RecurrencePattern> =
    new EventEmitter();

  private destroy$: Subject<void> = new Subject<void>();

  public occurranceDayIntervalInMonthOptions: SelectOption[] = [
    { value: 1, label: 'Ersten' },
    { value: 2, label: 'Zweiten' },
    { value: 3, label: 'Dritten' },
    { value: 4, label: 'Vierten' },
    { value: 5, label: 'Letzten' },
  ];

  public occurranceWeekDayInMonthOptions: SelectOption[] = [
    { value: 'MO', label: 'Montag' },
    { value: 'TU', label: 'Dienstag' },
    { value: 'WE', label: 'Mittwoch' },
    { value: 'TH', label: 'Donnerstag' },
    { value: 'FR', label: 'Freitag' },
    { value: 'SA', label: 'Samstag' },
    { value: 'SU', label: 'Sonntag' },
  ];

  public ngOnInit(): void {
    if (this.startDate && this.endDate) {
      this.showEvents = true;
      this.startTime = moment(this.startDate).format('HH:mm');
      this.endTime = moment(this.endDate).format('HH:mm');

      this.evaluateRecurrenceAvailability();
    }
    this.initializeForm();
    this.initializeRecurrencePattern();
    this.initializeEvents();

    // subscribe to changes from the dateSeries checkbox
    this.dateForm
      .get('dateSeries')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (!value) {
          const newDate = moment(this.dateForm.get('date').value).startOf(
            'day'
          );
          const startDate = moment(this.startDate);
          const endDate = moment(this.endDate);

          this.startDate = newDate
            .clone()
            .hour(startDate.hour())
            .minute(startDate.minute());
          this.endDate = newDate
            .clone()
            .hour(endDate.hour())
            .minute(endDate.minute());

          this.removeReoccuringEvents();
          this.setEvents();
          this.publishDates();
          this.updateStartDate(true);
          this.updateEndDate(true);
          this.resetRecurrencePattern();
        }
      });
  }

  /**
   * initializes the form
   * @returns void
   */
  private initializeForm(): void {
    this.dateForm = this.fb.group({
      date: new FormControl(this.startDate, Validators.required),
      dateRange: new FormGroup({
        start: new FormControl(this.startDate, Validators.required),
        end: new FormControl(this.endDate, Validators.required),
      }),
      dateSeries: new FormControl(this.dateSeries),
      startTime: new FormControl(this.startTime, Validators.required),
      endTime: new FormControl(this.endTime, Validators.required),
      allDayCheckbox: new FormControl(this.allDayEvent),
      recurringInterval: new FormControl(
        this.recurrencePattern?.interval ?? null
      ),
      ocurranceDayInMonth: new FormControl(
        this.recurrencePattern?.daysOfWeek ?? null
      ),
      ocurranceDayIntervalInMonth: new FormControl(
        this.recurrencePattern?.occuranceDayInMonth?.toString() ?? null
      ),
      mondayCheckbox: new FormControl(null),
      tuesdayCheckbox: new FormControl(null),
      wednesdayCheckbox: new FormControl(null),
      thursdayCheckbox: new FormControl(null),
      fridayCheckbox: new FormControl(null),
      saturdayCheckbox: new FormControl(null),
      sundayCheckbox: new FormControl(null),
      eventList: this.fb.array([], Validators.required),
    });

    if (this.allDayEvent) {
      this.dateForm.get('startTime').disable();
      this.dateForm.get('endTime').disable();
    }
  }

  /**
   * Getter for the occurranceDayIntervalInMonth form control
   * @returns the occurranceDayIntervalInMonth form control
   */
  get occurranceDayIntervalInMonthControl(): FormControl {
    return this.dateForm.get('ocurranceDayIntervalInMonth') as FormControl;
  }

  /**
   * Getter for the occurranceWeekDayInMonth form control
   * @returns the occurranceWeekDayInMonth form control
   */
  get occurranceWeekDayInMonthControl(): FormControl {
    return this.dateForm.get('ocurranceDayInMonth') as FormControl;
  }

  /**
   * Initializes the recurrence pattern form controls
   * @returns void
   */
  private initializeRecurrencePattern(): void {
    if (!this.recurrencePattern) {
      return;
    }
    this.daysOfWeek = this.recurrencePattern?.daysOfWeek
      ? this.recurrencePattern?.daysOfWeek.split(',').map(day => day.trim())
      : [];

    this.daysOfWeek?.forEach(it => {
      const controlName = this.getDayControlName(it);
      if (controlName) {
        this.dateForm.get(controlName).setValue(1);
      }
    });
  }

  /**
   * gets the control name for the given day
   * @param day the day to get the control name for
   * @returns the control name for the given day
   */
  private getDayControlName(day: string): string {
    switch (day) {
      case 'MO':
        return 'mondayCheckbox';
      case 'TU':
        return 'tuesdayCheckbox';
      case 'WE':
        return 'wednesdayCheckbox';
      case 'TH':
        return 'thursdayCheckbox';
      case 'FR':
        return 'fridayCheckbox';
      case 'SA':
        return 'saturdayCheckbox';
      case 'SU':
        return 'sundayCheckbox';
      default:
        return null;
    }
  }

  /**
   * gets the tooltip for the daily recurrence based on the selected start and end date
   * @returns the tooltip text
   */
  public getTooltipForDailyRecurrence(): string {
    if (this.sameDay) {
      return 'Die Daten liegen am gleichen Tag.';
    }
    if (this.disabledDayRecurrence) {
      return 'Tägliche Wiederholung ist nur bis zu einem Monat möglich.';
    }
    return 'Tägliche Wiederholung';
  }

  /**
   * gets the tooltip for the weekly recurrence based on the selected start and end date
   * @returns the tooltip text
   */
  public getTooltipForWeeklyRecurrence(): string {
    if (this.sameWeek) {
      return 'Die Daten liegen in der gleichen Woche.';
    }
    if (this.disabledWeekRecurrence) {
      return 'Wöchentliche Wiederholung ist nur bis zu einem Jahr möglich.';
    }
    return 'Wöchentliche Wiederholung';
  }

  /**
   * gets the tooltip for the monthly recurrence based on the selected start and end date
   * @returns the tooltip text
   */
  public getTooltipForMonthlyRecurrence(): string {
    if (this.sameMonth) {
      return 'Die Daten liegen im gleichen Monat.';
    }
    return 'Monatliche Wiederholung';
  }

  /**
   * checks which recurrence frequency is available with the selected start and end date
   * @returns void
   */
  private evaluateRecurrenceAvailability(): void {
    this.sameDay = moment(this.startDate).isSame(moment(this.endDate), 'day');
    this.sameWeek = moment(this.startDate).isSame(moment(this.endDate), 'week');
    this.sameMonth = moment(this.startDate).isSame(
      moment(this.endDate),
      'month'
    );
    this.disabledDayRecurrence =
      moment(this.endDate).diff(moment(this.startDate), 'days') > 30;
    this.disabledWeekRecurrence =
      moment(this.endDate).diff(moment(this.startDate), 'weeks') > 52;

    this.resetUnallowedRecurrenceFrequency();

    if (!this.sameDay) {
      this.dateSeries = true;
    }
  }

  /**
   * resets the frequency of the recurrence pattern if the frequency is not valid
   * @returns void
   */
  private resetUnallowedRecurrenceFrequency(): void {
    if (!this.recurrencePattern) {
      return;
    }
    if (
      this.recurrencePattern.frequency === 'daily' &&
      (this.disabledDayRecurrence || this.sameDay)
    ) {
      this.recurrencePattern.frequency = null;
    }
    if (
      this.recurrencePattern.frequency === 'weekly' &&
      (this.disabledWeekRecurrence || this.sameWeek)
    ) {
      this.recurrencePattern.frequency = null;
    }
    if (this.recurrencePattern.frequency === 'monthly' && this.sameMonth) {
      this.recurrencePattern.frequency = null;
    }
  }

  /**
   * initialize the events
   * @returns void
   */
  private initializeEvents(): void {
    if (!this.events || this.events.length === 0) {
      return;
    }
    this.sortEventsByDate();
    // create formControls for all events
    this.events?.forEach((event, index) => {
      if (!this.checkIfEventIsInRecurrencePattern(event)) {
        this.manuallyAddedDates.push(moment(event.startDate).toDate());
      }
      event.startTime = moment(event.startDate).format('HH:mm');
      event.endTime = moment(event.endDate).format('HH:mm');

      // set manually updated to true if the time is different from startTime
      if (
        event.startTime !== this.startTime ||
        event.endTime !== this.endTime
      ) {
        event.timeUpdated = true;
      }
      const eventForm = this.fb.group({
        id: event.id ?? null,
        eventDate: [event.startDate, Validators.required],
        eventStartTime: [
          moment(event.startDate).format('HH:mm'),
          Validators.required,
        ],
        eventEndTime: [
          moment(event.endDate).format('HH:mm'),
          Validators.required,
        ],
      });
      this.eventList.push(eventForm);
    });
    this.updateCustomAttributes();
  }

  /**
   * Check if the event is in the recurrence pattern
   * @param event
   * @returns
   */
  private checkIfEventIsInRecurrencePattern(event: EventPickerEventModel) {
    if (!this.checkIfDurationWasPreviouslyChanged(event) && this.dateSeries) {
      return false;
    }
    if (!this.recurrencePattern || !this.dateSeries) {
      if (this.checkIfEventDateIsSameDate(event)) return true;
      return false;
    }
    let eventStartDate = moment(event.startDate);
    const startDate = moment(this.startDate).clone();
    switch (this.recurrencePattern?.frequency) {
      case 'daily':
        // loop through all days until the end date and check if the event is on the same day and time
        return this.checkIfEventDateIsInDayRecurrencePattern(
          startDate,
          eventStartDate
        );
      case 'weekly':
        return this.checkIfEventDateIsInWeekRecurrencePattern(
          startDate,
          eventStartDate
        );
      case 'monthly':
        return this.checkIfEventDateIsInMonthRecurrencePattern(
          startDate,
          eventStartDate
        );
      default:
        return false;
    }
  }

  /**
   * check if the event date is the same as the selected date
   * applies only when no recurrence pattern is selected and the dateSeries is false
   * @param event the event to check
   * @returns true if the event date is the same as the selected date otherwise false
   */
  private checkIfEventDateIsSameDate(event: EventPickerEventModel) {
    return (
      moment(this.startDate).isSame(moment(event.startDate)) &&
      moment(this.endDate).isSame(moment(event.endDate))
    );
  }

  /**
   * Check if the event date is in the day recurrence pattern
   * @param startDate the selected start date
   * @param eventStartDate the event start date
   * @returns true if the event date is in the day recurrence pattern otherwise false
   */
  private checkIfEventDateIsInDayRecurrencePattern(
    startDate: moment.Moment,
    eventStartDate: moment.Moment
  ): boolean {
    while (startDate.isSameOrBefore(this.endDate, 'day')) {
      if (eventStartDate.isSame(startDate)) {
        return true;
      }
      startDate.add(this.recurrencePattern?.interval, 'd');
    }
    return false;
  }

  /**
   * Check if the event date is in the week recurrence pattern
   * @param startDate the selected start date
   * @param eventStartDate the event start date
   * @returns true if the event date is in the day recurrence pattern otherwise false
   */
  private checkIfEventDateIsInWeekRecurrencePattern(
    startDate: moment.Moment,
    eventStartDate: moment.Moment
  ): boolean {
    if (this.recurrencePattern?.daysOfWeek) {
      if (!startDate.isSameOrBefore(this.endDate, 'day')) {
        return false;
      }
      for (const it of this.daysOfWeek) {
        const weekStartDate = moment(startDate).clone();
        while (weekStartDate.isSameOrBefore(this.endDate, 'day')) {
          weekStartDate.day(this.convertWeekDayToNum(it));

          if (eventStartDate.isSame(weekStartDate)) {
            weekStartDate.add(this.recurrencePattern?.interval, 'w');
            return true;
          }
          weekStartDate.add(this.recurrencePattern?.interval, 'w');
        }
      }
      return false;
    } else {
      while (startDate.isSameOrBefore(this.endDate, 'day')) {
        if (eventStartDate.isSame(startDate)) {
          return true;
        }
        startDate.add(this.recurrencePattern?.interval, 'w');
      }
      return false;
    }
  }

  /**
   * Check if the event date is in the month recurrence pattern
   * @param startDate the selected start date
   * @param eventStartDate the event start date
   * @returns true if the event date is in the day recurrence pattern otherwise false
   */
  private checkIfEventDateIsInMonthRecurrencePattern(
    startDate: moment.Moment,
    eventStartDate: moment.Moment
  ): boolean {
    let dateInMonth = moment(startDate).clone();
    while (dateInMonth.isSameOrBefore(this.endDate, 'day')) {
      if (this.daysOfWeek && this.recurrencePattern?.occuranceDayInMonth) {
        const weekdayAsNumber = this.convertWeekDayToNum(this.daysOfWeek[0]);
        // option latest weekday in month
        if (this.recurrencePattern?.occuranceDayInMonth === 5) {
          const lastDayOfMonth = moment(dateInMonth)
            .clone()
            .endOf('month')
            .set({
              hour: dateInMonth.hour(),
              minute: dateInMonth.minute(),
              second: 0,
              millisecond: 0,
            });
          const weekday = lastDayOfMonth.clone().day(weekdayAsNumber);
          dateInMonth = weekday.isSame(dateInMonth, 'month')
            ? weekday
            : weekday.subtract(7, 'd');
        } else {
          const firstDayOfMonth = moment(dateInMonth).clone().date(1);
          const daysUntilWeekday =
            (weekdayAsNumber - firstDayOfMonth.day() + 7) % 7;
          const firstOccurrenceDate = firstDayOfMonth.add(
            daysUntilWeekday,
            'days'
          );

          dateInMonth = firstOccurrenceDate.add(
            (this.recurrencePattern?.occuranceDayInMonth - 1) * 7,
            'days'
          );
        }
      }
      if (eventStartDate.isSame(dateInMonth)) {
        dateInMonth.add(this.recurrencePattern?.interval, 'month');
        return true;
      } else {
        dateInMonth.add(this.recurrencePattern?.interval, 'month');
      }
    }
    return false;
  }

  /**
   * Check if the duration was previously changed
   * @description returns false if the duration was previously changed
   * @param event
   * @returns
   */
  private checkIfDurationWasPreviouslyChanged(
    event: EventPickerEventModel
  ): Boolean {
    const oldStartTime = moment(event.startDate).format('HH:mm');
    const oldEndTime = moment(event.endDate).format('HH:mm');

    return oldStartTime === this.startTime && oldEndTime === this.endTime;
  }

  // getter for eventList FormArray
  get eventList() {
    return this.dateForm?.controls['eventList'] as FormArray;
  }

  // custom form control settings
  public onTouched: () => void = () => {};

  writeValue(val: any): void {
    //val && this.dateForm.setValue(val, { emitEvent: false });

    if (val) {
      this.dateForm?.setValue(
        {
          dateRange: {
            start: moment(val.dateRange.start),
            end: moment(val.dateRange.end),
          },
          date: moment(val.date),
          dateSeries: val.dateSeries,
          recurringInterval: val.recurringInterval ?? null,
          startTime: val?.startTime ?? '00:00',
          endTime: val?.endTime ?? '23:59',
          eventList: val.eventList.map(event => ({
            id: event.id !== undefined ? event.id : null,
            eventDate: moment(event.eventDate),
            eventStartTime: event.eventStartTime,
            eventEndTime: event.eventEndTime,
          })),
          allDayCheckbox: val.allDayCheckbox,
          ocurranceDayInMonth: val.ocurranceDayInMonth,
          ocurranceDayIntervalInMonth: val.ocurranceDayIntervalInMonth,
          mondayCheckbox: val.mondayCheckbox,
          tuesdayCheckbox: val.tuesdayCheckbox,
          wednesdayCheckbox: val.wednesdayCheckbox,
          thursdayCheckbox: val.thursdayCheckbox,
          fridayCheckbox: val.fridayCheckbox,
          saturdayCheckbox: val.fridayCheckbox,
          sundayCheckbox: val.sundayCheckbox,
        },
        { emitEvent: false }
      );

      if (this.allDayEvent) {
        this.dateForm?.get('startTime').disable();
        this.dateForm?.get('endTime').disable();
      }
    }
  }
  registerOnChange(fn: any): void {
    this.dateForm?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.dateForm?.disable() : this.dateForm?.enable();
  }
  // custom form control validation
  validate(c: AbstractControl): ValidationErrors | null {
    return this.dateForm?.valid
      ? null
      : {
          invalidDateGroup: {
            valid: false,
            message: 'dateGroup fields are invalid',
          },
        };
  }

  ngOnChanges(changes: SimpleChanges): void {
    // update end date and time if the duration was changed
    if (
      changes.duration &&
      !changes.duration.firstChange &&
      this.startDate &&
      this.endDate
    ) {
      this.endDate = this.calculateEndDate(moment(this.startDate));
      if (this.endTime) {
        this.endTime = this.endDate.format('HH:mm');

        this.dateForm.get('endTime').setValue(this.endTime);
        this.updateEndDate();
        this.publishDates();

        this.removeReoccuringEvents();
        this.setEvents();
      }
    }

    // mark form as touched if parentForm is submitted
    if (changes.parentFormSubmitValidation) {
      this.dateForm?.markAllAsTouched();
    }
  }

  /**
   * when both dates from date range have changed updated events
   * @returns void
   */
  public onDateRangePickerClosed(): void {
    if (
      this.dateForm.get('dateRange.start').valid &&
      this.dateForm.get('dateRange.end').valid
    ) {
      // get the new start and end date
      const newStartDate = moment(
        this.dateForm.get('dateRange.start').value
      ).startOf('day');
      const newEndDate = moment(
        this.dateForm.get('dateRange.end').value
      ).startOf('day');
      // save previous start and endtime
      const oldStartDate = moment(this.startDate);
      const oldEndDate = moment(this.endDate);

      // update start and end date
      this.startDate = newStartDate
        .clone()
        .hour(oldStartDate.hour())
        .minute(oldStartDate.minute());
      this.endDate = newEndDate
        .clone()
        .hour(oldEndDate.hour())
        .minute(oldEndDate.minute());

      this.evaluateRecurrenceAvailability();
      this.removeReoccuringEvents();
      this.setEvents();
      this.publishDates();
      this.dateForm.get('date').setValue(this.startDate);
    }
  }

  /**
   * date from the datepicker was changed
   * @param event the event from the datepicker
   * @returns void
   */
  public dateChanged(event: any): void {
    const newDate = moment(event.target.value).startOf('day');
    const startDate = moment(this.startDate);
    const endDate = moment(this.endDate);

    this.startDate = newDate
      .clone()
      .hour(startDate.hour())
      .minute(startDate.minute());
    this.endDate = newDate
      .clone()
      .hour(endDate.hour())
      .minute(endDate.minute());

    this.removeReoccuringEvents();
    this.setEvents();
    this.publishDates();
    this.updateStartDate(true);
    this.updateEndDate(true);
  }

  /**
   * startTime from the eventList was changed
   * @param event the event from the timepicker
   * @returns void
   */
  public startTimeChanged(event: any): void {
    // get the new start time
    const [startTimeHours, startTimeMins] = event.target.value.split(':');

    // only execute if both digits of hour or minute are available
    if (!startTimeHours || !startTimeMins) {
      return;
    }

    // when no start date is set use today
    this.startDate = this.startDate
      ? moment(this.startDate)
      : moment().startOf('day');
    // set the new start time
    this.startDate = this.startDate.hour(startTimeHours).minute(startTimeMins);
    this.startTime = moment(this.startDate).format('HH:mm');
    this.updateStartDate();

    if (this.duration) {
      // update the end date based on the new start date
      this.endDate = this.calculateEndDate(this.startDate);

      // set start and end time of the course
      this.endTime = moment(this.endDate).format('HH:mm');

      this.dateForm.controls['endTime'].setValue(this.endTime);
      this.updateEndDate();
    }

    this.publishDates();

    if (!this.dateSeries || !this.recurrencePattern) {
      this.updateUnreoccuringEvents();
      return;
    }

    this.removeReoccuringEvents();
    this.setEvents();
  }

  /**
   * endTime from the eventList was changed
   * @param event the event from the timepicker
   * @returns void
   */
  public endTimeChanged(event: any) {
    const [endTimeHours, endTimeMins] = event.target.value.split(':');

    // only execute if both digits of hour or minute are available
    if (!endTimeHours || !endTimeMins) {
      return;
    }

    // when no end date is set use today
    this.endDate = this.endDate
      ? moment(this.endDate)
      : moment().startOf('day');
    // set the new end date and time
    this.endDate = this.endDate.hour(endTimeHours).minute(endTimeMins);
    this.endTime = moment(this.endDate).format('HH:mm');
    this.updateEndDate();

    if (this.duration) {
      // update the start date based on the new end date
      this.startDate = this.calculateStartDate(this.endDate);

      // set start and end time of the course
      this.startTime = moment(this.startDate).format('HH:mm');

      this.dateForm.get('startTime').setValue(this.startTime);
      this.updateStartDate();
    }
    this.publishDates();

    if (!this.dateSeries || !this.recurrencePattern) {
      this.updateUnreoccuringEvents();
      return;
    }

    this.removeReoccuringEvents();
    this.setEvents();
  }

  /**
   * updates the start and end date and time when it's no dateSerien
   * @returns void
   */
  private updateUnreoccuringEvents(): void {
    if (this.eventList.controls.length === 0) {
      this.setEvents();
    }
    // find the event date that was not manually changed or added and update it
    this.eventList.controls.forEach((control, index) => {
      //check if the event was manually added
      const isManuallyAdded = this.manuallyAddedDates.some(date =>
        moment(date).isSame(control.value.eventDate)
      );

      if (isManuallyAdded || this.manuallyAddedEventIndexes.includes(index)) {
        return;
      }
      // update event date to the new start date
      control.get('eventDate').setValue(this.startDate);
      // update event start time to the new start time
      control.get('eventStartTime').setValue(this.startTime);
      // update event end time to the new end time
      control.get('eventEndTime').setValue(this.endTime);
    });
    this.updateEvents();
  }

  /**
   * calculate the end date based on the start date and the duration
   * @param startDate the start date
   * @returns the end date of the course
   */
  private calculateEndDate(startDate: moment.Moment): moment.Moment {
    const oldEndDate = this.endDate
      ? moment(this.endDate).startOf('day')
      : moment().startOf('day');

    // change the time of the end date to the time of the start date and then add the duration to get the new end date
    return oldEndDate
      .hour(startDate.hour())
      .minute(startDate.minute())
      .add(this.duration, 'm');
  }

  /**
   * calculate the start date based on the end date and the duration
   * @param endDate the end date
   * @returns the start date of the course
   */
  private calculateStartDate(endDate: moment.Moment): moment.Moment {
    const oldStartDate = this.startDate
      ? moment(this.startDate).startOf('day')
      : moment().startOf('day');

    // change the time of the start date to the time of the end date and then subtract the duration to get the new start date
    return oldStartDate
      .hour(endDate.hour())
      .minute(endDate.minute())
      .subtract(this.duration, 'm');
  }

  /**
   * update the start date in the form
   * @param force whether to force the update
   * @returns void
   */
  private updateStartDate(force: boolean = false): void {
    if (this.dateForm.get('dateRange.start').value || force) {
      this.dateForm.get('dateRange.start').setValue(this.startDate);
    }
  }

  /**
   * update the end date in the form
   * @param force whether to force the update
   * @returns void
   */
  private updateEndDate(force: boolean = false): void {
    if (this.dateForm.get('dateRange.end').value || force) {
      this.dateForm.get('dateRange.end').setValue(this.endDate);
    }
  }

  /**
   * publish the start and end date to the parent component
   * @returns void
   */
  private publishDates(): void {
    if (
      this.dateForm.get('dateRange.start').valid &&
      this.dateForm.get('dateRange.end').valid
    ) {
      this.datesChanged.emit({
        start: moment(this.startDate).toDate(),
        end: moment(this.endDate).toDate(),
        allDayEvent: this.allDayEvent,
      });
    }
  }

  /**
   * convert the weekday as a string to a number
   * @param weekday the weekday as a string to convert
   * @returns the weekday as a number
   */
  private convertWeekDayToNum(weekday: string): number {
    switch (weekday) {
      case 'MO':
        return 1;
      case 'TU':
        return 2;
      case 'WE':
        return 3;
      case 'TH':
        return 4;
      case 'FR':
        return 5;
      case 'SA':
        return 6;
      case 'SU':
        return 7;
      default:
        return 1;
    }
  }

  /**
   * create events based on the selected date and time range and the selected recurrence pattern
   * @returns void
   */
  private setEvents(): void {
    // check if all date and time fields are set
    if (
      (!this.dateForm.get('dateRange.start').valid &&
        !this.dateForm.get('date').valid) ||
      (!this.dateForm.get('dateRange.end').valid &&
        !this.dateForm.get('date').valid) ||
      !this.startTime ||
      !this.endTime
    ) {
      return;
    }
    if (
      this.eventList.controls.length === 0 &&
      !this.recurrencePattern?.frequency
    ) {
      this.createEventFormGroup(moment(this.startDate));
    }

    if (this.recurrencePattern?.frequency && this.recurrencePattern?.interval) {
      this.generateRecurringEvents();
    }
    if (this.eventList.controls.length > 0) {
      this.showEvents = true;
    }
    this.updateCustomAttributes();
    this.updateEvents();
  }

  /**
   * generate recurring events based on the selected recurrence pattern
   * @returns void
   */
  private generateRecurringEvents(): void {
    let eventStartDate = moment(this.startDate);

    while (eventStartDate.isSameOrBefore(this.endDate, 'day')) {
      switch (this.recurrencePattern?.frequency) {
        case 'daily':
          this.handleDailyRecurrence(eventStartDate);
          break;
        case 'weekly':
          this.handleWeeklyRecurrence(eventStartDate);
          break;
        case 'monthly':
          this.handleMonthlyRecurrence(eventStartDate);
          break;
        default:
          return;
      }
    }
  }

  /**
   * generate events for daily recurrence
   * @param eventStartDate the start date of the event
   * @returns void
   */
  private handleDailyRecurrence(eventStartDate: moment.Moment): void {
    this.createEventFormGroup(eventStartDate);
    eventStartDate.add(this.recurrencePattern?.interval, 'd');
  }

  /**
   * generate events for weekly recurrence
   * @param eventStartDate the start date of the event
   * @returns void
   */
  private handleWeeklyRecurrence(eventStartDate: moment.Moment): void {
    if (this.recurrencePattern?.daysOfWeek) {
      this.daysOfWeek.forEach(day => {
        eventStartDate.day(this.convertWeekDayToNum(day));
        // check if eventStartDate is before the end date
        if (eventStartDate.isAfter(this.endDate)) {
          return;
        }
        this.createEventFormGroup(eventStartDate);
      });
    } else {
      this.createEventFormGroup(eventStartDate);
    }
    // jump to start of next week
    eventStartDate.add(this.recurrencePattern?.interval, 'w').weekday(0);
  }

  /**
   * generate events for monthly recurrence
   * @param eventStartDate the start date of the event
   * @returns void
   */
  private handleMonthlyRecurrence(eventStartDate: moment.Moment): void {
    if (this.daysOfWeek && this.recurrencePattern?.occuranceDayInMonth) {
      this.handleMonthlyWeekdayRecurrence(eventStartDate);
    } else {
      this.createEventFormGroup(eventStartDate);
    }
    eventStartDate.add(this.recurrencePattern?.interval, 'month');
  }

  /**
   * generate events for monthly recurrence with a weekday and occurance day in month
   * @param eventStartDate the start date of the event
   * @returns void
   */
  private handleMonthlyWeekdayRecurrence(eventStartDate: moment.Moment): void {
    const weekdayAsNumber = this.convertWeekDayToNum(this.daysOfWeek[0]);
    // Handle the latest weekday in month
    if (this.recurrencePattern?.occuranceDayInMonth === 5) {
      const lastDayOfMonth = eventStartDate.clone().endOf('month');
      const lastWeekday = lastDayOfMonth.clone().day(weekdayAsNumber);
      eventStartDate = lastWeekday.isSame(lastDayOfMonth, 'month')
        ? lastWeekday
        : lastWeekday.subtract(7, 'days');
    } else {
      const firstDayOfMonth = eventStartDate.clone().date(1);
      const daysUntilWeekday =
        (weekdayAsNumber - firstDayOfMonth.day() + 7) % 7;
      const firstOccurrenceDate = firstDayOfMonth.add(daysUntilWeekday, 'days');
      eventStartDate = firstOccurrenceDate.add(
        (this.recurrencePattern?.occuranceDayInMonth - 1) * 7,
        'days'
      );
    }
    this.createEventFormGroup(eventStartDate);
  }

  /**
   * create a new event form group and add it to the eventList
   * @param eventStartDate the start date of the event
   * @returns void
   */
  private createEventFormGroup(eventStartDate: moment.Moment): void {
    // only add event if no event for the same date exists and if the date is in the selected interval
    if (
      !this.eventList.controls.find(it =>
        moment(it.get('eventDate').value).isSame(eventStartDate.clone())
      ) &&
      !this.manuallyRemovedDates.find(it =>
        moment(it).isSame(eventStartDate.clone())
      ) &&
      eventStartDate.isSameOrAfter(this.startDate)
    ) {
      const [endTimeHours, endTimeMins] = this.endTime
        ? this.endTime.split(':')
        : this.endDate.format('HH:mm').split(':');

      const endDate = moment(eventStartDate)
        .hour(endTimeHours)
        .minute(endTimeMins)
        .toDate();
      const eventForm = this.fb.group({
        eventDate: [
          moment(eventStartDate).clone().toDate(),
          Validators.required,
        ],
        eventStartTime: [
          moment(eventStartDate).format('HH:mm'),
          Validators.required,
        ],
        eventEndTime: [moment(endDate).format('HH:mm'), Validators.required],
      });
      this.eventList.push(eventForm);

      // order events by date
      this.eventList.controls.sort((a, b) => {
        if (
          moment(a.get('eventDate').value).isBefore(b.get('eventDate').value)
        ) {
          return -1;
        } else if (
          moment(a.get('eventDate').value).isAfter(b.get('eventDate').value)
        ) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  /**
   * save the old values of the start and end time in the formControls
   * @returns void
   */
  private updateCustomAttributes(): void {
    this.eventList.controls.forEach(control => {
      control.get('eventStartTime')['data-oldValue'] =
        control.get('eventStartTime').value;
      control.get('eventEndTime')['data-oldValue'] =
        control.get('eventEndTime').value;
    });
  }

  /**
   * remove all events created by the recurrence pattern
   * @returns void
   */
  private removeReoccuringEvents(): void {
    let removableControls = [];
    this.eventList.controls.forEach((control, index) => {
      const eventDate = moment(control.get('eventDate').value);

      if (this.manuallyAddedDates.find(it => eventDate.isSame(it))) {
        return;
      } else {
        removableControls.push(index);
      }
    });
    while (removableControls.length > 0) {
      const removeAt = removableControls.pop();
      this.eventList.removeAt(removeAt);
    }
  }

  /**
   * update the events array
   * @returns void
   */
  private updateEvents(): void {
    if (this.checkForDuplicateEvents()) {
      return;
    }

    let event: EventPickerEventModel;
    const oldEvents = this.events.slice();
    this.events = [];

    this.eventList.controls.forEach(item => {
      // check if all fields are set
      if (
        !item.get('eventDate').value ||
        !item.get('eventStartTime').value ||
        !item.get('eventEndTime').value
      ) {
        return;
      }
      const id = item.get('id')?.value;
      const startTime = item.get('eventStartTime').value;
      const [startTimeHour, startTimeMinute] = startTime.split(':');
      const endTime = item.get('eventEndTime').value;
      const [endTimeHour, endTimeMinute] = endTime.split(':');
      const eventDate = item.get('eventDate').value;

      const startDate = moment(eventDate)
        .hour(startTimeHour)
        .minute(startTimeMinute)
        .toDate();
      const endDate = moment(eventDate)
        .hour(endTimeHour)
        .minute(endTimeMinute)
        .toDate();
      const oldEvent = oldEvents.find(it =>
        moment(it.startDate).isSame(moment(startDate))
      );
      event = {
        id: id ?? null,
        startDate: startDate,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        isCanceled: oldEvent?.isCanceled ?? false,
        room: oldEvent?.room ?? null,
      };

      this.events.push(event);
    });

    this.sortEventsByDate();

    this.eventsChanged.emit(this.events);
  }

  /**
   * sorts the events by their start date
   * @returns void
   */
  private sortEventsByDate(): void {
    this.events.sort((a, b) => {
      if (moment(b.startDate).isBefore(a.startDate)) {
        return 1;
      } else if (moment(a.startDate).isBefore(b.startDate)) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  /**
   * check if there are duplicate events
   * @returns true if there are duplicate events otherwise false
   */
  private checkForDuplicateEvents(): Boolean {
    const duplicateEvents = this.eventList.controls.filter(
      (control, index) =>
        this.eventList.controls.findIndex(
          it =>
            moment(it.get('eventDate').value).isSame(
              moment(control.get('eventDate').value),
              'day'
            ) &&
            it.get('eventStartTime').value ===
              control.get('eventStartTime').value
        ) !== index
    );

    if (duplicateEvents.length > 0) {
      duplicateEvents.forEach(control => {
        control.setErrors({ duplicate: true });
        this.eventList.updateValueAndValidity();
      });
      this.alertService.showErrorAlert(
        'Fehler',
        'Es können keine zwei Veranstaltungen zur gleichen Zeit stattfinden.'
      );
      return true;
    }
    return false;
  }

  /**
   * handle manually added dates
   * @param event the event from the datepicker
   * @returns void
   */
  public eventDateChanged(event: any): void {
    this.manuallyAddedDates.push(moment(event.target.value).toDate());
    this.updateCustomAttributes();
    this.updateEvents();
  }

  private endTimeTimeout: any;
  /**
   * startTime of an event was changed
   * @param event the event from the timepicker
   * @param index the index of the event in the eventList
   * @returns void
   */
  public eventStartTimeChanged(event: any, index: number): void {
    this.triggered++;
    clearTimeout(this.endTimeTimeout);
    // get the old startTime from dataAttribute and create a new date from startTime
    const [oldStartTimeHour, oldStartTimeMinute] = this.eventList
      .at(index)
      .get('eventStartTime')['data-oldValue']
      ? this.eventList
          .at(index)
          .get('eventStartTime')
          ['data-oldValue']?.split(':')
          .map(Number)
      : [0, 0];

    // get new startTime from input field
    const [startTimeHours, startTimeMinutes] = event.target.value
      .split(':')
      .map(Number);

    if (startTimeHours == null || startTimeMinutes == null) {
      return;
    }

    // check if the current eventDate is valid
    const invalidEventDate =
      this.eventList.at(index).get('eventDate').value === null;

    // create a new date from the new startTime
    const newStartDate = !invalidEventDate
      ? moment(this.eventList.at(index).get('eventDate').value)
          .hour(startTimeHours)
          .minute(startTimeMinutes)
      : moment().startOf('day').hour(startTimeHours).minute(startTimeMinutes);

    // update the event date with the new StartTime when the date value was not null
    if (!invalidEventDate) {
      this.eventList.at(index).get('eventDate').setValue(newStartDate);
    }

    // check if the eventEndTime was already set
    if (this.eventList.at(index).get('eventEndTime').value) {
      const totalMinutesStartTimes = oldStartTimeHour * 60 + oldStartTimeMinute;
      const calculatedMinutesEndTime = totalMinutesStartTimes + this.duration;

      const [endTimeHours, endTimeMinutes] = this.eventList
        .at(index)
        .get('eventEndTime')
        .value.split(':')
        .map(Number);
      const totalMinutesEndTime = endTimeHours * 60 + endTimeMinutes;

      // when the duration was manually changed don't update the endTime
      if (calculatedMinutesEndTime !== totalMinutesEndTime) {
        this.updateEvents();
        this.updateCustomAttributes();
        this.triggered = 0;
        return;
      }
    }

    if (
      event.target.value.split(':')[0].charAt(0) !== '0' &&
      event.target.value.split(':')[1].charAt(0) !== '0'
    ) {
      this.updateEndTime(index, startTimeHours, startTimeMinutes);
    } else {
      // prevent setting endTime when entering start time not finished
      this.endTimeTimeout = setTimeout(() => {
        if (this.triggered !== 0) {
          this.updateEndTime(index, startTimeHours, startTimeMinutes);
        }
      }, 1000);
    }
  }

  /**
   * update the endTime of an event
   * @param index the index of the event in the eventList
   * @param startTimeHours the hours of the startTime
   * @param startTimeMinutes the minutes of the startTime
   * @returns void
   */
  private updateEndTime(
    index: number,
    startTimeHours: number,
    startTimeMinutes: number
  ): void {
    const totalMinutesNewEndTime =
      startTimeHours * 60 + startTimeMinutes + this.duration;
    const newEndTimeHours = Math.floor(totalMinutesNewEndTime / 60);
    const newEndTimeMinutes = totalMinutesNewEndTime % 60;

    this.eventList
      .at(index)
      .get('eventEndTime')
      .setValue(
        `${this.padZero(newEndTimeHours)}:${this.padZero(newEndTimeMinutes)}`
      );

    this.updateEvents();
    this.updateCustomAttributes();
    this.triggered = 0;
  }

  /**
   * pad a number with a zero if it is smaller than 10
   * @param num the number to pad
   * @returns the padded number as a string
   */
  private padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  /**
   * endTime of an event was changed
   * @param event the event from the timepicker
   * @param index the index of the event in the eventList
   * @returns void
   */
  public eventEndTimeChanged(event: any, index: number) {
    this.updateEvents();
    this.updateCustomAttributes();
  }

  /**
   * add a new empty event to the eventList
   * @returns void
   */
  public addEvent(): void {
    // if dateRange is only one day add a new event with the same date but no time
    if (moment(this.startDate).isSame(moment(this.endDate), 'day')) {
      const eventForm = this.fb.group({
        eventDate: [this.startDate, Validators.required],
        eventStartTime: [null, Validators.required],
        eventEndTime: [null, Validators.required],
      });
      this.eventList.push(eventForm);

      // get the index of the new event
      const index = this.eventList.controls.length - 1;
      this.manuallyAddedEventIndexes.push(index);
      return;
    }
    const eventForm = this.fb.group({
      eventDate: [null, Validators.required],
      eventStartTime: [this.startTime, Validators.required],
      eventEndTime: [this.endTime, Validators.required],
    });
    this.eventList.push(eventForm);
  }

  /**
   * remove an event from the eventList
   * @param index the index of the event in the eventList
   * @returns void
   */
  public removeEvent(index: number): void {
    this.manuallyRemovedDates.push(
      moment(this.eventList.at(index).get('eventDate').value).toDate()
    );
    this.eventList.removeAt(index);

    if (this.eventList.length === 0) {
      this.alertService.showWarningAlert(
        'Keine Termine',
        'Es muss mindestens einen Termin geben'
      );
    }

    this.updateEvents();
  }

  /**
   * scroll to the bottom of the events container
   * @returns void
   */
  private scrollToBottom(): void {
    try {
      this.eventsContainer.nativeElement.scrollTop =
        this.eventsContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  public ngAfterViewChecked() {
    if (this.eventsFormArrayLength < this.eventList.controls.length) {
      this.scrollToBottom();
    }
    this.eventsFormArrayLength = this.eventList.controls.length;
  }

  /**
   * update the recurrence pattern when the frequency was changed
   * @param frequency the new frequency
   * @returns void
   */
  public onRecurringFrequencyChanged(frequency: recurrenceFrequency): void {
    // reset previous set values from other frequencys
    this.resetAllWeekDayCheckboxes();
    if (!this.recurrencePattern) {
      this.recurrencePattern = {
        id: null,
        frequency: null,
        interval: null,
        daysOfWeek: null,
        occuranceDayInMonth: null,
      };
    }
    this.recurrencePattern.daysOfWeek = null;
    this.recurrencePattern.occuranceDayInMonth = null;
    this.dateForm.controls['ocurranceDayIntervalInMonth'].setValue(null);
    this.dateForm.controls['ocurranceDayInMonth'].setValue(null);

    if (this.events.length > 1 && this.recurrencePattern) {
      this.removeReoccuringEvents();
    }
    if (this.recurrencePattern?.frequency === frequency) {
      this.recurrencePattern.interval = null;
      this.dateForm.get('recurringInterval').setValue(null);
      this.recurrencePattern.frequency = null;
    } else {
      this.recurrencePattern.interval = 1;
      this.dateForm.get('recurringInterval').setValue(1);
      this.recurrencePattern.frequency = frequency;
    }
    this.setEvents();

    this.recurrencePatternChanged.emit(this.recurrencePattern);
  }

  /**
   * unchecks all weekday checkboxes
   * resets the daysOfWeek array
   * @returns void
   */
  private resetAllWeekDayCheckboxes(): void {
    this.dateForm.get('mondayCheckbox').setValue(0);
    this.dateForm.get('tuesdayCheckbox').setValue(0);
    this.dateForm.get('wednesdayCheckbox').setValue(0);
    this.dateForm.get('thursdayCheckbox').setValue(0);
    this.dateForm.get('fridayCheckbox').setValue(0);
    this.dateForm.get('saturdayCheckbox').setValue(0);
    this.dateForm.get('sundayCheckbox').setValue(0);
    this.daysOfWeek = [];
  }

  /**
   * update the recurrence pattern when the interval was changed
   * @param interval the new interval
   * @returns void
   */
  public onRecurringIntervalChanged(event: Event) {
    const input = event.target as HTMLInputElement;
    this.recurrencePattern.interval = input.valueAsNumber;
    this.removeReoccuringEvents();
    this.setEvents();
    this.recurrencePatternChanged.emit(this.recurrencePattern);
  }

  /**
   * update the recurrence pattern when the occuranceDayInMonth was changed
   * @param interval the new interval
   * @returns void
   */
  public onOccuranceDayChanged(event: any) {
    this.recurrencePattern.occuranceDayInMonth = event.target.valueAsNumber;
    this.removeReoccuringEvents();
    this.setEvents();
    this.recurrencePatternChanged.emit(this.recurrencePattern);
  }

  /**
   * update the recurrence pattern when a weekday was checked or unchecked
   * @param day the weekday that was checked or unchecked
   * @param event the event from the checkbox
   * @returns void
   */
  public onRecurrenceDayCheckboxChanged(day: string, event: any) {
    if (!event.checked) {
      const existingIndex = this.daysOfWeek.indexOf(day);
      this.daysOfWeek.splice(existingIndex, 1);
    }
    if (event.checked) {
      this.daysOfWeek.push(day);
    }
    this.daysOfWeek = this.daysOfWeek.sort(
      (a, b) => this.convertWeekDayToNum(a) - this.convertWeekDayToNum(b)
    );

    this.recurrencePattern.daysOfWeek = this.daysOfWeek.join(', ');

    this.removeReoccuringEvents();
    this.setEvents();

    this.recurrencePatternChanged.emit(this.recurrencePattern);
  }

  /**
   * update the recurrence pattern when the ocurranceDayInMonth was changed
   * @param event the selection change event
   * @returns void
   */
  public onOcurranceDayInMonthChanged(event: SelectionChangeEvent) {
    this.recurrencePattern.occuranceDayInMonth = +event.newValue;

    this.removeReoccuringEvents();
    this.setEvents();

    this.recurrencePatternChanged.emit(this.recurrencePattern);
  }

  /**
   * update the recurrence pattern when the ocurranceWeekdayInMonth was changed
   * @param event the selection change event
   * @returns void
   */
  public onOcurranceWeekdayInMonthChanged(event: SelectionChangeEvent) {
    this.daysOfWeek = [event.newValue.toString()];
    this.recurrencePattern.daysOfWeek = event.newValue.toString();

    this.removeReoccuringEvents();
    this.setEvents();

    this.recurrencePatternChanged.emit(this.recurrencePattern);
  }

  /**
   * set course to be an all day event
   * @param event the event from the checkbox
   * @returns void
   */
  public onAllDayCheckboxClicked(event: any): void {
    this.allDayEvent = event.checked;

    if (event.checked) {
      // set course to be an all day event

      // save previous start and endtime
      this.previousStartTime = this.dateForm.get('startTime').getRawValue();
      this.previousEndTime = this.dateForm.get('endTime').getRawValue();

      // set new start and endtime and disable controls
      this.dateForm.get('startTime').setValue('00:00');
      this.dateForm.get('endTime').setValue('23:59');
      this.dateForm.get('startTime').disable();
      this.dateForm.get('endTime').disable();

      // update start and end date and start and endtime
      this.startDate = moment(this.startDate).hours(0).minutes(0);
      this.endDate = moment(this.endDate).hours(23).minutes(59);
      this.startTime = this.startDate.format('HH:mm');
      this.endTime = this.endDate.format('HH:mm');

      // create the events
      this.removeReoccuringEvents();
      this.setEvents();
    } else {
      // set course to not be an all day event

      // set new start and end dates and times
      if (this.previousStartTime && this.previousEndTime) {
        this.startDate = moment(this.startDate)
          .hours(+this.previousStartTime.split(':')[0])
          .minutes(+this.previousStartTime.split(':')[1]);
        this.endDate = moment(this.endDate)
          .hours(+this.previousEndTime.split(':')[0])
          .minutes(+this.previousEndTime.split(':')[1]);
      } else {
        this.startDate = moment(this.startDate).hours(12).minutes(0);
        const newEndDate = moment(this.startDate).add(this.duration, 'm');
        const [newEndDateHour, newEndDateMinute] = newEndDate
          .format('hh:mm')
          .split(':');
        this.endDate = moment(this.endDate)
          .hours(+newEndDateHour)
          .minutes(+newEndDateMinute);
      }
      this.startTime = this.startDate.format('HH:mm');
      this.endTime = this.endDate.format('HH:mm');

      // enable controls and set new start and endtime or if existing set previous times
      this.dateForm.get('startTime').enable();
      this.dateForm.get('endTime').enable();
      this.dateForm.get('startTime').setValue(this.startDate.format('HH:mm'));
      this.dateForm.get('endTime').setValue(this.endDate.format('HH:mm'));
      // create the events
      this.removeReoccuringEvents();
      this.setEvents();
    }
    this.publishDates();
  }

  /**
   * reset the recurrence pattern to the default values
   * keep the id of the recurrence pattern
   * @returns void
   */
  private resetRecurrencePattern(): void {
    if (this.recurrencePattern) {
      this.recurrencePattern = {
        id: this.recurrencePattern?.id,
        frequency: null,
        interval: null,
        daysOfWeek: null,
        occuranceDayInMonth: null,
      };
    }
    this.recurrencePatternChanged.emit(this.recurrencePattern);
  }

  /**
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
