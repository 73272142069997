import { Injectable } from '@angular/core';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';

@Injectable({
  providedIn: 'root',
})
export class DecryptionService {
  constructor(private cryptoService: CryptoService) {}
  /**
   * decrypts the encrypted string with the crypto service
   * @param encryptedString the string to decrypt
   * @returns the decrypted string or the encrypted string if an error occurred
   */
  public async decryptString(encryptedString: string): Promise<string> | null {
    if (!encryptedString) {
      return null;
    }
    let decryptedString = '';
    try {
      decryptedString = await this.cryptoService.decrypt(encryptedString);
    } catch (error) {
      decryptedString = encryptedString;
    }
    return decryptedString;
  }
}
