import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-education-progress',
  templateUrl: './education-progress.component.html',
  styleUrls: ['./education-progress.component.scss'],
})
export class EducationProgressComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  /**
   * Redirects to the initial-education-stock route
   * @returns void
   */
  public onOpenInitialEducationStock(): void {
    this.router.navigate(['initial-education-stock'], {
      relativeTo: this.activatedRoute,
    });
  }
}
