import { Inject, Injectable } from '@angular/core';
import Telemetrydeck, { TelemetrydeckMode } from '@healthycloud/telemetrydeck';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class TelemetryService {
  private telemetryClient: Telemetrydeck;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private userService: UserService
  ) {
    this.telemetryClient = new Telemetrydeck(
      this.config.telemetryDeckAppId,
      this.userService.currentUser?.id.toString(),
      false,
      TelemetrydeckMode.Web
    );
  }

  /**
   * sends a signal to TelemetryDeck
   * @param signalType the type of signal to send
   * @param payload the payload to send with the signal
   * @returns Promise<Response>
   */
  public async sendSignal(
    signalType: string,
    payload?: any
  ): Promise<Response> {
    return this.telemetryClient.signal(signalType, payload);
  }

  /**
   * tracks a pageview in TelemetryDeck
   * @returns Promise<Response>
   */
  public async trackPageview(): Promise<Response> {
    return await this.sendSignal('pageview');
  }
}
