import * as moment from 'moment';
import { CourseLocation, CourseModel } from '../models/course.model';
import { isSameDay } from './utils';

/**
 * get the list of education course titles concatenated with ',' from course
 * @param course the course
 * @returns the string of education course titles
 */
export function getEducationCourseTitles(course: CourseModel): string {
  return course.educationCourses
    ?.map(educationCourse => educationCourse.title)
    .join(', ');
}

/**
 * get course room based on the location from course
 * @param course the course
 * @returns the course room to display in the UI or null
 */
export function getCourseRoom(course: CourseModel): string | null {
  if (course.location === CourseLocation.ONLINE) {
    return 'Digital';
  }
  if (course.location === CourseLocation.HYBRID) {
    if (course.differentRooms) {
      return 'Hybrid / Unterschiedliche';
    }
    if (!course.differentRooms && course.room?.name) {
      return 'Hybrid / ' + course.room?.name;
    }
    return 'Hybrid';
  }
  if (course.differentRooms) {
    return 'Unterschiedliche';
  }
  return course.room?.name ?? null;
}

/**
 * get course start date based on events start date or start date from course
 * @param course the course
 * @returns the start date of the course
 */
export function getCourseStartDate(course: CourseModel): Date {
  if (course.eventsStartDate) {
    return course.eventsStartDate;
  }
  return course.startDate;
}

/**
 * get course end date based on events end date or end date from course
 * @param course the course
 * @returns the end date of the course
 */
export function getCourseEndDate(course: CourseModel): Date {
  if (course.eventsEndDate) {
    return course.eventsEndDate;
  }
  return course.endDate;
}

/**
 * get course date range based on course start and end date
 * @param course the course
 * @returns the date range of the course
 */
export function getCourseDateRange(course: CourseModel): string {
  const startDate = getCourseStartDate(course);
  const endDate = getCourseEndDate(course);

  if (isSameDay(startDate, endDate)) {
    return `${moment(startDate).format('DD.MM.YYYY')}`;
  }

  return `${moment(startDate).format('DD.MM.YYYY')} - ${moment(endDate).format(
    'DD.MM.YYYY'
  )}`;
}

/**
 * get course logon tooltip based on course status and registered students
 * @param course the course
 * @returns the tooltip message
 */
export function getCourseLogonTooltip(course: CourseModel): string {
  if (!course) {
    return '';
  }
  if (course.closed) {
    return 'Anmeldung an einem geschlossenen Kurs nicht möglich.';
  }
  if (!course.logonEnabled) {
    return 'Der Kurs wurde deaktiviert.';
  }
  if (course.registeredStudents >= course.maxParticipants) {
    return 'Der Kurs ist bereits belegt.';
  }
  if (isCourseFinished(course)) {
    return 'Der Kurs ist bereits abgeschlossen.';
  }
  return '';
}

/**
 * get course logoff tooltip based on course status
 * @param course the course
 * @returns the tooltip message
 */
export function getCourseLogoffTooltip(course: CourseModel): string {
  if (!course) {
    return '';
  }
  if (course.closed) {
    return 'Abmeldung von einem geschlossenen Kurs nicht möglich.';
  }
  if (isCourseFinished(course)) {
    return 'Der Kurs ist bereits abgeschlossen.';
  }
  return '';
}

/**
 * check if course logon is disabled based on course status and registered students
 * @param course the course
 * @returns true if course logon is disabled, false otherwise
 */
export function isCourseLogonDisabled(course: CourseModel): boolean {
  if (!course) {
    return true;
  }
  return (
    course.closed ||
    !course.logonEnabled ||
    course.registeredStudents >= course.maxParticipants ||
    isCourseFinished(course)
  );
}

/**
 * check if course logoff is disabled based on course status
 * @param course the course
 * @returns true if course logoff is disabled, false otherwise
 */
export function isCourseLogoffDisabled(course: CourseModel): boolean {
  if (!course) {
    return true;
  }
  return course.closed || isCourseFinished(course);
}

/**
 * check if course is finished based on events end date or end date from course
 * @param course the course
 * @returns true if course is finished, false otherwise
 */
export function isCourseFinished(course: CourseModel): boolean {
  if (course.eventsEndDate) {
    return moment().isAfter(course.eventsEndDate);
  }
  return moment().isAfter(course.endDate);
}
