<div class="edit-master-data-dialog flex-c">
  <!-- HEADER -->
  <div class="header flex-r">
    <span class="hasomed-text-h1">Institut und Rolle ändern</span>
    <button
      type="button"
      class="close-button"
      matTooltip="Abbrechen"
      (click)="onCancel()">
      <i class="fa-light fa-x close-button-icon"></i>
    </button>
  </div>

  <!-- FORMULAR -->
  <form class="edit-master-data-form" [formGroup]="editUserForm">
    <div class="flex-c gap-24 scrollable-content">
      <!-- Anrede & Titel -->
      <div class="flex-r gap-8">
        <!-- Anrede -->
        <div class="formular-row flex-grow">
          <label for="genderTitle" class="formular-row-label">
            <div class="label-title">Anrede</div>
          </label>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <input
                id="genderTitle"
                class="hasomed-text-input flex-grow"
                formControlName="genderTitle"
                placeholder="Anrede" />
            </div>
          </div>
        </div>

        <!-- Titel -->
        <div class="formular-row flex-grow">
          <label for="academicTitle" class="formular-row-label">
            <div class="label-title">Titel</div>
          </label>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <input
                id="academicTitle"
                class="hasomed-text-input flex-grow"
                formControlName="academicTitle"
                placeholder="Titel" />
            </div>
          </div>
        </div>
      </div>

      <!-- Vor- und Nachname -->
      <div class="flex-r gap-8">
        <!-- Vorname -->
        <div class="formular-row flex-grow">
          <label for="firstName" class="formular-row-label">
            <div class="label-title">
              Vorname<span *ngIf="isRequired(editUserForm.get('firstName'))"
                >*</span
              >
            </div>
          </label>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <input
                id="firstName"
                class="hasomed-text-input flex-grow"
                formControlName="firstName"
                placeholder="Vorname" />
            </div>
          </div>
        </div>

        <!-- Nachname -->
        <div class="formular-row flex-grow">
          <label for="lastName" class="formular-row-label">
            <div class="label-title">
              Nachname<span *ngIf="isRequired(editUserForm.get('lastName'))"
                >*</span
              >
            </div>
          </label>
          <div class="hasomed-input-field">
            <div class="formular-row-input">
              <input
                id="lastName"
                class="hasomed-text-input flex-grow"
                formControlName="lastName"
                placeholder="Nachname" />
            </div>
          </div>
        </div>
      </div>

      <!-- Email -->
      <div class="formular-row">
        <label for="email" class="formular-row-label">
          <div class="label-title">
            Email<span *ngIf="isRequired(editUserForm.get('email'))">*</span>
          </div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="email"
              class="hasomed-text-input flex-grow"
              formControlName="email"
              placeholder="Email" />
          </div>
        </div>
      </div>

      <div class="formular-row">
        <label class="formular-row-label">
          <div class="label-title">
            Superadmin<span *ngIf="isRequired(editUserForm.get('superadmin'))"
              >*</span
            >
          </div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <eleguide-select
              [control]="superAdminControl"
              controlId="superAdmin"
              [options]="availableSuperAdminSelectOptions"
              placeholder="Superadmin"
              [disabled]="user.id === currentUserId"></eleguide-select>
          </div>
        </div>
      </div>

      <!-- Institute -->
      <div
        class="formular-row"
        *ngIf="editUserForm.get('superadmin').value !== true">
        <label class="formular-row-label">
          <div class="label-title">
            Institut<span *ngIf="isRequired(editUserForm.get('institute'))"
              >*</span
            >
          </div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <eleguide-select
              [control]="instituteControl"
              controlId="institute"
              [options]="availableInstituteSelectOptions"
              placeholder="Institut"
              [showNullOption]="true"
              nullOptionLabel="-- Kein Institut --"></eleguide-select>
          </div>
        </div>
      </div>

      <!-- Role -->
      <div
        class="formular-row"
        *ngIf="editUserForm.get('superadmin').value !== true">
        <label class="formular-row-label">
          <div class="label-title">
            Rolle<span *ngIf="isRequired(editUserForm.get('role'))">*</span>
          </div>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <eleguide-select
              [control]="roleControl"
              controlId="roleId"
              [options]="availableRolesSelectOptions"
              placeholder="Rolle"
              [showNullOption]="true"
              nullOptionLabel="-- Keine Rolle --"></eleguide-select>
          </div>
        </div>
      </div>

      <!-- BUTTONS -->
      <div class="formular-buttons flex-r">
        <button
          type="button"
          class="hasomed-text-button hasomed-button-white"
          (click)="onCancel()">
          <span class="hasomed-button-text"> Abbrechen </span>
        </button>
        <button
          type="submit"
          class="hasomed-text-button hasomed-button-primary"
          [disabled]="!editUserForm.valid"
          (click)="onSave()">
          <i class="hasomed-button-icon fa-light fa-floppy-disk"></i>
          <span class="hasomed-button-text">Speichern</span>
        </button>
      </div>
    </div>
  </form>
</div>
