<div class="component-content">
  <div class="header">
    <div class="header-title" *ngIf="!isMobile">
      <h1 class="hasomed-text-h1">Praktische Tätigkeiten</h1>
      <h3 class="hasomed-text-h3">Fügen Sie neue Einträge hinzu.</h3>
    </div>

    <form class="header-buttons" [formGroup]="searchForm">
      <div class="hasomed-search" *ngIf="showSearchBar">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>
      <div class="filter-buttons" *ngIf="isMobile || isTablet">
        <button
          *ngIf="isMobile || isTablet"
          id="openSearchButton"
          [class.hasomed-button-active]="showSearchBar"
          [class.hasomed-button-primary]="
            searchForm.get('searchText').value !== ''
          "
          (click)="showSearchBar = !showSearchBar"
          class="hasomed-text-button hasomed-button-white-grey">
          <i class="fa-light fa-search hasomed-button-icon"></i>
        </button>
      </div>
      <button
        type="button"
        class="hasomed-text-button hasomed-button-primary"
        (click)="createPracticalWork()">
        <i class="fa-light fa-calendar-plus hasomed-button-icon"></i>
        <span class="hasomed-button-text">
          {{ isMobile ? '' : 'Neuen' }} Eintrag vornehmen
        </span>
      </button>
    </form>
  </div>
  <div class="main-content">
    <app-practical-work-table
      [searchText]="
        searchForm.get('searchText').value
      "></app-practical-work-table>
    <div class="vertical-spacer"></div>
  </div>
</div>
