import { EducationCourse } from './education-course.model';
import { eLog, eLogStatus, eLogStatusCounts } from './elog.model';
import {
  EventDate,
  EventDateCreateModel,
  EventDateUpdateModel,
} from './event.model';
import { ExamTypeModel } from './exam-type.model';
import { FileModel } from './file.model';
import { LearningContentModel } from './learning-content.model';
import { RoomModel } from './room.model';
import { UserModel } from './user.model';

export type CourseModel = {
  id: number;
  educationCourses: EducationCourse[];
  title: string;
  description: string;
  mandatory: boolean;
  titlePicture?: string;
  defaultTitlePicture?: number;
  courseType: CourseType;
  duration: number;
  link?: string;
  maxParticipants: number;
  registeredStudents?: number;
  room: RoomModel;
  differentRooms?: boolean;
  internalNotes?: string;
  lecturers: UserModel[];
  startDate: Date;
  endDate: Date;
  eventsStartDate?: Date;
  eventsEndDate?: Date;
  allDayEvent?: boolean;
  logonEnabled: boolean;
  closed: boolean;
  courseEventDates?: CourseEvent[];
  learningContents: LearningContentModel[];
  examTypes: ExamTypeModel[];
  elogStatusCounts?: eLogStatusCounts;
  students?: UserModel[];
  isRegistered?: boolean;
  recurrencePattern?: RecurrencePattern;
  location?: CourseLocation;
  files?: FileModel[];
};

export type CourseCreateModel = {
  title: string;
  description: string;
  courseTypeId: number;
  recurrencePattern?: RecurrencePattern;
  startDate: Date;
  endDate: Date;
  allDayEvent?: boolean;
  duration: number;
  maxParticipants: number;
  link?: string;
  location?: CourseLocation;
  internalNotes?: string;
  logonEnabled: boolean;
  mandatory: boolean;
  closed: boolean;
  defaultTitlePicture?: number;
  titlePicture?: string;
  lecturerIds: number[];
  studentIds?: number[];
  courseEventDates?: EventDateCreateModel[];
  educationCourseIds: number[];
  learningContentIds: number[];
  examTypeIds: number[];
  files?: FileModel[];
};

export type CourseUpdateModel = {
  title: string;
  description: string;
  courseTypeId: number;
  recurrencePattern?: RecurrencePattern;
  startDate: Date;
  endDate: Date;
  allDayEvent?: boolean;
  duration: number;
  maxParticipants: number;
  link?: string;
  location?: CourseLocation;
  internalNotes?: string;
  logonEnabled: boolean;
  mandatory: boolean;
  closed: boolean;
  defaultTitlePicture?: number;
  titlePicture?: string;
  lecturerIds: number[];
  studentIds?: number[];
  courseEventDates?: EventDateUpdateModel[];
  educationCourseIds: number[];
  learningContentIds: number[];
  examTypeIds: number[];
  files?: FileModel[];
};

export interface CourseEvent extends EventDate {
  id?: number;
  courseId?: number;
  lecturers?: UserModel[];
  elogStatusCounts?: eLogStatusCounts;
  elogStatus?: eLogStatus;
  eLogs?: eLog[];
}

export interface CourseFilter {
  typeId?: number;
  lecturerId?: number;
  roomId?: number;
  registeredCourses?: boolean;
  finishedCourses?: boolean;
  openCourses?: boolean;
  pendingELogs?: boolean;
}

export interface CourseType {
  id: number;
  id_institute?: number;
  name: string;
}

export interface RecurrencePattern {
  id: number;
  frequency: recurrenceFrequency;
  interval: number;
  daysOfWeek?: string;
  occuranceDayInMonth?: number;
}

export enum recurrenceFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum CourseLocation {
  ONLINE = 'Online',
  ONSITE = 'Präsenz',
  HYBRID = 'Hybrid',
}
