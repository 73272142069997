import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { availableRolesSelectOptions } from 'src/app/constants/role-options.constant';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { EducationCourse } from 'src/app/models/education-course.model';
import { Role } from 'src/app/models/permission.model';
import { SelectOption } from 'src/app/models/select-option.model';
import { UserCreateModel } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EducationCourseService } from 'src/app/services/education-course.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import { emailValidator } from 'src/app/validators/email.validator';
import { read, utils } from 'xlsx';

@Component({
  selector: 'app-invite-member',
  templateUrl: './invite-member.component.html',
  styleUrl: './invite-member.component.scss',
})
export class InviteMemberComponent implements OnInit, OnDestroy {
  public initialFormValues: {};
  public inviteMode: string = 'manual';
  public userForm: FormGroup = new FormGroup({
    excelFile: new FormControl(null),
    users: new FormArray([]),
  });

  public allowedFileTypes: FileFormat[] = [
    { type: 'XLS', mimeType: 'application/vnd.ms-excel' },
    {
      type: 'XLSX',
      mimeType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  ];

  public Role = Role;

  public availableRoleSelectOptions: SelectOption[] =
    availableRolesSelectOptions;
  public availableEducationCoursesSelectOptions: SelectOption[] = [];

  // import from utils
  public isRequired = isRequired;

  private destroy$: Subject<void> = new Subject();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.userForm.value,
        this.initialFormValues
      ) &&
      this.usersFormArray.length > 0
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private loadingService: LoadingService,
    private cancellationService: CancellationService,
    private educationCourseService: EducationCourseService
  ) {}

  public ngOnInit(): void {
    this.getEducationCourses();

    if (this.usersFormArray.length === 0) {
      this.addUser();
    }
    this.initialFormValues = this.userForm.value;

    this.userForm
      .get('excelFile')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        value.forEach((file: any) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.parseExcelFile(e.target.result);
          };
          reader.readAsArrayBuffer(file);
        });
      });
  }

  /**
   * Retrieves the education courses of the institute.
   * @returns void
   */
  private getEducationCourses(): void {
    this.educationCourseService
      .getInstituteEducationCourses()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.availableEducationCoursesSelectOptions = response.body
            ? response.body?.map(
                (educationCourse: EducationCourse): SelectOption => {
                  return {
                    value: educationCourse.id,
                    label: educationCourse.title,
                  };
                }
              )
            : [];
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Bildungsgänge konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * Retrieves the role form control at the specified index.
   * @param index - The index of the form control in the usersFormArray.
   * @returns The role form control at the specified index.
   */
  public getRoleControl(index: number): FormControl {
    return this.usersFormArray.controls[index].get('role') as FormControl;
  }

  /**
   * Retrieves the education course form control at the specified index.
   * @param index - The index of the form control in the usersFormArray.
   * @returns The education course form control at the specified index.
   */
  public getEducationCourseControl(index: number): FormControl {
    return this.usersFormArray.controls[index].get(
      'educationCourse'
    ) as FormControl;
  }

  /**
   * Handles the form submission.
   * If the form is valid, it sends the invitation to the users.
   * @returns void
   */
  public onSubmit(): void {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.userForm
      )
    ) {
      return;
    }

    this.loadingService.show();
    this.removeEmptyUserFormGroup();
    const users: UserCreateModel[] = this.usersFormArray.value?.map(
      (user: any): UserCreateModel => {
        return {
          name: {
            firstName: user.firstName,
            lastName: user.lastName,
          },
          email: user.email?.toLowerCase(),
          roleId: user.role,
          educationCourseId: user.educationCourse,
        };
      }
    );

    let completedInvitations = 0;
    let successfulEmails = [];
    let errorEmails = [];

    users.forEach((user, index) => {
      this.userService
        .inviteInstituteUser(
          this.userService.currentUser?.currentInstituteId,
          user
        )
        .pipe(first())
        .subscribe({
          next: response => {
            successfulEmails.push(user.email);
            completedInvitations++;

            if (completedInvitations === users.length) {
              this.loadingService.hide();
              displayFinalAlerts();
            }
          },
          error: error => {
            completedInvitations++;
            errorEmails.push(user.email);

            if (error.status === 409) {
              if (error?.error?.errors?.UserAlreadyRegisteredException) {
                this.setEmailAlreadyRegisteredError(index);
              } else {
                this.setEmailExistsInOtherInstituteError(index);
              }
            } else {
            }

            if (completedInvitations === users.length) {
              this.loadingService.hide();
              displayFinalAlerts();
            }
          },
        });
    });

    const displayFinalAlerts = () => {
      // Display success alert if there are successful invitations
      if (successfulEmails.length > 0) {
        const successMessage =
          successfulEmails.length > 1
            ? `Die Einladungen wurden erfolgreich versendet an: ${successfulEmails.join(', ')}.`
            : `Die Einladung wurde erfolgreich versendet an: ${successfulEmails[0]}.`;
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          successMessage,
          20000
        );
      }

      // Display error alert if there are failed invitations
      if (errorEmails.length > 0) {
        const errorMessage = `Einladung(en) konnten nicht versendet werden für: ${errorEmails.join(', ')}.`;
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          errorMessage,
          20000
        );
      }

      this.initialFormValues = this.userForm.value;

      if (this.userForm.invalid) {
        this.removeUsersWithSuccessfullInvitations();
        return;
      }

      this.onCancel();
    };
  }

  // getter for eventList FormArray
  get usersFormArray() {
    return this.userForm.controls['users'] as FormArray;
  }

  /**
   * removeUser
   * removes a user from the usersFormArray
   * @param index
   * @returns void
   */
  public removeUser(index: number): void {
    this.usersFormArray.removeAt(index);
  }

  /**
   * addUser
   * adds a new user to the usersFormArray
   * @returns void
   */
  public addUser(): void {
    this.usersFormArray.push(
      new FormGroup({
        firstName: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
        lastName: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
        email: new FormControl('', [Validators.required, emailValidator()]),
        role: new FormControl(null, [Validators.required]),
        educationCourse: new FormControl(null),
      })
    );
  }

  /**
   * parseExcelFile
   * parses the excel file and adds the users to the usersFormArray
   * @param file
   * @returns void
   */
  private parseExcelFile(file: any): void {
    this.removeEmptyUserFormGroup();
    const data = new Uint8Array(file);
    const workbook = read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0]; // Read first sheet
    const worksheet = workbook.Sheets[sheetName];
    const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
    const headers = jsonData.shift() as string[]; // Remove and store the headers
    const objectData = jsonData.map(row => {
      const rowObject = {};
      headers.forEach((header, index) => {
        switch (header) {
          case 'Vorname':
            header = 'firstName';
            break;
          case 'Nachname':
            header = 'lastName';
            break;
          case 'E-Mail':
            header = 'email';
            break;
          case 'Rolle':
            header = 'role';
            break;
          default:
            this.alertService.showErrorAlert(
              'Fehler',
              'Die Excel-Datei hat ein falsches Format!'
            );
            return;
        }

        rowObject[header] = row[index];
      });
      return rowObject;
    });

    objectData.forEach((user: any) => {
      switch (user.role) {
        case 'Verwaltung':
          user.role = Role.ADMINISTRATOR;
          break;
        case 'Lehrpersonal':
          user.role = Role.LECTURER;
          break;
        case 'Kandidat':
          user.role = Role.STUDENT;
          break;
        default:
          user.role = null;
      }
      this.usersFormArray.push(
        new FormGroup({
          firstName: new FormControl(user.firstName, [
            Validators.required,
            Validators.maxLength(50),
          ]),
          lastName: new FormControl(user.lastName, [
            Validators.required,
            Validators.maxLength(50),
          ]),
          email: new FormControl(user.email, [
            Validators.required,
            emailValidator(),
          ]),
          role: new FormControl(user.role, Validators.required),
          educationCourse: new FormControl(''),
        })
      );
    });
  }

  /**
   * removeEmptyUserFormGroup
   * remove empty formGroups from the usersFormArray
   * @returns void
   */
  private removeEmptyUserFormGroup(): void {
    this.usersFormArray.controls.forEach((control, index) => {
      if (
        control.get('firstName').value === '' &&
        control.get('lastName').value === '' &&
        control.get('email').value === '' &&
        control.get('role').value === '' &&
        control.get('educationCourse').value === ''
      ) {
        this.removeUser(index);
      }
    });
  }

  /**
   * Removes all users from the usersFormArray that have been successfully invited
   * @returns void
   */
  private removeUsersWithSuccessfullInvitations(): void {
    this.usersFormArray.controls.forEach((control, index) => {
      if (
        !control.get('email').hasError('emailExistsInOtherInstitute') &&
        !control.get('email').hasError('emailAlreadyRegistered')
      ) {
        this.removeUser(index);
      }
    });
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.loadingService.isLoading() || this.usersFormArray.length === 0) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.userForm.value,
      this.initialFormValues
    );
  }

  /**
   * onCancel
   * navigate back to the previous page
   * @returns void
   */
  public onCancel(): void {
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  /**
   * Sets the emailExistsInOtherInstitute error to the email formControl of the user at the given index
   * @param index the index of the user in the usersFormArray
   * @returns void
   */
  private setEmailExistsInOtherInstituteError(index: number): void {
    const emailControl = this.usersFormArray.controls[index].get('email');

    if (!emailControl) {
      return;
    }
    emailControl.setErrors({ emailExistsInOtherInstitute: true });
    emailControl.markAsTouched();
    emailControl.markAsDirty();
  }

  /**
   * Sets the setAlreadyRegisteredError error to the email formControl of the user at the given index
   * @param index the index of the user in the usersFormArray
   * @returns void
   */
  private setEmailAlreadyRegisteredError(index: number): void {
    const emailControl = this.usersFormArray.controls[index].get('email');

    if (!emailControl) {
      return;
    }
    emailControl.setErrors({ emailAlreadyRegistered: true });
    emailControl.markAsTouched();
    emailControl.markAsDirty();
  }

  /**
   * cancel all requests and unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
