import { RoomModel } from './room.model';
import { UserModel } from './user.model';

export enum EventDateType {
  COURSE = 1,
  PATIENT_SESSION = 2,
  SUPERVISION = 3,
  GROUP_SUPERVISION = 4,
  APPOINTMENT_EVENT = 5,
  APPOINTMENT_OTHER = 6,
  APPOINTMENT_HOLIDAY = 7,
  EXAM = 8,
}

export type EventDate = {
  id?: number;
  startDate: Date;
  endDate?: Date;
  room?: RoomModel;
};

export type EventDateCreateModel = {
  startDate: string;
  endDate: string;
  roomId?: number;
};

export type EventDateUpdateModel = {
  id: number;
  startDate: string;
  endDate: string;
  roomId?: number;
};

export interface InstituteEvent {
  name: string;
  type: EventDateType;
  currentRoom: RoomModel;
  organizer: UserModel;
  startDate: Date;
  endDate: Date;
  participants: number;
  numberOfLecturers: number;
  eventDates: EventDate[];
}
