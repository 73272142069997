import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import {
  EducationCourse,
  Regulation,
} from 'src/app/models/education-course.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EducationCourseService } from 'src/app/services/education-course.service';

@Component({
  selector: 'app-education-course-details',
  templateUrl: './education-course-details.component.html',
  styleUrl: './education-course-details.component.scss',
})
export class EducationCourseDetailsComponent implements OnInit, OnDestroy {
  public educationCourse: EducationCourse;
  public isLoading = true;

  private destroy$: Subject<void> = new Subject<void>();

  public Regulation = Regulation;

  constructor(
    private educationCourseService: EducationCourseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private location: Location
  ) {}

  public ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params.id) {
          this.getData(+atob(params.id));
        }
      });
  }

  /**
   * getData
   * get the education course data
   * @param educationCourseId
   * @returns void
   */
  private getData(educationCourseId: number): void {
    this.educationCourseService
      .getEducationCourseById(educationCourseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.educationCourse =
            await this.educationCourseService.parseBackendEducationCourse(
              response.body
            );

          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Daten vom Bildungsgang konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
          this.location.back();
        },
      });
  }

  /**
   * onEdit
   * navigate to edit page
   */
  public onEdit() {
    this.router.navigate(['../edit', btoa(String(this.educationCourse.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onGoBack
   * navigate back to previous page
   */
  public onGoBack() {
    this.location.back();
  }

  /**
   * unsubscribe from all subscriptions and cancel all requests
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
