import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { FileModel } from 'src/app/models/file.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FileService } from 'src/app/services/file.service';
import { UserService } from 'src/app/services/user.service';
import {
  formatBytes,
  getFileNameOnly,
  getFileType,
} from 'src/app/utils/file.utils';
import { getFullName } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnDestroy {
  private instituteId = this.userService.currentUser?.currentInstituteId;
  public dataSource: MatTableDataSource<FileModel> =
    new MatTableDataSource<FileModel>();
  public displayedColumns = [
    'file',
    'creator',
    'modifiedDate',
    'fileSize',
    'fileType',
    'actions',
  ];
  public isLoading = true;
  public isMobile = false;
  public isTablet = false;
  public showSearchBar = false;
  public searchForm: FormGroup = new FormGroup({
    searchText: new FormControl(''),
  });

  // for permission
  public Feature = Feature;
  public Permission = Permission;

  // import from utils
  public getFullName = getFullName;
  public getFileType = getFileType;
  public getFileNameOnly = getFileNameOnly;
  public formatBytes = formatBytes;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private fileService: FileService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private mobileUiService: MobileUiService
  ) {}

  public ngOnInit(): void {
    this.initTable();
    this.viewChanges();
  }

  /**
   * sets isMobile and isTablet depending on the current view
   * sets showSearchBar to true if the current view is desktop
   * sets the displayed columns depending on the current view
   * @returns void
   */
  private viewChanges() {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.initTableColumns(currentView);

        this.isMobile = currentView === 'mobile';
        this.isTablet = currentView === 'tablet';

        this.showSearchBar = currentView === 'desktop';
      });
  }

  /**
   * set the columns for the table depending on the current view
   * @param currentView the current view
   * @returns void
   */
  private initTableColumns(currentView: string): void {
    if (currentView === 'mobile') {
      this.displayedColumns = ['file', 'actions'];
    } else if (currentView === 'tablet') {
      this.displayedColumns = ['file', 'creator', 'fileSize', 'actions'];
    } else {
      this.displayedColumns = [
        'file',
        'creator',
        'modifiedDate',
        'fileSize',
        'fileType',
        'actions',
      ];
    }
  }

  /**
   * initializes the sorting and pagination of the table
   * inits the table data with the documents of the current institute
   * @returns void
   */
  private initTable(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'file':
          return item.name;
        case 'creator':
          return (
            item.creator?.name?.firstName + ' ' + item.creator?.name?.lastName
          );
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.getFiles();
  }

  /**
   * retrieves all files of the current institute and initializes the table data
   * @returns void
   */
  private getFiles(): void {
    this.fileService
      .getAllInstituteFiles(this.instituteId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          const files: FileModel[] = response.body
            ? await Promise.all(
                response.body.map(
                  async (file: FileModel): Promise<FileModel> =>
                    await this.fileService.parseBackendFile(file)
                )
              )
            : [];

          files?.forEach(file => {
            file.type = file.name.split('.').pop();
          });

          this.dataSource.data = files;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt! ',
            'Die Dateien konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * filter files by search text
   * @param event
   * @returns void
   */
  public applySearch(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * onOpen
   * open the file
   * @param file
   * @returns void
   */
  public onOpen(file: FileModel): void {
    this.fileService.openInstituteFile(this.instituteId, file.id);
  }

  /**
   * onDownload
   * download the file
   * @param file
   * @returns void
   */
  public onDownload(file: FileModel): void {
    this.fileService.downloadInstituteFile(this.instituteId, file.id);
  }

  /**
   * onDelete
   * delete the file
   * @param file
   */
  public onDelete(file: FileModel) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Datei löschen',
        message: `Möchten Sie die Datei "${file.name}" wirklich löschen?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(dialogResult => {
        if (!dialogResult) {
          return;
        }
        this.fileService
          .deleteFile(this.instituteId, file.id)
          .pipe(first())
          .subscribe({
            next: () => {
              this.alertService.showSuccessAlert(
                'Das hat geklappt! ',
                'Die Datei wurde erfolgreich gelöscht.'
              );
              this.dataSource.data = this.dataSource.data.filter(
                item => item.id !== file.id
              );
            },
            error: () => {
              this.alertService.showErrorAlert(
                'Das hat leider nicht geklappt! ',
                'Die Datei konnte nicht gelöscht werden.'
              );
            },
          });
      });
  }

  /**
   * onUpload
   * navigate to the upload page
   * @returns void
   */
  public onUpload(): void {
    this.router.navigate(['create'], { relativeTo: this.activatedRoute });
  }

  /**
   * onEdit
   * navigate to the edit page
   * @param file
   * @returns void
   */
  public onEdit(file: FileModel): void {
    this.router.navigate(['edit', btoa(file.id.toString())], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * cancel all requests and unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
