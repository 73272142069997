<div
  class="table-sort-icon"
  matTooltip="Unsortiert"
  *ngIf="
    (sort && sort.direction == '') ||
    (sort?.active != columnName &&
      (sort?.direction == 'asc' || sort?.direction == 'desc'))
  ">
  <i class="fa-light fa-arrow-up-arrow-down"></i>
</div>

<div
  class="table-sort-icon"
  matTooltip="Aufsteigend sortiert"
  *ngIf="sort && sort.active == columnName && sort.direction == 'asc'">
  <i class="fa-light fa-arrow-up-short-wide"></i>
</div>

<div
  class="table-sort-icon"
  matTooltip="Absteigend sortiert"
  *ngIf="sort && sort.active == columnName && sort.direction == 'desc'">
  <i class="fa-light fa-arrow-down-short-wide"></i>
</div>
