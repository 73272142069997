import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { Observable } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
})
export class LoadingComponent implements OnInit {
  public isLoading: Observable<boolean>;
  public loadingMessage: Observable<string>;

  public lottieConfig: AnimationOptions = {
    path: '/assets/lottie/eleguide-loading-animation.json',
  };

  constructor(private loadingService: LoadingService) {
    this.isLoading = this.loadingService.isLoading$;
    this.loadingMessage = this.loadingService.loadingMessage$;
  }

  ngOnInit(): void {}
}
