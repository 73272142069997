import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-view-profile-picture-dialog',
  templateUrl: './view-profile-picture-dialog.component.html',
  styleUrl: './view-profile-picture-dialog.component.scss',
})
export class ViewProfilePictureDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ViewProfilePictureDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { image: any }
  ) {}
}
