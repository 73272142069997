import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * timeRangeValidator
 * checks if the start time is before the end time in a formGroup
 * if not, it sets the timeRangeInvalid error on the formGroup and the start and end time formControls
 * @param startTimeKey key of the start time formControl
 * @param endTimeKey key of the end time formControl
 * @returns ValidatorFn
 */
export function timeRangeValidator(
  startTimeKey: string,
  endTimeKey: string
): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: any } | null => {
    const startTimeControl = formGroup.get(startTimeKey);
    const endTimeControl = formGroup.get(endTimeKey);

    if (
      !startTimeControl ||
      !endTimeControl ||
      !startTimeControl.value ||
      !endTimeControl.value
    ) {
      return null;
    }

    const [startHour, startMinute] = startTimeControl.value
      ?.split(':')
      ?.map(Number);
    const [endHour, endMinute] = endTimeControl.value?.split(':')?.map(Number);

    const startTotalMinutes = startHour * 60 + startMinute;
    const endTotalMinutes = endHour * 60 + endMinute;

    if (
      startTotalMinutes !== null &&
      endTotalMinutes !== null &&
      startTotalMinutes >= endTotalMinutes
    ) {
      startTimeControl.setErrors({ timeRangeInvalid: true });
      endTimeControl.setErrors({ timeRangeInvalid: true });
      return { timeRangeInvalid: true };
    }
    if (startTimeControl.hasError('timeRangeInvalid')) {
      startTimeControl.setErrors(null);
    }
    if (endTimeControl.hasError('timeRangeInvalid')) {
      endTimeControl.setErrors(null);
    }
    return null;
  };
}
