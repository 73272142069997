<div class="component-content">
  <div class="header">
    <div class="header-title">
      <div *ngIf="!isLoading" class="user-container-header">
        <img
          src="{{
            user.profilePicture ?? '../../../../../assets/img/user.jpg'
          }}"
          alt
          class="user-icon-image" />
        <div class="user-info">
          <h1 class="hasomed-text-h1">
            {{ getFullName(user) }}
          </h1>
          <div class="flex-r gap-16 align-center">
            <h3 class="hasomed-text-h3">
              {{ user?.userIdentifier ?? '-' }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="header-buttons">
      <button
        (click)="goBack()"
        class="hasomed-text-button hasomed-button-white-grey">
        <i class="fa-light fa-chevron-left hasomed-button-icon"></i>
        <span class="hasomed-button-text"> Zurück zur Übersicht </span>
      </button>
    </div>
  </div>
  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content-details">
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Anrede und Titel</span>
        <span class="label-description">
          Anrede und Titel des Lehrpersonals.
        </span>
      </div>
      <div
        *ngIf="
          !user?.name?.genderTitle && !user?.name?.academicTitle;
          else titles
        "
        class="detail-value">
        Noch nicht hinterlegt.
      </div>
      <ng-template #titles>
        <div class="detail-value">
          {{ user?.name?.genderTitle }} {{ user?.name?.academicTitle }}
        </div>
      </ng-template>
    </div>
    <mat-divider></mat-divider>

    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Name</span>
        <span class="label-description">
          Vorname und Nachname des Lehrpersonals.
        </span>
      </div>
      <div class="detail-value">
        {{ user?.name?.firstName }} {{ user?.name?.lastName }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Email -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">E-Mail Adresse</span>
        <span class="label-description">Ihre E-Mail Adresse</span>
      </div>
      <div class="detail-value">
        <i class="fa-light fa-envelope"></i>
        <span>{{ user?.email }}</span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- profilePicture -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Profilfoto</span>
        <span class="label-description"> Profilfoto des Lehrpersonals. </span>
      </div>
      <div class="detail-value">
        <img
          #userIconImage
          [attr.src]="
            user?.profilePicture ?? '../../../../../assets/img/user.jpg'
          "
          (click)="openProfilePictureDialog()"
          id="user-icon-image"
          class="uploaded-image"
          alt="Profilfoto des Lehrpersonals"
          matTooltip="Profilfoto des Lehrpersonals" />
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- additional qualifications -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Zusatzqualifikationen </span>
        <span class="label-description">
          Zusatzqualifikationen des Lehrpersonals.
        </span>
      </div>
      <div class="detail-value">
        <span>
          {{ getAllAdditionalQualifications(user) ?? 'Nicht hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- medicalAuthorizationOnly -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Ausschließlich Zulassung für Ärzte </span>
        <span class="label-description">
          Ausschließlich ärztliche Zulassung des Lehrpersonals.
        </span>
      </div>
      <div class="detail-value">
        <span>
          {{ user?.medicalAuthorizationOnly ? 'Ja' : 'Nein' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- expertise -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Fachkunde</span>
        <span class="label-description"> Fachkunde des Lehrpersonals. </span>
      </div>
      <div class="detail-value">
        <span>
          {{ getAllExpertises(user) ?? 'Nicht hinterlegt.' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- procedure -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Richtlinienverfahren </span>
        <span class="label-description">
          Richtlinienverfahren des Lehrpersonals.
        </span>
      </div>
      <div class="detail-value">
        <span>
          {{ getAllProcedures(user) ?? 'Nicht hinterlegt.' }}
        </span>
      </div>
    </div>
  </div>
</div>
