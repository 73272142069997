<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Patiententermin</h1>
      <h3 class="hasomed-text-h3">Schauen Sie sich den Patiententermin an.</h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onGoBack()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Zurück</span>
      </button>
      <button
        *ngIf="
          !isLecturer &&
          !isLoading &&
          !currentEventDate?.treatmentCase?.isCompleted
        "
        (click)="onEditPatientAppointment()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-pen hasomed-button-icon"></i>
        <span class="hasomed-button-text">Bearbeiten</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>

  <div *ngIf="!isLoading" class="main-content-details">
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Behandlungsfall</span>
        <span class="label-description">
          Der Behandlungsfall für diesen Patiententermin.
        </span>
      </div>
      <div class="detail-value">
        Patient: Chiffre ({{ currentEventDate.treatmentCase.patientChiffre }})
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Begleitpersonen</span>
        <span class="label-description">
          Die Begleitpersonen für diesen Patiententermin.
        </span>
      </div>
      <div class="detail-value">
        {{
          getAllPatientEventDateAccompanyingPersons(currentEventDate) ?? 'Keine'
        }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Status</span>
        <span class="label-description"> Aktueller Status des Termins. </span>
      </div>
      <div class="detail-value">
        <ng-container *ngIf="currentEventDate.isCanceled; else isFutureDate">
          <div class="label label-white">
            <i class="fa-light fa-circle-xmark label-icon"></i>
            <span class="hasomed-text-small label-text">abgesagt</span>
          </div>
        </ng-container>
        <ng-template #isFutureDate>
          <ng-container
            *ngIf="
              dateIsInFuture(currentEventDate.startDate);
              else isSupervised
            ">
            <div class="label label-white">
              <i class="fa-light fa-circle-dashed label-icon"></i>
              <span class="hasomed-text-small label-text">geplant</span>
            </div>
          </ng-container>
        </ng-template>
        <ng-template #isSupervised>
          <ng-container
            *ngIf="currentEventDate.isSupervised; else unsupervised">
            <div class="label label-green">
              <i class="fa-light fa-circle-check label-icon"></i>
              <span class="hasomed-text-small label-text">supervidiert</span>
            </div>
          </ng-container>
        </ng-template>
        <ng-template #unsupervised>
          <div class="label label-white">
            <i class="fa-light fa-circle-dashed label-icon"></i>
            <span class="hasomed-text-small label-text">
              nicht supervidiert
            </span>
          </div>
        </ng-template>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Dauer des Termins in Zeiteinheiten </span>
        <span class="label-description">
          Eine Einheit entspricht 50 Minuten.
        </span>
      </div>
      <div class="detail-value">
        {{ currentEventDate.durationInTimeUnits }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- date and time -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Datum des Patiententermins </span>
        <span class="label-description">
          An diesem Datum findet der Termin statt.
        </span>
      </div>
      <div class="detail-value">
        <div class="flex-r gap-8 flex-wrap">
          <div class="day-box">
            <i class="fa-light fa-calendar"></i>
            <span>
              {{ currentEventDate.startDate | date: 'dd.MM.yyyy' }}
            </span>
          </div>
          <div class="time-boxes">
            <div class="time-box">
              <i class="fa-light fa-clock"></i>
              <span>
                {{ currentEventDate.startDate | date: 'HH:mm' }}
              </span>
            </div>
            <div class="time-box">
              <i class="fa-light fa-clock"></i>
              <span>
                {{ currentEventDate.endDate | date: 'HH:mm' }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- room and location -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Ort der Supervision</span>
        <span class="label-description">
          In diesem Raum oder auf dieser Online Video Plattform findet der
          Termin statt.
        </span>
      </div>
      <div class="detail-value">
        <div class="flex-c gap-24">
          <span> Raum: {{ currentEventDate?.room?.name ?? '-' }} </span>
          <div class="flex-r gap-16 align-center">
            <span>
              URL für Video-Meeting:
              {{ currentEventDate?.videoMeetingLink ?? '-' }}
            </span>
            <button
              *ngIf="currentEventDate?.videoMeetingLink"
              (click)="
                this.clipboardService.copyToClipboard(
                  'URL für Video-Meeting',
                  currentEventDate?.videoMeetingLink
                )
              "
              class="hasomed-text-button hasomed-button-white-grey copy-button">
              <i class="fa-light fa-link hasomed-button-icon"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
