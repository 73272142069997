<div class="component-content">
  <div class="header">
    <div class="header-title" *ngIf="!isMobile">
      <h1 class="hasomed-text-h1">Supervisionsübersicht</h1>
      <h3 class="hasomed-text-h3">
        {{
          isAdministrator
            ? 'Sehen Sie alle Supervisionen ein.'
            : 'Sehen Sie Ihre Supervisionen ein.'
        }}
      </h3>
    </div>
    <form [formGroup]="searchForm" class="header-buttons">
      <div class="hasomed-search" *ngIf="showSearchBar">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          (keyup)="applySearch($event)"
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>
      <div class="filter-buttons">
        <button
          *ngIf="isMobile || isTablet"
          id="openSearchButton"
          [class.hasomed-button-active]="showSearchBar"
          [class.hasomed-button-primary]="
            searchForm.get('searchText').value !== ''
          "
          (click)="showSearchBar = !showSearchBar"
          class="hasomed-text-button hasomed-button-white-grey">
          <i class="fa-light fa-search hasomed-button-icon"></i>
        </button>

        <button
          id="filterButton"
          [ngClass]="{
            'hasomed-button-primary': hasActiveFilterValue(
              supervisionFilter,
              true
            ),
            'hasomed-button-white-grey': !hasActiveFilterValue(
              supervisionFilter,
              true
            ),
            'hasomed-button-active': filterOpened,
          }"
          [matMenuTriggerFor]="filterMenu"
          (onMenuOpen)="filterOpened = true"
          (onMenuClose)="filterOpened = false"
          type="button"
          class="hasomed-text-button hasomed-button-white-grey">
          <i class="fa-light fa-filter-list hasomed-button-icon"></i>
          <span class="hasomed-button-text">Filter</span>
        </button>

        <!-- Filter Menu -->
        <mat-menu #filterMenu="matMenu" class="filter-overlay">
          <app-filter
            *ngIf="filterOpened"
            [filters]="supervisionFilter"
            (filterChanged)="supervisionFilterChanged($event)">
          </app-filter>
        </mat-menu>
      </div>

      <button
        *ngIf="!isLecturer && !isAdministrator"
        (click)="createSupervision()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-folder-plus hasomed-button-icon"></i>
        <span class="hasomed-button-text">
          {{ isMobile || isTablet ? '' : 'Neue' }} Supervision anlegen
        </span>
      </button>
    </form>
  </div>
  <div class="main-content">
    <!-- Filter Tags -->
    <app-filter-tags
      [filters]="supervisionFilter"
      (filterChanged)="supervisionFilterChanged($event)">
    </app-filter-tags>
    <div
      [class.table-bicolor]="dataSource?.filteredData?.length > 1"
      class="table">
      <table [dataSource]="dataSource" mat-table matSort>
        <!-- date column -->
        <ng-container matColumnDef="date">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Wann
            <app-custom-table-sort-icon [sort]="sort" columnName="date">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let row; dataSource: dataSource"
            (click)="viewSupervisionEventDate(row)"
            class="gray-900 max-width-400 cell-overflow-hidden cursor-pointer"
            mat-cell>
            <span class="hasomed-text-body text-overflow-hidden">
              {{ row.startDate | date: 'dd.MM.yyyy, HH:mm' }} Uhr
            </span>
          </td>
        </ng-container>

        <!-- room Column -->
        <ng-container matColumnDef="room">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Wo
            <app-custom-table-sort-icon [sort]="sort" columnName="room">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{ row.room?.name ?? '-' }}
          </td>
        </ng-container>

        <!-- supervisor column -->
        <ng-container matColumnDef="supervisor">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Supervisor
            <app-custom-table-sort-icon [sort]="sort" columnName="supervisor">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let row; dataSource: dataSource"
            class="lecturer-cell"
            mat-cell>
            <div class="flex-r cell-overflow-hidden">
              <span
                [matTooltip]="getFullName(row?.supervisor, false)"
                class="text-overflow-hidden">
                {{ getFullName(row?.supervisor, false) ?? '-' }}
              </span>
            </div>
          </td>
        </ng-container>

        <!-- treatment cases column -->
        <ng-container matColumnDef="treatmentCases">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Behandlungsfälle
            <app-custom-table-sort-icon
              [sort]="sort"
              columnName="treatmentCases">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{
              row.treatmentCases?.length && row.treatmentCases?.length == 1
                ? row.treatmentCases?.length + ' Behandlungsfall'
                : row.treatmentCases?.length + ' Behandlungsfälle'
            }}
          </td>
        </ng-container>

        <!-- student column -->
        <ng-container matColumnDef="student">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Kandidat
            <app-custom-table-sort-icon [sort]="sort" columnName="student">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{ getFullName(row.student) ?? '-' }}
          </td>
        </ng-container>

        <!-- type column -->
        <ng-container matColumnDef="type">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Terminart
            <app-custom-table-sort-icon [sort]="sort" columnName="type">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{ row.type }}
          </td>
        </ng-container>

        <!-- status column -->
        <ng-container matColumnDef="status">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Status
            <app-custom-table-sort-icon [sort]="sort" columnName="status">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            <ng-container *ngIf="row.isCanceled; else isFuture">
              <div class="label label-white">
                <i class="fa-light fa-circle-xmark label-icon"></i>
                <span class="hasomed-text-small label-text">abgesagt</span>
              </div>
            </ng-container>
            <ng-template #isFuture>
              <ng-container *ngIf="dateIsInFuture(row.endDate); else isChecked">
                <div class="label label-white">
                  <i class="fa-light fa-circle-dashed label-icon"></i>
                  <span class="hasomed-text-small label-text">geplant</span>
                </div>
              </ng-container>
            </ng-template>
            <ng-template #isChecked>
              <ng-container *ngIf="row.isChecked; else unchecked">
                <div class="label label-green">
                  <i class="fa-light fa-circle-check label-icon"></i>
                  <span class="hasomed-text-small label-text">geprüft</span>
                </div>
              </ng-container>
            </ng-template>
            <ng-template #unchecked>
              <div class="label label-white">
                <i class="fa-light fa-circle-dashed label-icon"></i>
                <span class="hasomed-text-small label-text">ungeprüft</span>
              </div>
            </ng-template>
          </td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="center-header-cell" mat-header-cell>
            {{ isMobile ? 'Akt.' : 'Aktionen' }}
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            <div class="table-button-icons">
              <button
                #t="matMenuTrigger"
                [matMenuTriggerFor]="actionsMenu"
                type="button"
                class="table-button-icon"
                matTooltip="Supervision Aktionen">
                <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button
                  (click)="viewSupervisionEventDate(row)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i
                      class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Anzeigen</span>
                  </div>
                </button>

                <ng-container
                  *ngIf="
                    !row.isChecked &&
                    (isLecturer || isAdministrator) &&
                    !row.isCanceled &&
                    !dateIsInFuture(row.endDate)
                  ">
                  <mat-divider class="menu-divider"></mat-divider>
                  <button
                    (click)="onCheckEventDate(row)"
                    type="button"
                    mat-menu-item>
                    <div class="action-menu-item">
                      <i class="fa-light fa-circle-check icon-sm"></i>
                      <span class="hasomed-text-body gray-600">Geprüft</span>
                    </div>
                  </button>
                </ng-container>

                <ng-container *ngIf="!row.isCanceled && !row.isChecked">
                  <mat-divider class="menu-divider"></mat-divider>
                  <button
                    (click)="onCancelEventDate(row)"
                    type="button"
                    mat-menu-item>
                    <div class="action-menu-item">
                      <i class="fa-light fa-cancel icon-sm"></i>
                      <span class="hasomed-text-body gray-600">Absagen</span>
                    </div>
                  </button>
                </ng-container>

                <ng-container *ngIf="row.isCanceled">
                  <mat-divider class="menu-divider"></mat-divider>
                  <button
                    (click)="onConfirmEventDate(row)"
                    type="button"
                    mat-menu-item>
                    <div class="action-menu-item">
                      <i class="fa-light fa-circle-check icon-sm"></i>
                      <span class="hasomed-text-body gray-600">Zusagen</span>
                    </div>
                  </button>
                </ng-container>

                <ng-container *ngIf="!isLecturer">
                  <mat-divider class="menu-divider"></mat-divider>
                  <button
                    (click)="editSupervision(row)"
                    mat-menu-item
                    type="button">
                    <div class="action-menu-item">
                      <i class="fa-light fa-pen icon-sm"></i>
                      <span class="hasomed-text-body gray-600">Bearbeiten</span>
                    </div>
                  </button>
                </ng-container>

                <ng-container *ngIf="!isLecturer">
                  <mat-divider class="menu-divider"></mat-divider>
                  <button
                    (click)="onDeleteEventDate(row)"
                    type="button"
                    mat-menu-item>
                    <div class="action-menu-item">
                      <i class="fa-light fa-trash-can icon-sm"></i>
                      <span class="hasomed-text-body gray-600">Löschen</span>
                    </div>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="displayedColumns.length">
              <div *ngIf="isLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!isLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Es wurde keine Supervision gefunden.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="isMobile ? 0 : 1"
        custom-paginator
        aria-label="Select page"></mat-paginator>
    </div>
    <div class="vertical-spacer"></div>
  </div>
</div>
