<div
  class="flex-c news-article"
  (click)="onSelectNewsArticle(newsArticle)"
  [class.selected]="isSelected"
  [matTooltip]="!isSelected ? 'Mehr lesen' : ''">
  <div
    class="news-image"
    [class.border]="!isSelected"
    [style.background]="
      newsArticle.image
        ? 'url(' +
          newsArticle.image +
          ') no-repeat, lightgray 50% / cover no-repeat'
        : 'lightgray'
    ">
    <div
      class="label-no-icon label-news-image label-white"
      *ngIf="newsArticle.newsArticleType.name">
      <span class="label-text"> {{ newsArticle.newsArticleType.name }}</span>
    </div>
    <div
      class="label-no-icon label-news-image"
      [ngClass]="{
        'label-green': newsArticle.isPublished,
        'label-red': !newsArticle.isPublished,
      }"
      *ngIf="showLabels">
      <span class="label-text">
        {{ newsArticle.isPublished ? 'Veröffentlicht' : 'Entwurf' }}
      </span>
    </div>
  </div>
  <div class="news-text">
    <div class="hasomed-text-h3 news-text-title">{{ newsArticle.title }}</div>
    <div
      class="hasomed-text-small news-text-content"
      [innerHTML]="newsArticle.content | stripHtmlAndTruncate: 90"></div>
    <div class="news-text-more">
      <div class="news-text-more-link">
        <i class="fa-light fa-arrow-right"></i>
        <span>{{ isSelected ? 'weniger lesen' : 'mehr lesen' }}</span>
      </div>
    </div>
  </div>
  <div class="news-author">
    <img
      class="news-author-icon"
      #userIconImage
      [attr.src]="
        newsArticle.creator.profilePicture ?? '/assets/img/user.jpg'
      " />
    <div class="news-author-infos">
      <div class="hasomed-text-small news-author-name">
        {{ getFullName(newsArticle.creator) }}
      </div>
      <div class="hasomed-text-x-small news-author-created-at">
        <span
          *ngIf="
            newsArticle.timePublished && newsArticle.isPublished;
            else timeModified
          ">
          am {{ newsArticle.timePublished | date: 'dd.MM.yyyy' }} um
          {{ newsArticle.timePublished | date: 'HH:mm' }} Uhr
        </span>
        <ng-template #timeModified>
          <span *ngIf="newsArticle.timeModified; else timeCreated">
            am {{ newsArticle.timeModified | date: 'dd.MM.yyyy' }} um
            {{ newsArticle.timeModified | date: 'HH:mm' }} Uhr
          </span>
        </ng-template>
        <ng-template #timeCreated>
          <span>
            am {{ newsArticle.timeCreated | date: 'dd.MM.yyyy' }} um
            {{ newsArticle.timeCreated | date: 'HH:mm' }} Uhr
          </span>
        </ng-template>
      </div>
    </div>
  </div>
</div>
