import { AbstractControl, ValidatorFn } from '@angular/forms';

export function ibanValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let iban = control.value;

    if (!iban) {
      return null; // Wenn das Feld leer ist, wird die Validierung übersprungen
    }

    // Entferne Leerzeichen und führende Nullen
    iban = iban.replace(/\s+/g, '').toUpperCase();

    // Prüfe die IBAN-Länge für das jeweilige Land
    const ibanLengths: { [key: string]: number } = {
      DE: 22,
    };

    const country = iban.slice(0, 2);
    if (!ibanLengths[country] || iban.length !== ibanLengths[country]) {
      return { invalidIBAN: true };
    }

    // Verschiebe die ersten 4 Zeichen ans Ende der IBAN
    iban = iban.slice(4) + iban.slice(0, 4);

    // Ersetze Buchstaben durch Zahlen (A=10, B=11, ..., Z=35)
    const numericIBAN = iban.replace(/[A-Z]/g, char =>
      (char.charCodeAt(0) - 55).toString()
    );

    // Prüfziffer berechnen
    const remainder = BigInt(numericIBAN) % BigInt(97);

    const valid = remainder === BigInt(1);

    if (valid) {
      return null; // IBAN ist gültig
    } else {
      return { invalidIBAN: true }; // IBAN ist ungültig
    }
  };
}
