<form [formGroup]="roleForm" class="flex-c gap-40 dialog-content">
  <div class="flex-c gap-24">
    <div class="flex-r gap-24 justify-space-between">
      <h1 class="hasomed-text-h1">Rolle bearbeiten</h1>
      <button (click)="onCancel()" type="button" class="close-button">
        <i class="fa-light fa-x close-button-icon"></i>
      </button>
    </div>
    <div class="hasomed-input-field role-select">
      <div class="formular-row-input">
        <eleguide-select
          [control]="roleControl"
          controlId="role"
          [options]="availableRolesSelectOptions"
          placeholder="Rolle"></eleguide-select>
      </div>
    </div>
  </div>
  <div class="flex-r gap-24 justify-space-between">
    <button
      (click)="onCancel()"
      type="button"
      class="hasomed-text-button hasomed-button-white-grey">
      <span class="hasomed-button-text">Abbrechen</span>
    </button>
    <button
      (click)="onConfirm()"
      type="submit"
      class="hasomed-text-button hasomed-button-primary">
      <i class="hasomed-button-icon fa-light fa-floppy-disk"></i>
      <span class="hasomed-button-text">Speichern</span>
    </button>
  </div>
</form>
