import { ExpertiseModel } from './expertise.model';
import { ProcedureModel } from './procedure.model';

export type EducationCourse = {
  id?: number;
  instituteId?: number;
  regulation?: Regulation;
  title: string;
  expertise?: ExpertiseModel;
  procedure?: ProcedureModel;
  professionalAssociation?: ProfessionalAssociation;
  accreditationYear?: number;
  chamber?: string;
  internalNotes?: string;
  externalNotes?: string;
  contingentTheoreticalScientificHours?: number;
  contingentTheoreticalScientificAutoTrainingHours?: number;
  contingentTheoreticalScientificBalintGroupWorkHours?: number;
  contingentPractical1Hours?: number;
  contingentPractical1MedicalHistorySurveyMin?: number;
  contingentPractical2Hours?: string;
  contingentTreatmentInternshipHours?: number;
  contingentCompletedTreatmentCases?: number;
  contingentTreatmentCases?: number;
  contingentTreatmentCasesMin?: number;
  contingentTreatmentCasesMinHours?: number;
  contingentTreatmentCasesMin2?: number;
  contingentTreatmentCasesMin2Hours?: number;
  contingentSupervisionHours?: number;
  contingentSupervisionSingleSessionHours?: number;
  contingentSupervisionGroupSessionHours?: number;
  contingentSupervisionMedicalHistorySurveyMin?: number;
  contingentSelfExperienceHours?: number;
  contingentSelfExperienceSessionsMin?: number;
  contingentSelfExperienceSingleSessionsMin?: number;
  contingentSelfExperienceGroupSessionsMin?: number;
};

export interface EducationCourseCreateModel {
  title: string;
  regulation?: Regulation;
  expertiseId?: number;
  procedureId?: number;
  professionalAssociation?: ProfessionalAssociation;
  accreditationYear?: number;
  chamber?: string;
  internalNotes?: string;
  externalNotes?: string;
  contingentTheoreticalScientificHours?: number;
  contingentTheoreticalScientificAutoTrainingHours?: number;
  contingentTheoreticalScientificBalintGroupWorkHours?: number;
  contingentPractical1Hours?: number;
  contingentPractical1MedicalHistorySurveyMin?: number;
  contingentPractical2Hours?: string;
  contingentTreatmentInternshipHours?: number;
  contingentCompletedTreatmentCases?: number;
  contingentTreatmentCases?: number;
  contingentTreatmentCasesMin?: number;
  contingentTreatmentCasesMinHours?: number;
  contingentTreatmentCasesMin2?: number;
  contingentTreatmentCasesMin2Hours?: number;
  contingentSupervisionHours?: number;
  contingentSupervisionSingleSessionHours?: number;
  contingentSupervisionGroupSessionHours?: number;
  contingentSupervisionMedicalHistorySurveyMin?: number;
  contingentSelfExperienceHours?: number;
  contingentSelfExperienceSessionsMin?: number;
  contingentSelfExperienceSingleSessionsMin?: number;
  contingentSelfExperienceGroupSessionsMin?: number;
}

export interface EducationCourseUpdateModel {
  title: string;
  expertiseId?: number;
  procedureId?: number;
  policyProcedure?: PolicyProcedure;
  professionalAssociation?: ProfessionalAssociation;
  accreditationYear?: number;
  chamber?: string;
  internalNotes?: string;
  externalNotes?: string;
  contingentTheoreticalScientificHours?: number;
  contingentTheoreticalScientificAutoTrainingHours?: number;
  contingentTheoreticalScientificBalintGroupWorkHours?: number;
  contingentPractical1Hours?: number;
  contingentPractical1MedicalHistorySurveyMin?: number;
  contingentPractical2Hours?: string;
  contingentTreatmentInternshipHours?: number;
  contingentCompletedTreatmentCases?: number;
  contingentTreatmentCases?: number;
  contingentTreatmentCasesMin?: number;
  contingentTreatmentCasesMinHours?: number;
  contingentTreatmentCasesMin2?: number;
  contingentTreatmentCasesMin2Hours?: number;
  contingentSupervisionHours?: number;
  contingentSupervisionSingleSessionHours?: number;
  contingentSupervisionGroupSessionHours?: number;
  contingentSupervisionMedicalHistorySurveyMin?: number;
  contingentSelfExperienceHours?: number;
  contingentSelfExperienceSessionsMin?: number;
  contingentSelfExperienceSingleSessionsMin?: number;
  contingentSelfExperienceGroupSessionsMin?: number;
}

export enum Regulation {
  EDUCATION = 'Ausbildungsordnung',
  FURTHER_EDUCATION = 'Weiterbildungsordnung',
  GROUP_THERAPY = 'Gruppentherapie',
  BUILDUP_COURSE = 'Aufbaukurs',
  MEDICAL_PSYCHOTHERAPY = 'Ärztliche Psychotherapieausbildung',
  ADDITIONAL_CERTIFICATION_MEDICAL_PSYCHOTHERAPY = 'Zusatzbezeichnung: Ärztliche Psychotherapie',
  ADDITIONAL_CERTIFICATION_MEDICAL_PSYCHOANALYSIS = 'Zusatzbezeichnung: Ärztliche Psychoanalyse',
}

export enum Expertise {
  ADULT = 'Erwachsene',
  KJP = 'KJP',
  NEUROPSYCHOLOGY = 'Neuropsychologie',
}

export enum PolicyProcedure {
  TP = 'TP',
  AP = 'AP',
  ST = 'ST',
  VT = 'VT',
  AP_TP = 'AP/TP (verklammert)',
}

export enum ProfessionalAssociation {
  DGPT = 'DGPT',
  DGVT = 'DGVT',
  DPTV = 'DPTV',
  DGSF = 'DGSF',
  VAKJP = 'VAKJP',
  BLÄK = 'BLÄK',
  NONE = 'Keine',
  OTHER = 'Sonstige',
}
