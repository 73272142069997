import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import * as moment from 'moment';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  CooperationPartnerCreateModel,
  CooperationPartnerModel,
  CooperationPartnerUpdateModel,
} from '../models/cooperation-partner.model';
import { CancellationService } from './cancellation.service';
import { DecryptionService } from './decryption.service';
import { FileService } from './file.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class CooperationPartnerService {
  private instituteId = this.userService.currentUser.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private cancellationService: CancellationService,
    private userService: UserService,
    private decryptionService: DecryptionService,
    private cryptoService: CryptoService,
    private fileService: FileService
  ) {}

  /**
   * getAllCooperationPartners
   * gets all cooperation partners
   * @returns Observable<HttpResponse<any>>
   */
  public getAllCooperationPartners(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/cooperation-partners`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getCooperationPartnerById
   * gets a cooperation partner by id
   * @param cooperationPartnerId
   * @returns Observable<HttpResponse<any>>
   * */
  public getCooperationPartnerById(
    cooperationPartnerId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/cooperation-partners/${cooperationPartnerId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createCooperationPartner
   * creates a cooperation partner
   * @param cooperationPartner
   * @returns OPromise<Observable<HttpResponse<any>>>
   * */
  public async createCooperationPartner(
    cooperationPartner: CooperationPartnerCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    // encrypt email, phone, mobile and files
    cooperationPartner.email =
      cooperationPartner.email &&
      (await this.cryptoService.encrypt(cooperationPartner.email));
    cooperationPartner.phone =
      cooperationPartner.phone &&
      (await this.cryptoService.encrypt(cooperationPartner.phone));
    cooperationPartner.mobile =
      cooperationPartner.mobile &&
      (await this.cryptoService.encrypt(cooperationPartner.mobile));

    return this.http
      .post(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/cooperation-partners`,
        cooperationPartner,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * updateCooperationPartner
   * updates a cooperation partner
   * @param cooperationPartnerId
   * @param cooperationPartner
   * @returns Promise<Observable<HttpResponse<any>>>
   * */
  public async updateCooperationPartner(
    cooperationPartnerId: number,
    cooperationPartner: CooperationPartnerUpdateModel
  ): Promise<Observable<HttpResponse<any>>> {
    // encrypt email, phone, mobile and files
    cooperationPartner.email =
      cooperationPartner.email &&
      (await this.cryptoService.encrypt(cooperationPartner.email));
    cooperationPartner.phone =
      cooperationPartner.phone &&
      (await this.cryptoService.encrypt(cooperationPartner.phone));
    cooperationPartner.mobile =
      cooperationPartner.mobile &&
      (await this.cryptoService.encrypt(cooperationPartner.mobile));

    return this.http
      .put(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/cooperation-partners/${cooperationPartnerId}`,
        cooperationPartner,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * deleteCooperationPartner
   * deletes a cooperation partner
   * @param cooperationPartnerId
   * @returns Observable<HttpResponse<any>>
   * */
  public deleteCooperationPartner(
    cooperationPartnerId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .delete(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/cooperation-partners/${cooperationPartnerId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * openFile
   * get file from backend and open it in a new tab
   * @param cooperationPartnerId
   * @param fileId
   * @returns void
   */
  public openFile(cooperationPartnerId: number, fileId: number): void {
    this.fileService.openFile(
      `/api/institutes/${this.instituteId}/cooperation-partners/${cooperationPartnerId}/files/${fileId}`
    );
  }

  /**
   * downloadFile
   * get file from backend and download it
   * @param cooperationPartnerId
   * @param fileId
   * @returns void
   */
  public downloadFile(cooperationPartnerId: number, fileId: number): void {
    this.fileService.downloadFile(
      `/api/institutes/${this.instituteId}/cooperation-partners/${cooperationPartnerId}/files/${fileId}`
    );
  }

  /**
   * parseBackendCooperationPartner
   * parse cooperation partner from backend
   * @param cooperationPartner
   * @returns Promise<CooperationPartnerModel>
   */
  public async parseBackendCooperationPartner(
    cooperationPartner: CooperationPartnerModel
  ): Promise<CooperationPartnerModel> {
    return {
      id: cooperationPartner.id,
      instituteId: cooperationPartner.instituteId,
      name: cooperationPartner.name,
      contactPerson: {
        genderTitle: cooperationPartner.contactPerson.genderTitle,
        academicTitle: cooperationPartner.contactPerson.academicTitle,
        firstName: cooperationPartner.contactPerson.firstName,
        lastName: cooperationPartner.contactPerson.lastName,
      },
      email: await this.decryptionService.decryptString(
        cooperationPartner.email
      ),
      phone: await this.decryptionService.decryptString(
        cooperationPartner.phone
      ),
      mobile: await this.decryptionService.decryptString(
        cooperationPartner.mobile
      ),
      address: {
        street: cooperationPartner.address?.street,
        houseNumber: cooperationPartner.address?.houseNumber,
        addressAddition: cooperationPartner.address?.addressAddition,
        zipCode: cooperationPartner.address?.zipCode,
        city: cooperationPartner.address?.city,
        country: cooperationPartner.address?.country,
      },
      expertises: cooperationPartner.expertises,
      supplySector: cooperationPartner.supplySector,
      procedures: cooperationPartner.procedures,
      cooperationAgreementAvailable:
        cooperationPartner.cooperationAgreementAvailable,
      cooperationAgreementDate: cooperationPartner.cooperationAgreementDate
        ? moment(
            cooperationPartner.cooperationAgreementDate,
            'YYYY-MM-DD'
          ).toDate()
        : null,
      surveyFormAvailable: cooperationPartner.surveyFormAvailable,
      surveyFormDate: cooperationPartner.surveyFormDate
        ? moment(cooperationPartner.surveyFormDate, 'YYYY-MM-DD').toDate()
        : null,
      isAdvancedTrainingApproved: cooperationPartner.isAdvancedTrainingApproved,
      scopeOfAdvancedTrainingApproval:
        cooperationPartner.scopeOfAdvancedTrainingApproval,
      files: await this.fileService.parseBackendFiles(cooperationPartner.files),
    };
  }
}
