import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { Observable, switchMap, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  FileCreateModel,
  FileModel,
  FileUpdateModel,
} from '../models/file.model';
import {
  arrayBufferToString,
  exportPublicKey,
  generateKeyPair,
  getFileHash,
  signData,
  stringToArrayBuffer,
} from '../utils/file.utils';
import { AlertService } from './alert.service';
import { CancellationService } from './cancellation.service';
import { DecryptionService } from './decryption.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService,
    private alertService: AlertService,
    private cryptoService: CryptoService
  ) {}
  /**
   * Get all files for an institute based on the users role
   * @param instituteId
   * @returns Observable<HttpResponse<any>>
   */
  public getAllInstituteFiles(
    instituteId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(this.config.backendUrl + `/api/institutes/${instituteId}/files`, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * get an institute file by the id of the institute and the file
   * @param instituteId the id of the institute
   * @param fileId the id of the file
   * @returns Observable<HttpResponse<any>>
   */
  public getInstituteFile(
    instituteId: number,
    fileId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${instituteId}/files/${fileId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * upload an institute file with permissions to backend
   * @param instituteId
   * @param fileCreateModel
   * @returns Observable<HttpResponse<any>>
   */
  public createFile(
    instituteId: number,
    fileCreateModel: FileCreateModel
  ): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      this.config.backendUrl + `/api/institutes/${instituteId}/files`,
      fileCreateModel,
      { headers: headers, observe: 'response', responseType: 'json' }
    );
  }

  /**
   * update an institute file with permissions in backend
   * @param instituteId
   * @param fileId
   * @param fileUpdateModel
   * @returns Observable<HttpResponse<any>>
   */
  public updateInstituteFile(
    instituteId: number,
    fileId: number,
    fileUpdateModel: FileUpdateModel
  ): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put(
      this.config.backendUrl + `/api/institutes/${instituteId}/files/${fileId}`,
      fileUpdateModel,
      { headers: headers, observe: 'response', responseType: 'json' }
    );
  }

  /**
   * get the institute file from backend and open it in a new tab
   * @param instituteId
   * @param fileId
   * @returns void
   */
  public openInstituteFile(instituteId: number, fileId: number): void {
    this.openFile(`/api/institutes/${instituteId}/files/${fileId}`);
  }

  /**
   * get file from backend and open it in a new tab
   * @param endpointUrl
   * @returns void
   */
  public openFile(endpointUrl: string) {
    this.http
      .get(this.config.backendUrl + endpointUrl, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(takeUntil(this.cancellationService.cancelRequests$))
      .pipe(
        switchMap(async (response: HttpResponse<any>) => {
          const parsedFile = response.body
            ? await this.parseBackendFile(response.body)
            : null;
          if (!parsedFile) {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Die Datei konnte nicht geöffnet werden.'
            );
            return false;
          }

          const arrayBuffer = stringToArrayBuffer(atob(parsedFile.data));
          const blob = new Blob([arrayBuffer], { type: parsedFile.mimeType });

          const url = window.URL.createObjectURL(blob);
          window.open(url, '_blank');
          return true;
        })
      )
      .subscribe({
        next: () => {},
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Datei konnte nicht geöffnet werden.'
          );
        },
      });
  }

  /**
   * get the institute file from backend and download it
   * @param instituteId the id of the institute
   * @param fileId the id of the file
   * @returns void
   */
  public downloadInstituteFile(instituteId: number, fileId: number): void {
    this.downloadFile(`/api/institutes/${instituteId}/files/${fileId}`);
  }

  /**
   * get the file from backend and trigger download
   * @param endpointUrl the endpoint url to download the file from
   * @returns void
   */
  public downloadFile(endpointUrl: string): void {
    this.http
      .get(this.config.backendUrl + endpointUrl, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(takeUntil(this.cancellationService.cancelRequests$))
      .pipe(
        switchMap(async (response: HttpResponse<any>) => {
          const parsedFile = response.body
            ? await this.parseBackendFile(response.body)
            : null;
          if (!parsedFile) {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Die Datei konnte nicht geöffnet werden.'
            );
            return;
          }

          const arrayBuffer = stringToArrayBuffer(atob(parsedFile.data));
          const blob = new Blob([arrayBuffer], { type: parsedFile.mimeType });

          // Trigger download
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = parsedFile.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      )
      .subscribe({
        next: () => {},
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Datei konnte nicht heruntergeladen werden.'
          );
        },
      });
  }

  /**
   * delete an institute file from backend
   * @param instituteId the id of the institute
   * @param fileId the id of the file
   * @returns Observable<HttpResponse<any>
   */
  public deleteFile(
    instituteId: number,
    fileId: number
  ): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.config.backendUrl + `/api/institutes/${instituteId}/files/${fileId}`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * parse the file data from the backend and decrypt it if necessary
   * @param file the file to parse
   * @returns the parsed file
   */
  public async parseBackendFile(file: FileModel): Promise<FileModel> {
    // decrypt file data, handle base64 encoded and unencoded data
    if (file.data) {
      var decryptedFileData: string;

      // handle files not uploaded as base64 encoded array buffer
      if (file.data.includes('data:')) {
        var [, base64Data] = file.data.split(',');
        file.data = base64Data;
      }

      try {
        // decode base64 encoded data
        const decodedFileData = atob(file.data);
        // decrypt data
        decryptedFileData = await this.cryptoService.decrypt(decodedFileData);
      } catch (error) {
        // decrypt data if not base64 encoded and handle unencrypted data
        decryptedFileData = await this.decryptionService.decryptString(
          file.data
        );
      }
    }

    return {
      id: file.id,
      name: await this.decryptionService.decryptString(file.name),
      data: decryptedFileData,
      size: file.size,
      creator: file.creator,
      filePermissions: file.filePermissions,
      mimeType: file.mimeType,
      timeCreated: file.timeCreated,
      timeModified: file.timeModified,
    };
  }

  /**
   * parse the files received from the backend
   * @param files the files to parse
   * @returns the parsed files
   */
  public async parseBackendFiles(files: FileModel[]): Promise<FileModel[]> {
    if (!files || files.length === 0) {
      return [];
    }
    return Promise.all(
      files.map(async file => {
        return await this.parseBackendFile(file);
      })
    );
  }

  /**
   * handle file upload from the file upload component
   * @param value the value of the file upload
   * @param destinationFormField the form field to store the file data
   * @param singleFileUpload whether to upload a single file or multiple files
   * @param encryptFiles whether to encrypt the files before saving them
   * @returns void
   */
  public handleFileUpload(
    value: any,
    destinationFormField: AbstractControl,
    singleFileUpload: boolean = false,
    encryptFiles: boolean = false
  ): void {
    let newDocument: FileCreateModel;
    let newDocumentsArray: FileCreateModel[] = [];
    value.forEach((file: File) => {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        const keyPair = await generateKeyPair();
        var fileArrayBuffer: ArrayBuffer = await file.arrayBuffer();

        if (encryptFiles && fileArrayBuffer) {
          const base64Data = btoa(arrayBufferToString(fileArrayBuffer));
          const encryptedFileData =
            await this.cryptoService.encrypt(base64Data);
          fileArrayBuffer = stringToArrayBuffer(encryptedFileData);
        }

        const base64Signature = await signData(
          fileArrayBuffer,
          keyPair.privateKey
        );
        const base64PublicKey = await exportPublicKey(keyPair.publicKey);

        const base64Hash = await getFileHash(fileArrayBuffer);

        const base64Data = btoa(arrayBufferToString(fileArrayBuffer));

        newDocument = {
          name: file.name,
          data: base64Data,
          size: file.size,
          mimeType: file.type,
          signature: base64Signature,
          publicKey: base64PublicKey,
          hash: base64Hash,
        };

        if (singleFileUpload) {
          destinationFormField.setValue(newDocument);
          return;
        }

        newDocumentsArray.push(newDocument);

        // Check if all files have been read and update the documents array once
        if (newDocumentsArray.length === value.length) {
          destinationFormField.setValue(newDocumentsArray);
        }
      };
      reader.readAsDataURL(file);
    });
  }
}
