import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-single-event-picker',
  templateUrl: './single-event-picker.component.html',
  styleUrl: './single-event-picker.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleEventPickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SingleEventPickerComponent),
      multi: true,
    },
  ],
})
export class SingleEventPickerComponent
  implements OnInit, ControlValueAccessor, Validator, OnChanges, OnDestroy
{
  @Input() duration: number | null;
  @Input() startDate: any;
  @Input() endDate: any;
  @Input() parentFormSubmitValidation: Boolean;

  public startTime: any = null;
  public endTime: any = null;
  public dateForm: FormGroup;

  public minDate: Date = new Date(1960, 0, 1);

  @Output() datesChanged: EventEmitter<{
    start: Date;
    end: Date;
  }> = new EventEmitter();

  private destroy$: Subject<void> = new Subject<void>();

  public ngOnInit(): void {
    if (this.startDate && this.endDate) {
      this.startTime = moment(this.startDate).format('HH:mm');
      this.endTime = moment(this.endDate).format('HH:mm');
    }
    this.initializeForm();
  }

  /**
   * initializes the form
   * @returns void
   */
  private initializeForm(): void {
    this.dateForm = new FormGroup({
      date: new FormControl(this.startDate, Validators.required),
      startTime: new FormControl(this.startTime, Validators.required),
      endTime: new FormControl(this.endTime, Validators.required),
    });
  }

  // custom form control settings
  public onTouched: () => void = () => {};

  public writeValue(val: any): void {
    //val && this.dateForm.setValue(val, { emitEvent: false });

    if (val) {
      this.dateForm?.setValue(
        {
          date: moment(val.date),
          startTime: val?.startTime ?? '00:00',
          endTime: val?.endTime ?? '23:59',
        },
        { emitEvent: false }
      );
    }
  }
  public registerOnChange(fn: any): void {
    this.dateForm?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(fn);
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.dateForm?.disable() : this.dateForm?.enable();
  }
  // custom form control validation
  public validate(c: AbstractControl): ValidationErrors | null {
    return this.dateForm?.valid
      ? null
      : {
          invalidDateGroup: {
            valid: false,
            message: 'dateGroup fields are invalid',
          },
        };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // update end date and time if the duration was changed
    if (
      changes.duration &&
      !changes.duration.firstChange &&
      this.startDate &&
      this.endDate
    ) {
      this.endDate = this.calculateEndDate(moment(this.startDate));
      if (this.endTime) {
        this.endTime = this.endDate.format('HH:mm');

        this.dateForm.get('endTime').setValue(this.endTime);
        this.publishDates();
      }
    }

    // mark form as touched if parentForm is submitted
    if (changes.parentFormSubmitValidation) {
      this.dateForm?.markAllAsTouched();
    }
  }

  /**
   * date from the datepicker was changed
   * @param event the event from the datepicker
   * @returns void
   */
  public dateChanged(event: any): void {
    const newDate = moment(event.target.value).startOf('day');
    const startDate = moment(this.startDate);
    const endDate = moment(this.endDate);

    this.startDate = newDate
      .clone()
      .hour(startDate.hour())
      .minute(startDate.minute());
    this.endDate = newDate
      .clone()
      .hour(endDate.hour())
      .minute(endDate.minute());

    this.publishDates();
  }

  private timeChangedTimeout: any = null;
  private triggered: number = 0;

  /**
   * startTime from the eventList was changed
   * @param event the event from the timepicker
   * @returns void
   */
  public startTimeChanged(event: any): void {
    this.triggered++;
    // get the new start time
    const [startTimeHours, startTimeMins] = event.target.value.split(':');

    // only execute if both digits of hour or minute are available
    if (!startTimeHours || !startTimeMins) {
      return;
    }

    // Use today's date if startDate is not set, and then set the new start time
    this.startDate = moment(this.startDate || moment().startOf('day'))
      .hour(startTimeHours)
      .minute(startTimeMins);
    this.startTime = moment(this.startDate).format('HH:mm');

    if (this.hourOrMinuteStartWithZero(event.target.value)) {
      this.updateEndTime();
      return;
    }

    // prevent setting endTime when entering start time not finished
    this.timeChangedTimeout = setTimeout(() => {
      if (this.triggered !== 0) {
        this.updateEndTime();
      }
    }, 1000);
  }

  /**
   * check if the hour or minute of a time input starts with a zero
   * function is used to check if the time input was fully entered
   * @param value the value of the time input
   * @returns true if the hour or minute starts with a zero otherwise false
   */
  private hourOrMinuteStartWithZero(value: string): boolean {
    return value.split(':').some(val => val.charAt(0) === '0');
  }

  /**
   * update the end date and end time based on the start time
   * reset the timeout for the time changed event
   * @returns void
   */
  private updateEndTime(): void {
    if (this.duration) {
      // Update endDate and endTime based on the startDate
      this.endDate = this.calculateEndDate(this.startDate);
      this.endTime = moment(this.endDate).format('HH:mm');

      // Update the form control for endTime
      this.dateForm.controls['endTime'].setValue(this.endTime);
    }

    // Reset timeout and trigger time change event
    this.triggered = 0;
    if (this.timeChangedTimeout) {
      clearTimeout(this.timeChangedTimeout);
      this.timeChangedTimeout = null;
    }
    this.publishDates();
  }

  /**
   * endTime from the eventList was changed
   * @param event the event from the timepicker
   * @returns void
   */
  public endTimeChanged(event: any) {
    this.triggered++;
    const [endTimeHours, endTimeMins] = event.target.value.split(':');

    // only execute if both digits of hour or minute are available
    if (!endTimeHours || !endTimeMins) {
      return;
    }

    // when no end date is set use today
    this.endDate = this.endDate
      ? moment(this.endDate)
      : moment().startOf('day');
    // set the new end date and time
    this.endDate = this.endDate.hour(endTimeHours).minute(endTimeMins);
    this.endTime = moment(this.endDate).format('HH:mm');

    if (this.hourOrMinuteStartWithZero(event.target.value)) {
      this.updateEndTime();
      return;
    }

    // prevent setting endTime when entering start time not finished
    this.timeChangedTimeout = setTimeout(() => {
      if (this.triggered !== 0) {
        this.updateStartTime();
      }
    }, 1000);
  }

  /**
   * update the start date and start time based on the end time
   * reset the timeout for the time changed event
   * @returns void
   */
  private updateStartTime(): void {
    if (this.duration) {
      // Update startDate and startTime based on the endDate
      this.startDate = this.calculateStartDate(this.endDate);
      this.startTime = moment(this.startDate).format('HH:mm');

      // Update the form control for startTime
      this.dateForm.controls['startTime'].setValue(this.startTime);
    }

    // Reset timeout and trigger time change event
    this.triggered = 0;
    if (this.timeChangedTimeout) {
      clearTimeout(this.timeChangedTimeout);
      this.timeChangedTimeout = null;
    }
    this.publishDates();
  }

  /**
   * calculate the end date based on the start date and the duration
   * @param startDate the start date
   * @returns the end date of the course
   */
  private calculateEndDate(startDate: moment.Moment): moment.Moment {
    const oldEndDate = this.endDate
      ? moment(this.endDate).startOf('day')
      : moment().startOf('day');

    // change the time of the end date to the time of the start date and then add the duration to get the new end date
    return oldEndDate
      .hour(startDate.hour())
      .minute(startDate.minute())
      .add(this.duration, 'm');
  }

  /**
   * calculate the start date based on the end date and the duration
   * @param endDate the end date
   * @returns the start date of the course
   */
  private calculateStartDate(endDate: moment.Moment): moment.Moment {
    const oldStartDate = this.startDate
      ? moment(this.startDate).startOf('day')
      : moment().startOf('day');

    // change the time of the start date to the time of the end date and then subtract the duration to get the new start date
    return oldStartDate
      .hour(endDate.hour())
      .minute(endDate.minute())
      .subtract(this.duration, 'm');
  }

  /**
   * publish the start and end date to the parent component
   * @returns void
   */
  private publishDates(): void {
    this.dateForm.get('date').setValue(this.startDate);
    this.datesChanged.emit({
      start: moment(this.startDate).toDate(),
      end: moment(this.endDate).toDate(),
    });
  }

  /**
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
