import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { first, Subject } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import {
  Label,
  LabelCreateModel,
  LabelUpdateModel,
} from 'src/app/models/label.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LabelService } from 'src/app/services/label.service';
import { LoadingService } from 'src/app/services/loading.service';
import { isRequired } from 'src/app/utils/form.utils';

@Component({
  selector: 'app-create-edit-label-dialog',
  templateUrl: './create-edit-label-dialog.component.html',
  styleUrls: ['./create-edit-label-dialog.component.scss'],
})
export class CreateEditLabelDialogComponent implements OnInit, OnDestroy {
  public label?: Label;

  public labelForm: FormGroup;
  public initialFormValues: {};
  public availableColors: string[] = [];
  public selectedColor: string;

  // impoert from form.utils.ts
  public isRequired = isRequired;

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.labelForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  private destroy$: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<CreateEditLabelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { label?: Label },
    private dialog: MatDialog,
    private labelService: LabelService,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private cancellationService: CancellationService,
    private formSubmitValidationService: FormSubmitValidationService,
    private loadingService: LoadingService
  ) {
    this.label = data.label;

    // disable closing the dialog by clicking outside of it
    dialogRef.disableClose = true;
  }

  public ngOnInit() {
    this.availableColors = this.labelService.getAvailableColors();

    this.selectedColor = this.label
      ? this.label.color
      : this.availableColors[0];

    this.labelForm = new FormGroup({
      name: new FormControl(this.label?.name, [
        Validators.required,
        Validators.maxLength(50),
      ]),
      color: new FormControl(this.selectedColor),
    });

    this.initialFormValues = this.labelForm.value;
  }

  /**
   * onColorSelect
   * sets the selected color
   * @param color
   * @returns void
   */
  public onColorSelect(color: string): void {
    this.selectedColor = color;
    this.labelForm.patchValue({ color: color });
  }

  /**
   * Handles the form submission
   * If the form is valid and has changes, it will create or update the label.
   * @returns void
   */
  public onSubmit(): void {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.labelForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.labelForm.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.dialogRef.close(false);
      return;
    }

    this.loadingService.show();
    this.label ? this.updateLabel() : this.createLabel();
  }

  /**
   * createLabel
   * Create a new label
   */
  private createLabel(): void {
    const labelCreateModel: LabelCreateModel = {
      name: this.labelForm.get('name').value.trim(),
      color: this.labelForm.get('color').value,
    };

    this.labelService
      .createLabel(labelCreateModel)
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            `Das Etikett '${labelCreateModel.name}' wurde erstellt.`
          );
          this.initialFormValues = this.labelForm.value;
          this.dialogRef.close(true);
          this.loadingService.hide();
        },
        error: error => {
          // todo: handle duplicate label name
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt.',
            'Erstellen des Etiketts fehlgeschlagen.'
          );
          this.loadingService.hide();
        },
      });
  }

  /**
   * updateLabel
   * Update the label
   */
  private updateLabel(): void {
    this.label.name = this.labelForm.get('name').value.trim();
    this.label.color = this.labelForm.get('color').value;

    const labelUpdateModel: LabelUpdateModel = {
      name: this.labelForm.get('name').value.trim(),
      color: this.labelForm.get('color').value,
    };

    this.labelService.updateLabel(this.label.id, labelUpdateModel).subscribe({
      next: response => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          `Das Etikett '${this.label.name}' wurde aktualisiert.`
        );
        this.initialFormValues = this.labelForm.value;
        this.dialogRef.close(true);
        this.loadingService.hide();
      },
      error: error => {
        // todo: handle duplicate label name

        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt.',
          'Erstellen des Etiketts fehlgeschlagen.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * onCancel
   * Close the dialog, return false
   * @returns void
   */
  public onCancel(): void {
    // check if the form has unsaved changes
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.labelForm.value,
        this.initialFormValues
      )
    ) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Ungespeicherte Änderungen!',
          message:
            'Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren. \
            Möchten Sie die Seite trotzdem verlassen?',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe(dialogResult => {
          if (dialogResult) {
            // Close the dialog, return false
            this.dialogRef.close(false);
          }
        });
    } else {
      // Close the dialog, return false
      this.dialogRef.close(false);
    }
  }

  /**
   * unsubscribe from all subscriptions and cancel all requests
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
