import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, take, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { CreateEditAppointmentDialogComponent } from 'src/app/components/shared-components/create-edit-appointment-dialog/create-edit-appointment-dialog.component';
import { CalendarEventDateModel } from 'src/app/models/calendar-event.model';
import { EventDateType } from 'src/app/models/event.model';
import { SupervisionEventDateStatusUpdateModel } from 'src/app/models/supervision.model';
import { AlertService } from 'src/app/services/alert.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PatientAppointmentService } from 'src/app/services/patient-appointment.service';
import { SupervisionService } from 'src/app/services/supervision.service';
import { UserService } from 'src/app/services/user.service';
import { getFullName, getFullNames } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-calendar-event-detail',
  templateUrl: './calendar-event-detail.component.html',
  styleUrls: ['./calendar-event-detail.component.scss'],
})
export class CalendarEventDetailComponent implements OnInit, OnDestroy {
  public eventType = EventDateType;
  public eventForm: FormGroup;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private patientAppointmentService: PatientAppointmentService,
    private supervisionService: SupervisionService,
    private appointmentService: AppointmentService,
    private dialog: MatDialog,
    private alertService: AlertService,
    public userService: UserService,
    public dialogRef: MatDialogRef<CalendarEventDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      calendarEventDate: CalendarEventDateModel;
    },
    private loadingService: LoadingService
  ) {}

  public ngOnInit() {
    moment.locale('de');

    const formattedDate = this.formatCalendarEventDate();

    // get names of lecturers, supervisors and treatmentCaseStudent
    const studentName = this.data.calendarEventDate.treatmentCaseStudent
      ? getFullName(this.data.calendarEventDate.treatmentCaseStudent)
      : null;
    const supervisorNames = this.data.calendarEventDate.supervisors
      ? getFullNames(this.data.calendarEventDate.supervisors)
      : null;
    const lecturerNames = this.data.calendarEventDate.lecturers
      ? getFullNames(this.data.calendarEventDate.lecturers)
      : null;
    const supervisorName = getFullName(this.data.calendarEventDate.supervisor);

    this.eventForm = new FormGroup({
      date: new FormControl(formattedDate),
      room: new FormControl(
        this.data.calendarEventDate.room?.name ?? 'Kein Raum'
      ),
      chiffre: new FormControl(this.data.calendarEventDate.patientChiffre),
      supervisor: new FormControl(
        supervisorName ? supervisorName : supervisorNames
      ),
      lecturer: new FormControl(lecturerNames),
      treatmentCaseStudent: new FormControl(studentName),
      appointmentDescription: new FormControl(
        this.data.calendarEventDate.appointmentDescription
      ),
    });
  }

  /**
   * Formats the calendar event date based on the provided data.
   * @returns The formatted calendar event date.
   */
  private formatCalendarEventDate() {
    if (this.data.calendarEventDate.isAllDay) {
      return (
        moment(this.data.calendarEventDate.startDate).format('dd') +
        ', ' +
        moment(this.data.calendarEventDate.startDate).format('DD.MM.YYYY')
      );
    }

    return (
      moment(this.data.calendarEventDate.startDate).format('dd') +
      ', ' +
      moment(this.data.calendarEventDate.startDate).format('DD.MM.YYYY') +
      ', ' +
      moment(this.data.calendarEventDate.startDate).format('HH:mm') +
      ' - ' +
      moment(this.data.calendarEventDate.endDate).format('HH:mm') +
      ' Uhr'
    );
  }

  /**
   * get the event date title based on the event date type
   * @returns string
   */
  public getEventDateTitle(): string {
    switch (this.data.calendarEventDate.eventDateType) {
      case EventDateType.COURSE:
        return this.data.calendarEventDate.courseTitle ?? 'Kurs';
      case EventDateType.PATIENT_SESSION:
        return 'Patient: ' + this.data.calendarEventDate.patientChiffre;
      case EventDateType.SUPERVISION:
        return 'Einzelsupervision';
      case EventDateType.GROUP_SUPERVISION:
        return 'Gruppensupervision';
      case EventDateType.APPOINTMENT_EVENT:
        return this.data.calendarEventDate.appointmentTitle;
      case EventDateType.APPOINTMENT_OTHER:
        return this.data.calendarEventDate.appointmentTitle;
      case EventDateType.APPOINTMENT_HOLIDAY:
        return 'Feiertag: ' + this.data.calendarEventDate.appointmentTitle;
      default:
        return '';
    }
  }

  /**
   * Navigates to the treatment event dates page and closes the dialog.
   * @returns void
   */
  public onManageTreatmentAppointment(): void {
    if (
      this.data.calendarEventDate.eventDateType ===
      EventDateType.PATIENT_SESSION
    ) {
      this.router.navigate([
        'eleguide/education/ambulatory-part',
        btoa(this.data.calendarEventDate.treatmentCaseId.toString()),
        'event-dates',
        btoa(this.data.calendarEventDate.patientAppointmentId.toString()),
        btoa(this.data.calendarEventDate.eventDateId.toString()),
      ]);
    } else {
      this.router.navigate([
        'eleguide/education/supervision',
        btoa(this.data.calendarEventDate.supervisionId.toString()),
        btoa(this.data.calendarEventDate.eventDateId.toString()),
      ]);
    }
    this.dialogRef.close();
  }

  /**
   * Opens a dialog to manage the appointment.
   * @returns void
   */
  public onManageAppointment(): void {
    const dialog = this.dialog.open(CreateEditAppointmentDialogComponent, {
      maxWidth: '90dvw',
      maxHeight: '90dvh',
      data: {
        appointmentId: this.data.calendarEventDate.appointmentId,
      },
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result?.appointmentHasBeenUpdated) {
          this.dialogRef.close({ eventDateHasBeenUpdated: true });
          return;
        }

        if (result?.cancel) {
          return;
        }

        this.dialogRef.close();
      });
  }

  /**
   * onCancelAppointment
   * cancel the appointment
   * @returns void
   */
  public onCancelAppointment(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Termin Absagen',
        message: `Möchten Sie den Termin wirklich absagen?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.loadingService.show();
          switch (this.data.calendarEventDate.eventDateType) {
            case EventDateType.PATIENT_SESSION:
              this.cancelPatientAppointmentEventDate(
                this.data.calendarEventDate.treatmentCaseId,
                this.data.calendarEventDate.patientAppointmentId,
                this.data.calendarEventDate.eventDateId
              );
              break;
            case EventDateType.SUPERVISION:
              this.cancelSupervisionEventDate(
                this.data.calendarEventDate.supervisionId,
                this.data.calendarEventDate.eventDateId
              );
              break;
            case EventDateType.GROUP_SUPERVISION:
              this.cancelSupervisionEventDate(
                this.data.calendarEventDate.supervisionId,
                this.data.calendarEventDate.eventDateId
              );
              break;
            default:
              break;
          }
        }
      });
  }

  /**
   * Cancel patient appointment event date
   * @param treatmentCaseId The treatment case id
   * @param patientAppointmentId The patient appointment id
   * @param eventDateId The event date id
   * @returns void
   */
  private cancelPatientAppointmentEventDate(
    treatmentCaseId: number,
    patientAppointmentId: number,
    eventDateId: number
  ): void {
    this.patientAppointmentService
      .cancelPatientAppointmentEventDate(
        treatmentCaseId,
        patientAppointmentId,
        eventDateId
      )
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            `Das hat geklappt!`,
            `Der Termin wurde abgesagt.`
          );

          this.loadingService.hide();
          this.dialogRef.close({ eventDateHasBeenUpdated: true });
        },
        error: error => {
          this.loadingService.hide();
          this.alertService.showErrorAlert(
            `Das hat leider nicht geklappt!`,
            `Der Termin konnte nicht abgesagt werden.`
          );
        },
      });
  }

  /**
   * Cancel supervision event date
   * @param supervisionId The supervision id
   * @param eventDateId The event date id
   * @returns void
   */
  private cancelSupervisionEventDate(
    supervisionId: number,
    eventDateId: number
  ): void {
    const statusUpdateModel: SupervisionEventDateStatusUpdateModel = {
      isCanceled: true,
      isChecked: this.data.calendarEventDate.isChecked,
    };

    this.supervisionService
      .updateSupervisionEventDateStatus(
        supervisionId,
        eventDateId,
        statusUpdateModel
      )
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            `Das hat geklappt!`,
            `Der Termin wurde abgesagt.`
          );

          this.loadingService.hide();
          this.dialogRef.close({ eventDateHasBeenUpdated: true });
        },
        error: error => {
          this.loadingService.hide();
          this.alertService.showErrorAlert(
            `Das hat leider nicht geklappt!`,
            `Der Termin konnte nicht abgesagt werden.`
          );
        },
      });
  }

  /**
   * Deletes an appointment event date.
   * @param appointmentId - The ID of the appointment.
   * @param eventDateId - The ID of the event date.
   * @returns void
   */
  public onDeleteAppointmentEventDate(
    appointmentId: number,
    eventDateId: number
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Termin Löschen',
        message: `Möchten Sie den Termin wirklich löschen?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.loadingService.show();
          this.appointmentService
            .deleteAppointmentEventDate(appointmentId, eventDateId)
            .pipe(take(1))
            .subscribe({
              next: response => {
                this.alertService.showSuccessAlert(
                  `Das hat geklappt!`,
                  `Der Termin wurde gelöscht.`
                );

                this.loadingService.hide();
                this.dialogRef.close({ eventDateHasBeenUpdated: true });
              },
              error: error => {
                this.loadingService.hide();
                this.alertService.showErrorAlert(
                  `Das hat leider nicht geklappt!`,
                  `Der Termin konnte nicht gelöscht werden.`
                );
              },
            });
        }
      });
  }

  /**
   * Deletes a holiday appointment.
   * @param appointmentId - The ID of the appointment to be deleted.
   * @returns void
   */
  public onDeleteHolidayAppointment(appointmentId: number): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Feiertag Löschen',
        message: `Möchten Sie den Feiertag wirklich löschen?`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.loadingService.show();
          this.appointmentService
            .deleteAppointment(appointmentId)
            .pipe(take(1))
            .subscribe({
              next: response => {
                this.alertService.showSuccessAlert(
                  `Das hat geklappt!`,
                  `Der Feiertag wurde gelöscht.`
                );

                this.loadingService.hide();
                this.dialogRef.close({ eventDateHasBeenUpdated: true });
              },
              error: error => {
                this.loadingService.hide();
                this.alertService.showErrorAlert(
                  `Das hat leider nicht geklappt!`,
                  `Der Feiertag konnte nicht gelöscht werden.`
                );
              },
            });
        }
      });
  }

  /**
   * openCourseDetail
   * redirect to course detail page
   * @returns void
   */
  public onOpenCourseDetail(): void {
    if (this.userService.currentUserIsStudent()) {
      this.router.navigate([
        'eleguide/education/theoretical-education/',
        btoa(this.data.calendarEventDate.courseId?.toString()),
      ]);
    } else {
      this.router.navigate([
        'eleguide/education/course-administration',
        btoa(this.data.calendarEventDate.courseId?.toString()),
      ]);
    }

    this.dialogRef.close();
  }

  /**
   * Returns true if the event date is an appointment
   * @returns boolean
   */
  get isEventDateAppointment(): boolean {
    if (
      this.data.calendarEventDate.eventDateType ===
        EventDateType.APPOINTMENT_EVENT ||
      this.data.calendarEventDate.eventDateType ===
        EventDateType.APPOINTMENT_OTHER
    ) {
      return true;
    }

    return false;
  }

  /**
   * Returns true if the event date is a holiday appointment
   * @returns boolean
   */
  get isHolidayAppointment(): boolean {
    return (
      this.data.calendarEventDate.eventDateType ===
      EventDateType.APPOINTMENT_HOLIDAY
    );
  }

  /**
   * onClose
   * close the dialog
   * @returns void
   */
  public onClose(): void {
    this.dialogRef.close();
  }

  /**
   * Determines whether to show the room based on the event date type.
   * @returns {boolean} Returns true if the event date type is not an appointment holiday, otherwise false.
   */
  public showRoom(): boolean {
    return (
      this.data.calendarEventDate.eventDateType !==
      EventDateType.APPOINTMENT_HOLIDAY
    );
  }

  /**
   * Determines whether the user is allowed to cancel the appointment.
   * @returns {boolean} Returns true if the user is allowed to cancel the appointment, otherwise false.
   */
  public userCanCancelAppointment(): boolean {
    if (this.userService.currentUserIsStudent()) {
      return (
        this.data.calendarEventDate.eventDateType ===
          EventDateType.PATIENT_SESSION ||
        this.data.calendarEventDate.eventDateType ===
          EventDateType.SUPERVISION ||
        this.data.calendarEventDate.eventDateType ===
          EventDateType.GROUP_SUPERVISION
      );
    } else if (this.userService.currentUserIsLecturer()) {
      return (
        this.data.calendarEventDate.eventDateType ===
          EventDateType.SUPERVISION ||
        this.data.calendarEventDate.eventDateType ===
          EventDateType.GROUP_SUPERVISION
      );
    }
    return false;
  }

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
