<app-event-room-planning
  *ngIf="roomPlanningOpen"
  title="Raumplanung"
  [participants]="2"
  [eventDates]="eventDates"
  subtitle="Planen Sie den Raum für den Termin"
  (onRoomPlanningClose)="roomPlanningChanged($event)">
</app-event-room-planning>

<form
  [class.hide]="roomPlanningOpen"
  [formGroup]="eventDateForm"
  (ngSubmit)="onSubmit()"
  class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Supervisionstermin bearbeiten</h1>
      <h3 class="hasomed-text-h3">Bearbeiten Sie den Supervisionstermin</h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>
      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>
  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <div *ngIf="!isLoading" class="main-content-form">
    <div class="formular-row">
      <label class="formular-row-label" for="supervisionsType">
        <span class="label-title">
          Supervision
          <span *ngIf="isRequired(eventDateForm.get('supervisionsType'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Wählen Sie die Art der Supervsion.
        </span>
      </label>

      <div class="hasomed-input-field">
        <div class="hasomed-toggle-button">
          <button
            [ngClass]="{
              'hasomed-button-orange':
                eventDateForm.get('supervisionsType').value ===
                'Einzelsupervision',
              'hasomed-button-white-grey':
                eventDateForm.get('supervisionsType').value !==
                'Einzelsupervision',
              'border-red':
                eventDateForm.get('supervisionsType').invalid &&
                eventDateForm.get('supervisionsType').touched,
            }"
            (click)="
              eventDateForm
                .get('supervisionsType')
                .setValue('Einzelsupervision')
            "
            type="button"
            class="hasomed-text-button button-full-width btn-left">
            <span class="hasomed-button-text">Einzelsupervision</span>
          </button>
          <button
            [ngClass]="{
              'hasomed-button-orange':
                eventDateForm.get('supervisionsType').value ===
                'Gruppensupervision',
              'hasomed-button-white-grey':
                eventDateForm.get('supervisionsType').value !==
                'Gruppensupervision',
              'border-red':
                eventDateForm.get('supervisionsType').invalid &&
                eventDateForm.get('supervisionsType').touched,
            }"
            (click)="
              eventDateForm
                .get('supervisionsType')
                .setValue('Gruppensupervision')
            "
            type="button"
            class="hasomed-text-button button-full-width btn-right">
            <span class="hasomed-button-text">Gruppensupervision</span>
          </button>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            eventDateForm.get('supervisionsType')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Behandlungsfall
          <span
            *ngIf="
              isRequired(
                eventDateForm.get('supervisionAppointmentTreatmentCaseIds')
              )
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Wählen Sie einen oder mehrere Behandlungsfälle aus, die in der
          geplanten Sitzung bearbeitet werden sollen.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select">
            <mat-select
              id="supervisionAppointmentTreatmentCaseIds"
              formControlName="supervisionAppointmentTreatmentCaseIds"
              multiple>
              <mat-option
                *ngFor="let availableTreatmentCase of availableTreatmentCases"
                [value]="availableTreatmentCase?.id"
                class="hasomed-text-body">
                Patient: Chiffre ({{ availableTreatmentCase?.patientChiffre }})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            eventDateForm.get('supervisionAppointmentTreatmentCaseIds')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Dauer des Termins in Zeiteinheiten
          <span *ngIf="isRequired(eventDateForm.get('durationInTimeUnits'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Eine Einheit entspricht 45 Minuten.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <mat-form-field class="hasomed-select">
            <mat-select
              id="durationInTimeUnits"
              formControlName="durationInTimeUnits">
              <mat-option
                *ngFor="let duration of [1, 2, 3, 4, 5]"
                [value]="duration"
                class="hasomed-text-body">
                {{ duration }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            eventDateForm.get('durationInTimeUnits')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Datum des Supervisionstermins </span>
        <span class="label-description">
          Definieren Sie den Supervisionstermin.
        </span>
      </div>

      <div class="hasomed-input-field">
        <app-single-event-picker
          [duration]="+eventDateForm.get('durationInTimeUnits').value * 45"
          [startDate]="startDate"
          [endDate]="endDate"
          [parentFormSubmitValidation]="formSubmitted"
          (datesChanged)="onDatesChanged($event)"
          class="formular-row-input"
          formControlName="dateGroup"
          id="dateGroup"></app-single-event-picker>
        <app-error-message
          [formField]="eventDateForm.get('dateGroup')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="roomPlanning">
        <span class="label-title">
          Ort des Supervisionstermins
          <span *ngIf="isRequired(eventDateForm.get('location'))">*</span>
        </span>
        <span class="label-description">
          Wählen Sie zunächst den Ort des Supervisionstermins und rufen Sie
          anschließend die Raumplanung auf oder/und fügen Sie eine URL für ein
          Online-Meeting hinzu.
        </span>
      </label>

      <div class="formular-row-input-columns">
        <div class="flex-r gap-16">
          <button
            *ngFor="
              let eventLocation of EventLocation
                | keyvalue: originalEventLocationOrder
            "
            [ngClass]="{
              'hasomed-button-primary':
                eventDateForm.get('location').value === eventLocation.value,
              'hasomed-button-white-grey':
                eventDateForm.get('location').value != eventLocation.value,
            }"
            (click)="setEventLocation(eventLocation.value)"
            type="button"
            class="hasomed-text-button">
            <span class="hasomed-button-text">
              {{ eventLocation.value }}
            </span>
          </button>
        </div>

        <button
          [class.hasomed-button-red]="roomPlanningValid === false"
          [disabled]="!startDate || !endDate || roomPlanningDisabled"
          (click)="roomPlanningOpen = true"
          id="roomPlanning"
          type="button"
          class="hasomed-text-button hasomed-button-primary button-full-width">
          <i
            class="fa-light fa-arrow-up-right-from-square hasomed-button-icon"></i>
          <span class="hasomed-button-text">
            {{ roomPlanningValid ? 'Raumplanung bearbeiten' : 'Raumplanung' }}
          </span>
        </button>
        <span *ngIf="roomPlanningValid === false" class="flex-r justify-center">
          Nicht alle Termine haben einen Raum
        </span>

        <!-- LINK -->
        <div *ngIf="showLink" class="hasomed-input-field">
          <div class="formular-row-input">
            <label class="formular-row-label link-label" for="videoMeetingLink">
              <span class="label-title">
                URL für Video-Meeting
                <span *ngIf="isRequired(eventDateForm.get('videoMeetingLink'))"
                  >*</span
                >
              </span>
            </label>
            <input
              id="videoMeetingLink"
              class="hasomed-text-input"
              type="text"
              formControlName="videoMeetingLink"
              placeholder="URL für Video-Meeting" />
          </div>
          <app-error-message
            [formField]="
              eventDateForm.get('videoMeetingLink')
            "></app-error-message>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="supervisorIds">
        <span class="label-title">
          Supervisor
          <span *ngIf="isRequired(eventDateForm.get('supervisorId'))">*</span>
        </span>
        <span class="label-description"> Wählen Sie einen Supervisor. </span>
      </label>

      <div class="hasomed-input-field">
        <div class="formular-row-input-columns gap-32">
          <div class="flex-c gap-16 select-user-container">
            <div class="hasomed-search">
              <i class="fa-light fa-search hasomed-search-icon"></i>
              <input
                #supervisorInput
                id="supervisorSearch"
                class="hasomed-search-input"
                type="text"
                placeholder="Personensuche"
                formControlName="supervisorSearch" />
            </div>
            <div
              *ngIf="availableSupervisors"
              [ngClass]="{
                'error-border':
                  eventDateForm.get('supervisorId').invalid &&
                  eventDateForm.get('supervisorId').touched,
              }"
              id="supervisorIds"
              class="flex-c vertical-overflow gap-8 users">
              <div
                *ngFor="
                  let supervisor of availableSupervisors
                    | searchFilter: supervisorInput.value
                "
                [ngClass]="{
                  active:
                    eventDateForm.get('supervisorId').value === supervisor.id,
                }"
                (click)="onSupervisorSelected(supervisor)"
                class="flex-r user-container">
                <div class="flex-r user">
                  <div class="user-icon">
                    <img
                      src="{{
                        supervisor.profilePicture ??
                          '../../../../../assets/img/user.jpg'
                      }}"
                      alt="Profilbild"
                      class="user-icon-image" />
                  </div>
                  <div class="user-info">
                    <span class="hasomed-text-body">
                      {{ getFullName(supervisor) }}
                    </span>
                    <span class="hasomed-text-small">
                      {{ getAllAdditionalQualifications(supervisor) }}
                    </span>
                  </div>
                </div>
                <div
                  *ngIf="
                    eventDateForm.get('supervisorId').value == supervisor.id
                  ">
                  <i class="fa-light fa-circle-check user-check"></i>
                </div>
              </div>
            </div>
            <span
              *ngIf="availableSupervisors?.length === 0"
              class="hasomed-text-small">
              Keine Supervisoren gefunden.
            </span>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="eventDateForm.get('supervisorId')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="description">
        <span class="label-title">
          Beschreibung
          <span *ngIf="isRequired(eventDateForm.get('description'))">*</span>
        </span>
        <span class="label-description"> Ergänzen Sie Informationen. </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="description"
            class="hasomed-textarea-input"
            rows="5"
            formControlName="description"></textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="eventDateForm.get('description')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title">
          Dokumente
          <span *ngIf="isRequired(eventDateForm.get('documentUpload'))">*</span>
        </span>
        <span class="label-description">
          Laden Sie hier Ihre Dokumente hoch.
        </span>
      </label>
      <div class="formular-row-input">
        <div id="documents" class="document-upload-wrapper">
          <app-upload-area-dnd
            [existingFiles]="existingFiles"
            [showFileList]="true"
            [multiple]="false"
            [allowedFileTypes]="allowedFileTypesDocuments"
            [scrollTo]="true"
            [sensitiveDataAlert]="false"
            (openExistingFile)="onOpenExistingFile($event)"
            (downloadExistingFile)="onDownloadExistingFile($event)"
            (deleteExistingFile)="onDeleteExistingFile($event)"
            context="practicalWorkDocumentsUpload"
            inputId="documentUpload"
            formControlName="uploadedFiles"
            maxFileSize="12 MB">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>
  </div>
</form>
