<div class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Praktische Tätigkeit ansehen</h1>
      <h3 class="hasomed-text-h3">Sehen Sie die praktische Tätigkeit ein.</h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onGoBack()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Zurück</span>
      </button>
      <button
        *appCheckPermissions="{
          feature: feature.PRACTICAL_WORK,
          permission: permission.ADMIN,
        }"
        [disabled]="isLoading"
        (click)="onEditPracticalWork()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-pen hasomed-button-icon"></i>
        <span class="hasomed-button-text">Daten bearbeiten</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content-details">
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Praktische Tätigkeit </span>
      </div>
      <div [ngSwitch]="practicalWork?.type" class="detail-value">
        <span *ngSwitchCase="1">
          Psychiatrisch klinische Einrichtung (§2 Abs. 2 Nr. 1 PsychTh-APrV)
        </span>
        <span *ngSwitchCase="2">
          Einrichtung der psychotherapeutischen Versorgung (§2 Abs. 2 Nr. 2
          PsychTh-APrV)
        </span>
        <span *ngSwitchDefault>Keine Angabe</span>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Kooperationspartner</span>
      </div>
      <div class="detail-value">
        {{ practicalWork?.cooperationPartner.name }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="description">
        <span class="label-title">Beschreibung</span>
      </label>
      <div class="detail-value">
        {{ practicalWork?.description ?? '-' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Dauer der Tätigkeit</span>
      </div>
      <div class="detail-value">
        {{ practicalWork?.duration | floatToHoursMinutes }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Zeitraum</span>
      </div>
      <div class="detail-value">
        <i class="fa-light fa-calendar"></i>
        {{ practicalWork?.startDate | date: 'dd.MM.yyyy' }} -
        {{ practicalWork?.endDate | date: 'dd.MM.yyyy' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title">Dokumente</span>
      </label>
      <span
        *ngIf="
          !practicalWork?.files || practicalWork?.files?.length == 0;
          else files
        "
        class="detail-value">
        Keine Dokumente hochgeladen.
      </span>
      <ng-template #files>
        <div class="detail-value-files">
          <app-file-view
            *ngFor="let file of practicalWork?.files"
            [file]="file"
            (downloadFile)="onDownloadFile($event)"
            (openFile)="onOpenFile($event)"></app-file-view>
        </div>
      </ng-template>
    </div>
  </div>
</div>
