<div class="component-content">
  <div class="header">
    <div class="header-title" *ngIf="!isMobile">
      <h1 class="hasomed-text-h1">Kurse suchen</h1>
      <h3 class="hasomed-text-h3">Finden Sie Ihre nächsten Kurse.</h3>
    </div>

    <form class="header-buttons" [formGroup]="searchForm">
      <div class="hasomed-search" *ngIf="showSearchBar">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>
      <div class="filter-buttons">
        <button
          *ngIf="isMobile || isTablet"
          id="openSearchButton"
          [class.hasomed-button-active]="showSearchBar"
          [class.hasomed-button-primary]="
            searchForm.get('searchText').value !== ''
          "
          (click)="showSearchBar = !showSearchBar"
          class="hasomed-text-button hasomed-button-white-grey">
          <i class="fa-light fa-search hasomed-button-icon"></i>
        </button>
        <div
          class="hasomed-date-range"
          [class.daterange-filled]="
            dateRangeIsNotEmpty(searchForm.get('dateRange'))
          ">
          <mat-date-range-input
            class="hasomed-text-input hasomed-date-range-input"
            formGroupName="dateRange"
            [rangePicker]="picker">
            <input
              id="startDate"
              matStartDate
              placeholder="Startdatum"
              formControlName="start"
              readonly />
            <input
              id="endDate"
              matEndDate
              placeholder="Enddatum"
              formControlName="end"
              (dateChange)="applyDateRange()"
              readonly />
          </mat-date-range-input>
          <mat-datepicker-toggle
            class="hasomed-date-range-toggle"
            matIconSuffix
            [for]="picker">
            <mat-icon
              matDatepickerToggleIcon
              class="calendar-toggle-icon fa-light fa-calendar">
            </mat-icon>
          </mat-datepicker-toggle>
          <mat-date-range-picker
            #picker
            [touchUi]="isMobile || isTablet"
            [restoreFocus]="false"></mat-date-range-picker>
        </div>
        <button
          id="filterButton"
          type="button"
          class="hasomed-text-button hasomed-button-white-grey"
          [ngClass]="{
            'hasomed-button-primary': hasActiveFilterValue(courseFilter, true),
            'hasomed-button-white-grey': !hasActiveFilterValue(
              courseFilter,
              true
            ),
            'hasomed-button-active': filterOpened,
          }"
          [matMenuTriggerFor]="filterMenu"
          (onMenuOpen)="filterOpened = true"
          (onMenuClose)="filterOpened = false">
          <i class="fa-light fa-filter-list hasomed-button-icon"></i>
          <span class="hasomed-button-text">Filter</span>
        </button>
      </div>
    </form>
    <!-- Filter Menu -->
    <mat-menu class="filter-overlay" #filterMenu="matMenu">
      <app-filter
        *ngIf="filterOpened && !isLoading"
        [filters]="courseFilter"
        (filterChanged)="courseFilterChanged($event)"
        [courses]="courses">
      </app-filter>
    </mat-menu>
  </div>

  <div *ngIf="isLoading" class="main-content gap-16">
    <div
      class="flex-c course"
      *ngFor="let item of [].constructor(3); let i = index">
      <app-course-detail-skeleton
        class="flex-r course-summary"></app-course-detail-skeleton>
    </div>
  </div>
  <div *ngIf="!isLoading" class="main-content">
    <!-- Filter Tags -->
    <app-filter-tags
      [filters]="courseFilter"
      (filterChanged)="courseFilterChanged($event)">
    </app-filter-tags>
    <div class="flex-c gap-16">
      <div
        *ngFor="
          let course of courses
            | filterCourse: courseFilter
            | searchFilter: searchForm.value.searchText : 'title'
        "
        id="course-{{ course.id }}"
        class="flex-c course">
        <div class="flex-r course-summary">
          <div class="flex-r course-summary-details">
            <div class="flex-r image-container" *ngIf="!isMobile">
              <img
                [src]="course.titlePicture"
                class="course-image"
                alt="Titelbild" />
              <div
                class="label-small label-course-image"
                *ngIf="course.mandatory">
                <i class="label-icon fa-light fa-file-certificate"></i>
                <span class="label-text">Ausbildungsrelevant</span>
              </div>
            </div>

            <div class="flex-c gap-16 justify-space-between w-full">
              <h1
                class="hasomed-text-h1"
                [matTooltip]="course.title?.length > 150 ? course.title : ''">
                {{ course.title | slice: 0 : 150
                }}{{ course.title?.length > 150 ? '...' : '' }}
              </h1>
              <div class="course-metadata">
                <div>
                  <span> {{ getFullNames(course?.lecturers, false) }}, </span>
                  <span class="m-z gray-600">
                    {{ getCourseDateRange(course) }},
                    {{ getCourseStartDate(course) | date: 'HH:mm' }} -
                    {{ getCourseEndDate(course) | date: 'HH:mm' }} Uhr
                  </span>
                </div>
                <app-elog-cell
                  class="flex-r justify-end"
                  *ngIf="course?.isRegistered"
                  [eLogStatusCounts]="course.elogStatusCounts"
                  [shortText]="false"></app-elog-cell>
              </div>
              <mat-divider></mat-divider>
              <div class="course-registration-row">
                <div class="course-additional-info">
                  <div class="flex-r align-center gap-8">
                    <i class="fa-light fa-book hasomed-icon"></i>
                    <span class="hasomed-text-body gray-700">
                      {{ getEducationCourseTitles(course) }}
                    </span>
                  </div>
                  <div *ngIf="course.closed" class="flex-r align-center gap-8">
                    <i class="fa-light fa-lock-keyhole hasomed-icon"></i>
                    <span class="hasomed-text-body gray-700">
                      geschlossen
                    </span>
                  </div>
                  <div *ngIf="!course.closed" class="flex-r align-center gap-8">
                    <i class="fa-light fa-lock-keyhole-open hasomed-icon"></i>
                    <span class="hasomed-text-body gray-700"> offen </span>
                  </div>
                  <div class="flex-r align-center gap-8">
                    <i class="fa-light fa-map-pin hasomed-icon"></i>
                    <span class="hasomed-text-body gray-700">
                      {{ getCourseRoom(course) ?? 'Kein Raum' }}
                    </span>
                  </div>
                  <div class="flex-r align-center gap-8">
                    <i class="fa-light fa-user-pen hasomed-icon"></i>
                    <span class="hasomed-text-body gray-700">
                      {{ course.registeredStudents ?? 0 }} von
                      {{ course.maxParticipants }}
                      angemeldet
                    </span>
                  </div>
                </div>
                <mat-divider *ngIf="isMobile || isTablet"></mat-divider>
                <div class="course-registration-buttons">
                  <button
                    *ngIf="course.isRegistered"
                    class="hasomed-text-button hasomed-button-white-grey"
                    (click)="onCourseStudentChange(course)"
                    [disabled]="isCourseLogoffDisabled(course)"
                    [matTooltip]="getCourseLogoffTooltip(course)">
                    <i class="hasomed-button-icon fa-light fa-right-to-bracket">
                    </i>
                    <span class="hasomed-button-text"> Abmelden </span>
                  </button>
                  <button
                    *ngIf="!course.isRegistered"
                    class="hasomed-text-button hasomed-button-primary"
                    (click)="onCourseStudentChange(course)"
                    [disabled]="isCourseLogonDisabled(course)"
                    [matTooltip]="getCourseLogonTooltip(course)">
                    <i class="hasomed-button-icon fa-light fa-right-to-bracket">
                    </i>
                    <span class="hasomed-button-text"> Anmelden </span>
                  </button>
                  <button
                    class="hasomed-icon-button hasomed-button-white-grey"
                    (click)="showDetails(course)"
                    matTooltip="Details anzeigen">
                    <i
                      class="hasomed-button-icon fa-light fa-arrow-up-right-from-square">
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="hasomed-text-body"
        *ngIf="
          (!courses ||
            courses?.length === 0 ||
            (
              courses
              | filterCourse: courseFilter
              | searchFilter: searchForm.value.searchText : 'title'
            )?.length === 0) &&
          !isLoading
        ">
        Es wurden keine Kurse gefunden!
      </div>
    </div>
  </div>
</div>
