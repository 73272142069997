/**
 * Returns the label for the practical work type
 * @param type 1 or 2
 * @returns The label for the practical work type
 */
export function getPracticalWorkTypeLabel(type: number | null): string {
  if (type === null) return '-';

  if (type === 1)
    return 'Psychiatrisch klinische Einrichtung (§2 Abs. 2 Nr. 1 \
                PsychTh-APrV)';
  if (type === 2)
    return 'Einrichtung der psychotherapeutischen Versorgung (§2 Abs. 2 Nr. \
                2 PsychTh-APrV)';
  return '';
}
