import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { CreateEditAppointmentDialogComponent } from 'src/app/components/shared-components/create-edit-appointment-dialog/create-edit-appointment-dialog.component';
import { Feature, Permission } from 'src/app/models/permission.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard-my-space',
  templateUrl: './dsb-my-space.component.html',
  styleUrls: ['./dsb-my-space.component.scss'],
})
export class DSBMySpaceComponent implements OnInit, OnDestroy {
  @Output() appointmentCreated: Subject<void> = new Subject<void>();
  public showRoomUtilizationCalendar = false;

  // Importing Feature and Permission for use in the template
  Feature = Feature;
  Permission = Permission;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    // check if the user has selected the room utilization calendar
    const selectedCalendar = localStorage.getItem('selectedCalendar');

    if (selectedCalendar === 'room') {
      this.showRoomUtilizationCalendar = true;
    }
  }

  onCreateNewAppointment(): void {
    const dialogRef = this.dialog.open(CreateEditAppointmentDialogComponent, {
      maxWidth: '90dvw',
      maxHeight: '90dvh',
      data: {
        appointmentId: null,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result?.appointmentHasBeenCreated) {
          this.appointmentCreated.next();
        }
      });
  }

  /**
   * getDashboardHeaderTitle
   * returns the title for the dashboard header based on the user role
   * @returns string
   */
  public getDashboardHeaderTitle(): string {
    if (this.userService.currentUserIsStudent()) {
      return 'Ausbildungsüberblick';
    }

    return 'Institutsüberblick';
  }

  /**
   * getDashboardHeaderSubTitle
   * returns the subtitle for the dashboard header based on the user role
   * @returns string
   */
  public getDashboardHeaderSubTitle(): string {
    if (this.userService.currentUserIsStudent()) {
      return 'Die wichtigsten Informationen zu Ihrer Ausbildung.';
    }

    return 'Die wichtigsten Informationen zu Ihrem Institut.';
  }

  /**
   * changes the calendar to show the room utilization or the normal calendar
   * @returns void
   */
  public onChangeCalendar(): void {
    this.showRoomUtilizationCalendar = !this.showRoomUtilizationCalendar;

    // store the current state in the local storage
    localStorage.setItem(
      'selectedCalendar',
      this.showRoomUtilizationCalendar ? 'room' : 'normal'
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
