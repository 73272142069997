import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, filter, first, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { CourseModel } from 'src/app/models/course.model';
import {
  Filter,
  FilterDateRange,
  FilterType,
} from 'src/app/models/filter.model';
import { Role } from 'src/app/models/permission.model';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { FilterService } from 'src/app/services/filter.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserService } from 'src/app/services/user.service';
import {
  getCourseDateRange,
  getCourseEndDate,
  getCourseLogoffTooltip,
  getCourseLogonTooltip,
  getCourseRoom,
  getCourseStartDate,
  getEducationCourseTitles,
  isCourseLogoffDisabled,
  isCourseLogonDisabled,
} from 'src/app/utils/course.utils';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';
import { dateRangeIsNotEmpty } from 'src/app/utils/form.utils';
import { getFullNames } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-theoretical-education',
  templateUrl: './theoretical-education.component.html',
  styleUrls: ['./theoretical-education.component.scss'],
})
export class TheoreticalEducationComponent implements OnInit, OnDestroy {
  private coursesSubject: Subject<CourseModel[]> = new Subject();
  public courses: CourseModel[];

  public selectedCourse: CourseModel;
  public selectedCourseId: number;
  public filterOpened: boolean = false;
  public searchForm: FormGroup = new FormGroup({
    searchText: new FormControl(''),
    dateRange: new FormGroup({
      start: new FormControl(null, Validators.required),
      end: new FormControl(null, Validators.required),
    }),
  });
  public isLoading = true;
  public isMobile = false;
  public isTablet = false;
  public showSearchBar = false;

  public isStudent: boolean = false;

  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;
  @Input() searchText: string;

  private dateRangeSubject: Subject<FilterDateRange | null> = new Subject();
  public dateRangeFilter: Filter = { type: FilterType.DATE_RANGE, value: null };
  public courseFilter: Filter[] = [
    this.dateRangeFilter,
    {
      type: FilterType.COURSE_TYPE,
      value: null,
    },
    {
      type: FilterType.LECTURER,
      value: this.filterService.getLecturerFilterValue(),
    },
    {
      type: FilterType.ROOM_NAME,
      value: null,
    },
  ];
  public hasActiveFilterValue = hasActiveFilterValue;

  private destroy$ = new Subject<void>();

  // import from utils
  public getEducationCourseTitles = getEducationCourseTitles;
  public getCourseRoom = getCourseRoom;
  public getCourseStartDate = getCourseStartDate;
  public getCourseEndDate = getCourseEndDate;
  public getCourseDateRange = getCourseDateRange;
  public getCourseLogonTooltip = getCourseLogonTooltip;
  public getCourseLogoffTooltip = getCourseLogoffTooltip;
  public isCourseLogonDisabled = isCourseLogonDisabled;
  public isCourseLogoffDisabled = isCourseLogoffDisabled;
  public dateRangeIsNotEmpty = dateRangeIsNotEmpty;
  public getFullNames = getFullNames;

  constructor(
    public courseService: CourseService,
    private userService: UserService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private dialog: MatDialog,
    private filterService: FilterService,
    private router: Router,
    private cancellationService: CancellationService,
    private navigationService: NavigationService,
    private mobileUiService: MobileUiService
  ) {}

  public ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event: NavigationEnd) => {
        if (event.url === '/eleguide/education/theoretical-education') {
          this.selectedCourseId = null;
        } else {
          this.selectedCourseId = +atob(
            this.route.firstChild?.snapshot.params['id']
          );
        }
      });

    this.route.firstChild?.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (params['id'] != undefined) {
          this.selectedCourseId = +atob(params['id']);
        }
      });

    this.isStudent = this.userService.currentUser.roleId === Role.STUDENT;
    if (this.isStudent) {
      this.courseFilter.push(
        { type: FilterType.REGISTERED_COURSES, value: null },
        { type: FilterType.FINISHED_COURSES, value: null },
        { type: FilterType.OPEN_COURSES, value: null },
        { type: FilterType.PENDING_ELOGS, value: null }
      );
    }

    const registeredCourses =
      this.route.snapshot.queryParams['registered'] === 'true';

    if (registeredCourses) {
      // set filter to show only registered courses
      this.courseFilter.map(filter => {
        if (filter.type === FilterType.REGISTERED_COURSES) {
          filter.value = true;
        }
        return filter;
      });
    }

    this.dateRangeSubject.pipe(takeUntil(this.destroy$)).subscribe({
      next: dateRange => {
        if (!dateRange) {
          this.searchForm.patchValue({
            dateRange: {
              start: null,
              end: null,
            },
          });
        }

        // update value inside courseFilter
        this.dateRangeFilter.value = dateRange;
        this.courseFilter = this.courseFilter.map(filter => {
          if (filter.type === FilterType.DATE_RANGE) {
            filter.value = dateRange;
          }
          return filter;
        });
      },
    });

    this.getStudentsCourses();
    this.coursesSubject
      .pipe(takeUntil(this.destroy$))
      .subscribe((courses: CourseModel[]) => {
        this.courses = courses;

        // if a course id was passed as an url paramter set the selected course
        if (this.selectedCourseId) {
          this.selectedCourse = this.courses.find(course => {
            course.id === this.selectedCourseId;
          });

          // move selected course to the top of the list
          this.courses.sort((courseA, courseB) => {
            if (courseA.id === this.selectedCourseId) {
              return -1;
            }
            if (courseB.id === this.selectedCourseId) {
              return 1;
            }
            return 0;
          });
        }
        this.isLoading = false;
      });
    this.viewChanges();
  }

  /**
   * sets isMobile and isTablet depending on the current view
   * @returns void
   */
  private viewChanges() {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.isMobile = currentView === 'mobile';
        this.isTablet = currentView === 'tablet';

        this.showSearchBar = currentView === 'desktop';

        // if (!this.isMobile && !this.selectedNewsArticle) {
        //   this.selectedNewsArticle = this.filteredNewsArticles
        //     ? this.filteredNewsArticles[0]
        //     : null;
        // } else if (this.isMobile && this.selectedNewsArticle) {
        //   this.selectedNewsArticle = null;
        // }
      });
  }

  /**
   * gets all courses for the current student in the current institute
   * @returns void
   */
  public getStudentsCourses(): void {
    this.courseService
      .getCurrentStudentsCourses()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.coursesSubject.next(
            await Promise.all(
              response.body.map(
                async (courseData: any): Promise<CourseModel> => {
                  return this.courseService.parseBackendCourse(courseData);
                }
              )
            )
          );
        },
        error: error => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Kurse konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * navigates to the course details page and stores the current url for redirect in the navigation service
   * @param course
   */
  public showDetails(course: CourseModel): void {
    this.navigationService.setOverviewUrl(this.router.url);
    this.router.navigate(['./', btoa(course.id.toString())], {
      relativeTo: this.route,
    });
  }

  /**
   * gets called when the date range filter was changed and applies the new date range
   * @returns void
   */
  public applyDateRange(): void {
    this.dateRangeSubject.next({
      start: this.searchForm.value.dateRange?.start,
      end: this.searchForm.value.dateRange?.end,
    });
  }

  /**
   * courseFilterChanged
   * gets called when the course filter changed
   * @param courseFilter Filter[]
   * @returns void
   */
  public courseFilterChanged(courseFilter: Filter[]): void {
    this.courseFilter = courseFilter;

    if (!courseFilter.includes(this.dateRangeFilter)) {
      courseFilter.push(this.dateRangeFilter);
    }

    this.filterMenuTrigger.closeMenu();

    // find FILTER_TYPE.DATE_RANGE and update date range subject
    const dateRangeFilter = courseFilter.find(
      filter => filter.type === FilterType.DATE_RANGE
    );

    if (dateRangeFilter && !dateRangeFilter.value) {
      this.dateRangeSubject.next(null);
    } else if (dateRangeFilter) {
      this.dateRangeSubject.next(dateRangeFilter.value as FilterDateRange);
    }
  }

  /**
   * onCourseStudentChange
   * @param course
   * @returns void
   */
  public onCourseStudentChange(course: CourseModel): void {
    const dialogTitle = course.isRegistered ? 'Abmelden' : 'Anmelden';
    const dialogMessage = course.isRegistered
      ? `Möchten Sie sich wirklich vom Kurs '${course.title}' abmelden?`
      : `Möchten Sie sich wirklich am Kurs '${course.title}' anmelden?`;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: dialogTitle,
        message: dialogMessage,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(dialogResult => {
        if (!dialogResult) {
          return;
        }
        if (course.isRegistered) {
          this.deregisterFromCourse(course);
        } else {
          this.registerToCourse(course);
        }
      });
  }

  /**
   * register to course
   * @param course the course to register to
   * @returns void
   */
  private registerToCourse(course: CourseModel): void {
    this.courseService
      .registerToCourse(course.id)
      .pipe(first())
      .subscribe({
        next: () => {
          this.getStudentsCourses();

          this.alertService.showSuccessAlert(
            `Das hat geklappt!`,
            `Erfolgreich am Kurs '${course.title}' angemeldet.`
          );
        },
        error: error => {
          const disabledError = error?.error?.errors['CourseDisabledException'];
          if (disabledError) {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              `Der Kurs '${course.title}' wurde deaktiviert und die Anmeldung ist nicht mehr möglich.`
            );
            this.courses.find(c => c.id === course.id).logonEnabled = false;
            return;
          }
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            `Beim anmelden am Kurs '${course.title}' ist ein Fehler aufgetreten.`
          );
        },
      });
  }

  /**
   * deregister from course
   * @param course the course to deregister from
   * @returns void
   */
  private deregisterFromCourse(course: CourseModel): void {
    this.courseService
      .deregisterFromCourse(course.id)
      .pipe(first())
      .subscribe({
        next: () => {
          this.getStudentsCourses();

          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            `Erfolgreich vom Kurs '${course.title}' abgemeldet.`
          );
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            `Beim Abmelden vom Kurs '${course.title}' ist ein Fehler aufgetreten!`
          );
        },
      });
  }

  /**
   * cancels all requests and unsubsribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
