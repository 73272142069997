<div [class.disabled]="disabled" [class.invalid]="invalid" class="file-upload">
  <div
    (fileDropped)="onFileDropped($event)"
    class="file-upload-base"
    [class.drag-hover]="showDropHere"
    appDnd>
    <input
      #fileDropRef
      #fileInput
      [id]="inputId"
      [disabled]="disabled"
      [multiple]="multiple"
      (dragenter)="onDragEnter($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDragLeave($event)"
      (dragend)="onDragLeave($event)"
      (change)="fileBrowseHandler($event)"
      type="file"
      accept="{{ requiredFileMimeTypesString }}" />
    <div *ngIf="showDropHere" class="drop-here-message">
      Datei hier loslassen
    </div>
    <div class="file-upload-icon" [class.opacity-20]="showDropHere">
      <i class="fa-light fa-cloud-arrow-up icon"></i>
    </div>
    <div class="file-upload-text" [class.opacity-20]="showDropHere">
      <span class="hasomed-text-body title">
        <span class="link">Klicken für Upload</span>
        oder ziehen Sie eine Datei auf diese Fläche.
      </span>
      <span class="hasomed-text-small description">
        Dateiformate: {{ allowedFileTypesString }}
      </span>
      <span class="hasomed-text-small description">
        (max. {{ maxFileSize }})
      </span>
    </div>
    <mat-error *ngIf="errorMessage"> {{ errorMessage }} </mat-error>
  </div>
  <app-no-sensitive-data-alert
    *ngIf="
      showNoSensitiveDataAlert && sensitiveDataAlert
    "></app-no-sensitive-data-alert>
  <div *ngIf="showFileList && existingFiles?.length > 0" class="files-list">
    <app-file-view
      *ngFor="let file of existingFiles"
      [deleteEnabled]="true"
      [file]="file"
      (openFile)="onOpenExistingFile($event)"
      (downloadFile)="onDownloadExistingFile($event)"
      (deleteFile)="onDeleteExistingFile($event)"
      class="existing-file"></app-file-view>
  </div>
  <div *ngIf="showFileList && uploadedFiles?.length > 0" class="files-list">
    <div
      *ngFor="let file of uploadedFiles; let i = index"
      class="single-file"
      id="{{ scrollTo ? 'file-' + i : null }}">
      <div class="single-file-content">
        <div class="file-icon">
          <i class="fa-light fa-file"></i>
        </div>
        <div class="file-info">
          <div class="text-info">
            <div class="hasomed-text-body file-name">
              <span>{{ file?.name }}</span>
              <div *ngIf="file.uploaded === true" class="flex-r gap-8">
                <div
                  (click)="onDownload(file)"
                  class="icon-button"
                  matTooltip="Datei herunterladen">
                  <i class="fa-light fa-cloud-arrow-down icon-sm"></i>
                </div>
                <div
                  (click)="onDelete(file)"
                  class="icon-button"
                  matTooltip="Datei löschen">
                  <i class="fa-light fa-trash-can icon-sm"></i>
                </div>
              </div>
              <div
                *ngIf="file.progress == 100 && !file.uploaded"
                class="file-upload-icon">
                <i class="fa-solid fa-check icon"></i>
              </div>
              <div *ngIf="file.progress < 100" class="file-upload-waiting">
                <mat-spinner class="progress-spinner"></mat-spinner>
              </div>
            </div>
            <div class="hasomed-text-small">
              <span>
                {{ formatBytes(file?.size, 2) }}
              </span>
            </div>
          </div>
          <div class="progress-info">
            <div class="progress-bar">
              <app-progress-bar [progress]="file?.progress"></app-progress-bar>
            </div>
            <div class="hasomed-text-small">
              <span> {{ file?.progress }}% </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
