import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-session-expired-dialog',
  templateUrl: './session-expired-dialog.component.html',
  styleUrls: ['./session-expired-dialog.component.scss'],
})
export class SessionExpiredDialogComponent {
  constructor(public dialogRef: MatDialogRef<SessionExpiredDialogComponent>) {
    dialogRef.disableClose = true;
  }

  public onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }
}
