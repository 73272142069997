import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { InstituteModel } from 'src/app/models/institute.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { InstituteService } from 'src/app/services/institute.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-institute-details',
  templateUrl: './institute-details.component.html',
  styleUrl: './institute-details.component.scss',
})
export class InstituteDetailsComponent implements OnDestroy {
  public institute: InstituteModel;
  private instituteId: number;
  public isLoading = true;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private instituteService: InstituteService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private cancellationService: CancellationService
  ) {
    this.instituteId = this.userService.currentUser?.currentInstituteId;

    if (this.activatedRoute.snapshot.params.id) {
      this.instituteId = +atob(this.activatedRoute.snapshot.params.id);
    }

    if (this.instituteId) {
      this.getData(this.instituteId);
    } else {
      this.isLoading = false;
    }
  }

  /**
   * getData
   * get the institute data
   * @param instituteId
   * @returns Promise<void>
   */
  private async getData(instituteId: number): Promise<void> {
    this.instituteService
      .getInstitute(instituteId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.institute = await this.instituteService.parseBackendInstitute(
            response.body
          );
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Daten konnten nicht geladen werden. Bitte erneut versuchen.'
          );
        },
      });
  }

  /**
   * unsubscribe from all subscriptions and cancel all requests
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
