<div
  [ngClass]="{
    'sidenav-opened': !sidenavService.collapsed,
  }"
  class="sidenav">
  <div class="nav-header">
    <!-- Logo and Title Left -->
    <a class="nav-icon" href target>
      <img
        *ngIf="!sidenavService.collapsed"
        width="170"
        alt="EleGuideLogo"
        src="../../../../assets/img/EleGuide_Logo-Fullsize.svg" />
      <img
        *ngIf="sidenavService.collapsed"
        width="40"
        alt="EleGuideLogo"
        src="../../../../assets/img/Eleguide_logo_small.svg" />
    </a>
  </div>

  <div class="nav-area">
    <a
      *appCheckPermissions="{
        feature: feature.DASHBOARD,
        permission: permission.VIEW,
      }"
      [routerLink]="['dashboard']"
      [matTooltip]="sidenavService.collapsed ? 'Mein Bereich' : ''"
      class="hasomed-text-button hasomed-button-nav"
      routerLinkActive="active"
      matTooltipPosition="right">
      <i class="fa-light fa-house hasomed-button-icon"></i>

      <span class="hasomed-button-text">Mein Bereich</span>
    </a>
    <a
      *appCheckPermissions="{
        feature: feature.EDUCATION,
        permission: permission.VIEW,
      }"
      [routerLink]="['education']"
      [matTooltip]="sidenavService.collapsed ? 'Ausbildung' : ''"
      class="hasomed-text-button hasomed-button-nav"
      routerLinkActive="active"
      matTooltipPosition="right">
      <i class="fa-light fa-book hasomed-button-icon"></i>

      <div class="hasomed-button-text">
        <span>Ausbildung</span>
      </div>
    </a>
    <a
      *appCheckPermissions="{
        feature: feature.ORGANIZATION,
        permission: permission.VIEW,
      }"
      [routerLink]="['organization']"
      [matTooltip]="sidenavService.collapsed ? 'Organisation' : ''"
      class="hasomed-text-button hasomed-button-nav"
      routerLinkActive="active"
      matTooltipPosition="right">
      <i class="fa-light fa-user-gear hasomed-button-icon"></i>

      <div *ngIf="!sidenavService.collapsed" class="hasomed-button-text">
        <span>Organisation</span>
      </div>
    </a>
    <a
      *appCheckPermissions="{
        feature: feature.INSTITUTE,
        permission: permission.ADMIN,
      }"
      [routerLink]="['institute']"
      [matTooltip]="sidenavService.collapsed ? 'Institut' : ''"
      class="hasomed-text-button hasomed-button-nav"
      routerLinkActive="active"
      matTooltipPosition="right">
      <i class="fa-light fa-school hasomed-button-icon"></i>
      <div *ngIf="!sidenavService.collapsed" class="hasomed-button-text">
        <span>Institut</span>
      </div>
    </a>
    <a
      *appCheckPermissions="{
        feature: feature.ADMINISTRATION,
        permission: permission.VIEW,
      }"
      [routerLink]="['administration']"
      [matTooltip]="sidenavService.collapsed ? 'Administration' : ''"
      class="hasomed-text-button hasomed-button-nav"
      routerLinkActive="active"
      matTooltipPosition="right">
      <i class="fa-light fa-toolbox hasomed-button-icon"></i>

      <div *ngIf="!sidenavService.collapsed" class="hasomed-button-text">
        <span>Administration</span>
      </div>
    </a>
    <div class="vertical-spacer"></div>
    <button
      [matTooltip]="sidenavService.collapsed ? 'Hilfe und Kontakt' : ''"
      (click)="onOpenFeedbackDialog()"
      class="hasomed-text-button hasomed-button-nav feedback-button"
      matTooltipPosition="right">
      <i class="fa-light fa-message-question hasomed-button-icon"></i>
      <div *ngIf="!sidenavService.collapsed" class="hasomed-button-text">
        <span>Hilfe und Kontakt</span>
      </div>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div class="flex-r justify-end sidenav-footer">
    <button
      [class.hasomed-button-round]="sidenavService.collapsed"
      [class.sidenav-collapse-button]="!sidenavService.collapsed"
      [matTooltip]="
        sidenavService.collapsed
          ? 'Navigation ausklappen'
          : 'Navigation einklappen'
      "
      (click)="sidenavService.toggleSidenav()"
      class="hasomed-icon-button"
      matTooltipPosition="right">
      <div *ngIf="sidenavService.collapsed" class="hasomed-button-icon-sm">
        <i class="fa-light fa-chevrons-right"></i>
      </div>
      <div *ngIf="!sidenavService.collapsed" class="hasomed-button-icon-sm">
        <i class="fa-light fa-chevrons-left"></i>
      </div>
    </button>
  </div>
</div>
