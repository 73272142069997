import * as moment from 'moment';

/**
 * isNotNullOrUndefined
 * checks if the value is not null or undefined
 * @param value
 * @returns boolean
 */
export function isNotNullOrUndefined(value: any): boolean {
  return value !== null && value !== undefined;
}

/**
 * isSameDay
 * checks if two dates are the same day
 * @param date1
 * @param date2
 * @returns boolean
 */
export function isSameDay(date1: Date, date2: Date): boolean {
  return moment(date1).isSame(date2, 'day');
}
