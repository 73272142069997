<form
  [formGroup]="examTypeForm"
  (ngSubmit)="onSubmit()"
  class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{ editMode ? 'Prüfungsart bearbeiten' : 'Prüfungsart hinzufügen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie die Prüfungsart.'
            : 'Erstellen Sie eine weitere Prüfungsart.'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>

      <!-- Submit button -->
      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content-form">
    <!-- name -->
    <div class="formular-row">
      <label class="formular-row-label" for="name">
        <span class="label-title">
          Titel
          <span *ngIf="isRequired(examTypeForm.get('name'))">*</span>
        </span>
        <span class="label-description">
          Geben Sie der Prüfungsart einen Titel.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="name"
            class="hasomed-text-input"
            type="text"
            size="20"
            autocomplete="off"
            formControlName="name"
            placeholder="Prüfungsart" />
        </div>
        <app-error-message
          [formField]="examTypeForm.get('name')"></app-error-message>
      </div>
    </div>
  </div>
</form>
