import * as moment from 'moment';
import { WeekdayEnum } from '../enums/weekday.enum';
import { EventDate } from '../models/event.model';
import { FilterDateRange } from '../models/filter.model';

/**
 * Translates a weekday number to a german weekday string.
 * @param dayNumber The weekday number
 * @returns The german weekday as a string
 * @throws An error if the day number is not between 0 and 6
 */
export function getGermanWeekday(dayNumber: number): string {
  const germanWeekdays = [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ];
  if (dayNumber < 0 || dayNumber > 6) {
    throw new Error('Day number must be between 0 (Sunday) and 6 (Saturday).');
  }
  return germanWeekdays[dayNumber];
}

/**
 * Translates a weekday number to an english weekday string.
 * @param dayNumber the weekday number
 * @returns the english weekday as a string
 */
export function getEnglishWeekday(dayNumber: number): string {
  const englishWeekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  if (dayNumber < 0 || dayNumber > 6) {
    throw new Error('Day number must be between 0 (Sunday) and 6 (Saturday).');
  }
  return englishWeekdays[dayNumber];
}

/**
 * Translates a weekday number to a german weekday abbreviation.
 * @param weekday weekday number
 * @returns The german weekday abbreviation as a string
 */
export function getWeekDayAbbreviation(weekday: WeekdayEnum): string {
  switch (weekday) {
    case WeekdayEnum.SUNDAY:
      return 'So';
    case WeekdayEnum.MONDAY:
      return 'Mo';
    case WeekdayEnum.TUESDAY:
      return 'Di';
    case WeekdayEnum.WEDNESDAY:
      return 'Mi';
    case WeekdayEnum.THURSDAY:
      return 'Do';
    case WeekdayEnum.FRIDAY:
      return 'Fr';
    case WeekdayEnum.SATURDAY:
      return 'Sa';
  }
}

/**
 * Checks if a date is in the past.
 * @param date The date to check
 * @returns True if the date is in the past, false otherwise
 */
export function isDateInRange(
  startDate: Date,
  endDate: Date,
  dateRange: FilterDateRange
) {
  const start = moment(dateRange.start).startOf('day').toDate();
  const end = moment(dateRange.end).endOf('day').toDate();
  return (
    moment(startDate).isSameOrAfter(start) &&
    moment(endDate).isSameOrBefore(end)
  );
}

/**
 * Converts a date to a string in the format 'YYYY-MM-DD'.
 * @param date The date to convert
 * @returns The date as a string in the format 'YYYY-MM-DD'
 */
export function convertDateToDateOnly(date: Date): string | null {
  if (!date) return null;
  return moment(date).format('YYYY-MM-DD');
}

/**
 * checks if a date is in the future
 * @param date the date to check
 * @returns true if the date is in the future, false otherwise
 */
export function dateIsInFuture(date: Date): boolean {
  return new Date(date) > new Date();
}

/**
 * Sorts an array of event dates by their start date.
 * @param eventDates The event dates to sort
 * @returns The sorted event dates
 */
export function sortEventDatesByStartDate(
  eventDates: EventDate[]
): EventDate[] {
  return eventDates.sort((a, b) => {
    if (moment(b.startDate).isBefore(a.startDate)) {
      return 1;
    } else if (moment(a.startDate).isBefore(b.startDate)) {
      return -1;
    } else {
      return 0;
    }
  });
}

/**
 * Maps an array of event dates to an array of event date models.
 * function is needed because the event picker component needs the event dates in a specific format and then updates the event dates in the parent component
 * @param eventDates The event dates to map
 * @returns The mapped event date models
 */
export function mapEventDatesToEventDateModels(
  eventDates: EventDate[]
): EventDate[] {
  return eventDates.map(eventDate => {
    return {
      id: eventDate.id,
      startDate: eventDate.startDate,
      endDate: eventDate.endDate,
      room: eventDate.room,
    };
  });
}
