import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const phoneNumberPattern = '^[- +()/0-9]+$';
    const phoneNumber = control.value;

    const isValid = phoneNumber.match(phoneNumberPattern);

    return isValid ? null : { invalidPhoneNumber: true };
  };
}
