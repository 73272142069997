<form [formGroup]="inviteUserForm" class="flex-c gap-40 dialog-content">
  <div class="flex-c gap-24">
    <div class="flex-r gap-24 justify-space-between">
      <h1 class="hasomed-text-h1">Primärverwalter einladen</h1>
      <button (click)="onCancel()" type="button" class="close-button">
        <i class="fa-light fa-x close-button-icon"></i>
      </button>
    </div>
    <div class="formular-row">
      <label class="formular-row-label" for="firstName">
        <span class="label-title">Vorname</span>
        <span class="label-description">Vorname des Verwalters</span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="firstName"
            class="hasomed-text-input"
            type="text"
            autocomplete="given-name"
            formControlName="firstName"
            placeholder="Max" />
        </div>
        <app-error-message
          [formField]="inviteUserForm.get('firstName')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="lastName">
        <span class="label-title">Nachname</span>
        <span class="label-description">Nachname des Verwalters</span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="lastName"
            class="hasomed-text-input"
            type="text"
            autocomplete="family-name"
            formControlName="lastName"
            placeholder="Mustermann" />
        </div>
        <app-error-message
          [formField]="inviteUserForm.get('lastName')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="email">
        <span class="label-title">E-Mail Adresse</span>
        <span class="label-description">E-Mail Adresse des Verwalters</span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-icon-input">
          <i class="fa-light fa-envelope input-icon"></i>
          <input
            id="email"
            class="hasomed-text-input"
            type="text"
            autocomplete="email"
            formControlName="email"
            placeholder="max.mustermann@example.com" />
        </div>
        <app-error-message
          [formField]="inviteUserForm.get('email')"></app-error-message>
      </div>
    </div>
  </div>
  <div class="flex-r gap-24 justify-space-between">
    <button
      (click)="onCancel()"
      type="button"
      class="hasomed-text-button hasomed-button-white-grey">
      <span class="hasomed-button-text">Abbrechen</span>
    </button>
    <button
      (click)="onConfirm()"
      type="submit"
      class="hasomed-text-button hasomed-button-primary">
      <i class="hasomed-button-icon fa-light fa-paper-plane"></i>
      <span class="hasomed-button-text">Einladen</span>
    </button>
  </div>
</form>
