import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs';
import { availableRolesSelectOptions } from 'src/app/constants/role-options.constant';
import { Role } from 'src/app/models/permission.model';
import { UserModel } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-switch-role-dialog',
  templateUrl: './switch-role-dialog.component.html',
  styleUrl: './switch-role-dialog.component.scss',
})
export class SwitchRoleDialogComponent {
  private user: UserModel = this.data.user;
  public roleForm: FormGroup = new FormGroup({
    roleId: new FormControl(this.user.roleId, Validators.required),
  });

  public Role = Role;
  public availableRolesSelectOptions = availableRolesSelectOptions;

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<SwitchRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: UserModel }
  ) {}

  /**
   * onConfirm
   * Switches the role of the user and closes the dialog.
   * @returns void
   */
  public onConfirm(): void {
    this.userService
      .switchUsersRole(this.user.id, this.roleForm.value.roleId)
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Die Rolle wurde erfolgreich geändert.'
          );
          this.dialogRef.close(this.roleForm.value.roleId);
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Rolle konnte nicht geändert werden.'
          );
        },
      });
  }

  /**
   * Returns the role control form control.
   * @returns The role control form control.
   */
  get roleControl(): FormControl {
    return this.roleForm.get('roleId') as FormControl;
  }

  /**
   * Closes the dialog.
   * @returns void
   */
  public onCancel() {
    this.dialogRef.close(false);
  }
}
