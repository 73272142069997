import { NgModule } from '@angular/core';

import { DSBDigitalBulletinBoardComponent } from './dashboard-digital-bulletin-board/dsb-digital-bulletin-board.component';
import { DSBMessagesComponent } from './dashboard-messages/dsb-messages.component';
import { DSBMySpaceComponent } from './dashboard-my-space/dsb-my-space.component';
import { DashboardComponent } from './dashboard.component';

import { MaterialModule } from 'src/app/modules/material.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/modules/shared.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DSBInstituteNewsComponent } from './dashboard-institute-news/dsb-institute-news.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MySpaceHeaderComponent } from './dashboard-my-space/my-space-header/my-space-header.component';
import { CalendarComponent } from 'src/app/components/eleguide/dashboard/dashboard-my-space/calendar/calendar.component';
import { CalendarEventDetailComponent } from './dashboard-my-space/calendar/calendar-event-detail/calendar-event-detail.component';
import { CreateEditNewsArticleComponent } from './dashboard-institute-news/create-edit-news-article/create-edit-news-article.component';
import { CreateEditPageComponent } from './wiki/create-edit-page/create-edit-page.component';
import { WikiComponent } from './wiki/wiki.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DocumentsComponent } from './documents/documents.component';
import { RoomUtilizationCalendarComponent } from './dashboard-my-space/room-utilization-calendar/room-utilization-calendar.component';
import { CreateEditFileComponent } from './documents/create-edit-file/create-edit-file.component';

@NgModule({
  declarations: [
    DashboardComponent,
    MySpaceHeaderComponent,
    DSBMySpaceComponent,
    CalendarComponent,
    CalendarEventDetailComponent,
    DSBDigitalBulletinBoardComponent,
    DSBMessagesComponent,
    DocumentsComponent,
    DSBInstituteNewsComponent,
    CreateEditNewsArticleComponent,
    WikiComponent,
    CreateEditPageComponent,
    RoomUtilizationCalendarComponent,
    CreateEditFileComponent,
  ],
  imports: [
    MaterialModule,
    RouterModule,
    FullCalendarModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    EditorModule,
  ],
  exports: [DashboardComponent],
  providers: [],
})
export class DashboardModule {}
