import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstituteDetailsComponent } from './institute-details/institute-details.component';
import { InstituteComponent } from './institute.component';
import { MaterialModule } from 'src/app/modules/material.module';
import { RouterModule } from '@angular/router';
import { ColorPickerModule } from 'ngx-color-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/modules/shared.module';
import { CreateEditInstituteComponent } from './create-edit-institute/create-edit-institute.component';
import { LearningContentsComponent } from './learning-contents/learning-contents.component';
import { CreateEditLearningContentComponent } from './learning-contents/create-edit-learning-content/create-edit-learning-content.component';
import { RoomsComponent } from './rooms/rooms.component';
import { CreateEditRoomComponent } from './rooms/create-edit-room/create-edit-room.component';
import { RoomDetailsComponent } from './rooms/room-details/room-details.component';
import { ExamTypesComponent } from './exam-types/exam-types.component';
import { CreateEditExamTypeComponent } from './exam-types/create-edit-exam-type/create-edit-exam-type.component';
import { EducationCoursesComponent } from './education-courses/education-courses.component';
import { CreateEditEducationCourseComponent } from './education-courses/create-edit-education-course/create-edit-education-course.component';
import { EducationCourseDetailsComponent } from './education-courses/education-course-details/education-course-details.component';
import { MembersComponent } from './members/members.component';
import { InviteMemberComponent } from './members/invite-member/invite-member.component';
import { ViewMemberComponent } from './members/view-member/view-member.component';

@NgModule({
  declarations: [
    InstituteComponent,
    InstituteDetailsComponent,
    CreateEditInstituteComponent,
    LearningContentsComponent,
    CreateEditLearningContentComponent,
    RoomsComponent,
    CreateEditRoomComponent,
    RoomDetailsComponent,
    ExamTypesComponent,
    CreateEditExamTypeComponent,
    EducationCoursesComponent,
    CreateEditEducationCourseComponent,
    EducationCourseDetailsComponent,
    MembersComponent,
    InviteMemberComponent,
    ViewMemberComponent,
  ],
  imports: [
    MaterialModule,
    RouterModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [InstituteComponent],
})
export class InstituteModule {}
