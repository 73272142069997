<div class="component-content">
  <div class="header">
    <div class="header-title" *ngIf="!isMobile">
      <span class="hasomed-text-h1">Mitgliederübersicht</span>
      <span class="hasomed-text-h3">
        Verwalten Sie die Mitglieder oder laden Sie neue Mitglieder ein.
      </span>
    </div>
    <form [formGroup]="searchForm" class="header-buttons">
      <!-- Search -->
      <div class="hasomed-search" *ngIf="showSearchBar">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          #input
          (keyup)="applySearch($event)"
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche" />
      </div>
      <div class="filter-buttons">
        <button
          *ngIf="isMobile || isTablet"
          id="openSearchButton"
          [class.hasomed-button-active]="showSearchBar"
          [class.hasomed-button-primary]="
            searchForm.get('searchText').value !== ''
          "
          (click)="showSearchBar = !showSearchBar"
          class="hasomed-text-button hasomed-button-white-grey">
          <i class="fa-light fa-search hasomed-button-icon"></i>
        </button>
        <!-- Filter Button -->
        <button
          id="filterButton"
          [ngClass]="{
            'hasomed-button-primary': hasActiveFilterValue(membersFilter, true),
            'hasomed-button-white-grey': !hasActiveFilterValue(
              membersFilter,
              true
            ),
            'hasomed-button-active': filterOpened,
          }"
          [matMenuTriggerFor]="filterMenu"
          (onMenuOpen)="filterOpened = true"
          (onMenuClose)="filterOpened = false"
          type="button"
          class="hasomed-text-button hasomed-button-white-grey">
          <i class="fa-light fa-filter-list hasomed-button-icon"></i>
          <span class="hasomed-button-text">Filter</span>
        </button>

        <!-- Filter Menu -->
        <mat-menu #filterMenu="matMenu" class="filter-overlay">
          <app-filter
            *ngIf="filterOpened"
            [filters]="membersFilter"
            (filterChanged)="membersFilterChanged($event)">
          </app-filter>
        </mat-menu>
      </div>

      <!-- confirm all unconfirmed users button -->
      <button
        *ngIf="!isMobile"
        [disabled]="!hasUnconfirmedUsers"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="confirmAllUsers()">
        <i class="fa-light fa-user-check hasomed-button-icon"></i>
        <span class="hasomed-button-text">Alle Bestätigen</span>
      </button>

      <!-- invite members button -->
      <button
        (click)="onInvite()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="hasomed-button-icon fa-light fa-user-plus"></i>
        <span class="hasomed-button-text">
          {{ isMobile ? '' : 'Neuen' }} Nutzer einladen
        </span>
      </button>
    </form>
  </div>

  <div class="main-content">
    <!-- Filter Tags -->
    <app-filter-tags
      [filters]="membersFilter"
      (filterChanged)="membersFilterChanged($event)">
    </app-filter-tags>

    <div
      [class.table-bicolor]="dataSource?.filteredData?.length > 1"
      class="table">
      <table [dataSource]="dataSource" mat-table matSort>
        <!-- Name column -->
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Mitgliedername
            <app-custom-table-sort-icon [sort]="sort" columnName="name">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let row; dataSource: dataSource"
            class="cursor-pointer"
            mat-cell>
            <div class="user-container">
              <div
                *ngIf="!isMobile"
                class="user-icon"
                (click)="openProfilePictureDialog(row)"
                matTooltip="Profilbild">
                <img
                  src="{{ row.profilePicture ?? '../assets/img/user.jpg' }}"
                  alt
                  class="user-icon-image" />
              </div>
              <div class="user-info" (click)="onOpen(row)">
                <span class="user-name hasomed-text-body">
                  {{ getFullName(row) ?? row?.email }}
                </span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- email column -->
        <ng-container matColumnDef="email">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            E-Mail
            <app-custom-table-sort-icon [sort]="sort" columnName="email">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{ row?.email ?? '-' }}
          </td>
        </ng-container>

        <!-- entryDate column -->
        <ng-container matColumnDef="entryDate">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Eintrittsdatum
            <app-custom-table-sort-icon [sort]="sort" columnName="entryDate">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            {{ (row?.entryDate | date: 'dd.MM.yyyy') ?? 'offen' }}
          </td>
        </ng-container>

        <!-- logonState column -->
        <ng-container matColumnDef="logonState">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            {{ isMobile ? 'Status' : 'Anmeldestatus' }}
            <app-custom-table-sort-icon [sort]="sort" columnName="logonState">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            <span class="hasomed-text-body">
              <ng-container *ngIf="row?.isConfirmedByAdmin; else unconfirmed">
                Beigetreten
              </ng-container>
              <ng-template #unconfirmed>
                <ng-container
                  *ngIf="
                    !row?.isConfirmedByAdmin && row?.encryptionKeysGenerated;
                    else invited
                  ">
                  <button
                    type="button"
                    (click)="confirmUser(row)"
                    class="hasomed-text-button-small hasomed-button-primary">
                    <i class="fa-light fa-user-check hasomed-button-icon"></i>
                    <span class="hasomed-button-text">Bestätigen</span>
                  </button>
                </ng-container>
              </ng-template>
              <ng-template #invited>
                <ng-container *ngIf="row?.isInvited; else emptyState">
                  Eingeladen
                </ng-container>
              </ng-template>
              <ng-template #emptyState>-</ng-template>
            </span>
          </td>
        </ng-container>

        <!-- role column -->
        <ng-container matColumnDef="role">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            <span>Rolle</span>
            <app-custom-table-sort-icon [sort]="sort" columnName="role">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            <div
              [style.background]="getLabelBackgroundColor(row?.roleId)"
              class="label">
              <i class="fa-light fa-id-badge label-icon"></i>
              <span class="label-text">
                {{ getRoleName(row?.roleId) }}
              </span>
            </div>
          </td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="center-header-cell" mat-header-cell>
            {{ isMobile ? 'Akt.' : 'Aktionen' }}
          </th>
          <td *matCellDef="let row; dataSource: dataSource" mat-cell>
            <div class="table-button-icons">
              <button
                #t="matMenuTrigger"
                [matMenuTriggerFor]="actionsMenu"
                type="button"
                class="table-button-icon"
                matTooltip="Benutzer Aktionen">
                <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
              </button>

              <mat-menu #actionsMenu="matMenu" class="action-menu">
                <button (click)="onOpen(row)" mat-menu-item type="button">
                  <div class="action-menu-item">
                    <i
                      class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Anzeigen</span>
                  </div>
                </button>
                <mat-divider class="menu-divider"></mat-divider>
                <button
                  *ngIf="!row?.encryptionKeysGenerated"
                  (click)="onInviteAgain(row)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-user-plus icon-sm"></i>
                    <span class="hasomed-text-body gray-600"
                      >Erneut einladen</span
                    >
                  </div>
                </button>
                <mat-divider
                  *ngIf="!row?.encryptionKeysGenerated"
                  class="menu-divider"></mat-divider>
                <button
                  *ngIf="!isCurrentUser(row)"
                  (click)="onEditRole(row)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-user-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600"
                      >Rolle bearbeiten</span
                    >
                  </div>
                </button>
                <mat-divider
                  *ngIf="!isCurrentUser(row)"
                  class="menu-divider"></mat-divider>
                <button (click)="onEdit(row)" mat-menu-item type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-pen icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Bearbeiten</span>
                  </div>
                </button>
                <mat-divider
                  *ngIf="!isCurrentUser(row) && !row?.isPrimaryAdmin"
                  class="menu-divider"></mat-divider>
                <button
                  *ngIf="!isCurrentUser(row) && !row?.isPrimaryAdmin"
                  (click)="onDelete(row)"
                  mat-menu-item
                  type="button">
                  <div class="action-menu-item">
                    <i class="fa-light fa-trash-can icon-sm"></i>
                    <span class="hasomed-text-body gray-600">Löschen</span>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="displayedColumns.length" class="mat-cell">
              <div *ngIf="isLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!isLoading"
                class="table-no-match hasomed-text-body font-grey">
                <span> Es wurden keine Mitglieder gefunden. </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <mat-divider></mat-divider>
      <mat-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="isMobile ? 0 : 1"
        custom-paginator
        aria-label="Select page"></mat-paginator>
    </div>
  </div>
</div>
