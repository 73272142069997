import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  InstituteCreateModel,
  InstituteModel,
  InstituteUpdateModel,
} from '../models/institute.model';
import { OpeningHourModel } from '../models/opening-hour.model';
import { UserCreateModel } from '../models/user.model';
import {
  parseBackendOpeningHourModel,
  sortOpeningHoursByDay,
} from '../utils/opening-hour.utils';
import { CancellationService } from './cancellation.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class InstituteService {
  public currentInstitute?: InstituteModel;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private cancellationService: CancellationService,
    private userService: UserService
  ) {}

  /**
   * createInstitute
   * creates a new Institute
   * @param instituteCreateModel Institute
   * @returns Observable<HttpResponse<any>>
   */
  public createInstitute(
    instituteCreateModel: InstituteCreateModel
  ): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(
      this.config.backendUrl + '/api/institutes',
      instituteCreateModel,
      { headers: headers, responseType: 'json', observe: 'response' }
    );
  }

  /**
   * updateInstitute
   * updates an existing Institut
   * @param instituteId number
   * @param instituteUpdateModel Institut
   * @returns Observable<HttpResponse<any>>
   */
  public updateInstitute(
    instituteId: number,
    instituteUpdateModel: InstituteUpdateModel
  ): Observable<HttpResponse<any>> {
    return this.http.put(
      this.config.backendUrl + `/api/institutes/${instituteId}`,
      instituteUpdateModel,
      {
        responseType: 'json',
        observe: 'response',
      }
    );
  }

  /**
   * setCurrentInstitut
   * sets the current Institut
   * @param institute Institut
   * @returns void
   */
  public setCurrentInstitute(institute: InstituteModel) {
    this.currentInstitute = institute;
  }

  /**
   * getInsistut
   * gets an Institut by id
   * @param instituteId number
   * @returns Observable<HttpResponse<any>>
   */
  public getInstitute(instituteId: number): Observable<HttpResponse<any>> {
    return this.http
      .get(this.config.backendUrl + `/api/institutes/${instituteId}`, {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getAllInsistutes
   * gets all Institutes
   * @returns Observable<HttpResponse<any>>
   */
  public getAllInstitutes(): Observable<HttpResponse<any>> {
    return this.http
      .get(this.config.backendUrl + '/api/institutes', {
        responseType: 'json',
        observe: 'response',
      })
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getInstitutesByUser
   * gets all institutes of the current user
   * @returns Observable<any>
   */
  public getCurrentUsersInstitutes(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl + '/api/institutes/current-users-institutes',
        {
          responseType: 'json',
          observe: 'response',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * Get the opening hours of an institute
   * @param instituteId The institute id
   * @returns Observable<HttpResponse<any>>
   */
  public getInstituteOpeningHours(
    instituteId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl + `/api/institutes/${instituteId}/opening-hours`,
        {
          responseType: 'json',
          observe: 'response',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * deleteInstitute
   * deletes an Institute
   * @param id number
   * @returns Observable<any>
   */
  public deleteInstitute(id: number): Observable<HttpResponse<any>> {
    return this.http.delete(this.config.backendUrl + `/api/institutes/${id}`, {
      responseType: 'json',
      observe: 'response',
    });
  }

  /**
   * invitePrimaryAdmin
   * invites a primary admin to an institute
   * @param instituteId number
   * @param userCreateModel UserCreateModel
   * @returns Observable<HttpResponse<any>>
   */
  public invitePrimaryAdmin(
    instituteId: number,
    userCreateModel: UserCreateModel
  ): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      this.config.backendUrl +
        `/api/institutes/${instituteId}/invite-primary-admin`,
      userCreateModel,
      { headers: headers, observe: 'response', responseType: 'json' }
    );
  }

  /**
   * Calls the backend to mark the encryption keys as generated for the institute
   * @param instituteId number
   * @returns Observable<HttpResponse<any>>
   */
  public markKeysAsGenerated(
    instituteId: number
  ): Observable<HttpResponse<any>> {
    return this.http.patch(
      this.config.backendUrl +
        `/api/institutes/${instituteId}/mark-keys-as-generated`,
      {},
      {
        responseType: 'json',
        observe: 'response',
      }
    );
  }

  /**
   * parseBackendInstitute
   * parses the backend institute
   * @param instituteData InstituteModel
   * @returns Promise<InstituteModel>
   */
  public async parseBackendInstitute(
    instituteData: InstituteModel
  ): Promise<InstituteModel> {
    return {
      id: instituteData.id,
      name: instituteData.name,
      description: instituteData.description,
      logo: instituteData.logo,
      email: instituteData.email,
      phone: instituteData.phone,
      website: instituteData.website,
      address: instituteData.address,
      openingHours: sortOpeningHoursByDay(
        instituteData.openingHours.map((openingHour: OpeningHourModel) => {
          return parseBackendOpeningHourModel(openingHour);
        })
      ),
      primaryAdmin:
        instituteData.primaryAdmin &&
        (await this.userService.parseBackendUser(instituteData.primaryAdmin)),
      encryptionKeysGenerated: Boolean(instituteData.encryptionKeysGenerated),
    };
  }
}
