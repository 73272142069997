import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  Label,
  LabelCreateModel,
  LabelUpdateModel,
} from '../models/label.model';
import { CancellationService } from './cancellation.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  private instituteId: number =
    this.userService.currentUser?.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService,
    private cancellationService: CancellationService
  ) {}

  private availableColors: string[] = [
    '#F6A500',
    '#D26405',
    '#F93816',
    '#FF6584',
    '#49881D',
    '#79B63F',
    '#4CD68B',
    '#192A51',
    '#004976',
    '#573EE9',
    '#436EF0',
    '#667085',
    '#BBBDBE',
    '#000000',
  ];

  /**
   * getAvailableColors
   * returns all available colors
   * @returns string[]
   */
  public getAvailableColors(): string[] {
    return this.availableColors;
  }

  /**
   * getAllLabels
   * gets all labels from the current institute
   * @returns Observable<HttpResponse<any>>
   */
  public getAllLabels(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl + `/api/institutes/${this.instituteId}/labels`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createLabel
   * creates a new label
   * @param labelCreateModel
   * @returns Observable<HttpResponse<any>>
   */
  public createLabel(
    labelCreateModel: LabelCreateModel
  ): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      this.config.backendUrl + `/api/institutes/${this.instituteId}/labels`,
      labelCreateModel,
      { headers: headers, observe: 'response', responseType: 'json' }
    );
  }

  /**
   * updateLabel
   * updates a label
   * @param labelUpdateModel
   * @returns Observable<HttpResponse<any>>
   */
  public updateLabel(
    labelId: number,
    labelUpdateModel: LabelUpdateModel
  ): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/labels/${labelId}`,
      labelUpdateModel,
      { headers: headers, observe: 'response', responseType: 'json' }
    );
  }

  /**
   * deleteLabel
   * deletes a label
   * @param labelId
   * @returns Observable<HttpResponse<any>>
   */
  public deleteLabel(labelId: number): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/labels/${labelId}`,
      { observe: 'response', responseType: 'json' }
    );
  }

  /**
   * parseBackendLabel
   * parses a label from the backend
   * @param label
   * @returns Label
   */
  public async parseBackendLabel(label: Label): Promise<Label> {
    return {
      id: label.id,
      name: label.name,
      color: label.color,
      creator:
        label.creator &&
        (await this.userService.parseBackendUser(label.creator)),
      timeCreated:
        label.timeCreated &&
        moment(label.timeCreated).tz('Europe/Berlin').toDate(),
      timeModified:
        label.timeModified &&
        moment(label.timeModified).tz('Europe/Berlin').toDate(),
    };
  }
}
