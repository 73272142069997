<mat-form-field class="eleguide-select-form-field">
  <mat-select
    [id]="controlId"
    [formControl]="control"
    [placeholder]="placeholder"
    (selectionChange)="
      multiple
        ? onMultipleValuesChanges($event, index)
        : onSingleValueChanges($event, index)
    "
    [multiple]="multiple">
    <mat-option *ngIf="showNullOption" [value]="null">
      {{ nullOptionLabel }}
    </mat-option>

    <mat-divider
      *ngIf="showDivider && showNullOption"
      class="option-divider"></mat-divider>

    <app-select-check-all
      *ngIf="showCheckAll"
      [control]="control"
      [values]="getAllOptionValues"></app-select-check-all>

    <mat-divider
      *ngIf="showDivider && showCheckAll"
      class="option-divider"></mat-divider>

    <ng-container *ngFor="let option of options; let last = last">
      <mat-option [value]="option.value">
        {{ option.label }}
      </mat-option>
      <mat-divider
        *ngIf="showDivider && !last"
        class="option-divider"></mat-divider>
    </ng-container>
  </mat-select>
</mat-form-field>
