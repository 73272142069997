import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './components/authentication/callback/callback.component';
import { LogoutComponent } from './components/authentication/logout/logout.component';
import { AuthGuard } from './guards/auth.guard';
import { CryptoKeyResolver } from './resolver/crypto-key.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'eleguide',
    pathMatch: 'full',
  },
  { path: 'logout', component: LogoutComponent },
  {
    path: 'callback',
    component: CallbackComponent,
    resolve: {
      CryptoKeyResolver,
    },
  },
  {
    path: 'eleguide',
    loadChildren: () =>
      import(`./components/eleguide/eleguide-routing.module`).then(
        m => m.EleGuideRoutingModule
      ),
    canActivate: [AuthGuard],
    resolve: {
      CryptoKeyResolver,
    },
  },
  { path: '**', redirectTo: 'eleguide' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
