import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { first, Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
    private cookieService: CookieService
  ) {}

  /**
   * cbecks if the session is expired and if the user is authenticated
   * if the user is not authenticated, it will redirect to the login page
   * if the user is authenticated but not logged in, it will log the user in
   * @returns Promise<boolean>
   */
  public async isAuthenticated(): Promise<Boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      // check if session is expired
      if (this.isSessionExpired()) {
        this.saveCurrentUrlAndRedirectToLogin(this.router.url);
        return;
      }
      // check if the user is logged in via TGP
      this.isLoggedIn()
        .pipe(first())
        .subscribe({
          next: response => {
            if (response.body === 'true') {
              // user is logged in via TGP but not in local account
              if (!this.userService.currentUser) {
                this.loginUser()
                  .pipe(first())
                  .subscribe({
                    next: async response => {
                      this.userService.setEncryptedCurrentUser(response.body);

                      if (
                        (!this.userService.currentUser?.currentInstituteId ||
                          !this.userService.currentUser?.roleId) &&
                        !this.userService.currentUser?.isSuperadmin
                      ) {
                        resolve(false);
                      }
                      resolve(true);
                    },
                    error: error => {
                      resolve(false);
                    },
                  });
                // user is logged in via TGP and in local account
              } else {
                resolve(true);
              }
            } else {
              resolve(false);
            }
          },
          error: error => {
            resolve(false);
          },
        });
    });
  }

  /**
   * check if session cookie is missing or expired
   * @returns
   */
  public isSessionExpired(): boolean {
    const sessionTimeoutCookie = this.cookieService.get(
      this.config.sessionTimeoutCookieName
    );

    if (!sessionTimeoutCookie) {
      return true;
    }

    const sessionTimeout = moment.utc(
      sessionTimeoutCookie,
      'MM/DD/YYYY HH:mm:ss'
    );
    return moment().isAfter(sessionTimeout);
  }

  /**
   * check if user is logged in through tgp
   * @returns
   */
  public isLoggedIn(): Observable<any> {
    return this.http.get(this.config.backendUrl + '/isLoggedIn', {
      responseType: 'text',
      observe: 'response',
    });
  }

  /**
   * login user to tgp
   * @returns
   */
  public loginTgp(): Observable<any> {
    return this.http.get(this.config.backendUrl + '/login', {
      responseType: 'text',
      observe: 'response',
    });
  }

  /**
   * logout user from tgp
   * @returns
   */
  public logoutTgp(): Observable<any> {
    return this.http.get(this.config.backendUrl + '/logout', {
      responseType: 'text',
      observe: 'response',
    });
  }

  /**
   * login cidaas user to local account
   * @returns
   */
  public loginUser(): Observable<any> {
    return this.http.post(this.config.backendUrl + '/api/users/login', null, {
      responseType: 'json',
      observe: 'response',
    });
  }

  /**
   * logout the user from tgp and redirect to logout page
   * @returns void
   */
  public logout(): void {
    this.logoutTgp()
      .pipe(first())
      .subscribe({
        next: response => {
          if (response.status == 200) {
            this.router.navigate(['logout']);
          }
        },
        error: error => {
          this.router.navigate(['logout']);
        },
      });
  }

  /**
   * stores the current url in the local storage and redirects to the login page
   * the user will be redirected to the stored url after login
   * @param url the current url
   */
  public saveCurrentUrlAndRedirectToLogin(url: string): void {
    if (url === '/logout') {
      url = '/';
    }
    localStorage.setItem('loginRedirectUrl', url);
    window.location.href = this.config.backendUrl + '/login';
  }
}
