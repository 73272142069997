import { Component, Input } from '@angular/core';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-custom-table-sort-icon',
  templateUrl: './custom-table-sort-icon.component.html',
  styleUrls: ['./custom-table-sort-icon.component.scss'],
})
export class CustomTableSortIconComponent {
  @Input() sort: MatSort;
  @Input() columnName: string;
}
