import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import { eLog, eLogStatus } from '../models/elog.model';
import { CancellationService } from './cancellation.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ElogService {
  private instituteId: number = this.userService.currentUser?.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService,
    private cancellationService: CancellationService
  ) {}

  /**
   * getAllCourseEventElogs
   * @param courseEventDateId
   * @returns Observable<HttpResponse<any>>
   */
  public getAllCourseEventElogs(
    courseEventDateId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/elogs/course-event-dates/${courseEventDateId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * updateElog
   * Update a single eLog
   * @param courseEventElog
   * @returns Observable<HttpResponse<any>>
   */
  public updateElog(
    eLogId: number,
    elogStatus: eLogStatus
  ): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/elogs/${eLogId}`,
      { status: elogStatus },
      { headers: headers, observe: 'response', responseType: 'json' }
    );
  }

  /**
   * getNumberOfPendingElogsFromLecturer
   * Get the number of pending eLogs from the current lecturer
   * @returns Observable<HttpResponse<any>>
   */
  public getNumberOfPendingElogsFromLecturer(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/elogs/count/lecturer`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getNumberOfPendingElogsFromInstitute
   * Get the number of pending eLogs from the current institute
   * @returns Observable<HttpResponse<any>>
   */
  public getNumberOfPendingElogsFromInstitute(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/elogs/count`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getElogsByCourseIdAndUserId
   * Get eLogs by course id and user id
   * @param courseId
   * @param userId
   * @returns Observable<HttpResponse<any>>
   */
  public getElogsByCourseIdAndUserId(
    courseId: number,
    userId: number
  ): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/elogs/courses/${courseId}/students/${userId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * parseBackendElog
   * Parse the backend eLog to the frontend eLog
   * @param elog
   * @returns Promise<eLog>
   */
  public async parseBackendElog(elog: eLog): Promise<eLog> {
    return {
      id: elog.id,
      user: elog.user && (await this.userService.parseBackendUser(elog.user)),
      status: elog.status,
      courseEventDate: elog.courseEventDate
        ? {
            id: elog.courseEventDate.id,
            startDate: moment(elog.courseEventDate.startDate)
              .tz('Europe/Berlin')
              .toDate(),
            endDate: moment(elog.courseEventDate.endDate)
              .tz('Europe/Berlin')
              .toDate(),
            room: elog.courseEventDate.room,
          }
        : null,
    };
  }
}
