import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  constructor() {}

  // Variables
  public collapsed =
    localStorage.getItem('sidenavCollapsed') === 'false' ? false : true;

  public sidenavChangedSubject = new Subject<boolean>();

  toggleSidenav() {
    this.collapsed = !this.collapsed;
    localStorage.setItem('sidenavCollapsed', this.collapsed.toString());

    this.sidenavChangedSubject.next(this.collapsed);
  }
}
