<div class="flex-r gap-16">
  <!-- Table -->
  <div class="flex-c flex-1">
    <div
      class="table"
      [class.table-bicolor]="dataSource?.filteredData?.length > 1">
      <table [dataSource]="dataSource" mat-table matSort>
        <!-- Course title column -->
        <ng-container matColumnDef="title">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Kurs
            <app-custom-table-sort-icon [sort]="sort" columnName="title">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let course; dataSource: dataSource"
            (click)="openCourseDetails(course.id)"
            mat-cell
            class="max-width-400 cursor-pointer cell-overflow-hidden">
            <span
              [matTooltip]="course.title"
              class="gray-900 hasomed-text-body text-overflow-hidden">
              {{ course.title }}
            </span>
          </td>
        </ng-container>

        <!-- Course Dates Column -->
        <ng-container matColumnDef="dates">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Kurstermine
            <app-custom-table-sort-icon [sort]="sort" columnName="dates">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let course; dataSource: dataSource" mat-cell>
            {{ getCourseDateRange(course) }}
          </td>
        </ng-container>

        <!-- Course room column -->
        <ng-container matColumnDef="room">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Räume
            <app-custom-table-sort-icon [sort]="sort" columnName="rooms">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let course; dataSource: dataSource"
            class="hasomed-text-body text-overflow-hidden gray-600"
            mat-cell>
            {{ getCourseRoom(course) ?? '-' }}
          </td>
        </ng-container>

        <!-- participantAmount column -->
        <ng-container matColumnDef="participantAmount">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>
            Teilnehmeranzahl
            <app-custom-table-sort-icon
              [sort]="sort"
              columnName="participantAmount">
            </app-custom-table-sort-icon>
          </th>
          <td *matCellDef="let course; dataSource: dataSource" mat-cell>
            <span>
              {{
                course.registeredStudents == 1
                  ? course.registeredStudents + ' Teilnehmer'
                  : course.registeredStudents + ' Teilnehmende'
              }}
            </span>
          </td>
        </ng-container>

        <!-- eLog Status column -->
        <ng-container matColumnDef="eLogStatus">
          <th
            *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            class="elog-cell-header">
            Anwesenheit
            <app-custom-table-sort-icon [sort]="sort" columnName="eLogStatus">
            </app-custom-table-sort-icon>
          </th>
          <td
            *matCellDef="let course; dataSource: dataSource"
            mat-cell
            class="elog-cell">
            <app-elog-cell
              class="elog"
              [eLogStatusCounts]="course.elogStatusCounts"></app-elog-cell>
          </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell class="center-header-cell">
            {{ isMobile ? 'Akt.' : 'Aktionen' }}
          </th>
          <td *matCellDef="let course; dataSource: dataSource" mat-cell>
            <div class="table-button-icons">
              <button
                (click)="openCourseDetails(course.id)"
                type="button"
                class="table-button-icon"
                matTooltip="Kurs Details anzeigen">
                <i class="fa-light fa-arrow-up-right-from-square"></i>
              </button>
            </div>
          </td>
        </ng-container>

        <tbody>
          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td [attr.colspan]="displayedColumns.length">
              <div *ngIf="isLoading" class="loading-wrapper">
                <app-lottie-loading-spinner
                  size="45px"></app-lottie-loading-spinner>
              </div>
              <div
                *ngIf="!isLoading"
                class="mat-cell table-no-match hasomed-text-body font-grey">
                Es wurde kein Kurs gefunden.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="7"
        [renderButtonsNumber]="isMobile ? 0 : 1"
        custom-paginator
        aria-label="Select page"></mat-paginator>
    </div>
    <div class="vertical-spacer"></div>
  </div>
</div>
