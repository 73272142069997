import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  ChangeLogBackendModel,
  ChangeLogModel,
} from '../models/change-log.model';
import { recurrenceFrequency } from '../models/course.model';
import { eLogStatus } from '../models/elog.model';
import { OpeningHourChangeLogBackendModel } from '../models/opening-hour.model';
import { Role } from '../models/permission.model';
import { CancellationService } from './cancellation.service';
import { DecryptionService } from './decryption.service';
import { UserService } from './user.service';

type KeyValuePairBackend = { Key: string; Value: string };
type ParsedKeyValuePair = { key: string; value: string };

type KeyValueModel = { [key: string]: any };

export const dbKeys = {
  AccompanyingPersons: 'Begleitpersonen',
  AccreditationYear: 'Akkreditierungsjahr',
  AdditionalQualifications: 'Zusatzqualifikationen',
  Address: 'Adresse',
  AllDayEvent: 'Ganztägiger Termin',
  Birthdate: 'Geburtsdatum',
  Building: 'Gebäude',
  Chamber: 'Kammer',
  CourseEventDates: 'Kurstermine',
  Closed: 'Geschlossen',
  Color: 'Farbe',
  Confirmed: 'Bestätigung durch AWA...',
  ConfirmationDate: 'Bestätigungsdatum',
  ContactPerson: 'Kontaktperson',
  Content: 'Inhalt',
  ContingentTheoreticalScientificHours:
    'Kontingent wissenschaftlich-theoretische Stunden',
  ContingentTheoreticalScientificAutoTrainingHours:
    'Kontingent wissenschaftlich-theoretische Doppelstunden autogenes Training',
  ContingentTheoreticalScientificBalintGroupWorkHours:
    'Kontingent wissenschaftlich-theoretische Doppelstunden Balintgruppenarbeit',
  ContingentPractical1Hours: 'Kontingent  Praktische Tätigkeit 1 Stunden',
  ContingentPractical1MedicalHistorySurveyMin:
    'Kontingent  Praktische Tätigkeit 1 min Anamneseerhebungen',
  ContingentPractical2Hours: 'Kontingent  Praktische Tätigkeit 2 Stunden',
  ContingentTreatmentInternshipHours: 'Kontingent Behandlungspraktikum Stunden',
  ContingentCompletedTreatmentCases:
    'Kontingent Behandlungspraktikum abgeschlossene Behandlungsfälle',
  ContingentTreatmentCases:
    'Kontingent Behandlungspraktikum Behandlungsfälle (gesamt)',
  ContingentTreatmentCasesMin:
    'Kontingent Behandlungspraktikum Behandlungsfälle (min)',
  ContingentTreatmentCasesMinHours:
    'Kontingent Behandlungspraktikum Behandlungsfälle (min Stunden)',
  ContingentTreatmentCasesMin2:
    'Kontingent Behandlungspraktikum Behandlungsfälle (min)',
  ContingentTreatmentCasesMin2Hours:
    'Kontingent Behandlungspraktikum Behandlungsfälle (min Stunden)',
  ContingentSupervisionHours: 'Kontingent Supervision Stunden',
  ContingentSupervisionSingleSessionHours:
    'Kontingent Supervision Einzelsitzung Stunden',
  ContingentSupervisionGroupSessionHours:
    'Kontingent Supervision Gruppensitzung Stunden',
  ContingentSupervisionMedicalHistorySurveyMin:
    'Kontingent Supervision min Anamneseerhebungen',
  ContingentSelfExperienceHours: 'Kontingent Selbsterfahrung Stunden',
  ContingentSelfExperienceSessionsMin:
    'Kontingent Selbsterfahrung Sitzungen (min)',
  ContingentSelfExperienceSingleSessionsMin:
    'Kontingent Selbsterfahrung Einzelsitzungen (min)',
  ContingentSelfExperienceGroupSessionsMin:
    'Kontingent Selbsterfahrung Gruppensitzungen (min)',
  CooperationPartner: 'Kooperationspartner',
  CooperationAgreementAvailable: 'Kooperationsvereinbarung vorhanden',
  CooperationAgreementDate: 'Kooperationsvertrag Datum',
  CooperationPartnerExpertises: 'Fachkunden',
  CooperationPartnerFiles: 'Dateien',
  CooperationPartnerProcedures: 'Richtlinienverfahren',
  Course: 'Kurs',
  CourseFiles: 'Dateien',
  CourseExamTypes: 'Prüfungsarten',
  CourseLearningContents: 'Lehrinhalte',
  CourseLecturers: 'Lehrpersonal',
  CourseStudents: 'Teilnehmer',
  CourseType: 'Kurstyp',
  Creator: 'Ersteller',
  Date: 'Datum',
  DefaultTitlePicture: 'Standardtitelbild',
  Description: 'Beschreibung',
  Duration: 'Dauer',
  DurationInTimeUnits: 'Dauer in Zeiteinheiten',
  EducationCourses: 'Aus- und Weiterbildungsgänge',
  Email: 'E-Mail',
  EndDate: 'Enddatum',
  EntryDate: 'Eintrittsdatum',
  Equipment: 'Ausstattung',
  EventDates: 'Termine',
  ExamTypes: 'Prüfungsarten',
  ExternalNotes: 'Externe Notizen',
  Expertise: 'Fachkunde',
  Expertises: 'Fachkunden',
  File: 'Datei',
  Files: 'Dateien',
  Floor: 'Etage',
  Image: 'Bild',
  InternalNotes: 'Interne Notizen',
  IsAdvancedTrainingApproved: 'Weiterbildungsbefugt',
  IsAvailable: 'Verfügbar',
  IsCanceled: 'Abgesagt',
  IsPublished: 'Veröffentlicht',
  Label: 'Etikett',
  LandlineNumber: 'Festnetznummer',
  Lecturers: 'Lehrpersonal',
  LearningContents: 'Lehrinhalte',
  Location: 'Ort',
  LogonEnabled: 'Kurs aktiv',
  Mandatory: 'Verpflichtend',
  MaxParticipants: 'Maximale Teilnehmerzahl',
  Mobile: 'Mobilfunknummer',
  MobileNumber: 'Mobilfunknummer',
  Modifier: 'Bearbeiter',
  Name: 'Name',
  NewsArticleType: 'Artikeltyp',
  OpeningHours: 'Öffnungszeiten',
  PatientAge: 'Patientenalter',
  PatientAppointment: 'Patiententerminreihe',
  PatientAppointmentAccompanyingPerson: 'Begleitpersonen',
  PatientAppointments: 'Patiententermine',
  PatientAppointmentEventDates: 'Patiententermine',
  PatientChiffre: 'Patienten Chiffre',
  Phone: 'Festnetznummer',
  PracticalWorkFiles: 'Dateien',
  Procedure: 'Richtlinienverfahren',
  Procedures: 'Richtlinienverfahren',
  ProfessionalAssociation: 'Akkreditierung durch Berufsverband',
  ProfilePicture: 'Profilbild',
  PtkConsultation: 'PTK Hinzuziehung',
  PtkConsultationDate: 'PTK Hinzuziehung Datum',
  RecurrencePattern: 'Muster zur Wiederholung',
  Regulation: 'Aus- Weiterbildungsordnung',
  ResponsiblePerson: 'Verantwortlicher',
  RoleId: 'Rolle',
  RoomType: 'Raumtyp',
  ScopeOfAdvancedTrainingApproval: 'Umfang der Weiterbildungsbefugnis',
  SeatNumber: 'Platzanzahl',
  SelfAwarenessFiles: 'Dateien',
  SelfAwarenessTrainer: 'Selbsterfahrungsleiter',
  ShortName: 'Kurzform',
  Status: 'eLog Status',
  Student: 'Kandidat',
  Students: 'Teilnehmer',
  StartDate: 'Startdatum',
  Subtitle: 'Unterüberschrift',
  SupervisionAppointments: 'Supervisionstermine',
  SupervisionAppointmentFiles: 'Dateien',
  SupervisionAppointmentTreatmentCases: 'Supervisionsbehandlungsfälle',
  SupervisionAppointmentPatientAppointmentEventDates: 'Supervisionstermine',
  Supervisors: 'Supervisoren',
  SupplySector: 'Versorgungsbereiche',
  SurveyFormAvailable: 'Erhebungsbogen vorhanden',
  SurveyFormDate: 'Erhebungsbogen Datum',
  TherapyForm: 'Therapieform',
  TherapyPhase: 'Therapietyp',
  TimePublished: 'Veröffentlichungszeitpunkt',
  Title: 'Titel',
  TitlePicture: 'Titelbild',
  TreatmentCase: 'Behandlungsfall',
  TreatmentCases: 'Behandlungsfälle',
  TreatmentCaseAccompanyingPersons: 'Begleitpersonen',
  TreatmentCaseFiles: 'Dateien',
  TreatmentProcedures: 'Richtlinienverfahren',
  TreatmentCaseProcedures: 'Richtlinienverfahren',
  TreatmentCaseSupervisors: 'Supervisoren',
  Type: 'Typ',
  User: 'Benutzer',
  UserIdentifier: 'Mitgliedernummer',
  Appointment: 'Termin',
};

const entityTypes = {
  ExamType: 'Prüfungsart',
  Course: 'Kurs',
  LearningContent: 'Lehrinhalt',
  EducationCourse: 'Aus- und Weiterbildungsgang',
  Room: 'Raum',
  Label: 'Etikett',
  WikiArticle: 'Informations-Artikel',
  NewsArticle: 'Artikel',
  Institute: 'Institut',
  CooperationPartner: 'Kooperationspartner',
  PracticalWork: 'Praktische Tätigkeit',
  SelfAwareness: 'Selbsterfahrung',
  Elog: 'eLog',
  TreatmentCase: 'Behandlungsfall',
  PatientAppointment: 'Patiententerminreihe',
  SupervisionAppointment: 'Supervisionsterminreihe',
  SupervisionAppointmentEventDate: 'Supervisionstermin',
  PatientAppointmentEventDate: 'Patiententermin',
  User: 'Benutzer',
  Appointment: 'Termin',
  AppointmentEventDate: 'Termin',
};

const changeTypes = {
  Create: 'Erstellung',
  Update: 'Aktualisierung',
  Delete: 'Löschung',
  Register: 'Anmeldung',
  Unregister: 'Abmeldung',
  Publish: 'Veröffentlichung',
  Unpublish: 'Verbergen',
  Cancel: 'Absage',
  Confirm: 'Zusage',
  UpdateRole: 'Rollenänderung',
  Enable: 'Aktivierung',
  Disable: 'Deaktivierung',
};

export const eventTypes = {
  Course: 'Kurstermin',
  Patient: 'Patiententermin',
  Exam: 'Prüfungstermin',
  Supervision: 'Supervisionstermin',
  Group_supervision: 'Gruppensupervisionstermin',
};

const ignoreKeys = [
  'Institute',
  'CreatorId',
  'ModifierId',
  'CourseTypeId',
  'IsStandard',
  'RecurrencePatternId',
  'RoomTypeId',
  'CourseId',
  'UserId',
  'NewsArticleTypeId',
  'SupplySectorId',
  'CooperationPartnerId',
  'StudentId',
  'SelfAwarenessTrainerId',
  'TherapyPhaseId',
  'TreatmentCaseId',
  'RoomId',
  'PatientAppointmentId',
  'ExpertiseId',
  'ProcedureId',
  'LabelId',
  'SupervisionAppointmentId',
  'AppointmentId',
];

const booleanKeys = [
  'AllDayEvent',
  'Mandatory',
  'LogonEnabled',
  'IsPublished',
  'Closed',
  'IsAvailable',
  'CooperationAgreementAvailable',
  'SurveyFormAvailable',
  'IsAdvancedTrainingApproved',
  'IsCanceled',
  'Confirmed',
  'PtkConsultation',
];

const dateKeys = [
  'CooperationAgreementDate',
  'Date',
  'SurveyFormDate',
  'PtkConsultationDate',
  'ConfirmationDate',
  'EntryDate',
  'Birthdate',
];

const dateTimeKeys = ['StartDate', 'EndDate', 'TimePublished'];

const eventDatesKeys = [
  'CourseEventDates',
  'EventDates',
  'PatientAppointmentEventDates',
  'SupervisionAppointmentEventDates',
];

@Injectable({
  providedIn: 'root',
})
export class ChangeLogService {
  private instituteId: number =
    this.userService.currentUser?.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService
  ) {}

  /**
   * getAllChangeLogs
   * @description get all change logs from the backend
   * @returns Observable<HttpResponse<any>>
   */
  public getAllChangeLogs(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/change-logs`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getChangeLogById
   * @description get a specific change log by id
   * @param changeLogId
   * @returns Observable<HttpResponse<any>>
   */
  public getChangeLogById(changeLogId: number): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/change-logs/${changeLogId}`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   *
   * @description map audit log keys and values to human readable keys and values
   * @param values
   * @returns
   */
  private async mapAuditLogKeys(
    values: KeyValuePairBackend[]
  ): Promise<ParsedKeyValuePair[]> {
    if (!values) {
      return null;
    }

    values = values.filter(value => !ignoreKeys.includes(value.Key));

    const keyValueObject: KeyValueModel = values.reduce((acc, current) => {
      acc[current.Key] = current.Value;
      return acc;
    }, {});
    const newValues = Object.keys(keyValueObject).map(async key => {
      if (booleanKeys.includes(key)) {
        return this.processBooleanValue(keyValueObject, key);
      }

      if (dateKeys.includes(key)) {
        return this.processDateValue(keyValueObject, key);
      }

      if (dateTimeKeys.includes(key)) {
        return this.processDateTimeValue(keyValueObject, key);
      }

      if (eventDatesKeys.includes(key)) {
        return this.processEventDatesValue(keyValueObject, key);
      }

      switch (key) {
        case 'Name':
          return this.processNameValue(keyValueObject, key);
        case 'Address':
          return await this.processAddressValue(keyValueObject, key);
        case 'ContactPerson':
          return this.processNameValue(keyValueObject, key);
        case 'OpeningHours':
          const parsedOpeningHours = JSON.parse(
            '[' + keyValueObject[key] + ']'
          );
          return {
            key: dbKeys[key],
            value: parsedOpeningHours
              .map((openingHour: OpeningHourChangeLogBackendModel) => {
                // TODO
                return '';
                // return `Tag: ${openingHour.Day}, Von: ${convertUTCToLocalTime(openingHour.From)}, Bis: ${convertUTCToLocalTime(openingHour.To)}`;
              })
              .join(', '),
          };
        case 'RecurrencePattern':
          return this.processRecurrencePatternValue(keyValueObject, key);
        case 'Status':
          return this.processElogStatusValue(keyValueObject, key);
        case 'RoleId':
          let value = '';
          switch (keyValueObject[key]) {
            case Role.ADMINISTRATOR:
              value = 'Verwalter';
              break;
            case Role.LECTURER:
              value = 'Lehrpersonal';
              break;
            case Role.STUDENT:
              value = 'Kandidat';
              break;
            default:
              value = keyValueObject[key];
              break;
          }
          return {
            key: dbKeys[key],
            value: value,
          };
        default:
          return {
            key: dbKeys[key] ?? key,
            value: await this.decryptionService.decryptString(
              keyValueObject[key]
            ),
          };
      }
    });

    return await Promise.all(newValues.filter(v => v !== null));
  }

  /**
   * parseAuditLogObject
   * @description parse the backend audit log object
   * @param changeLogData
   * @returns
   */
  public async parseAuditLogObject(
    changeLogData: ChangeLogBackendModel
  ): Promise<ChangeLogModel> {
    var mappedOldValues =
      changeLogData.oldValues &&
      (await Promise.all(
        await this.mapAuditLogKeys(JSON.parse(changeLogData.oldValues))
      ));
    var mappedNewValues =
      changeLogData.newValues &&
      changeLogData.newValues &&
      (await Promise.all(
        await this.mapAuditLogKeys(JSON.parse(changeLogData.newValues))
      ));

    [mappedOldValues, mappedNewValues] = this.removeSameValues(
      mappedOldValues,
      mappedNewValues
    );

    return {
      id: changeLogData.id,
      entityId: changeLogData.entityId,
      timeCreated: moment(changeLogData.timeCreated)
        .tz('Europe/Berlin')
        .toDate(),
      entityType: entityTypes[changeLogData.entityType],
      changeType: changeTypes[changeLogData.changeType],
      changeDescription: await this.processChangeDescription(
        changeLogData.entityType,
        changeLogData.changeDescription
      ),
      collapsed: true,
      oldValues: mappedOldValues,
      newValues: mappedNewValues,
      user: await this.userService.parseBackendUser(changeLogData.user),
    };
  }

  /**
   * process the change description based on the entity type
   * @param entityType
   * @param changeDescription
   * @returns
   */
  private async processChangeDescription(
    entityType: string,
    changeDescription: string
  ): Promise<string> {
    if (
      entityType === 'SupervisionAppointmentEventDate' ||
      entityType === 'PatientAppointmentEventDate' ||
      entityType === 'AppointmentEventDate'
    ) {
      return changeDescription
        ? moment
            .utc(changeDescription, 'MM/DD/YYYY HH:mm:ss')
            .tz('Europe/Berlin')
            .format('DD.MM.YYYY HH:mm') + ' Uhr'
        : null;
    }
    if (entityType === 'SelfAwareness') {
      return changeDescription
        ? moment
            .utc(changeDescription, 'MM/DD/YYYY')
            .tz('Europe/Berlin')
            .format('DD.MM.YYYY')
        : null;
    }
    return await this.decryptionService.decryptString(changeDescription);
  }

  /**
   * removeSameValues
   * @description remove values that are the same in old and new values
   * @param oldValues
   * @param newValues
   * @returns
   */
  private removeSameValues(
    oldValues: ParsedKeyValuePair[],
    newValues: ParsedKeyValuePair[]
  ): [ParsedKeyValuePair[], ParsedKeyValuePair[]] {
    if (!oldValues || !newValues) {
      return [oldValues, newValues];
    }
    const filteredOldValues = oldValues.filter(
      oldPair =>
        !newValues.some(
          newPair =>
            newPair.key === oldPair.key && newPair.value === oldPair.value
        )
    );

    const filteredNewValues = newValues.filter(
      newPair =>
        !oldValues.some(
          oldPair =>
            oldPair.key === newPair.key && oldPair.value === newPair.value
        )
    );

    return [filteredOldValues, filteredNewValues];
  }

  /**
   * processes the boolean value
   * @param keyValueObject KeyValueModel
   * @param key string
   * @returns ParsedKeyValuePair
   */
  private processBooleanValue(
    keyValueObject: KeyValueModel,
    key: string
  ): ParsedKeyValuePair {
    return {
      key: dbKeys[key],
      value:
        keyValueObject[key] === 1 ||
        keyValueObject[key] === true ||
        keyValueObject[key] === 'true'
          ? 'Ja'
          : 'Nein',
    };
  }

  /**
   * processes the date value
   * @param keyValueObject KeyValueModel
   * @param key string
   * @returns ParsedKeyValuePair
   */
  private processDateValue(
    keyValueObject: KeyValueModel,
    key: string
  ): ParsedKeyValuePair {
    return {
      key: dbKeys[key],
      value: keyValueObject[key]
        ? moment(keyValueObject[key]).tz('Europe/Berlin').format('DD.MM.YYYY')
        : null,
    };
  }

  /**
   * processes the date time value
   * @param keyValueObject KeyValueModel
   * @param key string
   * @returns ParsedKeyValuePair
   */
  private processDateTimeValue(
    keyValueObject: KeyValueModel,
    key: string
  ): ParsedKeyValuePair {
    return {
      key: dbKeys[key],
      value: keyValueObject[key]
        ? moment(keyValueObject[key])
            .tz('Europe/Berlin')
            .format('DD.MM.YYYY HH:mm') + ' Uhr'
        : null,
    };
  }

  /**
   * processes the name value
   * @param keyValueObject KeyValueModel
   * @param key string
   * @returns ParsedKeyValuePair
   */
  private processNameValue(
    keyValueObject: KeyValueModel,
    key: string
  ): ParsedKeyValuePair {
    // check if the value is an object
    if (typeof keyValueObject[key] === 'object') {
      let fullName = '';
      if (keyValueObject[key]?.GenderTitle) {
        fullName += keyValueObject[key]?.GenderTitle + ' ';
      }
      if (keyValueObject[key]?.AcademicTitle) {
        fullName += keyValueObject[key]?.AcademicTitle + ' ';
      }
      if (keyValueObject[key]?.FirstName) {
        fullName += keyValueObject[key]?.FirstName + ' ';
      }
      if (keyValueObject[key]?.LastName) {
        fullName += keyValueObject[key]?.LastName;
      }
      return {
        key: dbKeys[key],
        value: keyValueObject[key] ? fullName.trim() : null,
      };
    }

    return {
      key: dbKeys[key],
      value: keyValueObject[key],
    };
  }

  /**
   * processes the recurrence pattern value
   * @param keyValueObject KeyValueModel
   * @param key string
   * @returns ParsedKeyValuePair
   */
  private processRecurrencePatternValue(
    keyValueObject: KeyValueModel,
    key: string
  ): ParsedKeyValuePair {
    const frequency =
      (keyValueObject[key]?.Frequency as recurrenceFrequency) ==
      recurrenceFrequency.DAILY
        ? 'Täglich'
        : (keyValueObject[key]?.Frequency as recurrenceFrequency) ==
            recurrenceFrequency.WEEKLY
          ? 'Wöchentlich'
          : (keyValueObject[key]?.Frequency as recurrenceFrequency) ==
              recurrenceFrequency.MONTHLY
            ? 'Monatlich'
            : '';
    const newValue = [];
    for (const childKey in keyValueObject[key]) {
      if (!keyValueObject[key][childKey]) {
        continue;
      }
      switch (childKey) {
        case 'Frequency':
          newValue.push(`Frequenz: ${frequency}`);
          break;
        case 'Interval':
          newValue.push(`Intervall: ${keyValueObject[key]?.Interval}`);
          break;
        case 'DaysOfWeek':
          newValue.push(`Wochentage: ${keyValueObject[key]?.DaysOfWeek}`);
          break;
        case 'WeekOfMonth':
          newValue.push(
            `Woche im Monat: ${keyValueObject[key]?.OccuranceDayInMonth}`
          );
          break;
        default:
          break;
      }
    }

    return {
      key: dbKeys[key],
      value: keyValueObject[key] != null ? newValue.join(', ') : null,
    };
  }

  /**
   * processes the address value
   * @param keyValueObject KeyValueModel
   * @param key string
   * @returns Promise<ParsedKeyValuePair>
   */
  private async processAddressValue(
    keyValueObject: KeyValueModel,
    key: string
  ): Promise<ParsedKeyValuePair> {
    const parsedAddress = keyValueObject[key];

    const addressValue = [];
    for (const childKey in parsedAddress) {
      if (!parsedAddress[childKey]) {
        continue;
      }
      switch (childKey) {
        case 'Street':
          addressValue.push(
            `Straße: ${await this.decryptionService.decryptString(parsedAddress?.Street)}`
          );
          break;
        case 'HouseNumber':
          addressValue.push(
            `Hausnummer: ${await this.decryptionService.decryptString(parsedAddress?.HouseNumber)}`
          );
          break;
        case 'AddressAddition':
          addressValue.push(
            `Adresszusatz: ${await this.decryptionService.decryptString(parsedAddress?.AddressAddition)}`
          );
          break;
        case 'ZipCode':
          addressValue.push(
            `PLZ: ${await this.decryptionService.decryptString(parsedAddress?.ZipCode)}`
          );
          break;
        case 'City':
          addressValue.push(
            `Stadt: ${await this.decryptionService.decryptString(parsedAddress?.City)}`
          );
          break;
        case 'Country':
          addressValue.push(
            `Land: ${await this.decryptionService.decryptString(parsedAddress?.Country)}`
          );
          break;
        default:
          break;
      }
    }
    return {
      key: dbKeys[key],
      value: keyValueObject[key] != null ? addressValue.join(', ') : null,
    };
  }

  /**
   * processes the eLog status value
   * @param keyValueObject KeyValueModel
   * @param key string
   * @returns ParsedKeyValuePair
   */
  private processElogStatusValue(
    keyValueObject: KeyValueModel,
    key: string
  ): ParsedKeyValuePair {
    var eLogStatusString: string;
    switch (keyValueObject[key]) {
      case eLogStatus.UPCOMING:
        eLogStatusString = 'offen';
        break;
      case eLogStatus.ABSENT:
        eLogStatusString = 'abwesend';
        break;
      case eLogStatus.CHECKED:
        eLogStatusString = 'geprüft';
        break;
      case eLogStatus.EXCUSED:
        eLogStatusString = 'entschuldigt';
        break;
      case eLogStatus.PENDING:
        eLogStatusString = 'ausstehend';
        break;
      case eLogStatus.UNEXCUSED:
        eLogStatusString = 'unentschuldigt';
        break;
      default:
        return {
          key: dbKeys[key],
          value: keyValueObject[key],
        };
    }

    return {
      key: dbKeys[key],
      value: eLogStatusString,
    };
  }

  /**
   * processes the event dates value
   * @param keyValueObject KeyValueModel
   * @param key string
   * @returns ParsedKeyValuePair
   */
  private processEventDatesValue(
    keyValueObject: KeyValueModel,
    key: string
  ): ParsedKeyValuePair {
    if (!keyValueObject[key]) {
      return {
        key: dbKeys[key],
        value: null,
      };
    }
    return {
      key: dbKeys[key],
      value: keyValueObject[key]
        .split(',')
        .map(eventDate => {
          return eventDate
            .split(' - ')
            .map(date => {
              // check if the date is a valid date
              if (!moment(date, 'MM/DD/YYYY HH:mm:ss').isValid()) {
                return null;
              }
              return moment
                .utc(date, 'MM/DD/YYYY HH:mm:ss')
                .tz('Europe/Berlin')
                .format('DD.MM.YYYY HH:mm');
            })
            .filter(date => date !== null)
            .join(' - ');
        })
        .join(', '),
    };
  }
}
