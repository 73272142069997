import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { Feature, Permission } from 'src/app/models/permission.model';
import { SelfAwarenessEntryModel } from 'src/app/models/self-awareness-entry.model';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { SelfAwarenessService } from 'src/app/services/self-awareness.service';
import { UserService } from 'src/app/services/user.service';
import { getFullName } from 'src/app/utils/user.utils';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-self-awareness-table',
  templateUrl: './self-awareness-table.component.html',
  styleUrl: './self-awareness-table.component.scss',
})
export class SelfAwarenessTableComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() searchText: string;
  public displayedColumns = [
    'selfAwarenessTrainer',
    'date',
    'duration',
    'confirmation',
    'actions',
  ];
  public dataSource: MatTableDataSource<SelfAwarenessEntryModel> =
    new MatTableDataSource();
  public isLoading: boolean = true;
  public isMobile = false;
  public selfAwarenessEntries: SelfAwarenessEntryModel[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  public feature = Feature;
  public permission = Permission;

  public getFullName = getFullName;

  constructor(
    private selfAwarenessService: SelfAwarenessService,
    private alertService: AlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cancellationService: CancellationService,

    private userService: UserService,
    private dialog: MatDialog,
    private mobileUiService: MobileUiService
  ) {}

  public ngOnInit() {
    this.activatedRoute.parent?.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        let userId = +atob(params.get('userId'));

        if (!userId) {
          userId = this.userService.currentUser.id;
        }

        this.getSelfAwarenessEntriesFromStudent(userId);
      });
    this.viewChanges();
  }

  /**
   * ngOnChanges
   * apply search filter on changes
   * @param changes
   * @returns void
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchText) {
      this.applySearch(this.searchText);
    }
  }

  /**
   * sets isMobile and isTablet depending on the current view
   * sets showSearchBar to true if the current view is desktop
   * sets the displayed columns depending on the current view
   * @returns void
   */
  private viewChanges() {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.initTableColumns(currentView);

        this.isMobile = currentView === 'mobile';
      });
  }

  /**
   * set the columns for the table depending on the current view
   * @param currentView the current view
   * @returns void
   */
  private initTableColumns(currentView: string): void {
    if (currentView === 'mobile') {
      this.displayedColumns = ['selfAwarenessTrainer', 'actions'];
    } else if (currentView === 'tablet') {
      this.displayedColumns = [
        'selfAwarenessTrainer',
        'date',
        'duration',
        'actions',
      ];
    } else {
      this.displayedColumns = [
        'selfAwarenessTrainer',
        'date',
        'duration',
        'confirmation',
        'actions',
      ];
    }
  }

  /**
   * Get the self awareness entries from the student
   * @param userId The user id
   * @returns void
   */
  public getSelfAwarenessEntriesFromStudent(userId: number): void {
    this.selfAwarenessService
      .getAllSelfAwarenessEntriesByStudentId(userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.selfAwarenessEntries = response.body
            ? await Promise.all(
                response.body.map(
                  async (
                    selfAwarenessEntry: SelfAwarenessEntryModel
                  ): Promise<SelfAwarenessEntryModel> =>
                    await this.selfAwarenessService.parseBackendSelfAwarenessEntry(
                      selfAwarenessEntry
                    )
                )
              )
            : [];

          this.dataSource.data = this.selfAwarenessEntries;
          this.dataSource.filterPredicate = (data, filter) => {
            const dataStr = getFullName(
              data.selfAwarenessTrainer
            ).toLowerCase();
            return dataStr ? dataStr.indexOf(filter) != -1 : false;
          };

          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading = false;
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Selbsterfahrungseinträge konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );

          this.isLoading = false;
        },
      });
  }

  /**
   * Apply the search filter
   * @param filterValue The filter value
   * @returns void
   */
  public applySearch(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * Navigate to the self awareness entry view
   * @param selfAwarenessEntry The self awareness entry
   * @returns void
   */
  public navigateToViewSelfAwareness(
    selfAwarenessEntry: SelfAwarenessEntryModel
  ): void {
    this.router.navigate(['./', btoa(String(selfAwarenessEntry.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * Navigate to the edit self awareness entry page
   * @param selfAwarenessEntry The self awareness entry
   * @returns void
   */
  public navigateToEditSelfAwareness(
    selfAwarenessEntry: SelfAwarenessEntryModel
  ): void {
    this.router.navigate(['./', 'edit', btoa(String(selfAwarenessEntry.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * Deletes the self awareness entry
   * @param selfAwarenessEntry The self awareness entry
   * @returns void
   */
  public deleteSelfAwareness(
    selfAwarenessEntry: SelfAwarenessEntryModel
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '445px',
      data: {
        title: 'Eintrag löschen',
        message: `Möchten Sie den Eintrag wirklich löschen? Der Eintrag kann nicht wiedehergestellt werden.`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.selfAwarenessService
            .deleteSelfAwarenessEntry(selfAwarenessEntry.id)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this.alertService.showSuccessAlert(
                  'Das hat geklappt!',
                  `Der Eintrag wurde gelöscht!`
                );

                // delete entry from table data without reloading from backend
                const index = this.dataSource.data.indexOf(selfAwarenessEntry);
                if (index > -1) {
                  this.dataSource.data = this.dataSource.data.filter(
                    item => item !== selfAwarenessEntry
                  );
                }
              },
              error: () => {
                this.alertService.showErrorAlert(
                  'Das hat nicht geklappt!',
                  'Der Eintrag konnte nicht gelöscht werden.'
                );
              },
            });
        }
      });
  }

  /**
   * download the confirmation file
   * @param selfAwarenessId the id of the self awareness entry
   * @param fileId the id of the file
   * @returns void
   */
  public onDownload(selfAwarenessId: number, fileId: number): void {
    this.selfAwarenessService.downloadFile(selfAwarenessId, fileId);
  }

  /**
   * unsubscribe from all subscriptions and cancel all requests
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
