import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { APP_CONFIG } from './app.config';
import { AppModule } from './app/app.module';

fetch('assets/app.config.json')
  .then(res => res.json())
  .then(config => {
    // initialize Sentry
    Sentry.init({
      dsn: 'https://fb5e21c7eecded2121afc270da8997f4@crashbunny.gesundwolke.de/4',
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        Sentry.browserTracingIntegration(),
        // Registers the Replay integration,
        // which automatically captures Session Replays
        Sentry.replayIntegration(),

        Sentry.httpClientIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      // use angular environment to determine the environment:
      environment: config.sentryEnvironment,
    });

    if (config.appTitle) {
      document.title = config.appTitle;
    }

    // Change the favicon
    if (config.faviconUrl) {
      const link: HTMLLinkElement =
        document.querySelector("link[rel~='icon']") ||
        document.createElement('link');
      link.rel = 'icon';
      link.href = config.faviconUrl;
      document.head.appendChild(link);
    }

    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
      .bootstrapModule(AppModule)
      .catch(err => {});
  });
