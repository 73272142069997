import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FileModel } from 'src/app/models/file.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { PracticalWorkModel } from 'src/app/models/practical-work.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { PracticalWorkService } from 'src/app/services/practical-work.service';

@Component({
  selector: 'app-practical-work-details',
  templateUrl: './practical-work-details.component.html',
  styleUrls: ['./practical-work-details.component.scss'],
})
export class PracticalWorkDetailsComponent implements OnInit, OnDestroy {
  public practicalWork: PracticalWorkModel;
  public isLoading: boolean = true;

  private destroy$: Subject<void> = new Subject<void>();

  public feature = Feature;
  public permission = Permission;

  constructor(
    private practicalWorkService: PracticalWorkService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private cancellationService: CancellationService,
    private alertService: AlertService
  ) {}

  public ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (!params.id) {
          return;
        }

        const practicalWorkId = +atob(params.id);

        this.practicalWorkService
          .getPracticalWorkById(practicalWorkId)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: async result => {
              this.practicalWork = result.body
                ? await this.practicalWorkService.parsePracticalWork(
                    result.body
                  )
                : null;
              this.isLoading = false;
            },
            error: error => {
              this.alertService.showErrorAlert(
                'Das hat nicht geklappt!',
                'Die praktische Tätigkeit konnte nicht geladen werden.'
              );

              this.isLoading = false;
            },
          });
      });
  }

  /**
   * onEditPracticalWork
   * Navigate to edit practical work
   * @returns void
   */
  public onEditPracticalWork(): void {
    if (this.practicalWork) {
      this.router.navigate(['../edit', btoa(String(this.practicalWork.id))], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  /**
   * onGoBack
   * Navigate back
   * @returns void
   */
  public onGoBack(): void {
    this.location.back();
  }

  /**
   * onOpenFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenFile(file: FileModel): void {
    this.practicalWorkService.openFile(this.practicalWork.id, file.id);
  }

  /**
   * onDownloadFile
   * download the file
   * @param file
   * @returns void
   */
  public onDownloadFile(file: FileModel): void {
    this.practicalWorkService.downloadFile(this.practicalWork.id, file.id);
  }

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
