<form [formGroup]="form" class="component-content" (ngSubmit)="onSubmit()">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{ editMode ? 'Dokument bearbeiten' : 'Dokument hochladen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie IhrDokument.'
            : 'Laden Sie Ihr Dokument hoch.'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>
      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>

  <div *ngIf="!isLoading" class="main-content-form">
    <div *ngIf="editMode" class="formular-row">
      <label class="formular-row-label" for="filename">
        <span class="label-title">
          Titel
          <span *ngIf="isRequired(form.get('filename'))">*</span>
        </span>
        <span class="label-description"> Benennen Sie die Datei. </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="type"
            type="text"
            formControlName="filename"
            class="hasomed-text-input"
            placeholder="Dateiname" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="form.get('filename')"></app-error-message>
      </div>
    </div>

    <mat-divider *ngIf="editMode"></mat-divider>

    <div *ngIf="!editMode" class="formular-row">
      <label class="formular-row-label" for="fileUpload">
        <span class="label-title">
          Datei hochladen
          <span *ngIf="isRequired(form.get('file'))">*</span>
        </span>
        <span class="label-description">
          Hier können Sie eine Datei für die unten ausgewählten Rollen
          hochladen.
        </span>
      </label>
      <div class="formular-row-input">
        <div id="documents" class="document-upload-wrapper">
          <app-upload-area-dnd
            [sensitiveDataAlert]="false"
            [existingFiles]="existingFiles"
            [multiple]="false"
            [scrollTo]="true"
            [showFileList]="true"
            [invalid]="form.get('file').invalid && form.get('file').touched"
            [allowedFileTypes]="allowedFileTypes"
            (deleteExistingFile)="onDeleteExistingFile()"
            context="fileUpload"
            inputId="fileUpload"
            formControlName="fileUpload"
            maxFileSize="10 MB">
          </app-upload-area-dnd>
          <app-error-message [formField]="form.get('file')"></app-error-message>
        </div>
      </div>
    </div>

    <mat-divider *ngIf="!editMode"></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Dokument teilen
          <span *ngIf="isRequired(form.get('rolesWithAccess'))">*</span>
        </span>
        <span class="label-description">
          Bestimmen Sie, für welche Rollen die Datei sichtbar sein soll.
        </span>
      </div>
      <div class="hasomed-input-field">
        <eleguide-select
          [control]="rolesWithAccessControl"
          controlId="rolesWithAccess"
          [options]="availableRolesSelectOptions"
          placeholder="Berechtigte Rollen"
          [multiple]="true"
          [showCheckAll]="true"></eleguide-select>
        <!-- Error Messages -->
        <app-error-message
          [formField]="form.get('rolesWithAccess')"></app-error-message>
      </div>
    </div>
  </div>
</form>
