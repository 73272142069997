import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { SkeletonModule } from 'primeng/skeleton';
import { EventRoomPlanningComponent } from '../components/eleguide/education/room-organization/event-room-planning/event-room-planning.component';
import { AcademicTitleSelectComponent } from '../components/shared-components/academic-title-select/academic-title-select.component';
import { ChangePasswordDialogComponent } from '../components/shared-components/change-password-dialog/change-password-dialog.component';
import { CopyToClipboardComponent } from '../components/shared-components/copy-to-clipboard/copy-to-clipboard.component';
import { CountrySelectComponent } from '../components/shared-components/country-select/country-select.component';
import { CreateEditAppointmentDialogComponent } from '../components/shared-components/create-edit-appointment-dialog/create-edit-appointment-dialog.component';
import { CreateEditInitialEducationStockComponent } from '../components/shared-components/create-edit-initial-education-stock/create-edit-initial-education-stock.component';
import { CustomTableSortIconComponent } from '../components/shared-components/custom-table-sort-icon/custom-table-sort-icon.component';
import { DefaultRouteComponent } from '../components/shared-components/default-route/default-route.component';
import { EditUserComponent } from '../components/shared-components/edit-user/edit-user.component';
import { EducationProgressBarComponent } from '../components/shared-components/education-progress-bar/education-progress-bar.component';
import { EleguideMultipleSelectComponent } from '../components/shared-components/eleguide-multiple-select/eleguide-multiple-select.component';
import { EleguideSelectComponent } from '../components/shared-components/eleguide-select/eleguide-select.component';
import { ElogCellComponent } from '../components/shared-components/elog-cell/elog-cell.component';
import { EmptyStateDialogComponent } from '../components/shared-components/empty-state-dialog/empty-state-dialog.component';
import { EmptyStateComponent } from '../components/shared-components/empty-state/empty-state.component';
import { ErrorMessageComponent } from '../components/shared-components/error-message/error-message.component';
import { EventPickerComponent } from '../components/shared-components/event-picker/event-picker.component';
import { FileViewComponent } from '../components/shared-components/file-view/file-view.component';
import { FilterTagsComponent } from '../components/shared-components/filter-tags/filter-tags.component';
import { FilterComponent } from '../components/shared-components/filter/filter.component';
import { GenderTitleSelectComponent } from '../components/shared-components/gender-title-select/gender-title-select.component';
import { ImageCropperDialogComponent } from '../components/shared-components/image-cropper-dialog/image-cropper-dialog.component';
import { ImageDetailDialogComponent } from '../components/shared-components/image-detail-dialog/image-detail-dialog.component';
import { InitialEducationStockAlertComponent } from '../components/shared-components/initial-education-stock-alert/initial-education-stock-alert.component';
import { InitialEducationStockDetailsComponent } from '../components/shared-components/initial-education-stock-details/initial-education-stock-details.component';
import { InstituteOverviewComponent } from '../components/shared-components/institute-overview/institute-overview.component';
import { InviteUserDialogComponent } from '../components/shared-components/invite-user-dialog/invite-user-dialog.component';
import { NewsArticleComponent } from '../components/shared-components/news-article/news-article.component';
import { NoSensitiveDataAlertComponent } from '../components/shared-components/no-sensitive-data-alert/no-sensitive-data-alert.component';
import { OpeningHoursComponent } from '../components/shared-components/opening-hours/opening-hours.component';
import { PracticalWorkDetailsComponent } from '../components/shared-components/practical-work-details/practical-work-details.component';
import { PracticalWorkTableComponent } from '../components/shared-components/practical-work-table/practical-work-table.component';
import { SelectCheckAllComponent } from '../components/shared-components/select-check-all/select-check-all.component';
import { SelfAwarenessDetailsComponent } from '../components/shared-components/self-awareness-details/self-awareness-details.component';
import { SelfAwarenessTableComponent } from '../components/shared-components/self-awareness-table/self-awareness-table.component';
import { SessionExpiredDialogComponent } from '../components/shared-components/session-expired-dialog/session-expired-dialog.component';
import { SingleEventPickerComponent } from '../components/shared-components/single-event-picker/single-event-picker.component';
import { SwitchRoleDialogComponent } from '../components/shared-components/switch-role-dialog/switch-role-dialog.component';
import { TreatmentCasesTableComponent } from '../components/shared-components/treatment-cases-table/treatment-cases-table.component';
import { ProgressBarComponent } from '../components/shared-components/upload-area-dnd/progress-bar/progress-bar.component';
import { UploadAreaDNDComponent } from '../components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { UserMasterDataComponent } from '../components/shared-components/user-master-data/user-master-data.component';
import { ViewProfilePictureDialogComponent } from '../components/shared-components/view-profile-picture-dialog/view-profile-picture-dialog.component';
import { CourseDetailSkeletonComponent } from '../components/skeletons/course-detail-skeleton/course-detail-skeleton.component';
import { CourseElogDetailSkeletonComponent } from '../components/skeletons/course-elog-detail-skeleton/course-elog-detail-skeleton.component';
import { UserContainerHeaderSkeletonComponent } from '../components/skeletons/user-container-header-skeleton/user-container-header-skeleton.component';
import { CheckPermissionsDirective } from '../directives/check-permissions.directive';
import { CustomPaginatorDirective } from '../directives/custom-paginator.directive';
import { TypeSafeMatCellDef } from '../directives/type-safe-mat-cell.directive';
import { SentryErrorHandler } from '../error-handler/sentry-error-handler';
import { LottieLoadingSpinnerComponent } from '../lottie-loading-spinner/lottie-loading-spinner.component';
import { CustomTimePipe } from '../pipes/custom-time.pipe';
import { FilterNewsArticlesPipe } from '../pipes/filter-news-articles.pipe';
import { NgSwitchMultiCasePipe } from '../pipes/ng-switch-multi-case.pipe';
import { StripHtmlPipe } from '../pipes/strip-html.pipe';
import { SearchFilterPipe } from '../search-filter.pipe';
import { MaterialModule } from './material.module';

export function playerFactory(): any {
  return import('lottie-web');
}
@NgModule({
  declarations: [
    CustomTableSortIconComponent,
    UploadAreaDNDComponent,
    ProgressBarComponent,
    CustomPaginatorDirective,
    SearchFilterPipe,
    NewsArticleComponent,
    EventPickerComponent,
    EventRoomPlanningComponent,
    CheckPermissionsDirective,
    DefaultRouteComponent,
    CopyToClipboardComponent,
    ChangePasswordDialogComponent,
    FilterNewsArticlesPipe,
    ErrorMessageComponent,
    FilterComponent,
    FilterTagsComponent,
    ElogCellComponent,
    PracticalWorkTableComponent,
    PracticalWorkDetailsComponent,
    EmptyStateComponent,
    EmptyStateDialogComponent,
    NoSensitiveDataAlertComponent,
    SessionExpiredDialogComponent,
    ImageCropperDialogComponent,
    ImageDetailDialogComponent,
    FileViewComponent,
    TypeSafeMatCellDef,
    InstituteOverviewComponent,
    SwitchRoleDialogComponent,
    TreatmentCasesTableComponent,
    InviteUserDialogComponent,
    OpeningHoursComponent,
    InitialEducationStockAlertComponent,
    CreateEditInitialEducationStockComponent,
    EducationProgressBarComponent,
    InitialEducationStockDetailsComponent,
    SelectCheckAllComponent,
    EditUserComponent,
    ViewProfilePictureDialogComponent,
    UserMasterDataComponent,
    LottieLoadingSpinnerComponent,
    CourseDetailSkeletonComponent,
    CourseElogDetailSkeletonComponent,
    UserContainerHeaderSkeletonComponent,
    EleguideSelectComponent,
    AcademicTitleSelectComponent,
    EleguideMultipleSelectComponent,
    GenderTitleSelectComponent,
    CountrySelectComponent,
    CreateEditAppointmentDialogComponent,
    SelfAwarenessTableComponent,
    SelfAwarenessDetailsComponent,
    SingleEventPickerComponent,
  ],
  exports: [
    CustomTableSortIconComponent,
    UploadAreaDNDComponent,
    ProgressBarComponent,
    CustomPaginatorDirective,
    SearchFilterPipe,
    NewsArticleComponent,
    EventPickerComponent,
    EventRoomPlanningComponent,
    CheckPermissionsDirective,
    DefaultRouteComponent,
    CopyToClipboardComponent,
    ChangePasswordDialogComponent,
    FilterNewsArticlesPipe,
    ErrorMessageComponent,
    FilterComponent,
    FilterTagsComponent,
    ElogCellComponent,
    PracticalWorkTableComponent,
    PracticalWorkDetailsComponent,
    EmptyStateComponent,
    EmptyStateDialogComponent,
    NoSensitiveDataAlertComponent,
    SessionExpiredDialogComponent,
    ImageCropperModule,
    ImageCropperDialogComponent,
    ImageDetailDialogComponent,
    FileViewComponent,
    TypeSafeMatCellDef,
    InstituteOverviewComponent,
    SwitchRoleDialogComponent,
    TreatmentCasesTableComponent,
    InviteUserDialogComponent,
    OpeningHoursComponent,
    InitialEducationStockAlertComponent,
    CreateEditInitialEducationStockComponent,
    EducationProgressBarComponent,
    InitialEducationStockDetailsComponent,
    SelectCheckAllComponent,
    EditUserComponent,
    ViewProfilePictureDialogComponent,
    UserMasterDataComponent,
    LottieComponent,
    LottieLoadingSpinnerComponent,
    SkeletonModule,
    CourseDetailSkeletonComponent,
    CourseElogDetailSkeletonComponent,
    UserContainerHeaderSkeletonComponent,
    EleguideSelectComponent,
    AcademicTitleSelectComponent,
    EleguideMultipleSelectComponent,
    GenderTitleSelectComponent,
    CountrySelectComponent,
    CreateEditAppointmentDialogComponent,
    SelfAwarenessTableComponent,
    SelfAwarenessDetailsComponent,
    SingleEventPickerComponent,
  ],
  providers: [
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    ClipboardModule,
    CustomTimePipe,
    ImageCropperModule,
    StripHtmlPipe,
    LottieComponent,
    SkeletonModule,
    NgSwitchMultiCasePipe,
  ],
})
export class SharedModule {}
