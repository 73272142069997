<div class="flex-c flex-grow">
  <div
    class="flex-c table course-details-main"
    [class.table-bicolor]="dataSource?.filteredData?.length > 1">
    <table
      mat-table
      [dataSource]="dataSource"
      class="course-details-table"
      matSort>
      <!-- lecturer column / using firstName for sorting -->
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Teilnehmende
          <app-custom-table-sort-icon [sort]="sort" columnName="user">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let user; dataSource: dataSource">
          <div class="user-container">
            <div class="user-icon">
              <img
                [attr.src]="
                  user.profilePicture ?? '../../../../../assets/img/user.jpg'
                "
                alt=""
                class="user-icon-image" />
            </div>
            <div class="user-info">
              <span class="hasomed-text-body">
                {{ getFullName(user) }}
              </span>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- userIdentifier column -->
      <ng-container matColumnDef="userIdentifier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Bildungs-ID
          <app-custom-table-sort-icon [sort]="sort" columnName="userIdentifier">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let user; dataSource: dataSource">
          {{ user.userIdentifier ?? '-' }}
        </td>
      </ng-container>

      <!-- Education progress column -->
      <ng-container matColumnDef="educationalProgress">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="width: 500px"
          mat-sort-header>
          <span>Ausbildungsfortschritt</span>
          <app-custom-table-sort-icon
            [sort]="sort"
            columnName="educationalProgress">
          </app-custom-table-sort-icon>
        </th>
        <td
          mat-cell
          *matCellDef="let user; dataSource: dataSource"
          style="width: 500px">
          <div class="education_progress">
            <div class="progress-container">
              <div
                class="progress-bar"
                ngClass="progress-orange"
                [ngStyle]="{
                  width: getTotalEducationalProgressInPercent(user) + '%',
                }"></div>
            </div>
            <span
              class="progress-label"
              [ngClass]="
                getTotalEducationalProgressInPercent(user) < 10
                  ? 'text-red'
                  : ''
              ">
              {{ getTotalEducationalProgressInPercent(user) }}%
            </span>
          </div>
        </td>
      </ng-container>

      <!-- entryDate column -->
      <ng-container matColumnDef="entryDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Eintrittsdatum
          <app-custom-table-sort-icon [sort]="sort" columnName="entryDate">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let user; dataSource: dataSource">
          {{ (user.entryDate | date: 'dd.MM.yyyy') ?? '-' }}
        </td>
      </ng-container>

      <!-- label column -->
      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Etikett
          <app-custom-table-sort-icon [sort]="sort" columnName="label">
          </app-custom-table-sort-icon>
        </th>
        <td mat-cell *matCellDef="let user; dataSource: dataSource">
          <div
            *ngIf="user.label"
            class="label"
            [style.background]="user.label.color">
            <i class="fa-light fa-id-badge label-icon"></i>
            <span class="label-text">{{ user.label.name }}</span>
          </div>
          <span *ngIf="!user.label" class="hasomed-text-body">-</span>
        </td>
      </ng-container>

      <!-- action column -->
      <ng-container matColumnDef="actions">
        <th class="center-header-cell" mat-header-cell *matHeaderCellDef>
          {{ isMobile ? 'Akt.' : 'Aktionen' }}
        </th>
        <td
          class="center-cell-text"
          mat-cell
          *matCellDef="let user; dataSource: dataSource">
          <div class="table-button-icons">
            <button
              type="button"
              class="table-button-icon"
              matTooltip="Details anzeigen"
              (click)="showParticipantDetails(user)">
              <i class="fa-light fa-arrow-up-right-from-square"></i>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td [attr.colspan]="displayedColumns.length">
          <div *ngIf="isLoading" class="loading-wrapper">
            <app-lottie-loading-spinner
              size="45px"></app-lottie-loading-spinner>
          </div>
          <div
            *ngIf="!isLoading"
            class="mat-cell table-no-match hasomed-text-body font-grey">
            Es wurden keine Teilnehmenden gefunden.
          </div>
        </td>
      </tr>
    </table>

    <mat-divider></mat-divider>
    <mat-paginator
      custom-paginator
      [appCustomLength]="dataSource.filteredData.length"
      [pageSize]="5"
      [renderButtonsNumber]="isMobile ? 0 : 1"
      aria-label="Select page"></mat-paginator>
  </div>
  <div class="vertical-spacer"></div>
</div>
