import { Component } from '@angular/core';
import { Feature, Permission } from 'src/app/models/permission.model';

@Component({
  selector: 'app-student-education-progress',
  templateUrl: './student-education-progress.component.html',
  styleUrls: ['./student-education-progress.component.scss'],
})
export class StudentEducationProgressComponent {
  public Feature = Feature;
  public Permission = Permission;
  constructor() {}
}
