import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { InstituteEvent } from '../models/event.model';
import { Filter, FilterDateRange, FilterType } from '../models/filter.model';
import { RoomModel } from '../models/room.model';
import { isDateInRange } from '../utils/date.utils';

@Pipe({
  name: 'filterRoomOrganization',
  standalone: true,
})
export class FilterRoomOrganizationPipe implements PipeTransform {
  transform(
    instituteEvents: InstituteEvent[],
    filter: Filter[]
  ): InstituteEvent[] {
    if (!instituteEvents || !filter) {
      return instituteEvents;
    }

    return instituteEvents.filter(instituteEvent => {
      return filter.every(filter => {
        if (filter.value === null) {
          return true;
        }

        switch (filter.type) {
          case FilterType.DATE_RANGE:
            return isDateInRange(
              instituteEvent.startDate,
              instituteEvent.endDate,
              filter.value as FilterDateRange
            );
          case FilterType.ROOM_NAME:
            if (this.isRoom(filter.value)) {
              return instituteEvent.currentRoom?.id === filter.value.id;
            }
            return false;
          case FilterType.OPEN_ROOMPLANNING:
            return instituteEvent.eventDates.some(eventDate => {
              const isEventInFutureAndHasNoRoom =
                moment(eventDate.startDate).isSameOrAfter(moment()) &&
                !eventDate.room;
              return isEventInFutureAndHasNoRoom;
            });
          default:
            return true;
        }
      });
    });
  }

  private isRoom(obj: any): obj is RoomModel {
    return obj && typeof obj === 'object' && 'floor' in obj;
  }
}
