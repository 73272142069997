<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Bildungsgang</h1>
      <h3 class="hasomed-text-h3">Sehen Sie sich den Bildungsgang ein.</h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onGoBack()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Zurück</span>
      </button>
      <button
        [disabled]="isLoading"
        (click)="onEdit()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-pen hasomed-button-icon"></i>
        <span class="hasomed-button-text">Bearbeiten</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content-details">
    <!-- Ordnung -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Verordnung</span>
        <span class="label-description">
          Zu welcher Verordnung gehört der Bildungsgang?
        </span>
      </div>
      <div class="detail-value">
        {{ educationCourse?.regulation }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Titel -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Ausbildungstitel</span>
        <span class="label-description">
          Beschreibender kurzer Titel für die Ausbildung.
        </span>
      </div>
      <div class="detail-value">
        {{ educationCourse?.title }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Fachkunde -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Fachkunde/Gebietsweiterbildung </span>
        <span class="label-description">
          Wählen Sie die passende Fachkunde.
        </span>
      </div>
      <div class="detail-value">
        {{ educationCourse?.expertise?.name }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Richtlinienverfahren -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Richtlinienverfahren/Bereichsweiterbildung
        </span>
        <span class="label-description">
          Welches Richtlinenverfahren findet Anwendung? (nur eine auswahl
          möglich)
        </span>
      </div>
      <div class="detail-value">
        {{ educationCourse?.procedure?.name }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Berufsverband -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Akkreditierung durch Berufsverband </span>
        <span class="label-description">
          Bei welchem Berufsverband sind Sie akkreditiert?
        </span>
      </div>
      <div class="detail-value">
        {{ educationCourse?.professionalAssociation }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Akkreditierungsjahr -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Akkreditierungsjahr</span>
        <span class="label-description"> Wann wurden Sie akkreditiert? </span>
      </div>
      <div class="detail-value">
        {{ educationCourse?.accreditationYear }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kammer -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Zuständige Kammer</span>
        <span class="label-description">
          Welche Kammer ist für Sie zuständig?
        </span>
      </div>
      <div class="detail-value">
        {{ educationCourse?.chamber }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Interne Notiz -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Interne Notizen zur Ausbildung </span>
        <span class="label-description">
          Nur mit der Rolle Verwaltung sichtbar.
        </span>
      </div>
      <div class="detail-value">
        {{
          educationCourse?.internalNotes &&
          educationCourse?.internalNotes !== ''
            ? educationCourse?.internalNotes
            : '-'
        }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Externe Notiz -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Externe Notizen zur Ausbildung </span>
        <span class="label-description">
          Werden Auszubildenden öffentlich angezeigt.
        </span>
      </div>
      <div class="detail-value">
        {{
          educationCourse?.externalNotes &&
          educationCourse?.externalNotes !== ''
            ? educationCourse?.externalNotes
            : '-'
        }}
      </div>
    </div>

    <!-- SUB HEADER -->
    <div class="flex-c sub-header">
      <h1 class="hasomed-text-h1">Kontingente für Ausbildung</h1>
      <h3 class="hasomed-text-h3">Festlegung der einzelnen Kontingente.</h3>
    </div>

    <!-- Kontingent Theoretisch-Wissenschaftlich -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Theoretisch-Wissenschaftlich </span>
        <span class="label-description"> Kontingent für die Ausbildung. </span>
      </div>
      <div class="detail-value">
        mind.
        {{ educationCourse?.contingentTheoreticalScientificHours ?? 0 }} Stunden
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kontingent Praktisch-1 -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Praktische Tätigkeit 1 </span>
        <span class="label-description"> Kontingent für die Ausbildung. </span>
      </div>
      <div class="detail-value">
        mind. {{ educationCourse?.contingentPractical1Hours ?? 0 }} Stunden
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kontingent Praktisch-2 -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Praktische Tätigkeit 2 </span>
        <span class="label-description"> Kontingent für die Ausbildung. </span>
      </div>
      <div class="detail-value">
        mind. {{ educationCourse?.contingentPractical2Hours ?? 0 }} Stunden
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kontingent Behandlungspraktikum -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Behandlungspraktikum </span>
        <span class="label-description"> Kontingent für die Ausbildung. </span>
      </div>
      <div class="detail-columns">
        <div class="detail-value">
          mind.
          {{ educationCourse?.contingentTreatmentInternshipHours ?? 0 }} Stunden
          (Gesamtstunden)
        </div>
        <div class="detail-value">
          <span class="inner-label">Fälle gesamt</span>
          <span class="inner-value">
            {{ educationCourse?.contingentCompletedTreatmentCases ?? 0 }}
          </span>
        </div>
        <div class="detail-value">
          <span class="inner-label"> davon mind. Fälle: </span>
          <span class="inner-value">
            {{ educationCourse?.contingentTreatmentCasesMin ?? 0 }}
          </span>
        </div>
        <div class="detail-value">
          <span class="inner-label"> mit mind. Stunden </span>
          <span class="inner-value">
            {{ educationCourse?.contingentTreatmentCasesMinHours ?? 0 }}
          </span>
        </div>
        <div class="detail-value">
          <span class="inner-label"> davon mind. Fälle: </span>
          <span class="inner-value">
            {{ educationCourse?.contingentTreatmentCasesMin2 ?? 0 }}
          </span>
        </div>
        <div class="detail-value">
          <span class="inner-label"> mit mind. Stunden </span>
          <span class="inner-value">
            {{ educationCourse?.contingentTreatmentCasesMin2Hours ?? 0 }}
          </span>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kontingent Supervision -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Supervision</span>
        <span class="label-description"> Kontingent für die Ausbildung. </span>
      </div>
      <div class="detail-columns">
        <div class="detail-value">
          mind. {{ educationCourse?.contingentSupervisionHours ?? 0 }} Stunden
        </div>
        <div class="detail-value">
          <span class="inner-label"> davon mind. in Einzelsitzung </span>
          <span class="inner-value">
            {{ educationCourse?.contingentSupervisionSingleSessionHours ?? 0 }}
          </span>
        </div>
        <div class="detail-value">
          <span class="inner-label"> Rest in Gruppe mit max. 4 TN </span>
          <span class="inner-value">
            {{ educationCourse?.contingentSupervisionGroupSessionHours ?? 0 }}
          </span>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kontingent Supervision -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Lehrtherapie, Lehranalyse oder Selbsterfahrung
        </span>
        <span class="label-description"> Kontingent für die Ausbildung. </span>
      </div>
      <div class="detail-columns">
        <div class="detail-value">
          mind.
          {{ educationCourse?.contingentSelfExperienceHours ?? 0 }}
          Stunden
        </div>
        <div class="detail-value">
          <span class="inner-label"> mind. Sitzungen (gesamt) </span>
          <span class="inner-value">
            {{ educationCourse?.contingentSelfExperienceSessionsMin ?? 0 }}
          </span>
        </div>
        <div class="detail-value">
          <span class="inner-label"> davon mind. in Einzelsitzung </span>
          <span class="inner-value">
            {{
              educationCourse?.contingentSelfExperienceSingleSessionsMin ?? 0
            }}
          </span>
        </div>
        <div class="detail-value">
          <span class="inner-label"> davon mind. in Gruppensitzung</span>
          <span class="inner-value">
            {{ educationCourse?.contingentSelfExperienceGroupSessionsMin ?? 0 }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
