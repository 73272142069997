import { SelectOption } from '../models/select-option.model';

export const practicalWorkTypeSelectOptions: SelectOption[] = [
  {
    value: 1,
    label: 'Psychiatrisch klinische Einrichtung (§2 Abs. 2 Nr. 1 PsychTh-APrV)',
  },
  {
    value: 2,
    label:
      'Einrichtung der psychotherapeutischen Versorgung (§2 Abs. 2 Nr. 2 PsychTh-APrV)',
  },
];
