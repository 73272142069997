import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-detail-dialog',
  templateUrl: './image-detail-dialog.component.html',
  styleUrls: ['./image-detail-dialog.component.scss'],
})
export class ImageDetailDialogComponent {
  public profilePicture: any;
  public title: string = 'Ihr Profilbild';
  constructor(
    public dialogRef: MatDialogRef<ImageDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { image: any; title?: string; viewOnly?: boolean }
  ) {
    this.profilePicture = data.image;
    if (data.viewOnly) {
      this.title = 'Profilbild';
    }
    if (data.title) {
      this.title = data.title;
    }
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onDelete(): void {
    this.dialogRef.close('delete');
  }
}
