import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs';
import { Role } from 'src/app/models/permission.model';
import { UserCreateModel } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { InstituteService } from 'src/app/services/institute.service';
import { isRequired } from 'src/app/utils/form.utils';
import { emailValidator } from 'src/app/validators/email.validator';

@Component({
  selector: 'app-invite-user-dialog',
  templateUrl: './invite-user-dialog.component.html',
  styleUrl: './invite-user-dialog.component.scss',
})
export class InviteUserDialogComponent {
  public instituteId: number;
  public inviteUserForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, emailValidator()]),
  });

  public isRequired = isRequired;

  constructor(
    public dialogRef: MatDialogRef<InviteUserDialogComponent>,
    private alertService: AlertService,
    public instituteService: InstituteService,
    @Inject(MAT_DIALOG_DATA) public data: { instituteId: number }
  ) {
    dialogRef.disableClose = true;
    this.instituteId = data.instituteId;
  }

  public onConfirm(): void {
    const userCreateModel: UserCreateModel = {
      name: {
        firstName: this.inviteUserForm.get('firstName').value,
        lastName: this.inviteUserForm.get('lastName').value,
      },
      email: this.inviteUserForm.get('email').value,
      roleId: Role.ADMINISTRATOR,
    };
    this.instituteService
      .invitePrimaryAdmin(this.instituteId, userCreateModel)
      .pipe(first())
      .subscribe({
        next: response => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Benutzer wurde erfolgreich eingeladen.'
          );
          this.dialogRef.close(userCreateModel);
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Das Einladen des Benutzers ist fehlgeschlagen.'
          );
        },
      });
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }
}
