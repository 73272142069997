import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FileModel } from 'src/app/models/file.model';
import { SelfAwarenessEntryModel } from 'src/app/models/self-awareness-entry.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { SelfAwarenessService } from 'src/app/services/self-awareness.service';
import { getFullName } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-self-awareness-details',
  templateUrl: './self-awareness-details.component.html',
  styleUrl: './self-awareness-details.component.scss',
})
export class SelfAwarenessDetailsComponent implements OnInit, OnDestroy {
  public selfAwarenessEntry?: SelfAwarenessEntryModel;
  public isLoading: boolean = true;

  private destroy$: Subject<void> = new Subject<void>();

  public getFullName = getFullName;

  constructor(
    private selfAwarenessService: SelfAwarenessService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private cancellationService: CancellationService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        if (!params.id) {
          return;
        }

        const selfAwarenessEntryId = +atob(params.id);
        this.initializeSelfAwarenessEntry(selfAwarenessEntryId);
      });
  }

  /**
   * Initializes the self awareness entry
   * @param selfAwarenessEntryId The self awareness entry id
   * @returns void
   */
  private initializeSelfAwarenessEntry(selfAwarenessEntryId: number): void {
    this.selfAwarenessService
      .getSelfAwarenessEntryById(selfAwarenessEntryId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.selfAwarenessEntry = response.body
            ? await this.selfAwarenessService.parseBackendSelfAwarenessEntry(
                response.body
              )
            : null;
          this.isLoading = false;
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat nicht geklappt!',
            'Der Eintrag konnte nicht geladen werden.'
          );
        },
      });
  }

  /**
   * onEditSelfAwarenessEntry
   */
  public onEditSelfAwarenessEntry() {
    if (this.selfAwarenessEntry) {
      this.router.navigate(
        ['../edit', btoa(String(this.selfAwarenessEntry.id))],
        {
          relativeTo: this.activatedRoute,
        }
      );
    }
  }

  /**
   * onGoBack
   */
  public onGoBack() {
    this.location.back();
  }

  /**
   * onOpenFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenFile(file: FileModel): void {
    this.selfAwarenessService.openFile(this.selfAwarenessEntry.id, file.id);
  }

  /**
   * onDownloadFile
   * download the file
   * @param file
   * @returns void
   */
  public onDownloadFile(file: FileModel): void {
    this.selfAwarenessService.downloadFile(this.selfAwarenessEntry.id, file.id);
  }

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
