import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministrationComponent } from './administration.component';
import { InstitutesComponent } from './institutes/institutes.component';
import { MaterialModule } from 'src/app/modules/material.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/modules/shared.module';
import { UsersAndRolesComponent } from './users-and-roles/users-and-roles.component';
import { UpdateUserDialogComponent } from './users-and-roles/update-user-dialog/update-user-dialog.component';

@NgModule({
  declarations: [
    AdministrationComponent,
    InstitutesComponent,
    UsersAndRolesComponent,
    UpdateUserDialogComponent,
  ],
  exports: [AdministrationComponent],
  imports: [
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    CommonModule,
  ],
})
export class AdministrationModule {}
