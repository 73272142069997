<span mat-dialog-title>
  {{ title }}
</span>
<div mat-dialog-content>
  <div class="flex-r justify-center">
    <img
      [attr.src]="profilePicture ?? '../../../../../assets/img/user.jpg'"
      [alt]="data.viewOnly ? 'Profilbild' : 'Ihr Profilbild'"
      class="profilePicture" />
  </div>
</div>

<div class="action-buttons">
  <button
    type="button"
    mat-dialog-close
    class="hasomed-text-button hasomed-button-primary">
    <i class="hasomed-button-icon fa-light fa-x"></i>
    <span class="hasomed-button-text">Schließen</span>
  </button>
  <button
    *ngIf="!data.viewOnly"
    type="button"
    (click)="onDelete()"
    [disabled]="!profilePicture"
    class="hasomed-text-button hasomed-button-white">
    <i class="hasomed-button-icon fa-light fa-trash"></i>
    <span class="hasomed-button-text">Profilbild löschen</span>
  </button>
</div>
