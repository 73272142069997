<div class="empty-state-content">
  <div class="icon">
    <i class="fa-light fa-elephant"></i>
  </div>

  <div class="text">
    <h1 class="hasomed-text-title">Spannende Neuerungen sind Unterwegs!</h1>
    <h3 class="hasomed-text-h3">
      <p>
        Wir entwickeln EleGuide stetig weiter.<br />
        Daher gibt es Stellen, an denen der volle Funktionsumfang noch nicht
        sichtbar ist. Wir wollen gemeinsam herausfinden, welche spannenden neuen
        Funktionen für unsere Kund:innen interessant sein könnten. Da Sie
        geklickt haben, interessiert uns Ihr Feedback!
      </p>

      <p>Benötigen Sie diese Funktion? Wie würde Sie Ihnen helfen?</p>

      <p>
        Teilen Sie Ihr Feedback einfach und unverbindlich über den
        Feedback-Button mit.
      </p>

      <p>Zusammen gestalten wir die Zukunft der Aus- und Weiterbildung.</p>
    </h3>
  </div>

  <button
    type="button"
    class="hasomed-text-button hasomed-button-primary"
    (click)="onOpenFeedbackPage()">
    <i class="hasomed-button-icon fa-light fa-message-question"></i>
    <span class="hasomed-button-text">Feedback / Support</span>
  </button>
</div>
