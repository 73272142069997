import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent {
  public changePasswordForm: FormGroup;
  public showOldPassword = false;
  public showNewPassword = false;
  public showNewPasswordConfirm = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private alertService: AlertService
  ) {}

  public ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required]),
      newPasswordConfirm: new FormControl('', [Validators.required]),
    });

    // get controls
    const oldPasswordControl = this.changePasswordForm.get('oldPassword');
    const newPasswordControl = this.changePasswordForm.get('newPassword');
    const newPasswordConfirmControl =
      this.changePasswordForm.get('newPasswordConfirm');

    // update validity of newPassword when oldPassword changes and newPassword is not empty
    oldPasswordControl.valueChanges.subscribe(value => {
      if (newPasswordControl.value !== '') {
        newPasswordControl.updateValueAndValidity();
      }
    });

    // check if new Password is the same as old password
    newPasswordControl.valueChanges.subscribe(value => {
      if (value === oldPasswordControl.value) {
        this.changePasswordForm
          .get('newPassword')
          .setErrors({ sameAsOldPassword: true });
      } else {
        // only remove error when control has no required error
        if (
          !newPasswordControl.hasError('required') &&
          !this.changePasswordForm
            .get('newPassword')
            .hasError('sameAsOldPassword')
        ) {
          newPasswordControl.setErrors(null);
          newPasswordConfirmControl.updateValueAndValidity();
        }
      }
    });

    // check if newPassword and newPasswordConfirm are the same, and newPasswordConfirm has required length of 6
    newPasswordConfirmControl.valueChanges.subscribe(value => {
      // check if new password and confirm password are the same
      if (value !== newPasswordControl.value) {
        newPasswordConfirmControl.setErrors({ notSame: true });
      } else {
        // only remove error when control has no required error
        if (!newPasswordConfirmControl.hasError('required')) {
          this.changePasswordForm.get('newPasswordConfirm').setErrors(null);
        }
      }
    });
  }

  /**
   * togglePasswordVisability
   */
  public togglePasswordVisability(passwordField: string) {
    if (passwordField === 'oldPassword') {
      this.showOldPassword = !this.showOldPassword;
    } else if (passwordField === 'newPassword') {
      this.showNewPassword = !this.showNewPassword;
    } else if (passwordField === 'newPasswordConfirm') {
      this.showNewPasswordConfirm = !this.showNewPasswordConfirm;
    }
  }

  public onConfirm() {
    if (this.changePasswordForm.valid) {
      const oldPassword = this.changePasswordForm.get('oldPassword').value;
      const newPassword = this.changePasswordForm.get('newPassword').value;

      this.alertService.showErrorAlert(
        'Das hat nicht geklappt',
        'Bitte ändern Sie Ihr Passwort direkt in Cidaas.'
      );
    }
  }

  public onDismiss() {
    this.dialogRef.close(false);
  }

  /**
   * hasErrors
   * @param formFieldName name of form field
   * @returns if the field has errors
   */
  public hasErrors(formFieldName: string) {
    const formField = this.changePasswordForm.get(formFieldName);
    if (!formField) {
      return false;
    }
    return (
      formField.invalid &&
      formField.errors &&
      (formField.dirty || formField.touched)
    );
  }
}
