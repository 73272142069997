export enum WeekdayEnum {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export const Weekdays = Object.keys(WeekdayEnum)
  .filter(key => isNaN(Number(key)))
  .map(key => ({ key, value: WeekdayEnum[key as keyof typeof WeekdayEnum] }));
