<form
  [formGroup]="practicalWorkForm"
  (ngSubmit)="onSubmit()"
  class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{ editMode ? 'Eintrag bearbeiten' : 'Neuen Eintrag vornehmen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie einen bestehenden Eintrag.'
            : 'Fügen Sie einen neuen Eintrag hinzu.'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>

      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <!-- LOADING WRAPPER -->
  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content-form">
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Praktische Tätigkeit
          <span *ngIf="isRequired(practicalWorkForm.get('type'))">*</span>
        </span>
        <span class="label-description">
          Um welche Art der praktischen Tätigkeit handelt es sich?
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <eleguide-select
            [control]="typeControl"
            controlId="type"
            [options]="typeSelectOptions"
            placeholder="Wählen Sie Ihre Praktische Tätigkeit"></eleguide-select>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="practicalWorkForm.get('type')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Kooperationspartner
          <span
            *ngIf="isRequired(practicalWorkForm.get('cooperationPartnerId'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Wählen Sie einen Kooperationspartner.
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <eleguide-select
            [control]="cooperationPartnerControl"
            controlId="cooperationPartnerId"
            [options]="availableCooperationPartners"
            [placeholder]="
              availableCooperationPartners.length === 0
                ? 'Keine Kooperationspartner vorhanden'
                : 'Wählen Sie einen Kooperationspartner'
            "></eleguide-select>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            practicalWorkForm.get('cooperationPartnerId')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="description">
        <span class="label-title">
          Beschreibung
          <span *ngIf="isRequired(practicalWorkForm.get('description'))"
            >*</span
          >
        </span>
        <span class="label-description"> Ergänzende Informationen </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="description"
            class="hasomed-textarea-input"
            rows="5"
            formControlName="description"
            placeholder="Beschreibung"></textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            practicalWorkForm.get('description')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Dauer der Tätigkeit
          <span *ngIf="isRequired(practicalWorkForm.get('duration'))">*</span>
        </span>
        <span class="label-description">
          Gesamtdauer der praktischen Tätigkeit in Stunden
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="duration"
            class="hasomed-text-input"
            min="0"
            step="0.5"
            type="number"
            formControlName="duration"
            placeholder="Dauer" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="practicalWorkForm.get('duration')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label flex-1 align-center">
        <span class="label-title">
          Zeitraum
          <span *ngIf="isRequired(practicalWorkForm.get('dateRange'))">*</span>
        </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <div
            [class.error-border]="
              practicalWorkForm.get('dateRange').invalid &&
              practicalWorkForm.get('dateRange').touched
            "
            class="hasomed-date-range">
            <mat-date-range-input
              [min]="minDate"
              [rangePicker]="picker"
              class="hasomed-date-range-input"
              formGroupName="dateRange">
              <input
                [min]="minDate"
                id="startDate"
                matStartDate
                placeholder="Startdatum"
                formControlName="startDate" />
              <input
                [min]="minDate"
                id="endDate"
                matEndDate
                placeholder="Enddatum"
                formControlName="endDate" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              [for]="picker"
              class="hasomed-date-range-toggle"
              matIconSuffix>
              <mat-icon
                matDatepickerToggleIcon
                class="calendar-toggle-icon fa-light fa-calendar">
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker
              #picker
              [restoreFocus]="false"></mat-date-range-picker>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          *ngIf="!practicalWorkForm.get('dateRange').get('endDate').invalid"
          [formField]="
            practicalWorkForm.get('dateRange').get('startDate')
          "></app-error-message>
        <app-error-message
          [formField]="
            practicalWorkForm.get('dateRange').get('endDate')
          "></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="documentUpload">
        <span class="label-title">
          Bestätigung
          <span *ngIf="isRequired(practicalWorkForm.get('documentUpload'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Laden Sie hier Ihre Bestätigung hoch.
        </span>
      </label>
      <div class="formular-row-input">
        <div id="documents" class="document-upload-wrapper">
          <app-upload-area-dnd
            [existingFiles]="existingFiles"
            [showFileList]="true"
            [multiple]="false"
            [allowedFileTypes]="allowedFileTypesDocuments"
            [scrollTo]="true"
            [sensitiveDataAlert]="false"
            (openExistingFile)="onOpenExistingFile($event)"
            (downloadExistingFile)="onDownloadExistingFile($event)"
            (deleteExistingFile)="onDeleteExistingFile($event)"
            context="practicalWorkDocumentsUpload"
            inputId="documentUpload"
            formControlName="uploadedFiles"
            maxFileSize="12 MB">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>
  </div>
</form>
