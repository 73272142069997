import { Component } from '@angular/core';
import { NavLink } from 'src/app/models/nav-link.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss'],
})
export class EducationComponent {
  public isStudent = this.userService.currentUserIsStudent();
  public navLinks: NavLink[] = [
    {
      label: 'Kursverwaltung',
      link: 'course-administration',
      feature: Feature.COURSE_ADMINISTRATION,
      permission: Permission.VIEW,
    },
    {
      label: 'Kandidaten',
      link: 'students',
      feature: Feature.STUDENT_ADMINISTRATION,
      permission: Permission.VIEW,
    },
    {
      label: 'Lehrpersonal',
      link: 'lecturers',
      feature: Feature.LECTURER_ADMINISTRATION,
      permission: Permission.VIEW,
    },
    {
      label: 'Theoretische Ausbildung',
      link: 'theoretical-education',
      feature: Feature.THEORETICAL_EDUCATION,
      permission: Permission.VIEW,
    },
    {
      label: 'Praktische Tätigkeit',
      link: 'practical-work',
      feature: Feature.PRACTICAL_WORK_NAVIGATION,
      permission: Permission.VIEW,
    },
    {
      label: 'Ambulanter Teil',
      link: 'ambulatory-part',
      feature: Feature.AMBULATORY_PART,
      permission: Permission.VIEW,
    },
    {
      label: 'Supervision',
      link: 'supervision',
      feature: Feature.SUPERVISION,
      permission: Permission.VIEW,
    },
    {
      label: 'Selbsterfahrung / Lehranalyse',
      link: 'self-awareness',
      feature: Feature.SELF_AWARENESS,
      permission: Permission.VIEW,
    },
    // {
    //   label: 'Raumorganisation',
    //   link: 'room-organization',
    //   feature: Feature.ROOM_PLANNING,
    //   permission: Permission.VIEW,
    // },
    {
      label: 'Kooperationspartner',
      link: 'cooperation-partner',
      feature: Feature.COOPERATION_PARTNER,
      permission: Permission.VIEW,
    },
    {
      label: 'Ausbildungsbegleitung',
      link: 'advanced-lecturers',
      feature: Feature.ADVANCED_LECTURERS,
      permission: Permission.VIEW,
      infoTooltip: 'Selbsterfahrungsleiter, Supervisoren, Lehranalytiker',
    },
  ];

  constructor(private userService: UserService) {}
}
