<form class="flex-c" [formGroup]="filterForm">
  <div class="filter-wrapper" (click)="$event.stopPropagation()">
    <div *ngIf="isLoading" class="loading-wrapper">
      <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
    </div>

    <!-- Info shown when no filter is given -->
    <span *ngIf="!formGroupHasControls()" class="hasomed-text-body">
      Keine Filter vorhanden
    </span>

    <ng-container *ngIf="!isLoading">
      <!-- STUDENT LECTURER FILTER -->
      <!-- educationcourse dropdown -->
      <div
        class="form-group"
        *ngIf="filterForm.get(filterType.EDUCATION_COURSE)">
        <label>Bildungsgang</label>
        <mat-form-field class="hasomed-select">
          <mat-select
            [id]="filterType.EDUCATION_COURSE"
            class="hasomed-select"
            [formControlName]="filterType.EDUCATION_COURSE"
            [compareWith]="compareEducationCourse">
            <mat-option [value]="null"> </mat-option>
            <mat-option
              [value]="educationCourse"
              class="hasomed-text-body"
              *ngFor="let educationCourse of educationCourses">
              {{ educationCourse.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- label dropdown -->
      <div class="form-group" *ngIf="filterForm.get(filterType.LABEL)">
        <label>Etikett</label>
        <mat-form-field class="hasomed-select">
          <mat-select
            [id]="filterType.LABEL"
            class="hasomed-select"
            [formControlName]="filterType.LABEL"
            [compareWith]="compareLabel">
            <mat-option [value]="null"> </mat-option>
            <mat-option
              [value]="label"
              class="hasomed-text-body"
              *ngFor="let label of labels">
              <div class="label" [style.background]="label.color">
                <i class="fa-light fa-id-badge label-icon"></i>
                <span class="hasomed-text-small label-text">
                  {{ label.name }}
                </span>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- additionalQualification dropdown -->
      <div
        class="form-group"
        *ngIf="filterForm.get(filterType.ADDITIONAL_QUALIFICATION)">
        <label>Zusatzqualifikation</label>
        <mat-form-field class="hasomed-select">
          <mat-select
            [id]="filterType.ADDITIONAL_QUALIFICATION"
            class="hasomed-select"
            [formControlName]="filterType.ADDITIONAL_QUALIFICATION"
            [compareWith]="compareAdditionalQualification">
            <mat-option [value]="null"> </mat-option>
            <mat-option
              [value]="additionalQualification"
              class="hasomed-text-body"
              *ngFor="let additionalQualification of additionalQualifications">
              {{ additionalQualification.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- procedure filter -->
      <div class="form-group" *ngIf="filterForm.get(filterType.PROCEDURE)">
        <label>Richtlinienverfahren</label>
        <mat-form-field class="hasomed-select">
          <mat-select
            [id]="filterType.PROCEDURE"
            class="hasomed-select"
            [formControlName]="filterType.PROCEDURE"
            [compareWith]="compareProcedure">
            <mat-option [value]="null"> </mat-option>
            <mat-option
              [value]="procedure"
              class="hasomed-text-body"
              *ngFor="let procedure of procedures">
              {{ procedure.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- expertise filter -->
      <div class="form-group" *ngIf="filterForm.get(filterType.EXPERTISE)">
        <label>Fachkunde</label>
        <mat-form-field class="hasomed-select">
          <mat-select
            [id]="filterType.EXPERTISE"
            class="hasomed-select"
            [formControlName]="filterType.EXPERTISE"
            [compareWith]="compareExpertise">
            <mat-option [value]="null"> </mat-option>
            <mat-option
              [value]="expertise"
              class="hasomed-text-body"
              *ngFor="let expertise of expertises">
              {{ expertise.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- COURSE FILTER -->
      <!-- lecturer -->
      <div class="form-group" *ngIf="filterForm.get(filterType.LECTURER)">
        <label>Lehrpersonal</label>
        <mat-form-field class="hasomed-select">
          <mat-select
            [id]="filterType.LECTURER"
            class="hasomed-select"
            [formControlName]="filterType.LECTURER"
            [compareWith]="compareFilterUser">
            <mat-option [value]="null"> </mat-option>
            <mat-option
              [value]="lecturer"
              class="hasomed-text-body"
              *ngFor="let lecturer of instituteLecturer">
              {{ lecturer.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- course type -->
      <div class="form-group" *ngIf="filterForm.get(filterType.COURSE_TYPE)">
        <label>Kurs-Typ</label>
        <mat-form-field class="hasomed-select">
          <mat-select
            [id]="filterType.COURSE_TYPE"
            class="hasomed-select"
            [formControlName]="filterType.COURSE_TYPE">
            <mat-option [value]="null"> </mat-option>
            <mat-option
              [value]="type"
              class="hasomed-text-body"
              *ngFor="let type of courseTypes">
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- room name -->
      <div class="form-group" *ngIf="filterForm.get(filterType.ROOM_NAME)">
        <label>Raum</label>
        <mat-form-field class="hasomed-select">
          <mat-select
            [id]="filterType.ROOM_NAME"
            class="hasomed-select"
            [formControlName]="filterType.ROOM_NAME">
            <mat-option [value]="null"> </mat-option>
            <mat-option
              [value]="room"
              class="hasomed-text-body"
              *ngFor="let room of rooms">
              {{ room.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- ROOM FILTER -->
      <!-- room floor -->
      <div class="form-group" *ngIf="filterForm.get(filterType.ROOM_FLOOR)">
        <span class="hasomed-text-small">Etage</span>
        <mat-form-field class="hasomed-select">
          <mat-select
            [id]="filterType.ROOM_FLOOR"
            class="hasomed-select"
            [formControlName]="filterType.ROOM_FLOOR">
            <mat-option [value]="null"> </mat-option>
            <mat-option [value]="0" class="hasomed-text-body"> EG </mat-option>
            <mat-option [value]="1" class="hasomed-text-body">
              1. OG
            </mat-option>
            <mat-option [value]="2" class="hasomed-text-body">
              2. OG
            </mat-option>
            <mat-option [value]="3" class="hasomed-text-body">
              3. OG
            </mat-option>
            <mat-option [value]="4" class="hasomed-text-body">
              4. OG
            </mat-option>
            <mat-option [value]="5" class="hasomed-text-body">
              5. OG
            </mat-option>
            <mat-option [value]="6" class="hasomed-text-body">
              6. OG
            </mat-option>
          </mat-select></mat-form-field
        >
      </div>

      <!-- room capacity -->
      <div class="form-group" *ngIf="filterForm.get(filterType.ROOM_CAPACITY)">
        <span class="hasomed-text-small">Platzanzahl: Mindestens...</span>
        <mat-form-field class="hasomed-select">
          <mat-select
            [id]="filterType.ROOM_CAPACITY"
            class="hasomed-select"
            [formControlName]="filterType.ROOM_CAPACITY">
            <mat-option [value]="null"> </mat-option>
            <mat-option [value]="5" class="hasomed-text-body"> 5 </mat-option>
            <mat-option [value]="10" class="hasomed-text-body"> 10 </mat-option>
            <mat-option [value]="20" class="hasomed-text-body"> 20 </mat-option>
            <mat-option [value]="30" class="hasomed-text-body"> 30 </mat-option>
            <mat-option [value]="40" class="hasomed-text-body"> 40 </mat-option>
            <mat-option [value]="50" class="hasomed-text-body"> 50 </mat-option>
          </mat-select></mat-form-field
        >
      </div>

      <!-- Members -->
      <!-- role dropdown -->
      <div class="form-group" *ngIf="filterForm.get(filterType.ROLE)">
        <label>Rolle</label>
        <mat-form-field class="hasomed-select">
          <mat-select
            [id]="filterType.ROLE"
            class="hasomed-select"
            [formControlName]="filterType.ROLE">
            <mat-option [value]="null"> </mat-option>
            <mat-option [value]="Role.ADMINISTRATOR" class="hasomed-text-body">
              Verwaltung
            </mat-option>
            <mat-option [value]="Role.LECTURER" class="hasomed-text-body">
              Lehrpersonal
            </mat-option>
            <mat-option [value]="Role.STUDENT" class="hasomed-text-body">
              Kandidat
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- logonState dropdown -->
      <div class="form-group" *ngIf="filterForm.get(filterType.LOGON_STATE)">
        <label>Anmeldestatus</label>
        <mat-form-field class="hasomed-select">
          <mat-select
            [id]="filterType.LOGON_STATE"
            class="hasomed-select"
            [formControlName]="filterType.LOGON_STATE">
            <mat-option [value]="null"> </mat-option>
            <mat-option value="invited" class="hasomed-text-body">
              Eingeladen
            </mat-option>
            <mat-option value="registered" class="hasomed-text-body">
              Registriert
            </mat-option>
            <mat-option value="confirmed" class="hasomed-text-body">
              Beigetreten
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- SUPERVISION FILTER -->
      <!-- student -->
      <div
        class="form-group"
        *ngIf="filterForm.get(filterType.SUPERVISION_STUDENT)">
        <label>Kandidat</label>
        <mat-form-field class="hasomed-select">
          <mat-select
            [id]="filterType.SUPERVISION_STUDENT"
            class="hasomed-select"
            [formControlName]="filterType.SUPERVISION_STUDENT"
            [compareWith]="compareFilterUser">
            <mat-option [value]="null"> </mat-option>
            <mat-option
              [value]="student"
              class="hasomed-text-body"
              *ngFor="let student of instituteStudents">
              {{ student.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- supervisor -->
      <div
        class="form-group"
        *ngIf="filterForm.get(filterType.SUPERVISION_SUPERVISOR)">
        <label>Supervisor</label>
        <mat-form-field class="hasomed-select">
          <mat-select
            [id]="filterType.SUPERVISION_SUPERVISOR"
            class="hasomed-select"
            [formControlName]="filterType.SUPERVISION_SUPERVISOR"
            [compareWith]="compareFilterUser">
            <mat-option [value]="null"> </mat-option>
            <mat-option
              [value]="lecturer"
              class="hasomed-text-body"
              *ngFor="let lecturer of instituteLecturer">
              {{ lecturer.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- supervisionStatus dropdown -->
      <div
        class="form-group"
        *ngIf="filterForm.get(filterType.SUPERVISION_STATUS)">
        <label>Status</label>
        <mat-form-field class="hasomed-select">
          <mat-select
            [id]="filterType.SUPERVISION_STATUS"
            class="hasomed-select"
            [formControlName]="filterType.SUPERVISION_STATUS">
            <mat-option [value]="null"> </mat-option>
            <mat-option value="checked" class="hasomed-text-body">
              Geprüft
            </mat-option>
            <mat-option value="unchecked" class="hasomed-text-body">
              Ungeprüft
            </mat-option>
            <mat-option value="canceled" class="hasomed-text-body">
              Abgesagt
            </mat-option>
            <mat-option value="planned" class="hasomed-text-body">
              Geplant
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- advanced filters -->
      <div class="flex-c gap-16" *ngIf="showAdvancedFilters()">
        <span class="advanced-filter-label">Erweiterte Filter</span>

        <!-- user filter -->
        <!-- onlyDoctors -->
        <mat-slide-toggle
          *ngIf="filterForm.get(filterType.ONLY_DOCTORS)"
          hideIcon
          [formControlName]="filterType.ONLY_DOCTORS"
          (click)="$event.stopPropagation()">
          <div class="toggle-text">
            <span class="hasomed-text-body toggle-text-title">
              Ausschließlich Ärzte
            </span>
            <span class="hasomed-text-small toggle-text-description">
              Nur Ärzte anzeigen
            </span>
          </div>
        </mat-slide-toggle>

        <!-- COURSE FILTER -->
        <!-- registeredCourses -->
        <mat-slide-toggle
          *ngIf="filterForm.get(filterType.REGISTERED_COURSES)"
          hideIcon
          [formControlName]="filterType.REGISTERED_COURSES"
          (click)="$event.stopPropagation()">
          <div class="toggle-text">
            <span class="hasomed-text-body toggle-text-title">
              Angemeldete Kurse
            </span>
            <span class="hasomed-text-small toggle-text-description">
              Nur angemeldete Kurse anzeigen
            </span>
          </div>
        </mat-slide-toggle>

        <!-- finishedCourses -->
        <mat-slide-toggle
          *ngIf="filterForm.get(filterType.FINISHED_COURSES)"
          hideIcon
          [formControlName]="filterType.FINISHED_COURSES"
          (click)="$event.stopPropagation()">
          <div class="toggle-text">
            <span class="hasomed-text-body toggle-text-title">
              Abgeschlossene Kurse
            </span>
            <span class="hasomed-text-small toggle-text-description">
              Nur abgeschlossene Kurse anzeigen
            </span>
          </div>
        </mat-slide-toggle>

        <!-- openCourses -->
        <mat-slide-toggle
          *ngIf="filterForm.get(filterType.OPEN_COURSES)"
          hideIcon
          [formControlName]="filterType.OPEN_COURSES"
          (click)="$event.stopPropagation()">
          <div class="toggle-text">
            <span class="hasomed-text-body toggle-text-title">
              Offene Kurse
            </span>
            <span class="hasomed-text-small toggle-text-description">
              Nur offene Kurse anzeigen
            </span>
          </div>
        </mat-slide-toggle>

        <!-- pendingELogs -->
        <mat-slide-toggle
          *ngIf="filterForm.get(filterType.PENDING_ELOGS)"
          hideIcon
          [formControlName]="filterType.PENDING_ELOGS"
          (click)="$event.stopPropagation()">
          <div class="toggle-text">
            <span class="hasomed-text-body toggle-text-title">
              Anwesenheit ausstehend
            </span>
            <span class="hasomed-text-small toggle-text-description">
              {{
                eLogUserFilter
                  ? 'Nur Lehrpersonal mit ausstehenden Anwesenheiten in Kursen anzeigen'
                  : 'Nur Kurse mit ausstehender Anwesenheit.'
              }}
            </span>
          </div>
        </mat-slide-toggle>

        <!-- ROOM Filter -->
        <!-- roomActive toggle-->
        <mat-slide-toggle
          *ngIf="filterForm.get(filterType.ROOM_ACTIVE)"
          hideIcon
          [formControlName]="filterType.ROOM_ACTIVE"
          (click)="$event.stopPropagation()">
          <div class="toggle-text">
            <span class="hasomed-text-body toggle-text-title">
              Aktive Räume
            </span>
            <span class="hasomed-text-small toggle-text-description">
              Nur verfügbare Räume anzeigen.
            </span>
          </div>
        </mat-slide-toggle>
        <!-- open room planning toggle -->
        <mat-slide-toggle
          *ngIf="filterForm.get(filterType.OPEN_ROOMPLANNING)"
          hideIcon
          [formControlName]="filterType.OPEN_ROOMPLANNING"
          (click)="$event.stopPropagation()">
          <div class="toggle-text">
            <span class="hasomed-text-body toggle-text-title">
              Raumplanung offen
            </span>
            <span class="hasomed-text-small toggle-text-description">
              Nur Veranstaltungen mit offener Raumplanung anzeigen
            </span>
          </div>
        </mat-slide-toggle>
      </div>
    </ng-container>

    <div class="buttons">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey m-z"
        (click)="clearFilter()">
        <span class="hasomed-button-text">Zurücksetzen</span>
      </button>

      <button
        type="button"
        class="hasomed-text-button hasomed-button-orange"
        (click)="applyFilter()">
        <i class="fa-light fa-filter hasomed-button-icon"></i>
        <span class="hasomed-button-text">Anwenden</span>
      </button>
    </div>
  </div>
</form>
