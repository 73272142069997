import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CancellationService } from 'src/app/services/cancellation.service';

@Component({
  selector: 'app-session-warning',
  templateUrl: './session-warning.component.html',
  styleUrls: ['./session-warning.component.scss'],
})
export class SessionWarningComponent implements OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  constructor(
    public dialogRef: MatDialogRef<SessionWarningComponent>,
    private authService: AuthService,
    private cancellationService: CancellationService
  ) {
    dialogRef.disableClose = true;
  }

  /**
   * renews the tgp session
   * sends a request to the isLoggedIn endpoint to refresh the session
   * @returns void
   */
  public onRenewSession(): void {
    // call tgp isLoggedIn endpoint to refresh session
    this.authService
      .isLoggedIn()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.status == 200) {
            this.dialogRef.close();
          }
        },
      });
  }

  /**
   * closes the dialog
   * @returns void
   */
  public onDismiss(): void {
    this.dialogRef.close();
  }

  /**
   * unsubscribe from all subscriptions and cancel all requests
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
