import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import { FeedbackModel } from '../models/feedback.model';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient
  ) {}

  /**
   * sends the users feedback to the endpoint for sending feedback mails
   * @param feedback the feedback to send to the backend
   * @returns Observable<HttpResponse<any>>
   */
  public sendFeedback(feedback: FeedbackModel): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(this.config.backendUrl + `/api/feedback`, feedback, {
      headers: headers,
      observe: 'response',
      responseType: 'json',
    });
  }
}
