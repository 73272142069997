<form class="hasomed-input-field" [formGroup]="openingHoursForm">
  <div class="formular-row-input-columns gap-16">
    <div class="weekday-container">
      <span>Wochentage:</span>
      <div class="weekday-checkbox-list">
        <mat-checkbox
          *ngFor="let weekday of Weekdays; let i = index"
          [disableRipple]="true"
          (change)="onWeekdayCheckboxChanged(weekday.value, $event)"
          id="{{ i }}"
          class="input-checkbox checkbox"
          type="checkbox"
          color="primary"
          formControlName="checkbox{{ i }}">
          <span
            class="flex-c hasomed-text-body font-greydark input-checkbox-label checkbox-label">
            {{ getWeekdayAbbreviation(weekday.value) }}
          </span>
        </mat-checkbox>
      </div>
    </div>
    <span *ngIf="openingHoursFormArray.controls?.length > 0">Uhrzeiten:</span>
    <ng-container formArrayName="openingHours">
      <div class="opening-hours-container">
        <ng-container
          *ngFor="
            let openingHour of openingHoursFormArray.controls;
            let i = index
          ">
          <div class="flex-c">
            <div
              [formGroupName]="i"
              class="opening-hour"
              id="openingHour{{ i }}">
              <div class="day-box">
                <i class="fa-light fa-calendar"></i>
                <span>
                  {{ getGermanWeekday(openingHour.value.day) }}
                </span>
              </div>
              <div class="time-inputs">
                <input
                  id="from"
                  type="time"
                  class="hasomed-time-input"
                  formControlName="from" />
                <input
                  id="to"
                  type="time"
                  class="hasomed-time-input"
                  formControlName="to" />
                <div
                  (click)="removeOpeningHour(i, openingHour.value.day)"
                  type="button"
                  class="hasomed-text-button hasomed-button-white-grey remove-button">
                  <i class="hasomed-button-icon-sm fa-light fa-trash-can"></i>
                </div>
              </div>
            </div>
            <app-error-message
              *ngIf="hasErrors(openingHoursFormArray.get(i.toString()))"
              [formField]="
                openingHoursFormArray.get(i.toString())
              "></app-error-message>
            <app-error-message
              *ngIf="
                hasErrors(
                  openingHoursFormArray.get(i.toString()).get('from')
                ) &&
                !openingHoursFormArray.get(i.toString())?.errors
                  ?.timeRangeInvalid
              "
              [formField]="
                openingHoursFormArray.get(i.toString()).get('from')
              "></app-error-message>
            <app-error-message
              *ngIf="
                hasErrors(openingHoursFormArray.get(i.toString()).get('to')) &&
                !openingHoursFormArray.get(i.toString())?.errors
                  ?.timeRangeInvalid
              "
              [formField]="
                openingHoursFormArray.get(i.toString()).get('to')
              "></app-error-message>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <app-error-message
    [formField]="openingHoursForm.get('openingHours')"></app-error-message>
</form>
