import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PracticalWorkTableComponent } from 'src/app/components/shared-components/practical-work-table/practical-work-table.component';
import { SelfAwarenessTableComponent } from 'src/app/components/shared-components/self-awareness-table/self-awareness-table.component';
import { TreatmentCasesTableComponent } from 'src/app/components/shared-components/treatment-cases-table/treatment-cases-table.component';
import { UserMasterDataComponent } from 'src/app/components/shared-components/user-master-data/user-master-data.component';
import { featureGuard } from 'src/app/guards/feature.guard';
import { Feature, Permission } from 'src/app/models/permission.model';
import { CourseElogComponent } from './course-elog/course-elog.component';
import { StudentDetailComponent } from './student-detail.component';
import { StudentEducationProgressComponent } from './student-education-progress/student-education-progress.component';
import { StudentOverviewOfFeesComponent } from './student-overview-of-fees/student-overview-of-fees.component';

const routes: Routes = [
  {
    path: '',
    component: StudentDetailComponent,
    children: [
      {
        path: '',
        redirectTo: 'course-elog',
        pathMatch: 'full',
      },
      {
        path: 'course-elog',
        component: CourseElogComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.STUDENT_ADMINISTRATION,
          permission: Permission.VIEW,
        },
      },
      {
        path: 'education-progress',
        component: StudentEducationProgressComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.STUDENT_ADMINISTRATION,
          permission: Permission.ADMIN,
        },
      },
      {
        path: 'practical-work',
        component: PracticalWorkTableComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.STUDENT_ADMINISTRATION,
          permission: Permission.ADMIN,
        },
      },
      {
        path: 'ambulatory-part',
        component: TreatmentCasesTableComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.STUDENT_ADMINISTRATION,
          permission: Permission.ADMIN,
        },
      },
      {
        path: 'self-awareness',
        component: SelfAwarenessTableComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.STUDENT_ADMINISTRATION,
          permission: Permission.ADMIN,
        },
      },
      {
        path: 'overview-of-fees',
        component: StudentOverviewOfFeesComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.STUDENT_ADMINISTRATION,
          permission: Permission.ADMIN,
        },
      },
      {
        path: 'master-data',
        component: UserMasterDataComponent,
        canActivate: [featureGuard],
        data: {
          feature: Feature.STUDENT_ADMINISTRATION,
          permission: Permission.VIEW,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StudentDetailRoutingModule {}
