import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { NewsArticleModel } from 'src/app/models/news-article.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { NewsArticleService } from 'src/app/services/news-article.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard-institut-news',
  templateUrl: './dsb-institute-news.component.html',
  styleUrls: ['./dsb-institute-news.component.scss'],
})
export class DSBInstituteNewsComponent implements OnInit, OnDestroy {
  private newInstituteNewsCount = 0;
  public showOnlyPublishedArticles = true;
  public isLoading = true;
  public isMobile = false;
  public isTablet = false;
  public showSearchBar = false;
  public newsArticles: NewsArticleModel[];
  public filteredNewsArticles: NewsArticleModel[];
  public selectedNewsArticle?: NewsArticleModel;
  public searchForm = new FormGroup({
    searchText: new FormControl(''),
  });

  public feature = Feature;
  public permission = Permission;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public newsArticleService: NewsArticleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public userService: UserService,
    private sanitizer: DomSanitizer,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private mobileUiService: MobileUiService
  ) {}

  public ngOnInit() {
    // show drafts if user is admin
    if (
      this.userService.currentUserIsAdministrator ||
      this.userService.currentUserIsSuperadmin
    ) {
      this.showOnlyPublishedArticles = false;
    }

    this.getNewsArticles();
    this.viewChanges();
  }

  /**
   * sets isMobile and isTablet depending on the current view
   * @returns void
   */
  private viewChanges() {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.isMobile = currentView === 'mobile';
        this.isTablet = currentView === 'tablet';

        this.showSearchBar = currentView === 'desktop';

        if (!this.isMobile && !this.selectedNewsArticle) {
          this.selectedNewsArticle = this.filteredNewsArticles
            ? this.filteredNewsArticles[0]
            : null;
        } else if (this.isMobile && this.selectedNewsArticle) {
          this.selectedNewsArticle = null;
        }
      });
  }

  /**
   * retrieves all news articles of the current institute
   * @returns void
   */
  private getNewsArticles(): void {
    this.newsArticleService
      .getAllNewsArticles()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.newsArticles = response.body
            ? await Promise.all(
                response.body?.map(
                  async (newsArticle: any): Promise<NewsArticleModel> => {
                    return await this.newsArticleService.parseBackendNewsArticle(
                      newsArticle
                    );
                  }
                )
              )
            : [];
          this.filteredNewsArticles = this.newsArticles;

          if (this.activatedRoute.snapshot.params['id']) {
            const id = Number(atob(this.activatedRoute.snapshot.params['id']));
            this.selectedNewsArticle = this.newsArticles.find(
              newsArticle => newsArticle.id === id
            );
            // if selected news article, move it to the top of the list
            if (this.selectedNewsArticle) {
              this.filteredNewsArticles = this.filteredNewsArticles.filter(
                newsArticle => newsArticle.id !== id
              );
              this.filteredNewsArticles.unshift(this.selectedNewsArticle);
            }
          } else {
            // select first news article
            this.selectedNewsArticle =
              this.filteredNewsArticles &&
              this.filteredNewsArticles.length > 0 &&
              !this.isMobile
                ? this.filteredNewsArticles[0]
                : null;
          }

          this.isLoading = false;
          this.newsArticleService.updateNewNewsArticlesCount(0);
          this.subscribeToNewsArticleChanges();
        },
        error: () => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Institutsneuigkeiten konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * subscribe to new news articles count
   * check if there are new news articles and show an alert
   * @returns void
   */
  private subscribeToNewsArticleChanges(): void {
    this.newsArticleService.newNewsArticlesCount$
      .pipe(takeUntil(this.destroy$))
      .subscribe((count: number) => {
        if (count > this.newInstituteNewsCount) {
          this.newInstituteNewsCount = count;
          this.alertService.showInfoAlert(
            'Neue Institutsneuigkeiten',
            'Es gibt neue Institutsneuigkeiten. Bitte aktualisieren Sie die Seite.',
            15000
          );
        }
      });
  }

  /**
   * apply search filter to news articles
   * @param event the search input event
   * @returns void
   */
  public applySearch(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    const trimmedFilterValue = filterValue.trim().toLowerCase();
    this.filteredNewsArticles = this.newsArticles.filter(it =>
      it.title.toLowerCase().includes(trimmedFilterValue)
    );
    if (this.filteredNewsArticles.length > 0) {
      this.selectedNewsArticle = !this.isMobile
        ? this.filteredNewsArticles[0]
        : null;
    } else {
      this.selectedNewsArticle = null;
    }
  }

  /**
   * navigates to the edit news article page
   * @param newsArticle
   * @returns void
   */
  public onEdit(newsArticle: NewsArticleModel): void {
    this.router.navigate([
      'eleguide/dashboard/institute-news/',
      'edit',
      btoa(String(newsArticle.id)),
    ]);
  }

  /**
   * navigates to the create news article page
   * @returns void
   */
  public onCreateNewNewsArticle(): void {
    this.router.navigate(['eleguide/dashboard/institute-news/create']);
  }

  /**
   * sets the selectedNewsArticle to the clicked news article
   * @param newsArticle the clicked news article
   * @returns void
   */
  public onSelectNewsArticle(newsArticle: NewsArticleModel): void {
    if (this.selectedNewsArticle === newsArticle) {
      this.selectedNewsArticle = null;
      return;
    }
    this.selectedNewsArticle = newsArticle;
  }

  /**
   * returns the sanitized content of the selected news article
   * @returns SafeHtml
   */
  public getSanitizedArticleContent(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.selectedNewsArticle?.content
    );
  }

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
