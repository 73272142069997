<div class="component-content">
  <div class="header">
    <h1 class="hasomed-text-h1">
      Bankverbindung {{ editMode ? 'ändern' : 'anlegen' }}
    </h1>
    <button
      type="button"
      class="hasomed-button-icon"
      matTooltip="Abbrechen"
      (click)="onCancel()">
      <i class="fa-light fa-x"></i>
    </button>
  </div>

  <form [formGroup]="editBankDetailsForm">
    <div class="flex-c gap-24 scrollable-content">
      <div class="formular-row-columns gap-8">
        <label for="accountHolder" class="formular-row-label">
          <span class="label-title"
            >Konto Inhaber
            <span *ngIf="isRequired(editBankDetailsForm.get('accountHolder'))">
              *
            </span>
          </span>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="accountHolder"
              formControlName="accountHolder"
              type="text"
              class="hasomed-text-input flex-1"
              placeholder="Konto Inhaber" />
          </div>
          <!-- Error Messages -->
          <app-error-message
            [formField]="
              editBankDetailsForm.get('accountHolder')
            "></app-error-message>
        </div>
      </div>
      <div class="formular-row-columns gap-8">
        <label for="iban" class="formular-row-label">
          <span class="label-title">
            IBAN
            <span *ngIf="isRequired(editBankDetailsForm.get('iban'))"> * </span>
          </span>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="iban"
              formControlName="iban"
              type="text"
              class="hasomed-text-input flex-1"
              placeholder="DE00 0000 0000 0000 0000 00" />
          </div>
          <!-- Error Messages -->
          <app-error-message
            [formField]="editBankDetailsForm.get('iban')"></app-error-message>
        </div>
      </div>

      <div class="formular-row-columns gap-8">
        <label for="bic" class="formular-row-label">
          <span class="label-title">
            BIC
            <span *ngIf="isRequired(editBankDetailsForm.get('bic'))"> * </span>
          </span>
        </label>
        <div class="hasomed-input-field">
          <div class="formular-row-input">
            <input
              id="bic"
              formControlName="bic"
              type="text"
              class="hasomed-text-input flex-1"
              placeholder="BIC" />
          </div>
          <!-- Error Messages -->
          <app-error-message
            [formField]="editBankDetailsForm.get('bic')"></app-error-message>
        </div>
      </div>
    </div>
    <div class="flex-r formular-buttons">
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey"
        (click)="onCancel()">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>
      <button
        type="submit"
        class="hasomed-text-button hasomed-button-primary"
        [disabled]="!editBankDetailsForm.valid"
        (click)="onSubmit()">
        <i class="hasomed-button-icon fa-light fa-floppy-disk"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </form>
</div>
