import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormControl,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import {
  SelectionChangeEvent,
  SelectOption,
} from 'src/app/models/select-option.model';
import { AlertService } from 'src/app/services/alert.service';
import { hasErrors } from 'src/app/utils/form.utils';

@Component({
  selector: 'eleguide-multiple-select',
  templateUrl: './eleguide-multiple-select.component.html',
  styleUrl: './eleguide-multiple-select.component.scss',
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class EleguideMultipleSelectComponent implements OnInit {
  @Input() array: FormArray = new FormArray([]);
  @Input() arrayName: string = '';
  @Input() options: SelectOption[] = [];
  @Input() placeholder: string = '';
  @Input() showDivider: boolean = true;
  @Input() isRequired: boolean = false;

  hasErrors = hasErrors;

  constructor(private alertService: AlertService) {}

  ngOnInit(): void {}

  /**
   * Retrieves the form control at the specified index from the form array.
   * @param index - The index of the form control to retrieve.
   * @returns The form control at the specified index.
   */
  public getFormArrayControlAtIndex(index: number): FormControl {
    return this.array.at(index) as FormControl;
  }

  /**
   * Adds a new form control to the array.
   * The form control is initialized with the required validator if the component is required.
   * @returns void
   */
  public addFormArrayControl(): void {
    const formControl = new FormControl();

    if (this.isRequired) {
      formControl.setValidators(Validators.required);
      formControl.updateValueAndValidity();
    }

    this.array.push(formControl);
  }

  /**
   * Updates the form array control based on the selection change event.
   * @param selectionChangeEvent - The selection change event containing the new value and index.
   * @returns void
   */
  public updateFormArrayControl(
    selectionChangeEvent: SelectionChangeEvent
  ): void {
    if (
      selectionChangeEvent.newValue === null ||
      selectionChangeEvent.index === null
    ) {
      return;
    }

    const control = this.array.at(selectionChangeEvent.index);

    if (!control) {
      return;
    }

    const isDuplicateValue = this.array.controls.some(
      (control, i) =>
        control.value === selectionChangeEvent.newValue &&
        i !== selectionChangeEvent.index
    );

    if (isDuplicateValue) {
      control.setValue(null);

      this.alertService.showErrorAlert(
        'Das hat leider nicht geklappt!',
        'Die ausgewählte Option ist bereits vorhanden.'
      );
      return;
    }

    control.setValue(selectionChangeEvent.newValue);
  }

  /**
   * Removes a control from the form array at the specified index.
   * @param index - The index of the control to remove
   * @returns void
   */
  public removeFormArrayControl(index: number): void {
    this.array.removeAt(index);
  }
}
