<div class="content">
  <div class="dialog">
    <div class="header">
      <img
        width="150"
        alt="EleGuideLogo"
        src="../../../../assets/img/EleGuide_Logo-Fullsize.svg" />
    </div>
    <div class="body">
      <h1 class="hasomed-text-h1">Sie wurden erfolgreich ausgeloggt.</h1>
      <p>Sie können dieses Fenster nun schließen</p>
      <button
        class="hasomed-text-button hasomed-button-primary"
        (click)="onLogin()">
        <span class="hasomed-button-text"> Erneut anmelden </span>
      </button>
    </div>
  </div>
</div>
