import { Pipe, PipeTransform } from '@angular/core';
import { Filter, FilterType } from '../models/filter.model';
import { RoomModel } from '../models/room.model';

@Pipe({
  name: 'filterRoomOverview',
  standalone: true,
})
export class FilterRoomOverviewPipe implements PipeTransform {
  transform(items: RoomModel[], filter: Filter[]): any[] {
    if (!items || !filter) {
      return items;
    }

    return items.filter(item => {
      return filter.every(filter => {
        if (filter.value === null) {
          return true;
        }

        switch (filter.type) {
          case FilterType.ROOM_FLOOR:
            return item.floor === filter.value;
          case FilterType.ROOM_CAPACITY:
            return item.seatNumber >= Number(filter.value);
          case FilterType.ROOM_ACTIVE:
            return Boolean(item.isAvailable) === Boolean(filter.value);
          default:
            return true;
        }
      });
    });
  }
}
