<form
  [formGroup]="instituteForm"
  (ngSubmit)="onSubmit()"
  class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        {{ editMode ? 'Institut bearbeiten' : 'Institut erstellen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{
          editMode
            ? 'Bearbeiten Sie das Institut.'
            : 'Erstellen Sie ein neues Institut.'
        }}
      </h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>

      <!-- Submit button -->
      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content-form">
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Institutsname
          <span *ngIf="isRequired(instituteForm.get('name'))">*</span>
        </span>
        <span class="label-description">Name des Instituts.</span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="name"
            class="hasomed-text-input"
            type="text"
            autocomplete="off"
            formControlName="name"
            placeholder="Name" />
        </div>
        <app-error-message
          [formField]="instituteForm.get('name')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Description -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">
          Beschreibung des Instituts oder Slogan
          <span *ngIf="isRequired(instituteForm.get('description'))">*</span>
        </span>
        <span class="label-description"> Ergänzende Informationen. </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="description"
            class="hasomed-text-input"
            type="text"
            autocomplete="off"
            formControlName="description"
            placeholder="Beschreibung" />
        </div>
        <app-error-message
          [formField]="instituteForm.get('description')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Email -->
    <div class="formular-row">
      <label class="formular-row-label" for="email">
        <span class="label-title">
          E-Mail Adresse
          <span *ngIf="isRequired(instituteForm.get('email'))">*</span>
        </span>
        <span class="label-description"> E-Mail als Kontaktmöglichkeit. </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-icon-input">
          <i class="fa-light fa-envelope input-icon"></i>
          <input
            id="email"
            class="hasomed-text-input"
            type="text"
            size="20"
            autocomplete="off"
            formControlName="email"
            placeholder="email@example.com" />
        </div>
        <app-error-message
          [formField]="instituteForm.get('email')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- phone -->
    <div class="formular-row">
      <label class="formular-row-label" for="phone">
        <span class="label-title">
          Telefonnummer (Festnetz)
          <span *ngIf="isRequired(instituteForm.get('phone'))">*</span>
        </span>
        <span class="label-description">
          Festnetznummer als Kontaktmöglichkeit.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-icon-input">
          <i class="fa-light fa-phone input-icon"></i>
          <input
            id="phone"
            class="hasomed-text-input"
            type="text"
            formControlName="phone"
            placeholder="01234/56789" />
        </div>
        <app-error-message
          [formField]="instituteForm.get('phone')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- website -->
    <div class="formular-row">
      <label class="formular-row-label" for="mobile">
        <span class="label-title">
          Webseite
          <span *ngIf="isRequired(instituteForm.get('website'))">*</span>
        </span>
        <span class="label-description"> Webseite des Instituts. </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-icon-input">
          <i class="fa-light fa-globe input-icon"></i>
          <input
            id="website"
            class="hasomed-text-input"
            type="text"
            formControlName="website"
            placeholder="https://eleguide.de" />
        </div>
        <app-error-message
          [formField]="instituteForm.get('website')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Address -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Adresse</span>
        <span class="label-description"> Fügen Sie die Anschrift hinzu. </span>
      </div>
      <div class="hasomed-input-field">
        <div class="formular-row-input-columns">
          <div class="formular-row-input">
            <input
              id="street"
              class="hasomed-text-input"
              type="text"
              size="15"
              formControlName="street"
              placeholder="Straße" />
            <input
              id="houseNumber"
              class="hasomed-text-input"
              type="text"
              maxlength="5"
              size="5"
              formControlName="houseNumber"
              placeholder="Hausnummer" />
          </div>
          <div class="formular-row-input">
            <input
              id="addressAddition"
              class="hasomed-text-input"
              type="text"
              size="10"
              formControlName="addressAddition"
              placeholder="Adresszusatz (Etage, Appartment)" />
          </div>
          <div class="formular-row-input">
            <input
              id="zipCode"
              class="hasomed-text-input"
              type="text"
              size="5"
              formControlName="zipCode"
              placeholder="PLZ" />
            <input
              id="city"
              class="hasomed-text-input"
              type="text"
              size="15"
              formControlName="city"
              placeholder="Ort" />
          </div>
          <div class="formular-row-input">
            <country-select
              class="flex-1"
              [control]="countryControl"></country-select>
          </div>
        </div>
        <app-error-message
          [formField]="instituteForm.get('street')"></app-error-message>
        <app-error-message
          [formField]="instituteForm.get('houseNumber')"></app-error-message>
        <app-error-message
          [formField]="
            instituteForm.get('addressAddition')
          "></app-error-message>
        <app-error-message
          [formField]="instituteForm.get('zipCode')"></app-error-message>
        <app-error-message
          [formField]="instituteForm.get('city')"></app-error-message>
        <app-error-message
          [formField]="instituteForm.get('country')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- openingHours -->
    <div class="formular-row">
      <label class="formular-row-label" for="openingHours">
        <span class="label-title">
          Öffnungszeiten des Instituts
          <span *ngIf="isRequired(instituteForm.get('openingHours'))">*</span>
        </span>
        <span class="label-description">
          In diesen Zeiten ist das Institut geöffnet.
        </span>
      </label>
      <div class="hasomed-input-field">
        <app-opening-hours
          [openingHours]="openingHours"
          [parentFormGroup]="instituteForm"
          [editMode]="editMode"
          (openingHoursChange)="onOpeningHoursChange($event)"
          (openingHoursInitialized)="
            onOpeningHoursInitialized()
          "></app-opening-hours>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- profilePicture -->
    <div class="formular-row">
      <label class="formular-row-label" for="profilePictureUpload">
        <span class="label-title">
          Logo des Instituts
          <span *ngIf="isRequired(instituteForm.get('profilePicture'))">*</span>
        </span>
        <span class="label-description">
          Bitte laden Sie ein Logo für das Institut hoch.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input-image-upload">
          <div
            *ngIf="!instituteForm.get('logo').value"
            class="uploaded-image-container">
            <i class="fa-light fa-school logo-placeholder"></i>
          </div>
          <div
            *ngIf="instituteForm.get('logo').value"
            class="uploaded-image-container">
            <img
              #userIconImage
              [attr.src]="instituteForm.get('logo').value"
              id="user-icon-image"
              class="uploaded-image"
              alt="Das Institutslogo"
              matTooltip="Das Institutslogo" />
          </div>
          <app-upload-area-dnd
            context="instituteLogoUpload"
            [showFileList]="false"
            [allowedFileTypes]="allowedFileTypes"
            (change)="imageChangeEvent($event)"
            class="image-upload-area"
            inputId="instituteLogoUpload">
          </app-upload-area-dnd>
        </div>
      </div>
    </div>
  </div>
</form>
