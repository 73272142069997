<div class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Persönliche Daten</h1>
      <h3 class="hasomed-text-h3">Füllen Sie Ihre Daten aus.</h3>
    </div>
    <div class="header-buttons">
      <!-- Submit button -->
      <button
        [disabled]="isLoading"
        (click)="onEdit()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-pen hasomed-button-icon"></i>
        <span class="hasomed-button-text">Bearbeiten</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>
  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content">
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Anrede und Titel</span>
        <span class="label-description"> Ihre Anrede und Titel. </span>
      </div>
      <div
        *ngIf="!user.name.genderTitle && !user.name.academicTitle; else titles"
        class="detail-value">
        Noch nicht hinterlegt.
      </div>
      <ng-template #titles>
        <div class="detail-value">
          {{ user.name.genderTitle }} {{ user.name.academicTitle }}
        </div>
      </ng-template>
    </div>
    <mat-divider></mat-divider>
    <!-- Name -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Name</span>
        <span class="label-description"> Ihr Vorname und Nachname. </span>
      </div>
      <div class="detail-value">
        {{ user.name.firstName }} {{ user.name.lastName }}
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Email -->
    <div class="formular-row">
      <label class="formular-row-label" for="email">
        <span class="label-title">E-Mail Adresse</span>
        <span class="label-description"> Ihre E-Mail Adresse. </span>
      </label>
      <div class="detail-value">
        <i class="fa-light fa-envelope"></i>
        <span>{{ user.email }}</span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Birthdate -->
    <div class="formular-row">
      <div class="formular-row-label">
        <div class="label-title">Geburtsdatum</div>
        <div class="label-description">Ihr Geburtsdatum.</div>
      </div>
      <div class="detail-value">
        <span>
          {{
            (user?.birthdate | date: 'dd.MM.yyyy') ?? 'Noch nicht hinterlegt.'
          }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Student number -->
    <div class="formular-row">
      <div class="formular-row-label">
        <ng-container [ngSwitch]="user?.roleId">
          <span
            *ngSwitchCase="user?.roleId === Role.LECTURER"
            class="label-title">
            Ihre "Lehrpersonalnummer"
          </span>
          <span
            *ngSwitchCase="user?.roleId === Role.STUDENT"
            class="label-title">
            Ihre "Matrikelnummer"
          </span>
          <span *ngSwitchDefault class="label-title">
            Ihre Personalnummer
          </span>
        </ng-container>
        <span class="label-description"> Wird automatisch vergeben. </span>
      </div>
      <div class="detail-value">
        <i class="fa-light fa-lock"></i>
        <span>
          {{
            user?.userIdentifier != '' && user?.userIdentifier
              ? user?.userIdentifier
              : (user?.roleId === Role.STUDENT
                  ? '"Matrikelnummer"'
                  : user?.roleId === Role.LECTURER
                    ? '"Lehrpersonalnummer"'
                    : 'Personalnummer') + ' noch nicht definiert'
          }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- entry date -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Eintrittsdatum</span>
        <span class="label-description"> Ihr Eintrittsdatum am Institut. </span>
      </div>
      <div class="detail-value">
        <i class="fa-light fa-lock"></i>
        <span>
          {{
            (user?.entryDate | date: 'dd.MM.yyyy') ??
              'Ihr Eintrittsdatum wurde noch nicht hinterlegt'
          }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Avatar -->
    <div class="formular-row">
      <span class="formular-row-label">
        <span class="label-title">Profilfoto</span>
        <span class="label-description"> Wird in Ihrem Profil angezeigt. </span>
      </span>
      <div class="detail-value">
        <img
          (click)="openProfilePictureDialog()"
          #userIconImage
          [attr.src]="
            user.profilePicture ?? '../../../../../assets/img/user.jpg'
          "
          id="user-icon-image"
          class="uploaded-image"
          alt="Ihr Profilbild"
          matTooltip="Ihr Profilbild" />
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Education Type -->
    <div *ngIf="user?.roleId === Role.STUDENT" class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Aus- oder Weiterbildungsgang </span>
        <span class="label-description">
          Ihr Aus- oder Weiterbildungsgang.
        </span>
      </div>
      <div class="detail-value">
        <i class="fa-light fa-lock"></i>
        <span>
          {{
            user?.educationCourse?.title
              ? user?.educationCourse?.title
              : 'Noch nicht hinterlegt.'
          }}
        </span>
      </div>
    </div>
    <mat-divider *ngIf="user?.roleId === Role.STUDENT"></mat-divider>

    <!-- Measles Protection -->
    <!-- <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Masernstatus</span>
        <span class="label-description"> Impfschutz für Masern </span>
      </div>
      <div class="user-detail-value">
        <i class="fa-light fa-lock"></i>
        <span>
          {{ user?.measlesProtection ? 'Geprüft' : 'Nicht geprüft' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider> -->

    <!-- Address -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Adresse</span>
        <span class="label-description">
          Ihre Anschrift für Schriftverkehr.
        </span>
      </div>
      <span
        *ngIf="
          !user?.address?.street &&
            !user?.address?.houseNumber &&
            !user?.address?.addressAddition &&
            !user?.address?.zipCode &&
            !user?.address?.city &&
            !user?.address?.country;
          else address
        "
        class="detail-value">
        Nicht hinterlegt.
      </span>
      <ng-template #address>
        <div class="detail-value-address">
          <span *ngIf="user?.address?.street || user?.address?.houseNumber">
            {{ user?.address?.street }}
            {{ user?.address?.houseNumber }}
          </span>
          <span *ngIf="user?.address?.addressAddition">
            {{ user?.address?.addressAddition }}
          </span>
          <span *ngIf="user?.address?.zipCode || user?.address?.city">
            {{ user?.address?.zipCode }}
            {{ user?.address?.city }}
          </span>
          <span *ngIf="user?.address?.country">
            {{ user?.address?.country }}
          </span>
        </div>
      </ng-template>
    </div>
    <mat-divider></mat-divider>

    <!-- Document -->
    <div class="formular-row">
      <label class="formular-row-label" for="profileDocuments">
        <span class="label-title">Dokumentenupload</span>
        <span class="label-description">
          Ihre Dokumente für Ihre Aus- und Weiterbildung.
        </span>
      </label>
      <span
        *ngIf="!user?.files || user?.files?.length == 0; else files"
        class="detail-value">
        Keine Dokumente hochgeladen.
      </span>
      <ng-template #files>
        <div class="detail-value-files">
          <app-file-view
            *ngFor="let file of user?.files"
            [file]="file"
            (downloadFile)="onDownloadFile($event)"
            (openFile)="onOpenFile($event)"></app-file-view>
        </div>
      </ng-template>
    </div>
  </div>
</div>
