import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FileModel } from 'src/app/models/file.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { SupervisionEventDateModel } from 'src/app/models/supervision.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { SupervisionService } from 'src/app/services/supervision.service';
import { UserService } from 'src/app/services/user.service';
import { dateIsInFuture } from 'src/app/utils/date.utils';
import { getFullName } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-supervision-event-date-details',
  templateUrl: './supervision-event-date-details.component.html',
  styleUrl: './supervision-event-date-details.component.scss',
})
export class SupervisionEventDateDetailsComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public supervisionEventDate: SupervisionEventDateModel;
  public isStudent = this.userService.currentUserIsStudent();
  public isLecturer = this.userService.currentUserIsLecturer();

  private destroy$: Subject<void> = new Subject<void>();

  public feature = Feature;
  public permission = Permission;

  // import from utils
  public getFullName = getFullName;
  public dateIsInFuture = dateIsInFuture;

  constructor(
    private supervisionService: SupervisionService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private cancellationService: CancellationService,
    private location: Location,
    private router: Router,
    public clipboardService: ClipboardService,
    private userService: UserService
  ) {}

  public ngOnInit(): void {
    const params = this.activatedRoute.snapshot.params;
    const supervisionId = +atob(decodeURIComponent(params.supervisionId));
    const supervisionEventDateId = +atob(decodeURIComponent(params.id));
    this.getData(supervisionId, supervisionEventDateId);
  }

  /**
   * get the supervision event date
   * @param supervisionId the supervision id
   * @param supervisionEventDateId the supervision event date id
   * @returns void
   */
  private async getData(
    supervisionId: number,
    supervisionEventDateId: number
  ): Promise<void> {
    this.supervisionService
      .getSupervisionEventDate(supervisionId, supervisionEventDateId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.supervisionEventDate =
            await this.supervisionService.parseBackendSupervisionEventDate(
              response.body
            );
          this.isLoading = false;
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Supervisionstermin konnte nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
          this.onGoBack();
        },
      });
  }

  /**
   * navigate back to the previous page
   * @returns void
   */
  public onGoBack(): void {
    this.location.back();
  }

  /**
   * navigate to the edit page
   * @returns void
   */
  public onEdit() {
    this.router.navigate(
      ['../edit', btoa(String(this.supervisionEventDate.id))],
      {
        relativeTo: this.activatedRoute,
      }
    );
  }

  /**
   * open the file
   * @param file
   * @returns void
   */
  public onOpenFile(file: FileModel): void {
    this.supervisionService.openFile(
      this.supervisionEventDate.supervisionId,
      this.supervisionEventDate?.id,
      file.id
    );
  }

  /**
   * download a file
   * @param file
   * @returns void
   */
  public onDownloadFile(file: FileModel): void {
    this.supervisionService.downloadFile(
      this.supervisionEventDate.supervisionId,
      this.supervisionEventDate?.id,
      file.id
    );
  }

  /**
   * unsubscribe from all subscriptions and cancel all requests
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
