<form [formGroup]="contactForm" class="flex-c gap-40 dialog-content">
  <div class="flex-c gap-24">
    <div class="flex-r gap-24 justify-space-between">
      <h1 class="hasomed-text-h1">Hilfe und Kontakt</h1>
      <button (click)="onCancel()" type="button" class="close-button">
        <i class="fa-light fa-x close-button-icon"></i>
      </button>
    </div>
    <div class="formular-row">
      <label class="formular-row-label" for="reason">
        <span class="label-title">Grund Ihrer Kontaktaufnahme</span>
        <span class="label-description">Wählen Sie einen Grund aus.</span>
      </label>
      <div class="hasomed-input-field">
        <mat-form-field class="hasomed-select">
          <mat-select
            formControlName="reason"
            id="reason"
            placeholder="Grund der Kontaktaufnahme">
            <ng-container
              *ngFor="let reason of selectableReasons; let last = last">
              <mat-option [value]="reason" class="hasomed-text-body">
                {{ reason }}
              </mat-option>
              <mat-divider *ngIf="!last" class="option-divider"></mat-divider>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <app-error-message
          [formField]="contactForm.get('reason')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <label class="formular-row-label" for="message">
        <span class="label-title">Ihre Nachricht an uns.</span>
        <span class="label-description">
          Bitte schildern Sie uns Ihr Anliegen.
        </span>
        <span class="label-description mt-16">
          Falls es sich hierbei um die Meldung eines Problems handelt, versuchen
          Sie die Entstehung des Problems detailliert zu beschreiben.
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="message"
            class="hasomed-textarea-input"
            rows="10"
            type="text"
            formControlName="message"
            placeholder="Ihre Nachricht."></textarea>
        </div>
        <app-error-message
          [formField]="contactForm.get('message')"></app-error-message>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Weitere Kontaktmöglichkeiten</span>
        <span class="label-description"
          >Alternativ können Sie uns auch telefonisch oder via Mail
          erreichen.</span
        >
      </div>
      <div class="flex-c gap-16">
        <div class="flex-r gap-16 align-center">
          <i class="fa-light fa-envelope"></i>
          <span>E-Mail: {{ 'support@healthycloud.de' }}</span>
          <app-copy-to-clipboard
            textToCopy="support@healthycloud.de"
            type="E-Mail Adresse">
          </app-copy-to-clipboard>
        </div>
        <div class="flex-r gap-16 align-center">
          <i class="fa-light fa-phone"></i>
          <span>Telefon: {{ '03916107647' }}</span>
          <app-copy-to-clipboard textToCopy="03916107647" type="Telefon">
          </app-copy-to-clipboard>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-r gap-24 justify-space-between">
    <button
      (click)="onCancel()"
      type="button"
      class="hasomed-text-button hasomed-button-white-grey">
      <span class="hasomed-button-text">Schließen</span>
    </button>
    <button
      (click)="onConfirm()"
      type="submit"
      class="hasomed-text-button hasomed-button-primary">
      <i class="hasomed-button-icon fa-light fa-paper-plane"></i>
      <span class="hasomed-button-text">Abschicken</span>
    </button>
  </div>
</form>
