import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { BankAccountModel } from 'src/app/models/bank-account.model';
import { CourseModel } from 'src/app/models/course.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { getCourseDateRange, getCourseRoom } from 'src/app/utils/course.utils';

@Component({
  selector: 'app-lecturer-courses',
  templateUrl: './lecturer-courses.component.html',
  styleUrl: './lecturer-courses.component.scss',
})
export class LecturerCoursesComponent implements OnInit, OnDestroy {
  public userId: number;
  public isMobile = false;

  public displayedColumns: string[] = [
    'title',
    'dates',
    'room',
    'participantAmount',
    'eLogStatus',
    'actions',
  ];
  public bankDetails: BankAccountModel;
  public dataSource: MatTableDataSource<CourseModel> =
    new MatTableDataSource<CourseModel>();
  public isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /* for permission */
  public feature = Feature;
  public permission = Permission;

  // import from utils
  public getCourseRoom = getCourseRoom;
  public getCourseDateRange = getCourseDateRange;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cancellationService: CancellationService,
    private courseService: CourseService,
    private navigationService: NavigationService,
    private mobileUiService: MobileUiService,
    private alertService: AlertService
  ) {}

  public ngOnInit() {
    this.activatedRoute.parent?.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.userId = +atob(params.get('userId'));
        this.getLecturersCourses(this.userId);
      });
    this.viewChanges();
  }

  /**
   * subscribes to the current view changes
   * sets the columns to display based on the current view
   * sets the isMobile variable based on the current view
   * @returns void
   */
  private viewChanges(): void {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.initTableColumns(currentView);
        this.isMobile = currentView === 'mobile';
      });
  }

  /**
   * initializes the columns to display based on the current view and the user role
   * @param currentView The current view
   * @returns void
   */
  private initTableColumns(currentView: string): void {
    if (currentView === 'mobile') {
      this.displayedColumns = ['title', 'eLogStatus', 'actions'];
    } else if (currentView === 'tablet') {
      this.displayedColumns = ['title', 'eLogStatus', 'actions'];
    } else {
      this.displayedColumns = [
        'title',
        'dates',
        'room',
        'participantAmount',
        'eLogStatus',
        'actions',
      ];
    }
  }

  /**
   * getLecturersCourses
   * @description get the courses of the student
   * @param userId
   * @returns void
   */
  private getLecturersCourses(userId: number): void {
    this.courseService
      .getLecturersCourses(userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.dataSource.data = response.body;
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'courseTitle':
                return item.title;
              case 'eLogStatus':
                // sort failed on top, followed by checked and then followed by pending
                if (item.elogStatusCounts.pending > 0) {
                  return item.elogStatusCounts.pending * 1000;
                } else if (item.elogStatusCounts.upcoming > 0) {
                  return item.elogStatusCounts.upcoming * 100;
                } else if (item.elogStatusCounts.checked > 0) {
                  return item.elogStatusCounts.checked * 10;
                } else {
                  return item.elogStatusCounts.pending * 10;
                }
              default:
                return item[property];
            }
          };
          this.isLoading = false;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        },
        error: () => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Kurse konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * openCourseDetails
   * @description open the the details of the course
   * @param courseId
   * @returns void
   */
  public openCourseDetails(courseId: number): void {
    this.navigationService.setOverviewUrl(this.router.url);
    this.router.navigate([
      `/eleguide/education/course-administration/${btoa(String(courseId))}/events`,
    ]);
  }

  /**
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
