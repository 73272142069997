import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CancellationService {
  private cancelRequests = new Subject<void>();
  public cancelRequests$ = this.cancelRequests.asObservable();

  constructor() {}

  public cancelAllRequests(): void {
    this.cancelRequests.next();
  }
}
