import { Pipe, PipeTransform } from '@angular/core';
import { Filter, FilterType, FilterUser } from '../models/filter.model';
import { SupervisionEventDateModel } from '../models/supervision.model';
import { dateIsInFuture } from '../utils/date.utils';

@Pipe({
  name: 'filterSupervision',
})
export class FilterSupervisionPipe implements PipeTransform {
  transform(
    supervisions: SupervisionEventDateModel[],
    filter: Filter[]
  ): SupervisionEventDateModel[] {
    if (!supervisions || !filter) {
      return supervisions;
    }

    return supervisions.filter(supervision => {
      return filter.every(filter => {
        if (filter.value === null) {
          return true;
        }

        switch (filter.type) {
          case FilterType.SUPERVISION_STATUS:
            switch (filter.value) {
              case 'checked':
                return supervision.isChecked;
              case 'unchecked':
                return (
                  !supervision.isChecked &&
                  !dateIsInFuture(supervision.endDate) &&
                  !supervision.isCanceled
                );
              case 'canceled':
                return supervision.isCanceled;
              case 'planned':
                return (
                  !supervision.isChecked && dateIsInFuture(supervision.endDate)
                );
              default:
                return false;
            }
          case FilterType.SUPERVISION_STUDENT:
            return supervision.student?.id === (filter.value as FilterUser).id;
          case FilterType.SUPERVISION_SUPERVISOR:
            return (
              supervision.supervisor?.id === (filter.value as FilterUser).id
            );
          case FilterType.SUPERVISION_TYPE:
            return supervision.type === filter.value;
          default:
            return true;
        }
      });
    });
  }
}
