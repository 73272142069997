import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { Filter, FilterType } from 'src/app/models/filter.model';
import { SupervisionEventDateModel } from 'src/app/models/supervision.model';
import { FilterSupervisionPipe } from 'src/app/pipes/filter-supervision.pipe';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SupervisionService } from 'src/app/services/supervision.service';
import { UserService } from 'src/app/services/user.service';
import { dateIsInFuture } from 'src/app/utils/date.utils';
import { hasActiveFilterValue } from 'src/app/utils/filter.utils';
import { dateRangeIsNotEmpty } from 'src/app/utils/form.utils';
import { getFullName, getFullNames } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-supervision',
  templateUrl: './supervision.component.html',
  styleUrl: './supervision.component.scss',
})
export class SupervisionComponent implements OnInit, OnDestroy {
  public supervisionEventDates: SupervisionEventDateModel[] = [];
  public searchForm: FormGroup = new FormGroup({
    searchText: new FormControl(''),
  });
  public filterOpened: boolean = false;
  public displayedColumns = [
    'date',
    'room',
    'supervisor',
    'student',
    'type',
    'status',
    'actions',
  ];
  public dataSource: MatTableDataSource<SupervisionEventDateModel> =
    new MatTableDataSource<SupervisionEventDateModel>();
  public isLoading = true;
  public isLecturer: boolean = false;
  public isAdministrator: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatMenuTrigger) filterMenuTrigger: MatMenuTrigger;

  public supervisionFilter: Filter[] = [
    {
      type: FilterType.SUPERVISION_STATUS,
      value: null,
    },
  ];
  public hasActiveFilterValue = hasActiveFilterValue;

  // import from utils
  public getFullNames = getFullNames;
  public getFullName = getFullName;
  public dateRangeIsNotEmpty = dateRangeIsNotEmpty;
  public dateIsInFuture = dateIsInFuture;

  public isMobile = false;
  public isTablet = false;
  public showSearchBar = false;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private supervisionService: SupervisionService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private mobileUiService: MobileUiService,
    private loadingService: LoadingService
  ) {}

  public ngOnInit(): void {
    this.isLecturer = this.userService.currentUserIsLecturer();
    this.isAdministrator = this.userService.currentUserIsAdministrator();

    this.initTable();
    this.viewChanges();
    this.initFilters();
  }

  /**
   * initializes the supervision filters based on the user role
   * @returns void
   */
  private initFilters(): void {
    if (this.isAdministrator) {
      this.supervisionFilter.push({
        type: FilterType.SUPERVISION_STUDENT,
        value: null,
      });
      this.supervisionFilter.push({
        type: FilterType.SUPERVISION_SUPERVISOR,
        value: null,
      });
    } else if (this.isLecturer) {
      this.supervisionFilter.push({
        type: FilterType.SUPERVISION_STUDENT,
        value: null,
      });
    }
  }

  /**
   * sets isMobile and isTablet depending on the current view
   * sets showSearchBar to true if the current view is desktop
   * sets the displayed columns depending on the current view
   * @returns void
   */
  private viewChanges() {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.initTableColumns(currentView);

        this.isMobile = currentView === 'mobile';
        this.isTablet = currentView === 'tablet';

        this.showSearchBar = currentView === 'desktop';
      });
  }

  /**
   * set the columns for the table depending on the current view and the user role
   * @param currentView the current view
   * @returns void
   */
  private initTableColumns(currentView: string): void {
    if (this.isLecturer) {
      this.initLecturerColumns(currentView);
    } else if (this.isAdministrator) {
      this.initAdministratorColumns(currentView);
    } else {
      this.initStudentColumns(currentView);
    }
  }

  /**
   * set the students table columns depending on the current view
   * @param currentView the current view
   * @returns void
   */
  private initStudentColumns(currentView: string): void {
    if (currentView === 'mobile') {
      this.displayedColumns = ['date', 'status', 'actions'];
    } else if (currentView === 'tablet') {
      this.displayedColumns = ['date', 'supervisor', 'status', 'actions'];
    } else {
      this.displayedColumns = [
        'date',
        'room',
        'treatmentCases',
        'supervisor',
        'type',
        'status',
        'actions',
      ];
    }
  }

  /**
   * set the lecturers table columns depending on the current view
   * @param currentView the current view
   * @returns void
   */
  private initLecturerColumns(currentView: string): void {
    if (currentView === 'mobile') {
      this.displayedColumns = ['date', 'student', 'actions'];
    } else if (currentView === 'tablet') {
      this.displayedColumns = ['date', 'student', 'status', 'actions'];
    } else {
      this.displayedColumns = [
        'date',
        'room',
        'student',
        'type',
        'status',
        'actions',
      ];
    }
  }

  /**
   * set the administrators table columns depending on the current view
   * @param currentView the current view
   * @returns void
   */
  private initAdministratorColumns(currentView: string): void {
    if (currentView === 'mobile') {
      this.displayedColumns = ['date', 'student', 'actions'];
    } else if (currentView === 'tablet') {
      this.displayedColumns = ['date', 'student', 'status', 'actions'];
    } else {
      this.displayedColumns = [
        'date',
        'room',
        'supervisor',
        'student',
        'type',
        'status',
        'actions',
      ];
    }
  }

  /**
   * initializes the sorting, pagination and filtering of the table
   * inits the table data with the supervision event dates
   * @returns void
   */
  private initTable(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'date':
          return item.startDate;
        case 'room':
          return item.room?.name;
        case 'supervisor':
          return getFullName(item.supervisor);
        default:
          return item[property];
      }
    };

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.initTableColumns(this.mobileUiService.currentView$.value);

    this.getAllSupervisionEventDates();
  }

  /**
   * retrieve all supervision event dates from the backend
   * @returns void
   */
  private getAllSupervisionEventDates(): void {
    this.supervisionService
      .getSupervisionEventDates()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.supervisionEventDates = response.body;
          this.dataSource.data = response.body
            ? await Promise.all(
                response.body.map(
                  async (
                    supervisionData: any
                  ): Promise<SupervisionEventDateModel> => {
                    return await this.supervisionService.parseBackendSupervisionEventDate(
                      supervisionData
                    );
                  }
                )
              )
            : [];
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Supervisionstermine konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * view supervision event date
   * @param supervisionEventDate
   * @returns void
   */
  public viewSupervisionEventDate(
    supervisionEventDate: SupervisionEventDateModel
  ): void {
    this.router.navigate(
      [
        btoa(String(supervisionEventDate.supervisionId)),
        btoa(String(supervisionEventDate.id)),
      ],
      {
        relativeTo: this.activatedRoute,
      }
    );
  }

  /**
   * navigate to create supervision page
   * @returns void
   */
  public createSupervision(): void {
    this.router.navigate(['./create'], { relativeTo: this.activatedRoute });
  }

  /**
   * navigate to edit supervision event date page
   * @param supervisionEventDate SupervisionEventDateModel
   * @returns void
   */
  public editSupervision(
    supervisionEventDate: SupervisionEventDateModel
  ): void {
    this.router.navigate(
      [
        './',
        btoa(String(supervisionEventDate.supervisionId)),
        'edit',
        btoa(String(supervisionEventDate.id)),
      ],
      {
        relativeTo: this.activatedRoute,
      }
    );
  }

  /**
   * confirm supervision event date
   * @param supervisionEventDate the supervision event date
   * @returns void
   */
  public onConfirmEventDate(
    supervisionEventDate: SupervisionEventDateModel
  ): void {
    this.loadingService.show();
    this.supervisionService
      .updateSupervisionEventDateStatus(
        supervisionEventDate.supervisionId,
        supervisionEventDate.id,
        { isCanceled: false, isChecked: supervisionEventDate.isChecked }
      )
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.loadingService.hide();
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Supervisionstermin wurde zugesagt.'
          );
          this.dataSource.data = this.dataSource.data.map(eventDate => {
            if (eventDate.id === supervisionEventDate.id) {
              eventDate.isCanceled = false;
            }
            return eventDate;
          });
          this.supervisionEventDates = this.supervisionEventDates.map(
            eventDate => {
              if (eventDate.id === supervisionEventDate.id) {
                eventDate.isCanceled = false;
              }
              return eventDate;
            }
          );
        },
        error: () => {
          this.loadingService.hide();
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Supervisionstermin konnte nicht zugesagt werden.'
          );
        },
      });
  }

  /**
   * cancel supervision event date
   * @param supervisionEventDate the supervision event date
   * @returns void
   */
  public onCancelEventDate(
    supervisionEventDate: SupervisionEventDateModel
  ): void {
    this.loadingService.show();
    this.supervisionService
      .updateSupervisionEventDateStatus(
        supervisionEventDate.supervisionId,
        supervisionEventDate.id,
        { isCanceled: true, isChecked: supervisionEventDate.isChecked }
      )
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.loadingService.hide();
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Supervisionstermin wurde abgesagt.'
          );
          this.dataSource.data = this.dataSource.data.map(eventDate => {
            if (eventDate.id === supervisionEventDate.id) {
              eventDate.isCanceled = true;
            }
            return eventDate;
          });
          this.supervisionEventDates = this.supervisionEventDates.map(
            eventDate => {
              if (eventDate.id === supervisionEventDate.id) {
                eventDate.isCanceled = true;
              }
              return eventDate;
            }
          );
        },
        error: () => {
          this.loadingService.hide();
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Supervisionstermin konnte nicht abgesagt werden.'
          );
        },
      });
  }

  /**
   * set supervision event date as checked
   * @param supervisionEventDate the supervision event date
   * @returns void
   */
  public onCheckEventDate(
    supervisionEventDate: SupervisionEventDateModel
  ): void {
    this.loadingService.show();
    this.supervisionService
      .updateSupervisionEventDateStatus(
        supervisionEventDate.supervisionId,
        supervisionEventDate.id,
        { isCanceled: supervisionEventDate.isCanceled, isChecked: true }
      )
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.loadingService.hide();
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Supervisionstermin wurde als geprüft markiert.'
          );
          this.dataSource.data = this.dataSource.data.map(eventDate => {
            if (eventDate.id === supervisionEventDate.id) {
              eventDate.isChecked = true;
            }
            return eventDate;
          });
        },
        error: () => {
          this.loadingService.hide();
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Supervisionstermin konnte nicht als geprüft markiert werden.'
          );
        },
      });
  }

  /**
   * delete supervision event date
   * @param supervisionEventDate
   * @returns void
   */
  public onDeleteEventDate(
    supervisionEventDate: SupervisionEventDateModel
  ): void {
    this.loadingService.show();
    this.supervisionService
      .deleteSupervisionEventDate(
        supervisionEventDate.supervisionId,
        supervisionEventDate.id
      )
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.loadingService.hide();
          this.dataSource.data = this.dataSource.data.filter(
            eventDate => eventDate.id !== supervisionEventDate.id
          );
          this.supervisionEventDates = this.supervisionEventDates.filter(
            eventDate => eventDate.id !== supervisionEventDate.id
          );
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Supervisionstermin wurde gelöscht.'
          );
        },
        error: () => {
          this.loadingService.hide();
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Supervisionstermin konnte nicht gelöscht werden.'
          );
        },
      });
  }

  /**
   * filter the supervision event dates
   * @param event
   */
  public applySearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * gets called when the supervision filter has changed
   * @param supervisionFilter Filter[]
   * @returns void
   */
  public supervisionFilterChanged(supervisionFilter: Filter[]): void {
    this.supervisionFilter = supervisionFilter;

    this.applySupervisionFilter();
    if (this.filterMenuTrigger && this.filterMenuTrigger.menuOpen) {
      this.filterMenuTrigger.closeMenu();
    }
  }

  /**
   * applies the supervision filter
   * @returns void
   */
  public applySupervisionFilter(): void {
    this.dataSource.data = FilterSupervisionPipe.prototype.transform(
      this.supervisionEventDates,
      this.supervisionFilter
    );
  }

  /**
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
