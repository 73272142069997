import { Injectable } from '@angular/core';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { first } from 'rxjs';
import { UserModel } from '../models/user.model';
import { InstituteService } from './institute.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class KeyManagementService {
  public keysInitialized = false;

  constructor(
    private userService: UserService,
    private instituteService: InstituteService,
    private cryptoService: CryptoService
  ) {}

  /**
   * Initializes the keys of the user
   * @returns Promise<boolean>
   */
  public async initializeKeys(): Promise<boolean> {
    // Initialize the keys

    if (this.userService.currentUser?.isSuperadmin) {
      return Promise.resolve(true);
    }

    if (
      this.userService.currentUser?.isPrimaryAdmin &&
      this.userService.currentUser?.currentInstituteId !== null &&
      !this.userService.currentUser?.encryptionKeysGenerated
    ) {
      return await this.initializePrimaryAdminKeys();
    }

    if (!this.userService.currentUser?.encryptionKeysGenerated) {
      await this.generateAndStoreUserKeys();
      if (!this.userService.currentUser?.isConfirmedByAdmin) {
        return Promise.resolve(false);
      }
    }

    return Promise.resolve(true);
  }

  /**
   * Checks if the institute has encryption keys generated and if not,
   * handles the key generation of the tenant and the user
   * @returns Promise<boolean>
   */
  private async initializePrimaryAdminKeys(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.instituteService
        .getInstitute(this.userService.currentUser?.currentInstituteId)
        .pipe(first())
        .subscribe({
          next: async response => {
            this.instituteService.currentInstitute =
              await this.instituteService.parseBackendInstitute(response.body);

            if (
              this.instituteService.currentInstitute?.encryptionKeysGenerated
            ) {
              resolve(true);
              return;
            }

            try {
              const generatedKeyId =
                await this.cryptoService.generateAndStoreTenantAndUserKeys(
                  this.instituteService.currentInstitute?.id
                );

              if (!generatedKeyId) {
                reject();
                return;
              }

              await this.markInstituteKeysAsGenerated();
              await this.markUserKeysAsGenerated();
              await this.confirmUserRegistration(
                this.userService.currentUser?.id,
                this.userService.currentUser?.externalId
              );
            } catch (error) {
              reject();
            }

            resolve(true);
          },
          error: error => {
            reject();
          },
        });
    });
  }

  /**
   * Calls the cryptoService to generate and store the user keys and marks them as generated
   * @returns Promise<boolean>
   */
  private async generateAndStoreUserKeys(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      const externalId = this.userService.currentUser?.externalId;

      if (!externalId) {
        reject('User does not have an externalId');
        return;
      }

      try {
        await this.cryptoService.generateAndStoreUserKeys(externalId);
        await this.markUserKeysAsGenerated();
      } catch (error) {
        reject();
      }

      resolve(true);
    });
  }

  /**
   * Calls the markKeysAsGenerated method of the InstituteService
   * @returns Promise<void>
   */
  private async markInstituteKeysAsGenerated(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.instituteService
        .markKeysAsGenerated(this.instituteService.currentInstitute?.id)
        .pipe(first())
        .subscribe({
          next: response => {
            resolve();
          },
          error: error => {
            reject();
          },
        });
    });
  }

  /**
   * Calls the markKeysAsGenerated method of the UserService
   * @returns Promise<void>
   */
  private async markUserKeysAsGenerated(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.userService
        .markKeysAsGenerated()
        .pipe(first())
        .subscribe({
          next: response => {
            resolve();
          },
          error: error => {
            reject();
          },
        });
    });
  }

  /**
   * Calls the confirmUserRegistration method of the UserService
   * @param userId The id of the user to confirm the registration
   * @param confirmingAdminIdpId The idp id of the confirming admin
   * @returns Promise<void>
   */
  public async confirmUserRegistration(
    userId: number,
    confirmingAdminIdpId: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.userService
        .confirmUserRegistration(userId, confirmingAdminIdpId)
        .pipe(first())
        .subscribe({
          next: response => {
            resolve();
          },
          error: error => {
            reject();
          },
        });
    });
  }

  /**
   * Encrypts the KEK for the invited user and confirms the registration
   * @param user The user to generate the keys for
   * @param confirmingAdminIdpId The idp id of the confirming admin
   * @returns Boolean as a promise
   */
  public async encryptKekForInvitedUserAndConfirmRegistration(
    user: UserModel,
    confirmingAdminIdpId: string
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      if (!user.externalId) reject('User does not have an externalId');
      if (!user.currentInstituteId) reject('User does not have an institute');
      if (!this.userService.currentUser?.confirmingAdminIdpId)
        reject('User does not have a confirming admin');

      this.cryptoService
        .encryptAndStoreKekForUser(
          user.currentInstituteId,
          user.externalId,
          this.userService.currentUser?.confirmingAdminIdpId
        )
        .pipe(first())
        .subscribe({
          next: async () => {
            await this.confirmUserRegistration(user.id, confirmingAdminIdpId);
            resolve(true);
          },
          error: error => {
            reject();
          },
        });
    });
  }
}
