import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import {
  ExamTypeCreateModel,
  ExamTypeModel,
  ExamTypeUpdateModel,
} from '../models/exam-type.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ExamTypeService {
  private instituteId: number =
    this.userService.currentUser?.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private userService: UserService
  ) {}

  /**
   * getAllExamTypes
   * get all exam types from current institute
   * @returns Observable<HttpResponse<any>>
   */
  public getAllExamTypes(): Observable<HttpResponse<any>> {
    return this.http.get(
      this.config.backendUrl + `/api/institutes/${this.instituteId}/exam-types`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }
  /**
   * getExamTypeById
   * get an exam type from the current institute by id
   * @param examTypeId
   * @returns Observable<HttpResponse<any>>
   */
  public getExamTypeById(examTypeId: number): Observable<any> {
    return this.http.get(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/exam-types/${examTypeId}`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * createExamType
   * create a new exam type for the current institute
   * @param examType
   * @returns Observable<HttpResponse<any>>
   */
  public createExamType(
    examType: ExamTypeCreateModel
  ): Observable<HttpResponse<any>> {
    return this.http.post(
      this.config.backendUrl + `/api/institutes/${this.instituteId}/exam-types`,
      examType,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * updateExamType
   * update an exam type for the current institute
   * @param examTypeId
   * @param examType
   * @returns Observable<HttpResponse<any>>
   */
  public updateExamType(
    examTypeId: number,
    examType: ExamTypeUpdateModel
  ): Observable<HttpResponse<any>> {
    return this.http.put(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/exam-types/${examTypeId}`,
      examType,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * deleteExamType
   * delete an exam type from the current institute
   * @param examTypeId
   * @returns Observable<HttpResponse<any>>
   * */
  public deleteExamType(examTypeId: number): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/exam-types/${examTypeId}`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * parseBackendExamType
   * parse the exam type from the backend
   * @param examType
   * @returns ExamTypeModel
   */
  public parseBackendExamType(examType: ExamTypeModel): ExamTypeModel {
    return {
      id: examType.id,
      name: examType.name,
      creator: examType.creator,
      timeCreated:
        examType.timeCreated &&
        moment(examType.timeCreated).tz('Europe/Berlin').toDate(),
      timeModified:
        examType.timeModified &&
        moment(examType.timeModified).tz('Europe/Berlin').toDate(),
      isStandard: examType.isStandard,
    };
  }
}
