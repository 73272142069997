<button (click)="onOpenMobileMenu()" class="menu-button">
  <i class="fa-light fa-bars menu-button-icon"></i>
</button>
<div class="menu-dialog" [class.menu-dialog-shown]="isMobileMenuOpen">
  <div class="header">
    <div class="flex-r gap-16 align-center">
      <img
        class="user-icon"
        [attr.src]="
          userService.currentUser.profilePicture ??
          '../../../assets/img/user.jpg'
        "
        alt="" />
      <div class="user-name">
        {{
          userService.currentUser.name?.firstName +
            ' ' +
            userService.currentUser.name?.lastName
        }}
      </div>
    </div>
    <button (click)="onOpenMobileMenu()" class="close-button">
      <i class="fa-light fa-times close-button-icon"></i>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div class="flex-c gap-16">
    <a
      *appCheckPermissions="{
        feature: feature.DASHBOARD,
        permission: permission.VIEW,
      }"
      (click)="onOpenMobileMenu()"
      [routerLink]="['dashboard']"
      class="hasomed-text-button hasomed-button-nav"
      routerLinkActive="active"
      matTooltipPosition="right">
      <i class="fa-light fa-house hasomed-button-icon"></i>

      <span class="hasomed-button-text">Mein Bereich</span>
    </a>
    <a
      *appCheckPermissions="{
        feature: feature.EDUCATION,
        permission: permission.VIEW,
      }"
      (click)="onOpenMobileMenu()"
      [routerLink]="['education']"
      class="hasomed-text-button hasomed-button-nav"
      routerLinkActive="active"
      matTooltipPosition="right">
      <i class="fa-light fa-book hasomed-button-icon"></i>

      <div class="hasomed-button-text">
        <span>Ausbildung</span>
      </div>
    </a>
    <a
      *appCheckPermissions="{
        feature: feature.ORGANIZATION,
        permission: permission.VIEW,
      }"
      (click)="onOpenMobileMenu()"
      [routerLink]="['organization']"
      class="hasomed-text-button hasomed-button-nav"
      routerLinkActive="active"
      matTooltipPosition="right">
      <i class="fa-light fa-user-gear hasomed-button-icon"></i>

      <div class="hasomed-button-text">
        <span>Organisation</span>
      </div>
    </a>
    <a
      *appCheckPermissions="{
        feature: feature.INSTITUTE,
        permission: permission.ADMIN,
      }"
      (click)="onOpenMobileMenu()"
      [routerLink]="['institute']"
      class="hasomed-text-button hasomed-button-nav"
      routerLinkActive="active"
      matTooltipPosition="right">
      <i class="fa-light fa-school hasomed-button-icon"></i>
      <div class="hasomed-button-text">
        <span>Institut</span>
      </div>
    </a>
    <a
      *appCheckPermissions="{
        feature: feature.ADMINISTRATION,
        permission: permission.VIEW,
      }"
      (click)="onOpenMobileMenu()"
      [routerLink]="['administration']"
      class="hasomed-text-button hasomed-button-nav"
      routerLinkActive="active"
      matTooltipPosition="right">
      <i class="fa-light fa-toolbox hasomed-button-icon"></i>

      <div class="hasomed-button-text">
        <span>Administration</span>
      </div>
    </a>
  </div>
  <mat-divider></mat-divider>
  <button (click)="onOpenSettings()" type="button" class="user-menu-button">
    <div class="hasomed-button-icon">
      <i class="fa-light fa-gear"></i>
    </div>
    <div class="hasomed-button-text">
      <span>Einstellungen</span>
    </div>
  </button>
  <mat-divider></mat-divider>
  <button (click)="onOpenInformation()" type="button" class="user-menu-button">
    <div class="hasomed-button-icon">
      <i class="fa-light fa-circle-info"></i>
    </div>
    <div class="hasomed-button-text">
      <span>Informationen</span>
    </div>
  </button>
  <mat-divider></mat-divider>
  <button
    (click)="logout()"
    type="button"
    class="user-menu-button logout-button">
    <div class="hasomed-button-icon">
      <i class="fa-light fa-right-from-bracket"></i>
    </div>
    <div class="hasomed-button-text">
      <span>Log out</span>
    </div>
  </button>
</div>
