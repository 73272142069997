import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor() {}

  // Variables
  public theme: string;

  // Functions
  public setTheme(theme: string) {
    this.theme = theme;
  }
}
