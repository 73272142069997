<div class="warning-component">
  <div class="flex-r gap-8 header">
    <i class="fa-light fa-exclamation-circle"></i>
    <h1 class="hasomed-text-h1">Ihre Sitzung läuft bald ab</h1>
  </div>
  <div class="content">
    <div class="icon"></div>
    <div class="text">
      <span>
        Ihre Sitzung läuft bald ab. Bitte melden Sie sich erneut an.
      </span>
    </div>
  </div>
  <div class="footer gap-16">
    <button
      type="button"
      class="hasomed-text-button hasomed-button-white"
      (click)="onDismiss()">
      <span class="hasomed-button-text">Abbrechen</span>
    </button>
    <button
      type="button"
      class="hasomed-text-button hasomed-button-primary"
      (click)="onRenewSession()">
      <span class="hasomed-button-text">Sitzung erneuern</span>
    </button>
  </div>
</div>
