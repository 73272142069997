<div class="calendar-toolbar">
  <!-- Today button -->
  <div class="flex-r gap-16">
    <div class="nav-buttons flex-r">
      <!-- Prev button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey nav-button"
        (click)="onClickPrev()">
        <i class="hasomed-button-icon fa-light fa-chevron-left"></i>
      </button>

      <!-- Next button -->
      <button
        type="button"
        class="hasomed-text-button hasomed-button-white-grey nav-button"
        (click)="onClickNext()">
        <i class="hasomed-button-icon fa-light fa-chevron-right"></i>
      </button>
    </div>

    <div class="hasomed-toggle-button">
      <button
        id="today-button"
        type="button"
        class="hasomed-text-button hasomed-button-primary btn-left"
        (click)="onClickToday()"
        [disabled]="isTodayInView">
        <i class="hasomed-button-icon fa-light fa-calendar-day"></i>
        <span class="hasomed-button-text">Heute</span>
      </button>

      <button
        #datePickerTrigger
        class="hasomed-icon-button hasomed-button-white-grey btn-right"
        (click)="datePicker.open()">
        <input
          matInput
          [matDatepicker]="datePicker"
          [formControl]="datePickerControl"
          hidden />
        <i
          matDatepickerToggleIcon
          class="hasomed-button-icon fa-light fa-calendar"></i>

        <mat-datepicker
          #datePicker
          [restoreFocus]="false"
          [touchUi]="true"></mat-datepicker>
      </button>
    </div>
  </div>

  <!-- Title -->
  <div id="day-title" class="hasomed-text-h1">
    <span id="fcCustomTitle"></span>
  </div>
</div>
<full-calendar
  #fullCalendar
  class="calendar"
  [options]="calendarOptions"></full-calendar>
