<div class="component-content">
  <!-- HEADER -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Supervisionstermin</h1>
      <h3 class="hasomed-text-h3">Sehen Sie sich den Supervisionstermin an.</h3>
    </div>
    <div class="header-buttons">
      <button
        (click)="onGoBack()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Zurück</span>
      </button>
      <button
        *appCheckPermissions="{
          feature: feature.SUPERVISION,
          permission: permission.ADMIN,
        }"
        [disabled]="isLoading"
        (click)="onEdit()"
        type="button"
        class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-pen hasomed-button-icon"></i>
        <span class="hasomed-button-text">Bearbeiten</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content-details">
    <!-- type -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Supervision</span>
        <span class="label-description">Art der Supervision.</span>
      </div>
      <div class="detail-value">{{ supervisionEventDate?.type ?? '-' }}</div>
    </div>

    <mat-divider></mat-divider>

    <!-- treatmentCase -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Behandlungsfall</span>
        <span class="label-description">
          Der Behandlungsfall für diesen Supervisionstermin.
        </span>
      </div>
      <div class="detail-value-columns">
        <ng-container
          *ngFor="let treatmentCase of supervisionEventDate?.treatmentCases">
          <span>Patient: Chiffre ({{ treatmentCase.patientChiffre }})</span>
        </ng-container>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- status -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Status</span>
        <span class="label-description">Aktueller Status des Termins.</span>
      </div>
      <div class="detail-value">
        <ng-container *ngIf="supervisionEventDate.isCanceled; else isFuture">
          <div class="label label-white">
            <i class="fa-light fa-circle-xmark label-icon"></i>
            <span class="hasomed-text-small label-text">abgesagt</span>
          </div>
        </ng-container>
        <ng-template #isFuture>
          <ng-container
            *ngIf="
              dateIsInFuture(supervisionEventDate.startDate);
              else isChecked
            ">
            <div class="label label-white">
              <i class="fa-light fa-circle-dashed label-icon"></i>
              <span class="hasomed-text-small label-text">geplant</span>
            </div>
          </ng-container>
        </ng-template>
        <ng-template #isChecked>
          <ng-container *ngIf="supervisionEventDate.isChecked; else unchecked">
            <div class="label label-green">
              <i class="fa-light fa-circle-check label-icon"></i>
              <span class="hasomed-text-small label-text">geprüft</span>
            </div>
          </ng-container>
        </ng-template>
        <ng-template #unchecked>
          <div class="label label-white">
            <i class="fa-light fa-circle-dashed label-icon"></i>
            <span class="hasomed-text-small label-text">ungeprüft</span>
          </div>
        </ng-template>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- duration -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Dauer des Termins in Zeiteinheiten</span>
        <span class="label-description">
          Eine Einheit entspricht 45 Minuten.
        </span>
      </div>
      <div class="detail-value">
        <span>
          {{ supervisionEventDate?.durationInTimeUnits ?? '-' }}
        </span>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- date and time -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Datum der Supervision </span>
        <span class="label-description">
          An diesem Datum findet der Termin statt.
        </span>
      </div>
      <div class="detail-value">
        <div class="flex-r gap-8 flex-wrap">
          <div class="day-box">
            <i class="fa-light fa-calendar"></i>
            <span>
              {{ supervisionEventDate.startDate | date: 'dd.MM.yyyy' }}
            </span>
          </div>
          <div class="time-boxes">
            <div class="time-box">
              <i class="fa-light fa-clock"></i>
              <span>
                {{ supervisionEventDate.startDate | date: 'HH:mm' }}
              </span>
            </div>
            <div class="time-box">
              <i class="fa-light fa-clock"></i>
              <span>
                {{ supervisionEventDate.endDate | date: 'HH:mm' }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- room and location -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Ort der Supervision</span>
        <span class="label-description">
          In diesem Raum oder auf dieser Online Video Plattform findet der
          Termin statt.
        </span>
      </div>
      <div class="detail-value">
        <div class="flex-c gap-24">
          <span> Raum: {{ supervisionEventDate?.room?.name ?? '-' }} </span>
          <div class="flex-r gap-16 align-center">
            <span>
              URL für Video-Meeting:
              {{ supervisionEventDate?.videoMeetingLink ?? '-' }}
            </span>
            <button
              *ngIf="supervisionEventDate?.videoMeetingLink"
              (click)="
                clipboardService.copyToClipboard(
                  'URL für Video-Meeting',
                  supervisionEventDate?.videoMeetingLink
                )
              "
              class="hasomed-text-button hasomed-button-white-grey copy-button">
              <i class="fa-light fa-link hasomed-button-icon"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <ng-container *ngIf="!isLecturer">
      <!-- supervisor -->
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">Supervisor</span>
          <span class="label-description">
            Verantwortlicher Supervisor für diesen Termin.
          </span>
        </div>
        <div class="detail-value">
          {{ getFullName(supervisionEventDate?.supervisor) ?? '-' }}
        </div>
      </div>

      <mat-divider></mat-divider>
    </ng-container>

    <ng-container *ngIf="!isStudent">
      <!-- student -->
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title">Kandidat</span>
          <span class="label-description">
            Der Kandidat zu dem dieser Termin gehört.
          </span>
        </div>
        <div class="detail-value">
          {{ getFullName(supervisionEventDate?.student) ?? '-' }}
        </div>
      </div>

      <mat-divider></mat-divider>
    </ng-container>

    <!-- description -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Beschreibung </span>
        <span class="label-description"> Ergänzende Informationen. </span>
      </div>
      <div class="detail-value">
        {{ supervisionEventDate?.description ?? '-' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- files -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title">Dokumente</span>
        <span class="label-description">
          Sehen Sie die hinterlegten Dokumente ein.
        </span>
      </div>
      <span
        *ngIf="
          !supervisionEventDate?.files ||
            supervisionEventDate?.files?.length == 0;
          else files
        "
        class="detail-value">
        Keine Dokumente hochgeladen.
      </span>
      <ng-template #files>
        <div class="detail-value-files">
          <app-file-view
            *ngFor="let file of supervisionEventDate?.files"
            [file]="file"
            (downloadFile)="onDownloadFile($event)"
            (openFile)="onOpenFile($event)"></app-file-view>
        </div>
      </ng-template>
    </div>
  </div>
</div>
