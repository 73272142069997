<div [class.table-bicolor]="dataSource?.filteredData?.length > 1" class="table">
  <table [dataSource]="dataSource" mat-table matSort>
    <!-- Chiffre column -->
    <ng-container matColumnDef="chiffre">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        Chiffre
        <app-custom-table-sort-icon [sort]="sort" columnName="chiffre">
        </app-custom-table-sort-icon>
      </th>
      <td
        *matCellDef="let treatmentCase; dataSource: dataSource"
        (click)="onViewTreatmentCase(treatmentCase)"
        class="cursor-pointer gray-900"
        matTooltip="Behandlungsfall anzeigen"
        mat-cell>
        {{ treatmentCase.patientChiffre }}
      </td>
    </ng-container>

    <!-- Supervisors column -->
    <ng-container matColumnDef="supervisors">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        Supervisor
        <app-custom-table-sort-icon [sort]="sort" columnName="supervisors">
        </app-custom-table-sort-icon>
      </th>
      <td *matCellDef="let treatmentCase; dataSource: dataSource" mat-cell>
        {{ getAllSupervisors(treatmentCase) ?? '-' }}
      </td>
    </ng-container>

    <!-- Dates column -->
    <ng-container matColumnDef="dates">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        Termine
        <app-custom-table-sort-icon [sort]="sort" columnName="dates">
        </app-custom-table-sort-icon>
      </th>
      <td *matCellDef="let treatmentCase; dataSource: dataSource" mat-cell>
        <ng-container
          *ngIf="
            treatmentCase.startDate && treatmentCase.endDate;
            else emptyDate
          ">
          <span>
            {{ treatmentCase.startDate | date: 'dd.MM.yyyy' }}
          </span>
          <span
            *ngIf="!isSameDay(treatmentCase.startDate, treatmentCase.endDate)">
            - {{ treatmentCase.endDate | date: 'dd.MM.yyyy' }}
          </span>
        </ng-container>
        <ng-template #emptyDate>-</ng-template>
      </td>
    </ng-container>

    <!-- Status column -->
    <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        Status
        <app-custom-table-sort-icon [sort]="sort" columnName="status">
        </app-custom-table-sort-icon>
      </th>
      <td *matCellDef="let treatmentCase; dataSource: dataSource" mat-cell>
        {{
          treatmentCase.isCompleted
            ? 'Abgeschlossen'
            : 'Laufend, ' + treatmentCase.therapyPhase?.name
        }}
      </td>
    </ng-container>

    <!-- Actions column -->
    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef class="center-header-cell" mat-header-cell>
        {{ isMobile ? 'Akt.' : 'Aktionen' }}
      </th>
      <td *matCellDef="let treatmentCase; dataSource: dataSource" mat-cell>
        <div class="table-button-icons">
          <button
            #t="matMenuTrigger"
            [matMenuTriggerFor]="actionsMenu"
            type="button"
            class="table-button-icon"
            matTooltip="Behandlungsfall Aktionen">
            <i class="hasomed-button-icon fa-light fa-pen-to-square"></i>
          </button>

          <mat-menu #actionsMenu="matMenu" class="action-menu">
            <button
              (click)="onViewTreatmentCaseAppointmentEventDates(treatmentCase)"
              mat-menu-item
              type="button">
              <div class="action-menu-item">
                <i class="fa-light fa-calendar-plus icon-sm"></i>
                <span class="hasomed-text-body gray-600">Termine Anzeigen</span>
              </div>
            </button>

            <mat-divider class="menu-divider"></mat-divider>
            <button
              (click)="onViewTreatmentCase(treatmentCase)"
              mat-menu-item
              type="button">
              <div class="action-menu-item">
                <i class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
                <span class="hasomed-text-body gray-600">Fall Anzeigen</span>
              </div>
            </button>

            <mat-divider class="menu-divider"></mat-divider>
            <button
              (click)="onEditTreatmentCase(treatmentCase)"
              mat-menu-item
              type="button">
              <div class="action-menu-item">
                <i class="fa-light fa-pen icon-sm"></i>
                <span class="hasomed-text-body gray-600">Bearbeiten</span>
              </div>
            </button>

            <mat-divider class="menu-divider"></mat-divider>
            <button
              (click)="onCompleteTreatmentCase(treatmentCase)"
              mat-menu-item
              type="button">
              <div class="action-menu-item">
                <i class="fa-light fa-circle-check icon-sm"></i>
                <span class="hasomed-text-body gray-600">{{
                  treatmentCase?.isCompleted ? 'Wiederaufnehmen' : 'Abschließen'
                }}</span>
              </div>
            </button>

            <ng-container *ngIf="!treatmentCase?.isCompleted">
              <mat-divider class="menu-divider"></mat-divider>
              <button
                (click)="onDeleteTreatmentCase(treatmentCase)"
                type="button"
                mat-menu-item>
                <div class="action-menu-item">
                  <i class="fa-light fa-trash-can icon-sm"></i>
                  <span class="hasomed-text-body gray-600">Löschen</span>
                </div>
              </button>
            </ng-container>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <tbody>
      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

      <!-- Row shown when there is no matching data. -->
      <tr *matNoDataRow class="mat-row">
        <td [attr.colspan]="displayedColumns.length">
          <div *ngIf="isLoading" class="loading-wrapper">
            <app-lottie-loading-spinner
              size="45px"></app-lottie-loading-spinner>
          </div>
          <div
            *ngIf="!isLoading"
            class="mat-cell table-no-match hasomed-text-body font-grey">
            Es wurde kein Behandlungsfall gefunden.
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <mat-divider></mat-divider>
  <mat-paginator
    [appCustomLength]="dataSource.filteredData.length"
    [pageSize]="7"
    [renderButtonsNumber]="isMobile ? 0 : 1"
    custom-paginator
    aria-label="Select page"></mat-paginator>
</div>
<div class="vertical-spacer"></div>
