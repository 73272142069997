import { FormArray } from '@angular/forms';
import * as moment from 'moment';
import 'moment-timezone';
import { WeekdayEnum } from '../enums/weekday.enum';
import { OpeningHourModel } from '../models/opening-hour.model';

const days = [
  WeekdayEnum.MONDAY,
  WeekdayEnum.TUESDAY,
  WeekdayEnum.WEDNESDAY,
  WeekdayEnum.THURSDAY,
  WeekdayEnum.FRIDAY,
  WeekdayEnum.SATURDAY,
  WeekdayEnum.SUNDAY,
];

/**
 * sorts the opening hours by the day of the week
 * @param openingHours OpeningHourModel[]
 * @returns OpeningHourModel[]
 */
export function sortOpeningHoursByDay(
  openingHours: OpeningHourModel[]
): OpeningHourModel[] {
  if (!openingHours) {
    return [];
  }
  return openingHours.sort((a, b) => {
    return days.indexOf(a.dayOfWeek) - days.indexOf(b.dayOfWeek);
  });
}

/**
 * creates an array of OpeningHourModels from the form array
 * @returns OpeningHourModel[]
 */
export function createOpeningHoursFromFormArray(
  openingHours: FormArray
): OpeningHourModel[] {
  if (!openingHours) {
    return [];
  }

  const localTimeZone = moment.tz.guess();

  return openingHours.controls.map(control => {
    return {
      id: control.get('id')?.value,
      dayOfWeek: control.get('day').value,
      openingTime: control.get('from').value,
      closingTime: control.get('to').value,
      timeZone: control.get('timeZone')?.value ?? localTimeZone,
    };
  });
}

/**
 * Converts a opening hour in utc to a opening hour in local time.
 * @param openingHour The opening hour in utc from the backend
 * @returns The opening hour in local time
 */
export function parseBackendOpeningHourModel(
  openingHour: OpeningHourModel
): OpeningHourModel {
  return {
    id: openingHour.id,
    dayOfWeek: openingHour.dayOfWeek,
    openingTime: openingHour.openingTime,
    closingTime: openingHour.closingTime,
    timeZone: openingHour.timeZone,
  };
}

/**
 * sorts the opening hours form array by the day of the week
 * @param openingHours the opening hours to sort
 * @returns the sorted opening hours
 */
export function sortOpeningHoursFormArrayByWeekday(
  openingHours: FormArray
): FormArray {
  openingHours.controls.sort((a, b) => {
    const dayA = a.get('day').value;
    const dayB = b.get('day').value;
    return days.indexOf(dayA) - days.indexOf(dayB);
  });
  return openingHours;
}
