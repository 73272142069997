import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs';
import { FileModel } from 'src/app/models/file.model';
import { formatBytes } from 'src/app/utils/file.utils';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-file-view',
  standalone: false,
  templateUrl: './file-view.component.html',
  styleUrl: './file-view.component.scss',
})
export class FileViewComponent implements OnInit {
  @Input() public file: FileModel;
  @Input() public deleteEnabled: boolean = false;
  @Output() public deleteFile: EventEmitter<FileModel> = new EventEmitter();
  @Output() public downloadFile: EventEmitter<FileModel> = new EventEmitter();
  @Output() public openFile: EventEmitter<FileModel> = new EventEmitter();

  // import from utils
  public formatBytes = formatBytes;

  constructor(private dialog: MatDialog) {}

  public ngOnInit(): void {}

  /**
   * onDownload
   * @param file
   * @returns void
   */
  public onDownload(file: FileModel): void {
    this.downloadFile.emit(file);
  }

  /**
   * onOpen
   * @param file
   * @returns void
   */
  public onOpen(file: FileModel): void {
    this.openFile.emit(file);
  }

  /**
   * onDelete
   * @param file
   * @returns void
   */
  public onDelete(file: FileModel): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Datei löschen',
        message: 'Möchten Sie die Datei wirklich löschen?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.deleteFile.emit(file);
        }
      });
  }
}
