<div class="component-content">
  <!-- HEADER  -->
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Impressum</h1>
      <h3 class="hasomed-text-h3">Gemäß § 5 TMG</h3>
    </div>
  </div>

  <!-- MAIN CONTENT -->
  <div class="main-content gap-40">
    <div class="flex-c gap-16">
      <img
        width="170"
        src="../../../../../assets/img/EleGuide_Logo-Fullsize.svg"
        alt="EleGuide Logo" />
      <span class="version">Version 0.8.0</span>
    </div>
    <div class="flex-c gap-16">
      <div class="flex-c">
        <span class="hasomed-text-body">HealthyCloud GmbH</span>
        <span class="hasomed-text-body">Ölweide 18</span>
        <span class="hasomed-text-body">39114 Magdeburg</span>
      </div>
      <div class="flex-c">E-Mail: kontakt&#64;healthycloud.de</div>
      <div class="flex-c">
        <span class="hasomed-text-body">
          Geschäftsführer: Matthias Weber und Patrick Hauff
        </span>
        <span class="hasomed-text-body">
          Registergericht: Amtsgericht Stendal
        </span>
        <span class="hasomed-text-body">Registernummer: HRB 33570</span>
        <span class="hasomed-text-body">USt.-ID: DE364912193</span>
      </div>
    </div>
    <div class="flex-c">
      <a href="https://eleguide.de/" class="link" target="_blank">Webseite</a>
      <a
        href="https://healthycloud.de/impressum_datenschutz/"
        class="link"
        target="_blank">
        Impressum & Datenschutz
      </a>
    </div>
  </div>
</div>
