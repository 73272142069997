import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CryptoService } from '@healthycloud/lib-ngx-crypto';
import { Observable, takeUntil } from 'rxjs';
import { APP_CONFIG, AppConfig } from 'src/app.config';
import { OpeningHourModel } from '../models/opening-hour.model';
import {
  RoomCreateModel,
  RoomModel,
  RoomUpdateModel,
} from '../models/room.model';
import {
  parseBackendOpeningHourModel,
  sortOpeningHoursByDay,
} from '../utils/opening-hour.utils';
import { CancellationService } from './cancellation.service';
import { DecryptionService } from './decryption.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class RoomService {
  private instituteId: number =
    this.userService.currentUser?.currentInstituteId;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private cryptoService: CryptoService,
    private decryptionService: DecryptionService,
    private cancellationService: CancellationService,
    private userService: UserService
  ) {}

  /**
   * getAllRooms
   * Get all rooms of the current institute
   * @returns Observable<HttpResponse<any>>
   */
  public getAllRooms(): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl + `/api/institutes/${this.instituteId}/rooms`,
        {
          observe: 'response',
          responseType: 'json',
        }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * getRoomById
   * Get room by id
   * @param roomId
   * @returns Observable<HttpResponse<any>>
   */
  public getRoomById(roomId: number): Observable<HttpResponse<any>> {
    return this.http
      .get(
        this.config.backendUrl +
          `/api/institutes/${this.instituteId}/rooms/${roomId}`,
        { observe: 'response', responseType: 'json' }
      )
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * createRoom
   * Creates a new room
   * @param roomCreateModel
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async createRoom(
    roomCreateModel: RoomCreateModel
  ): Promise<Observable<HttpResponse<any>>> {
    if (roomCreateModel.address.street)
      roomCreateModel.address.street = await this.cryptoService.encrypt(
        roomCreateModel.address.street
      );
    if (roomCreateModel.address.houseNumber)
      roomCreateModel.address.houseNumber = await this.cryptoService.encrypt(
        roomCreateModel.address.houseNumber
      );
    if (roomCreateModel.address.addressAddition)
      roomCreateModel.address.addressAddition =
        await this.cryptoService.encrypt(
          roomCreateModel.address.addressAddition
        );
    if (roomCreateModel.address.zipCode)
      roomCreateModel.address.zipCode = await this.cryptoService.encrypt(
        roomCreateModel.address.zipCode
      );
    if (roomCreateModel.address.city)
      roomCreateModel.address.city = await this.cryptoService.encrypt(
        roomCreateModel.address.city
      );
    if (roomCreateModel.address.country)
      roomCreateModel.address.country = await this.cryptoService.encrypt(
        roomCreateModel.address.country
      );

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(
      this.config.backendUrl + `/api/institutes/${this.instituteId}/rooms`,
      roomCreateModel,
      { headers: headers, observe: 'response', responseType: 'json' }
    );
  }

  /**
   * Updates a room object or the available state of a room
   * @param roomId id of the room
   * @param roomUpdateModel room object
   * @param mode mode of the update
   * @returns Promise<Observable<HttpResponse<any>>>
   */
  public async updateRoom(
    roomId: number,
    roomUpdateModel: RoomUpdateModel
  ): Promise<Observable<HttpResponse<any>>> {
    if (roomUpdateModel.address.street)
      roomUpdateModel.address.street = await this.cryptoService.encrypt(
        roomUpdateModel.address.street
      );
    if (roomUpdateModel.address.houseNumber)
      roomUpdateModel.address.houseNumber = await this.cryptoService.encrypt(
        roomUpdateModel.address.houseNumber
      );
    if (roomUpdateModel.address.addressAddition)
      roomUpdateModel.address.addressAddition =
        await this.cryptoService.encrypt(
          roomUpdateModel.address.addressAddition
        );
    if (roomUpdateModel.address.zipCode)
      roomUpdateModel.address.zipCode = await this.cryptoService.encrypt(
        roomUpdateModel.address.zipCode
      );
    if (roomUpdateModel.address.city)
      roomUpdateModel.address.city = await this.cryptoService.encrypt(
        roomUpdateModel.address.city
      );
    if (roomUpdateModel.address.country)
      roomUpdateModel.address.country = await this.cryptoService.encrypt(
        roomUpdateModel.address.country
      );

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/rooms/${roomId}`,
      roomUpdateModel,
      { headers: headers, observe: 'response', responseType: 'json' }
    );
  }

  /**
   * deleteRoom
   * Deletes a room
   * @param roomId id of the room
   * @returns Observable
   */
  public deleteRoom(roomId: number): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/rooms/${roomId}`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * enableOrDisableRoom
   * Enable or disable a room
   * @param roomId id of the room
   * @param isAvailable boolean
   * @returns Observable<HttpResponse<any>>
   */
  public enableOrDisableRoom(
    roomId: number,
    isAvailable: boolean
  ): Observable<HttpResponse<any>> {
    return this.http.patch(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/rooms/${roomId}`,
      { isAvailable },
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * getRoomTypes
   * Get all room types
   * @returns Observable<HttpResponse<any>>
   */
  public getRoomTypes(): Observable<HttpResponse<any>> {
    return this.http
      .get(this.config.backendUrl + '/api/room-types', {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(takeUntil(this.cancellationService.cancelRequests$));
  }

  /**
   * checkAvailabilityOfRooms
   * Check the availability of rooms
   * @param startDate start date
   * @param endDate end date
   * @param numberOfPersons number of persons
   * @returns Observable<HttpResponse<any>>
   */
  public checkAvailabilityOfRooms(
    startDate: Date,
    endDate: Date,
    numberOfPersons: number,
    eventDateId?: number
  ): Observable<HttpResponse<any>> {
    const params = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      numberOfPersons: numberOfPersons,
    };

    if (eventDateId) {
      params['eventDateId'] = eventDateId;
    }

    return this.http.get<any[]>(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/rooms/check-availability`,
      {
        params,
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Check if room has upcoming event dates
   * @param roomId id of the room
   * @returns Observable<HttpResponse<boolean>>
   */
  public hasRoomUpcomingEventDates(
    roomId: number
  ): Observable<HttpResponse<boolean>> {
    return this.http.get<boolean>(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/rooms/${roomId}/has-upcoming-event-dates`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * Remove room from upcoming event dates
   * @param roomId id of the room
   * @returns Observable<HttpResponse<any>>
   */
  public removeRoomFromUpcomingEventDates(
    roomId: number
  ): Observable<HttpResponse<any>> {
    return this.http.delete(
      this.config.backendUrl +
        `/api/institutes/${this.instituteId}/rooms/${roomId}/remove-from-upcoming-event-dates`,
      {
        observe: 'response',
        responseType: 'json',
      }
    );
  }

  /**
   * parseBackendRoom
   * Parses the backend room data to a frontend room object, decrypts the encrypted data
   * @param room backend room data
   * @returns Promise<Room>
   */
  public async parseBackendRoom(room: RoomModel): Promise<RoomModel> {
    try {
      const parsedRoom: RoomModel = {
        id: room.id,
        name:
          room.name && (await this.decryptionService.decryptString(room.name)),
        roomType: room.roomType,
        address: {
          street:
            room.address?.street &&
            (await this.decryptionService.decryptString(room.address.street)),
          houseNumber:
            room.address?.houseNumber &&
            (await this.decryptionService.decryptString(
              room.address.houseNumber
            )),
          addressAddition:
            room.address?.addressAddition &&
            (await this.decryptionService.decryptString(
              room.address.addressAddition
            )),
          zipCode:
            room.address?.zipCode &&
            (await this.decryptionService.decryptString(room.address.zipCode)),
          city:
            room.address?.city &&
            (await this.decryptionService.decryptString(room.address.city)),
          country:
            room.address?.country &&
            (await this.decryptionService.decryptString(room.address.country)),
        },
        floor: room.floor,
        building: room.building,
        seatNumber: room.seatNumber,
        equipment: room.equipment,
        responsiblePerson: room.responsiblePerson,
        openingHours: sortOpeningHoursByDay(
          room.openingHours.map(
            (openingHour: OpeningHourModel): OpeningHourModel => {
              return parseBackendOpeningHourModel(openingHour);
            }
          )
        ),
        isAvailable: room.isAvailable,
      };

      return parsedRoom;
    } catch (error) {
      const parsedRoom: RoomModel = {
        id: room.id,
        name: room.name,
        address: {
          street: room.address?.street,
          houseNumber: room.address?.houseNumber,
          addressAddition: room.address?.addressAddition,
          zipCode: room.address?.zipCode,
          city: room.address?.city,
          country: room.address?.country,
        },
        floor: room.floor,
        building: room.building,
        seatNumber: room.seatNumber,
        equipment: room.equipment,
        responsiblePerson: room.responsiblePerson,
        isAvailable: room.isAvailable,
      };

      return parsedRoom;
    }
  }
}
