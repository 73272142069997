import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private messageSubject = new BehaviorSubject<string>('Lädt...');

  public isLoading$ = this.loadingSubject.asObservable();
  public loadingMessage$ = this.messageSubject.asObservable();

  public show(message: string = 'Lädt...'): void {
    this.messageSubject.next(message);
    this.loadingSubject.next(true);
  }

  public hide(): void {
    this.loadingSubject.next(false);
  }

  public isLoading(): boolean {
    return this.loadingSubject.value;
  }
}
