<div *ngIf="isLoading" class="loading-wrapper">
  <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
</div>
<div *ngIf="!isLoading" class="flex-c course-details-main">
  <div class="flex-c gap-32">
    <div class="flex-c gap-16">
      <h1 class="hasomed-text-h1">
        {{ course.title }}
      </h1>
      <div
        *ngIf="course.description"
        class="hasomed-text-h3 description"
        [innerHTML]="getSanitizedHtml(course.description)"></div>
    </div>
    <div class="flex-c gap-16">
      <div class="detail-row">
        <span class="title">Kurstyp</span>
        <span class="value">{{ course.courseType?.name }}</span>
      </div>
      <mat-divider></mat-divider>
      <div class="detail-row">
        <span class="title">Aus- und Weiterbildungsgang</span>
        <span
          class="value"
          *ngIf="
            !course.educationCourses || course.educationCourses?.length == 0;
            else educationCourses
          ">
          -
        </span>
        <ng-template #educationCourses>
          <div class="value-list">
            <span *ngFor="let educationCourse of course.educationCourses">
              {{ educationCourse?.title }}
            </span>
          </div>
        </ng-template>
      </div>
      <mat-divider></mat-divider>
      <div class="detail-row">
        <span class="title">Kursart</span>
        <div class="value">
          <i class="fa-light fa-lock"></i>
          <span>{{ course.closed ? 'geschlossen' : 'offen' }}</span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="detail-row">
        <span class="title">Kursausrichtung</span>
        <div class="value">
          <i class="fa-light fa-file-certificate"></i>
          <span>{{
            course.mandatory ? 'Ausbildungsrelevant' : 'Optional'
          }}</span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="detail-row">
        <span class="title">Lehrinhalte</span>
        <span
          class="value"
          *ngIf="
            !course.learningContents || course.learningContents?.length == 0;
            else learningContents
          ">
          -
        </span>
        <ng-template #learningContents>
          <div class="value-list">
            <span *ngFor="let learningContent of course.learningContents">
              {{ learningContent?.name }}
            </span>
          </div>
        </ng-template>
      </div>
      <mat-divider></mat-divider>
      <div class="detail-row">
        <span class="title">Art der Prüfungen</span>
        <span
          class="value"
          *ngIf="
            !course.examTypes || course.examTypes?.length == 0;
            else examTypes
          ">
          -
        </span>
        <ng-template #examTypes>
          <div class="value-list">
            <span *ngFor="let examType of course.examTypes">
              {{ examType?.name }}
            </span>
          </div>
        </ng-template>
      </div>
      <mat-divider
        *ngIf="
          (course.isRegistered || !isStudent) &&
          course.location != CourseLocation.ONSITE
        "></mat-divider>
      <div
        *ngIf="
          (course.isRegistered || !isStudent) &&
          course.location != CourseLocation.ONSITE
        "
        class="detail-row">
        <span class="title">Online beitreten</span>
        <span class="value">{{ course.link ?? '-' }}</span>
      </div>
      <mat-divider *ngIf="!isStudent"></mat-divider>
      <div class="detail-row" *ngIf="!isStudent">
        <span class="title">Notizen für den Kurs</span>
        <span class="value">{{ course.internalNotes ?? '-' }}</span>
      </div>
    </div>
  </div>
</div>
