import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  Feature,
  FeaturePermission,
  Permission,
  Role,
} from '../models/permission.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  public permissionsChanged = new Subject<void>();
  constructor(private userService: UserService) {}

  public checkPermission(feature: Feature, permission: Permission): boolean {
    const featurePermissions = this.getRoleFeaturePermission();
    const featurePermission = featurePermissions.find(
      f => f.feature === feature
    );

    if (!!featurePermission) {
      switch (permission) {
        case Permission.VIEW:
          return featurePermission.permission !== Permission.NONE;
        case Permission.ADMIN:
          return featurePermission.permission === Permission.ADMIN;
      }
    }

    return false;
  }

  private getRoleFeaturePermission(): FeaturePermission[] {
    const user = this.userService.currentUser;
    let featurePermission = [];

    if (user.isSuperadmin) {
      return this.getSuperadminFeaturePermission();
    }

    switch (user.roleId) {
      case Role.STUDENT:
        featurePermission = this.getStudentFeaturePermission();
        break;
      case Role.ADMINISTRATOR:
        featurePermission = this.getAdministratorFeaturePermission();
        break;
      case Role.LECTURER:
        featurePermission = this.getLecturerFeaturePermission();
        break;
    }

    return featurePermission;
  }

  private getStudentFeaturePermission(): FeaturePermission[] {
    const studentFeaturePermission = [
      {
        feature: Feature.DASHBOARD,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.DB_MYSPACE,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.DB_BULLETIN_BOARD,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.DB_MESSAGES,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.DB_DOCUMENTS,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.DB_INSTITUTE_NEWS,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.DB_WIKI,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.EDUCATION,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.THEORETICAL_EDUCATION,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.PRACTICAL_WORK,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.AMBULATORY_PART,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.TREATMENT_CASE,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.SUPERVISION,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.SELF_AWARENESS,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.PRACTICAL_WORK_NAVIGATION,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.COOPERATION_PARTNER,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.ADVANCED_LECTURERS,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.ORGANIZATION,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.OVERVIEW_OF_FEES,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.EDUCATION_PROGRESS,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.INSTITUTE,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.PERSONAL_DATA,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.INITIAL_EDUCATION_STOCK,
        permission: Permission.ADMIN,
      },
    ];

    return studentFeaturePermission;
  }

  private getSuperadminFeaturePermission(): FeaturePermission[] {
    const superadminFeaturePermission = [
      {
        feature: Feature.PERSONAL_DATA,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.MODIFY_INSTITUTE,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.ADMINISTRATION,
        permission: Permission.ADMIN,
      },
    ];

    return superadminFeaturePermission;
  }

  private getAdministratorFeaturePermission(): FeaturePermission[] {
    const administratorFeaturePermission = [
      {
        feature: Feature.DASHBOARD,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.DB_MYSPACE,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.DB_BULLETIN_BOARD,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.DB_MESSAGES,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.DB_DOCUMENTS,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.DB_INSTITUTE_NEWS,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.DB_WIKI,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.EDUCATION,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.COURSE_ADMINISTRATION,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.ELOG,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.STUDENT_ADMINISTRATION,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.LECTURER_ADMINISTRATION,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.ROOM_PLANNING,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.PRACTICAL_WORK,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.TREATMENT_CASE,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.SUPERVISION,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.COOPERATION_PARTNER,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.ADVANCED_LECTURERS,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.ORGANIZATION,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.LABEL,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.ANALYTICS,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.BILLING,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.AUDIT_LOG,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.INSTITUTE,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.MODIFY_INSTITUTE,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.ROOM_ADMINISTRATION,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.PERSONAL_DATA,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.INITIAL_EDUCATION_STOCK,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.MANAGE_APPOINTMENTS,
        permission: Permission.ADMIN,
      },
    ];

    return administratorFeaturePermission;
  }

  private getLecturerFeaturePermission(): FeaturePermission[] {
    const lecturerFeaturePermission = [
      {
        feature: Feature.DASHBOARD,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.DB_MYSPACE,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.DB_BULLETIN_BOARD,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.DB_MESSAGES,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.DB_DOCUMENTS,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.DB_INSTITUTE_NEWS,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.DB_WIKI,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.EDUCATION,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.COURSE_ADMINISTRATION,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.ELOG,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.STUDENT_ADMINISTRATION,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.SUPERVISION,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.ROOM_PLANNING,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.COOPERATION_PARTNER,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.ADVANCED_LECTURERS,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.INSTITUTE,
        permission: Permission.VIEW,
      },
      {
        feature: Feature.MODIFY_INSTITUTE,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.ROOM_ADMINISTRATION,
        permission: Permission.ADMIN,
      },
      {
        feature: Feature.PERSONAL_DATA,
        permission: Permission.VIEW,
      },
    ];

    return lecturerFeaturePermission;
  }
}
