import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent {
  @Input() public formField: AbstractControl;

  constructor() {}

  ngOnInit(): void {}

  /**
   * hasErrors
   * checks if the formControl has errors
   * @param formControl formControl of the formGroup
   * @returns boolean
   */
  public hasErrors() {
    if (!this.formField) {
      return false;
    }

    return (
      this.formField.invalid &&
      this.formField.errors &&
      (this.formField.dirty || this.formField.touched)
    );
  }

  /**
   * getErrorMessage
   * returns the error message of the formControl
   * @returns string
   */
  public getErrorMessage() {
    if (this.formField && this.hasErrors()) {
      if (this.formField.hasError('required')) {
        return 'Dies ist ein Plichtfeld.';
      }

      if (this.formField.hasError('invalidChiffre')) {
        return 'Dies ist keine gültige Chiffre. Format: Anfangsbuchstabe \
        Familienname + 6 stellig Geb. Datum.';
      }

      if (this.formField.hasError('invalidIBAN')) {
        return 'Dies ist keine gültige IBAN.';
      }

      if (this.formField.hasError('invalidEmail')) {
        return 'Dies ist keine gültige E-Mail Adresse.';
      }

      if (this.formField.hasError('maxlength')) {
        return `Maximale Länge: ${this.formField.errors.maxlength.requiredLength} Zeichen.`;
      }

      if (this.formField.hasError('maxNumberLength')) {
        return `Maximale Länge: ${this.formField.errors.maxNumberLength.value} Zeichen.`;
      }

      if (this.formField.hasError('minlength')) {
        return `Minimale Länge: ${this.formField.errors.minlength.requiredLength} Zeichen.`;
      }

      if (this.formField.hasError('minNumberLength')) {
        return `Minimale Länge: ${this.formField.errors.minNumberLength.value} Zeichen.`;
      }

      if (this.formField.hasError('positiveNumbersOnly')) {
        return 'Nur positive Zahlen sind erlaubt.';
      }

      if (this.formField.hasError('notPositiveDecimalNumber')) {
        return 'Nur positive Zahlen sind erlaubt.';
      }

      if (this.formField.hasError('invalidHouseNumber')) {
        return 'Dies ist keine gültige Hausnummer.';
      }
      if (this.formField.hasError('userExists')) {
        return 'Der Benutzer existiert bereits.';
      }

      if (this.formField.hasError('invalidDateGroup')) {
        return 'Bitte Datum und Uhrzeit auswählen.';
      }

      if (this.formField.hasError('min')) {
        return `Mindestwert: ${this.formField.errors.min.min}`;
      }

      if (this.formField.hasError('max')) {
        return `Maximalwert: ${this.formField.errors.max.max}`;
      }

      if (this.formField.hasError('matEndDateInvalid')) {
        return 'Das Enddatum muss nach dem Startdatum liegen.';
      }

      if (this.formField.hasError('matStartDateInvalid')) {
        return 'Das Startdatum muss vor dem Enddatum liegen.';
      }

      if (this.formField.hasError('matDatepickerMin')) {
        return `Das Datum muss nach dem ${moment(this.formField.errors.matDatepickerMin.min).format('DD.MM.YYYY')} liegen.`;
      }

      if (this.formField.hasError('emailExistsInOtherInstitute')) {
        return 'Diese E-Mail wurde bereits an einem anderen Institut genutzt. Leider ist eine Einladung nicht möglich. Bitte fragen Sie eine andere E-Mail Adresse an. ';
      }

      if (this.formField.hasError('timeRangeInvalid')) {
        return `Die Endzeit darf nicht vor der Startzeit liegen.`;
      }

      if (this.formField.hasError('emailAlreadyRegistered')) {
        return `Ein Benutzer mit dieser E-Mail ist bereits registriert.`;
      }

      if (this.formField.hasError('invalidYear')) {
        return 'Bitte geben Sie ein gültiges Jahr ein.';
      }

      if (this.formField.hasError('invalidYearRange')) {
        return `Bitte geben Sie ein Jahr zwischen ${this.formField.errors.invalidYearRange.minYear} und ${this.formField.errors.invalidYearRange.maxYear} ein.`;
      }

      if (this.formField.hasError('invalidPhoneNumber')) {
        return 'Dies ist keine gültige Telefonnummer.';
      }

      if (this.formField.hasError('hasHtmlTags')) {
        return 'HTML Tags sind nicht erlaubt.';
      }

      if (this.formField.hasError('sumExceedsMax')) {
        return `Die Summe darf den Gesamtwert nicht überschreiten. Maximalwert: ${this.formField.errors.sumExceedsMax.maxValue}, Summe: ${this.formField.errors.sumExceedsMax.sum}.`;
      }
    }

    return '';
  }
}
