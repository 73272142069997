import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private overviewUrl: string;

  public setOverviewUrl(url: string): void {
    this.overviewUrl = url;
  }

  public getOverviewUrl(): string {
    return this.overviewUrl;
  }
}
