import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil } from 'rxjs';
import {
  ChangeLogBackendModel,
  ChangeLogModel,
} from 'src/app/models/change-log.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { ChangeLogService } from 'src/app/services/change-log.service';
import { getFullName } from 'src/app/utils/user.utils';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss'],
})
export class ChangeLogComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public filters: any;
  public availableFilters = {
    advancedFilters: null,
    defaultFilters: null,
  };
  public filterOpened: boolean = false;
  public filterEmpty: boolean = true;

  public searchForm: FormGroup = new FormGroup({
    searchText: new FormControl(''),
  });

  public displayedColumns = [
    'what',
    'onWhat',
    'oldValue',
    'newValue',
    'who',
    'date',
  ];
  public dataSource: MatTableDataSource<ChangeLogModel> =
    new MatTableDataSource<ChangeLogModel>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private destroy$: Subject<void> = new Subject<void>();

  public getFullName = getFullName;

  constructor(
    private changeLogService: ChangeLogService,
    private cancellationService: CancellationService,
    private alertService: AlertService
  ) {}

  public ngOnInit() {
    this.initTable();
  }

  /**
   * initializes the sorting, pagination and filtering of the table
   * inits the table data with the change logs of the current institute
   * @returns void
   */
  private initTable(): void {
    this.dataSource.filterPredicate = (data, filter) => {
      const dataStr =
        data.entityType +
        data.changeType +
        data.user?.name?.firstName +
        data.user?.name?.lastName +
        data.timeCreated;
      return dataStr.toLowerCase().indexOf(filter) != -1;
    };

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.getChangeLogs();
  }

  /**
   * retrieves all change logs of the current institute and initializes the table data
   * @returns void
   */
  private getChangeLogs(): void {
    this.changeLogService
      .getAllChangeLogs()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.dataSource.data = response.body
            ? await Promise.all(
                response.body?.map(
                  async (
                    auditLog: ChangeLogBackendModel
                  ): Promise<ChangeLogModel> => {
                    return await this.changeLogService.parseAuditLogObject(
                      auditLog
                    );
                  }
                )
              )
            : [];

          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Audit-Log Einträge konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * show or hide the change log details based on the current state
   * retrieves the change log details if they are not already present
   * @param changeLog The change log to show or hide
   * @returns void
   */
  public handleCollapseChangeLog(changeLog: ChangeLogModel): void {
    if (!changeLog.collapsed) {
      changeLog.collapsed = true;
    } else if (
      changeLog.collapsed &&
      (changeLog.oldValues === null ||
        changeLog.oldValues === undefined ||
        changeLog.oldValues?.length === 0) &&
      (changeLog.newValues === null ||
        changeLog.newValues === undefined ||
        changeLog.newValues?.length === 0)
    ) {
      this.getChangeLogDetails(changeLog);
    } else {
      changeLog.collapsed = false;
    }
  }

  /**
   * getChangeLogDetails
   * gets the details of the change log
   * @param changeLog
   * @returns void
   */
  public getChangeLogDetails(changeLog: ChangeLogModel): void {
    this.changeLogService
      .getChangeLogById(changeLog.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          const changeLog = await this.changeLogService.parseAuditLogObject(
            response.body
          );

          this.dataSource.data = this.dataSource.data.map(
            (log: ChangeLogModel) => {
              if (log.id === changeLog.id) {
                log = changeLog;
                log.collapsed = false;
              }
              return log;
            }
          );
        },
        error: error => {},
      });
  }

  /**
   * applyFilter
   * filters the data in the table
   * @param event
   * @returns void
   */
  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
