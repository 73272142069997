import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent {
  constructor(private dialog: MatDialog) {}

  /**
   * onOpenFeedbackPage
   * open feedback page
   * @returns void
   */
  public onOpenFeedbackPage(): void {
    this.dialog.open(FeedbackDialogComponent, {
      width: '900px',
    });
  }
}
