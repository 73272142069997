import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  Optional,
  Output,
  Renderer2,
  Self,
  SimpleChanges,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { map, startWith, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[custom-paginator]',
})
export class CustomPaginatorDirective implements AfterViewInit, OnDestroy {
  /**
   * custom emitter for parent component
   */
  @Output() pageIndexChangeEmitter: EventEmitter<number> =
    new EventEmitter<number>();

  /**
   * whether we want to display first/last button and dots
   */
  @Input() showFirstButton = true;
  @Input() showLastButton = true;

  /**
   * how many buttons to display before and after
   * the selected button
   */
  @Input() renderButtonsNumber = 2;

  /**
   * how many elements are in the table
   */
  @Input() appCustomLength: number = 0;

  /**
   * set true to hide left and right arrows surrounding the bubbles
   */
  @Input() hideDefaultArrows = false;

  /**
   * current page number to display
   */
  @Input() pageNumber: number = 0;

  /**
   * references to DOM elements
   */
  private dotsEndRef!: HTMLElement;
  private dotsStartRef!: HTMLElement;
  private bubbleContainerRef!: HTMLElement;

  // remember rendered buttons on UI that we can remove them when page index change
  private buttonsRef: HTMLElement[] = [];

  private neededButtons: number = 0;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Host() @Self() @Optional() private readonly matPag: MatPaginator,
    private elementRef: ElementRef,
    private ren: Renderer2
  ) {}

  public ngAfterViewInit(): void {
    this.styleDefaultPagination();
    this.createBubbleDivRef();
    this.renderButtons();
  }

  /**
   * react on parent component changing the appCustomLength - rerender buttons
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.['appCustomLength']?.firstChange) {
      // remove buttons before creating new ones
      this.removeButtons();
      this.renderButtons();
      // switch back to page 0
      this.switchPage(0);
    }
    if (
      changes['pageNumber'] &&
      changes['pageNumber'].currentValue !== undefined
    ) {
      this.switchPage(changes['pageNumber'].currentValue);
    }
  }

  private renderButtons(): void {
    this.buildButtons();

    // when pagination change -> change button styles
    this.matPag.page
      .pipe(
        map(e => [e.previousPageIndex ?? 0, e.pageIndex]),
        startWith([0, 0]),
        takeUntil(this.destroy$)
      )
      .subscribe(([prev, curr]) => {
        this.changeActiveButtonStyles(prev, curr);
      });
  }

  /**
   * change the active button style to the current one and display/hide additional buttons
   * based on the navigated index
   */
  private changeActiveButtonStyles(previousIndex: number, newIndex: number) {
    // if 1 or 0 button needed, render the button and add active class
    if (this.neededButtons <= 1) {
      const button = this.buttonsRef[0];
      this.ren.setStyle(button, 'display', 'flex');
      this.ren.addClass(button, 'g-bubble__active');
      return;
    }

    if (this.neededButtons < 5) {
      this.buttonsRef.forEach(button => {
        this.ren.setStyle(button, 'display', 'flex');
      });
    }

    const previouslyActive = this.buttonsRef[previousIndex];
    const currentActive = this.buttonsRef[newIndex];

    // remove active style from previously active button
    this.ren.removeClass(previouslyActive, 'g-bubble__active');

    // add active style to new active button
    this.ren.addClass(currentActive, 'g-bubble__active');

    // hide all buttons
    this.buttonsRef.forEach(button =>
      this.ren.setStyle(button, 'display', 'none')
    );

    // show N previous buttons and X next buttons
    const renderElements =
      this.neededButtons < 5 ? this.neededButtons : this.renderButtonsNumber;
    const endDots = newIndex < this.buttonsRef.length - renderElements - 1;
    const startDots = newIndex - renderElements > 0;

    const firstButton = this.buttonsRef[0];
    const lastButton = this.buttonsRef[this.buttonsRef.length - 1];

    // first bubble and dots
    if (this.showFirstButton) {
      // only render dots if needed buttons are 5 or higher
      if (this.neededButtons >= 5) {
        this.ren.setStyle(
          this.dotsStartRef,
          'display',
          startDots ? 'block' : 'none'
        );
      }
      this.ren.setStyle(firstButton, 'display', 'flex');
    }

    // last bubble and dots
    if (this.showLastButton && this.neededButtons > 1) {
      // only render dots if needed buttons are 5 or higher
      if (this.neededButtons >= 5) {
        this.ren.setStyle(
          this.dotsEndRef,
          'display',
          endDots ? 'block' : 'none'
        );
      }
      this.ren.setStyle(lastButton, 'display', 'flex');
    }

    // resolve starting and ending index to show buttons
    const startingIndex = startDots ? newIndex - renderElements : 0;

    const endingIndex = endDots
      ? newIndex + renderElements
      : this.buttonsRef.length - 1;

    // display starting buttons
    for (let i = startingIndex; i <= endingIndex; i++) {
      const button = this.buttonsRef[i];
      this.ren.setStyle(button, 'display', 'flex');
    }
  }

  /**
   * Removes or change styling of some html elements
   */
  private styleDefaultPagination() {
    const nativeElement = this.elementRef.nativeElement;
    const itemsPerPage = nativeElement.querySelector(
      '.mat-mdc-paginator-page-size'
    );
    const howManyDisplayedEl = nativeElement.querySelector(
      '.mat-mdc-paginator-range-label'
    );
    const previousButton = nativeElement.querySelector(
      'button.mat-mdc-paginator-navigation-previous'
    );
    const nextButtonDefault = nativeElement.querySelector(
      'button.mat-mdc-paginator-navigation-next'
    );

    this.changeDefaultButtonStyles(previousButton);
    this.changeDefaultButtonStyles(nextButtonDefault);

    // remove 'items per page'
    this.ren.setStyle(itemsPerPage, 'display', 'none');

    // style text of how many elements are currently displayed
    this.ren.setStyle(howManyDisplayedEl, 'position', 'absolute');
    this.ren.setStyle(howManyDisplayedEl, 'left', '0');
    this.ren.setStyle(howManyDisplayedEl, 'color', '#919191');
    this.ren.setStyle(howManyDisplayedEl, 'font-size', '14px');

    // check whether the user wants to remove left & right default arrow
    if (this.hideDefaultArrows) {
      this.ren.setStyle(previousButton, 'display', 'none');
      this.ren.setStyle(nextButtonDefault, 'display', 'none');
    }

    // set custom tooltip for next and previous button
    this.matPag._intl.nextPageLabel = 'Nächste Seite';
    this.matPag._intl.previousPageLabel = 'Vorherige Seite';
  }

  /**
   * Change the styles of the button to the default bubble style
   * @param button The button to change the style of
   * @returns void
   */
  private changeDefaultButtonStyles(button: Element): void {
    if (!button) {
      return;
    }
    const buttonTouchTarget: Element = button.querySelector(
      '.mat-mdc-button-touch-target'
    );
    const buttonRipple: Element = button.querySelector(
      '.mat-mdc-button-persistent-ripple'
    );

    const styles = {
      width: '34px',
      height: '34px',
      padding: '5px',
      margin: '0 4px',
    };

    // Apply styles to the button
    for (const [style, value] of Object.entries(styles)) {
      this.ren.setStyle(button, style, value);
      this.ren.addClass(button, 'g-bubble');
    }

    if (buttonTouchTarget) {
      this.ren.setStyle(buttonTouchTarget, 'width', styles.width);
      this.ren.setStyle(buttonTouchTarget, 'height', styles.height);
    }

    if (buttonRipple) {
      this.ren.setStyle(buttonRipple, 'opacity', '0');
    }
  }

  /**
   * creates `bubbleContainerRef` where all buttons will be rendered
   */
  private createBubbleDivRef(): void {
    const actionContainer = this.elementRef.nativeElement.querySelector(
      'div.mat-mdc-paginator-range-actions'
    );
    const nextButtonDefault = this.elementRef.nativeElement.querySelector(
      'button.mat-mdc-paginator-navigation-next'
    );

    // create a HTML element where all bubbles will be rendered
    this.bubbleContainerRef = this.ren.createElement('div') as HTMLElement;
    this.ren.addClass(this.bubbleContainerRef, 'g-bubble-container');

    // render element before the 'next button' is displayed
    this.ren.insertBefore(
      actionContainer,
      this.bubbleContainerRef,
      nextButtonDefault
    );
  }

  /**
   * helper function that builds all button and add dots
   * between the first button, the rest and the last button
   *
   * end result: (1) .... (4) (5) (6) ... (25)
   */
  private buildButtons(): void {
    this.neededButtons = Math.ceil(this.appCustomLength / this.matPag.pageSize);

    // if there is only one button needed, create one
    if (this.neededButtons <= 1) {
      this.ren.setStyle(this.elementRef.nativeElement, 'display', 'none');
      this.buttonsRef = [this.createButton(0)];
      return;
    }

    // Create buttons without dots if you have less then 5 pages
    if (this.neededButtons < 5) {
      for (let index = 0; index <= this.neededButtons - 1; index++) {
        this.buttonsRef = [...this.buttonsRef, this.createButton(index)];
      }
      return;
    }

    // create first button
    this.buttonsRef = [this.createButton(0)];

    // add dots (....) to UI
    this.dotsStartRef = this.createDotsElement();

    // create all buttons needed for navigation (except the first & last one)
    for (let index = 1; index < this.neededButtons - 1; index++) {
      this.buttonsRef = [...this.buttonsRef, this.createButton(index)];
    }

    // add dots (....) to UI
    this.dotsEndRef = this.createDotsElement();

    // create last button to UI after the dots (....)
    this.buttonsRef = [
      ...this.buttonsRef,
      this.createButton(this.neededButtons - 1),
    ];
  }

  /**
   * Remove all buttons / dots from DOM
   */
  private removeButtons(): void {
    // Remove all childs (buttons and dots) inside bubbleContainerRef
    while (this.bubbleContainerRef.firstChild) {
      this.bubbleContainerRef.removeChild(this.bubbleContainerRef.firstChild);
    }

    // Clear buttonsRef Array
    this.buttonsRef = [];
    this.buttonsRef.length = 0;
  }

  /**
   * create button HTML element
   */
  private createButton(i: number): HTMLElement {
    const bubbleButton = this.ren.createElement('div');
    const text = this.ren.createText(String(i + 1));

    // add class & text
    this.ren.addClass(bubbleButton, 'g-bubble');
    this.ren.setStyle(bubbleButton, 'margin', '0 4px');
    this.ren.appendChild(bubbleButton, text);

    // react on click
    this.ren.listen(bubbleButton, 'click', () => {
      this.switchPage(i);
    });

    // render on UI
    this.ren.appendChild(this.bubbleContainerRef, bubbleButton);

    // set style to hidden by default
    this.ren.setStyle(bubbleButton, 'display', 'none');

    return bubbleButton;
  }

  /**
   * helper function to create dots (....) on DOM indicating that there are
   * many more bubbles until the last one
   */
  private createDotsElement(): HTMLElement {
    const dotsEl = this.ren.createElement('span');
    const dotsText = this.ren.createText('...');

    // add class
    this.ren.setStyle(dotsEl, 'font-size', '18px');
    this.ren.setStyle(dotsEl, 'margin-right', '8px');
    this.ren.setStyle(dotsEl, 'padding-top', '6px');
    this.ren.setStyle(dotsEl, 'color', '#919191');

    // append text to element
    this.ren.appendChild(dotsEl, dotsText);

    // render dots to UI
    this.ren.appendChild(this.bubbleContainerRef, dotsEl);

    // set style none by default
    this.ren.setStyle(dotsEl, 'display', 'none');

    return dotsEl;
  }

  /**
   * Helper function to switch page
   */
  private switchPage(i: number): void {
    const previousPageIndex = this.matPag.pageIndex;
    this.matPag.pageIndex = i;
    this.matPag['_emitPageEvent'](previousPageIndex);

    this.pageIndexChangeEmitter.emit(i);
  }

  /**
   * unsubscribe from all subscriptions
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
