import { KeyValue, Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { EventLocation } from 'src/app/enums/event-location.enum';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { RecurrencePattern } from 'src/app/models/course.model';
import { EventDate } from 'src/app/models/event.model';
import {
  SupervisionAppointmentModel,
  SupervisionCreateModel,
  SupervisionEventDateCreateModel,
} from 'src/app/models/supervision.model';
import { TreatmentCaseModel } from 'src/app/models/treatment-case.model';
import {
  AdditionalQualificationEnum,
  UserModel,
} from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SupervisionService } from 'src/app/services/supervision.service';
import { TreatmentCaseService } from 'src/app/services/treatment-case.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import {
  getAllAdditionalQualifications,
  getFullName,
} from 'src/app/utils/user.utils';

@Component({
  selector: 'app-create-edit-supervision',
  templateUrl: './create-edit-supervision.component.html',
  styleUrl: './create-edit-supervision.component.scss',
})
export class CreateEditSupervisionComponent implements OnInit, OnDestroy {
  public currentAppointment?: SupervisionAppointmentModel;
  public editMode: boolean = false;
  public isLoading: boolean = true;
  public appointmentForm: FormGroup;
  public initialFormValues: any = {};
  public availableTreatmentCases: TreatmentCaseModel[] = [];
  public availableSupervisors: UserModel[] = [];
  public defaultSelectedSupervisors: UserModel[] = [];
  private studentId: number = this.userService.currentUser.id;

  // event picker
  public recurrencePattern: RecurrencePattern;
  public eventDates: EventDate[] = [];
  public startDate: any;
  public endDate: any;
  public allDayEvent: boolean = false;
  public formSubmitted: boolean = false;

  public roomPlanningOpen: boolean = false;
  public roomPlanningValid: boolean = true;
  public roomPlanningDisabled: boolean = false;
  public showLink: boolean = false;

  // import from utils
  public isRequired = isRequired;
  public getFullName = getFullName;
  public getAllAdditionalQualifications = getAllAdditionalQualifications;

  public EventLocation = EventLocation;

  private destroy$: Subject<void> = new Subject<void>();

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.appointmentForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private treatmentCaseService: TreatmentCaseService,
    private supervisionAppointmentService: SupervisionService,
    private userService: UserService,
    private cancellationService: CancellationService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    private dialog: MatDialog,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private location: Location,
    private loadingService: LoadingService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.createForm();

    if (this.activatedRoute.snapshot.params['appointmentId']) {
      this.editMode = true;
    }

    if (this.activatedRoute.snapshot.params['studentId']) {
      this.studentId = +atob(
        decodeURIComponent(this.activatedRoute.snapshot.params['studentId'])
      );
    }

    await Promise.all([
      this.initAvailableTreatmentCases(),
      this.initAvailableSupervisors(),
    ]);

    this.setSelectedTreatmentCase();

    if (this.editMode) {
      const supervisionAppointmentId = +atob(
        decodeURIComponent(this.activatedRoute.snapshot.params['appointmentId'])
      );
      this.initSupervisionAppointment(supervisionAppointmentId);
    } else {
      this.isLoading = false;
    }
  }

  /**
   * Create the form
   * @returns void
   */
  private createForm(): void {
    this.appointmentForm = this.formBuilder.group({
      supervisionsType: new FormControl('', Validators.required),
      supervisionAppointmentTreatmentCaseIds: new FormControl(
        [],
        Validators.required
      ),
      durationInTimeUnits: new FormControl(1, Validators.required),
      dateGroup: new FormControl(null),
      location: new FormControl(EventLocation.ONSITE),
      videoMeetingLink: new FormControl(null),
      supervisorId: new FormControl([], Validators.required),
      supervisorSearch: new FormControl(null),
      description: new FormControl(null, Validators.maxLength(6000)),
    });

    // react to location changes
    this.appointmentForm.controls['location'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(location => {
        if (location == EventLocation.ONLINE) {
          this.roomPlanningDisabled = true;
          this.roomPlanningValid = true;
          this.showLink = true;

          // set required validator for link if location is ONLINE
          this.appointmentForm
            .get('videoMeetingLink')
            .setValidators([Validators.required]);
          return;
        }

        if (location == EventLocation.ONSITE) {
          this.roomPlanningDisabled = false;
          this.showLink = false;

          // remove required validator for link if location is ONSITE
          this.appointmentForm.get('videoMeetingLink').clearValidators();
          this.appointmentForm.get('videoMeetingLink').updateValueAndValidity();
          return;
        }

        if (location == EventLocation.HYBRID) {
          this.roomPlanningDisabled = false;
          this.showLink = true;

          // set required validator for link if location is HYBRID
          this.appointmentForm
            .get('videoMeetingLink')
            .setValidators([Validators.required]);
          return;
        }
      });

    this.initialFormValues = this.appointmentForm.value;
  }

  /**
   * Init available treatment cases and supervisors for the select fields
   * @returns void
   */
  private async initAvailableTreatmentCases(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.isLoading = true;
      this.treatmentCaseService
        .getAllTreatmentCasesByInstituteIdAndStudentId(this.studentId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            this.availableTreatmentCases = response.body
              ? await Promise.all(
                  response.body.map(async (treatmentCaseData: any) => {
                    return this.treatmentCaseService.parseBackendTreatmentCase(
                      treatmentCaseData
                    );
                  })
                )
              : [];
            resolve();
          },
          error: () => {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Die Behandlungsfälle konnten nicht geladen werden!'
            );
            this.isLoading = false;
            reject();
          },
        });
    });
  }

  /**
   * init the available supervisors for the select field
   * @returns void
   */
  private async initAvailableSupervisors(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.userService
        .getInstituteUsersByAdditionalQualification(
          AdditionalQualificationEnum.SUPERVISOR
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            this.availableSupervisors = response.body
              ? await Promise.all(
                  response.body.map(
                    async (userData: any) =>
                      await this.userService.parseBackendUser(userData)
                  )
                )
              : [];
            resolve();
          },
          error: () => {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Die Supervisoren konnten nicht geladen werden!'
            );
            reject();
          },
        });
    });
  }

  /**
   * Sets the selected treatment case for the appointment.
   * @returns void
   */
  private setSelectedTreatmentCase(): void {
    if (!this.activatedRoute.snapshot.params['treatmentCaseId']) {
      return;
    }

    const treatmentCaseId = +atob(
      decodeURIComponent(this.activatedRoute.snapshot.params['treatmentCaseId'])
    );

    this.appointmentForm
      .get('supervisionAppointmentTreatmentCaseIds')
      .setValue([treatmentCaseId]);
  }

  /**
   * initalizes the supervision appointment to edit
   * @param appointmentId the appointment id
   * @returns void
   */
  private initSupervisionAppointment(appointmentId: number): void {
    // TODO: is currently not possible due to the design, will probably be added later
    // this.supervisionAppointmentService
    //   .getSupervisionAppointmentById(appointmentId)
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe({
    //     next: async response => {
    //       this.currentAppointment =
    //         await this.supervisionAppointmentService.parseBackendSupervisionAppointment(
    //           response.body
    //         );
    //       this.appointmentForm.patchValue({
    //         supervisionsType: this.currentAppointment.type,
    //         supervisionAppointmentTreatmentCaseIds:
    //           this.currentAppointment.treatmentCases.map(
    //             (treatmentCase: TreatmentCaseModel) => treatmentCase.id
    //           ),
    //         durationInTimeUnits: this.currentAppointment.durationInTimeUnits,
    //         location: this.currentAppointment.location,
    //         videoMeetingLink: this.currentAppointment.videoMeetingLink,
    //         description: this.currentAppointment.description,
    //       });
    //       this.eventDates = this.currentAppointment.eventDates;
    //       this.recurrencePattern = this.currentAppointment.recurrencePattern;
    //       // set start date to the earliest date and end date to the latest date
    //       this.startDate = this.eventDates.reduce((acc, date) => {
    //         return acc < date.startDate ? acc : date.startDate;
    //       }, this.eventDates[0].startDate);
    //       this.endDate = this.eventDates.reduce((acc, date) => {
    //         return acc > date.endDate ? acc : date.endDate;
    //       }, this.eventDates[0].endDate);
    //       this.cdr.detectChanges();
    //       this.initialFormValues = this.appointmentForm.value;
    //       this.isLoading = false;
    //     },
    //     error: () => {
    //       this.alertService.showErrorAlert(
    //         'Das hat leider nicht geklappt!',
    //         'Die Supervision konnte nicht geladen werden.'
    //       );
    //       this.onCancel();
    //     },
    //   });
  }

  /**
   * event dates changed from event picker
   * @param eventDates
   * @returns void
   */
  public onEventsChanged(eventDates: EventDate[]): void {
    this.eventDates = eventDates;
  }

  /**
   * dates changed from event picker
   * @param dates
   * @returns void
   */
  public onDatesChanged(dates: {
    start: Date;
    end: Date;
    allDayEvent: boolean;
  }): void {
    this.startDate = dates.start;
    this.endDate = dates.end;
    this.allDayEvent = dates.allDayEvent;
    this.cdr.detectChanges();
  }

  /**
   * setEventLocation
   * set the event location in the appointment form
   * @param location event location
   */
  public setEventLocation(location: EventLocation) {
    this.appointmentForm.get('location').setValue(location);
  }

  /**
   * open room planning
   * @returns void
   */
  public openRoomPlanning(): void {
    /* only open room planning if eventDates have been selected */
    if (this.eventDates.length > 0) {
      this.roomPlanningOpen = true;
    } else {
      this.alertService.showErrorAlert(
        'Fehler',
        'Bitte wählen Sie mindestens einen Termin aus!'
      );
    }
  }

  /**
   * room planning changed
   * @param eventDates
   * @returns void
   */
  public roomPlanningChanged(eventDates: EventDate[]): void {
    if (!eventDates) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Ungespeicherte Änderungen!',
          message:
            'Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren. \
              Möchten Sie die Seite trotzdem verlassen?',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.roomPlanningOpen = !this.roomPlanningOpen;
            return;
          }
        });
    } else {
      this.eventDates = eventDates;
      this.roomPlanningOpen = false;
    }
  }
  /**
   * adds or removes the clicked supervisor to the selected supervisors control
   * @param supervisor
   * @returns void
   */
  public onSupervisorSelected(supervisor: UserModel): void {
    if (this.appointmentForm.get('supervisorId').value == supervisor.id) {
      this.appointmentForm.get('supervisorId').setValue(null);
      return;
    }
    this.appointmentForm.get('supervisorId').setValue(supervisor.id);
  }

  /**
   * On cancel
   * @returns void
   */
  public onCancel(): void {
    this.location.back();
  }

  /**
   * Handles the form submission for creating or updating an appointment.
   * Clears the validators based on the appointment type.
   * If the form is valid and has changes it creates or updates the appointment.
   * @returns void
   */
  public onSubmit(): void {
    this.formSubmitted = true;

    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.appointmentForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.appointmentForm.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.loadingService.show();

    this.editMode
      ? this.updateSupervisionAppointment()
      : this.createSupervisionAppointment();
  }

  /**
   * create a supervision appointment
   * @returns Promise<void>
   */
  private async createSupervisionAppointment(): Promise<void> {
    const supervisionAppointment: SupervisionCreateModel = {
      type: this.appointmentForm.get('supervisionsType').value,
      dateSeriesStart: this.startDate,
      dateSeriesEnd: this.endDate,
      durationInTimeUnits: this.appointmentForm.get('durationInTimeUnits')
        .value,
      location: this.appointmentForm.get('location').value,
      videoMeetingLink: this.appointmentForm.get('videoMeetingLink').value,
      recurrencePattern: this.recurrencePattern,
      eventDates: this.eventDates.map(
        (eventDate: EventDate): SupervisionEventDateCreateModel => {
          return {
            type: this.appointmentForm.get('supervisionsType').value,
            startDate: eventDate.startDate,
            endDate: eventDate.endDate,
            roomId: eventDate.room?.id ?? null,
            treatmentCaseIds: this.appointmentForm.get(
              'supervisionAppointmentTreatmentCaseIds'
            ).value,
            supervisorId: this.appointmentForm.get('supervisorId').value,
            durationInTimeUnits: this.appointmentForm.get('durationInTimeUnits')
              .value,
            location: this.appointmentForm.get('location').value,
            videoMeetingLink:
              this.appointmentForm.get('videoMeetingLink').value,
          };
        }
      ),
      description: this.appointmentForm.get('description').value,
    };

    const createSupervisionAppointmentObservable =
      await this.supervisionAppointmentService.createSupervisionAppointment(
        supervisionAppointment
      );

    createSupervisionAppointmentObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Die Supervision wurde erfolgreich erstellt.'
          );
          this.initialFormValues = this.appointmentForm.value;
          this.onCancel();
          this.loadingService.hide();
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Supervision konnte nicht erstellt werden.'
          );
          this.loadingService.hide();
        },
      });
  }

  /**
   * update a supervision appointment
   * @returns Promise<void>
   */
  private async updateSupervisionAppointment(): Promise<void> {
    // TODO: is currently not possible due to the design, will probably be added later
    // const supervisionAppointment: SupervisionAppointmentUpdateModel = {
    //   type: this.appointmentForm.get('supervisionsType').value,
    //   dateSeriesStart: this.startDate,
    //   dateSeriesEnd: this.endDate,
    //   recurrencePattern: this.recurrencePattern,
    //   durationInTimeUnits: this.appointmentForm.get('durationInTimeUnits')
    //     .value,
    //   eventDates: this.eventDates.map(
    //     (eventDate: EventDate): SupervisionEventDateUpdateModel => {
    //       return {
    //         id: eventDate.id,
    //         type: this.appointmentForm.get('supervisionsType').value,
    //         startDate: eventDate.startDate,
    //         endDate: eventDate.endDate,
    //         roomId: eventDate.room?.id ?? null,
    //         treatmentCaseIds: this.appointmentForm.get(
    //           'supervisionAppointmentTreatmentCaseIds'
    //         ).value,
    //         supervisorId: this.appointmentForm.get('supervisorId').value,
    //         durationInTimeUnits: this.appointmentForm.get('durationInTimeUnits')
    //           .value,
    //         location: this.appointmentForm.get('location').value,
    //         videoMeetingLink:
    //           this.appointmentForm.get('videoMeetingLink').value,
    //       };
    //     }
    //   ),
    //   location: this.appointmentForm.get('location').value,
    //   videoMeetingLink: this.appointmentForm.get('videoMeetingLink').value,
    //   description: this.appointmentForm.get('description').value,
    // };
    // const updateSupervisionAppointmentObservable =
    //   await this.supervisionAppointmentService.updateSupervisionAppointment(
    //     this.currentAppointment.id,
    //     supervisionAppointment
    //   );
    // updateSupervisionAppointmentObservable
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe({
    //     next: () => {
    //       this.alertService.showSuccessAlert(
    //         'Das hat geklappt!',
    //         'Die Supervision wurde erfolgreich aktualisiert.'
    //       );
    //       this.initialFormValues = this.appointmentForm.value;
    //       this.onCancel();
    //       this.loadingService.hide();
    //     },
    //     error: () => {
    //       this.alertService.showErrorAlert(
    //         'Das hat leider nicht geklappt!',
    //         'Die Supervision konnte nicht aktualisiert werden.'
    //       );
    //       this.loadingService.hide();
    //     },
    //   });
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }

    return this.formDeactivateService.confirmDeactivation(
      this.appointmentForm.value,
      this.initialFormValues
    );
  }

  /**
   * Preserve the original enum order
   */
  public originalEventLocationOrder = (
    a: KeyValue<string, EventLocation>,
    b: KeyValue<string, EventLocation>
  ): number => {
    return 0;
  };

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
