import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform<T>(items: T[], searchString: string, searchAttr?: string): T[] {
    let newItems = [];
    if (!searchString) {
      return items;
    } else {
      searchString = searchString.toString().toLowerCase();
    }
    if (items.length === 0) {
      return null;
    }
    if (!searchAttr) {
      return items.filter(item => {
        for (let key of Object.keys(item)) {
          if (item[key] && typeof item[key] === 'object') {
            for (let subKey of Object.keys(item[key])) {
              if (
                item[key][subKey]
                  ?.toString()
                  .toLowerCase()
                  .includes(searchString)
              ) {
                return true;
              }
            }
          } else if (
            item[key]?.toString().toLowerCase().includes(searchString)
          ) {
            return true;
          }
        }
        return false;
      });
    } else {
      for (let item of items) {
        if (item[searchAttr]?.toString().toLowerCase().includes(searchString)) {
          newItems.push(item);
        }
      }
    }

    if (newItems) {
      return newItems;
    } else {
      return null;
    }
  }
}
