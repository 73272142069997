import { Filter, FilterType } from '../models/filter.model';

/**
 * hasActiveFilterValue
 * Check if filter has active value
 * @param filter
 * @param ignoreDatePicker
 * @returns boolean
 */
export function hasActiveFilterValue(
  filter: Filter[],
  ignoreDatePicker: boolean
): boolean {
  return filter.some((filter: Filter) => {
    if (filter.type === FilterType.DATE_RANGE && ignoreDatePicker) {
      return false;
    }
    return filter.value !== null;
  });
}
