import { InjectionToken } from '@angular/core';

export interface AppConfig {
  backendUrl: string;
  sessionTimeoutCookieName: string;
  telemetryDeckAppId: string;
  appTitle: string;
  faviconUrl: string;
  sentryEnvironment: string;
}

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');
