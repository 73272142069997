<div class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Nachrichten.</h1>
      <h3 class="hasomed-text-h3">Sehen Sie Ihre Nachrichten.</h3>
    </div>
  </div>

  <div class="main-content">
    <app-empty-state></app-empty-state>
  </div>
</div>
