import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { PatientAppointmentEventDateModel } from 'src/app/models/patient-appointment.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { PatientAppointmentService } from 'src/app/services/patient-appointment.service';
import { UserService } from 'src/app/services/user.service';
import { dateIsInFuture } from 'src/app/utils/date.utils';
import {
  getAllPatientEventDateAccompanyingPersons,
  getAllSupervisionAppointmentSupervisors,
} from 'src/app/utils/treatment-case.utils';

@Component({
  selector: 'app-patient-event-date-details',
  templateUrl: './patient-event-date-details.component.html',
  styleUrl: './patient-event-date-details.component.scss',
})
export class PatientEventDateDetailComponent implements OnInit, OnDestroy {
  public treatmentCaseId: number = 0;
  public appointmentId: number = 0;
  public currentEventDate?: PatientAppointmentEventDateModel;
  public isLoading: boolean = true;
  public isLecturer = this.userService.currentUserIsLecturer();

  private destroy$: Subject<void> = new Subject<void>();

  // import from utils
  public getAllSupervisionAppointmentSupervisors =
    getAllSupervisionAppointmentSupervisors;
  public getAllPatientEventDateAccompanyingPersons =
    getAllPatientEventDateAccompanyingPersons;
  public dateIsInFuture = dateIsInFuture;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private patientAppointmentService: PatientAppointmentService,
    private cancellationService: CancellationService,
    private alertService: AlertService,
    public clipboardService: ClipboardService
  ) {}

  public ngOnInit() {
    const params = this.activatedRoute.snapshot.params;
    const treatmentCaseId = +atob(decodeURIComponent(params.treatmentCaseId));
    const patientAppointmentId = +atob(
      decodeURIComponent(params.appointmentId)
    );
    const eventDateId = +atob(decodeURIComponent(params.eventDateId));
    this.initPatientAppointment(
      treatmentCaseId,
      patientAppointmentId,
      eventDateId
    );
  }

  /**
   * Initialize patient appointment
   * @param treatmentCaseId The treatment case id
   * @param patientAppointmentId The patient appointment id
   * @param eventDateId The event date id
   * @returns void
   */
  private initPatientAppointment(
    treatmentCaseId: number,
    patientAppointmentId: number,
    eventDateId: number
  ): void {
    this.patientAppointmentService
      .getPatientAppointmentEventDate(
        treatmentCaseId,
        patientAppointmentId,
        eventDateId
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.currentEventDate =
            await this.patientAppointmentService.parsePatientAppointmentEventDate(
              response.body
            );

          this.isLoading = false;
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Patiententermin konnte nicht geladen werden.'
          );
          this.onGoBack();
        },
      });
  }

  /**
   * navigate to edit patient appointment
   * @returns void
   */
  public onEditPatientAppointment(): void {
    this.router.navigate(
      [
        '../../../edit-appointment',
        btoa(String(this.currentEventDate.patientAppointmentId)),
      ],
      {
        relativeTo: this.activatedRoute,
      }
    );
  }

  /**
   * Go back to the previous page
   * @returns void
   */
  public onGoBack(): void {
    this.router.navigate(['../../'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
