import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { MobileUiService } from 'src/app/service/mobile-ui.service';

@Component({
  selector: 'app-ambulatory-part',
  templateUrl: './ambulatory-part.component.html',
  styleUrls: ['./ambulatory-part.component.scss'],
})
export class AmbulatoryPartComponent implements OnInit {
  public searchForm: FormGroup = new FormGroup({
    searchText: new FormControl(''),
  });
  public isLoading = true;
  public isMobile = false;
  public isTablet = false;
  public showSearchBar = false;

  public selectedTreatmentCase: any;
  public treatmentCases: any[] = [];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private mobileUiService: MobileUiService
  ) {}

  public ngOnInit(): void {
    this.viewChanges();
  }

  /**
   * sets isMobile and isTablet depending on the current view
   * sets showSearchBar to true if the current view is desktop
   * sets the displayed columns depending on the current view
   * @returns void
   */
  private viewChanges() {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.isMobile = currentView === 'mobile';
        this.isTablet = currentView === 'tablet';

        this.showSearchBar = currentView === 'desktop';
      });
  }

  public onCreateAppointment() {
    this.router.navigate(['create-appointment'], {
      relativeTo: this.activatedRoute,
    });
  }

  public onCreateTreatmentCase() {
    this.router.navigate(['create-treatment-case'], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * unsubscribe from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
