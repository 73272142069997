import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minNumberLength(minLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const valueLength = control.value?.toString().length;

    return valueLength >= minLength
      ? null
      : { minNumberLength: { value: minLength } };
  };
}
