import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'floatToHoursMinutes',
  standalone: true,
})
export class CustomTimePipe implements PipeTransform {
  transform(value: number): string {
    const timeInMinutes = value * 60;

    let hours = String(Math.floor(timeInMinutes / 60)).padStart(2, '0');
    let minutes = String(Math.floor(timeInMinutes % 60)).padStart(2, '0');

    return hours + ':' + minutes + ' Std.';
  }
}
