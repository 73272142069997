import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { first, Subject, takeUntil } from 'rxjs';
import { EmptyStateDialogComponent } from 'src/app/components/shared-components/empty-state-dialog/empty-state-dialog.component';
import { ImageCropperDialogComponent } from 'src/app/components/shared-components/image-cropper-dialog/image-cropper-dialog.component';
import { ImageDetailDialogComponent } from 'src/app/components/shared-components/image-detail-dialog/image-detail-dialog.component';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import {
  EducationCourse,
  ProfessionalAssociation,
} from 'src/app/models/education-course.model';
import { ExpertiseModel } from 'src/app/models/expertise.model';
import { FileModel } from 'src/app/models/file.model';
import { Label } from 'src/app/models/label.model';
import { Feature, Permission, Role } from 'src/app/models/permission.model';
import { ProcedureModel } from 'src/app/models/procedure.model';
import { SelectOption } from 'src/app/models/select-option.model';
import {
  AdditionalQualification,
  UserModel,
  UserUpdateModel,
} from 'src/app/models/user.model';
import { AddressService } from 'src/app/services/address.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { EducationCourseService } from 'src/app/services/education-course.service';
import { ExpertiseService } from 'src/app/services/expertise.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LabelService } from 'src/app/services/label.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ProcedureService } from 'src/app/services/procedure.service';
import { UserService } from 'src/app/services/user.service';
import { isRequired } from 'src/app/utils/form.utils';
import { getFullName, getUserIdFromParams } from 'src/app/utils/user.utils';
import { houseNumberValidator } from 'src/app/validators/house-number.validator';
import { ibanValidator } from 'src/app/validators/iban.validator';
import { maxNumberLength } from 'src/app/validators/max-number-length.validator';
import { minNumberLength } from 'src/app/validators/min-number-length.validator';
import { phoneNumberValidator } from 'src/app/validators/phone-number.validator';
import { positiveNumbersOnlyValidator } from 'src/app/validators/positive-numbers-only.validator';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrl: './edit-user.component.scss',
})
export class EditUserComponent implements OnInit, OnDestroy {
  public initialFormValues: {};
  public user: UserModel;
  public isLoading = true;
  public existingFiles: FileModel[] = [];
  public userForm: FormGroup;
  public datePickerStartDate = new Date(1990, 0, 1);
  public today = moment();
  public roleBasedLabel: string;

  public educationCourses: EducationCourse[];
  public availableEducationCourseSelectOptions: SelectOption[];
  public availableLabelSelectOptions: SelectOption[];
  public additionalQualifications: AdditionalQualification[];
  public expertises: ExpertiseModel[];
  public procedures: ProcedureModel[];

  /* for permission */
  public feature = Feature;
  public permission = Permission;
  public Role = Role;

  // import from utils
  public isRequired = isRequired;
  public getFullName = getFullName;

  private destroy$: Subject<void> = new Subject<void>();

  public allowedFileTypes: FileFormat[] = [
    { type: 'JPG', mimeType: 'image/jpg, image/jpeg' },
    { type: 'PNG', mimeType: 'image/png' },
    { type: 'GIF', mimeType: 'image/gif' },
    { type: 'WEBP', mimeType: 'image/webp' },
  ];

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.userForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private cancellationService: CancellationService,
    private alertService: AlertService,
    private formSubmitValidationService: FormSubmitValidationService,
    private location: Location,
    private educationCourseService: EducationCourseService,
    private formDeactivateService: FormDeactivateService,
    private labelService: LabelService,
    public addressService: AddressService,
    private procedureService: ProcedureService,
    private expertiseService: ExpertiseService,
    private loadingService: LoadingService
  ) {}

  public ngOnInit() {
    const userId =
      getUserIdFromParams(this.activatedRoute.parent) ??
      getUserIdFromParams(this.activatedRoute);

    // create form
    this.createForm();
    this.getLabels();
    this.getUser(userId);
  }

  /**
   * create the initial form
   * @returns void
   */
  private createForm(): void {
    this.userForm = this.formBuilder.group({
      genderTitle: new FormControl('', Validators.maxLength(25)),
      academicTitle: new FormControl('', Validators.maxLength(25)),
      firstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      email: new FormControl(''),
      birthdate: new FormControl(null),
      profilePicture: new FormControl(null),
      measlesProtection: new FormControl(null),
      street: new FormControl('', Validators.maxLength(255)),
      houseNumber: new FormControl('', [
        houseNumberValidator(),
        Validators.maxLength(6),
      ]),
      addressAddition: new FormControl('', Validators.maxLength(100)),
      zipCode: new FormControl('', [
        minNumberLength(5),
        maxNumberLength(5),
        positiveNumbersOnlyValidator(true),
      ]),
      city: new FormControl('', Validators.maxLength(255)),
      country: new FormControl('', Validators.maxLength(100)),
      documents: new FormControl(null),
      uploadedFiles: new FormControl(null),
      existingFiles: new FormControl(null),
      label: new FormControl(null),
      entryDate: new FormControl(null),
    });
  }

  /**
   * Getter for the gender title form control.
   * @returns The FormControl for the gender title field.
   */
  get genderTitleControl(): FormControl {
    return this.userForm.get('genderTitle') as FormControl;
  }

  /**
   * Getter for the academic title form control.
   * @returns The FormControl for the academic title field.
   */
  get academicTitleControl(): FormControl {
    return this.userForm.get('academicTitle') as FormControl;
  }

  /**
   * Getter for the label form control.
   * @returns The FormControl for the label field.
   */
  get labelControl(): FormControl {
    return this.userForm.get('label') as FormControl;
  }

  /**
   * Getter for the education course form control.
   * @returns The FormControl for the education course field.
   */
  get educationCourseControl(): FormControl {
    return this.userForm.get('educationCourse') as FormControl;
  }

  /**
   * Getter for the country form control.
   * @returns The FormControl for the country field.
   */
  get countryControl(): FormControl {
    return this.userForm.get('country') as FormControl;
  }

  /**
   * add the student form fields
   * @returns void
   */
  private initStudentForm(): void {
    this.userForm.addControl('educationCourse', new FormControl(null));
    this.userForm.addControl(
      'accountHolder',
      new FormControl(null, [Validators.maxLength(255)])
    );
    this.userForm.addControl('iban', new FormControl(null, [ibanValidator()]));
    this.userForm.addControl(
      'bic',
      new FormControl(null, [Validators.maxLength(11)])
    );
  }

  /**
   * add the lecturer form fields
   * @returns void
   */
  private initLecturerForm(): void {
    this.userForm.addControl('additionalQualifications', new FormControl(null));
    this.userForm.addControl('medicalAuthorizationOnly', new FormControl(null));
    this.userForm.addControl('professionalAssociation', new FormControl(null));
    this.userForm.addControl('expertises', new FormControl(null));
    this.userForm.addControl('procedures', new FormControl(null));
    this.userForm.addControl('ptkConsultation', new FormControl(null));
    this.userForm.addControl('ptkConsultationDate', new FormControl(null));
    this.userForm.addControl('confirmed', new FormControl(null));
    this.userForm.addControl('confirmationDate', new FormControl(null));
    this.userForm.addControl(
      'landlineNumber',
      new FormControl(null, [Validators.maxLength(20), phoneNumberValidator()])
    );
    this.userForm.addControl(
      'mobileNumber',
      new FormControl(null, [Validators.maxLength(20), phoneNumberValidator()])
    );
  }

  /**
   * retrieve user data from the backend
   * @param userId The id of the user
   * @returns void
   */
  private getUser(userId: number): void {
    this.userService
      .getInstituteUserById(userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.user = await this.userService.parseBackendUser(response.body);

          this.user.files?.forEach(file => {
            this.existingFiles.push(file);
          });

          // init form fields based on role
          switch (this.user.roleId) {
            case Role.STUDENT:
              this.roleBasedLabel = ' des Kandidaten';
              this.getEducationCourses();
              this.initStudentForm();
              break;
            case Role.LECTURER:
              this.roleBasedLabel = ' des Lehrpersonals';
              this.getAdditionalQualifications();
              this.getExpertises();
              this.getProcedures();
              this.initLecturerForm();
              break;
            case Role.ADMINISTRATOR:
              this.roleBasedLabel = ' des Verwalters';
              break;
            default:
              this.roleBasedLabel = '';
          }

          this.userForm.patchValue({
            genderTitle: this.user.name.genderTitle,
            academicTitle: this.user.name.academicTitle,
            firstName: this.user.name.firstName,
            lastName: this.user.name.lastName,
            email: this.user.email,
            birthdate: this.user.birthdate,
            profilePicture: this.user.profilePicture,
            educationCourse: this.user.educationCourse?.id,
            measlesProtection: this.user.measlesProtection,
            street: this.user.address?.street,
            houseNumber: this.user.address?.houseNumber,
            addressAddition: this.user.address?.addressAddition,
            zipCode: this.user.address?.zipCode,
            city: this.user.address?.city,
            country: this.user.address?.country,
            label: this.user.label?.id,
            entryDate: this.user.entryDate,
            accountHolder: this.user.bankAccount?.accountHolder,
            iban: this.user.bankAccount?.iban,
            bic: this.user.bankAccount?.bic,
            additionalQualifications: this.user.additionalQualifications?.map(
              (qualification: AdditionalQualification) => qualification.id
            ),
            medicalAuthorizationOnly: this.user.medicalAuthorizationOnly,
            professionalAssociation: this.user.professionalAssociation,
            expertises: this.user.expertises?.map(
              (expertise: ExpertiseModel) => expertise.id
            ),
            procedures: this.user.procedures?.map(
              (procedure: ProcedureModel) => procedure.id
            ),
            ptkConsultation: this.user.ptkConsultation,
            ptkConsultationDate: this.user.ptkConsultationDate,
            confirmed: this.user.confirmed,
            confirmationDate: this.user.confirmationDate,
            landlineNumber: this.user.landlineNumber,
            mobileNumber: this.user.mobileNumber,
          });

          this.initialFormValues = this.userForm.value;
          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Daten konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * getEducationCourses
   * Get the education courses of the institute
   * @returns void
   */
  private getEducationCourses(): void {
    this.educationCourseService
      .getInstituteEducationCourses()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.educationCourses = response.body?.map(
            (educationCourseData: any): EducationCourse => {
              return {
                id: educationCourseData.id,
                title: educationCourseData.title,
              };
            }
          );

          this.availableEducationCourseSelectOptions =
            this.educationCourses.map(
              (educationCourse: EducationCourse): SelectOption => {
                return {
                  value: educationCourse.id,
                  label: educationCourse.title,
                };
              }
            );
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Bildungsgänge konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * getLabels
   * Get the labels of the institute
   * @returns void
   */
  private getLabels(): void {
    this.labelService
      .getAllLabels()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          const labels = response.body
            ? await Promise.all(
                response.body?.map((label: Label) =>
                  this.labelService.parseBackendLabel(label)
                )
              )
            : [];

          this.availableLabelSelectOptions = labels.map(
            (label: Label): SelectOption => ({
              value: label.id,
              label: label.name,
            })
          );
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Etiketten konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * getAdditionalQualifications
   * Get the additional qualifications
   * @returns void
   */
  private getAdditionalQualifications(): void {
    this.userService
      .getUserAdditionalQualifications()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: result => {
          this.additionalQualifications = result.body;
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Zusatzqualifikationen konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * getExpertises
   * Get the expertises
   * @returns void
   */
  private getExpertises(): void {
    this.expertiseService
      .getAllExpertises()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: result => {
          this.expertises = result.body;
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Fachkunden konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * getProcedures
   * Get the procedures
   * @returns void
   */
  private getProcedures(): void {
    this.procedureService
      .getAllProcedures()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: result => {
          this.procedures = result.body;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Richtlinienverfahren konnten nicht geladen werden.'
          );
        },
      });
  }

  /**
   * return the professional association enum values as array
   */
  get filteredProfessionalAssociations() {
    return Object.keys(ProfessionalAssociation)
      .filter(
        key =>
          ProfessionalAssociation[
            key as keyof typeof ProfessionalAssociation
          ] !== ProfessionalAssociation.NONE
      )
      .map(key => ({
        key,
        value:
          ProfessionalAssociation[key as keyof typeof ProfessionalAssociation],
      }));
  }

  /**
   * changeArrayFormFieldValue
   * @param fieldValueId
   * @param fieldName
   * @returns void
   */
  public changeArrayFormFieldValue(
    fieldValueId: number | null,
    fieldName: string
  ): void {
    if (!fieldValueId) {
      this.userForm.get(fieldName).setValue([]);
      return;
    }
    if (this.userForm.get(fieldName).value.includes(fieldValueId)) {
      this.userForm
        .get(fieldName)
        .setValue(
          this.userForm
            .get(fieldName)
            .value.filter((id: number) => id !== fieldValueId)
        );
    } else {
      this.userForm
        .get(fieldName)
        .setValue(this.userForm.get(fieldName).value.concat(fieldValueId));
    }
  }

  /**
   * imageChangeEvent
   * @description open the image cropper dialog and save the result
   * @param event
   * @returns void
   */
  public imageChangeEvent(event: any): void {
    const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
      width: '500px',
      data: {
        image: event,
        title: 'Profilbild zuschneiden',
        round: true,
        height: 300,
        aspectRatio: 1,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result: any) => {
        if (result) {
          this.userForm.get('profilePicture').setValue(result);
        }
      });
  }

  /**
   * openProfilePictureDialog
   * opens the profile picture dialog
   * @returns void
   */
  public openProfilePictureDialog(): void {
    const dialogRef = this.dialog.open(ImageDetailDialogComponent, {
      data: {
        image: this.userForm.get('profilePicture').value,
        title: 'Profilbild des Benutzers',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result: any) => {
        if (result && result === 'delete') {
          this.userForm.get('profilePicture').setValue(null);
        }
      });
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.userForm.value,
      this.initialFormValues
    );
  }

  /**
   * onChangeInstitut
   * @description change the institute of the student
   * @returns void
   */
  public onChangeInstitut(): void {
    // Placeholder

    this.dialog.open(EmptyStateDialogComponent);
  }

  /**
   * Handles the form submission.
   * If the form is valid and has changes, the user object is built and the user is updated
   * @returns A promise that resolves to void
   */
  public async onSubmit(): Promise<void> {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.userForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.userForm.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.buildUser();

    /* reset initialFormValue to current value */
    this.initialFormValues = this.userForm.value;

    const userUpdateModel: UserUpdateModel = {
      labelId: this.userForm.value.label,
      educationCourseId: this.userForm.value.educationCourse,
      name: {
        genderTitle: this.userForm.value.genderTitle,
        academicTitle: this.userForm.value.academicTitle,
        firstName: this.userForm.value.firstName,
        lastName: this.userForm.value.lastName,
      },
      userIdentifier: this.user.userIdentifier,
      birthdate:
        this.user.birthdate && moment(this.user.birthdate).format('YYYY-MM-DD'),
      address: this.user.address,
      entryDate:
        this.user.entryDate && moment(this.user.entryDate).format('YYYY-MM-DD'),
      profilePicture: this.user.profilePicture,
      measlesProtection: this.user.measlesProtection,
      additionalQualificationIds:
        this.userForm.value.additionalQualifications ?? [],
      bankAccount: {
        accountHolder: this.userForm.value.accountHolder,
        iban: this.userForm.value.iban,
        bic: this.userForm.value.bic,
      },
      medicalAuthorizationOnly: this.userForm.value.medicalAuthorizationOnly,
      professionalAssociation: this.userForm.value.professionalAssociation,
      expertiseIds: this.userForm.value.expertises ?? [],
      procedureIds: this.userForm.value.procedures ?? [],
      ptkConsultation: this.userForm.value.ptkConsultation,
      ptkConsultationDate:
        this.userForm.value.ptkConsultationDate &&
        moment(this.userForm.value.ptkConsultationDate).format('YYYY-MM-DD'),
      confirmed: this.userForm.value.confirmed,
      confirmationDate:
        this.userForm.value.confirmationDate &&
        moment(this.userForm.value.confirmationDate).format('YYYY-MM-DD'),
      landlineNumber: this.userForm.value.landlineNumber,
      mobileNumber: this.userForm.value.mobileNumber,
      files: this.user.files,
    };

    this.loadingService.show();
    const updateUserObservable = await this.userService.updateUser(
      this.user.id,
      userUpdateModel
    );
    updateUserObservable.pipe(first()).subscribe({
      next: response => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          'Die Stammdaten wurden aktualisiert.'
        );
        this.onCancel();
        this.loadingService.hide();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Das hat leider nicht geklappt!',
          'Die Stammdaten konnten nicht gespeichert werden.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * buildUser
   * build the user object and create the userIdentifier
   * @returns void
   */
  public buildUser(): void {
    const files: FileModel[] = this.userForm.value.documents
      ? this.existingFiles.concat(this.userForm.value.documents)
      : this.existingFiles;

    this.user.email = this.userForm.value.email;
    this.user.name = {
      genderTitle: this.userForm.value.genderTitle,
      academicTitle: this.userForm.value.academicTitle,
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
    };
    this.user.birthdate = this.userForm.value.birthdate;
    this.user.entryDate = this.userForm.value.entryDate;
    this.user.profilePicture = this.userForm.value.profilePicture;
    this.user.address = {
      street: this.userForm.value.street,
      houseNumber: this.userForm.value.houseNumber,
      addressAddition: this.userForm.value.addressAddition,
      zipCode: this.userForm.value.zipCode,
      city: this.userForm.value.city,
      country: this.userForm.value.country,
    };
    this.user.educationCourse = this.educationCourses?.find(
      item => item.id === this.userForm.value.educationCourse
    );
    this.user.measlesProtection = this.userForm.value.measlesProtection;
    this.user.userIdentifier = this.userService.createUserIdentifier(this.user);
    this.user.files = files;
  }

  /**
   * onCancel
   * navigate back to the previous page
   * @returns void
   */
  public onCancel(): void {
    this.location.back();
  }

  /**
   * onOpenFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenFile(file: FileModel): void {
    this.userService.openFile(this.user?.id, file.id);
  }

  /**
   * onDownloadFile
   * download a file
   * @param file
   * @returns void
   */
  public onDownloadFile(file: FileModel): void {
    this.userService.downloadFile(this.user?.id, file.id);
  }

  /**
   * unsubscribe from all subscriptions and cancel all requests
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
