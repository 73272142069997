import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function sumNotExceedMax(
  maxControlName: string,
  firstControlName: string,
  secondControlName: string
): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const group = formGroup as FormGroup;
    const maxControl = group.get(maxControlName);
    const maxValue = maxControl?.value;
    const firstControl = group.get(firstControlName);
    const secondControl = group.get(secondControlName);

    if (
      maxValue == null ||
      firstControl?.value == null ||
      secondControl?.value == null
    ) {
      return null;
    }

    const sum = firstControl?.value + secondControl?.value;

    return sum <= maxValue ? null : { sumExceedsMax: { maxValue, sum } };
  };
}
