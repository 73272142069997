<div class="multiple-select-container">
  <ng-container [formArrayName]="arrayName">
    <ng-container *ngFor="let control of array.controls; let i = index">
      <div class="select-with-delete-btn">
        <eleguide-select
          [control]="getFormArrayControlAtIndex(i)"
          [controlId]="arrayName"
          [options]="options"
          [placeholder]="placeholder"
          [showDivider]="showDivider"
          [index]="i"
          (selectionChanged)="updateFormArrayControl($event)"></eleguide-select>
        <button
          [disabled]="array.length <= 1"
          (click)="removeFormArrayControl(i)"
          type="button"
          class="hasomed-text-button hasomed-button-white-grey">
          <i class="hasomed-button-icon-sm fa-light fa-trash-can"></i>
        </button>
      </div>
      <!-- Error Messages -->
      <app-error-message
        *ngIf="hasErrors(control)"
        [formField]="control"></app-error-message>
    </ng-container>
  </ng-container>
  <button
    [disabled]="array?.length === options?.length"
    (click)="addFormArrayControl()"
    type="button"
    class="hasomed-text-button hasomed-button-white-grey button-flex-start">
    <i class="fa-light fa-plus hasomed-button-icon"></i>
    <span class="hasomed-button-text">weitere hinzufügen</span>
  </button>
</div>
