import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { first, Subject, takeUntil } from 'rxjs';
import { CourseEvent, CourseModel } from 'src/app/models/course.model';
import { eLog, eLogStatus, eLogStatusCounts } from 'src/app/models/elog.model';
import { UserModel } from 'src/app/models/user.model';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CourseService } from 'src/app/services/course.service';
import { ElogService } from 'src/app/services/elog.service';
import { UserService } from 'src/app/services/user.service';
import {
  getCourseDateRange,
  getEducationCourseTitles,
} from 'src/app/utils/course.utils';
import { getParticipantAmount } from 'src/app/utils/event.utils';
import { getFullName, getFullNames } from 'src/app/utils/user.utils';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';

@Component({
  selector: 'app-elog',
  templateUrl: './elog.component.html',
  styleUrl: './elog.component.scss',
})
export class ElogComponent implements OnInit, OnDestroy {
  public courseIsLoading = true;
  public firstTableDataIsLoading = true;
  public course: CourseModel;
  public eLogStatusCounts: eLogStatusCounts;
  private eventId: number;
  private userId: number;
  public currentELogDataSourcePage: number = 0;
  public eventDataSource: MatTableDataSource<CourseEvent> =
    new MatTableDataSource<CourseEvent>();
  public eventColumnsToDisplay = [
    'date',
    'time',
    'room',
    'participantAmount',
    'eLog',
  ];
  public studentTableDataSource: MatTableDataSource<UserModel> =
    new MatTableDataSource<UserModel>();
  public studentColumnsToDisplay = [
    'name',
    'userIdentifier',
    'entryDate',
    'label',
    'eLog',
  ];
  public eLogTableDataIsLoading = true;
  public eLogDataSource: MatTableDataSource<eLog> = new MatTableDataSource();
  public eLogColumnsToDisplay = [
    'select',
    'studentName',
    'userIdentifier',
    'eLogStatus',
    'actions',
  ];
  public eLogStatus = eLogStatus;
  public isSetELogStatusDisabled: boolean = true;
  public setStatusTooltip: string = 'Anwesenheitsstatus setzen';
  public selectedCourseEvent: CourseEvent;
  public selectedCourseEventElogs: eLog[];
  public eLogs: eLog[];
  public selection: SelectionModel<eLog> = new SelectionModel<eLog>();
  public initialSelection: eLog[] = [];
  public showStudentTable: boolean = false;
  public showEventTable: boolean = false;

  // Helper functions from utils.ts
  public getFullNames = getFullNames;
  public getEducationCourseTitles = getEducationCourseTitles;
  public getParticipantAmount = getParticipantAmount;
  public getFullName = getFullName;
  public getCourseDateRange = getCourseDateRange;

  public searchForm = this.formBuilder.group({
    searchText: ['', noWhitespaceValidator],
  });

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public isMobile = false;
  public isTablet = false;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private courseService: CourseService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private elogService: ElogService,
    private userService: UserService,
    private cancellationService: CancellationService,
    private location: Location,
    private mobileUiService: MobileUiService
  ) {}

  public ngOnInit(): void {
    const courseId = +atob(
      this.activatedRoute.parent?.snapshot.paramMap.get('id')
    );
    this.eventId = +atob(this.activatedRoute.snapshot.paramMap.get('eventId'));
    this.userId = +atob(this.activatedRoute.snapshot.paramMap.get('userId'));
    this.getCourseDetails(courseId);

    if (this.eventId) {
      this.showEventTable = true;
      this.getCourseEventDates(courseId);
      this.getElogsByCourseEventId(this.eventId);

      this.eLogColumnsToDisplay = [
        'select',
        'studentName',
        'userIdentifier',
        'eLogStatus',
        'actions',
      ];
    }

    if (this.userId) {
      this.showStudentTable = true;
      this.getStudent(this.userId);
      this.getElogsByCourseIdAndUserId(courseId, this.userId);
    }

    const allowMultiSelect = true;
    this.selection = new SelectionModel<eLog>(
      allowMultiSelect,
      this.initialSelection
    );
    this.viewChanges();
    this.initTableColumns(this.mobileUiService.currentView$.value);
  }

  /**
   * subscribes to the currentView$ observable and updates the isMobile and isTablet properties
   * @returns void
   */
  private viewChanges(): void {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.initTableColumns(currentView);
        this.isMobile = currentView === 'mobile';
        this.isTablet = currentView === 'tablet';
      });
  }

  /**
   * initializes the table columns based on the current view and the route parameters
   * @param currentView the current view
   * @returns void
   * */
  private initTableColumns(currentView: string): void {
    if (currentView === 'mobile') {
      this.eventColumnsToDisplay = ['date', 'eLog'];
      this.studentColumnsToDisplay = ['name', 'eLog'];
      if (this.userId) {
        this.eLogColumnsToDisplay = ['select', 'date', 'eLogStatus', 'actions'];
      } else {
        this.eLogColumnsToDisplay = [
          'select',
          'studentName',
          'eLogStatus',
          'actions',
        ];
      }
    } else if (currentView === 'tablet') {
      this.eventColumnsToDisplay = ['date', 'time', 'room', 'eLog'];
      this.studentColumnsToDisplay = ['name', 'entryDate', 'label', 'eLog'];
      if (this.userId) {
        this.eLogColumnsToDisplay = [
          'select',
          'date',
          'time',
          'eLogStatus',
          'actions',
        ];
      } else {
        this.eLogColumnsToDisplay = [
          'select',
          'studentName',
          'eLogStatus',
          'actions',
        ];
      }
    } else {
      this.eventColumnsToDisplay = [
        'date',
        'time',
        'room',
        'participantAmount',
        'eLog',
      ];
      this.studentColumnsToDisplay = [
        'name',
        'userIdentifier',
        'entryDate',
        'label',
        'eLog',
      ];
      if (this.userId) {
        this.eLogColumnsToDisplay = [
          'select',
          'date',
          'time',
          'room',
          'eLogStatus',
          'actions',
        ];
      } else {
        this.eLogColumnsToDisplay = [
          'select',
          'studentName',
          'userIdentifier',
          'eLogStatus',
          'actions',
        ];
      }
    }
  }

  /**
   * getCourseDetails
   * loads the course details by the given course id
   * @param courseId
   * @returns void
   */
  private getCourseDetails(courseId: number): void {
    this.courseService
      .getCourseDetails(courseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.course = await this.courseService.parseBackendCourse(
            response.body
          );
          this.courseIsLoading = false;
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Kursdetails konnten nicht geladen werden.'
          );
          this.courseIsLoading = false;
        },
      });
  }

  /**
   * getCourseEventDates
   * loads the course with the given courseId
   * @param courseId
   * @returns void
   */
  private getCourseEventDates(courseId: number): void {
    this.courseService
      .getCourseEventDates(courseId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.selectedCourseEvent = response.body?.find(
            it => it.id === this.eventId
          );
          this.selectedCourseEvent.startDate = moment(
            this.selectedCourseEvent.startDate
          )
            .tz('Europe/Berlin')
            .toDate();
          this.selectedCourseEvent.endDate = moment(
            this.selectedCourseEvent.endDate
          )
            .tz('Europe/Berlin')
            .toDate();

          this.eventDataSource.data = [this.selectedCourseEvent];

          this.firstTableDataIsLoading = false;
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Kurstermine konnten nicht geladen werden.'
          );
          this.firstTableDataIsLoading = false;
        },
      });
  }

  /**
   * getElogsByCourseEventId
   * loads the eLogs by the given eventId
   * @param eventId
   */
  private getElogsByCourseEventId(eventId: number) {
    this.elogService
      .getAllCourseEventElogs(eventId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async result => {
          this.eLogs = result.body
            ? await Promise.all(
                result.body.map(async (eLog: eLog) => {
                  return await this.elogService.parseBackendElog(eLog);
                })
              )
            : [];
          this.eLogDataSource.data = this.eLogs;

          this.eLogDataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'studentName':
                return getFullName(item.user, false);
              case 'userIdentifier':
                return item.user.userIdentifier;
              default:
                return item[property];
            }
          };

          this.eLogDataSource.filterPredicate = (data, filter) => {
            const dataStr = getFullName(data.user, false);
            return dataStr.indexOf(filter) != -1;
          };
          this.eLogDataSource.sort = this.sort;
          this.eLogDataSource.paginator = this.paginator;
          this.eLogTableDataIsLoading = false;
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die eLogs konnten nicht geladen werden.'
          );
          this.eLogTableDataIsLoading = false;
        },
      });
  }

  /**
   * getStudent
   * loads the student by the given userId
   * @param userId
   * @returns void
   */
  private getStudent(userId: number): void {
    this.userService
      .getInstituteUserById(userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.studentTableDataSource.data = [
            await this.userService.parseBackendUser(response.body),
          ];

          this.firstTableDataIsLoading = false;
        },
        error: () => {
          this.firstTableDataIsLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Der Student konnte nicht geladen werden.'
          );
        },
      });
  }

  /**
   * getElogsByCourseIdAndUserId
   * loads the eLogs by the given courseId and userId
   * @param courseId
   * @param userId
   * @returns void
   */
  private getElogsByCourseIdAndUserId(courseId: number, userId: number): void {
    this.elogService
      .getElogsByCourseIdAndUserId(courseId, userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async result => {
          this.eLogs = result.body
            ? await Promise.all(
                result.body.map(async (eLog: eLog) => {
                  return await this.elogService.parseBackendElog(eLog);
                })
              )
            : [];
          this.eLogDataSource.data = this.eLogs;
          this.updateElogStatusCounts();

          this.eLogDataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'date':
                return item.courseEventDate?.startDate;
              case 'time':
                return moment(item.courseEventDate?.startDate).format('HH:mm');
              case 'room':
                return item.courseEventDate.room?.name;
              case 'eLogStatus':
                return item.status;
              default:
                return item[property];
            }
          };

          this.eLogDataSource.filterPredicate = (data, filter) => {
            const dataStr = data.status;
            return dataStr.indexOf(filter) != -1;
          };
          this.eLogDataSource.sort = this.sort;
          this.eLogDataSource.paginator = this.paginator;
          this.eLogTableDataIsLoading = false;

          this.jumpToPageWithClosestEvent();
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die eLogs konnten nicht geladen werden.'
          );
          this.eLogTableDataIsLoading = false;
        },
      });
  }

  /**
   * finds the closest event to today and jumps to the page where the event is located
   * @returns void
   */
  private jumpToPageWithClosestEvent(): void {
    // find date closest to today
    const closestEventIndex = this.eLogDataSource.data.findIndex(event =>
      moment(event.courseEventDate?.startDate).isAfter(moment())
    );

    if (closestEventIndex) {
      const pageIndex = Math.floor(
        closestEventIndex / this.eLogDataSource.paginator.pageSize
      );
      this.currentELogDataSourcePage = pageIndex;
    }
  }

  /**
   * updateElogStatusCounts
   * updates the eLogStatusCounts
   * @returns void
   */
  private updateElogStatusCounts(): void {
    this.eLogStatusCounts = {
      pending: this.eLogs.filter(it => it.status === eLogStatus.PENDING).length,
      checked: this.eLogs.filter(it => it.status === eLogStatus.CHECKED).length,
      absent: this.eLogs.filter(it => it.status === eLogStatus.ABSENT).length,
      excused: this.eLogs.filter(it => it.status === eLogStatus.EXCUSED).length,
      unexcused: this.eLogs.filter(it => it.status === eLogStatus.UNEXCUSED)
        .length,
      upcoming: this.eLogs.filter(it => it.status === eLogStatus.UPCOMING)
        .length,
    };
  }

  /**
   * applyFilter
   * Filters the table data by the given filterValue
   * @param event
   */
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.eLogDataSource.filter = filterValue.trim().toLowerCase();
    if (this.eLogDataSource.paginator) {
      this.eLogDataSource.paginator.firstPage();
    }
  }

  /**
   * onBackToOverview
   * navigates back to the previous page
   */
  public onBackToOverview() {
    this.location.back();
  }

  /**
   * isSingleELogStatusDisabled
   * returns true if the eLogStatus of the given eLog is disabled
   * @param eLogTableData eLogTableData
   * @returns boolean
   */
  public isSingleELogStatusDisabled(eLogTableData: eLog): boolean {
    return eLogTableData.status === eLogStatus.UPCOMING;
  }

  /**
   * getAvailableElogs
   * returns the available eLogStatus
   * @returns eLogStatus[]
   */
  public getAvailableElogs(): eLogStatus[] {
    let availableElogs: eLogStatus[] = [];
    availableElogs.push(eLogStatus.CHECKED);

    // if course is not mandatory, add absent to availableElogs
    if (!this.course?.mandatory) {
      availableElogs.push(eLogStatus.ABSENT);
    }

    availableElogs.push(
      eLogStatus.PENDING,
      eLogStatus.EXCUSED,
      eLogStatus.UNEXCUSED
    );
    return availableElogs;
  }

  /**
   * updateSelectedUsersELogStatus
   * sets the eLogStatus of the selected students to the given eLogStatus
   * @param elogStatus
   * @returns void
   */
  public updateSelectedUsersELogStatus(elogStatus: eLogStatus) {
    const selectedElogRows = this.selection.selected;

    // update eLogStatus of selected students
    selectedElogRows.forEach((selectedELog, index) => {
      if (index === selectedElogRows.length - 1) {
        this.updateSingleELogStatus(selectedELog, elogStatus);
      } else {
        this.updateSingleELogStatus(selectedELog, elogStatus, false);
      }
    });
  }

  /**
   * updateSingleELogStatus
   * updates the eLogStatus of the given eLog to the given eLogStatus
   * @param user User
   * @param eLogStatus eLogStatus
   * @returns void
   */
  public updateSingleELogStatus(
    eLog: eLog,
    newElogStatus: eLogStatus,
    updateTable: boolean = true
  ): void {
    // prevent setting eLogStatus if course event is in the future
    if (
      this.selectedCourseEvent?.startDate > new Date() ||
      eLog.courseEventDate?.startDate > new Date()
    ) {
      return;
    }

    const alertMessage = this.userId
      ? `den ${moment(eLog.courseEventDate.startDate).format('DD.MM.YYYY')}`
      : `'${getFullName(eLog.user)}'`;

    this.elogService
      .updateElog(eLog.id, newElogStatus)
      .pipe(first())
      .subscribe({
        next: result => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            `Der Anwesenheitsstatus für ${alertMessage} wurde erfolgreich geändert.`
          );

          // update eLogStatus in current eLog array
          this.eLogs.forEach(courseEventElog => {
            if (courseEventElog.id === eLog.id) {
              courseEventElog.status = newElogStatus;
            }
          });
          if (updateTable) {
            this.updateElogStatusCounts();
            this.updateELogTableData();
          }
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            `Der Anwesenheitsstatus für ${alertMessage} konnte nicht geändert werden.`
          );
        },
      });
  }

  /**
   * updateELogTableData
   * updates the eLog table data
   * @returns void
   */
  private updateELogTableData(): void {
    const previousSelection = this.selection.selected;

    this.eLogDataSource.data = this.eLogs;
    this.eLogDataSource.sort = this.sort;

    this.selection.clear();

    previousSelection.forEach(selectedELog => {
      this.selectRow(
        this.eLogDataSource.data.find(it => it.id === selectedELog.id)
      );
    });

    this.updateEventTableData();
    this.updateIsSetElogStatusDisabled();
  }

  /**
   * updateEventTableData
   * updates the data of the event table
   */
  private updateEventTableData(): void {
    this.eLogStatusCounts = {
      pending: this.eLogs.filter(it => it.status === eLogStatus.PENDING).length,
      checked: this.eLogs.filter(it => it.status === eLogStatus.CHECKED).length,
      absent: this.eLogs.filter(it => it.status === eLogStatus.ABSENT).length,
      excused: this.eLogs.filter(it => it.status === eLogStatus.EXCUSED).length,
      unexcused: this.eLogs.filter(it => it.status === eLogStatus.UNEXCUSED)
        .length,
      upcoming: this.eLogs.filter(it => it.status === eLogStatus.UPCOMING)
        .length,
    };
    this.eventDataSource.data = this.eventDataSource.data.map(event => {
      event.elogStatusCounts = this.eLogStatusCounts;
      return event;
    });
  }

  /**
   * updateIsSetElogStatusDisabled
   * updates the isSetELogStatusDisabled and setStatusTooltip
   * @returns void
   */
  private updateIsSetElogStatusDisabled() {
    if (this.selection.selected.length === 0) {
      this.isSetELogStatusDisabled = true;
      this.setStatusTooltip =
        'Bitte wählen Sie mindestens einen Kandidat*in aus';
      return;
    } else if (this.selectedCourseEvent?.startDate > new Date()) {
      this.isSetELogStatusDisabled = true;
      this.setStatusTooltip =
        'Anwesenheitsstatus kann erst nach stattfinden des Kurses gesetzt werden';
      return;
    } else {
      this.isSetELogStatusDisabled = false;
      this.setStatusTooltip = 'Anwesenheitsstatus setzen';
    }
  }

  /**
   * isAllSelected
   * Whether the number of selected elements matches the total number of rows
   * @returns boolean
   */
  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.eLogDataSource.data.length;
    return numSelected == numRows;
  }

  /**
   * toggleAllRows
   * Selects all rows if they are not all selected; otherwise clear selection
   * @returns void
   */
  public toggleAllRows(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.eLogDataSource.data.forEach(row => this.selection.select(row));
    this.updateIsSetElogStatusDisabled();
  }

  /**
   * selectRow
   * selects the given row
   * @param row
   * @returns void
   */
  public selectRow(row: eLog) {
    this.selection.toggle(row);
    this.updateIsSetElogStatusDisabled();
  }

  /**
   * cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
