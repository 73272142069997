import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function houseNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // Erlaubt Zahlen, optional gefolgt von einem Buchstaben (z.B. "10", "123B")
    // Leerzeichen sind optional
    if (!control.value) {
      return null;
    }

    const validHouseNumberPattern = /^\d+\s?[A-Za-z]?$/;
    const isValid = validHouseNumberPattern.test(control.value);
    return isValid ? null : { invalidHouseNumber: true };
  };
}
