import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs';
import { FeedbackModel } from 'src/app/models/feedback.model';
import { Role } from 'src/app/models/permission.model';
import { UserModel } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { FeedbackService } from 'src/app/services/feedback.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { InstituteService } from 'src/app/services/institute.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UserService } from 'src/app/services/user.service';
import { getFullName } from 'src/app/utils/user.utils';
import { NoHtmlTagsValidator } from 'src/app/validators/no-html-tags.validator';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent {
  private userAgent = navigator.userAgent;
  public contactForm: FormGroup = new FormGroup({
    reason: new FormControl('', [Validators.required]),
    message: new FormControl('', [Validators.required, NoHtmlTagsValidator()]),
  });

  public selectableReasons: string[] = [
    'Feedback',
    'Problem melden',
    'Kontaktanfrage',
    'Sonstiges',
  ];

  constructor(
    public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private feedbackService: FeedbackService,
    private formSubmitValidationService: FormSubmitValidationService,
    private userService: UserService,
    private instituteService: InstituteService,
    private alertService: AlertService,
    private loadingService: LoadingService
  ) {
    dialogRef.disableClose = true;
  }

  /**
   * validates the form and
   * sends the feedback to the server
   * @returns void
   */
  public onConfirm(): void {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.contactForm
      )
    ) {
      return;
    }

    const feedback: FeedbackModel = {
      subject: this.contactForm.controls['reason'].value,
      message: this.getMessage(),
    };

    this.loadingService.show();

    this.feedbackService
      .sendFeedback(feedback)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Ihr Anliegen wurde weitergeleitet.'
          );
          this.dialogRef.close(true);
          this.loadingService.hide();
        },
        error: () => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Ihr Anliegen konnte nicht übermittelt werden.'
          );
          this.loadingService.hide();
        },
      });
  }

  /**
   * closes the dialog
   * @returns void
   */
  public onCancel(): void {
    this.dialogRef.close(false);
  }

  /**
   * adds all client and user information to the message of the user
   * @returns the message with all necessary information
   */
  private getMessage(): string {
    var message = this.contactForm.controls['message'].value;
    message +=
      '\n\n\n' + 'Benutzer: ' + getFullName(this.userService.currentUser);
    message += '\n' + 'E-Mail: ' + this.userService.currentUser.email;
    if (!this.userService.currentUser.isSuperadmin) {
      message +=
        '\n' + 'Institut: ' + this.instituteService.currentInstitute.name;
    }
    message +=
      '\n' + 'Rolle: ' + this.getRoleName(this.userService.currentUser);
    message += '\n\n' + 'Aktuelle Url: ' + window.location.href;
    message += '\n\n' + 'Browserinformationen: ' + this.userAgent;

    return message;
  }

  /**
   * getRoleName
   * Get the role name based on the user
   * @returns void
   */
  public getRoleName(user: UserModel): string {
    if (user.isSuperadmin) {
      return 'Superadmin';
    }
    switch (user.roleId) {
      case Role.ADMINISTRATOR:
        return 'Verwaltung';
      case Role.LECTURER:
        return 'Lehrpersonal';
      case Role.STUDENT:
        return 'Kandidat';
      default:
        return '-';
    }
  }
}
