<div class="flex-c component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Audit-Log</h1>
      <h3 class="hasomed-text-h3">
        Bekommen Sie einen Einblick in den Audit-Log.
      </h3>
    </div>
    <form [formGroup]="searchForm" class="header-buttons">
      <div class="hasomed-search">
        <i class="fa-light fa-search hasomed-search-icon"></i>
        <input
          id="searchInput"
          class="hasomed-search-input"
          type="text"
          formControlName="searchText"
          placeholder="Suche"
          (keyup)="applyFilter($event)" />
      </div>
    </form>
  </div>
  <div class="main-content">
    <div
      [class.table-bicolor]="dataSource?.filteredData?.length > 1"
      class="table">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- what column -->
        <ng-container matColumnDef="what">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Was
            <app-custom-table-sort-icon [sort]="sort" columnName="what">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row; dataSource: dataSource">
            <div class="flex-c cell-overflow-hidden">
              <span class="hasomed-text-body">{{ row.changeType }}</span>
            </div>
          </td>
        </ng-container>

        <!-- onWhat column -->
        <ng-container matColumnDef="onWhat">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Woran
            <app-custom-table-sort-icon [sort]="sort" columnName="onWhat">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row; dataSource: dataSource">
            <div
              class="flex-c cell-overflow-hidden max-width-500"
              matTooltip="{{ row.entityType }} - {{ row.changeDescription }}">
              <span class="hasomed-text-body text-overflow-hidden">
                {{ row.entityType }} - {{ row.changeDescription }}
              </span>
            </div>
          </td>
        </ng-container>

        <!-- oldValue column -->
        <ng-container matColumnDef="oldValue">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Alter Wert</th>
          <td mat-cell *matCellDef="let row; dataSource: dataSource">
            <div class="collapsable">
              <div
                class="collapsable-trigger"
                (click)="handleCollapseChangeLog(row)">
                <i class="fa-light fa-chevron-down"></i>
                <span>
                  {{ row.collapsed ? 'Ausklappen' : 'Einklappen' }}
                </span>
              </div>
              <div
                class="flex-c collapsable-content gap-4"
                *ngIf="!row.collapsed">
                <span *ngFor="let item of row.oldValues">
                  {{ item.key }}:
                  {{
                    item.value && item.value !== '' ? item.value : 'Kein Wert'
                  }}
                </span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- newValue column -->
        <ng-container matColumnDef="newValue">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Neuer Wert</th>
          <td mat-cell *matCellDef="let row; dataSource: dataSource">
            <div class="collapsable">
              <div
                class="collapsable-trigger"
                (click)="handleCollapseChangeLog(row)">
                <i class="fa-light fa-chevron-down"></i>
                <span>
                  {{ row.collapsed ? 'Ausklappen' : 'Einklappen' }}
                </span>
              </div>
              <div
                class="flex-c collapsable-content gap-4"
                *ngIf="!row.collapsed">
                <span *ngFor="let item of row.newValues">
                  {{ item.key }}:
                  {{
                    item.value && item.value !== '' ? item.value : 'Kein Wert'
                  }}
                </span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- responsible column -->
        <ng-container matColumnDef="who">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Verantwortlicher
            <app-custom-table-sort-icon [sort]="sort" columnName="who">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row; dataSource: dataSource">
            {{ getFullName(row.user) }}
          </td>
        </ng-container>

        <!-- date column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Datum
            <app-custom-table-sort-icon [sort]="sort" columnName="date">
            </app-custom-table-sort-icon>
          </th>
          <td mat-cell *matCellDef="let row; dataSource: dataSource">
            {{ row.timeCreated | date: 'dd.MM.yyyy HH:mm' }} Uhr
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length">
            <div *ngIf="isLoading" class="loading-wrapper">
              <app-lottie-loading-spinner
                size="45px"></app-lottie-loading-spinner>
            </div>
            <div
              *ngIf="!isLoading"
              class="mat-cell table-no-match hasomed-text-body font-grey">
              Es wurde kein Audit-Log gefunden.
            </div>
          </td>
        </tr>
      </table>
      <mat-divider></mat-divider>
      <mat-paginator
        custom-paginator
        [appCustomLength]="dataSource.filteredData.length"
        [pageSize]="15"
        [renderButtonsNumber]="1"
        aria-label="Select page"></mat-paginator>
    </div>
    <div class="vertical-spacer"></div>
  </div>
</div>
