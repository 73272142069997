import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/shared-components/confirm-dialog/confirm-dialog.component';
import { LearningContentModel } from 'src/app/models/learning-content.model';
import { Feature, Permission } from 'src/app/models/permission.model';
import { MobileUiService } from 'src/app/service/mobile-ui.service';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { LearningContentService } from 'src/app/services/learning-content.service';
import { getFullName } from 'src/app/utils/user.utils';
import { noWhitespaceValidator } from 'src/app/validators/no-whitespace.validator';

@Component({
  selector: 'app-learning-contents',
  templateUrl: './learning-contents.component.html',
  styleUrl: './learning-contents.component.scss',
})
export class LearningContentsComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'name',
    'shortName',
    'timeCreated',
    'creator',
    'actions',
  ];
  public dataSource: MatTableDataSource<LearningContentModel> =
    new MatTableDataSource<LearningContentModel>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public isLoading = true;
  public isMobile = false;
  public isTablet = false;
  public showSearchBar = false;

  /* for permission */
  public feature = Feature;
  public permission = Permission;

  // import from utils
  public getFullName = getFullName;

  public searchForm = new FormGroup({
    searchText: new FormControl('', noWhitespaceValidator),
  });

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private learningContentService: LearningContentService,
    private alertService: AlertService,
    private cancellationService: CancellationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private mobileUiService: MobileUiService
  ) {}

  public ngOnInit() {
    this.initTable();
    this.viewChanges();
  }

  /**
   * sets isMobile and isTablet depending on the current view
   * sets showSearchBar to true if the current view is desktop
   * sets the displayed columns depending on the current view
   * @returns void
   */
  private viewChanges() {
    this.mobileUiService.currentView$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentView => {
        this.initTableColumns(currentView);

        this.isMobile = currentView === 'mobile';
        this.isTablet = currentView === 'tablet';

        this.showSearchBar = currentView === 'desktop';
      });
  }

  /**
   * set the columns for the table depending on the current view
   * @param currentView the current view
   * @returns void
   */
  private initTableColumns(currentView: string): void {
    if (currentView === 'mobile') {
      this.displayedColumns = ['name', 'actions'];
    } else if (currentView === 'tablet') {
      this.displayedColumns = ['name', 'shortName', 'actions'];
    } else {
      this.displayedColumns = [
        'name',
        'shortName',
        'timeCreated',
        'creator',
        'actions',
      ];
    }
  }

  /**
   * initializes the sorting and pagination of the table
   * inits the table data with the learning contents of the current institute
   * @returns void
   */
  private initTable(): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item.name;
        case 'shortName':
          return item.shortName;
        case 'timeCreated':
          return item.timeCreated;
        case 'creator':
          return (
            item.creator?.name?.firstName + ' ' + item.creator?.name?.lastName
          );
        default:
          return item[property];
      }
    };

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.getLearningContents();
  }

  /**
   * retrieves all learning contents of the current institute and initializes the table data
   * @returns void
   */
  private getLearningContents(): void {
    this.learningContentService
      .getInstituteLearningContents()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.dataSource.data = response.body;
          this.isLoading = false;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Die Lehrinhalte konnten nicht geladen werden. Bitte versuchen Sie es erneut.'
          );
        },
      });
  }

  /**
   * applySearch
   * apply search filter
   * @param event
   * @returns void
   */
  public applySearch(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * onCreate
   * navigate to create learning content page
   * @returns void
   */
  public onCreate(): void {
    this.router.navigate(['create'], { relativeTo: this.activatedRoute });
  }

  /**
   * onEdit
   * navigate to edit learning content page
   * @param learningContent
   * @returns void
   */
  public onEdit(learningContent: LearningContentModel): void {
    this.router.navigate(['edit', btoa(String(learningContent.id))], {
      relativeTo: this.activatedRoute,
    });
  }

  /**
   * onDelete
   * delete a learning content
   * @param learningContent
   * @returns void
   */
  public onDelete(learningContent: LearningContentModel): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '445px',
      data: {
        title: 'Lehrinhalt löschen',
        message:
          `Möchten Sie den Lehrinhalt '${learningContent.name}' wirklich löschen?` +
          (learningContent.isStandard
            ? ' \n\n Dieser Lehrinhalt ist aus der MWBO entnommen, es wird nicht empfohlen, ihn zu löschen.'
            : ''),
      },
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(dialogResult => {
        if (dialogResult) {
          this.learningContentService
            .deleteLearningContent(learningContent.id)
            .pipe(first())
            .subscribe({
              next: response => {
                this.alertService.showSuccessAlert(
                  'Das hat geklappt!',
                  'Der Lehrinhalt wurde gelöscht.'
                );
                this.dataSource.data = this.dataSource.data.filter(
                  item => item !== learningContent
                );
              },
              error: error => {
                this.alertService.showErrorAlert(
                  'Das hat leider nicht geklappt!',
                  'Der Lehrinhalt konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.'
                );
              },
            });
        }
      });
  }

  /**
   * unsubscribe from all subscriptions and cancel all requests
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
