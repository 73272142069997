import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectOption } from 'src/app/models/select-option.model';

@Component({
  selector: 'country-select',
  templateUrl: './country-select.component.html',
  styleUrl: './country-select.component.scss',
})
export class CountrySelectComponent {
  @Input() control: FormControl = new FormControl();
  public countries: SelectOption[] = [
    { value: 'Deutschland', label: 'Deutschland' },
    { value: 'Österreich', label: 'Österreich' },
    { value: 'Schweiz', label: 'Schweiz' },
  ];
}
