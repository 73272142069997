<div class="flex-c gap-8" [formGroup]="dateForm">
  <div class="field-row">
    <div class="field-label">Datum:</div>
    <mat-form-field
      class="hasomed-date-field"
      [class.hasomed-date-field-empty]="!dateForm?.get('date').value">
      <input
        class="hasomed-date-input"
        maxlength="10"
        [min]="minDate"
        (dateChange)="dateChanged($event)"
        matInput
        placeholder="Wählen Sie ein Datum"
        [matDatepicker]="picker"
        formControlName="date" />
      <mat-datepicker-toggle
        class="hasomed-date-toggle"
        matIconPrefix
        [for]="picker">
        <mat-icon
          matDatepickerToggleIcon
          class="calendar-toggle-icon fa-light fa-calendar">
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="field-row">
    <div class="field-label">Dauer:</div>
    <div class="time-picker">
      <input
        type="time"
        class="hasomed-time-input"
        (change)="startTimeChanged($event)"
        placeholder="hh:mm"
        formControlName="startTime" />
      <input
        type="time"
        class="hasomed-time-input"
        (change)="endTimeChanged($event)"
        formControlName="endTime" />
    </div>
  </div>
</div>
