<div class="single-file">
  <div class="single-file-content">
    <div class="file-icon">
      <i class="fa-light fa-file"></i>
    </div>
    <div class="file-info">
      <div class="text-info">
        <div class="hasomed-text-body file-name">
          <span>{{ file?.name }}</span>
          <div class="flex-r gap-8">
            <div
              (click)="onOpen(file)"
              class="icon-button"
              matTooltip="Datei öffnen">
              <i class="fa-light fa-arrow-up-right-from-square icon-sm"></i>
            </div>
            <div
              (click)="onDownload(file)"
              class="icon-button"
              matTooltip="Datei herunterladen">
              <i class="fa-light fa-cloud-arrow-down icon-sm"></i>
            </div>
            <div
              *ngIf="deleteEnabled"
              (click)="onDelete(file)"
              class="icon-button"
              matTooltip="Datei löschen">
              <i class="fa-light fa-trash-can icon-sm"></i>
            </div>
          </div>
        </div>
        <div class="hasomed-text-small">
          <span>
            {{ formatBytes(file?.size, 2) }}
          </span>
        </div>
      </div>
      <div class="progress-info">
        <div class="progress-bar">
          <app-progress-bar [progress]="100"></app-progress-bar>
        </div>
        <div class="hasomed-text-small">
          <span>{{ 100 }}%</span>
        </div>
      </div>
    </div>
  </div>
</div>
