import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, first, takeUntil } from 'rxjs';
import { FileFormat } from 'src/app/components/shared-components/upload-area-dnd/upload-area-dnd.component';
import { practicalWorkTypeSelectOptions } from 'src/app/constants/practical-work-type.constant';
import { CanDeactivateType } from 'src/app/guards/form.guard';
import { CooperationPartnerModel } from 'src/app/models/cooperation-partner.model';
import { FileModel } from 'src/app/models/file.model';
import {
  PracticalWorkCreateModel,
  PracticalWorkModel,
  PracticalWorkUpdateModel,
} from 'src/app/models/practical-work.model';
import { SelectOption } from 'src/app/models/select-option.model';
import { AlertService } from 'src/app/services/alert.service';
import { CancellationService } from 'src/app/services/cancellation.service';
import { CooperationPartnerService } from 'src/app/services/cooperation-partner.service';
import { FileService } from 'src/app/services/file.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PracticalWorkService } from 'src/app/services/practical-work.service';
import { isRequired } from 'src/app/utils/form.utils';

@Component({
  selector: 'app-create-edit-practical-work',
  templateUrl: './create-edit-practical-work.component.html',
  styleUrls: ['./create-edit-practical-work.component.scss'],
})
export class CreateEditPracticalWorkComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public editMode = false;
  public currentPracticalWork?: PracticalWorkModel;
  public practicalWorkForm: FormGroup;
  public initialFormValues: {};
  public availableCooperationPartners: SelectOption[] = [];
  public uploadedFiles: FileModel[] = [];
  public existingFiles: FileModel[] = [];
  public minDate: Date = new Date(1960, 0, 1);
  public allowedFileTypesDocuments: FileFormat[] = [
    { type: 'PDF', mimeType: 'application/pdf' },
  ];

  // import from form.utils.ts
  public isRequired = isRequired;

  private destroy$: Subject<void> = new Subject<void>();

  public typeSelectOptions: SelectOption[] = practicalWorkTypeSelectOptions;

  /* add window.onbeforeunload to warn the user if the form has unsaved changes */
  @HostListener('window:beforeunload', ['$event'])
  public reloadNotification($event: any): void {
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.practicalWorkForm.value,
        this.initialFormValues
      )
    ) {
      $event.returnValue =
        'Es gibt ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren.';
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private practicalWorkService: PracticalWorkService,
    private cooperationPartnerService: CooperationPartnerService,
    private alertService: AlertService,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private cancellationService: CancellationService,
    private loadingService: LoadingService,
    private fileService: FileService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.createForm();
    await this.initializeAvailableCooperationPartners();

    if (this.activatedRoute.snapshot.params['id']) {
      this.editMode = true;
      const practicalWorkId = +atob(this.activatedRoute.snapshot.params['id']);
      this.initializePracticalWork(practicalWorkId);
    }

    this.initialFormValues = this.practicalWorkForm.value;
    this.isLoading = false;

    // subscribe to uploadedFiles changes and convert them to base64
    this.practicalWorkForm
      .get('uploadedFiles')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (!value || value.length === 0) {
          this.practicalWorkForm.get('documents').setValue(null);
          return;
        }

        this.fileService.handleFileUpload(
          value,
          this.practicalWorkForm.get('documents'),
          false,
          true
        );
      });
  }

  /**
   * initializePracticalWork
   * Initializes the practical work by id
   * @param practicalWorkId The id of the practical work
   * @returns void
   */
  private initializePracticalWork(practicalWorkId: number): void {
    this.isLoading = true;
    this.practicalWorkService
      .getPracticalWorkById(practicalWorkId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          this.currentPracticalWork = response.body
            ? await this.practicalWorkService.parsePracticalWork(response.body)
            : null;

          if (!this.currentPracticalWork) {
            this.alertService.showErrorAlert(
              'Das hat leider nicht geklappt!',
              'Die Praktische Tätigkeit konnte nicht geladen werden.'
            );
            this.onCancel();
            return;
          }

          this.currentPracticalWork.files?.forEach(file => {
            this.existingFiles.push(file);
          });

          this.practicalWorkForm.patchValue({
            type: this.currentPracticalWork.type,
            cooperationPartnerId:
              this.currentPracticalWork.cooperationPartner.id,
            duration: this.currentPracticalWork.duration,
            dateRange: {
              startDate: this.currentPracticalWork.startDate,
              endDate: this.currentPracticalWork.endDate,
            },
            description: this.currentPracticalWork.description,
          });

          this.isLoading = false;
          this.initialFormValues = this.practicalWorkForm.value;
        },
        error: error => {
          this.alertService.showErrorAlert(
            'Das hat nicht geklappt!',
            'Beim Laden der Praktischen Tätigkeit ist ein Fehler aufgetreten.'
          );
          this.onCancel();
        },
      });
  }

  /**
   * createForm
   * Creates the form for the practical work
   * @returns void
   */
  private createForm(): void {
    this.practicalWorkForm = new FormGroup({
      type: new FormControl(null, Validators.required),
      cooperationPartnerId: new FormControl(null, Validators.required),
      description: new FormControl('', Validators.maxLength(6000)),
      duration: new FormControl(null, [
        Validators.required,
        Validators.min(0),
        Validators.max(10000),
      ]),
      dateRange: new FormGroup(
        {
          startDate: new FormControl(null, Validators.required),
          endDate: new FormControl(null, Validators.required),
        },
        Validators.required
      ),
      documents: new FormControl(null),
      uploadedFiles: new FormControl(null),
      existingFiles: new FormControl(null),
    });
  }

  /**
   * Returns the FormControl for the 'type' field in the practicalWorkForm.
   * @returns The FormControl for the 'type' field.
   */
  get typeControl(): FormControl {
    return this.practicalWorkForm.get('type') as FormControl;
  }

  /**
   * Returns the FormControl for the 'cooperationPartnerId' field in the practicalWorkForm.
   * @returns The FormControl for the 'cooperationPartnerId' field.
   */
  get cooperationPartnerControl(): FormControl {
    return this.practicalWorkForm.get('cooperationPartnerId') as FormControl;
  }

  /**
   * initializeAvailableCooperationPartners
   * Initializes the available cooperation partners for the select input
   * @returns Promise<void>
   */
  private async initializeAvailableCooperationPartners(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.cooperationPartnerService
        .getAllCooperationPartners()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: async response => {
            const cooperationPartners: CooperationPartnerModel[] = response.body
              ? await Promise.all(
                  response.body.map(
                    async (cooperationPartner: CooperationPartnerModel) => {
                      return this.cooperationPartnerService.parseBackendCooperationPartner(
                        cooperationPartner
                      );
                    }
                  )
                )
              : [];

            this.availableCooperationPartners = cooperationPartners.map(
              (cooperationPartner: CooperationPartnerModel): SelectOption => {
                return {
                  value: cooperationPartner.id,
                  label: cooperationPartner.name,
                };
              }
            );
            resolve();
          },
          error: error => {
            reject();
          },
        });
    });
  }

  /**
   * Handles the form submission.
   * If the form is valid and has changes, it creates or updates the practical work.
   * @returns void
   */
  public onSubmit(): void {
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.practicalWorkForm
      )
    ) {
      return;
    }

    if (
      !this.formDeactivateService.hasUnsavedChanges(
        this.practicalWorkForm.value,
        this.initialFormValues
      )
    ) {
      this.alertService.showSuccessAlert(
        'Gespeichert.',
        'Ihre Angaben wurden gespeichert.'
      );
      this.onCancel();
      return;
    }

    this.loadingService.show();
    this.editMode ? this.updatePracticalWork() : this.createPracticalWork();
  }

  /**
   * createPracticalWork
   * Creates a new practical work
   * @returns Promise<void>
   */
  private async createPracticalWork(): Promise<void> {
    const files: FileModel[] = this.practicalWorkForm.value.documents
      ? this.existingFiles.concat(this.practicalWorkForm.value.documents)
      : this.existingFiles;

    const practicalWork: PracticalWorkCreateModel = {
      type: this.practicalWorkForm.get('type').value,
      cooperationPartnerId: this.practicalWorkForm.get('cooperationPartnerId')
        .value,
      duration: this.practicalWorkForm.get('duration').value,
      startDate: moment(
        this.practicalWorkForm.get('dateRange').get('startDate').value
      ).format('YYYY-MM-DD'),
      endDate: moment(
        this.practicalWorkForm.get('dateRange').get('endDate').value
      ).format('YYYY-MM-DD'),
      description: this.practicalWorkForm.get('description').value,
      files: files,
    };

    const createPracticalWorkObservable =
      await this.practicalWorkService.createPracticalWork(practicalWork);

    createPracticalWorkObservable.pipe(first()).subscribe({
      next: response => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          'Die Praktische Tätigkeit wurde gespeichert.'
        );
        this.initialFormValues = this.practicalWorkForm.value;
        this.onCancel();
        this.loadingService.hide();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Das hat nicht geklappt!',
          'Beim Erstellen der Praktischen Tätigkeit ist ein Fehler aufgetreten.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * updatePracticalWork
   * Updates an existing practical work
   * @returns Promise<void>
   */
  private async updatePracticalWork(): Promise<void> {
    const practicalWorkId = this.currentPracticalWork?.id;
    const files: FileModel[] = this.practicalWorkForm.value.documents
      ? this.existingFiles.concat(this.practicalWorkForm.value.documents)
      : this.existingFiles;
    const practicalWork: PracticalWorkUpdateModel = {
      type: this.practicalWorkForm.get('type').value,
      cooperationPartnerId: this.practicalWorkForm.get('cooperationPartnerId')
        .value,
      duration: this.practicalWorkForm.get('duration').value,
      startDate: moment(
        this.practicalWorkForm.get('dateRange').get('startDate').value
      ).format('YYYY-MM-DD'),
      endDate: moment(
        this.practicalWorkForm.get('dateRange').get('endDate').value
      ).format('YYYY-MM-DD'),
      description: this.practicalWorkForm.get('description').value,
      files: files,
    };

    const updatePracticalWorkObservable =
      await this.practicalWorkService.updatePracticalWork(
        practicalWorkId,
        practicalWork
      );

    updatePracticalWorkObservable.pipe(first()).subscribe({
      next: response => {
        this.alertService.showSuccessAlert(
          'Das hat geklappt!',
          'Die Praktische Tätigkeit wurde erfolgreich aktualisiert.'
        );
        this.initialFormValues = this.practicalWorkForm.value;
        this.onCancel();
        this.loadingService.hide();
      },
      error: error => {
        this.alertService.showErrorAlert(
          'Das hat nicht geklappt!',
          'Beim Aktualisieren der Praktischen Tätigkeit ist ein Fehler aufgetreten.'
        );
        this.loadingService.hide();
      },
    });
  }

  /**
   * onCancel
   * Navigates back to the previous page
   * @returns void
   */
  public onCancel(): void {
    if (this.editMode) {
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../'], { relativeTo: this.activatedRoute });
    }
  }

  /**
   * onOpenExistingFile
   * open the file
   * @param file
   * @returns void
   */
  public onOpenExistingFile(file: FileModel): void {
    this.practicalWorkService.openFile(this.currentPracticalWork?.id, file.id);
  }

  /**
   * onDownloadExistingFile
   * download a file
   * @param file
   * @returns void
   */
  public onDownloadExistingFile(file: FileModel): void {
    this.practicalWorkService.downloadFile(
      this.currentPracticalWork?.id,
      file.id
    );
  }

  /**
   * onDeleteExistingFile
   * @param file
   * @returns void
   */
  public onDeleteExistingFile(file: FileModel): void {
    const index = this.existingFiles.indexOf(file);
    if (index > -1) {
      this.existingFiles.splice(index, 1);
    }
    this.practicalWorkForm.get('existingFiles').setValue(this.existingFiles);
  }

  /**
   * canDeactivate
   * checks if the form has unsaved changes amd asks the user if he wants to leave the page
   * @returns CanDeactivateType
   */
  public canDeactivate(): CanDeactivateType {
    if (this.isLoading) {
      return true;
    }
    return this.formDeactivateService.confirmDeactivation(
      this.practicalWorkForm.value,
      this.initialFormValues
    );
  }

  /**
   * Cancels all requests and unsubscribes from all subscriptions
   * @returns void
   */
  public ngOnDestroy(): void {
    this.cancellationService.cancelAllRequests();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
