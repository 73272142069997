import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { WeekdayEnum, Weekdays } from 'src/app/enums/weekday.enum';
import { OpeningHourModel } from 'src/app/models/opening-hour.model';
import {
  getGermanWeekday,
  getWeekDayAbbreviation,
} from 'src/app/utils/date.utils';
import { hasErrors } from 'src/app/utils/form.utils';
import {
  sortOpeningHoursByDay,
  sortOpeningHoursFormArrayByWeekday,
} from 'src/app/utils/opening-hour.utils';
import { timeRangeValidator } from 'src/app/validators/time-range.validator';

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrl: './opening-hours.component.scss',
})
export class OpeningHoursComponent implements OnInit {
  @Input() openingHours: OpeningHourModel[] = [];
  @Input() parentFormGroup: FormGroup = new FormGroup({});
  @Input() editMode: boolean = false;
  @Output() openingHoursChange: EventEmitter<OpeningHourModel[]> =
    new EventEmitter<OpeningHourModel[]>();
  @Output() openingHoursInitialized: EventEmitter<void> =
    new EventEmitter<void>();

  public Weekdays = Weekdays;
  public hasErrors = hasErrors;
  public getWeekdayAbbreviation = getWeekDayAbbreviation;
  public getGermanWeekday = getGermanWeekday;
  public openingHoursForm: FormGroup = new FormGroup({});

  ngOnInit(): void {
    this.createForm();
    this.createInitialFormArrays();
    this.setInitialCheckboxValues();
  }

  /**
   * Gets the opening hours form group from the parent form group and adds the necessary form controls
   * @returns void
   */
  private createForm(): void {
    this.openingHoursForm = this.parentFormGroup.get(
      'openingHoursFormGroup'
    ) as FormGroup;

    if (!this.openingHoursForm) return;

    const checkboxControls = [
      'checkbox0',
      'checkbox1',
      'checkbox2',
      'checkbox3',
      'checkbox4',
      'checkbox5',
      'checkbox6',
    ];

    checkboxControls.forEach(checkboxControl => {
      this.openingHoursForm.addControl(checkboxControl, new FormControl(false));
    });

    this.openingHoursForm.addControl('openingHours', new FormArray([]));
  }

  /**
   * Creates the initial form arrays based on the opening hours array
   * @returns void
   */
  private createInitialFormArrays(): void {
    if (this.openingHours.length === 0) return;

    this.openingHours = sortOpeningHoursByDay(this.openingHours);

    this.openingHours.forEach(openingHour => {
      this.openingHoursFormArray.push(
        new FormGroup(
          {
            id: new FormControl(openingHour.id),
            day: new FormControl(openingHour.dayOfWeek),
            from: new FormControl(openingHour.openingTime, Validators.required),
            to: new FormControl(openingHour.closingTime, Validators.required),
            timeZone: new FormControl(openingHour.timeZone),
          },
          { validators: timeRangeValidator('from', 'to') }
        )
      );
      sortOpeningHoursFormArrayByWeekday(this.openingHoursFormArray);
    });
  }

  /**
   * Sets the initial checkbox values based on the opening hours array
   * If the opening hours array is empty, the default values are set (from monday to friday, from 00:00 to 23:59)
   * @returns void
   */
  private setInitialCheckboxValues(): void {
    if (this.openingHours.length === 0 && !this.editMode) {
      // set from monday to friday to true and from 00:00 to 23:59 in for each day
      for (let i = 1; i <= 5; i++) {
        this.openingHoursForm.get(`checkbox${i}`).setValue(true);
        this.openingHoursFormArray.push(
          new FormGroup(
            {
              day: new FormControl(i),
              from: new FormControl('00:00', Validators.required),
              to: new FormControl('23:59', Validators.required),
            },
            { validators: timeRangeValidator('from', 'to') }
          )
        );
      }
      this.openingHoursInitialized.emit();
      return;
    }

    this.openingHours.forEach((openingHour: OpeningHourModel) => {
      this.openingHoursForm
        .get(`checkbox${openingHour.dayOfWeek}`)
        .setValue(true);
    });

    this.openingHoursInitialized.emit();
  }

  /**
   * Gets the opening hours form array
   */
  get openingHoursFormArray(): FormArray {
    return this.openingHoursForm.get('openingHours') as FormArray;
  }

  public removeOpeningHour(index: number, day: WeekdayEnum): void {
    this.openingHoursFormArray.removeAt(index);
    // set value of associated checkbox to false

    this.openingHoursForm.get(`checkbox${day}`).setValue(false);
  }

  /**
   * Removes or adds a form group to the form array based on the checkbox state
   * @param day The day of the week the checkbox belongs to
   * @param event The checkbox change event
   * @returns void
   */
  public onWeekdayCheckboxChanged(
    day: WeekdayEnum,
    event: MatCheckboxChange
  ): void {
    if (!event.checked) {
      this.openingHoursFormArray.controls =
        this.openingHoursFormArray.controls.filter(
          d => d.get('day').value !== day
        );
    }

    if (event.checked) {
      this.openingHoursFormArray.push(
        new FormGroup(
          {
            day: new FormControl(day),
            from: new FormControl(null, Validators.required),
            to: new FormControl(null, Validators.required),
          },
          { validators: timeRangeValidator('from', 'to') }
        )
      );
      sortOpeningHoursFormArrayByWeekday(this.openingHoursFormArray);
    }
  }

  /**
   * Preserve the original enum order
   */
  public originalWeekDayOrder = (
    a: KeyValue<string, WeekdayEnum>,
    b: KeyValue<string, WeekdayEnum>
  ): number => {
    return 0;
  };
}
