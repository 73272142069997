import { CourseEvent } from '../models/course.model';

/**
 * getParticipantAmount
 * get the amount of participants for the course event by summing up the elogs
 * @param event
 * @returns number
 */
export function getParticipantAmount(event: CourseEvent): number {
  if (!event.elogStatusCounts) {
    return 0;
  }
  return (
    event.elogStatusCounts.absent +
    event.elogStatusCounts.checked +
    event.elogStatusCounts.excused +
    event.elogStatusCounts.pending +
    event.elogStatusCounts.unexcused +
    event.elogStatusCounts.upcoming
  );
}
