import { Injectable } from '@angular/core';
import { FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class FormSubmitValidationService {
  constructor(private alertService: AlertService) {}

  /**
   * Validates a form group by trimming its values, marking all controls as touched,
   * and scrolling to the first invalid control if any.
   * @param formGroup - The form group to be validated.
   * @returns A boolean indicating whether the form group is valid or not.
   */
  public validateTrimAndScrollToError(formGroup: FormGroup): boolean {
    this.trimFormValues(formGroup);

    if (formGroup.invalid) {
      formGroup.markAllAsTouched();

      this.findFirstInvalidControlAndScrollToIt(formGroup);
      return false;
    }

    return true;
  }

  /**
   * Trims the values of a form group and its nested form groups or form arrays.
   * @param formGroup - The form group to trim the values of.
   */
  private trimFormValues(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach(control => {
      if (!control) return;

      if (control instanceof FormGroup || control instanceof FormArray) {
        // Recursively trim nested FormGroups or FormArrays
        this.trimFormValues(control as FormGroup);
      } else if (typeof control.value === 'string') {
        // Trim the string value
        control.setValue(control.value.trim());
      }
    });
  }

  /**
   * Finds the first invalid form control in a FormGroup and scrolls to it.
   * @param formGroup - The FormGroup to search for invalid controls.
   * @returns void
   */
  private findFirstInvalidControlAndScrollToIt(formGroup: FormGroup): void {
    // Get the first invalid form control key
    const firstInvalidControlKey = Object.keys(formGroup.controls).find(
      key => formGroup.controls[key].invalid
    );

    if (firstInvalidControlKey) {
      const firstInvalidControl = formGroup.controls[firstInvalidControlKey];
      const error = firstInvalidControl.errors;

      this.displayErrorMessageAlert(error);

      // Get the first invalid control element
      const firstInvalidControlElement = document.getElementById(
        firstInvalidControlKey
      );

      if (firstInvalidControlElement) {
        // Scroll to the first invalid form control
        firstInvalidControlElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }

  /**
   * Displays an error message alert based on the provided validation errors.
   * If the error is required, it shows an error alert with a specific message.
   * Otherwise, it shows a generic error alert message.
   * @param error - The validation errors object.
   */
  private displayErrorMessageAlert(error: ValidationErrors): void {
    if (error?.required) {
      this.alertService.showErrorAlert(
        'Das hat nicht geklappt!',
        'Es gibt noch unausgefüllte Pflichtfelder.'
      );
    } else {
      this.alertService.showErrorAlert(
        'Das hat nicht geklappt!',
        'Bitte überprüfen Sie Ihre Eingaben.'
      );
    }
  }
}
