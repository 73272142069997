<div class="component-content" *ngIf="initialEducationStock">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        Bereits absolvierte Stunden dieses Kandidaten
      </h1>
      <h3 class="hasomed-text-h3">
        Auflistung aller Ausbildungsstunden außerhalb von EleGuide.
      </h3>
    </div>
    <div class="header-buttons">
      <!-- Edit button -->
      <button
        [disabled]="isLoading"
        type="button"
        class="hasomed-text-button hasomed-button-orange"
        (click)="onOpenInitialEducationStockEdit()">
        <i class="fa-light fa-pen hasomed-button-icon-sm"></i>
        <span class="hasomed-button-text">Bearbeiten</span>
      </button>
    </div>
  </div>
  <div *ngIf="!isLoading && initialEducationStock" class="main-content-details">
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Dauer der Ausbildungsstunden </span>
        <span class="label-description">
          Gesamtdauer der schon absolvierten Theoretischen Ausbildung in
          Stunden.
        </span>
      </div>
      <div class="detail-value">
        {{
          initialEducationStock?.theoreticalEducationDurationHours
            | floatToHoursMinutes
        }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Zeitraum </span>
        <span class="label-description">
          Start- und Enddatum in dem die Theoretische Ausbildung bisher
          ausgeführt wurde.
        </span>
      </div>
      <div class="detail-value">
        <i class="fa-light fa-calendar"></i>
        {{
          initialEducationStock?.theoreticalEducationStartDate
            | date: 'dd.MM.yyyy'
        }}
        -
        {{
          initialEducationStock?.theoreticalEducationEndDate
            | date: 'dd.MM.yyyy'
        }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <ng-container
      *ngFor="
        let initialPracticalWorkStock of initialEducationStock?.initialPracticalWorkStocks
      ">
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title"> Praktische Tätigkeit </span>
          <span class="label-description">
            Art der praktischen Tätigkeit.
          </span>
        </div>
        <div class="detail-value">
          {{ getPracticalWorkTypeLabel(initialPracticalWorkStock?.type) }}
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title"> Kooperationspartner </span>
          <span class="label-description">
            Name des Kooperationspartners bei der praktischen Tätigkeit.
          </span>
        </div>
        <div class="detail-value">
          {{ initialPracticalWorkStock?.cooperationPartner?.name ?? '-' }}
        </div>
      </div>

      <mat-divider></mat-divider>

      <!-- Zeitraum -->
      <div class="formular-row">
        <div class="formular-row-label">
          <span class="label-title"> Zeitraum </span>
          <span class="label-description">
            Start- und Enddatum in dem die Praktische Tätigkeit bisher
            ausgeführt wurde.
          </span>
        </div>
        <div class="detail-value">
          <i class="fa-light fa-calendar"></i>
          {{ initialPracticalWorkStock?.startDate | date: 'dd.MM.yyyy' }}
          -
          {{ initialPracticalWorkStock?.endDate | date: 'dd.MM.yyyy' }}
        </div>
      </div>

      <mat-divider></mat-divider>
    </ng-container>

    <!-- Dauer Patientenbehandlung -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Dauer der Patientenbehandlung </span>
        <span class="label-description">
          Gesamtdauer der Patientenbehandlungen in Stunden.
        </span>
      </div>
      <div class="detail-value">
        {{
          initialEducationStock?.patientTreatmentDurationHours
            | floatToHoursMinutes
        }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Anzahl der Patientenbehandlungen -->
    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Anzahl der Patientenbehandlungen </span>
        <span class="label-description">
          Gesamtheit aller Patientenbehandlungen.
        </span>
      </div>
      <div class="detail-value">
        {{ initialEducationStock?.completedPatientTreatments ?? '-' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Zeitraum </span>
        <span class="label-description">
          Start- und Enddatum der gesamten Patientenbehanldungen.
        </span>
      </div>
      <div class="detail-value">
        <i class="fa-light fa-calendar"></i>
        {{
          initialEducationStock?.patientTreatmentStartDate | date: 'dd.MM.yyyy'
        }}
        -
        {{
          initialEducationStock?.patientTreatmentEndDate | date: 'dd.MM.yyyy'
        }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Dauer der Supervision </span>
        <span class="label-description">
          Gesamtdauer der Supervisionen in Stunden.
        </span>
      </div>
      <div class="detail-value">
        {{
          initialEducationStock?.supervisionDurationHours | floatToHoursMinutes
        }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Zeitraum </span>
        <span class="label-description">
          Start- und Enddatum der gesamten Supervisionen.
        </span>
      </div>
      <div class="detail-value">
        <i class="fa-light fa-calendar"></i>
        {{ initialEducationStock?.supervisionStartDate | date: 'dd.MM.yyyy' }}
        -
        {{ initialEducationStock?.supervisionEndDate | date: 'dd.MM.yyyy' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Dauer der Selbsterfahrung/Lehranalyse </span>
        <span class="label-description">
          Gesamtdauer der Selbsterfahrung/Lehranalyse in Stunden.
        </span>
      </div>
      <div class="detail-value">
        {{
          initialEducationStock?.selfAwarenessDurationHours
            | floatToHoursMinutes
        }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Zeitraum </span>
        <span class="label-description">
          Start- und Enddatum der gesamten Selbsterfahrung/Lehranalyse.
        </span>
      </div>
      <div class="detail-value">
        <i class="fa-light fa-calendar"></i>
        {{ initialEducationStock?.selfAwarenessStartDate | date: 'dd.MM.yyyy' }}
        -
        {{ initialEducationStock?.selfAwarenessEndDate | date: 'dd.MM.yyyy' }}
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="formular-row">
      <div class="formular-row-label">
        <span class="label-title"> Dokumente </span>
        <span class="label-description">
          Bestätigungen für die oben genannten Ausbildungsstunden.
        </span>
      </div>
      <span
        *ngIf="
          !initialEducationStock?.files ||
            initialEducationStock?.files?.length == 0;
          else files
        "
        class="detail-value">
        Keine Dokumente hochgeladen.
      </span>
      <ng-template #files>
        <div class="detail-value-files">
          <app-file-view
            *ngFor="let file of initialEducationStock?.files"
            [file]="file"
            (downloadFile)="onDownloadFile($event)"
            (openFile)="onOpenFile($event)"></app-file-view>
        </div>
      </ng-template>
    </div>
  </div>
</div>
