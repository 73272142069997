import { Component } from '@angular/core';

@Component({
  selector: 'app-user-container-header-skeleton',
  templateUrl: './user-container-header-skeleton.component.html',
  styleUrl: './user-container-header-skeleton.component.scss'
})
export class UserContainerHeaderSkeletonComponent {

}
