import { KeyValue } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import * as moment from 'moment';
import { Subject, take, takeUntil } from 'rxjs';
import { AppointmentTypeEnum } from 'src/app/enums/appointment-type.enum';
import { EventLocation } from 'src/app/enums/event-location.enum';
import {
  AppointmentCreateModel,
  AppointmentModel,
  AppointmentUpdateModel,
} from 'src/app/models/appointment.model';
import { RecurrencePattern } from 'src/app/models/course.model';
import {
  EventDate,
  EventDateCreateModel,
  EventDateUpdateModel,
} from 'src/app/models/event.model';
import { SelectOption } from 'src/app/models/select-option.model';
import { AlertService } from 'src/app/services/alert.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { FormDeactivateService } from 'src/app/services/form-deactivate.service';
import { FormSubmitValidationService } from 'src/app/services/form-submit-validation.service';
import { LoadingService } from 'src/app/services/loading.service';
import {
  mapEventDatesToEventDateModels,
  sortEventDatesByStartDate,
} from 'src/app/utils/date.utils';
import { isRequired } from 'src/app/utils/form.utils';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-create-edit-appointment-dialog',
  templateUrl: './create-edit-appointment-dialog.component.html',
  styleUrl: './create-edit-appointment-dialog.component.scss',
})
export class CreateEditAppointmentDialogComponent implements OnInit, OnDestroy {
  public appointmentForm: FormGroup = new FormGroup({});
  public initialFormValues: any = {};
  public appointmentId?: number = null;
  public editMode: boolean = false;
  public isLoading: boolean = true;
  public availableTypeSelectOptions: SelectOption[] = [
    { value: AppointmentTypeEnum.EVENT, label: 'Veranstaltung' },
    { value: AppointmentTypeEnum.OTHER, label: 'Sonstiges' },
    { value: AppointmentTypeEnum.HOLIDAY, label: 'Feiertag' },
  ];

  public selectedType: AppointmentTypeEnum = AppointmentTypeEnum.EVENT;

  // for event picker
  public recurrencePattern: RecurrencePattern;
  public eventDates: EventDate[] = [];
  public initialEventDates: EventDate[] = [];
  public allDayEvent: boolean = false;
  public formSubmitted: boolean = false;
  public dateSeriesStart: Date;
  public dateSeriesEnd: Date;

  // for roomplanning
  public roomPlanningValid: boolean = true;
  public roomPlanningDisabled: boolean = false;
  public roomPlanningOpen: boolean = false;
  public showLink: boolean = false;

  // for use in the template
  public isRequired = isRequired;
  public AppointmentTypeEnum = AppointmentTypeEnum;
  public EventLocation = EventLocation;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CreateEditAppointmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { appointmentId?: number },
    private appointmentService: AppointmentService,
    private formDeactivateService: FormDeactivateService,
    private formSubmitValidationService: FormSubmitValidationService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private alertService: AlertService
  ) {
    dialogRef.disableClose = true;
    this.appointmentId = data.appointmentId;
  }

  ngOnInit(): void {
    this.createForm();
    this.handleTypeValueChanges();
    this.handleLocationValueChanges();
    if (this.appointmentId) {
      this.editMode = true;
      this.initAppointment();
    } else {
      this.initialFormValues = this.appointmentForm.getRawValue();
      this.isLoading = false;
    }
  }

  /**
   * Creates the appointment form.
   * @returns void
   */
  private createForm(): void {
    this.appointmentForm = new FormGroup({
      type: new FormControl(this.selectedType, [Validators.required]),
      title: new FormControl(null, [
        Validators.required,
        Validators.maxLength(50),
      ]),
      description: new FormControl(null, [Validators.maxLength(500)]),
      maxParticipants: new FormControl(null),
      dateGroup: new FormControl(null),
      eventDates: new FormControl(this.eventDates),
      location: new FormControl(EventLocation.ONSITE),
      videoMeetingUrl: new FormControl(null),
      holidayDate: new FormControl(null),
    });
  }

  /**
   * Handles the value changes of the 'type' control in the appointment form.
   * If the selected type is 'HOLIDAY', sets validators for 'holidayDate' control and disables 'dateGroup' control.
   * If the selected type is 'EVENT' or 'OTHER', updates validators for 'holidayDate' control and enables 'dateGroup' control.
   * If the selected type is not recognized, no action is taken.
   * @returns void
   */
  private handleTypeValueChanges(): void {
    const holidayControl = this.appointmentForm.get('holidayDate');
    const dateGroupControl = this.appointmentForm.get('dateGroup');
    const typeControl = this.appointmentForm.get('type');

    if (!dateGroupControl || !holidayControl || !typeControl) {
      return;
    }

    if (this.selectedType === AppointmentTypeEnum.HOLIDAY) {
      holidayControl.setValidators([Validators.required]);
      dateGroupControl.disable();
    }

    typeControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.selectedType = value;

      switch (this.selectedType) {
        case AppointmentTypeEnum.EVENT:
          this.updateValidators(holidayControl, []);
          dateGroupControl.enable();
          break;
        case AppointmentTypeEnum.OTHER:
          this.updateValidators(holidayControl, []);
          dateGroupControl.enable();
          break;
        case AppointmentTypeEnum.HOLIDAY:
          this.appointmentForm.get('location').setValue(null);
          this.updateValidators(holidayControl, [Validators.required]);
          dateGroupControl.disable();
          break;
        default:
          break;
      }
    });
  }

  /**
   * Handles the changes in the location value of the appointment form.
   * If the selected type is HOLIDAY, sets the location value to null.
   * Subscribes to the value changes of the location form control and performs
   * specific actions based on the selected location.
   * @returns void
   */
  private handleLocationValueChanges(): void {
    if (this.selectedType === AppointmentTypeEnum.HOLIDAY) {
      this.appointmentForm.get('location').setValue(null);
    }

    this.appointmentForm
      .get('location')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(location => {
        switch (location) {
          case EventLocation.ONLINE:
            this.handleOnlineLocation();
            break;
          case EventLocation.ONSITE:
            this.handleOnsiteLocation();
            break;
          case EventLocation.HYBRID:
            this.handleHybridLocation();
            break;
        }
      });
  }

  /**
   * Handles the logic when the location is set to online.
   * Disables room planning, sets the link as required, and updates the form's validity.
   * @returns void
   */
  private handleOnlineLocation(): void {
    this.roomPlanningDisabled = true;
    this.roomPlanningValid = true;
    this.showLink = true;

    // set required validator for link if location is ONLINE
    this.appointmentForm
      .get('videoMeetingUrl')
      .setValidators([Validators.required]);
    this.appointmentForm.get('videoMeetingUrl').updateValueAndValidity();
  }

  /**
   * Handles the logic when the appointment location is set to onsite.
   * Enables room planning and hides the video meeting link.
   * Clears validators and updates the value and validity of the video meeting URL form control.
   * @returns void
   */
  private handleOnsiteLocation(): void {
    this.roomPlanningDisabled = false;
    this.showLink = false;

    this.appointmentForm.get('videoMeetingUrl').clearValidators();
    this.appointmentForm.get('videoMeetingUrl').updateValueAndValidity();
  }

  /**
   * Handles the hybrid location for the appointment.
   * Enables room planning and shows the link.
   * Sets the required validator for the video meeting URL field in the appointment form.
   * @returns void
   */
  private handleHybridLocation(): void {
    this.roomPlanningDisabled = false;
    this.showLink = true;

    this.appointmentForm
      .get('videoMeetingUrl')
      .setValidators([Validators.required]);
    this.appointmentForm.get('videoMeetingUrl').updateValueAndValidity();
  }

  /**
   * Updates the validators of a given control and triggers a validation update.
   * @param control - The control to update the validators for.
   * @param validators - The new validators to set for the control.
   * @returns void
   */
  private updateValidators(
    control: AbstractControl,
    validators: ValidatorFn | ValidatorFn[]
  ): void {
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  /**
   * Returns the FormControl for the 'type' field in the appointmentForm.
   * @returns The FormControl for the 'type' field.
   */
  get typeControl(): FormControl {
    return this.appointmentForm.get('type') as FormControl;
  }

  /**
   * Initializes the appointment by fetching the appointment details from the backend.
   * @returns void
   */
  private initAppointment(): void {
    this.appointmentService
      .getAppointmentById(this.appointmentId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: async response => {
          const appointment: AppointmentModel =
            await this.appointmentService.parseBackendAppointment(
              response.body
            );

          this.patchFormValues(appointment);
          this.isLoading = false;
        },
        error: error => {
          this.isLoading = false;
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Beim Laden des Termins ist ein Fehler aufgetreten.'
          );
          this.dialogRef.close();
        },
      });
  }

  /**
   * Patches the form values with the appointment details.
   * @param appointment - The appointment to patch the form values with.
   * @returns void
   */
  private patchFormValues(appointment: AppointmentModel): void {
    this.appointmentForm.patchValue({
      type: appointment.type,
      title: appointment.title,
      description: appointment.description,
      maxParticipants: appointment.maxParticipants,
      location: appointment.location,
      videoMeetingUrl: appointment.videoMeetingUrl,
      holidayDate: appointment.holidayDate,
    });

    this.eventDates = sortEventDatesByStartDate(appointment.eventDates);
    this.initialEventDates = JSON.parse(JSON.stringify(this.eventDates));
    this.recurrencePattern = appointment.recurrencePattern;
    this.dateSeriesStart = appointment.dateSeriesStart;
    this.dateSeriesEnd = appointment.dateSeriesEnd;
    this.initialFormValues = this.appointmentForm.getRawValue();
  }

  /**
   * Closes the dialog and performs a confirmation deactivation check before closing.
   * @returns void
   */
  public onClose(): void {
    // todo: refactor component so this is not needed
    this.eventDates = mapEventDatesToEventDateModels(this.eventDates);
    if (
      this.formDeactivateService.hasUnsavedChanges(
        this.eventDates,
        this.initialEventDates
      )
    ) {
      const confirmDeactivation$ =
        this.formDeactivateService.confirmDeactivation(
          this.eventDates,
          this.initialEventDates
        );

      confirmDeactivation$.pipe(take(1)).subscribe(canDeactivate => {
        if (canDeactivate) {
          this.dialogRef.close({ cancel: true });
        }
      });
      return;
    }
    const confirmDeactivation$ = this.formDeactivateService.confirmDeactivation(
      this.appointmentForm.getRawValue(),
      this.initialFormValues
    );

    confirmDeactivation$.pipe(take(1)).subscribe(canDeactivate => {
      if (canDeactivate) {
        this.dialogRef.close({ cancel: true });
      }
    });
  }

  /**
   * Handles the form submission.
   * @returns void
   */
  public onSubmit(): void {
    this.formSubmitted = true;
    if (
      !this.formSubmitValidationService.validateTrimAndScrollToError(
        this.appointmentForm
      )
    ) {
      return;
    }

    this.loadingService.show();
    this.editMode ? this.updateAppointment() : this.createAppointment();
  }

  /**
   * Creates an appointment using the provided data.
   * @returns void
   */
  private createAppointment(): void {
    const appointment: AppointmentCreateModel = {
      type: this.selectedType,
      title: this.appointmentForm.get('title').value,
      description: this.appointmentForm.get('description').value || null,
      maxParticipants:
        this.appointmentForm.get('maxParticipants').value || null,
      recurrencePattern: this.recurrencePattern || null,
      dateSeriesStart: this.dateSeriesStart
        ? this.dateSeriesStart.toISOString()
        : null,
      dateSeriesEnd: this.dateSeriesEnd
        ? this.dateSeriesEnd.toISOString()
        : null,
      eventDates: this.eventDates?.map(
        (eventDate: EventDate): EventDateCreateModel => ({
          startDate: eventDate.startDate.toISOString(),
          endDate: eventDate.endDate.toISOString(),
          roomId: eventDate.room?.id || null,
        })
      ),
      location: this.appointmentForm.get('location').value || null,
      videoMeetingUrl:
        this.appointmentForm.get('videoMeetingUrl').value || null,
      holidayDate: this.appointmentForm.get('holidayDate').value
        ? moment(this.appointmentForm.get('holidayDate').value).format(
            'YYYY-MM-DD'
          )
        : null,
    };

    this.appointmentService
      .createAppointment(appointment)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.loadingService.hide();
          this.initialFormValues = this.appointmentForm.getRawValue();
          this.dialogRef.close({ appointmentHasBeenCreated: true });

          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Termin wurde erfolgreich erstellt.'
          );
        },
        error: error => {
          this.loadingService.hide();
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Beim Erstellen des Termins ist ein Fehler aufgetreten.'
          );
        },
      });
  }

  /**
   * Updates the appointment with the provided data.
   * @returns void
   */
  private updateAppointment(): void {
    const updatedAppointment: AppointmentUpdateModel = {
      type: this.selectedType,
      title: this.appointmentForm.get('title').value,
      description: this.appointmentForm.get('description').value || null,
      maxParticipants:
        this.appointmentForm.get('maxParticipants').value || null,
      dateSeriesStart: this.dateSeriesStart
        ? this.dateSeriesStart.toISOString()
        : null,
      dateSeriesEnd: this.dateSeriesEnd
        ? this.dateSeriesEnd.toISOString()
        : null,
      recurrencePattern: this.recurrencePattern ?? null,
      eventDates: this.eventDates?.map(
        (eventDate: EventDate): EventDateUpdateModel => ({
          id: eventDate.id ?? null,
          startDate: eventDate.startDate.toISOString(),
          endDate: eventDate.endDate.toISOString(),
          roomId: eventDate.room?.id || null,
        })
      ),
      location: this.appointmentForm.get('location').value || null,
      videoMeetingUrl:
        this.appointmentForm.get('videoMeetingUrl').value || null,
      holidayDate: this.appointmentForm.get('holidayDate').value
        ? moment(this.appointmentForm.get('holidayDate').value).format(
            'YYYY-MM-DD'
          )
        : null,
    };

    this.appointmentService
      .updateAppointment(this.appointmentId, updatedAppointment)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          this.loadingService.hide();
          this.initialFormValues = this.appointmentForm.getRawValue();
          this.dialogRef.close({ appointmentHasBeenUpdated: true });

          this.alertService.showSuccessAlert(
            'Das hat geklappt!',
            'Der Termin wurde erfolgreich aktualisiert.'
          );
        },
        error: error => {
          this.loadingService.hide();
          this.alertService.showErrorAlert(
            'Das hat leider nicht geklappt!',
            'Beim Aktualisieren des Termins ist ein Fehler aufgetreten.'
          );
        },
      });
  }

  /**
   * Handles the change event when the event dates are updated.
   * @param eventDates - An array of event dates.
   * @returns void
   */
  public onEventsChanged(eventDates: EventDate[]): void {
    this.eventDates = eventDates;
  }

  /**
   * Handles the change event when the dates are updated.
   * @param dates - An object containing the updated start and end dates, and a flag indicating if it's an all-day event.
   * @returns void
   */
  public onDatesChanged(dates: {
    start: Date;
    end: Date;
    allDayEvent: boolean;
  }): void {
    this.dateSeriesStart = dates.start;
    this.dateSeriesEnd = dates.end;
    this.allDayEvent = dates.allDayEvent;
    this.cdr.detectChanges();
  }

  /**
   * Handles the change event of the room planning.
   * @param eventDates - An array of EventDate objects representing the selected dates.
   * @returns void
   */
  public roomPlanningChanged(eventDates: EventDate[]): void {
    if (!eventDates) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          title: 'Ungespeicherte Änderungen!',
          message:
            'Sie haben ungespeicherte Änderungen. Wenn Sie die Seite verlassen, gehen Daten verloren. \
              Möchten Sie die Seite trotzdem verlassen?',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe(result => {
          if (result) {
            this.roomPlanningOpen = !this.roomPlanningOpen;
            return;
          }
        });
    } else {
      this.eventDates = eventDates;
      this.roomPlanningOpen = false;
    }
  }

  /**
   * Preserve the original enum order
   */
  public originalEventLocationOrder = (
    a: KeyValue<string, EventLocation>,
    b: KeyValue<string, EventLocation>
  ): number => {
    return 0;
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
