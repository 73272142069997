<form
  [formGroup]="educationCourseForm"
  (ngSubmit)="onSubmit()"
  class="component-content">
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">
        Aus- und Weiterbildungsgänge {{ editMode ? 'bearbeiten' : 'anlegen' }}
      </h1>
      <h3 class="hasomed-text-h3">
        {{ editMode ? 'Bearbeiten des' : 'Anlegen eines' }} Aus- oder
        Weiterbildungsgangs.
      </h3>
    </div>
    <div class="header-buttons">
      <!-- Cancel button -->
      <button
        (click)="onCancel()"
        type="button"
        class="hasomed-text-button hasomed-button-white-grey">
        <span class="hasomed-button-text">Abbrechen</span>
      </button>

      <!-- Submit button -->
      <button type="submit" class="hasomed-text-button hasomed-button-primary">
        <i class="fa-light fa-floppy-disk hasomed-button-icon"></i>
        <span class="hasomed-button-text">Speichern</span>
      </button>
    </div>
  </div>

  <!-- LOADER -->
  <div *ngIf="isLoading" class="loading-wrapper">
    <app-lottie-loading-spinner size="45px"></app-lottie-loading-spinner>
  </div>

  <!-- MAIN CONTENT -->
  <div *ngIf="!isLoading" class="main-content-form">
    <!-- Ordnung -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Ausbildungsordnung oder Weiterbildungsordnung?
          <span *ngIf="isRequired(educationCourseForm.get('regulation'))"
            >*</span
          >
        </span>
        <span class="label-description"> Welche Verordnung wünschen Sie? </span>
      </label>
      <!-- AUSWAHL -->
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <button
            *ngFor="
              let regulation of Regulation | keyvalue: originalRegulationOrder
            "
            [ngClass]="{
              'hasomed-button-primary':
                educationCourseForm.get('regulation').value ===
                regulation.value,
              'hasomed-button-white-grey':
                educationCourseForm.get('regulation').value != regulation.value,
            }"
            (click)="setRegulation(regulation.value)"
            type="button"
            class="hasomed-text-button button-full-width">
            <span class="hasomed-button-text">
              {{ regulation.value }}
            </span>
          </button>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            educationCourseForm.get('regulation')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Titel -->
    <div class="formular-row">
      <label class="formular-row-label" for="title">
        <span class="label-title">
          Ausbildungstitel
          <span *ngIf="isRequired(educationCourseForm.get('title'))">*</span>
        </span>
        <span class="label-description">
          Beschreibender kurzer Titel der Ausbildung.
        </span>
      </label>

      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="title"
            class="hasomed-text-input"
            type="text"
            formControlName="title"
            placeholder="Titel" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="educationCourseForm.get('title')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Fachkunde -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Fachkunde/Gebietsweiterbildung
          <span *ngIf="isRequired(educationCourseForm.get('expertise'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Wählen Sie die passende Fachkunde.
        </span>
      </label>
      <!-- AUSWAHL -->
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <button
            *ngFor="let expertise of expertises"
            [ngClass]="{
              'hasomed-button-primary':
                educationCourseForm.get('expertise').value === expertise.id,
              'hasomed-button-white-grey':
                educationCourseForm.get('expertise').value != expertise.id,
            }"
            (click)="
              educationCourseForm.get('expertise').setValue(expertise.id)
            "
            type="button"
            class="hasomed-text-button button-full-width">
            <span class="hasomed-button-text">
              {{ expertise.name }}
            </span>
          </button>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            educationCourseForm.get('expertise')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Richtlinienverfahren -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Richtlinienverfahren/Bereichsweiterbildung
          <span *ngIf="isRequired(educationCourseForm.get('procedure'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Welches Richtlinenverfahren findet Anwendung? (nur eine Auswahl
          möglich)
        </span>
      </label>
      <!-- AUSWAHL -->
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <!-- BUTTONS -->
          <button
            *ngFor="let procedure of procedures"
            [ngClass]="{
              'hasomed-button-primary':
                educationCourseForm.get('procedure').value === procedure.id,
              'hasomed-button-white-grey':
                educationCourseForm.get('procedure').value != procedure.id,
            }"
            (click)="
              educationCourseForm.get('procedure').setValue(procedure.id)
            "
            type="button"
            class="hasomed-text-button button-full-width">
            <span class="hasomed-button-text">
              {{ procedure.name }}
            </span>
          </button>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            educationCourseForm.get('procedure')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Berufsverband -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Akkreditierung durch Berufsverband
          <span
            *ngIf="
              isRequired(educationCourseForm.get('professionalAssociation'))
            "
            >*</span
          >
        </span>
        <span class="label-description">
          Bei welchem Berufsverband sind Sie akkreditiert?
        </span>
      </label>
      <!-- AUSWAHL -->
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <!-- BUTTONS -->
          <button
            *ngFor="
              let paa of ProfessionalAssociation | keyvalue: originalPaaOrder
            "
            [ngClass]="{
              'hasomed-button-primary':
                educationCourseForm.get('professionalAssociation').value ===
                paa.value,
              'hasomed-button-white-grey':
                educationCourseForm.get('professionalAssociation').value !=
                paa.value,
            }"
            (click)="setPAA(paa.value)"
            type="button"
            class="hasomed-text-button button-full-width">
            <span class="hasomed-button-text">{{ paa.value }}</span>
          </button>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            educationCourseForm.get('professionalAssociation')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Akkreditierungsjahr -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Akkreditierungsjahr
          <span *ngIf="isRequired(educationCourseForm.get('accreditationYear'))"
            >*</span
          >
        </span>
        <span class="label-description"> Wann wurden Sie akkreditiert? </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="accreditationYear"
            class="hasomed-text-input"
            type="number"
            formControlName="accreditationYear"
            placeholder="Akkreditierungsjahr" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            educationCourseForm.get('accreditationYear')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kammer -->
    <div class="formular-row">
      <label class="formular-row-label" for="chamber">
        <span class="label-title">
          Zuständige Kammer
          <span *ngIf="isRequired(educationCourseForm.get('chamber'))">*</span>
        </span>
        <span class="label-description">
          Welche Kammer ist für Sie zuständig?
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="chamber"
            class="hasomed-text-input"
            type="text"
            formControlName="chamber"
            placeholder="Kammer" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="educationCourseForm.get('chamber')"></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Interne Notiz -->
    <div class="formular-row">
      <label class="formular-row-label" for="internalNotes">
        <span class="label-title">
          Interne Notizen zur Ausbildung
          <span *ngIf="isRequired(educationCourseForm.get('internalNotes'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Nur mit der Rolle Verwaltung sichtbar
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="internalNotes"
            class="hasomed-textarea-input"
            type="text"
            formControlName="internalNotes"
            placeholder="Interne Notiz">
          </textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            educationCourseForm.get('internalNotes')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Externe Notiz -->
    <div class="formular-row">
      <label class="formular-row-label" for="externalNotes">
        <span class="label-title">
          Externe Notizen zur Ausbildung
          <span *ngIf="isRequired(educationCourseForm.get('externalNotes'))"
            >*</span
          >
        </span>
        <span class="label-description">
          Werden Auszubildenden öffentlich angezeigt
        </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <textarea
            id="externalNotes"
            class="hasomed-textarea-input"
            type="text"
            formControlName="externalNotes"
            placeholder="Externe Notiz">
          </textarea>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            educationCourseForm.get('externalNotes')
          "></app-error-message>
      </div>
    </div>

    <!-- SUB HEADER -->
    <div class="flex-c sub-header">
      <h1 class="hasomed-text-h1 m-z">Kontingente für Ausbildung</h1>
      <h3 class="hasomed-text-h3 m-z">Festlegung der einzelnen Kontingente.</h3>
    </div>

    <!-- Kontingent Theoretisch-Wissenschaftlich -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Theoretisch-Wissenschaftlich
          <span
            *ngIf="
              isRequired(
                educationCourseForm.get('contingentTheoreticalScientificHours')
              )
            "
            >*</span
          >
        </span>
        <span class="label-description"> Kontingent für die Ausbildung. </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input-columns">
          <div class="flex-c">
            <input
              id="contingentTheoreticalScientificHours"
              class="hasomed-text-input"
              type="number"
              formControlName="contingentTheoreticalScientificHours"
              placeholder="min. Stunden" />
            <app-error-message
              [formField]="
                educationCourseForm.get('contingentTheoreticalScientificHours')
              "></app-error-message>
          </div>

          <!-- min. Doppelstunden autogenes Training u.ä. -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="contingentTheoreticalScientificAutoTrainingHours">
              <span class="label-title">
                min. Doppelstunden autogenes Training u.ä
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get(
                        'contingentTheoreticalScientificAutoTrainingHours'
                      )
                    )
                  "
                  >*</span
                >
              </span>
            </label>
            <div class="formular-row-input">
              <input
                id="contingentTheoreticalScientificAutoTrainingHours"
                class="hasomed-text-input"
                type="number"
                inputmode="numeric"
                pattern="\d*"
                formControlName="contingentTheoreticalScientificAutoTrainingHours"
                placeholder="min. Doppelstunden" />
            </div>
          </div>

          <!-- min. Doppelstunden Balintgruppenarbeit u.ä. -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="contingentTheoreticalScientificBalintGroupWorkHours">
              <span class="label-title">
                min. Doppelstunden Balintgruppenarbeit u.ä.
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get(
                        'contingentTheoreticalScientificBalintGroupWorkHours'
                      )
                    )
                  "
                  >*</span
                >
              </span>
            </label>
            <div class="formular-row-input">
              <input
                id="contingentTheoreticalScientificBalintGroupWorkHours"
                class="hasomed-text-input"
                type="number"
                formControlName="contingentTheoreticalScientificBalintGroupWorkHours"
                placeholder="min. Doppelstunden" />
            </div>
          </div>
        </div>
        <app-error-message
          [formField]="
            educationCourseForm.get(
              'contingentTheoreticalScientificAutoTrainingHours'
            )
          "></app-error-message>

        <app-error-message
          [formField]="
            educationCourseForm.get(
              'contingentTheoreticalScientificBalintGroupWorkHours'
            )
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kontingent Praktisch-1 -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Praktische Tätigkeit 1
          <span
            *ngIf="
              isRequired(educationCourseForm.get('contingentPractical1Hours'))
            "
            >*</span
          >
        </span>
        <span class="label-description"> Kontingent für die Ausbildung. </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input-columns">
          <div class="flex-c">
            <input
              id="contingentPractical1Hours"
              class="hasomed-text-input"
              type="number"
              formControlName="contingentPractical1Hours"
              placeholder="min. Stunden" />
            <!-- Error Messages -->
            <app-error-message
              [formField]="
                educationCourseForm.get('contingentPractical1Hours')
              "></app-error-message>
          </div>

          <div class="flex-c">
            <div class="formular-inner-row">
              <label
                class="formular-row-inner-label"
                for="contingentPractical1MedicalHistorySurveyMin">
                <span class="label-title">
                  min. Anamneseerhebungen
                  <span
                    *ngIf="
                      isRequired(
                        educationCourseForm.get(
                          'contingentPractical1MedicalHistorySurveyMin'
                        )
                      )
                    "
                    >*</span
                  >
                </span>
              </label>
              <div class="formular-row-input">
                <input
                  id="contingentPractical1MedicalHistorySurveyMin"
                  class="hasomed-text-input"
                  type="number"
                  formControlName="contingentPractical1MedicalHistorySurveyMin"
                  placeholder="min. Anamneseerhebungen" />
              </div>
            </div>
            <!-- Error Messages -->
            <app-error-message
              [formField]="
                educationCourseForm.get(
                  'contingentPractical1MedicalHistorySurveyMin'
                )
              "></app-error-message>
          </div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kontingent Praktisch-2 -->
    <div class="formular-row">
      <label class="formular-row-label">
        <span class="label-title">
          Praktische Tätigkeit 2
          <span
            *ngIf="
              isRequired(educationCourseForm.get('contingentPractical2Hours'))
            "
            >*</span
          >
        </span>
        <span class="label-description"> Kontingent für die Ausbildung. </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input">
          <input
            id="contingentPractical2Hours"
            class="hasomed-text-input"
            type="number"
            formControlName="contingentPractical2Hours"
            placeholder="min. Stunden" />
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            educationCourseForm.get('contingentPractical2Hours')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kontingent Behandlungspraktikum -->
    <div class="formular-row">
      <label
        class="formular-row-label"
        for="contingentTreatmentInternshipHours">
        <span class="label-title">
          Behandlungspraktikum
          <span
            *ngIf="
              isRequired(
                educationCourseForm.get('contingentTreatmentInternshipHours')
              )
            "
            >*</span
          >
        </span>
        <span class="label-description"> Kontingent für die Ausbildung. </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input-columns">
          <div class="formular-row-input">
            <input
              id="contingentTreatmentInternshipHours"
              class="hasomed-text-input"
              type="number"
              formControlName="contingentTreatmentInternshipHours"
              placeholder="min. Stunden" />
          </div>

          <!-- Abgeschlossene Behandlungen -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="contingentCompletedTreatmentCases">
              <span class="label-title">
                Abgeschlossene Behandlungen
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get(
                        'contingentCompletedTreatmentCases'
                      )
                    )
                  "
                  >*</span
                >
              </span>
            </label>
            <div class="formular-row-input">
              <input
                id="contingentCompletedTreatmentCases"
                class="hasomed-text-input"
                type="number"
                formControlName="contingentCompletedTreatmentCases"
                placeholder="Abgeschlossene Behandlungen" />
            </div>
          </div>

          <!-- Fälle -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="contingentTreatmentCases">
              <span class="label-title">
                Fälle (Gesamt)
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get('contingentTreatmentCases')
                    )
                  "
                  >*</span
                >
              </span>
            </label>
            <div class="formular-row-input">
              <input
                id="contingentTreatmentCases"
                class="hasomed-text-input"
                type="number"
                formControlName="contingentTreatmentCases"
                placeholder="Fälle" />
            </div>
          </div>

          <!-- min. Fälle -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="contingentTreatmentCasesMin">
              <span class="label-title">
                davon mindestens
                {{ educationCourseForm.value.contingentTreatmentCasesMin ?? 0 }}
                Fälle
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get('contingentTreatmentCasesMin')
                    )
                  "
                  >*</span
                >
              </span>
            </label>
            <div class="formular-row-input">
              <input
                [max]="educationCourseForm.value.contingentTreatmentCases"
                id="contingentTreatmentCasesMin"
                class="hasomed-text-input"
                type="number"
                formControlName="contingentTreatmentCasesMin"
                placeholder="min. Fälle" />
            </div>
          </div>

          <!-- mit min. Stunden -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="contingentTreatmentCasesMinHours">
              <span class="label-title">
                mit mindestens
                {{
                  educationCourseForm.value.contingentTreatmentCasesMinHours ??
                    0
                }}
                Stunden
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get(
                        'contingentTreatmentCasesMinHours'
                      )
                    )
                  "
                  >*</span
                >
              </span>
            </label>
            <div class="formular-row-input">
              <input
                [max]="
                  educationCourseForm.value.contingentTreatmentInternshipHours
                "
                id="contingentTreatmentCasesMinHours"
                class="hasomed-text-input"
                type="number"
                formControlName="contingentTreatmentCasesMinHours"
                placeholder="min. Stunden" />
            </div>
          </div>

          <!-- min Fälle (2) contingentTreatmentCasesMin2 -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="contingentTreatmentCasesMin2">
              <span class="label-title">
                davon mindestens
                {{
                  educationCourseForm.value.contingentTreatmentCasesMin2 ?? 0
                }}
                Fälle
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get('contingentTreatmentCasesMin2')
                    )
                  "
                  >*</span
                >
              </span>
            </label>
            <div class="formular-row-input">
              <input
                [max]="educationCourseForm.value.contingentTreatmentCases"
                id="contingentTreatmentCasesMin2"
                class="hasomed-text-input"
                type="number"
                formControlName="contingentTreatmentCasesMin2"
                placeholder="min. Fälle" />
            </div>
          </div>

          <!-- mit min. Stunden (2) -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="contingentTreatmentCasesMin2Hours">
              <span class="label-title">
                mit mindestens
                {{
                  educationCourseForm.value.contingentTreatmentCasesMin2Hours ??
                    0
                }}
                Stunden
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get(
                        'contingentTreatmentCasesMin2Hours'
                      )
                    )
                  "
                  >*</span
                >
              </span>
            </label>
            <div class="formular-row-input">
              <input
                [max]="
                  educationCourseForm.value.contingentTreatmentInternshipHours
                "
                id="contingentTreatmentCasesMin2Hours"
                class="hasomed-text-input"
                type="number"
                formControlName="contingentTreatmentCasesMin2Hours"
                placeholder="min. Stunden" />
            </div>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            educationCourseForm.get('contingentTreatmentInternshipHours')
          "></app-error-message>
        <app-error-message
          [formField]="
            educationCourseForm.get('contingentCompletedTreatmentCases')
          "></app-error-message>
        <app-error-message
          [formField]="
            educationCourseForm.get('contingentTreatmentCases')
          "></app-error-message>
        <app-error-message
          [formField]="
            educationCourseForm.get('contingentTreatmentCasesMin')
          "></app-error-message>
        <app-error-message
          [formField]="
            educationCourseForm.get('contingentTreatmentCasesMinHours')
          "></app-error-message>
        <app-error-message
          [formField]="
            educationCourseForm.get('contingentTreatmentCasesMin2')
          "></app-error-message>
        <app-error-message
          [formField]="
            educationCourseForm.get('contingentTreatmentCasesMin2Hours')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kontingent Supervision -->
    <div class="formular-row">
      <label class="formular-row-label" for="contingentSupervisionHours">
        <span class="label-title">
          Supervision
          <span
            *ngIf="
              isRequired(educationCourseForm.get('contingentSupervisionHours'))
            "
            >*</span
          >
        </span>
        <span class="label-description"> Kontingent für die Ausbildung. </span>
      </label>
      <div class="hasomed-input-field">
        <div class="formular-row-input-columns">
          <div class="formular-row-input">
            <input
              id="contingentSupervisionHours"
              class="hasomed-text-input"
              type="number"
              formControlName="contingentSupervisionHours"
              placeholder="min. Stunden" />
          </div>

          <!-- min. Einzelsitzung -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="contingentSupervisionSingleSessionHours">
              <span class="label-title">
                davon mind.
                {{
                  educationCourseForm.value
                    .contingentSupervisionSingleSessionHours
                }}
                Stunden in Einzelsitzung
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get(
                        'contingentSupervisionSingleSessionHours'
                      )
                    )
                  "
                  >*</span
                >
              </span>
            </label>
            <div class="formular-row-input">
              <input
                [max]="educationCourseForm.value.contingentSupervisionHours"
                id="contingentSupervisionSingleSessionHours"
                class="hasomed-text-input"
                type="number"
                formControlName="contingentSupervisionSingleSessionHours"
                placeholder="min. Einzelsitzung" />
            </div>
          </div>

          <!-- min. Gruppensitzung -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="contingentSupervisionGroupSessionHours">
              <span class="label-title">
                Rest in Gruppe mit max. 4 TN
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get(
                        'contingentSupervisionGroupSessionHours'
                      )
                    )
                  "
                  >*</span
                >
              </span>
            </label>
            <div class="formular-row-input">
              <input
                [max]="educationCourseForm.value.contingentSupervisionHours"
                id="contingentSupervisionGroupSessionHours"
                class="hasomed-text-input"
                type="number"
                formControlName="contingentSupervisionGroupSessionHours"
                placeholder="min. Gruppensitzung" />
            </div>
          </div>

          <!-- min. Anamneseerhebungen -->
          <div class="formular-inner-row">
            <label
              class="formular-row-inner-label"
              for="contingentSupervisionMedicalHistorySurveyMin">
              <span class="label-title">
                min. Anamneseerhebungen
                <span
                  *ngIf="
                    isRequired(
                      educationCourseForm.get(
                        'contingentSupervisionMedicalHistorySurveyMin'
                      )
                    )
                  "
                  >*</span
                >
              </span>
            </label>
            <div class="formular-row-input">
              <input
                id="contingentSupervisionMedicalHistorySurveyMin"
                class="hasomed-text-input"
                type="number"
                formControlName="contingentSupervisionMedicalHistorySurveyMin"
                placeholder="min. Anamneseerhebungen" />
            </div>
          </div>
        </div>
        <!-- Error Messages -->
        <app-error-message
          [formField]="
            educationCourseForm.get('contingent_sv')
          "></app-error-message>
        <app-error-message
          [formField]="
            educationCourseForm.get('contingent_sv_single_session')
          "></app-error-message>
        <app-error-message
          [formField]="
            educationCourseForm.get('contingent_sv_group_session')
          "></app-error-message>
      </div>
    </div>
    <mat-divider></mat-divider>

    <!-- Kontingent Supervision -->
    <div class="formular-row">
      <label class="formular-row-label" for="contingentSelfExperienceHours">
        <span class="label-title">
          Lehrtherapie, Lehranalyse oder Selbsterfahrung
          <span
            *ngIf="
              isRequired(
                educationCourseForm.get('contingentSelfExperienceHours')
              )
            "
            >*</span
          >
        </span>
        <span class="label-description"> Kontingent für die Ausbildung. </span>
      </label>
      <div class="hasomed-input-field gap-8" formGroupName="selfAwareness">
        <div class="formular-row-input-columns">
          <div class="flex-c">
            <input
              id="contingentSelfExperienceHours"
              class="hasomed-text-input"
              type="number"
              formControlName="contingentSelfExperienceHours"
              placeholder="min. Stunden" />
            <!-- Error Messages -->
            <app-error-message
              [formField]="
                educationCourseForm.get(
                  'selfAwareness.contingentSelfExperienceHours'
                )
              "></app-error-message>
          </div>
          <div class="flex-c">
            <div class="formular-inner-row">
              <label
                class="formular-row-inner-label"
                for="contingentSelfExperienceSessionsMin">
                <span class="label-title">
                  min. Sitzungen
                  <span
                    *ngIf="
                      isRequired(
                        educationCourseForm.get(
                          'selfAwareness.contingentSelfExperienceSessionsMin'
                        )
                      )
                    "
                    >*</span
                  >
                </span>
              </label>
              <div class="formular-row-input">
                <input
                  id="contingentSelfExperienceSessionsMin"
                  [class.invalid]="
                    educationCourseForm.get('selfAwareness').errors
                  "
                  class="hasomed-text-input"
                  type="number"
                  formControlName="contingentSelfExperienceSessionsMin"
                  placeholder="min. Sitzungen" />
              </div>
            </div>
            <!-- Error Messages -->
            <app-error-message
              [formField]="
                educationCourseForm.get(
                  'selfAwareness.contingentSelfExperienceSessionsMin'
                )
              "></app-error-message>
          </div>
          <div class="flex-c">
            <div class="formular-inner-row">
              <label
                class="formular-row-inner-label"
                for="contingentSelfExperienceSingleSessionsMin">
                <span class="label-title">
                  davon Einzel
                  <span
                    *ngIf="
                      isRequired(
                        educationCourseForm.get(
                          'selfAwareness.contingentSelfExperienceSingleSessionsMin'
                        )
                      )
                    "
                    >*</span
                  >
                </span>
              </label>
              <div class="formular-row-input">
                <input
                  id="contingentSelfExperienceSingleSessionsMin"
                  [class.invalid]="
                    educationCourseForm.get('selfAwareness').errors
                  "
                  class="hasomed-text-input"
                  type="number"
                  formControlName="contingentSelfExperienceSingleSessionsMin"
                  placeholder="min. Sitzungen" />
              </div>
            </div>
            <!-- Error Messages -->
            <app-error-message
              [formField]="
                educationCourseForm.get(
                  'selfAwareness.contingentSelfExperienceSingleSessionsMin'
                )
              "></app-error-message>
          </div>
          <div class="flex-c">
            <div class="formular-inner-row">
              <label
                class="formular-row-inner-label"
                for="contingentSelfExperienceGroupSessionsMin">
                <span class="label-title">
                  davon Gruppe
                  <span
                    *ngIf="
                      isRequired(
                        educationCourseForm.get(
                          'selfAwareness.contingentSelfExperienceGroupSessionsMin'
                        )
                      )
                    "
                    >*</span
                  >
                </span>
              </label>
              <div class="formular-row-input">
                <input
                  id="contingentSelfExperienceGroupSessionsMin"
                  [class.invalid]="
                    educationCourseForm.get('selfAwareness').errors
                  "
                  class="hasomed-text-input"
                  type="number"
                  formControlName="contingentSelfExperienceGroupSessionsMin"
                  placeholder="min. Sitzungen" />
              </div>
            </div>
            <!-- Error Messages -->
            <app-error-message
              [formField]="
                educationCourseForm.get(
                  'selfAwareness.contingentSelfExperienceGroupSessionsMin'
                )
              "></app-error-message>
          </div>
        </div>
        <app-error-message
          [formField]="
            educationCourseForm.get('selfAwareness')
          "></app-error-message>
      </div>
    </div>
  </div>
</form>
