import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Feature, Permission } from 'src/app/models/permission.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrl: './mobile-menu.component.scss',
})
export class MobileMenuComponent {
  public feature = Feature;
  public permission = Permission;
  public isMobileMenuOpen = false;

  constructor(
    public authService: AuthService,
    private router: Router,
    public userService: UserService
  ) {}
  public onOpenMobileMenu(): void {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  /**
   * navigates to the personal data page and closes the user menu
   * @returns void
   */
  public onOpenSettings(): void {
    this.router.navigate(['/eleguide/settings/personal-data']);
    this.isMobileMenuOpen = false;
  }

  /**
   * navigates to the imprint page and closes the user menu
   * @returns void
   */
  public onOpenInformation(): void {
    this.router.navigate(['/eleguide/settings/imprint']);
    this.isMobileMenuOpen = false;
  }

  /**
   * logout the user and close the user menu
   * @returns void
   */
  public logout(): void {
    this.authService.logout();
    this.isMobileMenuOpen = false;
  }
}
