import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectOption } from 'src/app/models/select-option.model';

@Component({
  selector: 'academic-title-select',
  templateUrl: './academic-title-select.component.html',
  styleUrl: './academic-title-select.component.scss',
})
export class AcademicTitleSelectComponent {
  @Input() control: FormControl = new FormControl();
  public academicTitles: SelectOption[] = [
    { value: null, label: 'Kein Titel' },
    { value: 'Prof. Dr.', label: 'Prof. Dr.' },
    { value: 'Dr.', label: 'Dr.' },
    { value: 'M.Sc.', label: 'M.Sc.' },
    { value: 'M.A.', label: 'M.A.' },
    { value: 'Dipl.-Psych.', label: 'Dipl.-Psych.' },
    { value: 'Mag.', label: 'Mag.' },
    { value: 'Dr. med.', label: 'Dr. med.' },
    { value: 'Dr. med. univ.', label: 'Dr. med. univ.' },
    { value: 'Dipl.-Soz.päd.', label: 'Dipl.-Soz.päd.' },
    { value: 'Dipl.-Päd.', label: 'Dipl.-Päd.' },
    { value: 'Dr. phil.', label: 'Dr. phil.' },
    { value: 'Dr. rer. nat.', label: 'Dr. rer. nat.' },
  ];
}
