import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectOption } from 'src/app/models/select-option.model';

@Component({
  selector: 'gender-title-select',
  templateUrl: './gender-title-select.component.html',
  styleUrl: './gender-title-select.component.scss',
})
export class GenderTitleSelectComponent {
  @Input() control: FormControl = new FormControl();
  public genderTitles: SelectOption[] = [
    { value: null, label: 'Keine Angabe' },
    { value: 'Herr', label: 'Herr' },
    { value: 'Frau', label: 'Frau' },
  ];
}
