import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

/**
 * isRequired
 * checks if the formControl is required
 * @param formControl formControl of the formGroup
 * @returns boolean
 */
export function isRequired(
  formControl: FormControl | AbstractControl
): boolean {
  if (!formControl) {
    return false;
  }

  return formControl.hasValidator(Validators.required);
}

/**
 * checks if the formControl is invalid and has been touched or dirty
 * @param formControl formControl of the formGroup
 * @returns boolean
 */
export function hasErrors(formControl: FormControl | AbstractControl): boolean {
  if (!formControl) {
    return false;
  }

  return formControl.invalid && (formControl.dirty || formControl.touched);
}

/**
 * Checks if all values in the formGroup are null
 * @param formGroup formGroup to check
 * @returns boolean
 */
export function areAllValuesNull(formGroup: FormGroup): boolean {
  // Recursive function to check if all values are null
  const checkAllNull = (control: AbstractControl): boolean => {
    if (control instanceof FormControl) {
      return control.value === null;
    } else if (control instanceof FormGroup) {
      return Object.values(control.controls).every(checkAllNull);
    } else if (control instanceof FormArray) {
      return control.controls.every(checkAllNull);
    }
    return false;
  };

  return checkAllNull(formGroup);
}

/**
 * Checks if the date range is not empty
 * @param dateRange date range form group
 * @returns true if the date range is not empty
 */
export function dateRangeIsNotEmpty(dateRange: AbstractControl): boolean {
  return (
    dateRange &&
    dateRange?.get('start')?.value !== null &&
    dateRange?.get('end')?.value !== null
  );
}
