import {
  PatientAppointmentEventDateModel,
  PatientAppointmentModel,
} from '../models/patient-appointment.model';
import { SupervisionAppointmentModel } from '../models/supervision.model';
import {
  TreatmentCaseEventDateModel,
  TreatmentCaseModel,
} from '../models/treatment-case.model';
import { getFullName } from './user.utils';

/**
 * getAllTreatmentProcedures
 * returns all procedures of a treatment case as a string
 * @param treatmentCase
 * @returns string | null
 */
export function getAllTreatmentProcedures(
  treatmentCase: TreatmentCaseModel
): string | null {
  if (
    !treatmentCase ||
    !treatmentCase.treatmentProcedures ||
    treatmentCase.treatmentProcedures?.length === 0
  ) {
    return null;
  }

  return treatmentCase.treatmentProcedures.map(p => p.name).join(', ');
}

/**
 * getAllSupervisors
 * returns all supervisors of a treatment case as a string
 * @param treatmentCase
 * @returns string | null
 */
export function getAllSupervisors(
  treatmentCase: TreatmentCaseModel
): string | null {
  if (
    !treatmentCase ||
    !treatmentCase.supervisors ||
    treatmentCase.supervisors?.length === 0
  ) {
    return null;
  }

  return treatmentCase.supervisors
    .map(supervisor => getFullName(supervisor))
    .join(', ');
}

/**
 * getAllAccompanyingPersons
 * returns all accompanying persons of a treatment case as a string
 * @param treatmentCase
 * @returns string | null
 */
export function getAllAccompanyingPersons(
  treatmentCase: TreatmentCaseModel
): string | null {
  if (
    !treatmentCase ||
    !treatmentCase.accompanyingPersons ||
    treatmentCase.accompanyingPersons?.length === 0
  ) {
    return null;
  }

  return treatmentCase.accompanyingPersons.map(s => s.name).join(', ');
}

/**
 * getAllEventDateSupervisors
 * returns all supervisors of a treatment case event date as a string
 * @param treatmentCaseAppointmentEventDate
 * @returns string | null
 */
export function getAllEventDateSupervisors(
  treatmentCaseAppointmentEventDate: TreatmentCaseEventDateModel
): string | null {
  if (!treatmentCaseAppointmentEventDate) {
    return null;
  }

  if (treatmentCaseAppointmentEventDate.supervisor) {
    return getFullName(treatmentCaseAppointmentEventDate.supervisor);
  }

  if (
    !treatmentCaseAppointmentEventDate.treatmentCaseSupervisors ||
    treatmentCaseAppointmentEventDate.treatmentCaseSupervisors.length === 0
  ) {
    return null;
  }

  return treatmentCaseAppointmentEventDate.treatmentCaseSupervisors
    .map(supervisor => getFullName(supervisor))
    .join(', ');
}

/**
 * getAllSupervisionAppointmentSupervisors
 * returns all supervisors of a supervision appointment as a string
 * @param supervisionAppointment
 * @returns string | null
 */
export function getAllSupervisionAppointmentSupervisors(
  supervisionAppointment: SupervisionAppointmentModel
): string | null {
  if (
    !supervisionAppointment ||
    !supervisionAppointment.supervisors ||
    supervisionAppointment.supervisors.length === 0
  ) {
    return null;
  }

  return supervisionAppointment.supervisors
    .map(supervisor => getFullName(supervisor))
    .join(', ');
}

/**
 * getAllPatientAppointmentAccompanyingPersons
 * returns all accompanying persons of a patient appointment as a string
 * @param patientAppointment
 * @returns string | null
 */
export function getAllPatientAppointmentAccompanyingPersons(
  patientAppointment: PatientAppointmentModel
): string | null {
  if (
    !patientAppointment ||
    !patientAppointment.accompanyingPersons ||
    patientAppointment.accompanyingPersons.length === 0
  ) {
    return null;
  }

  return patientAppointment.accompanyingPersons
    .map(accompanyingPerson => accompanyingPerson.name)
    .join(', ');
}

/**
 * returns all accompanying persons of a patient appointment event date as a string
 * @param patientAppointmentEventDate The patient appointment event date
 * @returns string | null
 */
export function getAllPatientEventDateAccompanyingPersons(
  patientAppointmentEventDate: PatientAppointmentEventDateModel
): string | null {
  if (
    !patientAppointmentEventDate ||
    !patientAppointmentEventDate.accompanyingPersons ||
    patientAppointmentEventDate.accompanyingPersons.length === 0
  ) {
    return null;
  }

  return patientAppointmentEventDate.accompanyingPersons
    .map(accompanyingPerson => accompanyingPerson.name)
    .join(', ');
}
