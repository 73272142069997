<div class="flex-r gap-24" *ngIf="!isMobile">
  <p-skeleton height="145px" width="195px" borderRadius="8px" />
  <div class="flex-c gap-8 justify-space-between">
    <p-skeleton width="20rem" height="24px" borderRadius="16px" />
    <div class="flex-c gap-16">
      <div class="flex-c gap-8">
        <p-skeleton width="8rem" height="18px" borderRadius="16px" />
        <p-skeleton width="10rem" height="18px" borderRadius="16px" />
        <p-skeleton width="14rem" height="18px" borderRadius="16px" />
      </div>
      <p-skeleton width="7rem" height="24px" borderRadius="16px" />
    </div>
  </div>
</div>
<div class="flex-r gap-24" *ngIf="isMobile">
  <div class="flex-c gap-8 justify-space-between">
    <p-skeleton width="20rem" height="24px" borderRadius="16px" />
    <div class="flex-c gap-16">
      <div class="flex-c gap-8">
        <p-skeleton width="8rem" height="18px" borderRadius="16px" />
        <p-skeleton width="10rem" height="18px" borderRadius="16px" />
        <p-skeleton width="14rem" height="18px" borderRadius="16px" />
      </div>
      <p-skeleton width="7rem" height="24px" borderRadius="16px" />
    </div>
  </div>
</div>
