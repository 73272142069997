import { inject } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { KeyManagementService } from '../services/key-management.service';

export const AuthGuard: CanActivateFn = async (
  route,
  state
): Promise<boolean | UrlTree> => {
  const authService = inject(AuthService);
  const keyManagementService = inject(KeyManagementService);

  const isAuthenticated = await authService.isAuthenticated();

  if (isAuthenticated) {
    const keysInitialized = await keyManagementService.initializeKeys();

    if (!keysInitialized) {
      authService.saveCurrentUrlAndRedirectToLogin(state.url);
      return false;
    }

    return true;
  } else {
    authService.saveCurrentUrlAndRedirectToLogin(state.url);
    return false;
  }
};
