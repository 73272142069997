<div class="component-content">
  <app-initial-education-stock-alert></app-initial-education-stock-alert>
  <div class="header">
    <div class="header-title">
      <h1 class="hasomed-text-h1">Ausbildungsfortschritt</h1>
      <h3 class="hasomed-text-h3">Überprüfen Sie Ihren Fortschritt.</h3>
    </div>
    <button
      type="button"
      class="hasomed-text-button hasomed-button-primary"
      (click)="onOpenInitialEducationStock()">
      <i class="fa-light fa-cloud-upload hasomed-button-icon-sm"></i>
      <span class="hasomed-button-text">Anfangsbestand</span>
    </button>
  </div>

  <div class="main-content">
    <app-education-progress-bar></app-education-progress-bar>
  </div>
</div>
