import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export function yearRangeValidator(
  minYear: number,
  maxYear: number
): ValidatorFn {
  if (!moment(minYear, 'YYYY', true).isValid()) {
    throw new Error('Invalid minYear');
  }

  if (!moment(maxYear, 'YYYY', true).isValid()) {
    throw new Error('Invalid maxYear');
  }

  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    if (!moment(control.value, 'YYYY', true).isValid()) {
      return { invalidYear: true };
    }

    const year = moment(control.value, 'YYYY').year();

    return moment(year).isSameOrAfter(minYear) &&
      moment(year).isSameOrBefore(maxYear)
      ? null
      : { invalidYearRange: { minYear, maxYear } };
  };
}
